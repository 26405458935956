import { useSelector } from 'react-redux';

import { getUserAddressLine1, getUserAddressLine2, getUserCity, getUserState, getUserZipCode } from 'store/selectors';

import { UserAddress } from './types';

export const useGetUserAddress = (): UserAddress => {
  const addressLine1 = useSelector(getUserAddressLine1);
  const addressLine2 = useSelector(getUserAddressLine2);
  const city = useSelector(getUserCity);
  const state = useSelector(getUserState);
  const zipCode = useSelector(getUserZipCode);

  return {
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
  };
};
