import React, {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';

import {
  Modal,
  ModalBaseProps,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import Toast from 'react-native-toast-notifications';

import { Close } from 'assets/icons';
import { renderTypes } from 'components/CustomToast';
import { KeyboardAvoid } from 'components/KeyboardAvoid';
import { webStyles } from 'style/common';
import { isNativeDevice } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

import { useModalStyles } from './styles';

type ModalComponentProps = {
  modalIsVisible: boolean;
  children: ReactElement;
  toastRef?: MutableRefObject<Toast | null>;
  BackgroundImageComponent?: React.ElementType;
  modalContentStyle?: StyleProp<ViewStyle>;
  centeredViewStyle?: StyleProp<ViewStyle>;
  closeButtonStyle?: StyleProp<ViewStyle>;
  removeCloseIcon?: boolean;
  overlayColor?: string;
  removeIconColor?: string;
  useKeyboardAvoid?: boolean;
  useContentWrapper?: boolean;
  disableScrollView?: boolean;
  fillScreen?: boolean;
  setModalVisible?: Dispatch<SetStateAction<boolean>> | ((visible: boolean) => void);
  onRequestClose?: AnyFunction;
  onDismiss?: AnyFunction;
};

type ContentWrapperProps = PropsWithChildren<
  Pick<ModalComponentProps, 'useContentWrapper' | 'disableScrollView' | 'fillScreen'>
>;
type WrapperProps = { children: ReactNode; useKeyboardAvoid?: boolean };

const ContentWrapper = ({ fillScreen, disableScrollView, useContentWrapper, children }: ContentWrapperProps) => {
  const Wrapper = disableScrollView ? View : ScrollView;

  return (
    <Wrapper
      style={{
        flexGrow: fillScreen ? 1 : 0,
        ...(useContentWrapper && { padding: 32 }),
      }}>
      {children}
    </Wrapper>
  );
};

const Wrapper = ({ children, useKeyboardAvoid }: WrapperProps) =>
  useKeyboardAvoid ? <KeyboardAvoid>{children}</KeyboardAvoid> : <>{children}</>;

const toastOffset = isNativeDevice ? 40 : 60;

export const ModalComponent = ({
  modalIsVisible,
  children,
  removeCloseIcon,
  modalContentStyle,
  overlayColor,
  toastRef,
  removeIconColor,
  useKeyboardAvoid,
  closeButtonStyle,
  useContentWrapper,
  BackgroundImageComponent,
  setModalVisible,
  onRequestClose,
  disableScrollView,
  fillScreen = false,
  onDismiss,
  centeredViewStyle,
  ...rest
}: ModalComponentProps & ModalBaseProps) => {
  const closeModal = () => (onRequestClose ? onRequestClose() : setModalVisible?.(false));
  const [buttonStyle, setButtonStyle] = useState({});

  const buttonStyleHandler = () => setButtonStyle({ outline: 'none' });

  const styles = useModalStyles(fillScreen);

  const contentJSX = onDismiss ? (
    <TouchableOpacity
      activeOpacity={1}
      // Do not make it -> onPressOut={onDismiss}, onDismiss function does not need to receive event
      onPressOut={() => onDismiss()}
      style={[styles.centeredView, { backgroundColor: overlayColor || 'rgba(0, 0, 0, 0.7)' }, centeredViewStyle]}>
      <TouchableWithoutFeedback>
        <View style={[styles.modalView, modalContentStyle]}>
          {!removeCloseIcon && (
            <TouchableOpacity
              onFocus={buttonStyleHandler}
              onPress={onRequestClose || closeModal}
              style={[styles.closeButton, webStyles.button, buttonStyle, closeButtonStyle]}>
              <Close width={14} height={14} stroke={removeIconColor || '#000'} />
            </TouchableOpacity>
          )}
          {BackgroundImageComponent && <BackgroundImageComponent />}
          <ContentWrapper
            disableScrollView={disableScrollView}
            fillScreen={fillScreen}
            useContentWrapper={useContentWrapper}>
            {React.cloneElement(children, { closeModal })}
          </ContentWrapper>
        </View>
      </TouchableWithoutFeedback>
    </TouchableOpacity>
  ) : (
    <View style={[styles.centeredView, { backgroundColor: overlayColor || 'rgba(0, 0, 0, 0.7)' }, centeredViewStyle]}>
      <View style={[styles.modalView, modalContentStyle]}>
        {!removeCloseIcon && (
          <TouchableOpacity
            onFocus={buttonStyleHandler}
            onPress={onRequestClose || closeModal}
            style={[styles.closeButton, webStyles.button, buttonStyle, closeButtonStyle]}>
            <Close width={14} height={14} stroke={removeIconColor || '#000'} />
          </TouchableOpacity>
        )}
        {BackgroundImageComponent && <BackgroundImageComponent />}
        <ContentWrapper
          disableScrollView={disableScrollView}
          fillScreen={fillScreen}
          useContentWrapper={useContentWrapper}>
          {React.cloneElement(children, { closeModal })}
        </ContentWrapper>
      </View>
    </View>
  );

  return (
    <Modal visible={modalIsVisible} transparent {...rest} animationType='fade'>
      <Wrapper useKeyboardAvoid={useKeyboardAvoid}>
        {contentJSX}
        <Toast offsetTop={toastOffset} placement='top' ref={toastRef} renderType={renderTypes} />
      </Wrapper>
    </Modal>
  );
};
export default ModalComponent;
