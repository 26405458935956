import React from 'react';

import { Modal, Button } from 'components';
import { StandardText } from 'style/common';

import { useInsuranceDesktopModalStyles } from './styles';

const title = 'Use your mobile device to add insurance information';
const description = 'To upload images of your insurance information please use your mobile device';

type DesktopModalProps = {
  isVisible: boolean;
  onVisibleChange: (isVisible: boolean) => void;
};

export const DesktopInsuranceModal = ({ isVisible, onVisibleChange }: DesktopModalProps) => {
  const styles = useInsuranceDesktopModalStyles();

  return (
    <Modal modalContentStyle={styles.modalWrapper} modalIsVisible={isVisible} setModalVisible={onVisibleChange}>
      <>
        <StandardText style={styles.title}>{title}</StandardText>
        <StandardText>{description}</StandardText>
        <Button text='Close' variant='primary' style={styles.closeButton} onPress={() => onVisibleChange(false)} />
      </>
    </Modal>
  );
};
