import { DefaultTheme } from 'styled-components/native';

import copayCardTemplateSrc from 'assets/images/copay_card_template.png';
import missedRefillTemplateSrc from 'assets/images/missed_refill_template.png';
import missedRefillTemplateMobileSrc from 'assets/images/missed_refill_template_mobile.png';
import upcomingRefillTemplateSrc from 'assets/images/upcoming_refill_template.png';
import upcomingRefillTemplateMobileSrc from 'assets/images/upcoming_refill_template_mobile.png';
import { isIOSDevice } from 'utils/helpers';

import { TileTypesEnum, TileMapping } from './types';

export const getNotificationTileMapping = (theme: DefaultTheme, isMobile?: boolean): TileMapping => ({
  [TileTypesEnum.notUsed]: {
    headerText: 'You haven’t used your activated card',
    descriptionText: 'You activated your card but you haven’t used it yet.',
    templateBackgroundColor: theme.colors.gray3,
    templateSrc: copayCardTemplateSrc,
    templateWrapperStyle: {
      right: isMobile ? -390 * 0.35 : 10, // on mobile 35% of original image width
      height: isMobile ? '60%' : '80%',
      width: isMobile ? '70%' : '40%',
      borderRadius: 16,
      overflow: 'hidden',
    },
  },
  [TileTypesEnum.used]: {
    headerText: 'You’ve used your card',
    descriptionText: 'We’ve updated your card’s metrics for you.',
    templateSrc: copayCardTemplateSrc,
    templateWrapperStyle: {
      right: isMobile ? (isIOSDevice ? -90 : -105) : 10,
      height: isMobile ? '60%' : '80%',
      width: isMobile ? '70%' : '40%',
      borderRadius: 16,
    },
  },
  [TileTypesEnum.missedRefill]: {
    headerText: 'You missed your refill',
    descriptionText: 'You missed your refill for MEDICATION on ',
    headerTextColor: theme.colors.negative,
    templateBackgroundColor: theme.colors.negative,
    templateSrc: isMobile ? missedRefillTemplateMobileSrc : missedRefillTemplateSrc,
    templateWrapperStyle: {
      right: isMobile ? -20 : 15,
      height: isMobile ? '70%' : '100%',
      width: isMobile ? '60%' : '35%',
      ...(isMobile && { top: '20%' }),
    },
  },
  [TileTypesEnum.upcomingRefill]: {
    headerText: 'You have an upcoming refill',
    descriptionText: 'You are eligible to save on your next CIZPLAM refill on  ',
    templateSrc: isMobile ? upcomingRefillTemplateMobileSrc : upcomingRefillTemplateSrc,
    templateWrapperStyle: {
      right: isMobile ? -20 : 15,
      height: isMobile ? '70%' : '100%',
      width: isMobile ? '60%' : '35%',
      ...(isMobile && { top: '20%' }),
    },
  },
});
