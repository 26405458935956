import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function Triangle(props: SvgProps) {
  return (
    <Svg width={37} height={49} viewBox='0 0 37 49' fill='none' {...props}>
      <Path
        d='M33.507 18.108c4.252 3.2 4.252 9.584 0 12.784L12.811 46.468C7.539 50.436 0 46.675 0 40.076V8.924c0-6.599 7.539-10.36 12.81-6.392l20.697 15.576z'
        fill={props.fill || '#fff'}
      />
    </Svg>
  );
}

export default Triangle;
