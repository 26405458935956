import React from 'react';

import { View } from 'react-native';

import { StandardText } from 'style/common';

export const NoStep = () => (
  <View>
    <StandardText>Oops, the content that you're trying to access is not available.</StandardText>
  </View>
);

export default NoStep;
