import styled from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';

export const Sections = styled(DefaultText)`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.mangoOrange};
`;

export const SectionTitle = styled(DefaultText)`
  ${getFontStyles(16)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.gray1};
  margin-bottom: 24px;
`;

export const SectionsDescription = styled(DefaultText)`
  ${getFontStyles(16)};
  width: 100%;
  font-family: ${(props) => props.theme.fontFamilies.default};
  color: ${(props) => props.theme.colors.gray2};
  margin: 12px 0;
`;

export const SectionContainer = styled.View`
  flex-direction: column;
  justify-content: space-between;
`;
