import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

const containerStyle = { paddingVertical: 28, paddingHorizontal: 32 };

export const useNotificationTileStyles = () => {
  const { isMobile, colors, fontFamilies } = useTheme();

  return StyleSheet.create({
    container: { overflow: 'hidden', borderRadius: 16 },
    underBox: isNativeDevice
      ? {
          borderRadius: 16,
          zIndex: 1,
        }
      : {
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          borderRadius: 16,
          zIndex: 1,
        },
    closeButtonWrapper: {
      position: 'absolute',
      top: isMobile ? 5 : 20,
      right: 20,
      zIndex: 4,
    },
    contentWrapper: isNativeDevice
      ? {
          width: '80%',
          borderRadius: 16,
          padding: 30,
          backgroundColor: 'white',
          zIndex: 2,
          ...containerStyle,
        }
      : {
          width: '80%',
          height: '100%',
          borderRadius: 16,
          backgroundColor: 'white',
          padding: 30,
          zIndex: 2,
          ...containerStyle,
        },
    headerText: {
      fontFamily: fontFamilies.bold,
      marginBottom: 6,
    },
    descriptionText: {
      color: colors.gray2,
      maxWidth: isMobile ? '97%' : '85%',
    },
  });
};
