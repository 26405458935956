import React, { useRef } from 'react';

import ToastContainer, { useToast } from 'react-native-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';

import { MedicineCabinetInterface } from 'api';
import trackerPageLayoutSrc from 'assets/images/tracker_page_layout.png';
import trackerPageLayoutNativeSrc from 'assets/images/tracker_page_layout_native.png';
import { MedicationNotifications } from 'components/MedicationNotifications';
import { ModalComponent as Modal } from 'components/Modal';
import { Survey } from 'components/Survey';
import { SurveyCard } from 'components/SurveyCard';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useMedTrackerData, useNotificationHandler } from 'hooks';
import { MedicineCabinetLayout } from 'screens/main/MedicineCabinet/layout';
import { setShouldShowSurvey, setShouldForceSurveyOpen, removeSelectedSurveyId, setMedications } from 'store/actions';
import {
  getIsMedicationCabinetLoading,
  getUserHasPsp,
  getAllSurveysIds,
  getShouldShowSurvey,
  getShouldForceSurveyOpen,
} from 'store/selectors';
import { handlePromise } from 'utils/handlePromise';

import { AsNeededMedicationModal, TrackerContentPlaceholder } from './components';
import { useStyles } from './styles';
import { TrackerContent } from './TrackerContent';
import { CabinetScreenProps } from './types';

export const MedicationTracker = ({ navigation }: CabinetScreenProps) => {
  const dataIsLoading = useSelector(getIsMedicationCabinetLoading);
  const { isMobile } = useDeviceInfoContext();
  const toast = useToast();
  const dispatch = useDispatch();
  useNotificationHandler();

  const toastRef = useRef<ToastContainer>(null);

  const userHasPsp = useSelector(getUserHasPsp);

  const allSurveysIds = useSelector(getAllSurveysIds);
  const shouldShowSurvey = useSelector(getShouldShowSurvey);
  const shouldForceSurveyOpen = useSelector(getShouldForceSurveyOpen);

  const shouldShowSurveyModal = shouldForceSurveyOpen || shouldShowSurvey;

  const closeSurvey = () => {
    dispatch(setShouldShowSurvey(false));
    dispatch(setShouldForceSurveyOpen(false));
    setTimeout(() => dispatch(removeSelectedSurveyId()), 400);
  };

  const medTrackerLayoutImage = isMobile ? trackerPageLayoutNativeSrc : trackerPageLayoutSrc;

  const { setRequestedDate, getMedTrackerData, setMedicationId } = useMedTrackerData();

  const styles = useStyles();

  const handleAsNeededMedicationModalSubmit = () => {
    getMedTrackerData({ withLoading: true });
    handlePromise(MedicineCabinetInterface.getMedications).then(
      ([resp]) => !!resp && dispatch(setMedications(resp.data.results))
    );
    toast.show('As-needed medication successfully added!', { type: 'success' });
  };

  return (
    <MedicineCabinetLayout navigation={navigation} imageUrl={medTrackerLayoutImage} shouldShowGreeting isMedSectors>
      <MedicationNotifications actionHandler={getMedTrackerData} isLoading={dataIsLoading} />
      {dataIsLoading ? (
        <TrackerContentPlaceholder />
      ) : (
        <>
          {!userHasPsp && (
            <>
              {allSurveysIds?.map?.((surveyId) => (
                <SurveyCard key={surveyId} surveyId={surveyId} />
              ))}
              <Modal
                modalContentStyle={styles.surveyModalContentWrapper}
                toastRef={toastRef}
                modalIsVisible={shouldShowSurveyModal}
                setModalVisible={closeSurvey}>
                <Survey isSurveyStarted={shouldShowSurveyModal} toastRef={toastRef} onRequestClose={closeSurvey} />
              </Modal>
            </>
          )}
          <TrackerContent
            getMedTrackerData={getMedTrackerData}
            setMedicationId={setMedicationId}
            setRequestedDate={setRequestedDate}
          />
        </>
      )}
      <AsNeededMedicationModal onSubmit={handleAsNeededMedicationModalSubmit} />
    </MedicineCabinetLayout>
  );
};

export default MedicationTracker;
