import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useContactCardStyles = () => {
  const { colors, fontFamilies, fontSizes } = useTheme();

  return StyleSheet.create({
    baseText: {
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
      ...fontSizes[16],
    },
    contactText: {
      fontFamily: fontFamilies.default,
      marginTop: 8,
    },
    workingSchedule: {
      fontFamily: fontFamilies.default,
      color: colors.gray2,
      marginTop: 8,
    },
    contentButton: {
      marginTop: 16,
      width: 114,
    },
  });
};
