import { StyleSheet } from 'react-native';
import { DefaultTheme } from 'styled-components/native';

import { INTERACTION_ITEM_HEIGHT } from 'screens/main/MedicineCabinet/components/MedicationWizard/Steps/components/styles';
import { isNativeDevice } from 'utils/helpers';

export const getStepStyles = (theme: DefaultTheme, showAsNeededCheckbox?: boolean) =>
  StyleSheet.create({
    headerText: {
      ...theme.fontSizes[24],
      fontFamily: theme.fontFamilies.bold,
      color: theme.colors.gray1,
      paddingRight: 20,
    },
    reminderScrollView: { maxHeight: showAsNeededCheckbox ? 315 : 300, paddingRight: 12 },
    descriptionText: { marginTop: 20, marginBottom: 28, color: theme.colors.gray2 },
    medicationScrollView: { maxHeight: 300, paddingRight: 12 },
    blockContainer: { width: '100%', marginBottom: 16 },
    negative: {
      color: theme.colors.negative,
      margin: 4,
    },
    buttonsContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 10,
    },
    buttonContainerPaddingRightScrollView: { paddingRight: 12 },
    button: { width: '100%' },
    dynamicFormItem: { flexDirection: 'row', width: '100%', marginBottom: 16 },
    recurringFieldWrapper: { flex: 1 },
    hourFieldWrapper: { flex: 1, maxWidth: 130, marginLeft: 10 },
    interactions: {
      flexGrow: isNativeDevice ? 0 : 1,
      marginBottom: 40,
      paddingRight: 12,
      maxHeight: INTERACTION_ITEM_HEIGHT * 3,
    },
    skipForNowWrapper: { paddingLeft: 2, marginTop: 10 },
    skipText: { color: theme.colors.gray2 },
    asNeededMedicationDescription: {
      marginBottom: 16,
      textAlign: 'center',
      paddingHorizontal: 16,
      color: theme.colors.gray2,
    },
  });
