import React from 'react';

import { Pressable, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { CopayCardType } from 'api/CopayCardInterface/types';
import { Layout } from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import {
  CopayInstructions,
  InformationField,
  RemainingIndicator,
  UsedIndicator,
} from 'screens/main/FinancialAssistance/CopayFinancialAssistance/components';
import {
  CopayRenewalCard,
  PrescriptionRefillCard,
} from 'screens/main/FinancialAssistance/CopayFinancialAssistance/components/CopayInfoCards';
import { getCopayCardTitleByType, TEXTS } from 'screens/main/FinancialAssistance/CopayFinancialAssistance/constants';
import {
  contentAreaStyle,
  CopayImageWrapper,
  CopayRenewalCardWrapper,
  CopayStyledImage,
  DeckWrapper,
  FinancialAssistanceContainer,
  getCopayStyles,
  InfoBlocksWrapper,
  LinkText,
  mobileContentAreaStyle,
  RemainingBlock,
  RemainingBlocksWrapper,
  RenewalDetailsWrapper,
} from 'screens/main/FinancialAssistance/CopayFinancialAssistance/styles';
import { CopayFinancialAssistanceProps } from 'screens/main/FinancialAssistance/CopayFinancialAssistance/types';
import { setSelectedCopayCard } from 'store/actions';
import { CopayCardInfo } from 'store/reducers/user-info/types/copay-card';
import { getAppDataIsLoading, getProgramCopayCards, getSelectedCopayCard } from 'store/selectors';
import { HeaderText, StandardText, webStyles } from 'style/common';
import { appDateFormatter } from 'utils/date/appDateFormatter';
import { openUrlInNewTab } from 'utils/helpers';

export const CopayFinancialAssistance = ({ navigation }: CopayFinancialAssistanceProps) => {
  const dataIsLoading = useSelector(getAppDataIsLoading);

  const dispatch = useDispatch();

  const handleSelectedCopayCard = (copayCard: CopayCardInfo) => () => {
    dispatch(setSelectedCopayCard(copayCard));
  };

  const theme = useTheme();
  const styles = getCopayStyles({ theme });

  const getLocalTabStyle = (copayCardType: CopayCardType, selectedCopayCardType?: CopayCardType) => {
    const isActive = copayCardType === selectedCopayCardType;
    const pressableStyle = [styles.subTitle, programCopayCards?.length > 1 && isActive && styles.activeBorderText];
    const textStyle = [
      styles.standardText,
      isActive && styles.activeColorText,
      programCopayCards?.length === 1 && { color: theme.colors.gray1 },
    ];
    return { pressableStyle, textStyle };
  };

  const programCopayCards = useSelector(getProgramCopayCards);
  const selectedCopayCard = useSelector(getSelectedCopayCard);

  const cardConfig = selectedCopayCard?.config;
  const {
    isFillsUsed = false,
    isCopayRenewal = false,
    isBenefitsUsed = false,
    isFillsRemaining = false,
    isBenefitsRemaining = false,
    isPrescriptionRefill = false,
    medicalTermsAndConditions = '',
    medicalPatientInstructions = '',
    pharmacyTermsAndConditions = '',
    pharmacyPatientInstructions = '',
  } = cardConfig || {};

  const enrolmentDate = appDateFormatter(selectedCopayCard?.enrollmentDate, 'MM/dd/yyyy', '');

  const hasRefill = Boolean(isPrescriptionRefill && selectedCopayCard?.lastDispenseDate);
  const hasCopayRenewal = Boolean(isCopayRenewal && selectedCopayCard?.copayRenewalDate);

  const isMedicalCardType = selectedCopayCard?.type === 'MEDICAL';
  const isPharmacyCardType = selectedCopayCard?.type === 'PHARMACY';

  const openModal = () => openUrlInNewTab(isMedicalCardType ? medicalTermsAndConditions : pharmacyTermsAndConditions);

  if (dataIsLoading) return <FullScreenLoader />;

  return (
    <Layout
      mobileContentAreaStyle={mobileContentAreaStyle}
      contentAreaStyle={contentAreaStyle}
      title={TEXTS.title}
      description={TEXTS.description}
      navigation={navigation}>
      <FinancialAssistanceContainer>
        <HeaderText>About</HeaderText>
        <View style={styles.copayCardsWrapper}>
          {!!programCopayCards?.length && (
            <ScrollView showsHorizontalScrollIndicator={false} horizontal={true}>
              {programCopayCards?.map((copayCard) => {
                const { pressableStyle, textStyle } = getLocalTabStyle(copayCard?.type, selectedCopayCard?.type);
                return (
                  !!copayCard?.type && (
                    <Pressable key={copayCard.type} style={pressableStyle} onPress={handleSelectedCopayCard(copayCard)}>
                      <StandardText style={textStyle}>{getCopayCardTitleByType(copayCard.type)}</StandardText>
                    </Pressable>
                  )
                );
              })}
            </ScrollView>
          )}
        </View>
        <InfoBlocksWrapper>
          <InformationField label='Enrollment Date' info={enrolmentDate || '-'} />
          <InformationField label='BIN' info={selectedCopayCard?.bin || '-'} />
          <InformationField label='Group' info={selectedCopayCard?.group || '-'} />
          <InformationField label='ID' info={selectedCopayCard?.memberNumber || '-'} />
        </InfoBlocksWrapper>
        <RenewalDetailsWrapper>
          <RemainingBlocksWrapper isCopayRenewal={isCopayRenewal} isPrescriptionRefill={isPrescriptionRefill}>
            {isBenefitsRemaining && isMedicalCardType && (
              <RemainingBlock>
                <RemainingIndicator
                  showOnlyUsedMetrics={!selectedCopayCard?.benefitsRemaining}
                  usedValue={selectedCopayCard?.benefitsUsed}
                  prefix='$'
                  remainingValue={selectedCopayCard?.benefitsRemaining}
                  title='Benefits Remaining'
                  maximumAvailable={selectedCopayCard?.maxAvailableBenefit}
                />
              </RemainingBlock>
            )}
            {isFillsRemaining && isPharmacyCardType && (
              <RemainingBlock>
                <RemainingIndicator
                  showOnlyUsedMetrics={!selectedCopayCard?.remainingFills}
                  usedValue={selectedCopayCard?.fillsUsed}
                  remainingValue={selectedCopayCard?.remainingFills}
                  title='Uses Remaining'
                  maximumAvailable={selectedCopayCard?.totalFillsAllowed}
                />
              </RemainingBlock>
            )}
            {isBenefitsUsed && isMedicalCardType && (
              <RemainingBlock>
                <UsedIndicator
                  usedValue={selectedCopayCard?.benefitsUsed}
                  title='Benefits Used'
                  updatedDate={selectedCopayCard?.updatedAt}
                  // TODO(change): should be value => how much increased... after GO Live
                  maxAvailableValue={selectedCopayCard?.maxAvailableBenefit}
                  prefix='$'
                />
              </RemainingBlock>
            )}
            {isFillsUsed && isPharmacyCardType && (
              <RemainingBlock>
                <UsedIndicator
                  usedValue={selectedCopayCard?.fillsUsed}
                  title='Fills Used'
                  updatedDate={selectedCopayCard?.updatedAt}
                  // TODO(change): should be value => how much increased... after GO Live
                  maxAvailableValue={selectedCopayCard?.totalFillsAllowed}
                />
              </RemainingBlock>
            )}
          </RemainingBlocksWrapper>
          {(hasCopayRenewal || hasRefill) && (
            <CopayRenewalCardWrapper>
              {isMedicalCardType && (
                <CopayRenewalCard
                  hasCopayRenewal={hasCopayRenewal}
                  nextFillDate={selectedCopayCard?.copayRenewalDate}
                />
              )}
              {isPharmacyCardType && (
                <PrescriptionRefillCard
                  hasRefill={hasRefill}
                  prescriptionRefillDate={selectedCopayCard?.lastDispenseDate}
                />
              )}
            </CopayRenewalCardWrapper>
          )}
        </RenewalDetailsWrapper>
        {!!medicalPatientInstructions && isMedicalCardType && (
          <CopayInstructions patientInstructions={medicalPatientInstructions} />
        )}
        {!!pharmacyPatientInstructions && isPharmacyCardType && (
          <CopayInstructions patientInstructions={pharmacyPatientInstructions} />
        )}
        {!!medicalTermsAndConditions && isMedicalCardType && (
          <Pressable onPress={openModal} style={webStyles.button}>
            <LinkText>Terms and Conditions</LinkText>
          </Pressable>
        )}
        {!!pharmacyTermsAndConditions && isPharmacyCardType && (
          <Pressable onPress={openModal} style={webStyles.button}>
            <LinkText>Terms and Conditions</LinkText>
          </Pressable>
        )}
        {!!selectedCopayCard?.fileUrl && !!selectedCopayCard?.type && (
          <DeckWrapper>
            <CopayImageWrapper>
              <CopayStyledImage
                key={selectedCopayCard.uuid}
                source={{ uri: selectedCopayCard.fileUrl }}
                resizeMode='stretch'
                data-id={selectedCopayCard.uuid}
              />
            </CopayImageWrapper>
          </DeckWrapper>
        )}
      </FinancialAssistanceContainer>
    </Layout>
  );
};

export default CopayFinancialAssistance;
