import { createSelector } from 'reselect';

import { GeneralAppGlobalState } from 'store/reducers/global-state/types';

// Internal selectors
export const _getAppTheme = (state: GeneralAppGlobalState) => state.appGlobalState.appTheme;
//

export const getAppDataIsLoading = (state: GeneralAppGlobalState) => state.appGlobalState.appDataIsLoading;
export const getAppTheme = createSelector(_getAppTheme, (theme) => theme);
export const getHasModifications = (state: GeneralAppGlobalState) => state.appGlobalState.hasModifications;
