import { Option } from './types';

const xOptionsGenerator = (count: number) =>
  new Array(count)
    .fill(null)
    .map((_, index): Option => ({ id: index + 1, label: `${index + 1}`, value: `${index + 1}` }));

export const xHoursOptions = xOptionsGenerator(24);

export const xDaysOptions = xOptionsGenerator(365);

export const xWeeksOptions = xOptionsGenerator(52);
