import * as Linking from 'expo-linking';

import { Routes } from 'routes/main';

const prefix = Linking.createURL('/');

/***
  Testing deep linking ( in expo) 
  After you open your simulator/device you can run these commands to test deep linking.
  --- While you're testing it in your local ---
  For ios use this example command --> npx uri-scheme open exp://127.0.0.1:19000/--/benefits --ios
  For android -->  npx uri-scheme open exp://127.0.0.1:19000/--/financial-assistance --android
  --- While you're testing it on RELEASED APP ---
  For ios and android open your browser and type --> exp://exp.host/@zealicexpo/tc-mango-lite/--/benefits
  // Standalone app example
  For ios and anroid open your browser and type --> mangoapp://--/medicine-cabinet
*/

export const linking = {
  prefixes: ['https://txtbypl.com', prefix],
  config: {
    screens: {
      [Routes.APP_NAVIGATOR]: {
        path: '',
        screens: {
          [Routes.HOME]: Routes.HOME,
          [Routes.BENEFITS]: Routes.BENEFITS,
          [Routes.PROFILE]: Routes.PROFILE,
          [Routes.REBATE_CENTER]: Routes.REBATE_CENTER,
          [Routes.FINANCIAL_ASSISTANCE]: Routes.FINANCIAL_ASSISTANCE,
          [Routes.RESOURCES]: Routes.RESOURCES,
          [Routes.MEDICINE_CABINET]: {
            screens: {
              [Routes.LIST]: Routes.MED_LIST,
              [Routes.DETAILS]: `${Routes.MED_DETAILS}/:medicationId`,
              [Routes.INTERACTION_DETAILS]: Routes.MED_INTERACTION_DETAILS,
            },
          },
          [Routes.TRACKER]: Routes.TRACKER,
        },
      },
      [Routes.LOGIN]: Routes.LOGIN,
      [Routes.SET_PASSWORD]: Routes.SET_PASSWORD,
      [Routes.PRIVACY_POLICY]: Routes.PRIVACY_POLICY,
      [Routes.TERMS_CONDITIONS]: Routes.TERMS_CONDITIONS,
      [Routes.TWO_FACTOR_AUTHENTICATION]: Routes.TWO_FACTOR_AUTHENTICATION,
    },
  },
};
