import React, {
  ForwardRefRenderFunction,
  useImperativeHandle,
  forwardRef,
  useRef,
  useCallback,
  useEffect,
} from 'react';

import { ScrollViewProps } from 'react-native';
import styledWeb from 'styled-components';

import { AppScrollViewProps, AppScrollViewRef } from 'components/AppScrollView/types';
import { isScrolledDivToEnd } from 'components/AppScrollView/utils';
import { convertStyleSheetToStyled } from 'style/convertStyleSheetToStyled';
import { WebScrollElement } from 'style/global';

export const BottomView = styledWeb.div`
`;

const AppScrollView: ForwardRefRenderFunction<AppScrollViewRef, AppScrollViewProps> = (
  { children, onScrollEnd, testID, onScrollEndOneTime, style, ...rest },
  ref
) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const bottomViewRef = useRef<HTMLDivElement>(null);

  const isFirstEndScroll = useRef(false);

  // -> Events
  const scrollTo: AppScrollViewRef['scrollTo'] = ({ x, y, animated }) =>
    scrollRef.current?.scrollTo({ behavior: animated ? 'smooth' : 'auto', top: y, left: x });
  const scrollToStart: AppScrollViewRef['scrollToStart'] = ({ animated } = {}) =>
    scrollRef.current?.scrollTo({ behavior: animated ? 'smooth' : 'auto', top: 0, left: 0 });
  const scrollToEnd: AppScrollViewRef['scrollToEnd'] = ({ animated } = {}) =>
    bottomViewRef.current?.scrollIntoView({ behavior: animated ? 'smooth' : 'auto' });
  const reset: AppScrollViewRef['reset'] = () => {
    scrollToStart({ animated: false });
    isFirstEndScroll.current = false;
  };

  useImperativeHandle(ref, () => ({ scrollTo, scrollToStart, scrollToEnd, reset }));
  // <-

  const handleScroll = useCallback(() => {
    if (!scrollRef.current || !onScrollEnd) return;

    if (onScrollEndOneTime && isFirstEndScroll.current) return;

    if (isScrolledDivToEnd(scrollRef.current, rest.horizontal)) {
      isFirstEndScroll.current = true;
      onScrollEnd();
    }
  }, [onScrollEnd, onScrollEndOneTime, rest.horizontal]);
  // Need for onScrollEnd if we don't have a scroll area
  useEffect(handleScroll, [handleScroll]);

  return (
    <WebScrollElement
      onScroll={handleScroll}
      style={convertStyleSheetToStyled(style)}
      ref={scrollRef}
      data-testid={testID}
      {...(rest as Omit<AppScrollViewProps, keyof ScrollViewProps>)}>
      {children}
      <BottomView ref={bottomViewRef} />
    </WebScrollElement>
  );
};

/**
 * @description Remember, for web and native it is not same components
 * @description Web - div, Native - ScrollView.
 * @description That means many props will don't work on web.
 * @description And if you need additional functionality, you should implement it for web
 */
export default forwardRef(AppScrollView);
