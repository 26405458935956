import { CustomServiceType } from 'store/reducers/additional-services/types';
import { NormalizedConsentsSchema } from 'store/schemas/consents';

export enum ConsentModalTypeEnum {
  app = 'app',
  program = 'program',
  service = 'service',
}

export type ConsentType = 'PRIVACY_POLICY' | 'TERMS_AND_CONDITIONS';

export type CommunicationConsentStatusType = 'ACTIVE' | 'INACTIVE';

export type ConsentActionType = 'CHECKBOX';

export type AppConsent = {
  title: string;
  body: string;
  id: string;
  action_text: string;
  action_types: string;
  required_to_proceed: boolean;
  description: string;
  type: ConsentType;
  groupType?: ConsentModalTypeEnum.app;
  isViewed?: boolean;
  isGiven?: boolean;
  uuid: string;
};

export type ProgramConsent = {
  id: string;
  type: ConsentType;
  required_to_proceed: boolean;
  required_to_communicate: boolean;
  title: string;
  body: string;
  action_text: string;
  action_types: ConsentActionType;
  created_at: string;
  updated_at: string;
  program_id: number;
  description: string;
  groupType?: ConsentModalTypeEnum.program;
  isViewed?: boolean;
  isGiven?: boolean;
  uuid: string;
};

export type ServiceConsent = {
  id: string;
  required_to_proceed: boolean;
  required_to_communicate: boolean;
  title: string;
  description?: string;
  body: string;
  action_text: string;
  created_at: string;
  updated_at: string;
  resource_service: number;
  form_request_service: any; // Need to check available types
  groupType?: ConsentModalTypeEnum.service;
  isViewed?: boolean;
  isGiven?: boolean;
  customServiceType?: CustomServiceType;
  uuid: string;
};

export type CommunicationConsent = {
  cognitoId: string | null;
  id: number | null;
  uuid: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  consentCategoryUuid: string;
  consentCategoryName: string;
  manufactureProgramUuid: string | null;
  status: CommunicationConsentStatusType;
  expiresAt: string | null;
  statusUpdatedAt: string | null;
};

export type CommunicationConsents = CommunicationConsent[];

export type GetCommunicationConsents = {
  data: { results: CommunicationConsents };
};

export type ConsentModalsQueue = {
  id: string;
  groupType: ConsentModalTypeEnum;
  priority: number;
  customServiceType?: CustomServiceType;
}[];

export type ConsentModalsState = {
  app: NormalizedConsentsSchema<AppConsent>;
  program: NormalizedConsentsSchema<ProgramConsent>;
  service: NormalizedConsentsSchema<ServiceConsent>;
};
