import styled, { css } from 'styled-components/native';

export const InformationContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

export const BasicInfoBlock = styled.View<{ desktopWidth?: string }>`
  margin-bottom: 24px;
  width: ${({ desktopWidth }) => desktopWidth || '25%'};
  ${({ theme }) =>
    theme.isTablet &&
    css`
      width: 50%;
    `}
`;
