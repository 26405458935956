import { useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { setCurrentlyActiveConsent, removeCurrentlyActiveConsent } from 'store/actions';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import type {
  AppConsent,
  ProgramConsent,
  ServiceConsent,
  ConsentModalsState,
} from 'store/reducers/user-info/types/consent';
import { getConsentModalsQueue, getConsentsModalState } from 'store/selectors';

// Modal with the highest priority (highest number) will be shown first

// This hook pulls the current modals' queue from redux-store and
// makes visible one modal with the highest priority

const getConsentsToShow = (
  modalsQueue: {
    id: string;
    groupType: ConsentModalTypeEnum;
    priority: number;
  }[],
  modalGroupType: ConsentModalTypeEnum,
  consentsModalState: ConsentModalsState
): AppConsent | ProgramConsent | ServiceConsent | undefined => {
  if (modalsQueue?.length) {
    const consentsInModalsQueue = modalsQueue.filter((modal) => modal.groupType === modalGroupType);
    const consentsPriorities = consentsInModalsQueue.map((modal) => modal.priority);
    const consentsPriorityToShow = Math.max(...consentsPriorities);

    let consentToShow: AppConsent | ProgramConsent | ServiceConsent | undefined;

    for (const presentConsentId of consentsModalState[modalGroupType].result) {
      const selectedConsentInQueue = consentsInModalsQueue.find(
        (modal) => presentConsentId === modal.id && modal.groupType === modalGroupType
      );

      const currentConsent = consentsModalState[modalGroupType].entities.consents[presentConsentId];

      if (
        selectedConsentInQueue?.priority === consentsPriorityToShow &&
        (!currentConsent?.isGiven || currentConsent?.required_to_proceed)
      ) {
        consentToShow = consentsModalState[modalGroupType].entities.consents[presentConsentId];
        break;
      }
    }

    return consentToShow;
  }
};

const useConsentModalsQueue = () => {
  const dispatch = useDispatch();

  const modalsQueue = useSelector(getConsentModalsQueue);
  const consentsModalState = useSelector(getConsentsModalState);

  const appConsentToShow = useMemo(
    () => getConsentsToShow(modalsQueue, ConsentModalTypeEnum.app, consentsModalState),
    [modalsQueue, consentsModalState]
  );

  const programConsentToShow = useMemo(
    () => getConsentsToShow(modalsQueue, ConsentModalTypeEnum.program, consentsModalState),
    [modalsQueue, consentsModalState]
  );

  const serviceConsentToShow = useMemo(
    () => getConsentsToShow(modalsQueue, ConsentModalTypeEnum.service, consentsModalState),
    [modalsQueue, consentsModalState]
  );

  useEffect(() => {
    if (appConsentToShow) {
      dispatch(
        setCurrentlyActiveConsent({
          consent: appConsentToShow,
        })
      );
    }

    if (!appConsentToShow && programConsentToShow) {
      dispatch(
        setCurrentlyActiveConsent({
          consent: programConsentToShow,
        })
      );
    }

    if (!appConsentToShow && !programConsentToShow && serviceConsentToShow) {
      dispatch(
        setCurrentlyActiveConsent({
          consent: serviceConsentToShow,
        })
      );
    }

    if (!programConsentToShow && !appConsentToShow && !serviceConsentToShow) {
      dispatch(removeCurrentlyActiveConsent());
    }
  }, [appConsentToShow, programConsentToShow, serviceConsentToShow, dispatch]);

  return appConsentToShow || programConsentToShow || serviceConsentToShow;
};

export default useConsentModalsQueue;
