import { StyleSheet } from 'react-native';
import styled, { DefaultTheme } from 'styled-components/native';

export const getStyles = (theme: DefaultTheme) =>
  StyleSheet.create({
    noResultText: { marginTop: 24, fontSize: 16, textAlign: 'center', color: theme.colors.gray1, paddingBottom: 70 },
    itemStyle: { fontSize: 16, maxWidth: theme.isMobile ? 270 : 620 },
    severityInfoWrapper: { flexDirection: 'row', alignItems: 'center' },
    searchFormWrapper: { position: 'relative', marginBottom: 12 },
    searchIcon: { position: 'absolute', left: 16, top: 18 },
    textField: { paddingLeft: 38, width: '100%' },
    severityFilterItem: { flexDirection: 'row', alignItems: 'center', marginRight: theme.isMobile ? 0 : 45 },
    severityText: { color: theme.colors.gray2 },
    severityFiltersWrapper: {
      flexDirection: 'row',
      justifyContent: theme.isMobile ? 'space-between' : 'flex-start',
      marginBottom: 16,
    },
    showMoreStyle: { marginTop: 20 },
  });

export const ContentContainer = styled.View`
  display: flex;
  position: relative;
`;

export const ItemBlock = styled.Pressable`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.gray3};
  position: relative;
`;
