import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { ServiceActionItem, Modal } from 'components';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import DigitalResource from 'screens/main/AdditionalServices/components/ModalContent/DigitalResource';
import QuestionnaireModal from 'screens/main/AdditionalServices/components/ModalContent/QuestionnaireModal';
import { HomeClosableCard } from 'screens/main/Home/components/HomeClosableCard/HomeClosableCard';
import { setDemoAdditionalServicesState } from 'store/actions';
import { ServiceFileTypesEnum } from 'store/reducers/additional-services/types';
import { getDemoAdditionalServicesState } from 'store/selectors';

import { demoMockInjectionService, demoMockVideoService } from './demoMock';

export const DemoAdditionalServicesCard = () => {
  const { isMobile } = useDeviceInfoContext();

  const dispatch = useDispatch();
  const demoAdditionalServicesState = useSelector(getDemoAdditionalServicesState);

  // Injection Service
  const [isInjectionModalVisible, setInjectionModalVisible] = useState(false);
  const [isInjectionServiceDone, setInjectionServiceDone] = useState(false);
  // Video Service
  const [isVideoModalVisible, setVideoModalVisible] = useState(false);
  const [isVideoServiceDone, setVideoServiceDone] = useState(false);

  const isVisible = demoAdditionalServicesState === 'AFTER_FORWARD_BUTTON_PRESS';

  const onCloseHandler = () => dispatch(setDemoAdditionalServicesState('CLOSED'));

  return (
    <HomeClosableCard onClose={onCloseHandler} visible={isVisible} title='Preparing for your first injection'>
      {/* Injection demo additional service */}
      <>
        <ServiceActionItem
          {...demoMockInjectionService}
          onAction={() => setInjectionModalVisible(true)}
          done={isInjectionServiceDone}
        />
        <QuestionnaireModal
          // Demo values will be set directly inside QuestionnaireModal component
          selectedAction={undefined}
          selectedActionId={0}
          handleAction={() => setInjectionServiceDone(true)}
          setModalVisible={setInjectionModalVisible}
          modalVisible={isInjectionModalVisible}
        />
      </>
      {/* Video demo additional service */}
      <>
        <ServiceActionItem
          {...demoMockVideoService}
          onAction={() => setVideoModalVisible(true)}
          done={isVideoServiceDone}
        />
        <Modal
          fillScreen={isMobile}
          modalIsVisible={isVideoModalVisible}
          setModalVisible={setVideoModalVisible}
          removeCloseIcon
          disableScrollView={isMobile}>
          <DigitalResource
            resourceType={ServiceFileTypesEnum.VIDEO}
            modalTitle={demoMockVideoService.actionLabel}
            closeHandler={() => {
              setVideoServiceDone(true);
              setVideoModalVisible(false);
            }}
          />
        </Modal>
      </>
    </HomeClosableCard>
  );
};
