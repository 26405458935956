import * as ActionTypes from 'store/action-types';

export type SetIsForgotPasswordModalVisible = (
  payload: boolean,
  withForgotPasswordModalMessage?: string
) => {
  type: typeof ActionTypes.SET_IS_FORGOT_PASSWORD_MODAL_VISIBLE;
  payload: boolean;
  withForgotPasswordModalMessage?: string;
};

export const setIsForgotPasswordModalVisible: SetIsForgotPasswordModalVisible = (
  payload,
  withForgotPasswordModalMessage
) => ({
  type: ActionTypes.SET_IS_FORGOT_PASSWORD_MODAL_VISIBLE,
  payload,
  withForgotPasswordModalMessage,
});
