import React from 'react';

import { AppHTML } from 'components/AppHTML/AppHTML';
import { MedicineCabinetLayout } from 'screens/main/MedicineCabinet/layout';
import { termsInfo } from 'screens/serviceInfo/TermsAndConditions/termsInfo';

export const TermsAndConditions = () => {
  return (
    <MedicineCabinetLayout withDownloadCard={false}>
      <AppHTML source={{ html: termsInfo }} />
    </MedicineCabinetLayout>
  );
};
