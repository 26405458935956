import { useEffect } from 'react';

import { env } from 'config';
import { isNativeDevice } from 'utils/helpers';

const { firebaseMeasurementId } = env;

export const useAnalyticsScript = (isReady: boolean) => {
  useEffect(() => {
    if (!isReady || isNativeDevice) return;
    const script = document.createElement('script');
    script.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments)};
    gtag('js', new Date());\n
    gtag('config', '${firebaseMeasurementId}')`;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [isReady]);
};
