import { useCallback, useState } from 'react';

import { useToast } from 'react-native-toast-notifications';

import { InsuranceInterface } from 'api/Insurance';
import { UploadInsuranceImagesParams } from 'api/Insurance/types';
import { makeRequestWithLoader } from 'utils/requests';
import { AnyFunction } from 'utils/types';

export const getBase64SizeInMB = (img = '') => {
  const buffer = new Blob([img]);
  return buffer.size / 1e6;
};

const LIMIT = 10;

export const useUploadInsuranceImages = () => {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  const uploadImages = useCallback(
    ({ PHARMACY, MEDICAL }: UploadInsuranceImagesParams = {}, onSuccess?: AnyFunction) => {
      const hasPharmacyImages = Object.values(PHARMACY || {}).some(Boolean);
      const hasMedicalImages = Object.values(MEDICAL || {}).some(Boolean);
      if (!hasPharmacyImages && !hasMedicalImages) return;

      const totalSize =
        getBase64SizeInMB(PHARMACY?.front) +
        getBase64SizeInMB(PHARMACY?.back) +
        getBase64SizeInMB(MEDICAL?.front) +
        getBase64SizeInMB(MEDICAL?.back);

      if (totalSize > LIMIT) return toast.show('Image size must be less than 10MB', { type: 'danger' });

      const onError = () =>
        toast.show('We were unable to load your insurance card. Please try again.', { type: 'danger' });

      return makeRequestWithLoader(
        () =>
          InsuranceInterface.uploadInsuranceImages({
            ...(hasPharmacyImages && { PHARMACY: { front: PHARMACY?.front || '', back: PHARMACY?.back || '' } }),
            ...(hasMedicalImages && { MEDICAL: { front: MEDICAL?.front || '', back: MEDICAL?.back || '' } }),
          })
            .then(onSuccess)
            .catch(onError),
        setLoading
      );
    },
    [toast]
  );

  return {
    isLoading,
    uploadImages,
  };
};
