import { View, StyleProp, ViewStyle } from 'react-native';
import styled, { css } from 'styled-components/native';

export const mobileContentAreaStyle: StyleProp<ViewStyle> = {
  alignItems: 'center',
  height: 227,
  justifyContent: 'flex-start',
};

export const ProfileContainer = styled.View`
  flex-direction: row;
`;

export const ProfileContentWrapper = styled(View)`
  flex: 1;
  ${({ theme: { isMobile, isTablet } }) =>
    css`
      margin-top: ${isMobile ? 84 : isTablet ? 40 : 0}px;
    `}
`;

export const BadgeContainer = styled.View`
  position: relative;
  height: 100%;
  width: 250px;
  ${({ theme }) =>
    theme.isTablet &&
    css`
      width: 0;
    `}
`;

export const TabsWrapper = styled.View`
  flex-direction: row;
  margin-bottom: 26px;
`;
