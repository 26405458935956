export const INFO_TEXT = 'Time to take {0}';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const NOTIFICATION_TILE_SKIPPED = 'NOTIFICATION_TILE_SKIPPED';
const NOTIFICATION_TILE_ACKNOWLEDGED = 'NOTIFICATION_TILE_ACKNOWLEDGED';

export const medicationNotificationsAnalyticsActions = {
  NOTIFICATION_TILE_SKIPPED,
  NOTIFICATION_TILE_ACKNOWLEDGED,
};
