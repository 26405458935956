import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { format, parse } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { Text, TouchableOpacity, View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { MedicineCabinetInterface } from 'api';
import { AppScrollView, Button, DatePicker, TimePicker } from 'components';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import InputLabel from 'components/InputLabel';
import { ReminderTypesEnum } from 'components/ReminderCard/types';
import { recurringValuesForDynamicFields } from 'components/ReminderModal/Edit/constants';
import { recurringOptionsMapping } from 'components/ReminderModal/Edit/mappings';
import { RecurringValues } from 'components/ReminderModal/Edit/types';
import { validatePastTimeForReminder } from 'components/ReminderModal/helpers';
import SelectBox from 'components/SelectBox';
import { useTimer } from 'hooks';
import { setMedicationWizardStep, setReminders } from 'store/actions/medicineCabinet';
import {
  getMedicationSelectOptions,
  getMedicationWizardStep,
  getReminderTypes,
  getSelectedMedication,
  getSelectedReminder,
} from 'store/selectors';
import { DefaultText, StandardText } from 'style/common';
import { SOMETHING_WRONG_ERROR_MESSAGE } from 'utils/constants';
import { extractTimeFromDate } from 'utils/dateTimeHelpers';
import { throttleToastHide } from 'utils/helpers';
import { TEST_ID } from 'utils/testIds/constants';

import { medicationWizardAnalyticsActions } from '../constants';
import useWizardAnalytics from '../useWizardAnalytics';
import { reminderStepConstants, validationTexts } from './shared/constants';
import { reminderStepDefaultValues as defaultValues } from './shared/defaultValues';
import { getStepStyles } from './shared/styles';
import { ReminderFormValues } from './shared/types';

const { REMINDER_CREATION_SKIPPED, REMINDER_CREATION_STARTED, REMINDER_CREATION_FINISHED, REMINDER_CREATION_FAILED } =
  medicationWizardAnalyticsActions;

export const ReminderStep = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const dispatch = useDispatch();
  const selectedReminder = useSelector(getSelectedReminder);
  const selectedMedication = useSelector(getSelectedMedication);
  const reminderTypes = useSelector(getReminderTypes);
  const medicationOptions = useSelector(getMedicationSelectOptions);
  const wizardStep = useSelector(getMedicationWizardStep);
  const theme = useTheme();
  const toastRef: MutableRefObject<ToastContainer | null> = useRef(null);
  const { start, pause } = useTimer();
  const analyticsHandler = useWizardAnalytics();

  useEffect(() => {
    start();
    analyticsHandler(REMINDER_CREATION_STARTED);
  }, [start, analyticsHandler]);

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<ReminderFormValues>({
    defaultValues: selectedReminder
      ? {
          ...selectedReminder,
          start_date: parse(selectedReminder.start_date, 'dd-MM-yyyy', new Date()),
        }
      : defaultValues,
    mode: 'onChange',
  });

  const isEditMode = !!selectedReminder?.id;
  const recurringValue = watch('recurring') as RecurringValues;
  const startDate = watch('start_date');
  const reminderOptions = reminderTypes.filter((type) => type.id !== ReminderTypesEnum.AS_NEEDED);

  const isDynamicRecurringValue = recurringValue
    ? recurringValuesForDynamicFields.indexOf(recurringValue as RecurringValues) > -1
    : true;
  const dynamicOptions = recurringOptionsMapping[recurringValue as RecurringValues] || [];

  const dynamicFieldName = (recurringValue?.toLowerCase?.() || '') as keyof ReminderFormValues;

  useEffect(() => {
    if (selectedMedication?.id) {
      setValue('medicationId', selectedMedication?.id?.toString?.());
    }
  }, [selectedMedication?.id, setValue]);

  // this effect triggers time_of_day field validation when start_date is changed
  useEffect(() => {
    if (!startDate) return;
    trigger('time_of_day');
  }, [startDate, trigger]);

  const { title, description, buttonText } = isEditMode ? reminderStepConstants.edit : reminderStepConstants.add;
  const isAsNeededOptionSelected = recurringValue === 'AS_NEEDED';
  const isOneTimeOptionSelected = recurringValue === 'ONE_TIME';

  const styles = getStepStyles(theme, isAsNeededOptionSelected);

  const submitHandler = async (values: ReminderFormValues) => {
    const isTimeValueString = typeof values.time_of_day === 'string' && !!values.time_of_day;
    const data = {
      ...(!isEditMode && { user_datetime: new Date().toISOString() }),
      medication_id: Number(values.medicationId),
      type: values.recurring,
      time_of_day: isTimeValueString
        ? values.time_of_day
        : extractTimeFromDate((values.time_of_day || new Date()) as Date),
      every_x_hours: recurringValue === 'EVERY_X_HOURS' ? Number(values.every_x_hours) : 0,
      every_x_weeks: recurringValue === 'EVERY_X_WEEKS' ? Number(values.every_x_weeks) : 0,
      every_x_days: recurringValue === 'EVERY_X_DAYS' ? Number(values.every_x_days) : 0,
      ...(selectedReminder?.id && { id: selectedReminder?.id }),
      start_date: format(values.start_date, 'dd-MM-yyyy'),
    };
    setSubmitDisabled(true);
    try {
      if (selectedReminder?.id) {
        await MedicineCabinetInterface.updateReminder(data);
      } else {
        await MedicineCabinetInterface.createReminder(data);
      }
      MedicineCabinetInterface.getReminders().then((resp) => {
        dispatch(setReminders(resp.data.results));
      });
      const passedTime = pause();
      analyticsHandler(REMINDER_CREATION_FINISHED, { event_label: passedTime });
    } catch (e: any) {
      const passedTime = pause();
      analyticsHandler(REMINDER_CREATION_FAILED, { event_label: passedTime });
      toastRef.current?.show(e?.data?.message || SOMETHING_WRONG_ERROR_MESSAGE, { type: 'danger' });
    }
    setSubmitDisabled(false);
    dispatch(setMedicationWizardStep(wizardStep + 1));
    throttleToastHide(toastRef.current);
  };

  const handleSkip = () => {
    dispatch(setMedicationWizardStep(wizardStep + 1));
    const passedTime = pause();
    analyticsHandler(REMINDER_CREATION_SKIPPED, { event_label: passedTime });
  };

  const asNeededToggleHandler = () => {
    setValue('recurring', isAsNeededOptionSelected ? '' : 'AS_NEEDED');
  };

  return (
    <View>
      <Text style={styles.headerText}>{title}</Text>
      <StandardText style={styles.descriptionText}>{description}</StandardText>
      <AppScrollView nestedScrollEnabled style={styles.reminderScrollView} VHHeight={40} leftScrollOffset={12}>
        <View style={styles.blockContainer}>
          <Controller
            control={control}
            name='medicationId'
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <SelectBox
                disabled
                value={value}
                testID={TEST_ID.ADD_NEW_MEDICATION_NAME}
                labelText='Medication'
                items={medicationOptions}
                onValueChange={onChange}
              />
            )}
          />
          {!!errors.medicationId && (
            <DefaultText style={styles.negative}>{validationTexts.validationMessage}</DefaultText>
          )}
        </View>
        {!isAsNeededOptionSelected && (
          <>
            <InputLabel labelText={isOneTimeOptionSelected ? 'Take...' : 'Take every...'} />
            <View style={styles.dynamicFormItem}>
              <View style={styles.recurringFieldWrapper}>
                <Controller
                  control={control}
                  name='recurring'
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <SelectBox
                      value={value}
                      labelText=''
                      testID={TEST_ID.ADD_NEW_MEDICATION_TAKE_EVERY_TYPE}
                      items={reminderOptions}
                      shouldShowLabel={false}
                      onValueChange={onChange}
                    />
                  )}
                />
                {!!errors.recurring && (
                  <DefaultText style={styles.negative}>{validationTexts.validationMessage}</DefaultText>
                )}
              </View>
              {isDynamicRecurringValue && (
                <View style={styles.hourFieldWrapper}>
                  <Controller
                    control={control}
                    name={dynamicFieldName}
                    rules={{ required: !!dynamicFieldName }}
                    render={({ field: { value, onChange } }) => (
                      <SelectBox
                        value={value}
                        labelText=''
                        shouldShowLabel={false}
                        items={dynamicOptions}
                        onValueChange={onChange}
                        testID={TEST_ID.ADD_NEW_MEDICATION_TAKE_EVERY_VALUE}
                        placeHolderText='#'
                        disabled={!isDynamicRecurringValue}
                      />
                    )}
                  />
                  {!!errors[dynamicFieldName] && (
                    <DefaultText style={styles.negative}>{validationTexts.validationMessage}</DefaultText>
                  )}
                </View>
              )}
            </View>
          </>
        )}
        <View style={styles.blockContainer}>
          <CheckboxWithLabel
            testID={TEST_ID.ADD_NEW_MEDICATION_AS_NEEDED_CHECKBOX}
            text='This is taken As-Needed'
            isConfirmed={isAsNeededOptionSelected}
            handleConfirmChange={asNeededToggleHandler}
          />
        </View>
        {!isAsNeededOptionSelected && (
          <View style={styles.blockContainer}>
            <Controller
              control={control}
              name='time_of_day'
              rules={{
                validate: (value: string | Date) =>
                  !validatePastTimeForReminder(startDate, value) ||
                  'Please select the current time or a time in the future',
              }}
              render={({ field: { value, onChange } }) => (
                <TimePicker
                  hoursTestID={TEST_ID.ADD_NEW_MEDICATION_HOURS_TIME}
                  minutesTestID={TEST_ID.ADD_NEW_MEDICATION_MINUTES_TIME}
                  meridiemTestID={TEST_ID.ADD_NEW_MEDICATION_MERIDIEM_TIME}
                  onChange={onChange}
                  value={value}
                  labelText={'Set a Time'}
                />
              )}
            />
            {!!errors.time_of_day && <DefaultText style={styles.negative}>{errors.time_of_day.message}</DefaultText>}
          </View>
        )}
        {isAsNeededOptionSelected && (
          <StandardText style={styles.asNeededMedicationDescription}>
            Record when you have taken this medication in your Medication Tracker
          </StandardText>
        )}
        {!isAsNeededOptionSelected && (
          <View style={styles.blockContainer}>
            <Controller
              control={control}
              name='start_date'
              defaultValue={new Date()}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  testID={TEST_ID.ADD_NEW_MEDICATION_START_DATE}
                  onChange={onChange}
                  value={value}
                  labelText='Start Date'
                  minDate={new Date()}
                />
              )}
            />
          </View>
        )}
      </AppScrollView>
      <View style={[styles.buttonsContainer]}>
        <Button
          style={[styles.button, { width: 100 }]}
          variant='mango'
          text={buttonText}
          onPress={handleSubmit(submitHandler)}
          disabled={submitDisabled}
        />
      </View>
      <TouchableOpacity style={styles.skipForNowWrapper} onPress={handleSkip}>
        <DefaultText style={styles.skipText}>Skip for now</DefaultText>
      </TouchableOpacity>
    </View>
  );
};

export default ReminderStep;
