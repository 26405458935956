import React, { useEffect } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { Button, AppScrollView } from 'components';
import CheckboxWithLabel from 'components/CheckboxWithLabel';
import InputLabel from 'components/InputLabel';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { ReminderTypesEnum } from 'store/reducers/medicine-cabinet/types';
import {
  getMedicationSelectOptions,
  getReminderTypes,
  getSelectedMedicationId,
  getSelectedMedicationReminders,
} from 'store/selectors';
import { DefaultText, StandardText } from 'style/common';

import { recurringValuesForDynamicFields, getTexts } from './constants';
import FieldBlockComponent from './FieldBlock';
import { getDynamicFields, getFieldsList } from './FieldsList';
import { recurringOptionsMapping } from './mappings';
import { getStyles, sharedStyles } from './styles';
import { ReminderEditProps, ReminderFields } from './types';

export const ReminderEdit = ({
  isEditMode,
  openDeleteModal,
  submitIsDisabled,
  isHomeScreen,
  recurringValue,
  handleSubmit,
}: ReminderEditProps) => {
  const { isMobile } = useDeviceInfoContext();
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
  } = useFormContext<ReminderFields>();

  const medicationReminders = useSelector(getSelectedMedicationReminders);
  const reminderTypes = useSelector(getReminderTypes);
  const medicationOptions = useSelector(getMedicationSelectOptions);
  const selectedMedicationId = useSelector(getSelectedMedicationId);
  const startDate = useWatch({ name: 'start_date', control });

  // this effect triggers time_of_day field validation when start_date is changed
  useEffect(() => {
    if (!startDate) return;
    trigger('time_of_day');
  }, [startDate, trigger]);

  const hasAsNeededReminderOnMedicationReminders = medicationReminders.some(
    (reminder) => reminder.type === ReminderTypesEnum.AS_NEEDED
  );
  const isAsNeededOptionSelected = recurringValue === 'AS_NEEDED';
  const isOneTimeOptionSelected = recurringValue === 'ONE_TIME';
  const showAsNeededCheckbox = !hasAsNeededReminderOnMedicationReminders || isAsNeededOptionSelected;
  const reminderOptions = reminderTypes.filter((type) => type.id !== ReminderTypesEnum.AS_NEEDED);
  const styles = getStyles(isMobile, showAsNeededCheckbox);

  const isDynamicRecurringValue = recurringValue ? recurringValuesForDynamicFields.indexOf(recurringValue) > -1 : true;
  const dynamicOptions = recurringOptionsMapping[recurringValue] || [];
  const dynamicRecurringFieldName = recurringValue?.toLowerCase?.() as keyof ReminderFields;

  const medicationDisabled = isEditMode || !!selectedMedicationId;

  const TEXTS = getTexts(isEditMode);
  const { medicationField, setTimeField, startDateField } = getFieldsList({
    medicationOptions,
    medicationDisabled,
    isAsNeededOptionSelected,
    startDate,
  });

  const dynamicFields = getDynamicFields({
    reminderOptions,
    isDynamicRecurringValue,
    dynamicOptions,
    recurringFieldName: dynamicRecurringFieldName,
  });

  const asNeededToggleHandler = () => setValue('recurring', isAsNeededOptionSelected ? '' : 'AS_NEEDED');

  return (
    <View style={styles.wrapper}>
      <DefaultText style={styles.reminderTitle}>{TEXTS.REMINDER_TITLE}</DefaultText>
      <DefaultText style={styles.reminderDescription}>{TEXTS.REMINDER_DESCRIPTION}</DefaultText>
      <AppScrollView nestedScrollEnabled style={styles.reminderScrollView} VHHeight={40} leftScrollOffset={12}>
        <View style={styles.formWrapper}>
          <View style={sharedStyles.formItem}>
            <FieldBlockComponent
              isHomeScreen={isHomeScreen}
              {...medicationField}
              control={control}
              error={errors[medicationField.fieldName]}
            />
          </View>
          {!isAsNeededOptionSelected && (
            <>
              <InputLabel labelText={isOneTimeOptionSelected ? 'Take...' : 'Take every...'} />
              <View style={sharedStyles.dynamicFormItem}>
                {dynamicFields.map((field) => (
                  <FieldBlockComponent
                    key={field.fieldName}
                    isHomeScreen={isHomeScreen}
                    {...field}
                    control={control}
                    error={errors[field.fieldName]}
                  />
                ))}
              </View>
            </>
          )}
          {showAsNeededCheckbox && (
            <View style={sharedStyles.formItem}>
              <CheckboxWithLabel
                text='This is taken As-Needed'
                isConfirmed={isAsNeededOptionSelected}
                handleConfirmChange={asNeededToggleHandler}
              />
            </View>
          )}
          <View style={[sharedStyles.formItem, isAsNeededOptionSelected && sharedStyles.mb0]}>
            <FieldBlockComponent
              isHomeScreen={isHomeScreen}
              {...setTimeField}
              control={control}
              error={errors[setTimeField.fieldName]}
            />
          </View>
          {isAsNeededOptionSelected && (
            <StandardText style={styles.asNeededMedicationDescription}>
              Record when you have taken this medication in your Medication Tracker
            </StandardText>
          )}
          {!isAsNeededOptionSelected && (
            <View style={sharedStyles.formItem}>
              <FieldBlockComponent
                isHomeScreen={isHomeScreen}
                {...startDateField}
                control={control}
                error={errors[startDateField.fieldName]}
                defaultValue={new Date()}
              />
            </View>
          )}
        </View>
      </AppScrollView>
      <View style={styles.buttonWrapper}>
        {isEditMode && !isHomeScreen && (
          <Button variant='tertiary' text='Delete' style={styles.deleteButton} onPress={openDeleteModal} />
        )}
        <Button
          onPress={handleSubmit}
          variant='mango'
          disabled={submitIsDisabled}
          style={styles.mangoButton}
          text={TEXTS.CONFIRM_TEXT}
        />
      </View>
    </View>
  );
};

export default ReminderEdit;
