import React from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';

import { ErrorBox } from 'components/ErrorBox';
import TextFieldWithLabel from 'components/TextFieldWithLabel';
import { AvailableInputFields, DefaultInputs } from 'screens/main/Profile/sections/ContactInformation/types';

import { InputBlock } from '../ChangeShippingAddress/styles';
import { Rules } from '../ChangeShippingAddress/types';

interface InputBlockProps {
  fieldName: AvailableInputFields;
  labelText: string;
  defaultValue: string;
  rules?: Rules;
  control: Control<DefaultInputs, Record<any, any>>;
  error?: FieldError;
}

export const InputBlockComponent = ({ fieldName, labelText, defaultValue, rules, control }: InputBlockProps) => (
  <InputBlock>
    <Controller
      name={fieldName}
      defaultValue={defaultValue}
      rules={rules}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <>
          <TextFieldWithLabel labelText={labelText} onBlur={onBlur} onChangeText={onChange} value={value} />
          {!!error?.message && <ErrorBox message={error.message} />}
        </>
      )}
    />
  </InputBlock>
);

export default InputBlockComponent;
