import React from 'react';

import Svg, { SvgProps, Defs, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Ointment = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 113.4 112.3' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='m12.91 21.66 1.4 1.39s-5.31 6.1-3.56 8.13 55.42 66.25 67.42 74.43a6.79 6.79 0 0 1 2.55 3.39c.42 1.27 3.92 6.14 7.2 1.06s15.46-21.71 15.46-21.71l10-13.7s-4.39-4.39-6.28-2.51c-2.1-1.7-62.7-55.23-73.36-59.62-.84-.42-4.18-3.55-6.9-.83a10.71 10.71 0 0 1-2.51 2.09l-2.09-1 .42-2.14L11.33.77s-1.74-2-4.6.44a60.53 60.53 0 0 0-5.65 5.64s-2.43 1.91 0 4.6 10.08 10.69 10.08 10.69Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M107.12 72.11 80.72 109c.42 1.27 3.92 6.14 7.2 1.06s15.46-21.71 15.46-21.71l10-13.7s-4.38-4.42-6.26-2.54Z'
        />
        <Path
          d='M32.82 57.1c8.93 10.34 19.14 22 27.82 31.36 9.37 9.61 16.9 16.67 17.53 17.15a4.86 4.86 0 0 1 2 2.25c.09.17.17.34.25.51.17.36.26.6.26.6l14.26-19.86C55.16 90.57 38.36 58.72 32.82 57.1Z'
          fillOpacity={0.1}
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M22 22.06a66 66 0 0 0 8.18-11.37 3.42 3.42 0 0 0-3.34 1 10.71 10.71 0 0 1-2.51 2.09c-5.46 2.33-10 9.27-10 9.27s-5.33 6.1-3.58 8.13A66.91 66.91 0 0 0 22 22.06ZM74.59 44.13c-17-14.39-35.42-29.37-40.85-31.61a20.68 20.68 0 0 0-2.43-1.45 32.56 32.56 0 0 1-7.6 12.35c3.62-.16 27.14.41 49.79 19.79ZM18.54 13a20.37 20.37 0 0 1 4.12-2.41L11.33.77s-1.74-2-4.6.44L18.53 13Z'
        />
        <Path d='M11.16 22.14s3.45-8.06 11.5-11.55l-.1.48A23.81 23.81 0 0 0 11.8 22Z' fillOpacity={0.2} />
      </G>
    </G>
  </Svg>
);

export default Ointment;
