import { StyleSheet } from 'react-native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';

export const usePreferencesStyles = () => {
  const { isMobile } = useDeviceInfoContext();

  return StyleSheet.create({
    wrapperStyle: { marginTop: isMobile ? 0 : 12 },
    infoContainer: { width: '100%' },
    infoBlock: { width: isMobile ? '50%' : '25%' },
    increaseWidth: { width: isMobile ? '100%' : '50%' },
    lastItemWithoutMargin: { marginBottom: 0 },
  });
};
