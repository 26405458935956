import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';

import { screenTitles } from 'components/Drawer/useGetAppScreens';
import { ServiceInfoParamList } from 'components/Navigation/types';
import { Routes } from 'routes/main';
import { PrivacyPolicy } from 'screens/serviceInfo/PrivacyPolicy';
import { TermsAndConditions } from 'screens/serviceInfo/TermsAndConditions';

const Stack = createStackNavigator<ServiceInfoParamList>();

export const SERVICE_INFO_GROUPS = (
  <Stack.Group>
    <Stack.Screen
      name={Routes.PRIVACY_POLICY}
      component={PrivacyPolicy}
      options={{ title: screenTitles[Routes.PRIVACY_POLICY] }}
    />
    <Stack.Screen
      name={Routes.TERMS_CONDITIONS}
      component={TermsAndConditions}
      options={{ title: screenTitles[Routes.TERMS_CONDITIONS] }}
    />
  </Stack.Group>
);
