import React from 'react';

import { format } from 'date-fns';
import { View } from 'react-native';

import { DefaultText, SubTitle } from 'style/common';

import { IndicatorWrapper, useIndicatorStyles } from './styles';
import { UsedIndicatorProps } from './types';

export const UsedIndicator = ({
  title,
  usedValue = 0,
  prefix = '',
  updatedDate,
  maxAvailableValue,
}: UsedIndicatorProps) => {
  const styles = useIndicatorStyles();

  const formattedDate = updatedDate && format(new Date(updatedDate), 'MMMM d, Y');

  return (
    <IndicatorWrapper>
      <SubTitle style={styles.subTitle}>{title}</SubTitle>
      <DefaultText style={styles.standardText}>Updated: {formattedDate}</DefaultText>
      <View style={styles.indicesWrapper}>
        <DefaultText style={styles.usedValueText}>{`${prefix}${usedValue || 0}`}</DefaultText>
        {/* TODO(add): will add this functionality after GO Live PL App
        <View style={styles.profitValueWrapper}>
          <View style={styles.arrowStyle} />
          <DefaultText style={[styles.standardText, { marginLeft: 6 }]}>
            {`+${prefix}${maxAvailableValue || 0 - usedValue}`}
          </DefaultText>
        </View>*/}
      </View>
    </IndicatorWrapper>
  );
};

export default UsedIndicator;
