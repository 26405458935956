import React, { useRef } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation } from '@react-navigation/native';
import { Animated, Pressable, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SwiperRef } from '@types';
import { Bell, Delete, Ellipse, GoToArrow, Info } from 'assets/icons';
import SwipeableRightAction from 'components/SwipeableRightAction';
import SwipeableWrapper from 'components/SwipeableWrapper';
import { useDemonstrateSwiperAnimation } from 'components/SwipeableWrapper/useDemonstrateSwiperAnimation';
import { useSwitchProgramHandler } from 'hooks';
import { Routes } from 'routes/main';
import { setSelectedMedicationId, setSelectedTab } from 'store/actions';
import { setShowedMedicationItemDemoAnimation } from 'store/actions/demoConfig';
import { Medication, TabTypesEnum } from 'store/reducers/medicine-cabinet/types';
import { getMedicationRemindersById } from 'store/selectors';
import { getShowedMedicationItemDemoAnimation } from 'store/selectors/demoConfig';
import { DefaultText, StandardText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';
import { sleep } from 'utils/helpers';
import { TEST_ID } from 'utils/testIds/constants';

import { medicationTypeIconMapping } from '../MedicationIconsHelpers/mappings';
import { MedTypeEnum } from '../MedicationIconsHelpers/types';
import { useProgramMedicationStyles } from './styles';

export type MedicationComponentProps = {
  primaryColor?: string;
  programUuid?: string;
  medication?: Medication;
  onDeleteMedicationPress?: (medicationId: number) => void;
  isFirst?: boolean;
};

export const MedicationComponent = ({
  primaryColor,
  programUuid,
  medication,
  onDeleteMedicationPress,
  isFirst,
}: MedicationComponentProps) => {
  const dispatch = useDispatch();

  const swiperRef: SwiperRef = useRef(null);

  const showedMedicationItemDemoAnimation = useSelector(getShowedMedicationItemDemoAnimation);

  useDemonstrateSwiperAnimation({
    enabled: isFirst && !showedMedicationItemDemoAnimation,
    swiperRef,
    onAnimationDone: () => dispatch(setShowedMedicationItemDemoAnimation(true)),
  });

  const navigation = useNavigation<DrawerNavigationHelpers>();
  const switchHandler = useSwitchProgramHandler();
  const medicationReminders = useSelector(getMedicationRemindersById(medication?.id));

  const medicationType = medication ? medication?.type : MedTypeEnum.TABLET;
  const MedicationIcon = medicationTypeIconMapping[medicationType];
  const isProgramMedication = !!medication?.sponsored_program_uuid;

  const shouldRenderInformationIcon = !!medication?.details_file_url;

  const { styles, colors } = useStylesWithAdditional(useProgramMedicationStyles, primaryColor);

  const handleGoToPSP = async () => {
    if (!programUuid) return;
    await switchHandler(programUuid);
    navigation.navigate(Routes.HOME);
    await sleep(150);
    swiperRef.current?.close();
  };

  const handleRedirect = (tab: TabTypesEnum) => async () => {
    dispatch(setSelectedTab(tab));
    if (medication?.id) {
      const reminderId = (medicationReminders?.length && medicationReminders[0].id) || null;
      navigation.navigate(Routes.MEDICINE_CABINET, {
        screen: Routes.DETAILS,
        params: {
          medicationId: medication.id,
          ...(reminderId && { reminderId }),
        },
      });
      dispatch(setSelectedMedicationId(medication.id));
      await sleep(150);
      swiperRef.current?.close();
    }
  };

  const handleDelete = () => {
    if (medication?.id) {
      onDeleteMedicationPress?.(medication.id);
    }
  };

  const renderQuantityInfo = () => {
    const medicationQuantity = medication?.quantity;

    const textJSX = (
      <StandardText numberOfLines={1} style={styles.displayQuantityText}>
        {medicationQuantity ? `${medicationQuantity} Remaining` : 'Update Quantity'}
      </StandardText>
    );

    return (
      <View style={styles.quantityInfoWrapper}>
        {!medicationQuantity && <Ellipse style={{ marginRight: 10 }} fill={colors.negative} />}
        {textJSX}
      </View>
    );
  };

  const renderRemindersBadge = (isSwipeBadge: boolean) => {
    if (!medicationReminders?.length) return null;

    return (
      <View style={[styles.badgeWrapper, isSwipeBadge && styles.swipeBadgeStyles]}>
        <DefaultText style={styles.badgeText}>{medicationReminders.length}</DefaultText>
      </View>
    );
  };

  const renderRightActions = (progress: Animated.AnimatedInterpolation) => (
    <View style={styles.swipeableRightActionsWrapper}>
      {programUuid && (
        <SwipeableRightAction
          x={360}
          height={112}
          progress={progress}
          Icon={() => <GoToArrow stroke={colors.white} />}
          onPress={handleGoToPSP}
          bgColor={colors.secondary}
        />
      )}
      <SwipeableRightAction
        x={216}
        height={112}
        progress={progress}
        Icon={() => (
          <View style={{ position: 'relative' }}>
            <Bell />
            {renderRemindersBadge(true)}
          </View>
        )}
        onPress={handleRedirect(TabTypesEnum.ABOUT_YOUR_DOSE)}
        bgColor={colors.gray3}
      />
      {shouldRenderInformationIcon && (
        <SwipeableRightAction
          x={216}
          height={112}
          progress={progress}
          Icon={() => <Info width={24} height={24} strokeWidth={1} fill={colors.white} />}
          onPress={handleRedirect(TabTypesEnum.DETAILS)}
          bgColor={colors.mangoSecondary}
        />
      )}
      <SwipeableRightAction
        x={72}
        height={112}
        progress={progress}
        Icon={() => <Delete testID={TEST_ID.MEDICATION_ELEMENT_DELETE_BUTTON} stroke={colors.white} />}
        onPress={handleDelete}
        bgColor={colors.negative}
      />
    </View>
  );

  return (
    <SwipeableWrapper swiperRef={swiperRef} renderRightActions={renderRightActions}>
      <Pressable onPress={handleRedirect(TabTypesEnum.ABOUT_YOUR_DOSE)} style={styles.medicationWrapper}>
        <View style={styles.titleWrapper}>
          <View>
            {/* We need set undefined for android. Don't remove it!*/}
            <MedicationIcon color={isProgramMedication ? colors.secondary : undefined} />
          </View>
          <View style={styles.medicationNameAndRemindingTimeWrapper}>
            <View style={styles.mgInfoAndTitleWrapper}>
              <StandardText numberOfLines={1} style={styles.displayNameText}>
                {medication?.name}
              </StandardText>
              {renderQuantityInfo()}
            </View>
            <View style={styles.remindingTimeWrapper}>
              <DefaultText style={styles.reminderTimeText}>{medication?.dosageAmount}</DefaultText>
            </View>
          </View>
        </View>
        <View style={styles.iconsWrapper}>
          {programUuid && (
            <TouchableOpacity onPress={handleGoToPSP} style={styles.touchableArea}>
              <GoToArrow size='small' />
            </TouchableOpacity>
          )}
          <TouchableOpacity onPress={handleRedirect(TabTypesEnum.ABOUT_YOUR_DOSE)} style={styles.touchableArea}>
            <Bell size='small' />
            {renderRemindersBadge(false)}
          </TouchableOpacity>
          {shouldRenderInformationIcon && (
            <TouchableOpacity style={styles.touchableArea} onPress={handleRedirect(TabTypesEnum.DETAILS)}>
              <Info width={16} height={16} fill={colors.gray1} />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            testID={TEST_ID.MEDICATION_ELEMENT_DELETE_BUTTON}
            onPress={handleDelete}
            style={styles.touchableArea}>
            <Delete size='small' />
          </TouchableOpacity>
        </View>
      </Pressable>
    </SwipeableWrapper>
  );
};
