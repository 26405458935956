import { useSelector } from 'react-redux';

import { ProgramConfigItem, ProgramConfigPages } from 'store/reducers/user-info/types/program';
import { getSelectedProgramConfig } from 'store/selectors/user';

type HookReturnField<T extends ProgramConfigPages> = `${T}PageAvailable`;

type HookReturnType<T extends ProgramConfigPages> = {
  [key in HookReturnField<T>]: boolean;
} & { pageConfig: ProgramConfigItem };

export const usePageAvailable = <T extends ProgramConfigPages>(tabName: T): HookReturnType<T> => {
  const programConfig = useSelector(getSelectedProgramConfig);

  const pageConfig = programConfig?.[tabName];

  const field: HookReturnField<T> = `${tabName}PageAvailable`;

  return {
    [field]: !!pageConfig?.page,
    pageConfig,
  } as HookReturnType<T>;
};
