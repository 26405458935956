import React, { useEffect } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { Button, AppFlatList } from 'components';
import { Routes } from 'routes/main';
import { INTERACTION_ITEM_HEIGHT } from 'screens/main/MedicineCabinet/components/MedicationWizard/Steps/components/styles';
import { setMedicationWizardVisibility, setSelectedInteractionId } from 'store/actions/medicineCabinet';
import { getDrugInteractions, getSelectedMedication } from 'store/selectors';
import { StandardText } from 'style/common';
import { TEST_ID } from 'utils/testIds/constants';

import { medicationWizardAnalyticsActions } from '../constants';
import useWizardAnalytics from '../useWizardAnalytics';
import InteractionItem from './components/InteractionItem';
import { interactionStepConstants } from './shared/constants';
import { getStepStyles } from './shared/styles';

const { VIEW_INTERACTION_DETAILS_FROM_SEQUENCE, MEDICATION_INTERACTIONS_STEP_VIEWED } =
  medicationWizardAnalyticsActions;

export const InteractionStep = () => {
  const dispatch = useDispatch();
  const drugInteractions = useSelector(getDrugInteractions);
  const selectedMedication = useSelector(getSelectedMedication);
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const analyticsHandler = useWizardAnalytics();

  const theme = useTheme();
  const styles = getStepStyles(theme);
  const hasConflict = !!drugInteractions?.length;

  useEffect(() => {
    analyticsHandler(MEDICATION_INTERACTIONS_STEP_VIEWED);
  }, [analyticsHandler]);

  const { title, description, buttonText, buttonStyle } = hasConflict
    ? interactionStepConstants.conflict
    : interactionStepConstants.complete;

  const handleCloseModal = () => {
    dispatch(setMedicationWizardVisibility(false));
  };

  const handleNavigateToInteractionDetails = (interactionId: number) => {
    dispatch(setMedicationWizardVisibility(false));
    dispatch(setSelectedInteractionId(interactionId));
    analyticsHandler(VIEW_INTERACTION_DETAILS_FROM_SEQUENCE);
    navigation.navigate(Routes.INTERACTION_DETAILS, {
      medicationId: selectedMedication?.id,
      interactionId,
      drugId: selectedMedication?.drugId,
    });
  };

  return (
    <View>
      <Text style={styles.headerText}>{title}</Text>
      <StandardText style={styles.descriptionText}>{description}</StandardText>
      {hasConflict ? (
        <AppFlatList
          style={styles.interactions}
          data={drugInteractions}
          nestedScrollEnabled
          getItemLayout={(_, index) => ({
            index,
            length: INTERACTION_ITEM_HEIGHT,
            offset: INTERACTION_ITEM_HEIGHT * index,
          })}
          keyExtractor={({ interacted_with: { id } }) => `${id}`}
          renderItem={({ item }) => (
            <InteractionItem
              interactionName={item.interacted_with.trade_name}
              severity={item.information.severity}
              onPress={() => handleNavigateToInteractionDetails(item.interacted_with.id)}
            />
          )}
        />
      ) : null}
      <Button
        testID={TEST_ID.ADD_NEW_MEDICATION_LAST_STEP_CLOSE_BUTTON}
        text={buttonText}
        style={[styles.button, buttonStyle]}
        onPress={handleCloseModal}
      />
    </View>
  );
};

export default InteractionStep;
