import React from 'react';

import { TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  InsuranceInformationSection,
  InsuranceInformationSectionProps,
} from 'screens/main/Profile/sections/InsuranceInformation';
import { PatientConsentCommunication } from 'screens/main/Profile/sections/PatientConsentCommunication';
import { useGetConsents } from 'screens/main/Profile/sections/Profile/useGetConsents';
import { logoutHandler } from 'store/actions/user/actionCreators/logoutHandler';
import { getUserHasPsp } from 'store/selectors';
import { HeaderText, StandardText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';

import { BasicInformationSection } from '../BasicInformation';
import { CommunicationPreferencesSection } from '../CommunicationPreferences';
import { ContactInformationSection } from '../ContactInformation';
import { useProfileStyles } from './styles';

export type ProfileSectionProps = Pick<InsuranceInformationSectionProps, 'setInsuranceSectionPosition'>;

export const ProfileSection = ({ setInsuranceSectionPosition }: ProfileSectionProps) => {
  const userHasPSP = useSelector(getUserHasPsp);

  const { styles, colors } = useStylesWithAdditional(useProfileStyles);

  const dispatch = useDispatch();

  const { consents } = useGetConsents();

  const onLogout = () => {
    dispatch(logoutHandler());
  };

  return (
    <>
      <View style={styles.profileHeaderWrapper}>
        <HeaderText headerTextColor={colors.mangoPrimary}>About</HeaderText>
        <TouchableOpacity onPress={onLogout}>
          <StandardText style={styles.standardText}>Log Out</StandardText>
        </TouchableOpacity>
      </View>
      <BasicInformationSection />
      <ContactInformationSection />
      {userHasPSP && <InsuranceInformationSection setInsuranceSectionPosition={setInsuranceSectionPosition} />}
      <CommunicationPreferencesSection />
      {consents.map((consent, index) => (
        <PatientConsentCommunication key={index} consents={consent} />
      ))}
    </>
  );
};
