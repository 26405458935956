import { SetShowEventNotification } from 'store/actions/user/actionCreators/setShowEventNotification';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setShowEventNotification = (
  state = INITIAL_STATE,
  payload: ReturnType<SetShowEventNotification>['payload']
): User => {
  return { ...state, showEventNotification: payload };
};

export default setShowEventNotification;
