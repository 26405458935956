export enum Routes {
  HOME = 'home',
  FINANCIAL_ASSISTANCE = 'financial-assistance',
  BENEFITS = 'benefits',
  RESOURCES = 'resources',
  REBATE_CENTER = 'rebate-center',
  PROFILE = 'profile',
  MEDICINE_CABINET = 'medicine-cabinet',
  LIST = 'list',
  DETAILS = 'details',
  INTERACTION_DETAILS = 'details/interaction',
  TRACKER = 'tracker',
  MED_LIST = 'medicine-cabinet/list',
  MED_DETAILS = 'medicine-cabinet/details',
  MED_INTERACTION_DETAILS = 'medicine-cabinet/details/interaction',
  LOGIN = 'login',
  SET_PASSWORD = 'set-password',
  APP_NAVIGATOR = 'app',
  NOT_FOUND = 'not-found',
  SCREENS = 'screens',
  TERMS_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  TWO_FACTOR_AUTHENTICATION = 'two-factor-authentication',
}
