import { parse, format } from 'date-fns';

import {
  TIME_24_FORMAT_TYPE,
  MERIDIEM_FORMAT_TYPE,
  MERIDIEM_FORMAT_WITHOUT_POSTFIX_TYPE,
} from 'components/TimePicker/constants';
import { fillByEmptySymbols } from 'utils/fillByEmptySymbols';

import { MeridiemTypes, PickerValues } from './types';

export const extractMeridiemValuesFromTime24 = (time24: string | Date): PickerValues => {
  const date = time24 instanceof Date ? time24 : parse(time24, TIME_24_FORMAT_TYPE, new Date()); // 20:00 -> new Date()
  const meridiemFormattedDate = format(date, MERIDIEM_FORMAT_TYPE); // new Date -> 08:00 PM

  const [hours, minutes, meridiem] = meridiemFormattedDate.split(/:|\s/);

  const hr = fillByEmptySymbols(hours, 2);
  const min = fillByEmptySymbols(minutes, 2);

  return {
    hourValue: hr,
    minuteValue: min,
    meridiemValue: MeridiemTypes[meridiem === 'PM' ? 'PM' : 'AM'],
    convertedTime: `${hr}:${min} ${meridiem}`,
  };
};

/** time12 = 4:30 PM to => 16:30; */
export const fromMeridiemToTime24Format = (time12: string | null) => {
  const date = time12 ? parse(time12, MERIDIEM_FORMAT_TYPE, new Date()) : new Date();
  return format(date, TIME_24_FORMAT_TYPE);
};

export const changeMeridiemInDate = (date: Date, meridiem: MeridiemTypes) => {
  const time = format(date, MERIDIEM_FORMAT_WITHOUT_POSTFIX_TYPE);

  return parse(`${time} ${meridiem}`, MERIDIEM_FORMAT_TYPE, date);
};
