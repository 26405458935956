import React from 'react';

import { RightArrow } from 'assets/icons';
import { DoneActionBox } from 'components/DoneActionBox';
import ViewAgainActionBox from 'components/ViewAgainActionBox';
import { StandardText } from 'style/common';

import { ActionIconWrapper, NewLabelWrapper, NewLabelText } from './styles';

type RightSideActionsProps = {
  isDone?: boolean;
  isNew?: boolean;
  isMobile?: boolean;
  viewAgainIsShown?: boolean;
};

const RightSideActionsView = ({ isDone, viewAgainIsShown }: RightSideActionsProps) => {
  if (!isDone) return null;
  return viewAgainIsShown ? <ViewAgainActionBox /> : <DoneActionBox />;
};

const ViewMore = ({ isDone, isMobile }: { isDone?: boolean; isMobile?: boolean }) => {
  if (isDone) return null;
  return isMobile ? (
    <ActionIconWrapper>
      <RightArrow />
    </ActionIconWrapper>
  ) : (
    <StandardText>View More</StandardText>
  );
};

export const RightSideActions = ({ isDone, isMobile, isNew, viewAgainIsShown }: RightSideActionsProps) => {
  return (
    <>
      {isNew && (
        <NewLabelWrapper>
          <NewLabelText>New</NewLabelText>
        </NewLabelWrapper>
      )}
      <RightSideActionsView isDone={isDone} viewAgainIsShown={viewAgainIsShown} />
      <ViewMore isDone={isDone} isMobile={isMobile} />
    </>
  );
};

export default RightSideActions;
