import { Alert } from 'react-native';

import { AnyFunction } from 'utils/types';

import { isNativeDevice } from './helpers';

export const showAlert = (handleConfirm: AnyFunction, message?: string) => {
  if (!isNativeDevice) {
    if (window.confirm(message || 'You have unsaved changes. Are you sure to discard them and leave the screen?')) {
      handleConfirm();
    }
    return;
  }
  Alert.alert(
    'Discard changes?',
    message || 'You have unsaved changes. Are you sure to discard them and leave the screen?',
    [
      { text: "Don't leave", style: 'cancel', onPress: () => null },
      {
        text: 'Discard',
        style: 'destructive',
        onPress: () => handleConfirm(),
      },
    ]
  );
};
