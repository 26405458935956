import { Dispatch } from 'redux';

import { resetUserInfo, setTourActive } from 'store/actions';
import { resetUserDemoInfo, resetUserMFAInfo } from 'store/actions/user/actionCreators/resetUserInfo';

export const clearPersistCache = ({
  dispatch,
  shouldReload = false,
}: {
  dispatch: Dispatch;
  shouldReload?: boolean;
}) => {
  dispatch(setTourActive(false));
  dispatch(resetUserInfo());
  dispatch(resetUserDemoInfo());
  dispatch(resetUserMFAInfo());
  shouldReload && window?.location?.reload?.();
};
