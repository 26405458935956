import React, { useRef, useState } from 'react';

import throttle from 'lodash.throttle';
import ToastContainer, { useToast } from 'react-native-toast-notifications';
import { useDispatch } from 'react-redux';

import { consentsApiMap } from 'components/ConsentModals/constants';
import { ModalComponent as Modal } from 'components/Modal';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useConsentModalsQueue } from 'hooks';
import { removeFromConsentModalsQueue, setConsentHasBeenGiven } from 'store/actions';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import { handlePromise } from 'utils/handlePromise';
import { isWebNativeDevice } from 'utils/helpers';

import Consent from './Consent';
import DeclineConsent from './DeclineConsent';

export const ConsentModal = () => {
  const { isMobile } = useDeviceInfoContext();
  const dispatch = useDispatch();
  const [isDeclineConsentVisible, setIsDeclineConsentVisible] = useState(false);
  const toastRef = useRef<ToastContainer>(null);

  const consentToBeViewed = useConsentModalsQueue();

  const toast = useToast();

  const throttledToastHide = throttle(() => toastRef.current?.hideAll(), 400);

  const showError = () => toast.show('Unable to save consent.', { type: 'danger' });

  const submitConsentHandler = async (isValid: boolean) => {
    if (isValid && consentToBeViewed) {
      try {
        if (!consentToBeViewed.groupType) return showError();

        const consentsApi = consentsApiMap[consentToBeViewed.groupType];

        const [, error] = await handlePromise(() => {
          const consent_id =
            consentToBeViewed.groupType !== ConsentModalTypeEnum.app ? consentToBeViewed?.uuid : consentToBeViewed.id;

          return consentsApi?.saveGivenConsent({ consent_id });
        });

        if (error) return showError();

        dispatch(
          setConsentHasBeenGiven({
            id: consentToBeViewed.id,
            groupType: consentToBeViewed.groupType,
            ...(consentToBeViewed.groupType === ConsentModalTypeEnum.service && {
              customServiceType: consentToBeViewed.customServiceType,
            }),
            isGiven: true,
          })
        );

        dispatch(
          removeFromConsentModalsQueue({
            id: consentToBeViewed.id,
            groupType: consentToBeViewed.groupType,
            ...(consentToBeViewed.groupType === ConsentModalTypeEnum.service && {
              customServiceType: consentToBeViewed.customServiceType,
            }),
          })
        );
      } catch (error) {
        console.log(error);
      }

      return;
    }

    throttledToastHide();
    toastRef.current?.show('Please accept that you agreed on the terms', {
      type: 'warning',
    });
  };

  const consentModalCloseHandler = () => {
    if (!consentToBeViewed) return;

    if (consentToBeViewed.required_to_proceed) return setIsDeclineConsentVisible(true);

    dispatch(
      removeFromConsentModalsQueue({
        id: consentToBeViewed.id as string,
        groupType: consentToBeViewed.groupType as ConsentModalTypeEnum,
        ...(consentToBeViewed.groupType === ConsentModalTypeEnum.service && {
          customServiceType: consentToBeViewed.customServiceType,
        }),
      })
    );
  };

  return (
    <>
      <Modal
        toastRef={toastRef}
        modalIsVisible={!!consentToBeViewed && !isDeclineConsentVisible}
        setModalVisible={() => null}
        disableScrollView={!isWebNativeDevice}
        modalContentStyle={isMobile && { height: 'auto', maxHeight: '96%' }}
        onRequestClose={consentModalCloseHandler}>
        <Consent toastRef={toastRef} onSubmitConsent={submitConsentHandler} />
      </Modal>
      <Modal
        overlayColor={'rgba(0, 0, 0, 0.4)'}
        modalIsVisible={isDeclineConsentVisible}
        setModalVisible={setIsDeclineConsentVisible}
        removeCloseIcon>
        <DeclineConsent modalOnClose={() => setIsDeclineConsentVisible(false)} />
      </Modal>
    </>
  );
};

export default ConsentModal;
