import React, { Component } from 'react';
import { View } from 'react-native';
import { getTimestampForCurrentDateUTC } from '../../dateutils';
import Day from '../day/index';


export class DayWrapper extends Component {
    constructor(props) {
        super(props);
      }
    componentWillUnmount() {
        this.props.componentsRefs.remove('day')
    }
    render() {
        const refHandler = (getTimestampForCurrentDateUTC() ===  getTimestampForCurrentDateUTC(this.props.day)) ? this.props.componentsRefs.setRef('day') : undefined;
        return (
        <View style={this.props.dayContainerStyle} ref={refHandler} key={this.props.id}>
            <Day {...this.props.dayProps} day={this.props.day} state={this.props.state} marking={this.props.marking} onPress={this.props.pressDay} onLongPress={this.props.longPressDay}/>
        </View>
        )
    }
}