import { gaHandler } from 'analytics/global_handlers';
import { EventCategories } from 'analytics/types';
import { meridiemFormat } from 'utils/dateTimeHelpers';

import { months } from './constants';

export const dateFormatter = (date: Date) => {
  const time = meridiemFormat(date);
  return `${time} - ${months[date.getMonth()]} ${date.getUTCDate()}, ${date.getFullYear()}`;
};

// Notifications on the tracker screen.
export const medicationNotificationsAnalyticsHandler = gaHandler(EventCategories.TRACK_MEDICATION_TRACKER);
