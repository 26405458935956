import React from 'react';

import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { MedicineCabinetInterface } from 'api';
import medCabinetEmptySrc from 'assets/images/med_cabinet_empty.png';
import medCabinetEmptyNativeSrc from 'assets/images/med_cabinet_empty_native.png';
import medCabinetLayoutSrc from 'assets/images/med_cabinet_layout.png';
import medCabinetLayoutNativeSrc from 'assets/images/med_cabinet_layout_native.png';
import { FullScreenLoader } from 'components/Loaders';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { ProgramMedications } from 'screens/main/MedicineCabinet/components';
import { medicationCabinetAnalyticsConstants } from 'screens/main/MedicineCabinet/constants';
import { medicationCabinetAnalyticsHandler } from 'screens/main/MedicineCabinet/helpers';
import { MedicineCabinetLayout } from 'screens/main/MedicineCabinet/layout';
import { CabinetScreenProps } from 'screens/main/MedicineCabinet/types';
import { setMedicationModalVisibility, setMedications } from 'store/actions';
import { getAppDataIsLoading, getMedications, getProgramMedications, getSelectedMedicationId } from 'store/selectors';

const { VIEWED_MEDICATION_CABINET } = medicationCabinetAnalyticsConstants;

export const MedicineCabinet = ({ navigation }: CabinetScreenProps) => {
  const dataIsLoading = useSelector(getAppDataIsLoading);
  const medications = useSelector(getMedications);
  const pspMedication = useSelector(getProgramMedications);

  const dispatch = useDispatch();
  const toast = useToast();

  const { isMobile } = useDeviceInfoContext();

  const medCabinetEmpty = !medications.length;
  const madCabinetImage = medCabinetEmpty ? medCabinetEmptySrc : medCabinetLayoutSrc;
  const medCabinetImageNative = medCabinetEmpty ? medCabinetEmptyNativeSrc : medCabinetLayoutNativeSrc;
  const medLayoutImage = isMobile ? medCabinetImageNative : madCabinetImage;

  const selectedMedicationId = useSelector(getSelectedMedicationId);

  useFirstEffect(() => {
    medicationCabinetAnalyticsHandler(VIEWED_MEDICATION_CABINET, getAnalyticEventLabel());
  }, []);

  const medCabinetTitle = 'Your Medication';

  const isMedCabinetEmpty = !medications.length && !pspMedication.length;

  const medicationModalSubmitHandler = (showToast: boolean, successMessage = 'Successful') => {
    if (selectedMedicationId) {
      dispatch(setMedicationModalVisibility(false));
    }
    MedicineCabinetInterface.getMedications().then((resp) => {
      dispatch(setMedications(resp.data.results));
    });
    showToast && toast.show(successMessage, { type: 'success' });
  };

  if (dataIsLoading) return <FullScreenLoader />;

  return (
    <MedicineCabinetLayout
      title={medCabinetTitle}
      navigation={navigation}
      imageUrl={medLayoutImage}
      isMedCabinetEmpty={isMedCabinetEmpty}
      isMedSectors>
      <View style={{ flex: 1 }}>
        <ProgramMedications medicationModalSubmitHandler={medicationModalSubmitHandler} />
      </View>
    </MedicineCabinetLayout>
  );
};

export default MedicineCabinet;
