import { GetSpecialtyPharmacyData } from 'api/SpecialtyPharmacy/types';
import apiRequest, { apiPrefix } from 'utils/network';

const SPECIALTY_PHARMACY_URL = '/user-specialty-pharmacy/';
const SEND_SMS_SPECIALTY_PHARMACY_URL = SPECIALTY_PHARMACY_URL + 'send-contact/';

export const SpecialtyPharmacyServicesInterface = {
  getSpecialtyPharmacy: () =>
    apiRequest<GetSpecialtyPharmacyData>({
      method: 'GET',
      endpoint: SPECIALTY_PHARMACY_URL,
      baseURL: apiPrefix,
      isProgram: true,
    }).then((res) => res.data),
  sendContactSMS: (pharmacyId: string) =>
    apiRequest({
      method: 'POST',
      endpoint: SEND_SMS_SPECIALTY_PHARMACY_URL + pharmacyId,
      baseURL: apiPrefix,
      isProgram: true,
    }),
  closeSpecialtyPharmacyModal: (pharmacyId: string) =>
    apiRequest({
      method: 'POST',
      endpoint: SPECIALTY_PHARMACY_URL + pharmacyId,
      baseURL: apiPrefix,
      isProgram: true,
      body: { isShown: true },
    }),
};
