import React, { Fragment, useEffect, useState } from 'react';

import { useRoute } from '@react-navigation/native';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { MenuItem, RouteType } from 'components/MenuItem';
import { useAdditionalServiceNotification } from 'components/MenuItem/useAdditionalServiceNotification';
import { useHandleNavigationPush } from 'hooks';
import { Routes } from 'routes/main';
import { getIsCopayUser, getUserHasPsp } from 'store/selectors';
import { useStylesWithAdditional } from 'style/hooks';
import { sleep } from 'utils/helpers';

import { useScreens, useTitles } from '../helpers';
import { useComponentStyles } from './styles';

const getItemsWithHR = (isCopayUser: boolean) => [isCopayUser && Routes.RESOURCES, Routes.PROFILE];

export const MenuItems = () => {
  const [activeRouteName, setActiveRouteName] = useState(Routes.HOME);
  const navigationHandler = useHandleNavigationPush();
  const isCopayUser = useSelector(getIsCopayUser);
  const userHasPsp = useSelector(getUserHasPsp);
  const screens = useScreens();
  const titles = useTitles();
  const itemsWithHR = getItemsWithHR(isCopayUser);
  const { styles, colors } = useStylesWithAdditional(useComponentStyles);
  const route = useRoute();

  const { hideNotifications } = useAdditionalServiceNotification();

  const syncRouteChanges = async () => {
    await sleep(50);
    const name = window.location.pathname.replace('/', '');
    setActiveRouteName(name as Routes);
  };

  useEffect(() => {
    syncRouteChanges();
  }, [route]);

  const handleNavigate = (screen: Routes) => {
    navigationHandler(screen);
    syncRouteChanges();
  };

  const activeColor = userHasPsp ? colors.gray1 : colors.mangoSecondary;

  return (
    <>
      {screens.map((screen, index) => {
        const withHR = userHasPsp && itemsWithHR.includes(screen);

        const itemJSX = (
          <MenuItem
            title={titles[index]}
            menuItemRoute={screen as RouteType}
            active={activeRouteName?.includes(screen)}
            activeColor={activeColor}
            testID={`${screen}-test`}
            iconSize={24}
            itemWrapperStyle={styles.menuItem}
            onPress={() => handleNavigate(screen)}
            hideNotifications={hideNotifications}
          />
        );

        return (
          <Fragment key={titles[index]}>
            {withHR && <View style={styles.verticalHr} />}
            <View style={styles.itemPadding}>{itemJSX}</View>
            {index === screens.length - 1 && <View style={styles.verticalHr} />}
          </Fragment>
        );
      })}
    </>
  );
};

export default MenuItems;
