import React, { useCallback, useEffect, useState } from 'react';

import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { MedicineCabinetInterface } from 'api';
import { MedicationData } from 'api/MedicineCabinetInterface/types';
import { AppScrollView, Button, TextFieldWithLabel } from 'components';
import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';
import { useTimer } from 'hooks';
import { useScrollToErrorField } from 'hooks/useScrollToErrorField';
import {
  setDrugInteractions,
  setMedications,
  setMedicationWizardStep,
  setMedicationWizardVisibility,
  setSelectedMedicationId,
} from 'store/actions/medicineCabinet';
import { getMedicationTypes, getMedicationWizardStep, getSelectedMedication } from 'store/selectors';
import { DefaultText, StandardText } from 'style/common';
import { PLACEHOLDERS } from 'utils/constants';
import { convertLocalToUTCDate, toCorrectDatePickerFormat } from 'utils/dateTimeHelpers';
import { TEST_ID } from 'utils/testIds/constants';

import { medicationWizardAnalyticsActions } from '../constants';
import useWizardAnalytics from '../useWizardAnalytics';
import { DrugAutoComplete } from './components/DrugAutoComplete';
import { medicationStepConstants } from './shared/constants';
import { medicationStepDefaultValues as defaultValues } from './shared/defaultValues';
import { getAddMedicationFields } from './shared/getAddMedicationFields';
import { totalDoseFormatter, validateNumber } from './shared/helpers';
import { getStepStyles } from './shared/styles';
import { MedicationFormValues } from './shared/types';

const {
  MEDICATION_CREATION_STARTED,
  MEDICATION_CREATION_FINISHED,
  MEDICATION_CREATION_FAILED,
  MEDICATION_CREATION_INTERRUPTED,
} = medicationWizardAnalyticsActions;

export const MedicationStep = () => {
  const [selectedDrugId, setSelectedDrugId] = useState<number | string>(0);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const dispatch = useDispatch();
  const wizardStep = useSelector(getMedicationWizardStep);
  const selectedMedication = useSelector(getSelectedMedication);
  const medicationTypes = useSelector(getMedicationTypes);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<MedicationFormValues>({
    defaultValues: selectedMedication
      ? { ...selectedMedication, dateStarted: toCorrectDatePickerFormat(new Date(selectedMedication.dateStarted)) }
      : defaultValues,
  });
  const theme = useTheme();
  const styles = getStepStyles(theme);
  const { start, pause } = useTimer();
  const analyticsHandler = useWizardAnalytics();

  useEffect(() => {
    start();
    analyticsHandler(MEDICATION_CREATION_STARTED);
  }, [start, analyticsHandler]);

  const isEditMode = !!selectedMedication?.id;
  const { title, description, buttonText } = isEditMode ? medicationStepConstants.edit : medicationStepConstants.add;
  const medicationType = watch('type');
  const totalDose = watch('totalDose');

  const getPostfixValue = () => {
    if (medicationType) {
      const typeLabel = medicationTypes.find((option) => option.value === medicationType)?.label;
      if (!typeLabel) return '';

      const suffix = totalDose && totalDose > 1 ? 's' : '';
      return totalDose ? `${typeLabel}${suffix}` : typeLabel.toString();
    }
    return '';
  };

  const handleCloseWizard = () => {
    const passedTime = pause();
    analyticsHandler(MEDICATION_CREATION_INTERRUPTED, { event_label: passedTime });
    dispatch(setMedicationWizardVisibility(false));
  };

  const submitHandler = async (values: MedicationFormValues) => {
    const drugId = selectedDrugId || selectedMedication?.drugId;
    if (drugId === undefined) return;

    const data = {
      ...values,
      dosageAmount: values.dosageAmount || '',
      totalDose: totalDoseFormatter(String(values.totalDose)) || 0,
      dateStarted: convertLocalToUTCDate(values.dateStarted),
      drugId,
      name: '',
      quantity: values.quantity || 0,
    } as unknown as MedicationData;

    setSubmitDisabled(true);
    try {
      if (selectedMedication?.id) {
        await MedicineCabinetInterface.updateMedication(data);
      } else {
        const response = await MedicineCabinetInterface.createMedication(data);
        const medicationsResponse = await MedicineCabinetInterface.getMedications();
        await Promise.resolve(dispatch(setMedications(medicationsResponse?.data?.results || [])));
        await Promise.resolve(dispatch(setSelectedMedicationId(response?.data?.id)));
        MedicineCabinetInterface.checkDrugInteractions(drugId).then((interactionResponse) => {
          dispatch(setDrugInteractions(interactionResponse?.data?.interactions));
        });
        const passedTime = pause();
        analyticsHandler(MEDICATION_CREATION_FINISHED, { event_label: passedTime });
      }
    } catch (e) {
      console.log(e);
      const passedTime = pause();
      analyticsHandler(MEDICATION_CREATION_FAILED, { event_label: passedTime });
    }
    setSubmitDisabled(false);
    dispatch(setMedicationWizardStep(wizardStep + 1));
  };

  const onSelectedMedication = useCallback(
    (onChange: ControllerRenderProps['onChange']) => (id: string | number) => {
      setSelectedDrugId(id);
      onChange(id);
    },
    []
  );

  const fields = getAddMedicationFields({
    name: ({ field: { onChange } }) => (
      <DrugAutoComplete
        initialValue={selectedMedication?.name || ''}
        disabled={isEditMode}
        testID={TEST_ID.ADD_NEW_MEDICATION_NAME}
        selectTestID={TEST_ID.ADD_NEW_MEDICATION_NAME_SELECT_OPTIONS}
        onSelectedMedicationItem={onSelectedMedication(onChange)}
      />
    ),
    type: ({ field: { value, onChange } }) => (
      <SelectBox
        testID={TEST_ID.ADD_NEW_MEDICATION_TYPE}
        value={value}
        labelText='Type of Medication'
        items={medicationTypes}
        onValueChange={onChange}
      />
    ),
    totalDose: ({ field: { value, onChange } }) => (
      <TextFieldWithLabel
        keyboardType={'numeric'}
        value={`${value}`}
        labelText='Dosage'
        testID={TEST_ID.ADD_NEW_MEDICATION_DOSAGE}
        placeholder={PLACEHOLDERS.DOSAGE}
        onChangeText={(text) => onChange(validateNumber(text) ?? '')}
        postfix={getPostfixValue()}
      />
    ),
    dosageAmount: ({ field: { value, onChange } }) => (
      <TextFieldWithLabel
        value={`${value}`}
        labelText='Strength'
        testID={TEST_ID.ADD_NEW_MEDICATION_STRENGTH}
        placeholder={PLACEHOLDERS.STRENGTH}
        onChangeText={onChange}
      />
    ),
    quantity: ({ field: { value, onChange } }) => (
      <TextFieldWithLabel
        keyboardType={'numeric'}
        value={`${value}`}
        testID={TEST_ID.ADD_NEW_MEDICATION_QUANTITY}
        labelText='Quantity'
        placeholder={PLACEHOLDERS.QUANTITY}
        onChangeText={(text) => onChange(validateNumber(text) ?? '')}
      />
    ),
    dateStarted: ({ field: { value, onChange } }) => (
      <DatePicker
        testID={TEST_ID.ADD_NEW_MEDICATION_START_DATE}
        onChange={(date) => onChange(convertLocalToUTCDate(date))}
        value={toCorrectDatePickerFormat(new Date(value as string)) as Date}
        labelText='Start Date'
      />
    ),
  });

  const listRef = useScrollToErrorField({
    errorNames: fields.map(({ name }) => name),
    itemHeight: 100, // margin + label + input
    errors,
  });

  return (
    <>
      <Text style={styles.headerText}>{title}</Text>
      <StandardText style={styles.descriptionText}>{description}</StandardText>
      <AppScrollView
        ref={listRef}
        nestedScrollEnabled
        style={styles.medicationScrollView}
        VHHeight={40}
        leftScrollOffset={16}>
        <View>
          {fields.map((field) => (
            <View key={field.name} style={styles.blockContainer}>
              <Controller control={control} {...field} />
              {!!field.errorText && !!errors[field.name] && (
                <DefaultText style={styles.negative}>{field.errorText}</DefaultText>
              )}
            </View>
          ))}
        </View>
      </AppScrollView>
      <View style={[styles.buttonsContainer, styles.buttonContainerPaddingRightScrollView]}>
        <Button variant='tertiary' style={styles.button} text='Cancel' onPress={handleCloseWizard} />
        <Button
          style={[styles.button, { marginLeft: 16 }]}
          variant='mango'
          text={buttonText}
          onPress={handleSubmit(submitHandler)}
          disabled={submitDisabled}
        />
      </View>
    </>
  );
};

export default MedicationStep;
