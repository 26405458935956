import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import ToastContainer, { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface } from 'api';
import { ModalComponent as Modal } from 'components/Modal';
import { useTimer } from 'hooks';
import { DeleteModal } from 'screens/main/MedicineCabinet/components/modals/DeleteModal';
import { MedCabinetWidgetType } from 'screens/main/MedicineCabinet/types';
import { setReminderModalVisibility, setSelectedReminderId } from 'store/actions';
import { ReminderTypesEnum } from 'store/reducers/medicine-cabinet/types';
import {
  getPSPReminderTemplate,
  getReminderModalVisibility,
  getSelectedMedication,
  getSelectedReminderId,
} from 'store/selectors';
import { getPSPMedicationWithoutReminder } from 'store/selectors/medicine-cabinet';
import { modalContentStyle } from 'style/common';
import { SOMETHING_WRONG_ERROR_MESSAGE } from 'utils/constants';
import { extractTimeFromDate, replaceTimeInDate } from 'utils/dateTimeHelpers';
import { handlePromise } from 'utils/handlePromise';
import { isNativeDevice, sleep, throttleToastHide } from 'utils/helpers';

import { reminderModalAnalyticsActions } from './constants';
import { ReminderEdit } from './Edit';
import { RecurringValues } from './Edit/types';
import { parseStartDate, reminderModalAnalyticsHandler } from './helpers';
import { ReminderOverview } from './Overview';
import { ReminderFields, ReminderModalProps } from './types';

const defaultValues = {
  medicationId: '',
  recurring: '',
  time: '',
  every_x_hours: '',
  every_x_days: '',
  every_x_weeks: '',
};

const pspReminderFrequency = (type?: ReminderTypesEnum) => {
  switch (type) {
    case ReminderTypesEnum.EVERY_X_DAYS: {
      return 'every_x_days';
    }
    case ReminderTypesEnum.EVERY_X_HOURS: {
      return 'every_x_hours';
    }
    case ReminderTypesEnum.EVERY_X_WEEKS: {
      return 'every_x_weeks';
    }
    default:
      return 'every_x_hours';
  }
};

const {
  ADD_REMINDER_STARTED,
  ADD_PSP_REMINDER_STARTED,
  ADD_REMINDER_FINISHED,
  ADD_PSP_REMINDER_FINISHED,
  ADD_REMINDER_INTERRUPTED,
  EDIT_REMINDER_STARTED,
  EDIT_PSP_REMINDER_STARTED,
  EDIT_REMINDER_FINISHED,
  EDIT_PSP_REMINDER_FINISHED,
  EDIT_REMINDER_INTERRUPTED,
} = reminderModalAnalyticsActions;

export const ReminderModal = ({ id, isHomeScreen, initialValues, submitHandler }: ReminderModalProps) => {
  const [isEditMode, setEditMode] = useState(!!id);
  const selectedMedication = useSelector(getSelectedMedication);
  const selectedReminderId = useSelector(getSelectedReminderId);
  const modalIsVisible = useSelector(getReminderModalVisibility);
  const pspMedicationsWithoutReminder = useSelector(getPSPMedicationWithoutReminder);
  const pspReminderTemplate = useSelector(getPSPReminderTemplate);

  const [deleteModalIsVisible, setDeleteModalVisibility] = useState(false);
  const [submitIsDisabled, setSubmitDisabled] = useState(false);

  const methods = useForm<ReminderFields>({ defaultValues, mode: 'onChange' });
  const { reset, control, handleSubmit } = methods;

  const dispatch = useDispatch();

  const sponsoredProgramUuid = pspMedicationsWithoutReminder?.sponsored_program_uuid;
  const pspOverviewMode = !isEditMode && isHomeScreen;

  const toast = useToast();
  const toastRef: MutableRefObject<ToastContainer | null> = useRef(null);
  const recurringValue = useWatch({ name: 'recurring', control }) as RecurringValues;
  const { start, pause } = useTimer();

  useEffect(() => {
    setEditMode(!!id);
  }, [id]);

  useEffect(() => {
    if (modalIsVisible) {
      const addedEvent = !selectedReminderId && pspOverviewMode ? ADD_PSP_REMINDER_STARTED : ADD_REMINDER_STARTED;
      const editEvent = isHomeScreen ? EDIT_PSP_REMINDER_STARTED : EDIT_REMINDER_STARTED;
      const eventName = selectedReminderId ? editEvent : addedEvent;
      start();
      reminderModalAnalyticsHandler(eventName);
    }
  }, [pspOverviewMode, isHomeScreen, modalIsVisible, selectedReminderId, start]);

  useEffect(() => {
    if (!modalIsVisible && !isEditMode) {
      reset({ ...defaultValues, medicationId: selectedMedication?.id?.toString?.() });
    }
  }, [modalIsVisible, isEditMode, reset, selectedMedication?.id]);

  useEffect(() => {
    if (modalIsVisible || deleteModalIsVisible) return;
    const resetReminderId = async () => {
      await sleep(300);
      dispatch(setSelectedReminderId(null));
    };
    resetReminderId();
  }, [modalIsVisible, deleteModalIsVisible, dispatch]);

  useEffect(() => {
    const selectedMedicationId = `${selectedMedication?.id || ''}`;
    if (initialValues) {
      const { every_x_days, every_x_hours, every_x_weeks, type, time_of_day, start_date } = initialValues;
      reset({
        medicationId: selectedMedicationId,
        recurring: type,
        time_of_day: !isNativeDevice ? time_of_day || '' : time_of_day ? replaceTimeInDate(time_of_day) : '',
        every_x_hours: `${every_x_hours || ''}`,
        every_x_days: `${every_x_days || ''}`,
        every_x_weeks: `${every_x_weeks || ''}`,
        start_date: start_date ? parseStartDate(start_date) : new Date(),
      });
    }
    if (!initialValues && !!selectedMedicationId) {
      const pspTemplateType = pspReminderFrequency(pspReminderTemplate?.type);
      const isSelectedPSPMedication = +selectedMedicationId === pspMedicationsWithoutReminder?.id;

      reset({
        ...defaultValues,
        medicationId: selectedMedicationId,
        ...(isSelectedPSPMedication &&
          !!pspReminderTemplate && {
            recurring: pspReminderTemplate.type,
            [pspTemplateType]: pspReminderTemplate.frequency,
          }),
      });
    }
  }, [pspMedicationsWithoutReminder, pspReminderTemplate, initialValues, reset, selectedMedication?.id]);

  const reminderSubmitHandler = async (values: ReminderFields) => {
    const isTimeValueString = typeof values.time_of_day === 'string' && !!values.time_of_day;
    const recurringValueEveryHours = recurringValue === 'EVERY_X_HOURS' ? Number(values.every_x_hours) : 0;
    const recurringValueEveryWeeks = recurringValue === 'EVERY_X_WEEKS' ? Number(values.every_x_weeks) : 0;
    const recurringValueEveryDays = recurringValue === 'EVERY_X_DAYS' ? Number(values.every_x_days) : 0;

    const data = {
      ...(!isEditMode && { user_datetime: new Date().toISOString() }),
      medication_id: pspOverviewMode ? pspMedicationsWithoutReminder?.id : Number(values.medicationId),
      type: pspOverviewMode ? pspReminderTemplate?.type ?? '' : values.recurring,
      time_of_day: isTimeValueString
        ? values.time_of_day
        : extractTimeFromDate((values.time_of_day || new Date()) as Date),
      every_x_hours: pspOverviewMode ? Number(pspReminderTemplate?.frequency) ?? '' : recurringValueEveryHours,
      every_x_weeks: pspOverviewMode ? Number(pspReminderTemplate?.frequency) ?? '' : recurringValueEveryWeeks,
      every_x_days: pspOverviewMode ? Number(pspReminderTemplate?.frequency) ?? '' : recurringValueEveryDays,
      start_date: pspOverviewMode ? format(new Date(), 'dd-MM-yyyy') : format(values.start_date, 'dd-MM-yyyy'),
      ...(selectedMedication?.id && { id: selectedReminderId }),
    };

    setSubmitDisabled(true);

    try {
      if (selectedReminderId) {
        await MedicineCabinetInterface.updateReminder(data);
        toast.show('Your reminder was updated', { type: 'success' });
      } else {
        await MedicineCabinetInterface.createReminder(data);
        toast.show('We added your reminder!', { type: 'success' });
        !!sponsoredProgramUuid && (await MedicineCabinetInterface.sponsoredMedicationAdded(sponsoredProgramUuid));
      }

      const passedTime = pause();
      const editReminderActions = isHomeScreen ? EDIT_PSP_REMINDER_FINISHED : EDIT_REMINDER_FINISHED;
      const addReminderActions = isHomeScreen ? ADD_PSP_REMINDER_FINISHED : ADD_REMINDER_FINISHED;
      const eventName = selectedReminderId ? editReminderActions : addReminderActions;
      reminderModalAnalyticsHandler(eventName, { event_label: passedTime });

      submitHandler();
      reset(defaultValues);
    } catch (e: any) {
      toastRef.current?.show(e?.response?.data?.message || SOMETHING_WRONG_ERROR_MESSAGE, { type: 'danger' });
    }
    setSubmitDisabled(false);
    throttleToastHide(toastRef.current);
  };

  const reminderModalVisibilityHandler = (visible: boolean) => dispatch(setReminderModalVisibility(visible));

  const openDeleteModal = () => {
    setDeleteModalVisibility(true);
    dispatch(setReminderModalVisibility(false));
  };

  const skipHandler = async () => {
    if (!sponsoredProgramUuid) return;

    const [, error] = await handlePromise(() =>
      MedicineCabinetInterface.sponsoredMedicationAdded(sponsoredProgramUuid)
    );
    error && toast?.show(SOMETHING_WRONG_ERROR_MESSAGE, { type: 'danger' });
  };

  const handleDelete = async () => {
    try {
      if (selectedReminderId) {
        await MedicineCabinetInterface.deleteReminder(selectedReminderId);
        submitHandler();
        toast?.show('Reminder successfully deleted!', { type: 'success' });
      }
    } catch (e) {
      toastRef.current?.show(SOMETHING_WRONG_ERROR_MESSAGE, { type: 'danger' });
    }
    throttleToastHide(toastRef.current);
  };

  const handleOnRequestClose = async () => {
    reminderModalVisibilityHandler(false);
    const needToSkip = Boolean(isHomeScreen && !isEditMode && pspMedicationsWithoutReminder);

    if (needToSkip) {
      await skipHandler();
    } else if (!deleteModalIsVisible && !isHomeScreen) {
      const passedTime = pause();
      const eventName = selectedReminderId ? EDIT_REMINDER_INTERRUPTED : ADD_REMINDER_INTERRUPTED;
      reminderModalAnalyticsHandler(eventName, { event_label: passedTime });
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal
        modalContentStyle={modalContentStyle}
        useContentWrapper
        toastRef={toastRef}
        modalIsVisible={modalIsVisible}
        onRequestClose={handleOnRequestClose}
        setModalVisible={reminderModalVisibilityHandler}>
        {isHomeScreen && !isEditMode ? (
          <ReminderOverview
            submitIsDisabled={submitIsDisabled}
            submitHandler={handleSubmit(reminderSubmitHandler)}
            setEditMode={setEditMode}
            onClose={handleOnRequestClose}
          />
        ) : (
          <ReminderEdit
            isHomeScreen={isHomeScreen}
            isEditMode={isEditMode}
            handleSubmit={handleSubmit(reminderSubmitHandler)}
            openDeleteModal={openDeleteModal}
            submitIsDisabled={submitIsDisabled}
            recurringValue={recurringValue}
          />
        )}
      </Modal>
      <DeleteModal
        itemNameToBeDeleted={MedCabinetWidgetType.REMINDER}
        setWidgetModalVisibility={reminderModalVisibilityHandler}
        deleteHandler={handleDelete}
        setDeleteModalVisibility={setDeleteModalVisibility}
        deleteModalIsVisible={deleteModalIsVisible}
        overlayColor={'rgba(0,0,0,.4)'}
      />
    </FormProvider>
  );
};

export default ReminderModal;
