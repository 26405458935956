import { RefObject, useEffect } from 'react';

import { NativeMethods } from 'react-native';

import { isNativeDevice } from 'utils/helpers';

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement & NativeMethods = HTMLElement & NativeMethods>(
  ref: RefObject<T | undefined | null>,
  handler: (event: AnyEvent) => void
): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    if (!isNativeDevice) {
      document.addEventListener(`mousedown`, listener);
      document.addEventListener(`touchstart`, listener);
    }

    return () => {
      if (!isNativeDevice) {
        document.removeEventListener(`mousedown`, listener);
        document.removeEventListener(`touchstart`, listener);
      }
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export default useOnClickOutside;
