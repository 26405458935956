import {
  SET_DEMO_ADDITIONAL_SERVICES_STATE,
  SET_DEMO_FORWARD_BUTTON_STATE,
  SET_DEMO_MILESTONE_TRACKER_STATE,
  SET_SHOWED_MEDICATION_ITEM_DEMO_ANIMATION,
  SET_SHOWED_REMINDER_ITEM_DEMO_ANIMATION,
} from 'store/action-types';
import { DemoConfigActions } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { DemoConfigState } from './types';

const demoConfig = (state = INITIAL_STATE, action: DemoConfigActions): DemoConfigState => {
  switch (action.type) {
    case SET_DEMO_ADDITIONAL_SERVICES_STATE:
      return {
        ...state,
        additionalServicesState: action.payload,
      };
    case SET_DEMO_FORWARD_BUTTON_STATE:
      return {
        ...state,
        showFastForwardButton: action.payload,
      };
    case SET_DEMO_MILESTONE_TRACKER_STATE:
      return {
        ...state,
        milestoneTrackerState: action.payload,
      };
    case SET_SHOWED_MEDICATION_ITEM_DEMO_ANIMATION:
      return {
        ...state,
        showedMedicationItemDemoAnimation: action.payload,
      };
    case SET_SHOWED_REMINDER_ITEM_DEMO_ANIMATION:
      return {
        ...state,
        showedReminderItemDemoAnimation: action.payload,
      };
    default:
      return state;
  }
};

export default demoConfig;
