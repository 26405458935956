import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import throttle from 'lodash.throttle';
import { View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { AppScrollView, Button, CheckboxWithLabel, ProgressSteps } from 'components';
import { AppScrollViewRef } from 'components/AppScrollView/types';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { removeFromConsentModalsQueue, setConsentHasBeenViewed } from 'store/actions';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import { getConsentsGroupToShow, getCurrentlyActiveConsent, getCurrentlyActiveService } from 'store/selectors';
import { DefaultText, PrimaryDefaultText, useConsentStyles, webStyles } from 'style/common';
import { TEST_ID } from 'utils/testIds/constants';

import { texts } from './constants';

export type ConsentProps = {
  toastRef: MutableRefObject<ToastContainer | null>;
  onSubmitConsent: (isValid: boolean) => void;
};

const Consent = ({ toastRef, onSubmitConsent }: ConsentProps) => {
  const { isMobile } = useDeviceInfoContext();
  const [hasCheckmark, setCheckmark] = useState(false);
  const [checkmarkIsDisabled, setCheckmarkDisabled] = useState(false);

  const dispatch = useDispatch();

  const currentlyActiveConsent = useSelector(getCurrentlyActiveConsent);
  const currentlyActiveAdditionalService = useSelector(getCurrentlyActiveService);
  const appOrProgramConsents = useSelector(getConsentsGroupToShow);

  const scrollRef = useRef<AppScrollViewRef>(null);

  const throttleToastHideAll = throttle(() => toastRef.current?.hideAll(), 400);

  const consentGroupToShow =
    appOrProgramConsents?.result ||
    (currentlyActiveAdditionalService &&
      currentlyActiveAdditionalService.service?.consentConfig?.length > 1 &&
      currentlyActiveAdditionalService.service.consentConfig.map((consent) => consent.id));

  const [isValid, setIsValid] = useState(!!currentlyActiveConsent?.isViewed && hasCheckmark);

  useEffect(() => {
    setIsValid(!!currentlyActiveConsent?.isViewed && hasCheckmark);
  }, [currentlyActiveConsent?.isViewed, hasCheckmark]);

  const style = useConsentStyles();

  useEffect(() => {
    if (!currentlyActiveConsent?.isViewed) setCheckmark(false);
    setCheckmarkDisabled(!currentlyActiveConsent?.isViewed);
  }, [currentlyActiveConsent?.isViewed]);

  useEffect(() => scrollRef.current?.reset(), [currentlyActiveConsent?.body]);

  const handleFirstCheckConfirm = () => {
    if (checkmarkIsDisabled) {
      throttleToastHideAll();
      toastRef.current?.show('You must read all terms and conditions before providing consent', {
        type: 'warning',
      });

      return;
    }
    setCheckmark((prev) => !prev);
  };

  const handleSubmit = () => onSubmitConsent(isValid);

  const handleSkip = () => {
    if (!currentlyActiveConsent || !currentlyActiveConsent.groupType) return;

    dispatch(
      removeFromConsentModalsQueue({
        id: currentlyActiveConsent.id,
        groupType: currentlyActiveConsent.groupType,
        ...(currentlyActiveConsent.groupType === ConsentModalTypeEnum.service && {
          customServiceType: currentlyActiveConsent.customServiceType,
        }),
      })
    );
  };

  const onScrollEndHandler = useCallback(() => {
    if (!currentlyActiveConsent || !currentlyActiveConsent.groupType) return;
    dispatch(
      setConsentHasBeenViewed({
        id: currentlyActiveConsent.id,
        groupType: currentlyActiveConsent.groupType,
        isViewed: true,
      })
    );
  }, [currentlyActiveConsent, dispatch]);

  if (!currentlyActiveConsent) return null;

  return (
    <View style={style.wrapper}>
      {consentGroupToShow && (
        <ProgressSteps steps={consentGroupToShow} currentlyActiveItemId={currentlyActiveConsent.id} />
      )}
      <PrimaryDefaultText numberOfLines={5} style={style.title}>
        {currentlyActiveConsent.title}
      </PrimaryDefaultText>
      <View style={style.consentScrollWrapper}>
        <AppScrollView
          testID={TEST_ID.CONSENT_DESCRIPTION}
          ref={scrollRef}
          style={style.consentContentScroll}
          leftScrollOffset={16}
          VHHeight={30}
          onScrollEnd={onScrollEndHandler}
          onScrollEndOneTime>
          <DefaultText style={style.consentContentText}>{currentlyActiveConsent.body}</DefaultText>
        </AppScrollView>
      </View>
      <CheckboxWithLabel
        testID={TEST_ID.CONSENT_CHECKBOX}
        isMobile={isMobile}
        handleConfirmChange={handleFirstCheckConfirm}
        isConfirmed={hasCheckmark}
        disabled={checkmarkIsDisabled}
        text={currentlyActiveConsent.action_text}
      />
      <View style={style.submitButtonWrapper}>
        <Button
          variant='secondary'
          testID={TEST_ID.CONSENT_SUBMIT_BUTTON}
          style={[style.submitButton, webStyles.button]}
          text={texts.buttonText}
          onPress={handleSubmit}
          disabled={!isValid}
        />
        {!currentlyActiveConsent.required_to_proceed && (
          <Button
            testID={TEST_ID.CONSENT_SKIP_BUTTON}
            variant='secondary'
            style={[style.skipButton, webStyles.button]}
            text={texts.skip}
            onPress={handleSkip}
          />
        )}
      </View>
    </View>
  );
};

export default Consent;
