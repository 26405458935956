import React, { useRef } from 'react';

import { View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { TextFieldWithLabel } from 'components';
import { Button } from 'components/Button';
import { FullScreenLoader } from 'components/Loaders';
import { ModalComponent as Modal } from 'components/Modal';
import { useResendActivateEmail } from 'screens/auth/Login/components/hooks/useResendActivateEmail';
import { useAuthStyles } from 'screens/auth/Login/styles';
import { setIsActivateAccountModalVisible } from 'store/actions';
import { getActivateAccountModalVisible } from 'store/selectors/user';
import { DefaultText, PrimaryDefaultText } from 'style/common';
import { isEmailValid } from 'utils/validations';

import { TEXTS } from '../constants';

export const ActivateAccountModal = () => {
  const dispatch = useDispatch();
  const isActivateAccountModalVisible = useSelector(getActivateAccountModalVisible);
  const styles = useAuthStyles();

  const toastRef = useRef<ToastContainer>(null);

  const { isLoading, email, setEmail, makeRequest } = useResendActivateEmail(toastRef.current);

  const onModalClose = () => dispatch(setIsActivateAccountModalVisible(false));

  const onSend = () => makeRequest(onModalClose);

  return (
    <Modal
      modalContentStyle={styles.modalWrapper}
      modalIsVisible={isActivateAccountModalVisible}
      onRequestClose={onModalClose}
      toastRef={toastRef}>
      <View>
        <PrimaryDefaultText style={styles.loginPageModalTitle}>Activate your account</PrimaryDefaultText>
        <DefaultText style={styles.loginPageModalDescription}>
          Enter the email connected with your account to receive an email to activate your account.
        </DefaultText>
        <TextFieldWithLabel
          height={56}
          labelText={TEXTS.FG_INPUT_LABEL}
          placeholder={TEXTS.FG_INPUT_PLACEHOLDER}
          autoCompleteType='off'
          value={email}
          onChangeText={setEmail}
        />
        <View style={styles.buttonWrapper}>
          <Button onPress={onSend} variant='secondary' disabled={!isEmailValid(email)} text={TEXTS.FG_BUTTON_TEXT} />
        </View>
        {isLoading && <FullScreenLoader />}
      </View>
    </Modal>
  );
};
