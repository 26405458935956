import React from 'react';

import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useSelector } from 'react-redux';

import { ClipBoard } from 'assets/icons';
import View from 'assets/icons/View';
import { ServiceActionItem, Spacer } from 'components';
import { Layout } from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import NotificationPopup from 'components/NotificationPopup';
import benefitsLocalization from 'localization/screens/Benefits';
import { Routes } from 'routes/main';
import { BenefitsInvestigationCard } from 'screens/main/Benefits/components/BenefitsInvestigationCard';
import { HistoryItemsBlock } from 'screens/main/Benefits/components/HistoryItemsBlock';
import { demoTrackerData } from 'screens/main/Benefits/demoBenefitsTrackerMock';
import { getAppDataIsLoading } from 'store/selectors';
import { HeaderText, HeaderWrapper, StandardText } from 'style/common';

import { notificationContent } from './constants';
import { useBenefitsPageStyle } from './styles';

const { title, description, header } = benefitsLocalization;

export const DemoBenefitsPage = () => {
  const styles = useBenefitsPageStyle();
  const isAppDataLoading = useSelector(getAppDataIsLoading);

  const navigation = useNavigation<DrawerNavigationHelpers>();

  if (isAppDataLoading) return <FullScreenLoader />;

  const handleAction = () => navigation.navigate(Routes.PROFILE, { isInsurance: true });

  return (
    <>
      <Layout title={title} description={description} navigation={navigation}>
        <HeaderWrapper>
          <HeaderText>{header}</HeaderText>
          <StandardText style={styles.lastRunText}>Last run on January 30, 2022</StandardText>
        </HeaderWrapper>
        <BenefitsInvestigationCard.Demo />
        <>
          <ServiceActionItem
            onAction={handleAction}
            actionLabel='View Your Full Summary of Benefits'
            IconComponent={ClipBoard}
          />
          <ServiceActionItem
            onAction={handleAction}
            actionLabel='View or Update Your Insurance Information'
            IconComponent={View}
          />
        </>
        <Spacer size={12} />
        <HeaderText style={styles.historyTitle}>History</HeaderText>
        <HistoryItemsBlock trackerData={demoTrackerData} />
      </Layout>
      <NotificationPopup {...notificationContent} />
    </>
  );
};
