import React from 'react';

import { View } from 'react-native';

import { StandardText } from 'style/common';

import { useExplanationModalStyle } from './styles';

export const CopayDefinitionContent = () => {
  const styles = useExplanationModalStyle();

  return (
    <>
      <StandardText style={styles.exampleTitle}>For Example:</StandardText>
      <StandardText style={styles.paragraphStyle}>
        If your copay is
        <StandardText style={styles.firstGrayColorText}> $30 </StandardText>
        and
      </StandardText>
      <StandardText style={styles.paragraphStyle}>
        A.) If you
        <StandardText style={styles.secondGrayColorText}> HAVE </StandardText>
        paid your full deductible, if you receive a bill for
        <StandardText style={styles.firstGrayColorText}> $100 then you will pay $30.</StandardText>
      </StandardText>
      <StandardText style={styles.paragraphStyle}>
        B.) If you
        <StandardText style={styles.secondGrayColorText}> HAVE NOT </StandardText>
        paid your deductible then
        <StandardText style={styles.firstGrayColorText}> you will pay $100</StandardText>
      </StandardText>
    </>
  );
};

export const CoinsuranceDefinitionContent = () => {
  const styles = useExplanationModalStyle();

  return (
    <View>
      <StandardText style={styles.exampleTitle}>For Example:</StandardText>
      <StandardText style={styles.paragraphStyle}>
        If your coinsurance is
        <StandardText style={styles.firstGrayColorText}> 50% </StandardText>
        and
      </StandardText>
      <StandardText style={styles.paragraphStyle}>
        A.) If you
        <StandardText style={styles.secondGrayColorText}> HAVE </StandardText>
        paid your full deductible, if you receive a bill for
        <StandardText style={styles.firstGrayColorText}> $100 then you will pay $50.</StandardText>
      </StandardText>
      <StandardText style={styles.paragraphStyle}>
        B.) If you
        <StandardText style={styles.secondGrayColorText}> HAVE NOT </StandardText>
        paid your deductible then
        <StandardText style={styles.firstGrayColorText}> you will pay $100</StandardText>
      </StandardText>
    </View>
  );
};
