import queryString from 'query-string';

import { NotificationsInterface } from 'api';
import { persistor, reduxStore } from 'store';
import { setExternalPartnerProgramUuid, setSelectedSurveyId, setShouldForceSurveyOpen } from 'store/actions';

type ApplyActionsForCapturedParamsQueryType = {
  hash: string;
  survey_uuid: string;
  partner_program_uuid: string;
};

const redirectionPath = '/overview';
const redirectionLink = 'https://corp.trialcard.com/capabilities/patient-engagement-and-adherence/';

// This works only on web
export const applyActionsForCapturedParams = () => {
  const {
    hash,
    survey_uuid,
    partner_program_uuid = '',
  } = queryString.parse(location.search) as unknown as ApplyActionsForCapturedParamsQueryType; // utm_source = sms

  if (location.pathname === redirectionPath) location.replace(redirectionLink);

  if (partner_program_uuid) {
    persistor.persist();
    reduxStore.dispatch(setExternalPartnerProgramUuid(partner_program_uuid));
  }

  if (survey_uuid) {
    reduxStore.dispatch(setSelectedSurveyId(survey_uuid as string));
    reduxStore.dispatch(setShouldForceSurveyOpen(true));
  }
  // trigger the next step for copay card only demo
  if (hash) NotificationsInterface.triggerNextNotificationTile(String(hash));
};
