import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useReminderOverviewStyles = () => {
  const { colors, fontFamilies, fontSizes } = useTheme();

  return StyleSheet.create({
    wrapper: {
      maxWidth: 290,
      padding: 8,
      paddingTop: 0,
    },
    reminderTitle: {
      ...fontSizes[24],
      color: colors.gray1,
      fontFamily: fontFamilies.bold,
      marginBottom: 16,
    },
    reminderDescription: {
      ...fontSizes[16],
      color: colors.gray1,
    },
    formWrapper: {
      borderWidth: 1,
      borderColor: colors.mangoSecondary,
      borderRadius: 8,
      marginVertical: 24,
      padding: 24,
    },
    medicationName: {
      ...fontSizes[16],
      color: colors.gray1,
      fontFamily: fontFamilies.bold,
    },
    medicationParams: {
      ...fontSizes[16],
      color: colors.gray1,
    },
    mangoButton: {
      width: 126,
    },
    buttonWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 8,
    },
    editButton: {
      width: 126,
    },
    skipButtonWrapper: {
      marginTop: 20,
    },
    skipButton: {
      ...fontSizes[16],
      color: colors.gray1,
    },
    headerImage: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: 10,
    },
  });
};
