import { StyleSheet, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';

import { getBoxShadowStyle } from 'style/common';
import { isIOSDevice } from 'utils/helpers';

interface StylesProps {
  padding?: number;
  style?: ViewStyle;
  isNotificationCard?: boolean;
}

export const useCardStyle = ({ padding, style, isNotificationCard }: StylesProps) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    main: {
      position: 'relative',
      backgroundColor: colors.white,
      borderRadius: 16,
      padding: padding ?? 32,
      marginBottom: 20,
      zIndex: 5,
      ...(isNotificationCard && {
        borderLeftWidth: 16,
        borderLeftColor: colors.secondary,
      }),
      ...getBoxShadowStyle(),
      ...(!isIOSDevice && { overflow: 'hidden' }), // TODO: hide Card content that exceeds its dimensions (iOS)
      ...style,
    },
  });
};
