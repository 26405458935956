import apiRequest, { apiPrefix, ProgramRequestConfig } from 'utils/network';

import {
  NotificationTilesReturnData,
  NotificationUpdateData,
  SimpleTextNotificationTileReturnData,
  UpdateSimpleTextNotificationParams,
} from './types';

const NOTIFICATIONS_URL = '/v1/updateNotificationStatus/';

const NOTIFICATION_TILES_URL = '/copay/notifications/';
const TRIGGER_NEXT_NOTIFICATION_TILE_URL = '/authapi/v1/copay/nextStep/';
const SET_NOTIFICATION_TILE_SHOWN_URL = '/copay/notificationShown/';

const SIMPLE_TEXT_NOTIFICATION_TILES_URL = '/notification-tiles/user';

const programConfig: ProgramRequestConfig = {
  isAuthRequest: true,
};

export const NotificationsInterface = {
  getSimpleTextNotificationTiles: (): Promise<SimpleTextNotificationTileReturnData> =>
    apiRequest({
      method: 'GET',
      endpoint: SIMPLE_TEXT_NOTIFICATION_TILES_URL,
      baseURL: apiPrefix,
      isProgram: true,
    }),
  updateSimpleTextNotificationTile: ({
    id,
    ...params
  }: UpdateSimpleTextNotificationParams): Promise<SimpleTextNotificationTileReturnData> =>
    apiRequest({
      method: 'PATCH',
      endpoint: `${SIMPLE_TEXT_NOTIFICATION_TILES_URL}/${id}`,
      baseURL: apiPrefix,
      body: params,
      isProgram: true,
    }),
  update: (data: NotificationUpdateData) => {
    return apiRequest({
      method: 'PUT',
      endpoint: NOTIFICATIONS_URL,
      baseURL: `${apiPrefix}/api`,
      body: data,
    });
  },
  getNotificationTiles: (): Promise<NotificationTilesReturnData> =>
    apiRequest({
      method: 'GET',
      endpoint: NOTIFICATION_TILES_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig,
    }),
  triggerNextNotificationTile: (hash: string) =>
    apiRequest({
      method: 'GET',
      endpoint: `${TRIGGER_NEXT_NOTIFICATION_TILE_URL}${hash}`,
      baseURL: apiPrefix,
    }),
  setNotificationTileShown: (notificationId: number) =>
    apiRequest({
      method: 'PUT',
      endpoint: `${SET_NOTIFICATION_TILE_SHOWN_URL}${notificationId}`,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig,
    }),
};
