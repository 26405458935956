import { useEffect, useState } from 'react';

import { Image, ImageSourcePropType } from 'react-native';

import { getNativeUrl } from 'utils/white-labeling';

type Config = Partial<{
  url: string | ImageSourcePropType;
  widthValue: number | null;
  isSrc: boolean;
  heightValue: number;
}>;

const useImageSizes = ({ url, widthValue, isSrc, heightValue }: Config) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!url) return;

    if (typeof url === 'string') {
      const currentUrl = isSrc ? url : getNativeUrl(url).uri;
      if (!currentUrl) return;
      Image.getSize(currentUrl, (srcWidth, srcHeight) => {
        const widthRatio = (widthValue || 40) / srcHeight;
        const heightRatio = (heightValue || 40) / srcWidth;
        setWidth(widthRatio * srcWidth);
        setHeight(heightRatio * srcHeight);
      });
      return;
    }

    const { width: assetWidth, height: assetHeight } = Image.resolveAssetSource(url);
    const heightRatio = (heightValue || 40) / assetWidth;
    const widthRatio = (widthValue || 40) / assetHeight;
    setWidth(widthRatio * assetWidth);
    setHeight(heightRatio * assetHeight);
  }, [url, widthValue, isSrc, heightValue]);

  return {
    imageHeight: height,
    imageWidth: width,
  };
};

export default useImageSizes;
