import React, { useRef, useEffect } from 'react';

import { AnimatedCircularProgress } from 'react-native-circular-progress';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { DefaultText } from 'style/common';
import { defaultTheme } from 'style/theme';

export type CircularProgressBarProps = { value: number | null; initialColor?: string };

export const CircularProgressBar = ({ value, initialColor }: CircularProgressBarProps) => {
  const progressComponentRef = useRef<AnimatedCircularProgress | null>(null);
  const { isMobile } = useDeviceInfoContext();

  useEffect(() => {
    progressComponentRef.current?.animate(value || 0, 3000);
  }, [value]);

  const ref = (ref: AnimatedCircularProgress) => (progressComponentRef.current = ref);

  return (
    <AnimatedCircularProgress
      size={104}
      width={10}
      fill={value || 0}
      tintColor={defaultTheme.colors.positive}
      backgroundColor={initialColor}
      rotation={0}
      ref={ref}>
      {(percent) => (
        <DefaultText
          style={{
            fontFamily: defaultTheme.fontFamilies.bold,
            fontSize: isMobile ? defaultTheme.fontSizes[18].fontSize : defaultTheme.fontSizes[24].fontSize,
          }}>
          {value === null ? `--` : `${percent.toFixed(0)}%`}
        </DefaultText>
      )}
    </AnimatedCircularProgress>
  );
};

export default CircularProgressBar;
