import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useLinerProgressBarStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    wrapper: {
      flexShrink: 1,
      flexBasis: 'auto',
      width: '100%',
      height: 10,
      flexDirection: 'row',
      backgroundColor: colors.gray3,
      borderRadius: 5,
      overflow: 'hidden',
      marginHorizontal: 20,
    },
    bar: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.primary,
      width: '50%',
    },
  });
};
