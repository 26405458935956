import styled, { css } from 'styled-components/native';

import { ButtonsWrapper, Wrapper } from '../ConfirmShipingAddress/styles';

export const ChangeAddressWrapper = styled(Wrapper)`
  ${({ theme }) =>
    !theme.isMobile &&
    css`
      width: 50vw;
    `}
`;

export const FormWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 12px;
`;

export const InputBlock = styled.View`
  width: 48%;
  margin-bottom: 20px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
    `}
`;
