import { MFAConfigInfoState } from './types';

export const INITIAL_STATE: MFAConfigInfoState = {
  mfaInfo: {
    session: '',
    userId: '',
    userName: '',
    mfaConfig: {
      id: 0,
      uuid: '',
      channels: [
        {
          name: 'phone_number',
          isEnabled: false,
        },
      ],
      isEnabled: false,
      createdAt: '',
      updatedAt: '',
      manufactureProgramUuid: '',
    },
    isPhoneAdded: false,
  },
  mfaCountingDown: 0,
  userMFAPhoneNumber: '',
  isMFACodeWasSend: false,
};
