import { Method } from 'axios';

import { env } from 'config';
import { StatusCode } from 'enums';
import { reduxStore } from 'store';
import { PSPProgram } from 'store/reducers/user-info/types/program';
import axiosClient from 'utils/axiosClient';

import {
  BAD_INTERNET_CONNECTION_ERROR_MESSAGE,
  EMAIL_OR_PASSWORD_ARE_INVALID_ERROR_MESSAGE,
  SOMETHING_WRONG_ERROR_MESSAGE,
} from './constants';
import { isNativeDevice } from './helpers';

export type ProgramRequestConfig = {
  withProxy?: boolean;
  isAuthRequest?: boolean;
};

type ApiRequestParams = {
  endpoint: string;
  method?: Method;
  body?: any;
  params?: any;
  baseURL?: string;
  headers?: any;
  isProgram?: boolean;
  programConfig?: ProgramRequestConfig;
};

type CurrentProgramEndpointConfig = {
  endpoint: string;
  withProxy: boolean;
  isAuthRequest: boolean;
};

const unauthorizedURLS = ['/login', '/api/v1/partner-registration/activate/'];
const basePath = '/api/v1';
const baseAuthPath = '/authapi/v1';

const getCurrentProgramEndpoint = (config: CurrentProgramEndpointConfig): string => {
  const { endpoint, withProxy, isAuthRequest } = config;
  const { programs, selectedProgramId }: { programs: PSPProgram[]; selectedProgramId: number } = reduxStore.getState()
    .userInfo as any;
  const currentProgram = programs.find((program) => program.id === selectedProgramId);

  if (!currentProgram) {
    throw new Error("Couldn't get default program");
  }

  const { uuid, manufacturerId } = currentProgram;

  const proxyUrl = withProxy ? `/proxy/${manufacturerId}` : '';
  const url = `${isAuthRequest ? baseAuthPath : basePath}${proxyUrl}${endpoint}`.trim();

  return `${url}?manufacture_program_uuid=${uuid}`;
};

const generateAuthorizationHeaders = (endpoint: string) => {
  const needsToken = !(unauthorizedURLS.indexOf(endpoint) > -1);
  const token = reduxStore.getState().userInfo.token;
  if (!needsToken || !token) return null;

  return { Authorization: `Bearer ${token}` };
};

export const BASE_URL: string = (() => {
  if (isNativeDevice) {
    const { apiURL } = env;
    return apiURL;
  }
  return '';
})();

const apiRequest = async <T>({
  endpoint,
  method = 'GET',
  body = null,
  params = null,
  baseURL, // to override base url
  headers = {},
  isProgram = false,
  programConfig: { withProxy = false, isAuthRequest = false } = {},
}: ApiRequestParams): Promise<any> => {
  return axiosClient
    .request({
      baseURL: baseURL || BASE_URL,
      url: isProgram ? getCurrentProgramEndpoint({ endpoint, withProxy, isAuthRequest }) : endpoint,
      method,
      params,
      data: body,
      headers: {
        ...generateAuthorizationHeaders(endpoint),
        ...headers,
      },
    })
    .catch((error) => {
      if (error?.response?.status === StatusCode.ClientErrorUnauthorized) {
        throw new Error(EMAIL_OR_PASSWORD_ARE_INVALID_ERROR_MESSAGE);
      } else if (error?.response?.status === StatusCode.ClientErrorForbidden) {
        throw new Error(BAD_INTERNET_CONNECTION_ERROR_MESSAGE);
      } else if (error?.message?.includes('Network Error') || error?.message?.includes('undefined')) {
        throw new Error(SOMETHING_WRONG_ERROR_MESSAGE);
      } else {
        throw error;
      }
    });
};

export const apiPrefix = isNativeDevice ? env.apiURL : '';

export default apiRequest;
