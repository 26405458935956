import uuid from 'react-native-uuid';

import { InsuranceTypeEnum } from 'enums';
import type { AddUserInsurance } from 'store/actions/user/actionCreators/insurances/addUserInsurance';
import {
  INITIAL_STATE,
  initialMedicalInsurance,
  initialPharmacyInsurance,
} from 'store/reducers/user-info/initialState';
import type { User } from 'store/reducers/user-info/types';

const addUserInsurance = (state = INITIAL_STATE, payload: ReturnType<AddUserInsurance>['payload']): User => {
  const selectedInsurance = (() => {
    if (payload === InsuranceTypeEnum.MEDICAL) {
      return { ...initialMedicalInsurance, _id: uuid.v4() as string };
    }
    if (payload === InsuranceTypeEnum.PHARMACY) {
      return { ...initialPharmacyInsurance, _id: uuid.v4() as string };
    }
  })();

  return {
    ...state,
    ...(selectedInsurance && { insurances: [...state.insurances, selectedInsurance] }),
  };
};

export default addUserInsurance;
