import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useProgressLegendStyles = () => {
  const { isMobile, ...theme } = useTheme();

  return {
    colors: {
      positiveColor: theme.colors.positive,
      negativeColor: theme.colors.gray5,
    },

    styles: StyleSheet.create({
      container: { flexDirection: 'row' },
      wrapper: { marginLeft: 24, width: '48%' },
      positiveTextWrapper: { flexDirection: 'row', alignItems: 'center', marginBottom: 16 },
      negativeTextWrapper: { flexDirection: 'row', alignItems: 'center' },
      dosesText: { marginLeft: 10, color: theme.colors.gray2 },
      nullDosesText: {
        marginLeft: 10,
        paddingVertical: 18,
        alignItems: 'center',
        width: 174,
        color: theme.colors.gray2,
        ...(isMobile && { marginLeft: 22, paddingVertical: 16 }),
      },
    }),
  };
};
