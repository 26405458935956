import { SetCurrentlyActiveConsent } from 'store/actions/user/actionCreators/consents/setCurrentlyActiveConsent';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setCurrentlyActiveConsent = (
  state = INITIAL_STATE,
  payload: ReturnType<SetCurrentlyActiveConsent>['payload']
): User => {
  return { ...state, currentlyActiveConsent: payload.consent };
};

export default setCurrentlyActiveConsent;
