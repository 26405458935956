import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { closeButtonStyles } from 'style/common';
import { defaultTheme } from 'style/theme';
import { isNativeDevice } from 'utils/helpers';

export const useTemplateBoxStyles = () => {
  const { isMobile, ...theme } = useTheme();

  return StyleSheet.create({
    container: {
      backgroundColor: 'white',
      paddingHorizontal: isMobile ? 16 : 24,
      paddingVertical: 16,
      borderTopRightRadius: 16,
      borderTopLeftRadius: 16,
      width: 175,
      marginLeft: isMobile ? -60 : -75,
      maxHeight: 105,
      marginTop: 'auto',
    },
    textRow: { width: 80, height: 12, backgroundColor: theme.colors.gray2, borderRadius: 8 },
    circleTextContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 12,
    },
    circle: { width: 20, height: 20, borderRadius: 12, backgroundColor: theme.colors.gray1, marginRight: 16 },
    divider16: { height: 12 },
  });
};

export const useSurveyCardStyles = () => {
  const { colors, isMobile } = useTheme();

  return StyleSheet.create({
    surveyCardWrapper: {
      overflow: 'hidden',
      flexDirection: 'row',
      backgroundColor: colors.primary,
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
    },
    underBox: isNativeDevice
      ? {
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          backgroundColor: colors.primary,
          zIndex: 1,
        }
      : {
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
          height: '100%',
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          backgroundColor: colors.primary,
          zIndex: 1,
        },
    overBox: {
      borderTopRightRadius: 16,
      borderBottomRightRadius: 16,
      backgroundColor: colors.white,
      padding: 30,
      width: isMobile ? '80%' : '82%',
    },
    templateImageBox: {
      width: '100%',
      height: '100%',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
    },
    closeButton: closeButtonStyles.button,
    hoverButton: closeButtonStyles.onHover,
    header: {
      fontFamily: defaultTheme.fontFamilies.bold,
      ...defaultTheme.fontSizes[16],
      color: colors.secondary,
    },
    description: {
      ...defaultTheme.fontSizes[16],
      color: defaultTheme.colors.gray1,
      width: isMobile ? '80%' : '90%',
      marginTop: 8,
      marginBottom: 24,
      marginRight: isMobile ? 8 : 32,
    },
    clickButton: {
      marginTop: 20,
    },
    clickButtonText: {
      ...defaultTheme.fontSizes[16],
      color: defaultTheme.colors.gray1,
      ...(isMobile && { maxWidth: '70%' }),
    },
    closeButtonWrapper: {
      position: 'absolute',
      top: 10,
      right: 15,
      zIndex: 2,
    },
  });
};
