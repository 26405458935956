import React from 'react';

import { createDrawerNavigator, DrawerNavigationOptions } from '@react-navigation/drawer';
import { Platform, StatusBar, View } from 'react-native';

import { DrawerStackParamList } from '@types';
import LeftSideDrawer from 'components/LeftSideDrawer';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useChangeFavicon } from 'hooks';

import { DrawerContent } from './CustomDrawerContent';
import { drawerContentStyles } from './styles';
import { useGetAppScreens } from './useGetAppScreens';

const DrawerNavigator = createDrawerNavigator<DrawerStackParamList>();

export const Drawer = () => {
  const { isMobile } = useDeviceInfoContext();

  const screens = useGetAppScreens();

  const screenOptions: DrawerNavigationOptions = {
    drawerPosition: 'right',
    ...(Platform.OS === 'ios' && { drawerType: 'front' }),
    lazy: true,
    headerShown: false,
    drawerStyle: {
      paddingTop: isMobile ? StatusBar.currentHeight : 0,
      ...(!isMobile && { display: 'none' }),
    },
  };

  useChangeFavicon();

  return (
    <View style={drawerContentStyles.navigatorContainer}>
      {!isMobile && <LeftSideDrawer />}
      <DrawerNavigator.Navigator
        useLegacyImplementation
        drawerContent={DrawerContent}
        screenOptions={screenOptions}
        backBehavior='history'>
        {screens.map(({ name, ...props }) => (
          <DrawerNavigator.Screen
            key={name}
            name={name as keyof DrawerStackParamList}
            options={{ title: props.tabName }}
            {...props}
          />
        ))}
      </DrawerNavigator.Navigator>
    </View>
  );
};

export default Drawer;
