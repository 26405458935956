import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { CommunicationConsentInterface } from 'api/Consents/communicationConsentsApi';
import { CommunicationConsents } from 'store/reducers/user-info/types/consent';
import { getConsentModalsQueue } from 'store/selectors';

export const useGetConsents = () => {
  const [isLoading, setLoading] = useState(false);
  const [consents, setConsents] = useState<CommunicationConsents[]>([]);

  const queue = useSelector(getConsentModalsQueue);

  useEffect(() => {
    setLoading(true);

    CommunicationConsentInterface.getConfig().then(({ results }) => {
      const list: CommunicationConsents[] = [];

      results.forEach((consent) => {
        if (list.find((c) => c[0]?.manufactureProgramUuid === consent.manufactureProgramUuid)) return;

        const isAppConsent = consent.manufactureProgramUuid === null;

        const programConsents = results.filter((c) => c.manufactureProgramUuid === consent.manufactureProgramUuid);

        if (!isAppConsent)
          programConsents.unshift({
            ...consent,
            consentCategoryName: 'All',
            status: programConsents.every((c) => c?.status === 'ACTIVE') ? 'ACTIVE' : 'INACTIVE',
          });

        list.push(programConsents);
      });

      setConsents(list);
      setLoading(false);
    });
  }, [queue]);

  return { consents, isLoading };
};
