// google analytics constants
const VIEW_ADHERENCE_CONTENT = 'VIEW_ADHERENCE_CONTENT';
const EDIT_DAILY_MEDICATION_STATUS = 'EDIT_DAILY_MEDICATION_STATUS';
const VIEW_PROGRAM_MEDICATION_TRACKER = 'VIEW_PROGRAM_MEDICATION_TRACKER';

export const medicationTrackerAnalyticsActions = {
  VIEW_ADHERENCE_CONTENT,
  EDIT_DAILY_MEDICATION_STATUS,
  VIEW_PROGRAM_MEDICATION_TRACKER,
};
