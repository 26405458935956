import { StyleSheet } from 'react-native';
import * as defaultStyle from '../style';
import { mergeDeep } from '../utils'

export default function getStyle(theme = {}) {
    const appStyle = { ...defaultStyle, ...theme };

    const initialStyles = {
        container: {
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: appStyle.calendarBackground
        },
        dayContainer: {
            flex: 1,
            alignItems: 'center'
        },
        emptyDayContainer: {
            flex: 1
        },
        monthView: {
            backgroundColor: appStyle.calendarBackground
        },
        week: {
            marginVertical: appStyle.weekVerticalMargin,
            flexDirection: 'row',
            justifyContent: 'space-around'
        }
    }

    const mergedStyles = !!theme['stylesheet.calendar.main'] ? mergeDeep(initialStyles, theme['stylesheet.calendar.main']) : initialStyles;

    return StyleSheet.create(mergedStyles);
}
