import { SetIsActivateAccountModalVisibleAction } from 'store/actions/user/actionCreators/setIsActivateAccountModalVisible';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

export const setIsActivateAccountModalVisible = (
  state = INITIAL_STATE,
  payload: SetIsActivateAccountModalVisibleAction['payload']
): User => ({
  ...state,
  isActivateAccountModalVisible: payload,
});
