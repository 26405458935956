import { gaHandler } from 'analytics/global_handlers';
import { EventCategories } from 'analytics/types';
import { getFAPAnalyticsConstant, financialAnalyticsActions } from 'screens/main/FinancialAssistance/constants.';

export type FAPType = 'PAP' | 'COPAY' | 'BRIDGE' | 'QUICKSTART' | 'FOUNDATION';

const { VIEW_FINANCIAL_ASSISTANCE } = financialAnalyticsActions;

export const getFAPActionsByType = (type?: FAPType) => {
  if (!type) return VIEW_FINANCIAL_ASSISTANCE;

  return getFAPAnalyticsConstant(type);
};

export const financialAssistanceAnalyticsHandler = gaHandler(EventCategories.TRACK_FINANCIAL_ASSISTANCE);
