import React from 'react';

import { TouchableOpacity, View } from 'react-native';

import { StandardText, SubTitle } from 'style/common';

import { useProfileLayoutStyles } from './styles';
import { ProfileInfoLayoutProps } from './types';

export const ProfileInfoLayout = ({
  wrapperStyle,
  title,
  titleColor,
  description,
  isEditMode,
  isProcessing,
  editButtonText = 'Edit',
  saveButtonText = 'Save',
  iconComponent,
  savingIsDisabled,
  setEditMode,
  children,
  withActionButton = true,
}: ProfileInfoLayoutProps) => {
  const showIconJSX = iconComponent && isProcessing ? iconComponent : null;

  const styles = useProfileLayoutStyles(titleColor, savingIsDisabled, !!showIconJSX);

  const editModeClickHandler = () => {
    setEditMode?.((prev) => !prev);
  };

  return (
    <View style={wrapperStyle}>
      <View style={styles.headerWrapper}>
        <SubTitle style={styles.subTitle}>{title}</SubTitle>
        {withActionButton && (
          <TouchableOpacity onPress={editModeClickHandler} style={styles.editModeButton} disabled={savingIsDisabled}>
            <StandardText style={styles.standardText}>{isEditMode ? saveButtonText : editButtonText}</StandardText>
          </TouchableOpacity>
        )}
      </View>
      {!!description && (
        <View style={styles.descriptionWrapper}>
          {showIconJSX}
          <StandardText style={styles.description}>{description}</StandardText>
        </View>
      )}
      {children}
    </View>
  );
};
