import { ServiceTypesEnum } from 'api/AdditionalServicesInterface/types';
import { Injection, Video } from 'assets/icons';
import { ServiceActionItemProps } from 'components/ServiceActionItem/types';

export const demoMockInjectionService: ServiceActionItemProps = {
  id: 0,
  IconComponent: Injection,
  actionLabel: 'Schedule an Injection Training',
  type: ServiceTypesEnum.FORM_REQUEST,
};

export const demoMockVideoService: ServiceActionItemProps = {
  id: 1,
  IconComponent: Video,
  actionLabel: 'View Injection Training',
  type: ServiceTypesEnum.RESOURCE,
};
