import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useInsuranceStyles = () => {
  const { colors, isTablet } = useTheme();

  return StyleSheet.create({
    iconWrapper: {
      padding: 6,
      marginRight: 16,
      borderRadius: 16,
      backgroundColor: colors.positive,
    },
    insuranceInformationWrapper: {
      flexDirection: isTablet ? 'column' : 'row',
    },
  });
};
