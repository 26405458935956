import { SetSelectedCopayCard } from 'store/actions/user/actionCreators/setSelectedCopayCard';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setSelectedCopayCard = (state = INITIAL_STATE, payload: ReturnType<SetSelectedCopayCard>['payload']): User => {
  return {
    ...state,
    selectedCopayCard: payload,
  };
};

export default setSelectedCopayCard;
