import 'react-native-gesture-handler';
import React from 'react';

import { registerRootComponent } from 'expo';
import { LogBox, StyleProp, Text, TextInput, ViewStyle } from 'react-native';
import ErrorBoundary from 'react-native-error-boundary';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from 'sentry-expo';

import { useAnalyticsScript } from 'analytics/useAnalyticsScript';
import { BoundaryUI, Navigation } from 'components';
import { FullScreenLoader } from 'components/Loaders';
import { env } from 'config';
import { AppThemeProvider } from 'contexts/AppThemeProvider';
import { AppToastProvider } from 'contexts/AppToastProvider';
import { DeviceInfoContextProvider } from 'contexts/DeviceInfo';
import { NotificationsContextProvider } from 'contexts/notifications';
import { useAppBootstrapping, useExternalScript, useRefsClear } from 'hooks';
import { persistor, reduxStore } from 'store';
import { AppContainer } from 'style/global';
import { isNativeDevice } from 'utils/helpers';

LogBox.ignoreLogs(['Require cycle:']);

const { firebaseMeasurementId, name } = env;

// Sentry will be collecting reports only for production and staging
if (name !== 'DEVELOPMENT') {
  Sentry.init({
    dsn: 'https://91b15fb07b5a4d4ea89f9927877840ad@o4504593801150464.ingest.sentry.io/4504634332872704',
    enableInExpoDevelopment: false,
    debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  });
}

export const App = () => {
  const { appIsLoading } = useAppBootstrapping();
  const style = !isNativeDevice ? { overflowX: 'hidden' } : { overflow: 'hidden' };

  useRefsClear();

  // Google Analytics initialization hooks
  const status = useExternalScript(`https://www.googletagmanager.com/gtag/js?id=${firebaseMeasurementId}`);
  useAnalyticsScript(status === 'ready');

  const errorHandler = (error: Error, stackTrace: string) => {
    // Sentry will be collecting reports only for production and staging
    if (name === 'DEVELOPMENT') return;
    const exception = { ...error, stackTrace, envName: name };
    if (isNativeDevice) {
      return Sentry.Native.captureException(exception);
    }
    Sentry.Browser.captureException(exception);
  };

  return (
    <ReduxProvider store={reduxStore}>
      <DeviceInfoContextProvider>
        <AppThemeProvider>
          <PersistGate loading={<FullScreenLoader />} persistor={persistor}>
            <NotificationsContextProvider>
              <AppContainer>
                <AppToastProvider>
                  <SafeAreaProvider style={style as StyleProp<ViewStyle>}>
                    <ErrorBoundary FallbackComponent={BoundaryUI} onError={errorHandler}>
                      <Navigation appIsLoading={appIsLoading || status !== 'ready'} />
                    </ErrorBoundary>
                  </SafeAreaProvider>
                </AppToastProvider>
              </AppContainer>
            </NotificationsContextProvider>
          </PersistGate>
        </AppThemeProvider>
      </DeviceInfoContextProvider>
    </ReduxProvider>
  );
};

registerRootComponent(App);

// Temporarily we are disabling the font scaling in this project. Until we have a solid solution and time for scaling support.
// We’ll be using maxFontSizeMultiplier prop to support font-scaling, which will take time and thinking.
// @ts-ignore
if (Text.defaultProps == null) {
  // @ts-ignore
  Text.defaultProps = { allowFontScaling: false };
}

// @ts-ignore
if (TextInput.defaultProps == null) {
  // @ts-ignore
  TextInput.defaultProps = { allowFontScaling: false };
}
