import { SeverityFilter } from './types';

export const severityFilters = [
  {
    id: 1,
    severity: 3,
    text: 'Severe',
  },
  {
    id: 2,
    severity: 2,
    text: 'Moderate',
  },
  {
    id: 3,
    severity: 1,
    text: 'Minor',
  },
] as SeverityFilter[];
