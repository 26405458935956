import { CopayFapMetricsDTO, FinancialAssistanceProgramDTO } from 'api/FinancialAssistanceInterface/types';

import { CopayCardConfig, CopayCardType } from './types';

const getCopayImageUrlByType = ({
  copayType,
  medicalImage,
  pharmacyImage,
}: {
  copayType?: CopayCardType;
  medicalImage?: CopayCardConfig['medicalImage'];
  pharmacyImage?: CopayCardConfig['pharmacyImage'];
}) => {
  switch (copayType) {
    case 'MEDICAL':
      return medicalImage || null;

    case 'PHARMACY':
      return pharmacyImage || null;

    default:
      return '';
  }
};

export const configMapper = (config: FinancialAssistanceProgramDTO): CopayCardConfig => ({
  id: 1,
  medicalPatientInstructions: config?.medicalPatientInstructions || '',
  pharmacyPatientInstructions: config?.pharmacyPatientInstructions || '',
  processorInstructions: config?.processorInstructions || '',
  medicalTermsAndConditions: config?.medicalTermsAndConditions || '',
  pharmacyTermsAndConditions: config?.pharmacyTermsAndConditions || '',
  isCopayRenewal: config?.isCopayRenewal || false,
  isBenefitsRemaining: config?.isBenefitsRemaining || false,
  isBenefitsUsed: config?.isBenefitsUsed || false,
  isFillsRemaining: config?.isFillsRemaining || false,
  isFillsUsed: config?.isFillsUsed || false,
  isPrescriptionRefill: config?.isPrescriptionRefill || false,
  createdAt: '2020-01-01',
  updatedAt: '2020-01-01',
  pharmacyImage: config?.pharmacyImage?.url || null,
  medicalImage: config?.medicalImage?.url || null,
});

export const copayCardMetricsMapper = ({
  fapData,
  fapMetrics,
}: {
  fapData: FinancialAssistanceProgramDTO;
  fapMetrics?: CopayFapMetricsDTO;
}) => ({
  createdAt: fapMetrics?.createdAt ?? '',
  updatedAt: fapMetrics?.updatedAt ?? '',
  id: fapMetrics?.id ?? 0,
  uuid: fapMetrics?.uuid ?? '',
  bin: fapMetrics?.bin ?? 0,
  group: fapMetrics?.group ?? 0,
  memberNumber: fapMetrics?.memberNumber ?? 0,
  enrollmentDate: fapMetrics?.enrollmentDate ?? '',
  maxAvailableBenefit: fapMetrics?.maxAvailableBenefit ?? 0,
  benefitsUsed: fapMetrics?.benefitsUsed ?? 0,
  benefitsRemaining: fapMetrics?.benefitsRemaining ?? 0,
  totalFillsAllowed: fapMetrics?.totalFillsAllowed ?? 0,
  fillsUsed: fapMetrics?.fillsUsed ?? 0,
  remainingFills: fapMetrics?.remainingFills ?? 0,
  activationDate: fapMetrics?.activationDate ?? '',
  lastDispenseDate: fapMetrics?.lastDispenseDate ?? '',
  copayRenewalDate: fapMetrics?.copayRenewalDate ?? '',
  type: fapMetrics?.cardType ?? '',
  fileUrl:
    getCopayImageUrlByType({
      copayType: fapMetrics?.cardType,
      medicalImage: fapData?.medicalImage?.url,
      pharmacyImage: fapData?.pharmacyImage?.url,
    }) ?? '',
  // initialize config of the fa-programs
  config: configMapper(fapData),
});
