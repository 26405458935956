import { ControllerProps } from 'react-hook-form/dist/types/controller';

import { REQUIRED_FIELD } from 'utils/constants';

import { ContactSectionInputFields } from './types';

export const getInputFieldsList = (): ContactSectionInputFields[] & Pick<ControllerProps, 'rules'> => [
  {
    fieldName: 'email',
    labelText: 'Email Address',
    rules: { required: REQUIRED_FIELD },
  },
  {
    fieldName: 'phoneNumber',
    labelText: 'Phone Number',
    rules: {
      minLength: {
        value: 12,
        message: 'Please enter a valid phone number',
      },
    },
  },
  // TODO(remove) remove for now will use in future
  // {
  //   fieldName: 'addressLine1',
  //   labelText: 'Address Line 1',
  // },
  // {
  //   fieldName: 'addressLine2',
  //   labelText: 'Address Line 2',
  // },
  // {
  //   fieldName: 'city',
  //   labelText: 'City',
  // },
  // {
  //   fieldName: 'state',
  //   labelText: 'State',
  // },
  // {
  //   fieldName: 'zipCode',
  //   labelText: 'Zip Code',
  // },
];
