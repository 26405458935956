import { ThunkDispatch } from 'redux-thunk';

import { NotificationsInterface } from 'api';
import { toSimpleTextNotificationTile } from 'api/NotificationsInterface/mappers';
import { handlePromise } from 'utils/handlePromise';

import { setSimpleTextNotificationTiles } from '../notification-tiles/setSimpleTextNotificationTiles';

export const initSimpleTextNotificationTiles = async (
  dispatch: ThunkDispatch<unknown, unknown, ReturnType<typeof setSimpleTextNotificationTiles>>
) => {
  const [res] = await handlePromise(NotificationsInterface.getSimpleTextNotificationTiles);

  if (!res) return;

  dispatch(setSimpleTextNotificationTiles(res.data.map(toSimpleTextNotificationTile)));
};
