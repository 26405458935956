import React from 'react';

import { View, Image, ImageSourcePropType } from 'react-native';
import { useSelector } from 'react-redux';

import { supportLogo } from 'assets/images';
import { Card } from 'components/Card';
import { getSelectedProgram } from 'store/selectors';
import { getShowWelcomeCard } from 'store/selectors/user';
import { DefaultText } from 'style/common';
import { getMediaUrl } from 'utils/helpers';

import { useStyles } from './styles';

export const WelcomeCard = () => {
  const styles = useStyles();

  const selectedProgram = useSelector(getSelectedProgram);
  const showWelcomeCard = useSelector(getShowWelcomeCard);

  if (!showWelcomeCard) return null;

  const headerText =
    selectedProgram?.welcomeCardHeader || `Welcome to the ${selectedProgram?.display_name} support program!`;
  const description =
    selectedProgram?.welcomeCardTextBlock ||
    `${selectedProgram?.display_name} is about providing you personalized support throughout your patient care journey. Get one-on-one assistance finding ways to save on the cost of your prescription and staying on track on ${selectedProgram?.display_name}.`;
  const logo: ImageSourcePropType = selectedProgram?.welcomeCardImage
    ? { uri: getMediaUrl(selectedProgram.welcomeCardImage) }
    : supportLogo;

  return (
    <Card>
      <View style={styles.contentWrapper}>
        <View style={styles.logoWrapper}>
          <Image source={logo} style={styles.logo} />
          <View style={styles.logoMask} />
        </View>
        <View style={styles.textBoxWrapper}>
          <DefaultText style={styles.header}>{headerText}</DefaultText>
          <DefaultText style={styles.description}>{description}</DefaultText>
        </View>
      </View>
    </Card>
  );
};

export default WelcomeCard;
