import React, { useImperativeHandle, forwardRef, Ref, ForwardedRef } from 'react';

import { View } from 'react-native';

import { AppFlatListProps, AppFlatListRef } from 'components/AppFlatList/types';
import { useWebLazyScroll } from 'components/AppFlatList/utils';
import { convertStyleSheetToStyled } from 'style/convertStyleSheetToStyled';
import { WebScrollElement } from 'style/global';

const AppFlatList = <T extends unknown>(
  {
    ListEmptyComponent,
    ListFooterComponent,
    ListHeaderComponent,
    contentContainerStyle,
    style,
    testID,
    ...props
  }: AppFlatListProps<T>,
  ref: Ref<AppFlatListRef>
) => {
  useImperativeHandle(ref, () => ({}));

  if (!props.getItemLayout) {
    throw new Error('You should to pass getItemLayout for web');
  }

  const { itemsContainerStyle, renderedItems, onScrollHandler } = useWebLazyScroll(props);

  const isEmptyData = !!props.data?.length;

  return (
    <WebScrollElement
      {...props}
      data-testid={testID}
      onScroll={onScrollHandler}
      style={convertStyleSheetToStyled(style)}>
      <View>
        {ListHeaderComponent}
        {!isEmptyData && ListEmptyComponent}
        {isEmptyData && <View style={[contentContainerStyle, itemsContainerStyle]}>{renderedItems}</View>}
        {ListFooterComponent}
      </View>
    </WebScrollElement>
  );
};

/**
 * @description Remember, for web and native it is not same components
 * @description Web - div, Native - FlatList.
 * @description That means many props will don't work for web.
 * @description And if you need additional functionality, you should implement this for web

 * @description Rules when you shouldn't use it:
 * @description 1. If you don't have a scroll for your list;
 * @description 2. If you have horizontal scroll;
 * @description 3. If you have just a few items, and you know it's static;

 * @description P. S. Horizontal scroll didn't implement
 */
export default forwardRef(AppFlatList) as <T>(
  props: AppFlatListProps<T> & { ref?: ForwardedRef<AppFlatListRef> }
) => JSX.Element;
