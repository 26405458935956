import React, { useEffect, useMemo, useState } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { TextFieldWithLabel } from 'components';
import { TextFieldProps } from 'components/TextFieldWithLabel';
import { useTimer } from 'hooks';
import { ProfileInfoLayout } from 'screens/main/Profile/components';
import { InformationContainer } from 'screens/main/Profile/sections/styles';
import { saveUserContactInfo, setBasicUserInformation, setHasModifications } from 'store/actions';
import { User } from 'store/reducers/user-info/types';
import { getUserEmail, getUserPhoneNumber } from 'store/selectors';
import { DefaultText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';
import { TRY_AGAIN_ERROR_MESSAGE } from 'utils/constants';
import { addDashesForPhoneNumber } from 'utils/convertString';

import { profileAnalyticsActions } from '../../constants';
import { profileAnalyticsHandler } from '../../helpers';
import { getInputFieldsList } from './inputFieldsList';
import { ContactInfoBlock, useContactInformationStyles } from './styles';

export const ContactInformationSection = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { start, pause } = useTimer();

  const { EDIT_CONTACT_INFO_STARTED, EDIT_CONTACT_INFO_FINISHED } = profileAnalyticsActions;

  const [isContactsEdit, setContactsEdit] = useState(false);

  const inputFieldsList = getInputFieldsList();

  const [savingIsInProgress, setSavingInProgress] = useState(false);

  const { styles, colors } = useStylesWithAdditional(useContactInformationStyles);

  const email = useSelector(getUserEmail);
  const phoneNumber = useSelector(getUserPhoneNumber);
  // TODO(remove) remove for now will use in future
  // const addressLine1 = useSelector(getUserAddressLine1);
  // const addressLine2 = useSelector(getUserAddressLine2);
  // const city = useSelector(getUserCity);
  // const state = useSelector(getUserState);
  // const zipCode = useSelector(getUserZipCode);

  const initialUserInfo = useMemo(
    () => ({
      email: email || '',
      phoneNumber: phoneNumber || '',
      // TODO(remove) remove for now will use in future
      // addressLine1: addressLine1 || '',
      // addressLine2: addressLine2 || '',
      // city: city || '',
      // state: state || '',
      // zipCode: zipCode || '',
    }),
    [email, phoneNumber]
  );

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<Pick<User, 'email' | 'phoneNumber'>>({
    defaultValues: initialUserInfo,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isFocused) return;

    setContactsEdit(false);
    reset({ ...initialUserInfo });
  }, [reset, isFocused, initialUserInfo]);

  useEffect(() => {
    dispatch(setHasModifications(isDirty));
  }, [isDirty, dispatch]);

  const submitHandler = (value: Pick<User, 'email' | 'phoneNumber'>) => {
    dispatch(setBasicUserInformation({ ...value }));
    setSavingInProgress(true);

    try {
      dispatch(saveUserContactInfo());
    } catch (error: any) {
      const message = error?.response?.data?.message || TRY_AGAIN_ERROR_MESSAGE;
      toast.show(message, {
        type: 'danger',
      });
    }
    setSavingInProgress(false);
    const passedTime = pause();
    profileAnalyticsHandler(EDIT_CONTACT_INFO_FINISHED, { event_label: passedTime });
  };

  const handleEditMode = async () => {
    if (isContactsEdit && isDirty) {
      await handleSubmit(submitHandler)();
      dispatch(setHasModifications(false));
    }
    if (!isContactsEdit) {
      // TODO(fix): for more fields better to use reset(), (issue) reset fn removed validation in the first render
      reset({ ...initialUserInfo });
      profileAnalyticsHandler(EDIT_CONTACT_INFO_STARTED, { event_label: Date.now() });
      start();
    }
    setContactsEdit((prev) => !prev);
  };

  const savingIsDisabled = savingIsInProgress || (isContactsEdit && !isValid);

  return (
    <ProfileInfoLayout
      wrapperStyle={styles.wrapper}
      title='Contact Information'
      titleColor={colors.gray1}
      isEditMode={isContactsEdit}
      savingIsDisabled={savingIsDisabled}
      setEditMode={handleEditMode}>
      <InformationContainer>
        {inputFieldsList.map(({ fieldName, labelText, rules }) => (
          <ContactInfoBlock key={fieldName}>
            <Controller
              rules={rules}
              name={fieldName}
              control={control}
              render={({ field: { value, onChange } }) => {
                const isPhoneNumberField = fieldName === 'phoneNumber';

                return (
                  <TextFieldWithLabel
                    value={value}
                    labelText={labelText}
                    editField={isContactsEdit}
                    fieldText={initialUserInfo[fieldName]}
                    editable={fieldName !== 'email'}
                    onChangeText={onChange}
                    {...(isPhoneNumberField &&
                      ({
                        onChangeText: (text: string) => onChange(addDashesForPhoneNumber(text)),
                        keyboardType: 'numeric',
                        placeholder: '000-000-0000',
                        autoComplete: 'tel-national',
                      } as Partial<TextFieldProps>))}
                  />
                );
              }}
            />
            {!!errors[fieldName] && !!errors[fieldName]?.message && (
              <DefaultText style={styles.negative}>{errors[fieldName]?.message}</DefaultText>
            )}
          </ContactInfoBlock>
        ))}
      </InformationContainer>
    </ProfileInfoLayout>
  );
};
