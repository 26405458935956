import React from 'react';

import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { View, Pressable, FlatList } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'components/BackButton';
import { useHandleNavigationPush, useSwitchProgramHandler } from 'hooks';
import { Routes } from 'routes/main';
import { medicationTypeIconMapping } from 'screens/main/MedicineCabinet/components/MedicationIconsHelpers/mappings';
import { MedTypeEnum } from 'screens/main/MedicineCabinet/components/MedicationIconsHelpers/types';
import { setSelectedTab } from 'store/actions';
import { getPrograms, getSelectedMedication, getSelectedTab } from 'store/selectors';
import { StandardText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';
import { removeDashes } from 'utils/convertString';

import { useSubsectionsTabsStyle, MedicationDetailsTitle, MedicationDetailsTabs, TitleContainer } from './styles';
import { getAvailableTabs } from './utils';

export const SubSectionsTabs = () => {
  const navigation = useNavigation<DrawerNavigationHelpers>();

  const selectedMedication = useSelector(getSelectedMedication);
  const selectedTab = useSelector(getSelectedTab);
  const programs = useSelector(getPrograms);
  const dispatch = useDispatch();
  const navigationHandler = useHandleNavigationPush();
  const switchHandler = useSwitchProgramHandler();

  const tabs = getAvailableTabs(selectedMedication);
  const selectedMedicationProgramUuid =
    selectedMedication?.sponsored_program_uuid && removeDashes(selectedMedication?.sponsored_program_uuid);
  const pspProgram = programs.find((program) => program?.uuid === selectedMedicationProgramUuid);

  const medicationName = selectedMedication?.name;
  const medicationType = selectedMedication ? selectedMedication?.type : MedTypeEnum.TABLET;
  const ICON = medicationTypeIconMapping[medicationType];

  const primaryColor = pspProgram?.white_labeling_conf?.primaryColor || pspProgram?.white_labeling_conf?.primary_color;
  const { styles, colors, isMobile } = useStylesWithAdditional(useSubsectionsTabsStyle, primaryColor);

  const handleGoToSupportProgram = () => {
    !!selectedMedicationProgramUuid && switchHandler(selectedMedicationProgramUuid);
    navigationHandler(Routes.HOME);
  };

  const isPspMedAndMobile = !!selectedMedicationProgramUuid && isMobile;

  const handleGoToMedicineCabinetListPage = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: Routes.LIST }],
    });
  };

  return (
    <>
      <View style={styles.backButtonActionsWrapper}>
        <BackButton
          style={styles.backButtonStyles}
          color={isPspMedAndMobile ? colors.white : colors.gray2}
          navigation={navigation}
          onPress={handleGoToMedicineCabinetListPage}
        />
        {!!selectedMedicationProgramUuid && (
          <Pressable onPress={handleGoToSupportProgram}>
            <StandardText style={styles.goToPspText}>Go to Support Program</StandardText>
          </Pressable>
        )}
      </View>
      <View style={styles.medicationContent}>
        <TitleContainer>
          <View>
            <ICON color={selectedMedicationProgramUuid ? colors.secondary : undefined} />
          </View>
          <MedicationDetailsTitle numberOfLines={2}>{medicationName}</MedicationDetailsTitle>
        </TitleContainer>
        <MedicationDetailsTabs>
          <FlatList // used just for ItemSeparatorComponent
            contentContainerStyle={styles.tabsContainer}
            horizontal
            showsHorizontalScrollIndicator={false}
            data={tabs}
            renderItem={({ item: { type, text } }) => {
              const isActive = type === selectedTab;

              return (
                <Pressable style={isActive && styles.activeBorderText} onPress={() => dispatch(setSelectedTab(type))}>
                  <StandardText style={[styles.defaultText, isActive && styles.activeColorText]}>{text}</StandardText>
                </Pressable>
              );
            }}
            keyExtractor={({ type }) => type}
            ItemSeparatorComponent={() => <View style={styles.tabsSeparator} />}
          />
        </MedicationDetailsTabs>
      </View>
    </>
  );
};
