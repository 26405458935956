import React, { useEffect, useState } from 'react';

import { useDrawerStatus } from '@react-navigation/drawer';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { PartnerRegistrationInterface } from 'api';
import { ModalComponent as Modal } from 'components/Modal';
import { tutorialAnalyticsActions } from 'components/Tourguide/constants';
import { tutorialAnalyticsHandler } from 'components/Tourguide/helpers';
import { useTourGuideSteps } from 'components/Tourguide/steps';
import { TourContent } from 'components/Tourguide/TourContent';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useStep } from 'hooks';
import { setInitialModalStates, setTourActive, setWebDrawerOpen, setWebTourState } from 'store/actions';
import { getWebTourState } from 'store/selectors';
import { handlePromise } from 'utils/handlePromise';

import { homeAnalyticsActions } from '../../constants';
import { landingPageAnalyticsHandler } from '../../helpers';
import { useTourModalStyles } from './styles';
import { WebTourGuide } from './WebTourGuide';

const { VIEW_LANDING_PAGE_FIRST_TIME_DASHBOARD } = homeAnalyticsActions;

export type EntreatyTourModalProps = {
  visible: boolean;
};

// TODO: 1-) highlight the navigation bar in web and mobile

const { USER_SKIPPED_TUTORIAL, USER_COMPLETED_TUTORIAL } = tutorialAnalyticsActions;

export const EntreatyTourModal = ({ visible }: EntreatyTourModalProps) => {
  const [modalVisible, setModalVisible] = useState(visible);
  const webTourState = useSelector(getWebTourState);
  const dispatch = useDispatch();
  const steps = useTourGuideSteps();
  const [currentStep, { canGoToNextStep, goToNextStep }] = useStep(steps.length);
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const { isMobile } = useDeviceInfoContext();
  const drawerStatus = useDrawerStatus();

  // after consent modal, visible state changes and we need to reset the initial state of the modal
  useEffect(() => setModalVisible(visible), [visible]);

  // steps array is 0 based, but currentStep is 1 based
  const step = currentStep - 1;
  const isFirstStep = step === 0;
  const isLastStep = step === steps.length - 1;
  const currentStepContent = steps[step] ?? {};
  const nextStepContent = steps[step + 1] ?? {};

  const { styles, isMobileTourActive } = useTourModalStyles(currentStepContent.isWebTour);

  // open the drawer if the user is on mobile and the tour is active
  useEffect(() => {
    if (drawerStatus !== 'open' && isMobileTourActive && !webTourState) navigation.openDrawer();
  }, [drawerStatus, isMobileTourActive, webTourState, navigation]);

  const closeModal = (completed = false) => {
    tutorialAnalyticsHandler(completed ? USER_COMPLETED_TUTORIAL : USER_SKIPPED_TUTORIAL, getAnalyticEventLabel());
    landingPageAnalyticsHandler(VIEW_LANDING_PAGE_FIRST_TIME_DASHBOARD, getAnalyticEventLabel());
    dispatch(setTourActive(false));
    dispatch(setInitialModalStates({ shouldProposeTutorial: false }));
    setModalVisible(false);
    setDrawer(false);
    handlePromise(() => PartnerRegistrationInterface.patch({ data: { tutorial_shown: true } }));
  };

  const setDrawer = (open: boolean) => {
    if (isMobile) return open ? navigation.openDrawer() : navigation.closeDrawer();
    dispatch(setWebDrawerOpen(open));
  };

  const firstStepNextHandler = () => {
    dispatch(setTourActive(true));
    setDrawer(true);
    goToNextStep();
  };

  const nextHandler = () => {
    if (isFirstStep && !currentStepContent?.isWebTour) return firstStepNextHandler();
    if (nextStepContent?.isWebTour) {
      setModalVisible(false);
      setDrawer(false);
      dispatch(setWebTourState(true));
      goToNextStep();
      return;
    }
    if (isLastStep) return closeModal(true);
    if (canGoToNextStep) goToNextStep();
  };

  const { title = '', description = '' } = currentStepContent;

  return (
    <>
      <WebTourGuide closeModal={closeModal} currentStep={step} goToNextStep={goToNextStep} />
      <Modal
        key={isMobileTourActive.toString()}
        modalContentStyle={styles.contentStyle}
        centeredViewStyle={styles.centeredViewStyle}
        removeCloseIcon
        transparent
        modalIsVisible={modalVisible}
        animationType={isMobileTourActive ? 'slide' : 'fade'}
        onDismiss={closeModal}>
        <TourContent
          currentStep={step}
          title={title}
          description={description}
          handleNext={nextHandler}
          handlePrevious={closeModal}
        />
      </Modal>
    </>
  );
};

export default EntreatyTourModal;
