import React, { useState, Dispatch, SetStateAction } from 'react';

import { View, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { SelectionType } from 'api/AdditionalServicesInterface/types';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Radio } from 'components/RadioButton';
import { question, submit } from 'localization/questionnaire';
import ServiceConsent from 'screens/main/AdditionalServices/components/ModalContent/ServiceConsent';
import { ProgressHeader } from 'screens/main/RebateCenter/components/ProgressHeader';
import { getActions } from 'store/selectors';
import { DefaultText, PrimaryDefaultText } from 'style/common';
import { isArray } from 'utils/conditions';

import { Thanks } from '../Thanks';
import { reachableTimes, TEXTS } from './constants';
import { useQuestionnaireStyles } from './styles';

export type Answer = {
  id: number;
  label: string;
};

type SelectedItem = number | number[];

type QuestionnaireProps = {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  id: number;
  contentTitle?: string;
  contentDescription?: string;
  questionText?: string;
  answers?: Answer[];
  onScheduleCompleted: (id: number, isValid: boolean, selectedAnswers: number[]) => void;
  hasConsent?: boolean;
  serviceName?: string;
  selectionType?: SelectionType;
};

export const Questionnaire = ({
  setModalVisible,
  id,
  onScheduleCompleted,
  contentTitle,
  contentDescription,
  questionText,
  answers,
  hasConsent,
  serviceName,
  selectionType = 'SINGLE_SELECT',
}: QuestionnaireProps) => {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showServiceConsent, setShowServiceConsent] = useState(hasConsent);
  const [consentConfirmed, setConsentConfirmed] = useState(false);

  const styles = useQuestionnaireStyles();

  const checkIsActive = (answerId: number) => {
    if (isArray(selectedItem)) return !!selectedItem.find((selectedItem) => selectedItem === answerId);
    return answerId === selectedItem;
  };

  const getIterableSelectedItem = (item?: SelectedItem) => (Array.isArray(item) ? item : []);

  const checkBoxClickHandler = (answerId: number) => {
    if (selectionType === 'SINGLE_SELECT') return setSelectedItem(answerId);

    const iterableSelectedItem = getIterableSelectedItem(selectedItem);

    setSelectedItem([...iterableSelectedItem, answerId]);
    if (checkIsActive(answerId)) {
      const newSelectedItem = iterableSelectedItem.filter((selectedItem) => selectedItem !== answerId);
      return setSelectedItem(newSelectedItem);
    }
  };

  const submitClickHandler = () => {
    if (showServiceConsent) {
      setShowServiceConsent(false);
      return;
    }
    const answers = isArray(selectedItem) ? selectedItem : selectedItem ? [selectedItem] : [];

    if (answers.length) {
      setIsSubmitted(true);
      onScheduleCompleted(id, true, answers);
      return;
    }
    onScheduleCompleted(id, false, answers);
  };

  const exitClickHandler = () => {
    setModalVisible(false);
  };

  const backHandler = () => {
    setConsentConfirmed(true);
    setShowServiceConsent(true);
  };

  const actions = useSelector(getActions);

  const scheduleAction = actions?.find((action) => action.id === id);
  const title = scheduleAction?.modalTitle || TEXTS.title;
  const description = scheduleAction?.modalDescription || TEXTS.description;

  const renderContent = () => {
    if (showServiceConsent)
      return (
        <ServiceConsent
          confirmed={consentConfirmed}
          onConfirmed={(confirmed: boolean) => setConsentConfirmed(confirmed)}
          actionId={id}
          serviceName={serviceName}
        />
      );
    return (
      <>
        <PrimaryDefaultText style={styles.firstTextBlock}>{contentTitle || title}</PrimaryDefaultText>
        <DefaultText style={styles.secondTextBlock}>{contentDescription || description}</DefaultText>
        <DefaultText style={styles.thirdTextBlock}>{questionText || question}</DefaultText>
        {(answers || reachableTimes).map(({ id, label }) => (
          <TouchableOpacity key={id} style={styles.checkboxWrapper} onPress={() => checkBoxClickHandler(id)}>
            {selectionType === 'SINGLE_SELECT' ? (
              <Radio isChecked={() => checkIsActive(id)} />
            ) : (
              <Checkbox isChecked={() => checkIsActive(id)} />
            )}
            <DefaultText style={styles.checkboxText}>{label}</DefaultText>
          </TouchableOpacity>
        ))}
      </>
    );
  };

  if (isSubmitted) {
    return <Thanks exitClickHandler={exitClickHandler} />;
  }

  return (
    <View style={styles.wrapper}>
      {hasConsent && (
        <ProgressHeader style={{ marginTop: 30 }} currentStep={showServiceConsent ? 1 : 2} stepCount={2} />
      )}
      {renderContent()}
      <View style={styles.submitButtonWrapper}>
        {hasConsent ? (
          <>
            <Button disabled={showServiceConsent} variant='tertiary' text={'Back'} onPress={backHandler} />
            <Button
              disabled={!consentConfirmed}
              variant='secondary'
              text={'Next'}
              style={styles.nextButton}
              onPress={submitClickHandler}
            />
          </>
        ) : (
          <Button variant='secondary' text={submit} onPress={submitClickHandler} />
        )}
      </View>
    </View>
  );
};
export default Questionnaire;
