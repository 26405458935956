import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const sharedStyles = StyleSheet.create({
  formItem: { width: 270, marginBottom: 16 },
  mb0: { marginBottom: 0 },
  dynamicFormItem: { flexDirection: 'row', width: 270, marginBottom: 16 },
  blockWrapper: { width: '100%' },
  conditionalInput: { marginLeft: 10, width: 130 },
});

export const getStyles = (isMobile?: boolean, showAsNeededCheckbox?: boolean) => {
  return StyleSheet.create({
    wrapper: { width: 300, padding: 8, paddingTop: 0 },
    reminderTitle: {
      ...defaultTheme.fontSizes[24],
      color: defaultTheme.colors.gray1,
      fontFamily: defaultTheme.fontFamilies.bold,
      marginBottom: 16,
    },
    reminderDescription: { ...defaultTheme.fontSizes[16], color: defaultTheme.colors.gray1, marginBottom: 20 },
    reminderScrollView: { maxHeight: showAsNeededCheckbox ? 315 : 300, paddingRight: 12 },
    formWrapper: isMobile ? {} : { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' },
    medicationName: {
      ...defaultTheme.fontSizes[16],
      color: defaultTheme.colors.gray1,
      fontFamily: defaultTheme.fontFamilies.bold,
    },
    medicationParams: { ...defaultTheme.fontSizes[16], color: defaultTheme.colors.gray1 },
    mangoButton: {
      width: 110,
    },
    buttonWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      marginTop: 16,
    },
    deleteButton: {
      width: 110,
      marginRight: 16,
    },
    headerImage: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: 10,
    },
    asNeededMedicationDescription: {
      marginBottom: 16,
      textAlign: 'center',
      paddingHorizontal: 16,
    },
  });
};
