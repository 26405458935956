import { TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';

export const PlusWrapper = styled(TouchableOpacity)`
  padding: 10px;
  justify-content: center;
  align-items: center;
  right: -10px;
`;

export const MedicineActionsContainer = styled.View`
  width: 100%;
  margin-bottom: 21px;
  flex-direction: column-reverse;
`;

export const MedicationRemindersContainer = styled.View`
  margin-bottom: 30px;
  z-index: -1;
`;

export const RemindersWrapper = styled.View`
  margin-top: 8px;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledTypePill = styled.View`
  padding: 8px;
  background-color: ${(props) => props.theme.colors.gray2};
  border-radius: 100px;
`;

export const MedicationActionsTitle = styled(DefaultText)`
  ${getFontStyles(24)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.gray1};
  margin-bottom: 6px;
`;

export const MedicationActionsDescription = styled(DefaultText)`
  ${getFontStyles(17)};
  color: ${(props) => props.theme.colors.gray2};
  margin-bottom: 26px;
`;

export const MedicationSubSection = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MedicationSubSectionSpacer = styled.View`
  height: 10px;
`;
