import { AnyObject } from 'utils/types';

// Helpers ->
const callConditionCallbacks = <T, TrueValue>(
  value: T,
  isTrue: boolean,
  { onTrue, onFalse }: Pick<Options<T, TrueValue>, 'onTrue' | 'onFalse'>
) => {
  if (isTrue && onTrue) onTrue(value as unknown as TrueValue);
  else if (onFalse) onFalse(value as Exclude<T, TrueValue>);

  return isTrue;
};

// Types ->
type Options<T, ExcludeValue> = Partial<{
  onTrue: (value: ExcludeValue) => void;
  onFalse: (value: Exclude<T, ExcludeValue>) => void;
}>;

type ComposeOptions<T> = Partial<{
  onTrue: (value: T) => void;
  onFalse: (value: T) => void;
  atLeastOne?: boolean;
}>;

type Condition<T> = (value: T) => boolean;

// Conditions ->
export const isString = <T>(value: T, options: Options<T, string> = {}) => {
  const isTrue = typeof value === 'string' || value instanceof String;

  callConditionCallbacks<T, string>(value, isTrue, options);

  return isTrue;
};

export const isNumber = <T>(value: T, options: Options<T, number> = {}) => {
  const isTrue = !isNaN(value as unknown as number);

  callConditionCallbacks<T, number>(value, isTrue, options);

  return isTrue;
};

export const isObject = <T>(value: T, options: Options<T, AnyObject> = {}) => {
  const isTrue = typeof value === 'object' && value !== null;

  callConditionCallbacks<T, AnyObject>(value, isTrue, options);

  return isTrue;
};

/**
 * @description will be empty these values:
 * @example
 * '' | null | undefined | NaN | 0 | [] | {}
 */
export const isEmpty = <T extends unknown>(
  value: T,
  options: Options<T, undefined | null> = {},
  additionalConditions?: unknown[]
) => {
  const isTrue =
    !value ||
    (value instanceof Array && !value?.length) ||
    (value instanceof Object && !Object.keys(value).length) ||
    !!additionalConditions?.some((condition) => condition === value);

  callConditionCallbacks<T, undefined | null>(value, isTrue, options);

  return isTrue;
};

// Additional ->
export const conditionCompose = <T>(
  value: T,
  conditions: Condition<T>[],
  { atLeastOne, ...options }: ComposeOptions<T> = {}
) => {
  const isTrue = conditions[atLeastOne ? 'some' : 'every']((condition) => condition(value));

  callConditionCallbacks(value, isTrue, options);

  return isTrue;
};

export const isArray = <ArrayType, T = unknown>(
  value: T | ArrayType[],
  options: Options<typeof value, ArrayType[]> = {}
): value is ArrayType[] => {
  const isTrue = Array.isArray(value);

  callConditionCallbacks<typeof value, ArrayType[]>(value, isTrue, options);

  return isTrue;
};
