import { TouchableOpacity, View, StyleProp, ViewStyle } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText, IconWrapper, getFontStyles } from 'style/common';

export const actionLabelStyle: StyleProp<ViewStyle> = { flex: 1, flexDirection: 'row' };

export const TouchableActionItemContainer = styled(TouchableOpacity)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.gray3};
  padding: 17px 0px 18px 0px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      justify-content: flex-start;
    `}
`;

export const NonTouchableActionItemContainer = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.gray3};
  padding: 17px 0px 18px 0px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      justify-content: flex-start;
    `}
`;

export const ActionLabelWrapper = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
`;

export const ActionText = styled(DefaultText)<{ done?: boolean }>`
  ${getFontStyles(16)}
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  color: ${(props) => (props.done ? props.theme.colors.gray2 : props.theme.colors.gray1)};
  margin-left: 18px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      max-width: 70%;
    `}
`;

export const ActionIconWrapper = styled(IconWrapper)`
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      justify-content: flex-start;
    `}
`;

export const DoneBoxContainer = styled.View`
  padding: 4px 16px;
  height: 28px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primary};
`;

export const DoneText = styled(ActionText)`
  color: #fff;
  margin-left: 0;
`;

export const DoneCheckIconWrapper = styled.View`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    !theme.isMobile &&
    css`
      margin-right: 8px;
    `}
`;
