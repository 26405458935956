import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { ImageSourcePropType } from 'react-native';

import step1Src from 'assets/images/step1.png';
import step2Src from 'assets/images/step2.png';
import step3Src from 'assets/images/step3.png';
import step4Src from 'assets/images/step4.png';
import step5Src from 'assets/images/step5.png';
import { Button } from 'components/Button';
import Modal from 'components/Modal';

import {
  ModalWrapper,
  ModalHeader,
  ModalInfo,
  ButtonWrapper,
  ButtonContainer,
  BackButton,
  TutorialImage,
} from '../styles';
import { ProgressHeader } from './ProgressHeader';

const steps = [
  {
    id: 1,
    title: 'Submit Your Request ',
    primary: 'Initiate requests for reimbursement and track and manage status of historical submissions',
    image: step1Src,
  },
  {
    id: 2,
    title: 'Upload Supporting Documents',
    primary:
      'Convenient ‘Document Upload Feature’ makes it easy for patients to provide required proof of purchase documentation (Supports JPEG & PDF)',
    image: step2Src,
  },
  {
    id: 3,
    title: 'Choose How To Receive Reimbusement',
    primary: 'Patients chose their preferred reimbursement vehicle',
    image: step3Src,
  },
  {
    id: 4,
    title: 'Stay Informed with Status Updates ',
    primary: 'Patients are alerted via SMS-Text or Email with updates related to the status of their reimbursement',
    image: step4Src,
  },
  {
    id: 5,
    title: 'Access Your Payment Details Anytime',
    primary: 'Payments Dashboard provides on-demand access to payment and reimbursement information and updates',
    image: step5Src,
  },
];

type TModalProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

export const TutorialModal: React.FC<TModalProps> = ({ visible, setVisible }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { image, title, primary } = steps[currentStep];

  useEffect(() => {
    if (visible) setCurrentStep(0);
  }, [visible]);

  return (
    <Modal transparent modalIsVisible={visible} setModalVisible={setVisible}>
      <ModalWrapper>
        <ProgressHeader stepCount={steps.length} currentStep={currentStep + 1} />
        <TutorialImage source={image as ImageSourcePropType} />
        <ModalHeader>{title}</ModalHeader>
        <ModalInfo>{primary}</ModalInfo>
        <ButtonContainer>
          <ButtonWrapper style={{ marginRight: 16 }}>
            <BackButton
              disabled={currentStep <= 0}
              onPress={() => setCurrentStep(currentStep - 1)}
              text='Back'
              variant='tertiary'
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              text={currentStep >= steps.length - 1 ? 'Done' : 'Next'}
              onPress={() => (currentStep >= steps.length - 1 ? setVisible(false) : setCurrentStep(currentStep + 1))}
              variant='secondary'
            />
          </ButtonWrapper>
        </ButtonContainer>
      </ModalWrapper>
    </Modal>
  );
};
