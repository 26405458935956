import { useSelector } from 'react-redux';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { usePageAvailable } from 'hooks/usePageAvailable';
import {
  additionalResources,
  benefits,
  financialAssistance,
  home,
  medCabinet,
  medTracker,
  profile,
  rebateCenter,
} from 'localization/drawer';
import { Routes } from 'routes/main';
import { getFinancialAssistanceData, getIsCopayUser, getUserHasPsp } from 'store/selectors';

const useScreenSettings = () => {
  const isCopayUser = useSelector(getIsCopayUser);
  const userHasPsp = useSelector(getUserHasPsp);
  const financialAssistanceData = useSelector(getFinancialAssistanceData);

  const { benefitsPageAvailable } = usePageAvailable('benefits');
  const { rebateCenterPageAvailable } = usePageAvailable('rebateCenter');

  const showFinancialAssistanceScreen = Boolean(financialAssistanceData);
  const showBenefitsScreen = Boolean(benefitsPageAvailable && !isCopayUser);
  const showRebateCenterScreen = Boolean(rebateCenterPageAvailable && !isCopayUser);

  return {
    showFinancialAssistanceScreen,
    showBenefitsScreen,
    showRebateCenterScreen,
    userHasPsp,
  };
};

export const useScreens = () => {
  const { showBenefitsScreen, showRebateCenterScreen, showFinancialAssistanceScreen, userHasPsp } = useScreenSettings();
  const { isMobile } = useDeviceInfoContext();

  return userHasPsp
    ? ([
        Routes.HOME,
        showFinancialAssistanceScreen && Routes.FINANCIAL_ASSISTANCE,
        showBenefitsScreen && Routes.BENEFITS,
        Routes.RESOURCES,
        showRebateCenterScreen && Routes.REBATE_CENTER,
        !isMobile && Routes.PROFILE,
        !isMobile && Routes.MEDICINE_CABINET,
        !isMobile && Routes.TRACKER,
      ].filter(Boolean) as Routes[])
    : noPspScreens;
};

export const useTitles = () => {
  const { showFinancialAssistanceScreen, showBenefitsScreen, showRebateCenterScreen, userHasPsp } = useScreenSettings();
  const { isMobile } = useDeviceInfoContext();

  return userHasPsp
    ? ([
        home,
        showFinancialAssistanceScreen && financialAssistance,
        showBenefitsScreen && benefits,
        additionalResources,
        showRebateCenterScreen && rebateCenter,
        !isMobile && profile,
        !isMobile && medCabinet,
        !isMobile && medTracker,
      ].filter(Boolean) as string[])
    : noPspTitles;
};

export const noPspScreens = [Routes.HOME, Routes.MEDICINE_CABINET, Routes.PROFILE];

export const noPspTitles = [home, medCabinet, profile];
