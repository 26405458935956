import React from 'react';

import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { getSelectedProgram } from 'store/selectors';
import { defaultTheme } from 'style/theme';

import { getRadioStyles } from './styles';

type RadioProps = {
  isChecked: boolean | (() => boolean);
  activeColor?: string;
};

export const Radio = ({ isChecked, activeColor }: RadioProps) => {
  const isActive = typeof isChecked === 'boolean' ? isChecked : isChecked();
  const selectedProgram = useSelector(getSelectedProgram);
  const secondaryColor = selectedProgram?.white_labeling_conf?.secondaryColor || defaultTheme.colors.secondary;
  const styles = getRadioStyles(secondaryColor, activeColor);
  return <View style={[styles.radioWrapper, isActive ? styles.radioActive : styles.radioInactive]} />;
};
