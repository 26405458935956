import * as ActionTypes from 'store/action-types';

export type SetSelectedAnswerForQuestion = (payload: {
  questionId: string;
  answerId: string;
  isSelected: boolean;
  multi?: boolean;
}) => {
  type: typeof ActionTypes.SET_SELECTED_ANSWER_FOR_QUESTION;
  payload: typeof payload;
};

export const setSelectedAnswerForQuestion: SetSelectedAnswerForQuestion = (payload) => ({
  type: ActionTypes.SET_SELECTED_ANSWER_FOR_QUESTION,
  payload,
});
