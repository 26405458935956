import { PSPReminderDTO } from 'store/reducers/medicine-cabinet/types';
import apiRequest, { apiPrefix } from 'utils/network';

import {
  AddReminderData,
  MedicationData,
  UpdateReminderData,
  AddReminderReturnData,
  SearchDrugReturnData,
  MedicationAdherenceReturnData,
  MedicationTrackerReturnData,
  UpdateReminderHistoryData,
  ReminderNotificationsReturnData,
  MedicationsResponseType,
  RemindersResponseType,
  MetaDataResponseType,
} from './types';

const MEDICATION_TRACKER_URL = '/v1/medication-tracker';
const MEDICATION_ADHERENCE_URL = '/v1/medication-adherence/';
const MEDICATION_URL = '/v1/medication-cabinet/medication/';
const SPONSORED_MEDICATION_ADDED_URL = '/v1/medication/sponsored-medication-added';
const SPONSORED_MEDICATION = '/v1/medication/sponsored';
const GET_MEDICATION_CABINET_META = '/v1/medication-cabinet/meta/';
const REMINDER_URL = '/v1/medication-cabinet/reminder/';
const REMINDER_NOTIFICATIONS_URL = '/v1/getNotificationList/';
const NOTIFICATION_STATUS_DOSE_URL = '/v1/medication-cabinet/medication/acknowledgment_toast_notification/';
const DRUG_SEARCH_URL = '/v1/drug/drug_search';
const ADHERENCE_URL = '/v1/medication-adherence/';
const GET_ALL_DRUG_INTERACTIONS_BY_ID_URL = 'v1/drug/get_all_interactions';
const CHECK_DRUG_INTERACTIONS_URL = 'v1/drug/check_drug_interaction';
const UPDATE_REMINDER_HISTORY_URL = '/v1/update-reminder-history/';
const SET_AS_NEEDED_MEDICATION_URL = '/v1/medication-cabinet/reminder-notification-history/acknowledge';
const GET_TRACKER_FILTER_OPTIONS_URL = '/v1/medication-cabinet/medication/filter';

export const MedicineCabinetInterface = {
  getMetaData: (): Promise<MetaDataResponseType> =>
    apiRequest({
      endpoint: GET_MEDICATION_CABINET_META,
      baseURL: `${apiPrefix}/api`,
    }),
  // MEDICATIONS
  getMedicationTracker: (params: string): Promise<MedicationTrackerReturnData> =>
    apiRequest({
      endpoint: `${MEDICATION_TRACKER_URL}${params}`,
      baseURL: `${apiPrefix}/api`,
    }),
  getMedicationAdherence: (params: string): Promise<MedicationAdherenceReturnData> =>
    apiRequest({
      endpoint: `${MEDICATION_ADHERENCE_URL}${params}`,
      baseURL: `${apiPrefix}/api`,
    }),
  getMedications: (): Promise<MedicationsResponseType> =>
    apiRequest({
      endpoint: MEDICATION_URL,
      baseURL: `${apiPrefix}/api`,
    }),
  getMedicationItem: (id: number) =>
    apiRequest({
      endpoint: MEDICATION_URL + id,
      baseURL: `${apiPrefix}/api`,
    }),
  createMedication: (data: MedicationData): Promise<AddReminderReturnData> =>
    apiRequest({
      method: 'POST',
      endpoint: MEDICATION_URL,
      body: data,
      baseURL: `${apiPrefix}/api`,
    }),
  sponsoredMedication: (program_uuid: string): Promise<PSPReminderDTO> =>
    apiRequest({
      method: 'GET',
      endpoint: SPONSORED_MEDICATION,
      params: { program_uuid },
      baseURL: `${apiPrefix}/api`,
    }),
  sponsoredMedicationAdded: (programUUID: string) =>
    apiRequest({
      method: 'POST',
      endpoint: SPONSORED_MEDICATION_ADDED_URL,
      body: { programUUID },
      baseURL: `${apiPrefix}/api`,
    }),
  updateMedication: (data: MedicationData) =>
    apiRequest({
      endpoint: MEDICATION_URL,
      method: 'PUT',
      body: data,
      baseURL: `${apiPrefix}/api`,
    }),
  deleteMedication: (id: number) =>
    apiRequest({
      endpoint: MEDICATION_URL + id,
      method: 'DELETE',
      baseURL: `${apiPrefix}/api`,
    }),
  // REMINDERS
  getReminders: (): Promise<RemindersResponseType> =>
    apiRequest({
      endpoint: REMINDER_URL,
      baseURL: `${apiPrefix}/api`,
    }),
  createReminder: (data: AddReminderData) =>
    apiRequest({
      endpoint: REMINDER_URL,
      method: 'POST',
      body: data,
      baseURL: `${apiPrefix}/api`,
    }),
  updateReminder: (data: UpdateReminderData) =>
    apiRequest({
      endpoint: REMINDER_URL,
      method: 'PUT',
      body: data,
      baseURL: `${apiPrefix}/api`,
    }),
  deleteReminder: (id: number) =>
    apiRequest({
      endpoint: `${REMINDER_URL}${id}`,
      method: 'DELETE',
      baseURL: `${apiPrefix}/api`,
    }),
  getReminderNotifications: (): Promise<ReminderNotificationsReturnData> =>
    apiRequest({
      endpoint: REMINDER_NOTIFICATIONS_URL,
      method: 'GET',
      baseURL: `${apiPrefix}/api`,
    }),
  getNotificationsStatusDose: ({ medicationId }: { medicationId: number }) =>
    apiRequest({
      endpoint: NOTIFICATION_STATUS_DOSE_URL + medicationId,
      method: 'GET',
      baseURL: `${apiPrefix}/api`,
    }),
  searchDrugs: (text: string): Promise<SearchDrugReturnData> =>
    apiRequest({
      endpoint: DRUG_SEARCH_URL,
      method: 'POST',
      baseURL: `${apiPrefix}/api`,
      body: { text },
    }),
  // DRUG INTERACTIONS
  // All interactions
  getAllDrugInteractionsByDrugId: (drugId: string | number) =>
    apiRequest({
      endpoint: `${GET_ALL_DRUG_INTERACTIONS_BY_ID_URL}/${drugId}`,
      baseURL: `${apiPrefix}/api`,
    }),
  // Cabinet interactions
  checkDrugInteractions: (id: number | string) =>
    apiRequest({
      endpoint: CHECK_DRUG_INTERACTIONS_URL,
      baseURL: `${apiPrefix}/api`,
      method: 'POST',
      body: { id },
    }),
  // If you don't send mark info, backend sets the status as 'acknowledged' as default.
  updateReminderHistory: (data: UpdateReminderHistoryData) =>
    apiRequest({
      endpoint: UPDATE_REMINDER_HISTORY_URL,
      baseURL: `${apiPrefix}/api`,
      method: 'PUT',
      body: data,
    }),
  setAsNeededMedication: (reminderId: number) =>
    apiRequest({
      endpoint: SET_AS_NEEDED_MEDICATION_URL,
      baseURL: `${apiPrefix}/api`,
      method: 'POST',
      body: { reminderId },
    }),
  getMedicationTrackerFilterData: (): Promise<MedicationsResponseType> =>
    apiRequest({
      endpoint: GET_TRACKER_FILTER_OPTIONS_URL,
      baseURL: `${apiPrefix}/api`,
    }),
};
