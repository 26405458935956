import { StyleSheet, ViewStyle, TextStyle } from 'react-native';
import { DefaultTheme } from 'styled-components';
import { useTheme } from 'styled-components/native';

import { MedCabinetWidgetType, CabinetInfoType } from 'screens/main/MedicineCabinet/types';
import { applyBreakpoints } from 'style/utils';

type EmptyCabinetStyles = {
  container: ViewStyle;
  infoTextAndIconWrapper: ViewStyle;
  infoIconWrapper: ViewStyle;
  actionButton: ViewStyle;
  infoText: TextStyle;
};

const getAdditionalCabinetEmptyStyles = (
  theme: Pick<DefaultTheme, 'width'>,
  isMedicationTracker?: boolean
): Record<MedCabinetWidgetType, Partial<EmptyCabinetStyles>> => {
  const medicationReminderAdditionalStyles: Partial<EmptyCabinetStyles> = {
    container: applyBreakpoints(theme, { xxl: { flexDirection: 'row' }, lg: {} }),
    infoTextAndIconWrapper: applyBreakpoints(theme, { xxl: { flexDirection: 'row' }, lg: {} }),
    infoText: applyBreakpoints(theme, { xxl: { marginTop: 0, marginLeft: 14 }, lg: {} }),
    actionButton: applyBreakpoints(theme, { lg: { marginTop: 20 } }),
  };

  return {
    [MedCabinetWidgetType.MEDICATION]: isMedicationTracker
      ? {
          container: applyBreakpoints(theme, {
            lg: { marginTop: 16, marginBottom: 0 },
            xxl: { marginVertical: 16 },
          }),
          actionButton: { marginTop: 20 },
        }
      : medicationReminderAdditionalStyles,
    [MedCabinetWidgetType.REMINDER]: medicationReminderAdditionalStyles,
  };
};

export const useCabinetEmptyStyles = (type: MedCabinetWidgetType, isMedicationTracker?: boolean) => {
  const { colors, fontSizes, fontFamilies, ...theme } = useTheme();

  const additional = getAdditionalCabinetEmptyStyles(theme, isMedicationTracker)[type];

  return StyleSheet.create<EmptyCabinetStyles>({
    container: {
      width: '100%',
      marginVertical: 8,
      padding: 32,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderColor: colors.gray3,
      borderRadius: 16,
      borderWidth: 1,
      ...additional.container,
    },
    infoTextAndIconWrapper: {
      alignItems: 'center',
      ...additional.infoTextAndIconWrapper,
    },
    infoText: {
      ...fontSizes[16],
      fontFamily: fontFamilies.bold,
      textAlign: 'center',
      marginTop: 18,
      color: colors.gray1,
      ...additional.infoText,
    },
    infoIconWrapper: {
      width: 24,
      height: 24,
      justifyContent: 'center',
      alignItems: 'center',
      ...additional.infoTextAndIconWrapper,
    },
    actionButton: { ...additional.actionButton },
  });
};

const getInfoBackgroundColor = ({ colors }: Pick<DefaultTheme, 'colors'>): Record<CabinetInfoType, string> => ({
  [CabinetInfoType.NOTIFY]: colors.mangoPrimary,
  [CabinetInfoType.WARNING]: colors.negative,
});

export const useCabinetInfoStyles = (type: CabinetInfoType) => {
  const { colors, fontSizes, fontFamilies, ...theme } = useTheme();

  const backgroundColor = getInfoBackgroundColor({ colors })[type];

  return StyleSheet.create({
    container: {
      backgroundColor,
      borderRadius: 16,
      marginBottom: 30,
      paddingVertical: 22,
      paddingHorizontal: 32,
      justifyContent: 'space-between',
      alignItems: 'center',
      ...applyBreakpoints(theme, {
        md: {},
        xxl: { flexDirection: 'row' },
      }),
    },
    infoTextAndIconWrapper: applyBreakpoints(theme, {
      md: { width: '100%' },
      xxl: { flexDirection: 'row', width: '60%' },
    }),
    infoIconWrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      ...applyBreakpoints(theme, {
        md: {},
        xxl: { marginRight: 24 },
      }),
    },
    actionButtonsWrapper: {
      flexDirection: 'row',
      ...applyBreakpoints(theme, {
        md: {
          marginTop: 18,
        },
      }),
    },
    firstInfoAction: {
      borderWidth: 2,
      borderColor: colors.white,
      backgroundColor,
    },
    secondInfoAction: {
      marginLeft: 16,
      backgroundColor: colors.white,
      borderWidth: 2,
      borderColor: colors.white,
    },
    textsWrapper: applyBreakpoints(theme, {
      md: {},
      xxl: { width: '80%' },
    }),
    infoTitleText: {
      ...fontSizes[16],
      fontFamily: fontFamilies.semiBold,
      color: colors.white,
      ...applyBreakpoints(theme, {
        md: { alignSelf: 'center', marginTop: 12 },
        xxl: { width: '100%' },
      }),
    },
    detailsText: {
      color: colors.white,
      ...applyBreakpoints(theme, {
        md: {
          textAlign: 'center',
          marginTop: 12,
        },
        xxl: {
          marginTop: 8,
        },
      }),
    },
  });
};
