import React, { ReactNode } from 'react';

import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { format } from 'date-fns';
import { View, Pressable, StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Close } from 'assets/icons';
import { Card } from 'components/Card';
import { Routes } from 'routes/main';
import { useNotificationTileStyles } from 'screens/main/Home/components/NotificationTile/style';
import { closeButtonStyles, StandardText, webStyles } from 'style/common';
import { AnyFunction } from 'utils/types';

export type NotificationTileProps = {
  headerText: string;
  descriptionText: string;
  date?: string;
  onCloseHandler: AnyFunction;
  headerTextColor?: string;
  templateBackgroundColor?: string;
  templateImageComponent?: ReactNode;
};

export const NotificationTile = ({
  headerText,
  headerTextColor,
  descriptionText,
  date,
  onCloseHandler,
  templateImageComponent,
  templateBackgroundColor,
}: NotificationTileProps) => {
  const theme = useTheme();
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const styles = useNotificationTileStyles();
  const dateText = date ? format(new Date(date), 'MMMM do') : null;

  const handlePress = () => {
    navigation.navigate(Routes.FINANCIAL_ASSISTANCE);
  };

  return (
    <Card style={{ padding: 0 }} isTouchable pressHandler={handlePress}>
      <View style={styles.container}>
        <Pressable
          onPress={onCloseHandler}
          style={(state) => [
            styles.closeButtonWrapper,
            webStyles.button,
            closeButtonStyles.button,
            // @ts-ignore
            state.hovered && closeButtonStyles.onHover,
          ]}>
          <Close stroke='#fff' />
        </Pressable>
        <View style={styles.contentWrapper}>
          <StandardText style={[styles.headerText, { color: headerTextColor || theme.colors.secondary }]}>
            {headerText}
          </StandardText>
          <StandardText style={styles.descriptionText}>
            {descriptionText}
            {dateText && `${dateText}.`}
          </StandardText>
        </View>
        {templateImageComponent}
        <View
          style={[
            styles.underBox,
            StyleSheet.absoluteFill,
            { backgroundColor: templateBackgroundColor || theme.colors.primary },
          ]}
        />
      </View>
    </Card>
  );
};

export default NotificationTile;
