import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function ClipBoard(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M16.5 4.5h2.25a2.4 2.4 0 011.591.586c.422.375.659.884.659 1.414v14c0 .53-.237 1.04-.659 1.414a2.4 2.4 0 01-1.591.586H5.25a2.4 2.4 0 01-1.591-.586A1.894 1.894 0 013 20.5v-14c0-.53.237-1.04.659-1.414A2.4 2.4 0 015.25 4.5H7.5'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M15.375 1.5h-6.75c-.621 0-1.125.504-1.125 1.125v2.25C7.5 5.496 8.004 6 8.625 6h6.75c.621 0 1.125-.504 1.125-1.125v-2.25c0-.621-.504-1.125-1.125-1.125z'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default ClipBoard;
