import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const Injection = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      d='M12.495 4.026a2 2 0 012.828 0l4.243 4.243a2 2 0 010 2.828l-8.167 8.167a2 2 0 01-2.828 0L4.328 15.02a2 2 0 010-2.829l8.167-8.166z'
      stroke={props.stroke || '#000'}
      strokeWidth={2}
    />
    <Path stroke={props.stroke || '#000'} strokeWidth={2} strokeLinecap='round' d='M1 22.95L5.95 18' />
    <Path stroke={props.stroke || '#000'} strokeWidth={2} strokeLinecap='square' d='M19 5.086L19.086 5' />
    <Path stroke={props.stroke || '#000'} strokeWidth={2} strokeLinecap='round' d='M17.414 1l5.657 5.657' />
  </Svg>
);

export default Injection;
