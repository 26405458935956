import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { isAndroidDevice, isNativeDevice } from 'utils/helpers';

export const useModalStyles = (fillWholeScreen?: boolean) => {
  const { colors, width = 0, height = 0 } = useTheme();

  return StyleSheet.create({
    centeredView: {
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      ...(!isNativeDevice && { cursor: 'auto' }),
    },
    modalView: {
      flexGrow: 0,
      margin: isNativeDevice ? (isAndroidDevice ? 8 : 16) : 24,
      borderRadius: 20,
      padding: 32,
      shadowColor: colors.black,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      backgroundColor: colors.white,
      maxWidth: '98%',
      ...(isNativeDevice && { maxHeight: '80%' }),
      ...(fillWholeScreen && {
        width,
        height,
        borderRadius: undefined,
        maxWidth: undefined,
        maxHeight: undefined,
        padding: undefined,
        margin: undefined,
      }),
    },
    closeButton: {
      position: 'absolute',
      top: 16,
      right: 16,
      padding: 16,
      zIndex: 1,
    },
  });
};
