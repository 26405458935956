import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Profile = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M14 15v-1.667c0-.884-.316-1.732-.879-2.357C12.56 10.351 11.796 10 11 10H5c-.796 0-1.559.351-2.121.976C2.316 11.601 2 12.45 2 13.333V15M8 7a3 3 0 100-6 3 3 0 000 6z'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Profile;
