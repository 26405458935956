import React, { useState } from 'react';

import { TouchableOpacity } from 'react-native';

import { BenefitsServicesInterface } from 'api/Benefits';
import { MilestoneTrackerData } from 'api/Benefits/types';
import { ProgressSteps } from 'components';
import { HomeClosableCard } from 'screens/main/Home/components/HomeClosableCard/HomeClosableCard';
import { useMilestoneTrackerStyles } from 'screens/main/Home/components/MilestoneTracker/styles';
import { DefaultText } from 'style/common';
import { useIsBreakpoint } from 'style/hooks';
import { openUrlInNewTab } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

type MilestoneTrackerCardRef = {
  trackerData?: MilestoneTrackerData;
  isBenefitPage?: boolean;
  onClose?: AnyFunction;
};
type MilestoneTrackerCardProps = {
  trackerData?: MilestoneTrackerData;
  isBenefitPage?: boolean;
  initialVisibleState?: boolean;
  onClose?: AnyFunction;
};

const MilestoneTracker = ({
  trackerData,
  onClose,
  initialVisibleState = true,
  isBenefitPage,
}: MilestoneTrackerCardProps) => {
  const isMd = useIsBreakpoint('md');

  const [isVisible, setVisible] = useState(initialVisibleState);

  const styles = useMilestoneTrackerStyles();

  if (!trackerData) return null;

  const isComplete = trackerData.trackerStatus === 'Complete';

  const metaField = trackerData?.meta?.[0];

  const hideTracker = isBenefitPage ? trackerData.type === 'BI_TRACKER' && isComplete : trackerData.isHidden; // We shouldn't show benefit investigation tracker for benefits page if tracker is complete

  if (hideTracker) return null;

  const openMetaFieldLink = () => metaField && openUrlInNewTab(metaField.value);

  const onCloseHandler = () => {
    setVisible(false);
    onClose ? onClose() : BenefitsServicesInterface.closeTrackerCard(trackerData.uuid);
  };

  return (
    <HomeClosableCard
      title={trackerData.name}
      description={trackerData.description}
      visible={isVisible}
      showCloseIcon={!isBenefitPage && isComplete}
      isNotificationCard
      onClose={onCloseHandler}>
      {!!metaField?.description && (
        <TouchableOpacity onPress={openMetaFieldLink}>
          <DefaultText numberOfLines={1} style={styles.metaFieldText}>
            {metaField.description}
          </DefaultText>
        </TouchableOpacity>
      )}
      <ProgressSteps
        showText={!isMd}
        steps={trackerData.milestones.map((milestone) => ({
          isChecked: milestone.milestoneStatus === 'Complete',
          isCompleted: milestone.milestoneStatus === 'Complete',
          stepText: milestone.title,
        }))}
        containerStyle={{ marginTop: 10 }}
      />
    </HomeClosableCard>
  );
};

export default MilestoneTracker;
