import { SimpleTextNotificationTileType } from '../../store/reducers/user-info/types/notification';
import { SimpleTextNotificationTileDTO } from './types';

/** @description mapper will take value from notification_tile template, if main object has some empty field */
export const toSimpleTextNotificationTile = (dto: SimpleTextNotificationTileDTO): SimpleTextNotificationTileType => ({
  isRead: dto.is_read ?? false,
  isDeleted: dto.is_deleted ?? false,
  id: dto.id ?? '',
  createdAt: dto.created_at ?? '',
  updatedAt: dto.updated_at ?? '',
  cognitoId: dto.cognito_id ?? '',
  status: dto.notification_tile.status ?? '',
  title: dto.title ?? dto.notification_tile.title ?? '',
  description: dto.description ?? dto.notification_tile.description ?? '',
  name: dto.notification_tile.display_name ?? '',
  type: dto.type ?? dto.notification_tile.type ?? null,
  isSingleSession: dto.is_single_session ?? dto.notification_tile.is_single_session ?? false,
  isClosable: dto.is_closable ?? dto.notification_tile.is_closable ?? true,
  destinationPage: dto.destination_page ?? dto.notification_tile.destination_page ?? null,
  externalLink: dto.external_link ?? dto.notification_tile.external_link ?? '',
  externalLinkName: dto.external_link_name ?? dto.notification_tile.external_link_name ?? '',
  metadata: {
    contentId: dto.metadata.content_id ?? null,
  },
  manufactureProgramUuid: dto.notification_tile.manufacture_program_uuid ?? '',
});
