import { ConstantImageSizes } from 'style/types';

export const CLOSE_LEFT_SIDE_MENU_WIDTH = 72;
export const OPEN_RIGHT_SIDE_MENU_WIDTH = 267;
export const MOBILE_HORIZONTAL_PADDING = 32;
export const MODAL_PADDING = 64;
export const ALLOWED_MODAL_PADDINGS = MOBILE_HORIZONTAL_PADDING * 2 - 16;
export const COPAY_CARD_MIN_HEIGHT = 280;
export const TUTORIAL_MODAL_MAX_WIDTH = 312;

export const MAX_CONTENT_WIDTH = 1440;

/** All sizes depend on cropper sizes of Admin Tool */

export const FINANCIAL_ASSISTANCE_IMAGE: ConstantImageSizes = {
  HEIGHT: 260,
  WIDTH: 400,
};

export const PROGRAM_LOGO_IMAGE: ConstantImageSizes = {
  HEIGHT: 240,
  WIDTH: 378,
};
