import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useSurveyContentStyle = () => {
  const { colors, isMobile, fontSizes, fontFamilies } = useTheme();

  return StyleSheet.create({
    wrapper: isMobile
      ? { paddingHorizontal: 5, paddingTop: 23, paddingBottom: 5 }
      : { paddingHorizontal: 29, paddingTop: 17, paddingBottom: 29, maxWidth: 696 },
    questionsWrapper: { marginVertical: 20, maxHeight: '40%' },
    title: {
      ...fontSizes[24],
      marginTop: 20,
      maxWidth: isMobile ? 240 : 347,
      fontFamily: fontFamilies.bold,
    },
    description: {
      ...fontSizes[16],
      color: colors.gray1,
      marginTop: 20,
    },
    buttonWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
    },
    submitButton: { width: 120, marginTop: 30 },
    backButton: {
      borderWidth: 2,
      borderColor: colors.gray2,
      borderRadius: 8,
      width: 120,
      height: 48,
      justifyContent: 'center',
      backgroundColor: colors.white,
      color: colors.gray2,
      textAlign: 'center',
      marginTop: 30,
      marginRight: 16,
    },
    backButtonDisabled: {
      borderColor: 'transparent',
      backgroundColor: colors.gray4,
    },
  });
};
