import Constants from 'expo-constants';
import { Pressable } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

export const HeaderContainer = styled.View`
  padding: 0 32px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  background: white;
  elevation: 3;
  z-index: 1;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column-reverse;
      padding: 0;
    `}
  ${isNativeDevice &&
  css`
    box-shadow: 0 13px 10px rgba(0, 0, 0, 0.07);
    /* Normally container's height is 112px if I make it like height: 112 - statusBarHeight. Header logo sticks on status bar.
    //So, that's why I gave it a 12px additional height to keep the header 12px away from the status bar.
    There is no any provided native design for header's status bar cases but in this way, It looks good.
    */
    height: ${130 - Constants.statusBarHeight}px;
  `}
`;

export const HeaderWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  height: 78px;
  padding: 0px 0px 30px 0px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 28px 11px 0px 11px;
      height: 68px;
    `}
  ${isNativeDevice &&
  css`
    padding-top: 28px;
  `}
`;

export const LegalInformation = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 17px;
  padding-top: 12px;
  height: 44px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0 11px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    `}
`;

export const InfoText = styled(DefaultText)`
  color: ${(props) => props.theme.colors.gray2};
  text-align: center;
  margin-right: 24px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      font-size: 12px;
      margin-right: 0;
    `}
`;

export const MenuItemsWrapper = styled.View`
  flex-direction: row;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      display: none;
    `}
`;

export const ActionsWrapper = styled.View`
  margin-left: auto;
  flex-direction: row;
  align-items: center;
`;

export const MenuIconWrapper = styled(Pressable)`
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
`;
