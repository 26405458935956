import React, { useState } from 'react';

import { TouchableOpacity, View, Pressable } from 'react-native';
import { useSelector } from 'react-redux';

import { BenefitInvestigationsData } from 'api/Benefits/types';
import { InformationField } from 'screens/main/FinancialAssistance/CopayFinancialAssistance/components';
import { getSelectedProgram } from 'store/selectors';
import { StandardText, HeaderWrapper } from 'style/common';

import { ExplanationModalType, ExplanationModal } from './ExplanationModal';
import { useBenefitsInvestigationCardStyles, InsuranceInfoBox, InsuranceInfoWrapper, InsuranceText } from './styles';

type BenefitsInsuranceCardProps = {
  investigationType: ExplanationModalType;
  benefitInvestigation: BenefitInvestigationsData;
};

export const BenefitsInvestigationCard = ({ investigationType, benefitInvestigation }: BenefitsInsuranceCardProps) => {
  const isEmpty = investigationType === 'empty';

  const styles = useBenefitsInvestigationCardStyles(isEmpty);

  const [explanationModalVisible, setExplanationModalVisible] = useState(false);

  const program = useSelector(getSelectedProgram);

  const programName = program?.display_name || '';
  const benefitTitle = investigationType === 'copay' ? 'Your Co-pay' : 'Your Coinsurance';
  const benefitInfo = investigationType === 'copay' ? benefitInvestigation.copay : benefitInvestigation.coinsurance;
  const contrastInvestigationText = isEmpty ? ' IS NOT ' : ' IS ';
  const investigationDescriptionText = 'covered by your insurance provider.';

  return (
    <View style={styles.wrapper}>
      <View style={styles.investigationDescriptionWrapper}>
        {!isEmpty && <StandardText style={styles.supTitle}>Good News!</StandardText>}
        <StandardText style={styles.investigationDescription}>
          {programName}
          <StandardText style={styles.investigationPrimaryDescription}>{contrastInvestigationText}</StandardText>
          {investigationDescriptionText}
        </StandardText>
      </View>
      {!isEmpty && benefitInfo && (
        <InformationField infoTextStyles={styles.infoText} label={benefitTitle} info={benefitInfo} />
      )}
      <TouchableOpacity style={styles.meaningButton} onPress={() => setExplanationModalVisible(true)}>
        <StandardText>What does this mean?</StandardText>
      </TouchableOpacity>
      <ExplanationModal
        type={investigationType}
        explanationModalVisible={explanationModalVisible}
        setExplanationModalVisible={setExplanationModalVisible}
      />
    </View>
  );
};

BenefitsInvestigationCard.Demo = () => (
  <InsuranceInfoBox>
    <HeaderWrapper>
      <InsuranceText>
        CIZPLAM <InsuranceText primary>IS</InsuranceText> covered by your insurance provider.
      </InsuranceText>
      <Pressable>
        <StandardText>View or edit your insurance</StandardText>
      </Pressable>
    </HeaderWrapper>
    <InsuranceInfoWrapper>
      <InformationField label='Coinsurance' info='60%' />
      <InformationField label='Co-pay' info='$30' />
      <InformationField label='Deductible' info='$8500' />
      <InformationField label='Deductible Met' info='$1200' />
      <InformationField label='OOP Max' info='$8500' />
      <InformationField label='OOP Met' info='$1200' />
    </InsuranceInfoWrapper>
  </InsuranceInfoBox>
);
