import React from 'react';

import { StyleProp, TextStyle, View, ViewStyle } from 'react-native';

import { ButtonProps } from 'components/Button';
import { useConfirmModalStyles } from 'components/ConfirmModal/styles';
import { Modal, Button, Spacer } from 'components/index';
import { DefaultText, PrimaryDefaultText } from 'style/common';
import { ModalSizes } from 'style/types';
import { AnyFunction } from 'utils/types';

export type ConfirmModalButton = Pick<ButtonProps, 'text' | 'textStyle' | 'containerStyle' | 'variant'>;

export type ConfirmModalProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string;
  description: string;
  modalSize?: ModalSizes;
  styles?: ViewStyle;
  titleStyles?: StyleProp<TextStyle>;
  descriptionStyles?: StyleProp<TextStyle>;
  onConfirm?: AnyFunction;
  onCancel?: AnyFunction;
  buttons?: [ConfirmModalButton | undefined, ConfirmModalButton | undefined];
};

export const ConfirmModal = ({
  onCancel,
  onConfirm,
  title,
  description,
  visible,
  setVisible,
  styles: containerStyles,
  titleStyles,
  descriptionStyles,
  modalSize = 'small',
  buttons: [confirmButton, cancelButton] = [
    { text: 'Yes', variant: 'primary' },
    { text: 'No', variant: 'tertiary' },
  ],
}: ConfirmModalProps) => {
  const styles = useConfirmModalStyles(modalSize);

  return (
    <Modal
      modalIsVisible={visible}
      setModalVisible={setVisible}
      modalContentStyle={[styles.modalContainer, containerStyles]}>
      <>
        <PrimaryDefaultText style={[styles.title, titleStyles]}>{title}</PrimaryDefaultText>
        <DefaultText style={[styles.description, descriptionStyles]}>{description}</DefaultText>
        <View style={styles.buttonsContainer}>
          {!!cancelButton && (
            <>
              <Button onPress={onCancel} {...cancelButton} />
              <Spacer size={16} orientation='horizontal' />
            </>
          )}
          {!!confirmButton && <Button onPress={onConfirm} {...confirmButton} />}
        </View>
      </>
    </Modal>
  );
};
