export const fillByEmptySymbols = (value: unknown, length = 1, emptySymbol = '0') => {
  if (!emptySymbol.length) {
    throw new Error('Empty symbol can`t be as empty string. Please set correct symbol');
  }
  let str = `${value}`;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (str.length >= length) return str;

    str = emptySymbol + str;
  }
};
