import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

export const usePhotoPickerStyles = () => {
  const { width = 0, colors, fontFamilies, fontSizes } = useTheme();

  return StyleSheet.create({
    camera: StyleSheet.absoluteFillObject,
    cropper: { ...StyleSheet.absoluteFillObject, top: isNativeDevice ? 32 : 0 },
    topButtons: {
      position: 'absolute',
      top: 40,
      left: 24,
    },
    buttonContainer: {
      borderRadius: 30,
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      alignSelf: 'flex-start',
      justifyContent: 'center',
      alignItems: 'center',
    },
    backButton: {
      width: 40,
      height: 40,
    },
    bottomButtonsContainer: {
      position: 'absolute',
      bottom: 28,
      width: '100%',
      minHeight: 60,
      justifyContent: 'center',
    },
    takePhotoButton: {
      width: 60,
      height: 60,
      position: 'absolute',
      left: width / 2 - 30,
    },
    galleryButton: {
      position: 'absolute',
      padding: 6,
      right: 12,
    },
    loadingText: {
      top: '25%',
      textAlign: 'center',
      color: colors.white,
      fontFamily: fontFamilies.bold,
      ...fontSizes[18],
    },
  });
};
