import React from 'react';

import { useRoute } from '@react-navigation/native';
import { StyleSheet, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { RightArrow } from 'assets/icons';
import { Card } from 'components/Card';
import { Routes } from 'routes/main';
import { setDemoForwardButtonState, setDemoAdditionalServicesState, setDemoMilestoneTrackerState } from 'store/actions';
import { getDemoShowFastForwardButton, isMilestoneTrackerFinished, getIsCopayUser } from 'store/selectors';
import { getIsDemoUser } from 'store/selectors/user';
import { IconWrapper } from 'style/common';

import { FastForwardHeaderWrapper, ForwardText } from './styles';

const fastForwardCardStyles = StyleSheet.create({
  fastForwardTextStyle: { fontFamily: 'Montserrat-Bold' },
});

export type FastForwardCardProps = {
  styles?: ViewStyle;
};

export const FastForwardCard = ({ styles }: FastForwardCardProps) => {
  const route = useRoute();
  const demoShowFastForwardButton = useSelector(getDemoShowFastForwardButton);
  const isDemoUser = useSelector(getIsDemoUser);
  const isMilestoneTrackerDataFinished = useSelector(isMilestoneTrackerFinished);
  const isCopayUser = useSelector(getIsCopayUser);

  const dispatch = useDispatch();

  const { colors } = useTheme();

  const isHomePage = route.name === Routes.HOME;

  if (isMilestoneTrackerDataFinished || isCopayUser || !isHomePage) return null;
  if (!demoShowFastForwardButton || !isDemoUser) return null;

  const { fastForwardTextStyle } = fastForwardCardStyles;

  const forwardHandler = () => {
    dispatch(setDemoForwardButtonState(false));
    dispatch(setDemoAdditionalServicesState('AFTER_FORWARD_BUTTON_PRESS'));
    dispatch(setDemoMilestoneTrackerState('AFTER_FORWARD_BUTTON_PRESS'));
  };

  return (
    <Card
      isTouchable
      padding={30}
      style={{ backgroundColor: colors.secondary, ...styles }}
      pressHandler={forwardHandler}>
      <FastForwardHeaderWrapper>
        <ForwardText style={fastForwardTextStyle}>Fast Forward</ForwardText>
        <IconWrapper>
          <RightArrow stroke='#FFF' />
        </IconWrapper>
      </FastForwardHeaderWrapper>
      <ForwardText>Click here to fast forward in a patient’s journey in a patient support program.</ForwardText>
    </Card>
  );
};

export default FastForwardCard;
