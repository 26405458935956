import React, { PropsWithChildren } from 'react';

import { View, TouchableOpacity, ViewStyle } from 'react-native';

import { AnyFunction } from 'utils/types';

import { useCardStyle } from './styles';

interface Card
  extends PropsWithChildren<{
    isTouchable?: boolean;
    padding?: number;
    style?: ViewStyle;
    pressHandler?: AnyFunction;
    isNotificationCard?: boolean;
  }> {}

export const Card = ({ isTouchable, padding = 0, pressHandler, style, children, isNotificationCard = false }: Card) => {
  const styles = useCardStyle({ padding, style, isNotificationCard });

  return isTouchable ? (
    <TouchableOpacity style={styles.main} onPress={pressHandler}>
      {children}
    </TouchableOpacity>
  ) : (
    <View style={styles.main}>{children}</View>
  );
};

export default Card;
