import React, { Dispatch, SetStateAction } from 'react';

import { format } from 'date-fns';
import { View, TouchableOpacity } from 'react-native';
import { DateData, DayState } from 'react-native-calendars';

import { MedicationTrackerData } from 'store/reducers/medicine-cabinet/types';
import { DefaultText } from 'style/common';
import { extractTimeFromDate, getTimestampForCurrentDateUTC } from 'utils/dateTimeHelpers';

import { AppliedIndicator } from './AppliedIndicator';
import { useDayStyles } from './styles';

type CustomDayProps = Partial<{
  medTrackerInfo: MedicationTrackerData;
  date: DateData;
  state: DayState;
  isSelectable: boolean;
  shouldDisableFutureDate: boolean;
  selectedDate: number;
  setSelectedDate: Dispatch<SetStateAction<number>>;
}>;

const getTimeStampForDateData = (date: DateData) => {
  const currentTime = extractTimeFromDate(new Date());
  const [hours, minutes] = currentTime.split(':');
  const dateObject = new Date(date.year, date.month - 1, date.day, Number(hours), Number(minutes), 0, 0);
  return dateObject.getTime();
};

export const CustomDayComponent = ({
  medTrackerInfo,
  date,
  state,
  isSelectable,
  selectedDate,
  setSelectedDate,
}: CustomDayProps) => {
  const isSelectedDate = date?.timestamp === selectedDate;
  const isFutureDate = date && date.timestamp > getTimestampForCurrentDateUTC();

  const styles = useDayStyles({
    state,
    isSelectable,
    isSelectedDate,
  });

  const dayPressHandler = () => {
    if (!isFutureDate) {
      date && setSelectedDate?.(date.timestamp);
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={0.65}
      disabled={!isSelectable || isFutureDate}
      style={styles.container}
      onPress={dayPressHandler}>
      <View style={styles.wrapper}>
        <AppliedIndicator date={date} state={state} isSelectable={isSelectable} medTrackerInfo={medTrackerInfo} />
        <DefaultText style={styles.dayText}>{date?.day}</DefaultText>
        <DefaultText style={styles.weekDayText}>{date && format(getTimeStampForDateData(date), 'EEE')}</DefaultText>
      </View>
    </TouchableOpacity>
  );
};

export default CustomDayComponent;
