import React from 'react';

import { format } from 'date-fns';

import { InfoCard } from './InfoCard';

export type PrescriptionRefillCardProps = {
  hasRefill?: boolean;
  prescriptionRefillDate?: string;
};

export const PrescriptionRefillCard = ({ hasRefill, prescriptionRefillDate }: PrescriptionRefillCardProps) => {
  if (!hasRefill) return null;

  const formattedDate = prescriptionRefillDate ? format(new Date(prescriptionRefillDate), 'MMM d, yyyy') : 'No date';

  return <InfoCard headerText='Last Use' description='Your card was last used on' date={formattedDate} />;
};

export default PrescriptionRefillCard;
