export const getInfoCardsConfig = (delay = 0) => ({
  from: { opacity: 0, maxHeight: '0%', x: -500 },
  enter: { opacity: 1, maxHeight: '100%', x: 0 },
  leave: { opacity: 0, maxHeight: '0%', x: -500 },
  delay,
});

export const fadeInAnimationConfig = (delay = 0, duration = 500) => ({
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: {
    duration,
  },
  delay,
});

export const notificationCardsAnimation = (isMobile: boolean, delay = 0) => ({
  from: { opacity: 0, maxHeight: isMobile ? '0%' : '100%', x: -500 },
  enter: { opacity: 1, maxHeight: '100%', x: 0 },
  leave: { opacity: 0, maxHeight: '100%', x: 0 },
  delay,
});
