import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Gum = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 99.97 84.92' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M52.11 84.9H15.65C8.39 84.88.14 77.05.09 69.7 0 49.57 0 29.43.08 9.29.1 3.68 3.89.06 9.64 0q37.95 0 75.91.05C91.1.07 99.89 8.91 99.91 14.5q.12 30.47 0 60.91c0 5.92-3.75 9.45-9.85 9.48-12.65.11-25.3.01-37.95.01Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M99.91 14.5c0-3.52-3.51-8.33-7.47-11.41a10.39 10.39 0 0 1 1.75 5.07q.12 30.47 0 60.91c0 5.92-3.76 9.45-9.85 9.48-12.66.06-25.31 0-38 0H9.88a14.65 14.65 0 0 1-8.14-2.91c2.79 5.08 8.58 9.23 13.86 9.25H90.06c6.1 0 9.83-3.56 9.85-9.48q.09-30.41 0-60.91Z'
          fillOpacity={0.2}
        />
        <Path
          fillOpacity={0.4}
          d='M56.66 3.09H9.27a5 5 0 0 0-5 5v54.74a5 5 0 0 0 8.82 3.29L60.46 11.4a5 5 0 0 0-3.8-8.31Z'
        />
        <Path
          d='M35.7 73.88h47.8A4.89 4.89 0 0 0 88.39 69V13.8a4.89 4.89 0 0 0-8.59-3.21L32 65.78a4.9 4.9 0 0 0 3.7 8.1Z'
          fill='#fff'
          fillOpacity={0.2}
        />
        <Circle fillOpacity={0.4} cx={47.87} cy={10.77} r={4.88} />
      </G>
    </G>
  </Svg>
);

export default Gum;
