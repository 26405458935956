import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { MODAL_PADDING, MOBILE_HORIZONTAL_PADDING } from 'style/sizes';

export const useWebPdfViewerStyles = () => {
  const { isMobile, height = 0, width = 0 } = useTheme();

  /**
   * if mobile => screen height - header height - footer height(elements margins - texts and buttons heights);
   * others => 60vh
   * */
  const pdfHeight = isMobile ? height - MODAL_PADDING * 2 - MOBILE_HORIZONTAL_PADDING - 114 : '60vh';

  return StyleSheet.create({
    wrapper: {
      paddingHorizontal: isMobile ? MOBILE_HORIZONTAL_PADDING : 0,
      paddingTop: isMobile ? MOBILE_HORIZONTAL_PADDING : 0,
    },
    pdfWrapper: {
      maxHeight: pdfHeight,
      minHeight: pdfHeight,
    },
    actionButtonsWrapper: {
      flexDirection: 'row',
      marginTop: 12,
      marginBottom: isMobile ? 16 : 0,
    },
    nextButton: { marginLeft: 16 },
    page: {
      width: isMobile ? width - MODAL_PADDING : undefined,
    },
  });
};
