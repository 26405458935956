import { useState } from 'react';

import { SpecialtyPharmacyServicesInterface } from 'api/SpecialtyPharmacy';
import { makeRequestWithLoader } from 'utils/requests';

export const useSendSpecialtyPharmacySMS = (pharmacyId: string) => {
  const [isLoading, setLoading] = useState(false);

  const sendSMS = () =>
    makeRequestWithLoader(() => SpecialtyPharmacyServicesInterface.sendContactSMS(pharmacyId), setLoading);

  return {
    isLoading,
    sendSMS,
  };
};
