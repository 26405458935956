import React, { Fragment, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { InsuranceImages, UploadInsuranceImagesParams } from 'api/Insurance/types';
import { Check } from 'assets/icons';
import { Spacer } from 'components';
import { checkCameraPermission } from 'components/PhotoPicker/utils';
import { useForceRerender } from 'hooks';
import { useInsuranceNotification } from 'screens/main/Home/components/MissingInsuranceCard/useInsuranceNotification';
import { ProfileInfoLayout } from 'screens/main/Profile/components';
import { DesktopInsuranceModal } from 'screens/main/Profile/sections/InsuranceInformation/DesktopInsuranceModal/DesktopInsuranceModal';
import { useInsuranceStyles } from 'screens/main/Profile/sections/InsuranceInformation/styles';
import { InsuranceCardType } from 'screens/main/Profile/sections/InsuranceInformation/types';
import { useUploadInsuranceImages } from 'screens/main/Profile/sections/InsuranceInformation/useUploadInsuranceImages';
import { useStylesWithAdditional } from 'style/hooks';
import { applyDifferentDevicesFunction } from 'utils/applyDifferentDevicesFunction';

import { text } from './constants';
import { InsuranceCard } from './InsuranceCard/InsuranceCard';
import { useInsurances } from './useInsurances';

export type InsuranceInformationSectionProps = {
  setInsuranceSectionPosition: (y: number) => void;
};

export const InsuranceInformationSection = ({ setInsuranceSectionPosition }: InsuranceInformationSectionProps) => {
  const { styles, colors } = useStylesWithAdditional(useInsuranceStyles);

  const {
    insurances,
    editInsurances,
    isLoading: isGetInsurancesLoading,
    onUpdate,
    noHaveData,
    isProcessing,
  } = useInsurances();
  const toast = useToast();
  const containerRef = useRef<View>(null);
  const { uploadImages, isLoading: isUploadImagesLoading } = useUploadInsuranceImages();
  const { clearNotification, isLoading: isFetchingNotificationLoading } = useInsuranceNotification();

  const isLoading = isGetInsurancesLoading || isUploadImagesLoading || isFetchingNotificationLoading;

  const [isEditMode, setEditMode] = useState(false);
  const [hasImages, setHasImages] = useState(false);
  const [isDesktopModalShown, setDesktopModalShow] = useState(false);
  // TODO(refactor): need to find another solution for keeping imagesData, and remove this hook
  const { forceRerender } = useForceRerender();

  const imagesData = useRef<Required<UploadInsuranceImagesParams>>({ PHARMACY: {}, MEDICAL: {} });
  const { MEDICAL, PHARMACY } = imagesData.current;

  const isMedicalUploaded = [MEDICAL.front, MEDICAL.back].some(Boolean);
  const isPharmacyUploaded = [PHARMACY.front, PHARMACY.back].some(Boolean);
  const isMedicalTwoSidesUploaded = [MEDICAL.front, MEDICAL.back].every(Boolean);
  const isPharmacyTwoSidesUploaded = [PHARMACY.front, PHARMACY.back].every(Boolean);
  const isUploadOneSideOfMedical = isMedicalUploaded && !isMedicalTwoSidesUploaded;
  const isUploadOneSideOfPharmacy = isPharmacyUploaded && !isPharmacyTwoSidesUploaded;
  const isUploadOneSideOfBothCards = isUploadOneSideOfMedical && isUploadOneSideOfPharmacy;

  const suffix = isUploadOneSideOfBothCards ? 's' : '';
  const validationErrorMessage = `Please upload images for both the front and back of your ${
    !isUploadOneSideOfBothCards && isUploadOneSideOfMedical ? 'Medical ' : ''
  }${!isUploadOneSideOfBothCards && isUploadOneSideOfPharmacy ? 'Pharmacy ' : ''} insurance card${suffix}.`;

  const onEditModeChange = (state: SetStateAction<boolean>) => {
    if (isEditMode && (isUploadOneSideOfMedical || isUploadOneSideOfPharmacy)) {
      toast.show(validationErrorMessage, {
        type: 'danger',
        animationType: 'zoom-in', // zoom-in to correctly display the toast
      });

      return;
    }

    return applyDifferentDevicesFunction({
      onNativeDeviceWeb: () => setEditMode(state),
      onDesktopWeb: () => setEditMode(state),
      onNativeDevice: () => {
        // We need it because of react state management
        const editState = typeof state === 'boolean' ? state : state(isEditMode);

        editState ? checkCameraPermission().then(() => setEditMode(state)) : setEditMode(state);
      },
    });
  };

  // Upload images request
  useEffect(() => {
    if (isEditMode || !hasImages) return;

    uploadImages(imagesData.current, () => {
      onUpdate();
      clearNotification();
      setEditMode(false);
      imagesData.current = { MEDICAL: {}, PHARMACY: {} };
      toast.show('We’ve sent your insurance card!', { type: 'success', animationType: 'zoom-in' }); // zoom-in to correctly display the toast
    });
  }, [isEditMode, hasImages, onUpdate, uploadImages, clearNotification, toast]);

  const onImagesChanged = useCallback((images: InsuranceImages, type: InsuranceCardType) => {
    imagesData.current[type] = images;
    setHasImages(Object.values(imagesData.current).some(({ back, front }) => back || front));
  }, []);

  useEffect(() => {
    containerRef.current?.measureInWindow((_, y) => setInsuranceSectionPosition(y));
  }, [setInsuranceSectionPosition]);

  const descriptionBeforeUpload = noHaveData && !isProcessing ? text.BEFORE_UPLOAD_DESCRIPTION : undefined;
  const descriptionAfterUpload = isProcessing ? text.AFTER_UPLOAD_DESCRIPTION : undefined;
  const shouldShowInsuranceCards = isEditMode || isLoading || (!noHaveData && !isProcessing);
  const description = shouldShowInsuranceCards ? undefined : descriptionBeforeUpload || descriptionAfterUpload;

  const iconJSX = (
    <View style={styles.iconWrapper}>
      <Check stroke={colors.white} />
    </View>
  );

  return (
    <ProfileInfoLayout
      isEditMode={isEditMode}
      editButtonText='Upload'
      saveButtonText={hasImages ? 'Submit' : 'Cancel'}
      setEditMode={onEditModeChange}
      isProcessing={isProcessing}
      iconComponent={iconJSX}
      title='Insurance Information'
      description={description}
      titleColor={colors.gray1}
      savingIsDisabled={isLoading}>
      <DesktopInsuranceModal onVisibleChange={setDesktopModalShow} isVisible={isDesktopModalShown} />
      <View ref={containerRef} style={styles.insuranceInformationWrapper}>
        {shouldShowInsuranceCards &&
          (isEditMode || isLoading ? editInsurances : insurances.filter(({ status }) => status === 'PROCESSED')).map(
            (insurance, i) => (
              <Fragment key={i}>
                {!!i && <Spacer size={32} orientation='horizontal' />}
                <InsuranceCard
                  onImageChanged={onImagesChanged}
                  forceRerender={forceRerender}
                  isLoading={isLoading}
                  isEditMode={isEditMode}
                  insurance={insurance}
                />
                <Spacer size={24} orientation='vertical' />
              </Fragment>
            )
          )}
      </View>
    </ProfileInfoLayout>
  );
};
