import { REQUIRED_ADDRESS_FIELDS } from './constants';
import { UserAddress } from './types';

export const getAddressItems = ({ addressLine1, addressLine2, city, state, zipCode }: UserAddress): string[] =>
  [addressLine1, addressLine2, `${city}, ${state} ${zipCode}`].filter(Boolean);

export const hasRequiredAddressFields = (address: UserAddress) =>
  Object.entries(address).every(([key, value]) => {
    if (!REQUIRED_ADDRESS_FIELDS.includes(key as keyof UserAddress)) return true;

    return !!value;
  });
