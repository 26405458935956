export type TimePickerProps = {
  labelText: string;
  showInfo?: boolean;
  isHomeScreen?: boolean;
  hoursTestID?: string;
  minutesTestID?: string;
  meridiemTestID?: string;
  value: Date | string;
  onChange: (date?: Date | string) => void;
};

export enum MeridiemTypes {
  AM = 'AM',
  PM = 'PM',
}

export type PickerValues = {
  hourValue: string;
  minuteValue: string;
  meridiemValue: MeridiemTypes;
  convertedTime: string;
};
