import React from 'react';

import { View } from 'react-native';

import { commonStyles } from 'style/common';
import { defaultTheme } from 'style/theme';

import { useTemplateBoxStyles } from './styles';

export const TemplateBox = () => {
  const styles = useTemplateBoxStyles();

  return (
    <View style={[commonStyles.boxShadowStyle, styles.container]}>
      <View style={[styles.textRow, { width: 100 }]} />
      <View style={styles.divider16} />
      <View style={styles.textRow} />
      <View style={styles.divider16} />
      <View style={styles.circleTextContainer}>
        <View style={styles.circle} />
        <View style={[styles.textRow, { backgroundColor: defaultTheme.colors.gray3 }]} />
      </View>
    </View>
  );
};

export default TemplateBox;
