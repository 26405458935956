import React from 'react';

import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Kit = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 105.95 91.96' {...props}>
    <G data-name='Layer 2'>
      <G data-name='Layer 1'>
        <Path
          d='M103.8 22.3c-5.36-7.93-12.94-10-22-9.35-6.65.47-6.88.08-6.72-6.53.06-4.78-1.91-6.53-6.59-6.42C59.68.21 50.86 0 42 .07c-9 .03-9.35-1.14-9 8.72.12 3.24-.93 4.42-4.24 4.31-6.48-.21-13-.09-19.46 0S0 15.83 0 22.52v52.87c0 8.48 8.1 16.53 16.59 16.55C29.76 92 42.9 92 56 92h40.42c6.68 0 9.44-2.71 9.45-9.3V29.33a11.78 11.78 0 0 0-2.07-7.03Zm-36.66-9.21c-4.47-.21-9-.05-13.43-.05-4.31 0-8.63-.2-12.93.06-3.76.22-3.87-1.9-3.84-4.66C37 5.87 37 4 40.54 4Q54 4.29 67.4 4c4-.09 3.44 2.42 3.58 4.91.17 2.87-.62 4.34-3.84 4.18Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M103.8 22.3a21.14 21.14 0 0 0-6.54-6.38 11.67 11.67 0 0 1 1.48 5.85c-.06 17.79 0 35.58 0 53.37 0 6.59-2.77 9.29-9.45 9.3H9.45a15.35 15.35 0 0 1-7.57-2.12c2.85 5.46 8.7 9.61 14.79 9.62C29.76 92 42.9 92 56 92h40.42c6.68 0 9.44-2.71 9.45-9.3V29.33a11.78 11.78 0 0 0-2.07-7.03Z'
          fillOpacity={0.2}
        />
        <G fillOpacity={0.2}>
          <Path d='M74 5.58a56.27 56.27 0 0 0 .34 7h1.77C75 11.78 74.93 10 75 6.42c.07-2.91-.64-4.7-2.27-5.62C73.64 1.83 74 3.38 74 5.58ZM66.35 3.18c-8.95.19-17.91.17-26.86 0C36 3.11 35.91 5 35.89 7.59c0 1.37.13 3.84.2 5H38c-1.08-.78-1.12-2.31-1.11-4.11C36.94 5.87 37 4 40.52 4q13.42.27 26.86 0a5 5 0 0 1 2 .3c-.38-.71-1.28-1.16-3.03-1.12Z' />
        </G>
        <Path fill='#999' d='M77.2 42.21H57.74V22.74H45.29v19.47H25.82v12.45h19.47v19.47h12.45V54.66H77.2V42.21z' />
      </G>
    </G>
  </Svg>
);

export default Kit;
