import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Enema = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 56.24 123.17' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill={props.fill || defaultTheme.colors.gray3}
          d='M28.36 77.47s-20.87-.22-25.13 2.47v31.42s-.74 6.84 24.35 6.84 24.35-6.84 24.35-6.84V79.94c-4.27-2.69-23.57-2.47-23.57-2.47Z'
        />
        <Circle fill='#f3f3f3' cx={36.16} cy={101.61} r={4.68} />
        <Circle fill='#f3f3f3' cx={39.14} cy={88.63} r={2.66} />
        <Circle fill='#f3f3f3' cx={46.16} cy={96.93} r={2.66} />
        <Circle fill='#f3f3f3' cx={45.2} cy={109.69} r={3.4} />
        <Path
          d='M43.11 55a3.8 3.8 0 0 1-2.43-3.54v-5.5a3.71 3.71 0 0 0-.37-1.64L40 43.6a3.8 3.8 0 0 0-3.43-2.17H19.68a3.8 3.8 0 0 0-3.43 2.17l-.32.67a3.71 3.71 0 0 0-.37 1.64v5.5A3.8 3.8 0 0 1 13.13 55C8.75 56.67 0 60.88 0 67v44.21a3.66 3.66 0 0 0 .29 1.46c1.09 2.51 6 9.86 25.89 10.49a9.72 9.72 0 0 0 1.09 0H29a9.72 9.72 0 0 0 1.09 0c19.9-.63 24.8-8 25.89-10.49a3.66 3.66 0 0 0 .29-1.46V67c-.03-6.12-8.78-10.33-13.16-12Z'
          fillOpacity={0.4}
          fill='#e2e2e2'
        />
        <Path
          fill={props.fill || defaultTheme.colors.gray3}
          d='M34.52 27c3.2-3.21-.67-5.08-.67-5.08V4.71c0-6.28-11.77-6.28-11.77 0v17.23s-3.87 1.87-.67 5.08c-.8.93-7.74 6-7.74 6v11.23s1.24 3.07 13.91 3.07h.78c12.67 0 13.91-3.07 13.91-3.07V33s-6.95-5-7.75-6Z'
        />
        <Path
          fillOpacity={0.1}
          d='M22.08 21.94a29.41 29.41 0 0 0 11.77 0s3.72 2 .67 5.08a42.14 42.14 0 0 1-13.11 0s-3.12-2.96.67-5.08Z'
        />
        <Path
          fillOpacity={0.2}
          d='M13.67 33v11.25s1.24 3.07 13.91 3.07h.78c12.67 0 13.91-3.07 13.91-3.07V33c-17.16 4-28.6 0-28.6 0Z'
        />
        <Path
          fillOpacity={0.1}
          d='M22.61 15.5V20a.33.33 0 0 0 .1.23 3.79 3.79 0 0 0 2.43.83.31.31 0 0 0 .29-.48 19.17 19.17 0 0 1-2.21-5.13.31.31 0 0 0-.61.05Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={30.21} cy={6.93} r={2.09} />
        <Path
          fillOpacity={0.2}
          d='M51.93 80.53v-.59c-4.27-2.69-23.57-2.47-23.57-2.47s-20.87-.22-25.13 2.47v.37c5.2 2.46 20.77 7.6 48.7.22Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M28.18 73.91c13.31 0 22.67-4.23 28.06-7.65-.64-5.71-8.86-9.65-13.06-11.26a3.85 3.85 0 0 1-1.85-1.54 61.93 61.93 0 0 1-26.2-.16 3.77 3.77 0 0 1-1.94 1.7C9 56.59.92 60.48.14 66.1c3.71 2.9 12.3 7.81 28.04 7.81Z'
        />
      </G>
    </G>
  </Svg>
);

export default Enema;
