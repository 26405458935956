import React from 'react';

import { View } from 'react-native';

import { Replay } from 'assets/icons';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { DefaultText } from 'style/common';

import { getViewAgainActionBoxStyles } from './styles';

export const ViewAgainActionBox = () => {
  const { isMobile } = useDeviceInfoContext();
  const styles = getViewAgainActionBoxStyles(isMobile);
  return (
    <View style={styles.viewAgainContainer}>
      <View style={styles.replayIconWrapper}>
        <Replay stroke='#FFF' />
      </View>
      {!isMobile && <DefaultText style={styles.actionText}>View Again</DefaultText>}
    </View>
  );
};

export default ViewAgainActionBox;
