import React, { useEffect, useState } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { AppHTML } from 'components/AppHTML/AppHTML';
import { getSelectedMedication } from 'store/selectors';
import { sleep } from 'utils/helpers';

import { medicationDetailAnalyticsActions } from '../../constants';
import { medicationDetailsAnalyticsHandler } from '../../helpers';
import { SectionContainer, SectionTitle } from '../../styles';
import DetailsContentPlaceholder from './DetailsContentPlaceholder';
import { getDetailsTabStyles } from './styles';

const { VIEW_MEDICATION_DETAILS_SECTION } = medicationDetailAnalyticsActions;

export const Details = () => {
  const [loading, setLoading] = useState(true);

  const selectedMedication = useSelector(getSelectedMedication);
  const styles = getDetailsTabStyles();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) return;

    const applyMountedActions = async () => {
      medicationDetailsAnalyticsHandler(VIEW_MEDICATION_DETAILS_SECTION);
      await sleep(900);
      setLoading(false);
    };

    applyMountedActions();
  }, [isFocused]);

  if (loading || !selectedMedication?.details_file_url) return <DetailsContentPlaceholder />;

  return (
    <SectionContainer>
      <SectionTitle style={styles.detailsText}>Details</SectionTitle>
      {!!selectedMedication?.details_file_url && <AppHTML source={{ uri: selectedMedication.details_file_url }} />}
    </SectionContainer>
  );
};
