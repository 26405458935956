export const convertCamelCaseToSnake = (str: string) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

export const removeDashes = (str: string) => str.split('-').join('');

export const removeSpacesAndDashes = (str: string) => str.replace(/-|\s/g, '');

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const addDashesForPhoneNumber = (str: string) => {
  const cardValue = str?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !cardValue?.[2]
    ? cardValue?.[1]
    : `${cardValue[1]}-${cardValue[2]}${`${cardValue[3] ? `-${cardValue[3]}` : ''}`}${`${
        cardValue[4] ? `-${cardValue[4]}` : ''
      }`}`;
};

export const addDashesAndWhiteSpaceForTFACode = (str: string) => {
  const cardValue = str?.replace(/\D/g, '').match(/(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})(\d{0,1})/);
  return !cardValue?.[2]
    ? cardValue?.[1]
      ? `${cardValue?.[1]}`
      : ''
    : `${cardValue[1]} ${cardValue[2]}${`${cardValue[3] ? ` ${cardValue[3]}` : ''}`}${`${
        cardValue[4] ? ` - ${cardValue[4]}` : ''
      }`}${`${cardValue[5] ? ` ${cardValue[5]}` : ''}`}${`${cardValue[6] ? ` ${cardValue[6]}` : ''}`}`;
};
