import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { closeButtonStyles } from 'style/common';

export const useSimpleNotificationTileStyles = () => {
  const { colors, fontSizes } = useTheme();

  return StyleSheet.create({
    hoverButton: closeButtonStyles.onHover,
    buttonPadding: {
      alignSelf: 'flex-start',
      paddingVertical: 2,
    },
    externalLinkText: {
      color: colors.gray1,
      ...fontSizes[16],
    },
  });
};
