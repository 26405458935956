import React, { useRef, useState } from 'react';

import { useRoute } from '@react-navigation/native';
import { ImageSourcePropType, View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { gaHandler } from 'analytics/global_handlers';
import { EventCategories } from 'analytics/types';
import { UserInterface } from 'api';
import { mobilePatientLink } from 'assets/images';
import appStoreSrc from 'assets/images/app_store.png';
import googlePlaySrc from 'assets/images/google-play.png';
import { Button } from 'components/Button';
import Modal from 'components/Modal';
import { AppPlatformsEnum } from 'enums';
import { DefaultText, StyledImage, webStyles } from 'style/common';
import { handlePromise } from 'utils/handlePromise';
import { isWebNativeAndroidDevice, isWebNativeDevice, isWebNativeIOSDevice, throttleToastHide } from 'utils/helpers';

import { saveUserContactInfo } from '../../store/actions';
import { getUserPhoneNumber } from '../../store/selectors';
import { AddPhoneNumberModal } from '../AddPhoneNumberModal';
import {
  downloadCardAnalyticsActions,
  getDownloadButtonsVisibilityState,
  openAndroidUrl,
  openIOSUrl,
  text,
} from './constants';
import {
  AppStoreBadgeWrapper,
  ButtonContainer,
  ButtonWrapper,
  CardContentWrapper,
  GooglePlayBadgeWrapper,
  ModalDescription,
  ModalHeader,
  ModalWrapper,
  StyledImageWrapper,
  useStyles,
} from './styles';

const downloadCardAnalyticsHandler = gaHandler(EventCategories.DOWNLOAD_APP);
const { APP_DOWNLOAD_ACTION_FAILED, APP_DOWNLOAD_ACTION_TRIGGERED } = downloadCardAnalyticsActions;

export const DownloadCard = () => {
  const route = useRoute();
  const dispatch = useDispatch();
  const userPhoneNumber = useSelector(getUserPhoneNumber);
  const [selectedPlatform, setSelectedPlatform] = useState<AppPlatformsEnum>();
  const [questionerModal, setQuestionerModal] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [addPhoneNumberModalVisible, setAddPhoneNumberModalVisible] = useState(false);
  const toastRefQuestionerModal = useRef<ToastContainer>(null);
  const toastRefAddPhoneNumberModal = useRef<ToastContainer>(null);

  const styles = useStyles();

  const storeButtonAction = async () => {
    if (!selectedPlatform) return;

    addPhoneNumberModalVisible && (await dispatch(saveUserContactInfo()));
    const [, error] = await handlePromise(() => UserInterface.downloadApp(selectedPlatform));
    if (error) {
      downloadCardAnalyticsHandler(APP_DOWNLOAD_ACTION_FAILED, { event_label: route.name });
      toastRefQuestionerModal.current?.show(error?.response?.data?.message, { type: 'danger' });
      throttleToastHide(toastRefQuestionerModal.current);

      return;
    }
    setQuestionerModal(false);
    setConfirmationModalVisible(true);
    downloadCardAnalyticsHandler(APP_DOWNLOAD_ACTION_TRIGGERED, { event_label: route.name });
  };

  const openQuestionModal = (platform: AppPlatformsEnum) => {
    setSelectedPlatform(platform);
    setQuestionerModal(true);
  };

  const openAddPhoneNumberModal = async () => {
    await setQuestionerModal(false);
    await setAddPhoneNumberModalVisible(true);
  };

  const badgeClickHandler = (platform: AppPlatformsEnum) => {
    // check is mobile web
    if (isWebNativeDevice) {
      //trigger this event in mobile web as well.
      downloadCardAnalyticsHandler(APP_DOWNLOAD_ACTION_TRIGGERED, { event_label: route.name });
      platform === AppPlatformsEnum.IOS ? openIOSUrl() : openAndroidUrl();
      return;
    }
    openQuestionModal(platform);
  };

  return (
    <View style={styles.container}>
      <StyledImageWrapper style={styles.imageWrapper}>
        <StyledImage source={mobilePatientLink} style={styles.image} />
      </StyledImageWrapper>
      <CardContentWrapper style={styles.infoWrapper}>
        <Modal
          toastRef={toastRefQuestionerModal}
          transparent
          setModalVisible={setQuestionerModal}
          modalIsVisible={questionerModal}>
          <ModalWrapper>
            <ModalHeader>{text.questionModalTitle}</ModalHeader>
            <ModalDescription>{text.questionModalDescription}</ModalDescription>
            <ButtonContainer>
              <ButtonWrapper style={{ marginRight: 16 }}>
                <Button variant='tertiary' text='No' onPress={() => setQuestionerModal(false)} />
              </ButtonWrapper>
              <ButtonWrapper>
                <Button
                  variant='secondary'
                  text='Yes'
                  onPress={userPhoneNumber ? storeButtonAction : openAddPhoneNumberModal}
                />
              </ButtonWrapper>
            </ButtonContainer>
          </ModalWrapper>
        </Modal>
        <Modal transparent setModalVisible={setConfirmationModalVisible} modalIsVisible={confirmationModalVisible}>
          <ModalWrapper>
            <ModalHeader>{`${selectedPlatform} ${text.sendTitle}`}</ModalHeader>
            <ModalDescription>{text.sendDescription}</ModalDescription>
            <ButtonWrapper>
              <Button variant='secondary' text='Okay' onPress={() => setConfirmationModalVisible(false)} />
            </ButtonWrapper>
          </ModalWrapper>
        </Modal>

        <View style={styles.textsWrapper}>
          <DefaultText style={styles.goMobileTextStyle}>{text.downloadCardTitle}</DefaultText>
          <DefaultText style={styles.stayConnectedStyle}>{text.downloadCardDescription}</DefaultText>
        </View>
        <View style={styles.badgesWrapperStyle}>
          {getDownloadButtonsVisibilityState(isWebNativeIOSDevice) && (
            <AppStoreBadgeWrapper style={webStyles.button} onPress={() => badgeClickHandler(AppPlatformsEnum.IOS)}>
              <StyledImage source={appStoreSrc as ImageSourcePropType} />
            </AppStoreBadgeWrapper>
          )}
          {getDownloadButtonsVisibilityState(isWebNativeAndroidDevice) && (
            <GooglePlayBadgeWrapper
              style={webStyles.button}
              onPress={() => badgeClickHandler(AppPlatformsEnum.Android)}>
              <StyledImage source={googlePlaySrc as ImageSourcePropType} />
            </GooglePlayBadgeWrapper>
          )}
        </View>
      </CardContentWrapper>
      <AddPhoneNumberModal
        toastRef={toastRefAddPhoneNumberModal}
        visible={addPhoneNumberModalVisible}
        setVisible={setAddPhoneNumberModalVisible}
        storeButtonAction={storeButtonAction}
      />
    </View>
  );
};

export default DownloadCard;
