import React from 'react';

import DateTimePicker from 'react-datepicker';
import { View } from 'react-native';

import InputLabel from 'components/InputLabel';

import 'react-datepicker/dist/react-datepicker.css';
import { WebDatePickerContainer } from './styles';
import { DatePickerProps } from './types';

export const DatePicker = ({ labelText, onChange, value, minDate, testID }: DatePickerProps) => {
  const initialValue = value || undefined;

  return (
    <WebDatePickerContainer>
      <InputLabel labelText={labelText} />
      <View testID={testID}>
        <DateTimePicker minDate={minDate} popperPlacement='auto-start' onChange={onChange} selected={initialValue} />
      </View>
    </WebDatePickerContainer>
  );
};

export default DatePicker;
