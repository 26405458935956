export enum MedTypeEnum {
  BAR = 'bar',
  CAPSULE = 'capsule',
  CONCENTRATION = 'concentration',
  CREAM = 'cream',
  DRESSING = 'dressing',
  DROP = 'drop',
  ELIXIR = 'elixir',
  ENEMA = 'enema',
  FILM = 'film',
  FOAM = 'foam',
  GEL = 'gel',
  GEL_FORMING_SOLUTION = 'gel-forming-solution',
  GEL_WITH_APPLICATOR = 'gel-with-applicator',
  GRANULE = 'granule',
  GUM = 'gum',
  INHALER = 'inhaler',
  INJECTION = 'injection',
  KIT = 'kit',
  LIQUID = 'liquid',
  LOTION = 'lotion',
  LOZENGE = 'lozenge',
  OINTMENT = 'ointment',
  PAD = 'pad',
  PASTE = 'paste',
  PATCH = 'patch',
  POWDER = 'powder',
  POWDER_FOR_INJECTION = 'powder-for-injection',
  RING = 'ring',
  SHAMPOO = 'shampoo',
  SPONGE = 'sponge',
  SPRAY = 'spray',
  STICK = 'stick',
  STRIP = 'strip',
  SUPPOSITORY = 'suppository',
  SWAB = 'swab',
  SYRUP = 'syrup',
  TABLET = 'tablet',
  TAPE = 'tape',
  TINCTURE = 'tincture',
  WATER = 'water',
}
