import React, { useState, useRef, useCallback } from 'react';

import { ImageSourcePropType, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { ArrowDown } from 'assets/icons';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useImageSizes, useOnClickOutside } from 'hooks';
import { getPrograms } from 'store/selectors';
import { StyledImage } from 'style/common';
import { getImageUrl } from 'utils/white-labeling';

import { LogoContainer } from '../styles';
import SelectPopup from './SelectPopup';
import { getStyles } from './styles';

export const ProgramSelect = ({ logo }: { logo: ImageSourcePropType }) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const popupRef: React.MutableRefObject<View | null> = useRef(null);
  const theme = useTheme();

  const programs = useSelector(getPrograms);

  const hasMoreThanOneProgram = programs?.length > 1;

  const { imageWidth: logoWidth } = useImageSizes({ url: logo });

  const { isMobile } = useDeviceInfoContext();

  const styles = getStyles(isMobile, logoWidth);

  const closeProgramSelectionPopup = useCallback(() => setPopupVisible(false), []);

  const showProgramSelectionPopup = () => {
    if (hasMoreThanOneProgram) setPopupVisible(true);
  };

  // @ts-ignore
  useOnClickOutside(popupRef, closeProgramSelectionPopup);

  const visibilityHandler = (visible: boolean) => setPopupVisible(visible);

  return (
    <LogoContainer onPress={showProgramSelectionPopup}>
      <View style={styles.imageWrapper}>
        <StyledImage resizeMode='contain' source={getImageUrl(logo)} />
      </View>
      {hasMoreThanOneProgram && (
        <View style={styles.listArrow}>
          <ArrowDown stroke={theme.colors.gray2} />
        </View>
      )}
      <SelectPopup ref={popupRef} visible={popupVisible} setVisibility={visibilityHandler} />
    </LogoContainer>
  );
};

export default ProgramSelect;
