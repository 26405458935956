import React from 'react';

import { TouchableOpacity } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';

import { Plus } from 'assets/icons';
import { setAsNeededMedicationModalVisibility } from 'store/actions/medicineCabinet';
import { StandardText } from 'style/common';

import { useAsNeededMedicationStyles } from './styles';

export const AddAsNeededMedicationTile = () => {
  const dispatch = useDispatch();
  const styles = useAsNeededMedicationStyles();

  const handleOnPress = () => {
    dispatch(setAsNeededMedicationModalVisibility(true));
  };

  return (
    <TouchableOpacity onPress={handleOnPress} style={styles.asNeededMedicationContainer}>
      <StandardText style={styles.asNeededText}>As-Needed Medication </StandardText>
      <Plus />
    </TouchableOpacity>
  );
};

export default AddAsNeededMedicationTile;
