import React from 'react';

import { useSelector } from 'react-redux';

import { ServiceActionItem } from 'components/ServiceActionItem';
import type { Action } from 'store/reducers/additional-services/types';
import { CustomServiceTypeEnum } from 'store/reducers/additional-services/types';
import { getActions } from 'store/selectors';

import { iconMapping } from '../map';

type ScheduleActionsProp = {
  actionHandler: (service: Action) => void;
};

export const ScheduleActions = ({ actionHandler }: ScheduleActionsProp) => {
  const actions = useSelector(getActions);

  return (
    <>
      {actions?.map((action) => (
        <ServiceActionItem
          key={action.id}
          onAction={() => actionHandler(action)}
          done={action.isCompleted}
          id={action.id}
          userDataUuid={action.userDataUuid}
          isNew={action.isNew}
          stateType={CustomServiceTypeEnum.actions}
          type={action.type}
          actionLabel={action.label}
          IconComponent={(iconMapping as any)[action.icon]}
          triggerableAction={action.repeatable}
        />
      ))}
    </>
  );
};
