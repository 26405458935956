import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { ALLOWED_MODAL_PADDINGS } from 'style/sizes';

export const useInsuranceDesktopModalStyles = () => {
  const { fontSizes, fontFamilies, colors, width = 0 } = useTheme();

  return StyleSheet.create({
    modalWrapper: {
      maxWidth: 324, // Will be design width or device width - paddings
      width: width - ALLOWED_MODAL_PADDINGS,
      paddingHorizontal: 40,
      paddingTop: 64,
      paddingBottom: 40,
    },
    title: {
      color: colors.gray1,
      fontFamily: fontFamilies.bold,
      marginBottom: 12,
      ...fontSizes[24],
    },
    closeButton: {
      marginTop: 40,
      alignSelf: 'flex-start',
    },
  });
};
