import styled from 'styled-components';

export const WebSwitchContainer = styled.div<{ isActive?: boolean }>`
  .toggle-switch {
    position: relative;
    display: inline-block;
    top: 2px;
    width: 52px;
    height: 28px;
  }

  .disable {
    pointer-events: none;
    opacity: 0.4;
  }

  .toggle-switch input[type='checkbox'] {
    display: none;
  }

  .toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #787d83;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
  }

  .toggle-switch .switch::before {
    position: absolute;
    content: '';
    left: 1px;
    top: 2px;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 100%;
    transition: transform 0.3s ease;
  }

  .toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(25px);
    background-color: white;
  }

  .toggle-switch input[type='checkbox']:checked + .switch {
    background-color: #4ebcc3;
  }
` as React.ElementType;
