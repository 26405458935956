import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { MOBILE_HORIZONTAL_PADDING } from 'style/sizes';

export const useInsuranceEditCardContentStyles = () => {
  const { colors, isMobile, isTablet, width = 0 } = useTheme();

  const mobileImageWidth = (width - MOBILE_HORIZONTAL_PADDING * 2 - 24 * 2 - 2) / 2 - 16; // (width - 64(paddings) - 48(inner paddings) - 2(border)) / 2 - 16(right-margin)
  const imageWidth = isMobile ? mobileImageWidth : isTablet ? '40%' : '50%';
  const imageHeight = isMobile ? mobileImageWidth / 1.5 : isTablet ? 100 : 60;

  return StyleSheet.create({
    imageFieldWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 8,
    },
    button: { paddingLeft: 4, paddingRight: 4 },
    imageWrapper: {
      width: imageWidth,
      height: imageHeight,
      minHeight: 84,
      minWidth: 100,
      marginRight: 16,
      borderRadius: 8,
      padding: isTablet ? 10 : 12,
      backgroundColor: colors.gray4,
    },
    emptyImageContent: {
      backgroundColor: colors.white,
      borderRadius: 8,
      width: '100%',
      height: '100%',
    },
  });
};
