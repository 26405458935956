import React from 'react';

import { ImageSourcePropType, ViewProps } from 'react-native';

import { patientProfileImg } from 'assets/images';
import { StyledImage } from 'style/common';

import { CircularBadgeContainer, BadgeImageWrapper, useStyles } from './styles';

export type ProfileBadgeProps = {
  source?: ImageSourcePropType;
};

export const ProfileBadge = ({ source }: ProfileBadgeProps & ViewProps) => {
  const styles = useStyles();

  return (
    <CircularBadgeContainer style={styles.badgeStyles}>
      <BadgeImageWrapper>
        <StyledImage source={source || patientProfileImg} />
      </BadgeImageWrapper>
    </CircularBadgeContainer>
  );
};

export default ProfileBadge;
