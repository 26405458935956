import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useMilestoneTrackerStyles = () => {
  const { colors, fontSizes } = useTheme();

  return StyleSheet.create({
    metaFieldText: {
      paddingVertical: 10,
      color: colors.gray1,
      ...fontSizes[16],
    },
  });
};
