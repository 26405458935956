import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Stick = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 102.25 136.8' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill={props.fill || defaultTheme.colors.gray3}
          d='M8.16 27C1.72 27-2 19.13 1.15 14.9A37.63 37.63 0 0 1 25.6.1c3.94-.67 6.53 2.1 8.6 5s.14 5.6-1.73 7.56A54.21 54.21 0 0 1 8.16 27ZM15.93 56.72C29.68 57.83 49 46.12 54.58 33.36c5.62 9.3 11.11 18.34 16.56 27.42C78.57 73.14 86 85.49 93.35 97.92c4.9 8.32 5.18 9.21-1.41 16.54-7.75 8.61-17.62 13.8-29.52 14.43-2.64.14-4.29-.95-5.63-3.22-13.47-22.83-27.02-45.62-40.86-68.95Z'
        />
        <Path
          fillOpacity={0.2}
          d='M19.18 14.31C29.05 8.39 29.77.76 29.77.76A6.43 6.43 0 0 0 25.6.1 37.63 37.63 0 0 0 1.15 14.9a6.53 6.53 0 0 0-.89 5.61 44.2 44.2 0 0 0 18.92-6.2Z'
        />
        <Path
          fill='#ddd'
          d='M54.54 31.23c-.61-.93-1.15-1.91-1.67-2.9-2-3.89-4.43-4-4.43-4S39.82 10.49 38.08 7.49s-3.47-1.62-3.47-1.62v.44c.14 2-1.42 4.68-2.7 6.22a37.52 37.52 0 0 1-9.34 7.86 47.06 47.06 0 0 1-14.18 5.75 7.45 7.45 0 0 1-5.33-1l-.43-.27a2.81 2.81 0 0 0-1.24 4.3c1.86 2.93 10.14 17 10.14 17s-1 2.18 1.49 5.78c.64.92 1.26 1.85 1.8 2.82v.05a3.87 3.87 0 0 0 3.08 2 22.36 22.36 0 0 0 10.74-1.73c2.33-1 14.1-6.9 19.73-11.42a22.42 22.42 0 0 0 6.5-8.72 3.83 3.83 0 0 0-.33-3.64Z'
        />
        <Ellipse fillOpacity={0.2} cx={25.49} cy={2.62} rx={2.26} ry={1.42} transform='rotate(-30 25.495 2.631)' />
        <Path fillOpacity={0.1} d='M60.92 127.15 29.61 77.68s30.32 45.93 39.33 48.66-5.26 3.45-5.26 3.45Z' />
        <Path
          fillOpacity={0.2}
          d='m58.27 41.54 39.14 65.95-5.56 10.74-43.14-69.39a3.13 3.13 0 0 1 .49-3.9l4.24-4.06a3.12 3.12 0 0 1 4.83.66Z'
        />
        <Path
          fill='#ddd'
          d='m57.23 126.8 6.45 10s15.56.41 24.82-7.28c13-8.32 13.75-16.92 13.75-16.92l-6.34-11.32s2 10.27-13.9 19.78c-14.96 8.94-24.78 5.74-24.78 5.74Z'
        />
        <G fillOpacity={0.4}>
          <Path
            d='M54.57 31.28v-.05c-.61-.93-1.15-1.91-1.67-2.9a9.06 9.06 0 0 0-2.39-3c.24.42.49.83.76 1.24a3.86 3.86 0 0 1 .33 3.65 22.42 22.42 0 0 1-6.5 8.72c-5.63 4.52-17.4 10.39-19.73 11.42a22.59 22.59 0 0 1-10.74 1.73 4 4 0 0 1-2-.77c.13.21.26.42.41.63.64.92 1.26 1.85 1.8 2.82v.05a3.87 3.87 0 0 0 3.08 2 22.36 22.36 0 0 0 10.74-1.73c2.33-1 14.1-6.9 19.73-11.42a22.42 22.42 0 0 0 6.5-8.72 3.83 3.83 0 0 0-.32-3.67Z'
            fill='#fff'
          />
        </G>
        <Path
          fillOpacity={0.1}
          d='M.8 28.14s6.78 2.66 13-.27c10-3.69 19.62-10.37 21.44-14.18a32 32 0 0 0 2.21-7.1c.11.12 11 17.74 11 17.74s1.06 6.89-14.27 15.88c-13.51 7.93-22.64 5.95-22.64 5.95Z'
        />
        <Path
          d='M34.38 17.48a11.29 11.29 0 0 0 3.73-6.11c.14-.58 1-.52 1.35 0 1.08 1.75 3.19 5.93 4.51 7.93a.79.79 0 0 1-1 1.16 19.78 19.78 0 0 0-8.09-1.58.79.79 0 0 1-.5-1.4Z'
          fillOpacity={0.2}
          fill='#fff'
        />
        <Path
          fillOpacity={0.1}
          d='m10.12 42.43 1.35 2.44a.4.4 0 0 0 .14.15c.33.19 1.6.77 4.22.33a.4.4 0 0 0 .06-.78 16.54 16.54 0 0 1-5.17-2.64.4.4 0 0 0-.6.5Z'
        />
      </G>
    </G>
  </Svg>
);

export default Stick;
