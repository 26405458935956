import { useEffect, useState } from 'react';

import { useFonts } from 'expo-font';

import { bootstrapApp } from './bootstrapApp';

const useAppBootstrapping = () => {
  const [appIsLoading, setAppLoading] = useState(true);

  const [fontsAreLoaded] = useFonts({
    Montserrat: require('assets/fonts/Montserrat-Regular.ttf'),
    'Montserrat-SemiBold': require('assets/fonts/Montserrat-SemiBold.ttf'),
    'Montserrat-Bold': require('assets/fonts/Montserrat-Bold.ttf'),
  });

  useEffect(() => {
    bootstrapApp({ fontsAreLoaded, setAppLoading });
  }, [fontsAreLoaded, setAppLoading]);

  return { appIsLoading };
};

export default useAppBootstrapping;
