import isEqual from 'lodash.isequal';
import { createSelector, createSelectorCreator, defaultMemoize, Selector } from 'reselect';

import { CommunicationPreferences } from 'screens/main/Profile/sections/CommunicationPreferences/types';
import type { InitialUserState } from 'store/reducers/user-info/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// Internal selectors
const _getUserInsurances = (state: InitialUserState) => state.userInfo.insurances;
const _getPrograms = (state: InitialUserState) => state.userInfo.programs;
const _getSelectedCopayCard = (state: InitialUserState) => state.userInfo.selectedCopayCard;
const _getAllSurveysIds = (state: InitialUserState) => state.userInfo.surveys?.result;
const _getAllSurveysById = (state: InitialUserState) => state.userInfo.surveys?.entities?.surveys;
const _getAllSurveysQuestionsById = (state: InitialUserState) => state.userInfo.surveys?.entities?.questions;
const _getConsentModalsQueue = (state: InitialUserState) => state.userInfo.consentModalsQueue;
const _getCopayCards = (state: InitialUserState) => state.userInfo.copayCards;
const _getUserConsents = (state: InitialUserState) => state.userInfo.consentModalsState.app;
const _getProgramsConsents = (state: InitialUserState) => state.userInfo.consentModalsState.program;
const _getConsentModalsState = (state: InitialUserState) => state.userInfo.consentModalsState;
const _getCurrentlyActiveConsent = (state: InitialUserState) => state.userInfo.currentlyActiveConsent;
const _getNotificationTiles = (state: InitialUserState) => state.userInfo.notificationTiles;
//

export const getUserAuthorization = (state: InitialUserState) => state.userInfo.authorized;
export const getUserHasPsp = (state: InitialUserState) => state.userInfo.hasPsp;
export const getUserFirstName = (state: InitialUserState) => state.userInfo.userFirstName;
export const getUserLastName = (state: InitialUserState) => state.userInfo.userLastName;
export const getUserPreferredName = (state: InitialUserState) => state.userInfo.userPreferredName;
export const getContactPersonName = (state: InitialUserState) => state.userInfo.contactPersonName;
export const getUserType = (state: InitialUserState) => state.userInfo.userType;
export const getUserAddressLine1 = (state: InitialUserState) => state.userInfo.addressLine1;
export const getUserAddressLine2 = (state: InitialUserState) => state.userInfo.addressLine2;
export const getUserCity = (state: InitialUserState) => state.userInfo.city;
export const getUserState = (state: InitialUserState) => state.userInfo.state;
export const getUserZipCode = (state: InitialUserState) => state.userInfo.zipCode;
export const getUserEmail = (state: InitialUserState) => state.userInfo.email;
export const getUserPhoneNumber = (state: InitialUserState) => state.userInfo.phoneNumber;
export const getShowWelcomeCard = (state: InitialUserState) => state.userInfo.showWelcomeCard;
export const getIsDemoUser = (state: InitialUserState) => state.userInfo.isDemo;
export const getIsCopayUser = (state: InitialUserState) => state.userInfo.isCopayUser;
export const getIsUserDoubleOpt = (state: InitialUserState) => state.userInfo.doubleOpt;

export const getUserHasMailPreference = (state: InitialUserState) => state.userInfo.hasMailPreference;
export const getUserHasCellPhonePreference = (state: InitialUserState) => state.userInfo.hasCellPhonePreference;
export const getUserHasEmailPreference = (state: InitialUserState) => state.userInfo.hasEmailPreference;
export const getHasPushNotificationPreference = (state: InitialUserState) =>
  state.userInfo.hasPushNotificationPreference;
export const getUserTimeZoneInformation = (state: InitialUserState) => state.userInfo.userTimeZoneInformation;
export const getUserCommunicationPreferences: Selector<never, CommunicationPreferences> = createSelector(
  [
    getUserHasCellPhonePreference,
    getUserHasMailPreference,
    getUserHasEmailPreference,
    getHasPushNotificationPreference,
  ],
  (hasCellPhonePreference, hasMailPreference, hasEmailPreference, hasPushNotificationPreference) => ({
    hasCellPhonePreference,
    hasMailPreference,
    hasEmailPreference,
    hasPushNotificationPreference,
  })
);

export const getUserToken = (state: InitialUserState) => state.userInfo.token;
export const getUserPushToken = (state: InitialUserState) => state.userInfo.pushToken;
export const getUserCognitoId = (state: InitialUserState) => state.userInfo.cognitoId;
export const getShouldProposeTutorial = (state: InitialUserState) => state.userInfo.shouldProposeTutorial;

export const getShouldShowSurvey = (state: InitialUserState) => state.userInfo.shouldShowSurvey;
export const getForgotPasswordModalVisible = (state: InitialUserState) => state.userInfo.isForgotPasswordModalVisible;
export const getForgotPasswordModalMessage = (state: InitialUserState) => state.userInfo.withForgotPasswordModalMessage;
export const getSessionExpiredModalVisible = (state: InitialUserState) => state.userInfo.isSessionExpiredModalVisible;
export const getActivateAccountModalVisible = (state: InitialUserState) => state.userInfo.isActivateAccountModalVisible;
export const getShouldForceSurveyOpen = (state: InitialUserState) => state.userInfo.shouldForceSurveyOpen;
export const getSurveyIsCompleted = (state: InitialUserState) => state.userInfo.isSurveyCompleted;

const getSelectedSurveyId = (state: InitialUserState) => state.userInfo.selectedSurveyId;

export const getForwardedSurveyIsExpired = createSelector(
  [_getAllSurveysIds, getSelectedSurveyId],
  (surveyIds, selectedSurveyId) => {
    if (!selectedSurveyId || !surveyIds.length) return false;

    return !surveyIds.includes(selectedSurveyId);
  }
);

export const getSelectedSurvey = createSelector(
  [_getAllSurveysById, getSelectedSurveyId],
  (allSurveysById, selectedSurveyId) => {
    if (!selectedSurveyId) return;
    return allSurveysById?.[selectedSurveyId];
  }
);
export const getUnnormalizedSelectedSurvey = createSelector(
  [_getAllSurveysById, _getAllSurveysQuestionsById, getSelectedSurveyId],
  (allSurveysById, allSurveysQuestionsById, selectedSurveyId) => {
    if (!selectedSurveyId || !allSurveysById) return;
    return {
      ...allSurveysById[selectedSurveyId],
      questions: allSurveysById[selectedSurveyId].questions.map(
        (questioId: string) => allSurveysQuestionsById[questioId]
      ),
    };
  }
);

export const selectSurveyById = (surveyId?: string) =>
  createSelector([_getAllSurveysById, _getAllSurveysQuestionsById], (allSurveysById, allSurveysQuestionsById) => {
    if (!surveyId) return;
    return {
      ...allSurveysById[surveyId],
      questions: allSurveysById[surveyId].questions.map((questioId: string) => allSurveysQuestionsById[questioId]),
    };
  });

export const getQuestionsOfSelectedSurvey = createSelector(
  [getSelectedSurvey, _getAllSurveysQuestionsById],
  (selectedSurvey, allSurveysQuestionsById) => {
    if (!selectedSurvey || !allSurveysQuestionsById) return;
    return selectedSurvey.questions.map((questionId) => allSurveysQuestionsById[questionId]);
  }
);
export const getProgressOfSelectedSurvey = createSelector([getSelectedSurvey], (selectedSurvey) => {
  if (!selectedSurvey) return;
  return selectedSurvey.percent_of_complete;
});
export const getAllSurveysIds = createSelector(_getAllSurveysIds, (allSurveysIds) => allSurveysIds);

export const getUserInsurances = createSelector(_getUserInsurances, (insurances) => insurances);

export const getWebDrawerOpenStatus = (state: InitialUserState) => state.userInfo.isWebDrawerOpen;

export const getSelectedProgramID = (state: InitialUserState) => state.userInfo.selectedProgramId;
export const getSelectedProgramIsDefault = (state: InitialUserState) => state.userInfo.selectedProgramIsDefault;

export const getPrograms = createSelector(_getPrograms, (programs) => programs);
export const getSelectedProgram = createSelector([_getPrograms, getSelectedProgramID], (programs, selectedProgramID) =>
  programs.find((program) => program.id === selectedProgramID)
);
export const getSelectedProgramConfig = createSelector(getSelectedProgram, (program) => program?.programConfig);

export const getSelectedCopayCard = createSelector(_getSelectedCopayCard, (copayCard) => copayCard);

export const getShowEventNotification = (state: InitialUserState) => state.userInfo.showEventNotification;

export const getConsentModalsQueue = createDeepEqualSelector(_getConsentModalsQueue, (queue) => queue);

export const getProgramCopayCards = createSelector(_getCopayCards, (copayCard) => copayCard);

export const getUserConsents = createSelector(_getUserConsents, (consents) => consents);
export const getProgramConsents = createSelector(_getProgramsConsents, (consents) => consents);
export const getConsentsModalState = createDeepEqualSelector(_getConsentModalsState, (consentsState) => consentsState);

export const getServiceConsentsModalState = createDeepEqualSelector(
  _getConsentModalsState,
  (consentsState) => consentsState.service
);

export const getCurrentlyActiveConsent = createDeepEqualSelector(
  _getCurrentlyActiveConsent,
  (activeConsent) => activeConsent
);

// getConsentsGroupToShow returns only app- or program-level consents
export const getConsentsGroupToShow = createDeepEqualSelector(
  [getConsentsModalState, getCurrentlyActiveConsent],
  (consentsState, activeConsent) => {
    if (activeConsent?.groupType && activeConsent.groupType !== ConsentModalTypeEnum.service) {
      return consentsState[activeConsent.groupType];
    }
  }
);

export const getNotificationTiles = createSelector(_getNotificationTiles, (tiles) => tiles);

export const getSimpleTextNotificationTiles = (state: InitialUserState) => state.userInfo.simpleTextNotificationTiles;

export const getFinancialAssistanceData = (state: InitialUserState) => state.userInfo.financialAssistanceData;
