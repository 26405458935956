import { StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';

import { BaseCard, DefaultText, getFontStyles } from 'style/common';
import { defaultTheme } from 'style/theme';

export const getStyles = (isMobile?: boolean) =>
  StyleSheet.create({
    reminderTime: { color: defaultTheme.colors.gray1, fontFamily: defaultTheme.fontFamilies.semiBold, fontSize: 15 },
    reminderInfoText: { maxWidth: 80 },
    actionText: { maxWidth: isMobile ? 300 : '100%', marginLeft: 0, paddingBottom: 14 },
    swipeableRightActionsWrapper: { flexDirection: 'row', justifyContent: 'flex-end', paddingLeft: 24 },
    takeInfo: { maxWidth: isMobile ? 300 : '100%', color: defaultTheme.colors.gray2 },
    touchableArea: { padding: 10, paddingLeft: 0, paddingRight: 24 },
    actionsWrapper: { width: 60, flexDirection: 'row', justifyContent: 'space-between' },
  });

export const ReminderCardContainer = styled(BaseCard)`
  border-radius: 16px;
  width: 100%;
  padding: 27px 24px;
  background-color: #fffffe;
  margin-bottom: 20px;
  ${(props) =>
    props.theme.isMobile &&
    css`
      flex-direction: column;
      justify-content: space-between;
      width: ${(props) => props.theme.width! - 64}px;
    `}
`;

export const MedicineInformationWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ReminderInformationWrapper = styled(MedicineInformationWrapper)`
  border-bottom-width: 0;
  align-items: flex-end;
`;

export const ReminderInfoText = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${(props) => props.theme.colors.gray2};
`;
