import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { View, StyleProp, ViewStyle } from 'react-native';

import { AppScrollView, ContactCard, DownloadCard, FastForwardCard, Footer, Header } from 'components';
import { AppScrollViewRef } from 'components/AppScrollView/types';
import { WEB_STEPS_ORDER } from 'components/Tourguide/constants';
import { WebTutorialWrapper } from 'components/Tourguide/WebTutorialWrapper';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { Routes } from 'routes/main';
import { SessionExpiredModal } from 'screens/auth/Login/components';
import { MedicineCenterHeader } from 'screens/main/MedicineCabinet/layout/header';
import { SafeViewWrapper } from 'style/common';
import { useIsBreakpoint } from 'style/hooks';
import { defaultTheme } from 'style/theme';
import { useShowElementOn } from 'style/utils';
import { isNativeDevice } from 'utils/helpers';

import {
  useStyles,
  ContentArea,
  LeftSideContentWrapper,
  PageDescription,
  PageInformationWrapper,
  PageTitle,
  RightSideContentWrapper,
  ContentContainer,
} from './styles';
import TransitionWrapper from './TransitionWrapper';

type LayoutProps = {
  title: string;
  description: string;
  navigation: DrawerNavigationHelpers;
  mobileContentAreaStyle?: StyleProp<ViewStyle>;
  contentAreaStyle?: StyleProp<ViewStyle>;
  scrollViewRef?: React.RefObject<AppScrollViewRef>;
  children: React.ReactNode;
};

export const Layout = ({
  title,
  description,
  navigation,
  mobileContentAreaStyle,
  contentAreaStyle,
  children,
  scrollViewRef,
}: LayoutProps) => {
  const { isMobile } = useDeviceInfoContext();
  const isFocused = useIsFocused();
  const route = useRoute();

  const isXl = useIsBreakpoint('xl');

  const isProfilePage = route.name === Routes.PROFILE;
  const showContactCard = !isXl && !isProfilePage;
  const profileLayoutColor = isProfilePage ? defaultTheme.colors.gray4 : '';

  const styles = useStyles(isProfilePage);

  const showRightFastForwardCardStyles = useShowElementOn('xxl');
  const showContentFastForwardCardStyles = useShowElementOn(['xl', 'lg', 'md', 'sm']);

  // TODO: this component renders 3 - 4 times according to the screen.

  return (
    <SafeViewWrapper>
      {isProfilePage ? <MedicineCenterHeader navigation={navigation} /> : <Header navigation={navigation} />}
      <AppScrollView ref={scrollViewRef} withRightMargin>
        {isFocused ? (
          <TransitionWrapper>
            <ContentArea contentAreaColor={profileLayoutColor}>
              <ContentContainer>
                <PageInformationWrapper style={[contentAreaStyle, isMobile && mobileContentAreaStyle]}>
                  <PageTitle isProfilePage={isProfilePage}>{title}</PageTitle>
                  <PageDescription isProfilePage={isProfilePage}>{description}</PageDescription>
                </PageInformationWrapper>
                <View style={styles.contentWrapper}>
                  <FastForwardCard styles={showContentFastForwardCardStyles} />
                  <LeftSideContentWrapper style={styles.leftSide} size={3}>
                    {children}
                  </LeftSideContentWrapper>
                  <RightSideContentWrapper style={styles.rightSide}>
                    <FastForwardCard styles={showRightFastForwardCardStyles} />
                    {showContactCard && (
                      <WebTutorialWrapper order={WEB_STEPS_ORDER.CONTACT_CARD}>
                        <ContactCard />
                      </WebTutorialWrapper>
                    )}
                    {!isNativeDevice && (
                      <WebTutorialWrapper order={WEB_STEPS_ORDER.DOWNLOAD_CARD} containerStyle={styles.downloadCard}>
                        <DownloadCard />
                      </WebTutorialWrapper>
                    )}
                  </RightSideContentWrapper>
                </View>
              </ContentContainer>
            </ContentArea>
            <Footer />
          </TransitionWrapper>
        ) : null}
      </AppScrollView>
      <SessionExpiredModal />
    </SafeViewWrapper>
  );
};

export default Layout;
