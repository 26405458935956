import { BenefitInvestigationsData } from 'api/Benefits/types';

import { ExplanationModalType } from './ExplanationModal';

export const getExplanationModalType = (benefitInvestigations: BenefitInvestigationsData): ExplanationModalType => {
  if (benefitInvestigations.copay) return 'copay';
  if (benefitInvestigations.coinsurance) return 'coinsurance';

  return 'empty';
};
