import { StyleSheet } from 'react-native';

import { AppTheme } from 'store/actions/globalState/types';
import { defaultTheme } from 'style/theme';
import { isNativeDevice } from 'utils/helpers';

type GetThemeProps = {
  isMobile?: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  width: number;
  height: number;
  appThemeColors: AppTheme;
  isLoggedIn: boolean;
  userHasPsp: boolean;
};

export const getStyles = () =>
  StyleSheet.create({
    visible: {
      display: 'flex',
    },
    fallback: { flex: 1, justifyContent: 'center', alignItems: 'center' },
    tooltip: { overflow: 'visible', padding: 0, ...(isNativeDevice && { height: '100%' }) },
  });

export const getTheme = ({
  isMobile,
  isTablet,
  isDesktop,
  width,
  height,
  appThemeColors,
  isLoggedIn,
  userHasPsp,
}: GetThemeProps) => ({
  ...defaultTheme,
  isMobile,
  isTablet,
  isDesktop,
  width,
  height,
  colors: {
    ...defaultTheme.colors,
    ...appThemeColors,
    ...(isLoggedIn &&
      !userHasPsp && {
        primary: defaultTheme.colors.mangoPrimary,
        secondary: defaultTheme.colors.mangoSecondary,
      }),
  },
});
