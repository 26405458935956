import * as ActionTypes from 'store/action-types';

export type SetShouldShowSurvey = (payload: boolean) => {
  type: typeof ActionTypes.SET_SHOULD_SHOW_SURVEY;
  payload: boolean;
};

export const setShouldShowSurvey: SetShouldShowSurvey = (payload) => ({
  type: ActionTypes.SET_SHOULD_SHOW_SURVEY,
  payload,
});
