import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const getStyles = (isMobile?: boolean) =>
  StyleSheet.create({
    checkboxWrapper: isMobile
      ? { flexDirection: 'row', marginTop: 10 }
      : { flexDirection: 'row', marginTop: 20, alignItems: 'center' },
    answerText: { ...defaultTheme.fontSizes[16], color: defaultTheme.colors.gray1, marginLeft: 8 },
  });
