import React, { lazy, Suspense, useEffect, useRef } from 'react';

import {
  DefaultTheme as NativeDefaultTheme,
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import { StatusBar, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { trackRouteChanges } from 'analytics/global_handlers';
import { ConsentModal } from 'components/ConsentModals';
import { Drawer } from 'components/Drawer';
import { FullScreenLoader } from 'components/Loaders';
import { Routes } from 'routes/main';
import { SERVICE_INFO_GROUPS } from 'screens/serviceInfo/navigationGroup';
import { removeCurrentlyActiveConsent } from 'store/actions';
import { getUserAuthorization, getConsentModalsQueue } from 'store/selectors';
import { DefaultText } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

import { screenTitles } from '../Drawer/useGetAppScreens';
import { linking } from './linking';
import { getStyles } from './styles';
import { AppStackParamList, AuthStackParamList } from './types';

const Stack = createStackNavigator<AuthStackParamList & AppStackParamList>();

const LazyLogin = lazy(() => import('screens/auth/Login'));
const LazySetPassword = lazy(() => import('screens/auth/SetPassword'));
const LazyTwoFactorAuthentication = lazy(() => import('screens/auth/TwoFactorAuthentication'));

const screenOptions: StackNavigationOptions = {
  headerShown: false,
  animationEnabled: isNativeDevice,
};

const currentTheme = {
  ...NativeDefaultTheme,
  colors: {
    ...NativeDefaultTheme.colors,
    background: 'transparent',
  },
};

const Fallback = () => {
  const styles = getStyles();
  return (
    <View style={styles.fallback}>
      <DefaultText>Loading...</DefaultText>
    </View>
  );
};

export type PatientLinkNavigationProps = { appIsLoading: boolean };

export const MangoNavigation = ({ appIsLoading }: PatientLinkNavigationProps) => {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef<string>();
  const { colors } = useTheme();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getUserAuthorization);
  const consentModalsQueue = useSelector(getConsentModalsQueue);

  useEffect(() => {
    if (!consentModalsQueue.length) {
      dispatch(removeCurrentlyActiveConsent());
    }
  }, [consentModalsQueue.length, dispatch]);

  const handleNavigationStateChange = async () => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef?.getCurrentRoute?.()?.name;

    if (currentRouteName && previousRouteName !== currentRouteName) {
      trackRouteChanges(currentRouteName);
    }
    // Save the current route name for later comparison
    routeNameRef.current = currentRouteName;
  };

  const currentStack = isLoggedIn ? (
    <Stack.Screen
      name={Routes.APP_NAVIGATOR}
      component={Drawer}
      options={{ title: screenTitles[Routes.APP_NAVIGATOR] }}
    />
  ) : (
    <Stack.Group>
      <Stack.Screen name={Routes.LOGIN} component={LazyLogin} options={{ title: screenTitles[Routes.LOGIN] }} />
      <Stack.Screen
        name={Routes.SET_PASSWORD}
        component={LazySetPassword}
        options={{ title: screenTitles[Routes.SET_PASSWORD] }}
      />
      <Stack.Screen
        name={Routes.TWO_FACTOR_AUTHENTICATION}
        component={LazyTwoFactorAuthentication}
        options={{ title: screenTitles[Routes.TWO_FACTOR_AUTHENTICATION] }}
      />
    </Stack.Group>
  );

  if (appIsLoading) return <FullScreenLoader />;

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <NavigationContainer
        ref={navigationRef}
        onReady={() => {
          routeNameRef.current = navigationRef?.getCurrentRoute?.()?.name;
        }}
        onStateChange={handleNavigationStateChange}
        linking={linking}
        theme={currentTheme}
        fallback={<Fallback />}>
        <StatusBar
          animated={true}
          barStyle={'dark-content'}
          backgroundColor={isLoggedIn ? 'transparent' : colors.secondary}
        />
        <Stack.Navigator
          screenOptions={screenOptions}
          initialRouteName={isLoggedIn ? Routes.APP_NAVIGATOR : Routes.LOGIN}>
          {currentStack}
          {SERVICE_INFO_GROUPS}
        </Stack.Navigator>
        {isLoggedIn && !!consentModalsQueue?.length && <ConsentModal />}
      </NavigationContainer>
    </Suspense>
  );
};

export default MangoNavigation;
