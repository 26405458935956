import { parse, isBefore } from 'date-fns';

import { gaHandler } from 'analytics/global_handlers';
import { EventCategories } from 'analytics/types';
import { currentDateRoundingDownward, extractTimeFromDate, replaceTimeInDate } from 'utils/dateTimeHelpers';

export const reminderModalAnalyticsHandler = gaHandler(EventCategories.TRACK_MEDICATION_DETAILS);

export const parseStartDate = (start_date: string) => {
  if (!start_date) return new Date();
  const isOldFormat = start_date.includes('/');
  if (isOldFormat) return parse(start_date, 'mm/dd/yyyy', new Date());
  return parse(start_date, 'dd-MM-yyyy', new Date());
};

export const validatePastTimeForReminder = (date: Date, reminderTime: string | Date) => {
  const time = typeof reminderTime === 'string' ? reminderTime : extractTimeFromDate(reminderTime);

  return isBefore(replaceTimeInDate(time, date), currentDateRoundingDownward(60));
};
