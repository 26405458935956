import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const Menu = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M1 8h14M1 3h14M1 13h14'
      stroke={props.stroke || '#000'}
      strokeWidth={1}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Menu;
