import uuid from 'react-native-uuid';

import { InsuranceTypeEnum, UserTypeEnum } from 'enums';

import { User } from './types';

export const initialMedicalInsurance = {
  _id: uuid.v4() as string, // internal id to distinguish insurances when there is no id assigned
  type: InsuranceTypeEnum.MEDICAL,
  bin: '',
  pcn: '',
  memberId: '',
};

export const initialPharmacyInsurance = {
  _id: uuid.v4() as string,
  type: InsuranceTypeEnum.PHARMACY,
  bin: '',
  pcn: '',
  memberId: '',
};

export const initialConsentModalsState = {
  app: { entities: { consents: {} }, result: [] },
  program: { entities: { consents: {} }, result: [] },
  service: { entities: { consents: {} }, result: [] },
};

export const initialSurveysState = { entities: { questions: {}, surveys: {} }, result: [] };

export const INITIAL_STATE: User = {
  authorized: false,
  hasPsp: false,
  userFirstName: '',
  userLastName: '',
  userPreferredName: '',
  contactPersonName: '',
  email: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  userType: UserTypeEnum.PATIENT,
  password: '',
  token: '',
  cognitoId: '',
  doubleOpt: false,
  shouldProposeTutorial: false,
  isForgotPasswordModalVisible: false,
  isSessionExpiredModalVisible: false,
  isActivateAccountModalVisible: false,
  shouldShowSurvey: false,
  shouldForceSurveyOpen: false,
  isSurveyCompleted: false,
  isForwardedSurveyExpired: false,
  isDemo: false,
  showWelcomeCard: false,
  isCopayUser: false,
  insurances: [initialPharmacyInsurance, initialMedicalInsurance],
  hasEmailPreference: false,
  hasCellPhonePreference: false,
  hasMailPreference: false,
  hasPushNotificationPreference: false,
  isWebDrawerOpen: false,
  programs: [],
  copayCards: [],
  surveys: initialSurveysState,
  showEventNotification: true,
  selectedProgramIsDefault: true,
  consentModalsQueue: [],
  notificationTiles: [],
  simpleTextNotificationTiles: [],
  consentModalsState: initialConsentModalsState,
  financialAssistanceData: undefined,
};
