import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Syrup = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 56.86 117.14' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M28.58 57.6c6.75-.73 13.5.34 20.27.91 4.21.36 6.45 2.06 6.18 7-.66 11.93-.72 23.88-1.11 35.83-.17 5.33-3.92 8.7-11.32 9.91a74.74 74.74 0 0 1-26.22.19C6.16 109.49 2.8 106 3.06 95.75s-.61-20.24-1.18-30.34C1.6 60.43 4 58.9 8.14 58.5c6.77-.64 13.53-1.56 20.44-.9Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M38.82 22.19c-1.32-1.54-1.34-4.19-1.15-5.72 3.25-.19 3.44-1.72 3.44-1.72V2.3C38.65.45 31.63.09 29 0h-1c-2.77.09-9.79.45-12.26 2.3v12.45s.2 1.53 3.45 1.72c.19 1.53.16 4.18-1.15 5.72s-18 17-18 29 1 54.21 1 54.21 1.54 10.34 19.79 11.66c.19.09 5.9 0 7.65 0s7.46.08 7.64 0c18.26-1.32 19.8-11.66 19.8-11.66s1-42.23 1-54.21-16.78-27.46-18.1-29Z'
          fillOpacity={0.4}
          fill='#e2e2e2'
        />
        <Path
          d='M19.19 16.47s2.44.8 9.24.8 9.24-.8 9.24-.8c3.25-.19 3.44-1.72 3.44-1.72V2.3C38.65.45 31.63.09 29 0h-1c-2.77.09-9.79.45-12.26 2.3v12.45s.2 1.53 3.45 1.72Z'
          fill='#ddd'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M28.43 5.11C39.36 5.11 41.11 3 41.11 3v-.7C38.65.45 31.63.09 29 0h-1c-2.77.09-9.79.45-12.26 2.3V3s1.75 2.11 12.69 2.11Z'
        />
        <Path fillOpacity={0.1} d='M19.48 4.41C16.42 3.78 15.74 3 15.74 3v11.75s.2 1.53 3.45 1.72l.29.08Z' />
        <Path
          d='M.66 90.64S5.5 96.07 29 96.07s27.91-5.43 27.91-5.43v-25s-3.79 5.3-27.91 5.3-29-5.33-29-5.33Z'
          fill='#e2e2e2'
        />
        <Path
          fillOpacity={0.1}
          d='m6.44 53.94-1.19-.33a2.48 2.48 0 0 0-3.17 2.44L3 103.83s1.65 7.1 8.74 6.34V57.43A2.49 2.49 0 0 0 10 55.05Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M21.11 25s9.69.88 14.87-1.83a.52.52 0 0 1 .58.06c1 .82 4.35 3.56 4.58 7.47a.53.53 0 0 1-.86.44c-1.84-1.5-7.05-4.62-19.17-6.14ZM28.55 64.24c18.84 0 25.61-3.24 25.62-3.24-1-1.58-2.8-2.28-5.32-2.5-6.77-.57-13.52-1.64-20.27-.91-6.91-.66-13.67.26-20.44.9-2.52.24-4.39.91-5.41 2.51 0 0 6.96 3.24 25.82 3.24Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={42.17} cy={33.09} r={0.91} />
      </G>
    </G>
  </Svg>
);

export default Syrup;
