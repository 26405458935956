import React from 'react';

import { View } from 'react-native';
import { useTheme } from 'styled-components/native';

import { getStyles, StylesProps } from './styles';
export type LineProps = StylesProps;

export const Line = ({ color, marginTop, marginBottom, marginVertical }: LineProps) => {
  const theme = useTheme();
  const styles = getStyles({ color: color || theme?.colors?.gray3, marginTop, marginBottom, marginVertical });
  return <View style={styles.main} />;
};

export default Line;
