import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { webStyles } from 'style/common';
import { useIsBreakpoint } from 'style/hooks';
import { COPAY_CARD_MIN_HEIGHT } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';
import { isAndroidDevice } from 'utils/helpers';

export const useFAOverviewStyles = ({
  copayCardWidth,
  overviewWithoutImage,
  currentCopayCardsLength = 0,
}: {
  copayCardWidth: number;
  overviewWithoutImage: boolean;
  currentCopayCardsLength: number;
}) => {
  const { colors, fontSizes, fontFamilies, width = 0 } = useTheme();

  const isSm = useIsBreakpoint('sm');
  const isOneCopayCardOnMobile = currentCopayCardsLength === 1 && isSm;

  return StyleSheet.create({
    copayCard: {
      flex: 1,
      ...(!isAndroidDevice && { flexDirection: 'row' }),
      height: copayCardWidth * 0.6345,
      ...(isOneCopayCardOnMobile && { maxHeight: (width - 64) * 0.6345 }),
      borderRadius: 22,
    },
    deckWrapper: {
      borderRadius: 22,
    },
    copayImageWrapper: {
      flex: 1,
      borderRadius: 22,
      // 0,6345 value the aspect ratio of the image.
      ...applyBreakpoints(
        { width },
        {
          sm: { height: (width - 64) * 0.6345 },
          md: {
            height: (copayCardWidth - 64) * 0.6345,
            width: '100%',
          },
        }
      ),
      ...webStyles.button,
    },
    styledImage: {
      ...(!isAndroidDevice && { borderRadius: 22 }),
      backgroundColor: colors.white,
    },
    copayOverviewContentWrapper: {
      flex: 1,
      minHeight: 'auto',
      ...applyBreakpoints(
        { width },
        {
          md: {
            padding: 4,
            flexDirection: 'column',
          },
          xxl: {
            flexDirection: 'row',
            maxHeight: copayCardWidth * 0.6345,
          },
        }
      ),
    },
    contentWrapper: {
      ...applyBreakpoints(
        { width },
        {
          md: { flexDirection: 'column' },
          xxl: {
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        }
      ),
    },
    cardContainer: {
      ...applyBreakpoints(
        { width },
        {
          md: { width: '100%' },
          xxl: { width: overviewWithoutImage ? '100%' : '48%' },
        }
      ),
    },
    logoWrapper: {
      height: COPAY_CARD_MIN_HEIGHT,
    },
    logo: {
      width: '100%',
      height: '100%',
      borderRadius: 16,
    },
    textBoxWrapper: {
      justifyContent: 'center',
      paddingVertical: 38,
      paddingHorizontal: 32,
      ...applyBreakpoints(
        { width },
        {
          md: { minHeight: overviewWithoutImage ? 'auto' : COPAY_CARD_MIN_HEIGHT },
          xxl: { height: overviewWithoutImage ? 'auto' : COPAY_CARD_MIN_HEIGHT },
        }
      ),
    },
    header: {
      ...fontSizes[16],
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
    },
    aboutProgramText: {
      ...fontSizes[14],
      marginTop: 24,
      color: colors.gray1,
    },
    description: {
      ...fontSizes[14],
      marginTop: 8,
      color: colors.gray2,
    },
  });
};
