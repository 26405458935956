import React, { useEffect, useState } from 'react';

import { useRoute, RouteProp, useIsFocused } from '@react-navigation/native';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { MedicineCabinetStackParamList } from '@types';
import { getAnalyticEventLabel } from 'analytics/helpers';
import { MedicineCabinetInterface } from 'api';
import medDetailsLayout from 'assets/images/med_details_layout.png';
import { BackButton } from 'components';
import { Routes } from 'routes/main';
import { setSelectedMedicationId, setSelectedTab, setDrugInteractions, setSelectedInteractionId } from 'store/actions';
import {
  getMedicationRemindersById,
  getSelectedInteraction,
  getSelectedMedication,
  getUserHasPsp,
} from 'store/selectors';
import { HeaderText, StandardText } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

import { SeverityStatus } from '../../components/MedicationWizard/Steps/components/InteractionItem';
import { MedicineCabinetLayout } from '../../layout';
import { CabinetScreenProps } from '../../types';
import { TabTypesEnum } from '../MedicationDetails/components/SubsectionsTabs/types';
import { medicationDetailAnalyticsActions } from '../MedicationDetails/constants';
import { medicationDetailsAnalyticsHandler } from '../MedicationDetails/helpers';
import { getInteractionDetailStyles } from './styles';

type InteractionDetailsRouteProps = RouteProp<MedicineCabinetStackParamList, Routes.INTERACTION_DETAILS>;

const { VIEW_DETAILS_OF_THE_INTERACTION } = medicationDetailAnalyticsActions;

export const InteractionDetails = ({ navigation }: CabinetScreenProps) => {
  const { isMobile, ...theme } = useTheme();
  const route = useRoute<InteractionDetailsRouteProps>();
  const [loading, setLoading] = useState(true);
  const userHasPsp = useSelector(getUserHasPsp);
  const dispatch = useDispatch();
  const styles = getInteractionDetailStyles(theme, isMobile);
  const selectedInteraction = useSelector(getSelectedInteraction);
  const selectedMedication = useSelector(getSelectedMedication);
  const { medicationId, interactionId, drugId } = route.params;
  const medicationReminders = useSelector(getMedicationRemindersById(Number(medicationId)));
  const isFocused = useIsFocused();

  const reminderId = (medicationReminders?.length && medicationReminders[0].id) || null;

  useEffect(() => {
    if (isFocused) {
      medicationDetailsAnalyticsHandler(VIEW_DETAILS_OF_THE_INTERACTION, getAnalyticEventLabel());
    }
  }, [isFocused]);

  useEffect(() => {
    const initiliazeDetailsScreen = async () => {
      if (medicationId) dispatch(setSelectedMedicationId(Number(medicationId)));
      if (drugId && !selectedInteraction) {
        const interactionsResponse = await MedicineCabinetInterface.checkDrugInteractions(Number(drugId));
        dispatch(setDrugInteractions(interactionsResponse?.data?.interactions));
        dispatch(setSelectedInteractionId(Number(interactionId)));
        setLoading(false);
      }
    };

    initiliazeDetailsScreen();
  }, [dispatch, drugId, interactionId, medicationId, selectedInteraction]);

  const handleNavigateToDetailsScreen = () => {
    const mainRoute = userHasPsp ? Routes.MEDICINE_CABINET : Routes.HOME;
    dispatch(setSelectedTab(TabTypesEnum.DRUG_INTERACTIONS));
    if (navigation.canGoBack()) {
      if (!isNativeDevice) {
        window.history.back();
        return;
      }
      navigation.goBack();
      return;
    }
    navigation.navigate(mainRoute, {
      screen: Routes.DETAILS,
      params: { medicationId, ...(reminderId && { reminderId }) },
    });
  };

  const interactionDetailsHeaderJSX = (
    <View style={styles.wrapper}>
      <View style={styles.backButtonActionsWrapper}>
        <BackButton
          style={styles.backButtonStyles}
          color={theme.colors.gray2}
          navigation={navigation}
          onPress={handleNavigateToDetailsScreen}
        />
      </View>
      <View style={styles.headerWrapper}>
        <HeaderText style={styles.headerText}>Interaction</HeaderText>
        <SeverityStatus severity={selectedInteraction?.information?.severity || 1} />
      </View>
    </View>
  );

  const fallBackText = loading ? 'Loading...' : 'No Data';

  const getTheTitleOfInteraction = () => {
    const interactedDrugName = selectedInteraction?.interacted_with?.trade_name || fallBackText;
    const medicationName = selectedMedication?.name || fallBackText;
    return `${medicationName} and ${interactedDrugName}`;
  };

  const detailsInfo = selectedInteraction?.information.body || fallBackText;

  return (
    <MedicineCabinetLayout
      navigation={navigation}
      imageUrl={medDetailsLayout}
      subSectionJSX={interactionDetailsHeaderJSX}
      contentAreaStyle={styles.layoutContentAreaStyle}>
      <View>
        <StandardText style={styles.interactionName}>{getTheTitleOfInteraction()}</StandardText>
        <StandardText>{detailsInfo}</StandardText>
      </View>
    </MedicineCabinetLayout>
  );
};

export default InteractionDetails;
