import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

type BellIconProps = {
  d?: string;
  size?: 'small' | 'medium';
};

const dSizes: Record<Required<BellIconProps>['size'], string> = {
  small:
    'M12.707 5.582a4.54 4.54 0 0 0-1.367-3.24A4.71 4.71 0 0 0 8.04 1a4.71 4.71 0 0 0-3.3 1.342 4.54 4.54 0 0 0-1.367 3.24c0 5.345-2.333 6.872-2.333 6.872h14s-2.333-1.527-2.333-6.872ZM9.313 14.364a1.39 1.39 0 0 1-.539.465c-.223.112-.476.171-.734.171s-.511-.059-.734-.17a1.39 1.39 0 0 1-.539-.466',
  medium:
    'M19.333 8.2c0-1.91-.772-3.74-2.147-5.091A7.403 7.403 0 0012 1c-1.945 0-3.81.759-5.185 2.109A7.134 7.134 0 004.667 8.2C4.667 16.6 1 19 1 19h22s-3.667-2.4-3.667-10.8zM14 22a2.186 2.186 0 01-.846.732A2.588 2.588 0 0112 23c-.405 0-.803-.092-1.154-.268A2.186 2.186 0 0110 22',
};
const sizes: Record<Required<BellIconProps>['size'], number> = {
  small: 16,
  medium: 24,
};

const Bell = ({ size = 'medium', ...props }: BellIconProps & SvgProps) => {
  return (
    <Svg width={sizes[size]} height={sizes[size]} fill='none' {...props}>
      <Path
        d={props.d || dSizes[size]}
        stroke={props.stroke || defaultTheme.colors.gray1}
        strokeWidth={props.strokeWidth || 2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default Bell;
