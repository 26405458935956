import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addToConsentModalsQueue, removeCurrentlyActiveConsent, removeCurrentlyActiveService } from 'store/actions';
import { CustomServiceTypeEnum } from 'store/reducers/additional-services/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import { getCurrentlyActiveService, getServiceConsentsModalState } from 'store/selectors';

export type ServicesProps = {
  initialActionModalState: boolean;
  initialDigitalResourceModalState: boolean;
};

export const useServiceConsentsQueue = ({
  initialActionModalState,
  initialDigitalResourceModalState,
}: ServicesProps) => {
  const [digitalResourceModalIsVisible, setDigitalResourceModalVisible] = useState(initialDigitalResourceModalState);
  const [actionModalIsVisible, setActionModalVisible] = useState(initialActionModalState);
  const [requestModalIsVisible, setRequestModalVisible] = useState(false);
  const serviceConsentsModalState = useSelector(getServiceConsentsModalState);
  const currentlyActiveService = useSelector(getCurrentlyActiveService);
  const dispatch = useDispatch();

  useEffect(() => {
    setActionModalVisible(initialActionModalState);
    setDigitalResourceModalVisible(initialDigitalResourceModalState);
  }, [initialActionModalState, initialDigitalResourceModalState]);

  useEffect(() => {
    if (currentlyActiveService && currentlyActiveService.service.consentConfig.length) {
      for (const consent of currentlyActiveService.service.consentConfig) {
        if (
          serviceConsentsModalState.entities.consents[consent.id].customServiceType ===
          currentlyActiveService.customServiceType
        ) {
          dispatch(
            addToConsentModalsQueue({
              id: consent.id,
              groupType: ConsentModalTypeEnum.service,
              customServiceType: currentlyActiveService.customServiceType,
              priority: 1,
            })
          );
        }
      }
    }
  }, [currentlyActiveService, serviceConsentsModalState, dispatch]);

  useEffect(() => {
    if (currentlyActiveService && currentlyActiveService.service.consentConfig.length) {
      for (const consent of currentlyActiveService.service.consentConfig) {
        const currentConsent = serviceConsentsModalState.entities.consents[consent.id];
        if (
          (currentConsent.customServiceType === currentlyActiveService.customServiceType &&
            currentConsent.isViewed &&
            currentConsent.isGiven) ||
          !currentConsent.required_to_proceed
        ) {
          removeCurrentlyActiveConsent();
          if (currentlyActiveService.customServiceType === CustomServiceTypeEnum.actions) {
            setActionModalVisible(true);
          }
          if (currentlyActiveService.customServiceType === CustomServiceTypeEnum.digitalResources) {
            setDigitalResourceModalVisible(true);
          }
          if (currentlyActiveService.customServiceType === CustomServiceTypeEnum.requests) {
            setRequestModalVisible(true);
          }
        }
      }
    }

    if (currentlyActiveService && !currentlyActiveService.service.consentConfig.length) {
      removeCurrentlyActiveConsent();
      if (currentlyActiveService.customServiceType === CustomServiceTypeEnum.actions) {
        setActionModalVisible(true);
      }
      if (currentlyActiveService.customServiceType === CustomServiceTypeEnum.digitalResources) {
        setDigitalResourceModalVisible(true);
      }
      if (currentlyActiveService.customServiceType === CustomServiceTypeEnum.requests) {
        setRequestModalVisible(true);
      }
    }
    return () => {
      if (!currentlyActiveService) return;

      dispatch(removeCurrentlyActiveService());
    };
  }, [dispatch, currentlyActiveService, serviceConsentsModalState]);

  return {
    actionModalIsVisible,
    digitalResourceModalIsVisible,
    requestModalIsVisible,
    ...(currentlyActiveService?.customServiceType === CustomServiceTypeEnum.actions && {
      selectedServiceAction: currentlyActiveService.service,
    }),
    ...(currentlyActiveService?.customServiceType === CustomServiceTypeEnum.digitalResources && {
      selectedServiceDigitalResource: currentlyActiveService.service,
    }),
    ...(currentlyActiveService?.customServiceType === CustomServiceTypeEnum.requests && {
      selectedServiceRequest: currentlyActiveService.service,
    }),
    setActionModalVisible,
    setDigitalResourceModalVisible,
    setRequestModalVisible,
  };
};

export default useServiceConsentsQueue;
