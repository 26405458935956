export type AnalyticsProps = {
  event_label?: string | number | undefined;
  [key: string]: any;
};

export enum EventCategories {
  TRACK_AUTH = 'TRACK_AUTH',
  TRACK_HOME = 'TRACK_HOME',
  TRACK_PROFILE = 'TRACK_PROFILE',
  TRACK_FINANCIAL_ASSISTANCE = 'TRACK_FINANCIAL_ASSISTANCE',
  TRACK_ADDITIONAL_SERVICES = 'TRACK_ADDITIONAL_SERVICES',
  TRACK_MEDICATION_CABINET = 'TRACK_MEDICATION_CABINET',
  TRACK_MEDICATION_DETAILS = 'TRACK_MEDICATION_DETAILS',
  TRACK_MEDICATION_TRACKER = 'TRACK_MEDICATION_TRACKER',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
}
