import { BenefitMilestonesReturnData, BenefitInvestigationsReturnData } from 'api/Benefits/types';
import apiRequest, { apiPrefix, ProgramRequestConfig } from 'utils/network';

const MILESTONES_TRACKING_URL = '/milestones/tracking';
const BENEFITS_INVESTIGATIONS_URL = '/benefits-investigations';

const requestConfig: ProgramRequestConfig = {
  withProxy: true,
};

export const BenefitsServicesInterface = {
  getBenefitTrackers: () =>
    apiRequest<BenefitMilestonesReturnData>({
      method: 'GET',
      endpoint: MILESTONES_TRACKING_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: requestConfig,
    }).then((res) => res.data),
  getBenefitInvestigations: () =>
    apiRequest<BenefitInvestigationsReturnData>({
      method: 'GET',
      endpoint: BENEFITS_INVESTIGATIONS_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: requestConfig,
    }).then((res) => res.data),
  closeTrackerCard: (trackerId: string) =>
    apiRequest({
      method: 'POST',
      endpoint: `${MILESTONES_TRACKING_URL}/${trackerId}/settings`,
      body: { is_hidden: true },
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: requestConfig,
    }),
};
