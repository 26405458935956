import * as Analytics from 'expo-firebase-analytics';

import { env } from 'config';
import { reduxStore } from 'store';
import { isDev, isNativeDevice } from 'utils/helpers';

import { AnalyticsProps } from './types';

const { apiURL, firebaseMeasurementId } = env;

if (isNativeDevice && isDev) {
  Analytics.setDebugModeEnabled(true);
}

export const trackRouteChanges = (pathname: string) => {
  if (isNativeDevice) {
    Analytics.logEvent('screen_view', { screen_name: pathname, customHostName: apiURL });
    return;
  }
  // Web, universal google analytics
  gtag('set', 'page_path', pathname);
  gtag('event', 'page_view', { send_to: firebaseMeasurementId, screen_name: pathname });
};

export const getEventProperties = () => {
  const { cognitoId: userId, programs, selectedProgramId } = reduxStore.getState().userInfo;
  const { uuid: programId, manufacturerId } = programs.find(({ id }) => id === selectedProgramId) || {};
  return {
    userId,
    programId,
    manufacturerId,
  };
};

export const gaHandler = (event_category: string) => (eventName: string, props?: AnalyticsProps) => {
  const eventProperties = getEventProperties();
  if (isNativeDevice) {
    Analytics.logEvent(eventName, {
      sender: event_category,
      event_label: Date.now(),
      customHostName: apiURL,
      ...eventProperties,
      ...props,
    });
    return;
  }
  setTimeout(
    () => gtag('event', eventName, { event_category, event_label: Date.now(), ...eventProperties, ...props }),
    0
  );
};
