import React, { useState, useEffect } from 'react';

import { InfoBlockLabel, InformationText } from 'style/common';

import { WebSwitchContainer } from './styles';
import { ComPrefInformationFieldProps } from './types';

const CommunicationPreferencesField = ({
  label,
  children,
  isEditMode = false,
  onChange,
  value = false,
}: ComPrefInformationFieldProps) => {
  const [isActive, setIsActive] = useState(value);
  useEffect(() => setIsActive(value), [value]);

  const handleSwitchChange = () => {
    setIsActive(!isActive);
    onChange?.(!isActive);
  };

  return (
    <>
      <InfoBlockLabel>{label}</InfoBlockLabel>
      {isEditMode ? (
        <WebSwitchContainer>
          <label className='toggle-switch'>
            <input type='checkbox' role='switch' checked={isActive} onChange={handleSwitchChange} />
            <span className='switch' />
          </label>
        </WebSwitchContainer>
      ) : (
        <InformationText>{children}</InformationText>
      )}
    </>
  );
};

export default CommunicationPreferencesField;
