import React from 'react';

import {
  InstructionsWrapper,
  ParagraphDescription,
  ParagraphTitle,
} from 'screens/main/FinancialAssistance/CopayFinancialAssistance/components/CopayInstructions/styles';

export type CopayInstructionsProps = {
  patientInstructions?: string;
};

export const CopayInstructions = ({ patientInstructions }: CopayInstructionsProps) => (
  <InstructionsWrapper>
    <ParagraphTitle>Patient Instructions</ParagraphTitle>
    <ParagraphDescription>{patientInstructions || ''}</ParagraphDescription>
  </InstructionsWrapper>
);
