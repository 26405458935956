import AsyncStorage from '@react-native-async-storage/async-storage';
import { AnyAction, applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import thunk, { ThunkAction } from 'redux-thunk';

import { RESET_ROUTE_INFO, RESET_USER_DEMO_INFO, RESET_USER_INFO, RESET_USER_MFA_INFO } from 'store/action-types';
import { additionalServices, appGlobalState, homeInfoCards, medicineCabinet, tour, userInfo } from 'store/reducers';
import demoConfig from 'store/reducers/demoConfig';
import { DemoConfigState } from 'store/reducers/demoConfig/types';
import routeInfo from 'store/reducers/routeInfo';
import { RouteInfoState } from 'store/reducers/routeInfo/types';
import mfaConfig from 'store/reducers/user-info/subreducers/mfaConfig';
import { MFAConfigInfoState } from 'store/reducers/user-info/subreducers/mfaConfig/types';
import { User } from 'store/reducers/user-info/types';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const routeInfoPersistConfig: PersistConfig<RouteInfoState> = {
  key: 'routeInfo',
  storage: AsyncStorage,
};

const userInfoPersistConfig: PersistConfig<User> = {
  key: 'userInfo',
  storage: AsyncStorage,
  blacklist: [
    'doubleOpt',
    'consentModalsQueue',
    'consentModalsState',
    'financialAssistanceData',
    'isSessionExpiredModalVisible',
  ],
};

const mfaPersistConfig: PersistConfig<MFAConfigInfoState> = {
  key: 'mfaConfig',
  storage: AsyncStorage,
};

const demoPersistConfig: PersistConfig<DemoConfigState> = {
  key: 'demoConfig',
  storage: AsyncStorage,
};

const reducers = combineReducers({
  appGlobalState,
  routeInfo: persistReducer(routeInfoPersistConfig, routeInfo),
  userInfo: persistReducer(userInfoPersistConfig, userInfo),
  mfaConfig: persistReducer(mfaPersistConfig, mfaConfig),
  demoConfig: persistReducer(demoPersistConfig, demoConfig),
  homeInfoCards,
  additionalServices,
  tour,
  medicineCabinet,
});

const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    case RESET_ROUTE_INFO:
      AsyncStorage.removeItem('persist:routeInfo');
      return reducers({ ...state, routeInfo: undefined }, action);
    case RESET_USER_INFO:
      AsyncStorage.removeItem('persist:userInfo');
      return reducers({ ...state, userInfo: undefined }, action);
    case RESET_USER_MFA_INFO:
      AsyncStorage.removeItem('persist:mfaConfig');
      return reducers({ ...state, mfaConfig: undefined }, action);
    case RESET_USER_DEMO_INFO: {
      AsyncStorage.removeItem('persist:demoConfig');
      return reducers({ ...state, demoConfig: undefined }, action);
    }
    default:
      return reducers(state, action);
  }
};

const reduxStore = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const persistor = persistStore(reduxStore);
persistor.pause();

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
export type AppThunkAction<R> = ThunkAction<R, RootState, unknown, AnyAction>;

export { reduxStore, persistor };
