import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { closeButtonStyles } from 'style/common';
import { applyBreakpoints } from 'style/utils';

export const useStyles = () => {
  const { isMobile, colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: 26, // close button width
    },
    closeButton: {
      position: 'absolute',
      zIndex: 1,
      top: 18,
      right: 18,
      ...closeButtonStyles.button,
    },
    hoverButton: closeButtonStyles.onHover,
    clickButtonText: {
      color: colors.gray1,
      ...fontSizes[16],
    },
    header: {
      ...applyBreakpoints(theme, {
        lg: { marginRight: 8 },
        xxl: { width: '80%' },
      }),
      fontFamily: fontFamilies.bold,
      color: colors.secondary,
      ...fontSizes[16],
    },
    description: {
      paddingVertical: 10,
      color: colors.gray1,
      ...fontSizes[16],
    },
    clickButton: {
      color: colors.gray1,
      ...fontSizes[16],
      ...(isMobile && { marginVertical: 10 }),
      ...applyBreakpoints(theme, {
        lg: { marginRight: 12 },
        xxl: { marginRight: 24 },
      }),
    },
  });
};
