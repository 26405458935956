import React, { useEffect } from 'react';

import Slider from '@react-native-community/slider';
import { View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch } from 'react-redux';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { setSelectedAnswerForQuestion } from 'store/actions';
import { Question } from 'store/reducers/user-info/types/survey';
import { DefaultText } from 'style/common';
import { defaultTheme } from 'style/theme';

import { getStyles } from './styles';

type RangeOfAnswersProps = {
  surveyId?: string;
  currentQuestion?: Question;
  toastRef: React.MutableRefObject<ToastContainer | null>;
};

export const RangeOfAnswers = ({ surveyId, currentQuestion }: RangeOfAnswersProps) => {
  const { isMobile } = useDeviceInfoContext();
  const dispatch = useDispatch();

  const styles = getStyles(isMobile);

  const minimumValue = 0;
  const maximumValue = currentQuestion?.answers?.length ? currentQuestion.answers.length - 1 : 0;
  const step = 1;

  // Set first answer as default selected

  useEffect(() => {
    const questionId = currentQuestion?.uuid;
    const answerId = currentQuestion?.answers[0].uuid;

    if (!questionId || !answerId) return;
    dispatch(
      setSelectedAnswerForQuestion({
        questionId,
        answerId,
        isSelected: true,
        multi: false,
      })
    );
  }, [currentQuestion, dispatch]);

  if (!currentQuestion) return null;

  const valueChangeHandler = (value: number) => {
    dispatch(
      setSelectedAnswerForQuestion({
        questionId: currentQuestion.uuid,
        answerId: currentQuestion.answers[value].uuid,
        isSelected: true,
        multi: false,
      })
    );
  };

  return (
    <View style={styles.sliderWrapper}>
      <View style={styles.divisionsWrapper}>
        {currentQuestion?.answers?.map?.((answer) => (
          <View key={answer.uuid} style={styles.division}>
            <DefaultText>{answer.text}</DefaultText>
          </View>
        ))}
      </View>
      <Slider
        minimumValue={minimumValue}
        maximumValue={maximumValue}
        step={step}
        thumbTintColor={defaultTheme.colors.primary}
        minimumTrackTintColor={defaultTheme.colors.gray3}
        maximumTrackTintColor={defaultTheme.colors.gray3}
        style={styles.slider}
        onValueChange={valueChangeHandler}
      />
    </View>
  );
};

export default RangeOfAnswers;
