import * as ActionTypes from 'store/action-types';

export type SetSelectedSurveyId = (payload: string) => {
  type: typeof ActionTypes.SET_SELECTED_SURVEY_ID;
  payload: typeof payload;
};

export const setSelectedSurveyId: SetSelectedSurveyId = (payload) => ({
  type: ActionTypes.SET_SELECTED_SURVEY_ID,
  payload,
});
