import React, { useState } from 'react';

import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { Button, Card, Modal, Support } from 'components';
import { useContactCardStyles } from 'components/ContactCard/styles';
import { getSelectedProgram } from 'store/selectors';
import { webStyles, DefaultText } from 'style/common';

export const ContactCard = () => {
  const styles = useContactCardStyles();
  const program = useSelector(getSelectedProgram);

  const [modalIsVisible, setModalVisible] = useState(false);

  if (!program) return null;

  const openModalClickHandler = () => setModalVisible(true);

  const closeModalClickHandler = () => setModalVisible(false);

  return (
    <Card style={{ marginBottom: 0 }} padding={30}>
      <View style={{ alignItems: 'flex-start' }}>
        <DefaultText style={styles.baseText}>Need Help?</DefaultText>
        <DefaultText style={[styles.baseText, styles.contactText]}>{`Contact ${program.display_name}`}</DefaultText>
        <DefaultText numberOfLines={3} style={[styles.baseText, styles.workingSchedule]}>
          {program.supportHours}
        </DefaultText>
      </View>
      <Button
        variant='secondary'
        text='Contact'
        style={[webStyles.button, styles.contentButton]}
        onPress={openModalClickHandler}
      />
      <Modal modalIsVisible={modalIsVisible} setModalVisible={setModalVisible} onRequestClose={closeModalClickHandler}>
        <Support closeModal={closeModalClickHandler} />
      </Modal>
    </Card>
  );
};

export default ContactCard;
