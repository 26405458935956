import { SvgProps } from 'react-native-svg';

import {
  Bar,
  Capsule,
  Concentration,
  Cream,
  Dressing,
  Drop,
  Elixir,
  Enema,
  Film,
  Foam,
  GelCap,
  GelFormingSolutions,
  GelWithApplicator,
  Granule,
  Gum,
  Inhaler,
  Injection,
  Kit,
  Liquid,
  Lotion,
  Lozenge,
  Ointment,
  Pad,
  Paste,
  Patch,
  Powder,
  Powdering,
  Ring,
  Shampoo,
  Sponge,
  Spray,
  Stick,
  Strip,
  Suppository,
  Swab,
  Syrup,
  Tablet,
  Tape,
  Tinture,
  Wafer,
} from 'assets/icons/medicationsType';

import { MedTypeEnum } from './types';

export const medicationTypeIconMapping: { [key in string]: (props: SvgProps) => JSX.Element } = {
  [MedTypeEnum.BAR]: Bar,
  [MedTypeEnum.CAPSULE]: Capsule,
  [MedTypeEnum.CONCENTRATION]: Concentration,
  [MedTypeEnum.CREAM]: Cream,
  [MedTypeEnum.DRESSING]: Dressing,
  [MedTypeEnum.DROP]: Drop,
  [MedTypeEnum.ELIXIR]: Elixir,
  [MedTypeEnum.ENEMA]: Enema,
  [MedTypeEnum.FILM]: Film,
  [MedTypeEnum.FOAM]: Foam,
  [MedTypeEnum.GEL]: GelCap,
  [MedTypeEnum.GEL_FORMING_SOLUTION]: GelFormingSolutions,
  [MedTypeEnum.GEL_WITH_APPLICATOR]: GelWithApplicator,
  [MedTypeEnum.GRANULE]: Granule,
  [MedTypeEnum.GUM]: Gum,
  [MedTypeEnum.INHALER]: Inhaler,
  [MedTypeEnum.INJECTION]: Injection,
  [MedTypeEnum.KIT]: Kit,
  [MedTypeEnum.LIQUID]: Liquid,
  [MedTypeEnum.LOTION]: Lotion,
  [MedTypeEnum.LOZENGE]: Lozenge,
  [MedTypeEnum.OINTMENT]: Ointment,
  [MedTypeEnum.PAD]: Pad,
  [MedTypeEnum.PASTE]: Paste,
  [MedTypeEnum.PATCH]: Patch,
  [MedTypeEnum.POWDER]: Powder,
  [MedTypeEnum.POWDER_FOR_INJECTION]: Powdering,
  [MedTypeEnum.RING]: Ring,
  [MedTypeEnum.SHAMPOO]: Shampoo,
  [MedTypeEnum.SPONGE]: Sponge,
  [MedTypeEnum.SPRAY]: Spray,
  [MedTypeEnum.STICK]: Stick,
  [MedTypeEnum.STRIP]: Strip,
  [MedTypeEnum.SUPPOSITORY]: Suppository,
  [MedTypeEnum.SWAB]: Swab,
  [MedTypeEnum.SYRUP]: Syrup,
  [MedTypeEnum.TABLET]: Tablet,
  [MedTypeEnum.TAPE]: Tape,
  [MedTypeEnum.TINCTURE]: Tinture,
  [MedTypeEnum.WATER]: Wafer,
};
