import * as ActionTypes from 'store/action-types';
import { CustomServiceType } from 'store/reducers/additional-services/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

export type RemoveFromConsentModalsQueue = (payload: {
  id: string;
  groupType: ConsentModalTypeEnum;
  customServiceType?: CustomServiceType;
}) => {
  type: typeof ActionTypes.REMOVE_FROM_CONSENT_MODALS_QUEUE;
  payload: typeof payload;
};

export const removeFromConsentModalsQueue: RemoveFromConsentModalsQueue = (payload) => ({
  type: ActionTypes.REMOVE_FROM_CONSENT_MODALS_QUEUE,
  payload,
});
