import React from 'react';

import { Linking, View } from 'react-native';
import { useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { Button } from 'components/Button';
import { supportAnalyticsActions } from 'components/Support/constants';
import { supportAnalyticsHandler } from 'components/Support/helpers';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { getSelectedProgram } from 'store/selectors';
import { DefaultText, webStyles } from 'style/common';
import { addDashesForPhoneNumber } from 'utils/convertString';
import { isNativeDevice } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

import { useSupportStyles } from './styles';

const { VIEW_SUPPORT_CONTACT } = supportAnalyticsActions;

type SupportProps = { closeModal?: AnyFunction };

export const Support = ({ closeModal }: SupportProps) => {
  const styles = useSupportStyles();

  const program = useSelector(getSelectedProgram);

  useFirstEffect(() => {
    supportAnalyticsHandler(VIEW_SUPPORT_CONTACT, getAnalyticEventLabel());
  }, []);

  if (!program) return null;

  const actionButtonText = isNativeDevice ? 'Call' : 'Close';

  const callNumber = () => {
    const callNumberLink = `tel:${program.phoneNumber}`;

    Linking.openURL(callNumberLink);

    closeModal?.();
  };

  return (
    <View style={styles.wrapper}>
      <View>
        <DefaultText style={styles.title}>{program.contactPopUpTitle}</DefaultText>
        <DefaultText style={styles.description}>{program.contactPopUpDescription}</DefaultText>
        <DefaultText style={styles.number}>{addDashesForPhoneNumber(program.phoneNumber)}</DefaultText>
      </View>
      <Button
        variant='secondary'
        text={actionButtonText}
        style={[styles.submitButton, webStyles.button]}
        onPress={isNativeDevice ? callNumber : closeModal}
      />
    </View>
  );
};

export default Support;
