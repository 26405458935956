import React, { Ref } from 'react';

import { KeyboardTypeOptions, TextInputProps, View } from 'react-native';

import InputLabel from 'components/InputLabel';
import { DefaultText } from 'style/common';
import { capitalize } from 'utils/convertString';

import { StyledPostfix, StyledPrefix } from './styles';
import { MangoTextInput, MangoTextInputProps, MangoTextInputRef } from './TextInput';

export type TextFieldProps = {
  labelText: string;
  showInfo?: boolean;
  keyboardType?: KeyboardTypeOptions;
  prefix?: string;
  postfix?: string;
  height?: number;
  fieldText?: string | number;
  editField?: boolean;
  renderLabel?: boolean;
  inputRef?: Ref<MangoTextInputRef>;
};

export const TextFieldWithLabel = ({
  labelText,
  showInfo = false,
  keyboardType,
  prefix = '',
  postfix,
  height,
  fieldText,
  editField = true,
  editable = true,
  renderLabel = true,
  inputRef,
  ...rest
}: TextFieldProps & TextInputProps & Omit<MangoTextInputProps, 'postfix'>) => {
  const prefixJSX = !!prefix && (
    <StyledPrefix>
      <DefaultText numberOfLines={1} style={{ fontSize: 16 }}>
        {capitalize(prefix)}
      </DefaultText>
    </StyledPrefix>
  );

  const postfixJSX = !!postfix && (
    <StyledPostfix>
      <DefaultText numberOfLines={1} style={{ fontSize: 16 }}>
        {capitalize(postfix)}
      </DefaultText>
    </StyledPostfix>
  );

  return (
    <>
      {renderLabel && <InputLabel labelText={labelText} showInfo={showInfo} />}
      {editField ? (
        <View>
          {prefixJSX}
          <MangoTextInput
            numberOfLines={1}
            postfix={!!postfix}
            autoCorrect={false}
            autoCapitalize='none'
            style={{ paddingLeft: prefix ? 54 : 16 }}
            keyboardType={keyboardType || 'default'}
            disabled={!editable}
            editable={editable}
            ref={inputRef}
            {...rest}
          />
          {postfixJSX}
        </View>
      ) : (
        <View>
          {!!fieldText && (
            <DefaultText numberOfLines={1} style={{ fontSize: 16 }}>
              {`${prefix} ${fieldText}`}
            </DefaultText>
          )}
        </View>
      )}
    </>
  );
};

export default TextFieldWithLabel;
