import produce from 'immer';

import { SetSurveyProgress } from 'store/actions/user/actionCreators/surveys/setSurveyProgress';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setSurveyProgress = (state = INITIAL_STATE, payload: ReturnType<SetSurveyProgress>['payload']): User => {
  const { surveyId, progress } = payload;
  const nextSurveyState = produce(state.surveys, (draftSurveys) => {
    const selectedSurvey = draftSurveys.entities.surveys?.[surveyId];

    if (selectedSurvey) {
      selectedSurvey.percent_of_complete = progress;
    }
  });
  return {
    ...state,
    surveys: nextSurveyState,
  };
};

export default setSurveyProgress;
