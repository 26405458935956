import React from 'react';

import Svg, { SvgProps, Defs, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Bar = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 128.27 129' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M128.15 34.65c.66 3.58-1.45 6.24-3.8 8.6q-40.89 41-81.89 81.86c-5.22 5.18-12.08 5.17-17.4.06Q14.28 114.79 3.89 104c-5.25-5.48-5.19-12.83.19-18.24Q44.93 44.72 86 3.89c5.22-5.19 12.08-5.17 17.4-.06 7.19 6.92 14.2 14 21.18 21.16a11.83 11.83 0 0 1 3.57 9.66Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <G fillOpacity={0.2}>
          <Path d='M124.81 25.57c-6.76-7.28-13.82-14.32-21.16-21-5.8-5.3-11.82-5.42-16.52-.78q-41.89 41.46-83.67 83a9.72 9.72 0 0 0-3.22 7.52 11.34 11.34 0 0 0 3.18 8.62c6.94 7.12 13.84 14.31 21.19 21 6.13 5.57 11.94 5.36 17.25.1Q83 83.23 124.09 42.37c5.06-5.03 5.58-11.57.72-16.8Zm-5 17.06Q81 81.24 42.15 119.78c-5.72 5.67-11.74 5.84-17.57.33C19 114.87 13.69 109.41 8.32 104a13.44 13.44 0 0 1-4.11-9.9c-.21-2.6 1.08-5.16 3.32-7.39Q47.28 47.28 87 7.83c4.78-4.73 10.9-4.76 16.1 0 6 5.51 11.75 11.24 17.3 17.18 5.23 5.62 4.88 12.21-.56 17.62Z' />
          <Path d='M103.38 53.69c-.2 1.61-1.36 1.8-2.53 1.28a11.7 11.7 0 0 1-3.26-2.22Q86.78 42.1 76 31.36a9.82 9.82 0 0 1-1.54-1.93c-.73-1.27-2-2.89-.61-4C75.51 24 76.53 26 77.47 27c8 7.9 16 15.87 24 23.83.85.78 1.78 1.54 1.91 2.86ZM78.31 78.79c-.38 1.67-1.43 1.75-2.36 1.31a10.29 10.29 0 0 1-2.81-2Q61.28 66.42 49.49 54.63a11.43 11.43 0 0 1-1.55-1.92 1.86 1.86 0 0 1 0-2.31c1-1.07 1.85-.3 2.56.36 1.7 1.58 3.33 3.23 5 4.87Q65.69 65.79 75.91 76c.91.87 1.68 1.94 2.4 2.79ZM23.78 75.29c1 .77 1.92 1.41 2.73 2.2Q38.87 89.72 51.16 102c.83.84 2.69 1.77 1.44 3.14s-2.88.16-3.91-.84q-12.57-12.35-25-24.82a2.91 2.91 0 0 1-1-3.12c.08-.36.59-.62 1.09-1.07Z' />
        </G>
        <Path d='M75.29 24.36c.19-1.6 1.36-1.79 2.53-1.27a11.3 11.3 0 0 1 3.25 2.22Q91.9 35.95 102.62 46.7a9.25 9.25 0 0 1 1.55 1.93c.73 1.27 2 2.88.6 4-1.61 1.38-2.63-.64-3.58-1.57q-12.07-11.87-24-23.83c-.84-.78-1.77-1.54-1.9-2.87ZM48.81 49.75c.38-1.68 1.43-1.75 2.37-1.31a10.36 10.36 0 0 1 2.8 2Q65.85 62.13 77.63 73.9a11.51 11.51 0 0 1 1.55 1.93 1.85 1.85 0 0 1 0 2.3c-.95 1.07-1.84.31-2.56-.36-1.7-1.58-3.33-3.22-5-4.86L51.21 52.58c-.91-.92-1.68-1.98-2.4-2.83ZM53.29 104.09c-1-.77-1.92-1.41-2.72-2.2q-12.36-12.21-24.66-24.5c-.83-.84-2.68-1.77-1.44-3.13s2.88-.17 3.91.84Q41 87.45 53.41 99.92a2.9 2.9 0 0 1 1 3.11c-.11.38-.62.62-1.12 1.06Z' />
      </G>
    </G>
  </Svg>
);

export default Bar;
