import React, { useRef, useState } from 'react';

import { PressableProps, StyleProp, TextStyle, ViewStyle } from 'react-native';

import { AnimatedWrapper, useSpring } from 'components/Animated/Animated.native';
import { useHover } from 'hooks';

import { ButtonText, StyledButton } from './styles';

export type ButtonProps = {
  text: string | React.ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost' | 'mango';
  disabled?: boolean;
  textStyle?: TextStyle;
  containerStyle?: StyleProp<ViewStyle>;
  withoutPadding?: boolean;
};

export const Button = ({ text, textStyle, containerStyle, withoutPadding, ...rest }: ButtonProps & PressableProps) => {
  const [pressed, setPressed] = useState(false);
  const buttonRef: any = useRef(null);

  const isHovered = useHover(buttonRef);

  const onPressIn = () => {
    setPressed(true);
  };

  const onPressOut = () => {
    setPressed(false);
  };

  const scaleValue = pressed ? 0.92 : 1;
  const animatedStyles = useSpring({ scale: scaleValue });

  return (
    <AnimatedWrapper style={[{ transform: [{ scale: animatedStyles.scale }] }, containerStyle]}>
      <StyledButton
        hover={isHovered}
        ref={buttonRef}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        style={withoutPadding && { paddingRight: 0, paddingLeft: 0 }}
        {...rest}>
        <ButtonText style={textStyle} variant={rest.variant} disabled={rest.disabled}>
          {text}
        </ButtonText>
      </StyledButton>
    </AnimatedWrapper>
  );
};

export default Button;
