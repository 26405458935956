import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const AdditionalServices = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M12.5 14C13.8807 14 15 12.8807 15 11.5C15 10.1193 13.8807 9 12.5 9C11.1193 9 10 10.1193 10 11.5C10 12.8807 11.1193 14 12.5 14Z'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M9 2H11.4C11.8243 2 12.2313 2.16389 12.5314 2.45561C12.8314 2.74733 13 3.143 13 3.55556V9'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M3.5 2C2.11929 2 1 3.11929 1 4.5C1 5.88071 2.11929 7 3.5 7C4.88071 7 6 5.88071 6 4.5C6 3.11929 4.88071 2 3.5 2Z'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M7 14H4.6C4.17565 14 3.76869 13.8361 3.46863 13.5444C3.16857 13.2527 3 12.857 3 12.4444L3 7'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default AdditionalServices;
