import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import { DefaultText } from 'style/common';

export const InputLabelText = styled(DefaultText)`
  color: ${(props) => props.theme.colors.gray2};
  line-height: 16.41px;
`;

export const LabelWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8px;
  min-height: 30px;
`;

export const IconWrapper = styled(Pressable)`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
`;
