import * as ActionTypes from 'store/action-types';
import { AppActionCreator, AppAction } from 'utils/types';

export type SetIsActivateAccountModalVisibleAction = AppAction<
  typeof ActionTypes.SET_IS_ACTIVATE_ACCOUNT_MODAL_VISIBLE,
  boolean
>;

export type SetIsActivateAccountModalVisibleActionCreator = AppActionCreator<SetIsActivateAccountModalVisibleAction>;

export const setIsActivateAccountModalVisible: SetIsActivateAccountModalVisibleActionCreator = (payload) => ({
  type: ActionTypes.SET_IS_ACTIVATE_ACCOUNT_MODAL_VISIBLE,
  payload,
});
