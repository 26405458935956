import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { demoTrackerPropsMock } from 'screens/main/Home/components/DemoMilestoneTracker/demoMock';
import { MilestoneTracker } from 'screens/main/Home/components/index';
import { setDemoMilestoneTrackerState } from 'store/actions';
import { getDemoMilestoneTrackerState } from 'store/selectors';

export const DemoMilestoneTracker = () => {
  const dispatch = useDispatch();

  const demoMilestoneTrackerState = useSelector(getDemoMilestoneTrackerState);

  const onClose = () => dispatch(setDemoMilestoneTrackerState('CLOSED'));

  return (
    <MilestoneTracker
      initialVisibleState={demoMilestoneTrackerState !== 'CLOSED'}
      isBenefitPage={false}
      onClose={onClose}
      trackerData={demoTrackerPropsMock[demoMilestoneTrackerState]}
    />
  );
};
