import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { tcPayLogo } from 'assets/images';
import { Layout } from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import { getAppDataIsLoading } from 'store/selectors';

import { CardItem } from './components/CardItem';
import { TutorialModal } from './components/Tutorial';
import { PageHeader } from './styles';

const title = 'Reimbursement Center';
const description = 'Submit claims to collect your reimbursements.';

export const RebateCenter = ({ navigation }: { navigation: any }) => {
  const dataIsLoading = useSelector(getAppDataIsLoading);

  const [showTutorial, setShowTutorial] = useState(false);

  if (dataIsLoading) return <FullScreenLoader />;

  return (
    <Layout navigation={navigation} title={title} description={description}>
      <PageHeader>Your Reimbursements</PageHeader>
      <TutorialModal visible={showTutorial} setVisible={setShowTutorial} />
      <CardItem
        image={tcPayLogo}
        info='Learn how to submit a new claim'
        buttonText='View Tutorial'
        onPress={() => setShowTutorial(true)}
      />
    </Layout>
  );
};

export default RebateCenter;
