import {
  AboutYourDose,
  DrugInteractions,
  Details,
} from 'screens/main/MedicineCabinet/sections/MedicationDetails/subSections';

import { TabTypesEnum } from './types';

export const tabsMapping: { [key in TabTypesEnum]: () => JSX.Element } = {
  [TabTypesEnum.ABOUT_YOUR_DOSE]: AboutYourDose,
  [TabTypesEnum.DRUG_INTERACTIONS]: DrugInteractions,
  [TabTypesEnum.DETAILS]: Details,
};
