import React from 'react';

import { View } from 'react-native';

import { useAdditionalServiceNotification } from 'components/MenuItem/useAdditionalServiceNotification';
import { Services } from 'screens/main/AdditionalServices/components';
import { PrimaryDefaultText } from 'style/common';

import { useAdditionalServicesSectionsStyles } from './styles';

export const AdditionalServicesSection = () => {
  const styles = useAdditionalServicesSectionsStyles();

  const { hideNotifications } = useAdditionalServiceNotification();

  return (
    <View>
      <PrimaryDefaultText style={styles.titleText}>Resources</PrimaryDefaultText>
      <Services
        initialActionModalState={false}
        initialDigitalResourceModalState={false}
        hideResourcesNotificationIcon={hideNotifications}
      />
    </View>
  );
};

export default AdditionalServicesSection;
