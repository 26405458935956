import { AdditionalServicesInterface } from 'api';
import { AdditionalServicesPostData } from 'api/AdditionalServicesInterface/types';
import { AppThunkAction } from 'store';
import {
  SET_COMPLETED_SERVICE_ACTION,
  SET_COMPLETED_SERVICE_REQUEST,
  SET_COMPLETED_SERVICE_DIGITAL_RESOURCE,
  SET_SELECTED_SERVICE_DIGITAL_RESOURCE,
  SET_SELECTED_SERVICE_REQUEST,
  SET_SELECTED_SERVICE_ACTION,
  REMOVE_SELECTED_SERVICE_ACTION,
  REMOVE_SELECTED_SERVICE_DIGITAL_RESOURCE,
  REMOVE_SELECTED_SERVICE_REQUEST,
  SET_CURRENTLY_ACTIVE_SERVICE,
  REMOVE_CURRENTLY_ACTIVE_SERVICE,
  INITIALIZE_PROGRAM_ADDITIONAL_SERVICES,
  SET_SHIPPING_ADDRESS_INFO,
  SET_RESOURCES_NOTIFICATION_ICON_VISIBILITY,
} from 'store/action-types';
import { SET_VIEWED_FINANCIAL_ASSISTANCE } from 'store/action-types/additional-services';
import { handlePromise } from 'utils/handlePromise';
import { AppActionCreator } from 'utils/types';

import { normalizeAdditionalServices } from './normalizer';
import { SetCurrentlyActiveService, RemoveCurrentlyActiveService, SetViewedFinancialAssistance } from './types';

export const setCompletedServiceAction = (payload: { id: number; isCompleted: boolean }) => ({
  type: SET_COMPLETED_SERVICE_ACTION,
  payload,
});

export const setCompletedServiceRequest = (payload: { id: number; isCompleted: boolean }) => ({
  type: SET_COMPLETED_SERVICE_REQUEST,
  payload,
});

export const setCompletedServiceDigitalResource = (payload: { id: number; isCompleted: boolean }) => ({
  type: SET_COMPLETED_SERVICE_DIGITAL_RESOURCE,
  payload,
});

export const initializeProgramAdditionalServices = (data: any) => {
  const normalizedData = normalizeAdditionalServices(data);
  return {
    type: INITIALIZE_PROGRAM_ADDITIONAL_SERVICES,
    payload: normalizedData,
  };
};

export const saveAdditionalServices =
  (data: AdditionalServicesPostData): AppThunkAction<Promise<void>> =>
  async (dispatch) => {
    const [res] = await handlePromise(() => AdditionalServicesInterface.put({ ...data, isSubmitted: true }));
    res && dispatch(setShippingAddressInfo({ data: res.data }));
  };

export const setResourcesNotificationIconVisibility = (payload: boolean) => ({
  type: SET_RESOURCES_NOTIFICATION_ICON_VISIBILITY,
  payload,
});

export const setShippingAddressInfo = (payload: { data: AdditionalServicesPostData }) => ({
  type: SET_SHIPPING_ADDRESS_INFO,
  payload,
});

export const setSelectedServiceAction = (payload: { id: number; isSelected: boolean }) => ({
  type: SET_SELECTED_SERVICE_ACTION,
  payload,
});

export const removeSelectedServiceAction = (payload: { id: number }) => ({
  type: REMOVE_SELECTED_SERVICE_ACTION,
  payload,
});

export const setSelectedServiceDigitalResource = (payload: { id: number; isSelected: boolean; isNew?: boolean }) => ({
  type: SET_SELECTED_SERVICE_DIGITAL_RESOURCE,
  payload,
});

export const setViewedFinancialAssistance: AppActionCreator<SetViewedFinancialAssistance> = (payload) => ({
  type: SET_VIEWED_FINANCIAL_ASSISTANCE,
  payload,
});

export const removeSelectedServiceDigitalResource = (payload: { id: number }) => ({
  type: REMOVE_SELECTED_SERVICE_DIGITAL_RESOURCE,
  payload,
});

export const setSelectedServiceRequest = (payload: { id: number; isSelected: boolean }) => ({
  type: SET_SELECTED_SERVICE_REQUEST,
  payload,
});

export const removeSelectedServiceRequest = (payload: { id: number }) => ({
  type: REMOVE_SELECTED_SERVICE_REQUEST,
  payload,
});

export const setCurrentlyActiveService: SetCurrentlyActiveService = (payload) => ({
  type: SET_CURRENTLY_ACTIVE_SERVICE,
  payload,
});

export const removeCurrentlyActiveService: RemoveCurrentlyActiveService = () => ({
  type: REMOVE_CURRENTLY_ACTIVE_SERVICE,
});
