import React, { useEffect, useMemo, useState } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { UserTypeEnum } from 'enums';
import { useTimer } from 'hooks/useTimer';
import { ProfileInfoLayout, ProfileInformationField } from 'screens/main/Profile/components';
import { BasicInfoBlock, InformationContainer } from 'screens/main/Profile/sections/styles';
import { saveUserBasicInfo, setBasicUserInformation, setHasModifications } from 'store/actions';
import {
  getUserFirstName,
  getUserLastName,
  getUserPreferredName,
  getUserTimeZoneInformation,
  getUserType,
} from 'store/selectors';
import { defaultTheme } from 'style/theme';
import { TRY_AGAIN_ERROR_MESSAGE } from 'utils/constants';
import { getTimeZoneInfo } from 'utils/dateTimeHelpers';
import { isIOSDevice } from 'utils/helpers';

import { profileAnalyticsActions } from '../../constants';
import { profileAnalyticsHandler } from '../../helpers';
import { initialFieldErrors } from './initialFieldErrors';
import { usePickerStyle } from './styles';
import type { AvailableInputFields, InputFieldErrors } from './types';
import { validateInputFields } from './validateInputFields';

export const BasicInformationSection = () => {
  const userFirstName = useSelector(getUserFirstName);
  const userLastName = useSelector(getUserLastName);
  const userPreferredName = useSelector(getUserPreferredName);
  // const contactPersonName = useSelector(getContactPersonName);
  const userTimeZoneInformation = useSelector(getUserTimeZoneInformation);
  const [fieldHasBeenChanged, setFieldHasBeenChanged] = useState(false);
  const userType = useSelector(getUserType);

  const { start, pause } = useTimer();
  const { EDIT_BASIC_INFO_STARTED, EDIT_BASIC_INFO_FINISHED } = profileAnalyticsActions;

  const initialUserInfo = useMemo(
    () => ({
      userFirstName,
      userLastName,
      userPreferredName,
      // contactPersonName,
      userTimeZoneInformation,
      userType,
    }),
    [userFirstName, userLastName, userPreferredName, userType, userTimeZoneInformation]
  );

  const dispatch = useDispatch();
  const toast = useToast();

  const [isProfileEdit, setProfileEdit] = useState(false);
  const [savingIsInProgress, setSavingInProgress] = useState(false);
  const [fieldError, setFieldError] = useState<InputFieldErrors>(initialFieldErrors);
  const isFormValid = Object.values(fieldError).some((field) => !field.message);

  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const isFocused = useIsFocused();

  useEffect(() => {
    const discardChanges = () => {
      setFieldHasBeenChanged(false);
      setProfileEdit(false);
      setFieldError(initialFieldErrors);
      setUserInfo(initialUserInfo);
    };

    if (!isFocused) {
      discardChanges();
    }
  }, [isFocused, initialUserInfo]);

  useEffect(() => {
    dispatch(setHasModifications(fieldHasBeenChanged));
  }, [fieldHasBeenChanged, dispatch]);

  const shouldSaveUserInfo = isProfileEdit && fieldHasBeenChanged;

  const setValue = (fieldName: AvailableInputFields | UserTypeEnum) => (fieldValue: string) => {
    validateInputFields({ fieldError, fieldValue, fieldName, setFieldError });
    setUserInfo({ ...userInfo, [fieldName]: fieldValue });
    setFieldHasBeenChanged(true);
  };

  const handleEditMode = async () => {
    if (!isFormValid) {
      toast.show('There are fields that require your attention', {
        type: 'danger',
      });
      return;
    }

    if (!isProfileEdit) {
      setProfileEdit(true);
      start();
      profileAnalyticsHandler(EDIT_BASIC_INFO_STARTED);
      return;
    }
    if (shouldSaveUserInfo) {
      dispatch(setBasicUserInformation(userInfo));
      setSavingInProgress(true);
      try {
        dispatch(saveUserBasicInfo());
      } catch (error: any) {
        const message = error?.response?.data?.message || TRY_AGAIN_ERROR_MESSAGE;
        toast.show(message, {
          type: 'danger',
        });
      }
    }
    setProfileEdit(false);
    setSavingInProgress(false);
    setFieldHasBeenChanged(false);
    const passedTime = pause();
    profileAnalyticsHandler(EDIT_BASIC_INFO_FINISHED, { event_label: passedTime });
  };

  const pickerStyle = usePickerStyle(userInfo.userType);
  const { timeZoneFromIntl } = getTimeZoneInfo();

  return (
    <ProfileInfoLayout
      title='Basic Information'
      titleColor={defaultTheme.colors.mangoPrimary}
      isEditMode={isProfileEdit}
      setEditMode={handleEditMode}
      savingIsDisabled={savingIsInProgress || !isFormValid}>
      <InformationContainer>
        <BasicInfoBlock style={{ marginBottom: isIOSDevice ? 10 : 24 }}>
          <ProfileInformationField
            label='First Name'
            children={userInfo.userFirstName}
            isEditMode={isProfileEdit}
            error={fieldError.userFirstName}
            onChangeText={setValue('userFirstName')}
          />
        </BasicInfoBlock>
        <BasicInfoBlock style={{ marginBottom: isIOSDevice ? 10 : 24 }}>
          <ProfileInformationField
            label='Last Name'
            children={userInfo.userLastName}
            isEditMode={isProfileEdit}
            onChangeText={setValue('userLastName')}
          />
        </BasicInfoBlock>
        <BasicInfoBlock>
          <ProfileInformationField
            label='Preferred Name'
            children={userInfo.userPreferredName}
            isEditMode={isProfileEdit}
            onChangeText={setValue('userPreferredName')}
          />
        </BasicInfoBlock>
        {/* this field on 5 pixels taller, that way margin = 19px */}
        {/* TODO(remove): removed for now will use in the future*/}
        {/*<BasicInfoBlock style={{ marginBottom: 19 }}>
          <ProfileInformationField label='Type' isNested>
            <SelectBoxWrapper>
              <SelectBox
                value={userInfo.userType}
                labelText=''
                disabled={!isProfileEdit}
                items={pickerItems}
                onValueChange={setValue('userType')}
                webStyle={pickerStyle}
                nativeStyle={pickerStyle}
                shouldShowIcon={false}
                shouldShowLabel={false}
                shouldShowDefaultPlaceHolder={false}
              />
            </SelectBoxWrapper>
          </ProfileInformationField>
        </BasicInfoBlock>
        <BasicInfoBlock>
          <ProfileInformationField
            label='Point of Contact'
            children={userInfo.contactPersonName}
            isEditMode={isProfileEdit}
            onChangeText={setValue('contactPersonName')}
          />
        </BasicInfoBlock>*/}
        <BasicInfoBlock>
          <ProfileInformationField label='Time Zone' children={timeZoneFromIntl} />
        </BasicInfoBlock>
      </InformationContainer>
    </ProfileInfoLayout>
  );
};
