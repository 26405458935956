import React, { ReactNode } from 'react';

import { View } from 'react-native';

import { Button, Modal } from 'components';
import { DefaultText } from 'style/common';

import { CoinsuranceDefinitionContent, CopayDefinitionContent } from './examples';
import { useExplanationModalStyle } from './styles';

export type ExplanationModalType = 'copay' | 'coinsurance' | 'empty';

type ExplanationModalProps = {
  type: ExplanationModalType;
  explanationModalVisible: boolean;
  setExplanationModalVisible: (visible: boolean) => void;
};

const modalTitleMapping: Record<ExplanationModalType, string> = {
  copay: 'What is a Co-pay?',
  coinsurance: 'What is a Coinsurance?',
  empty: 'What does this mean?',
};

const modalDescriptionMapping: Record<ExplanationModalType, string> = {
  copay:
    'A co-pay is a fixed amount that you will pay for a covered healthcare bill after you have paid all of your insurance deductible.  If you have not met your deductible, then you will pay the full price of the healthcare bill.',
  coinsurance:
    'This is the percentages of a covered healthcare bill that you will pay after you have paid all of your insurance deductible.',
  empty: 'After we talked to your insurance company, they have decided not to pay for this medication.',
};

const explanationModalMapping: Record<ExplanationModalType, ReactNode> = {
  copay: <CopayDefinitionContent />,
  coinsurance: <CoinsuranceDefinitionContent />,
  empty: null,
};

export const ExplanationModal = ({
  type,
  explanationModalVisible,
  setExplanationModalVisible,
}: ExplanationModalProps) => {
  const styles = useExplanationModalStyle();

  const cancelHandler = () => {
    setExplanationModalVisible(false);
  };

  return (
    <Modal
      modalIsVisible={explanationModalVisible}
      setModalVisible={setExplanationModalVisible}
      onRequestClose={cancelHandler}>
      <View style={styles.contentWrapper}>
        <DefaultText style={styles.explanationTitle}>{modalTitleMapping[type]}</DefaultText>
        <DefaultText style={styles.explanationDescription}>{modalDescriptionMapping[type]}</DefaultText>
        {explanationModalMapping[type]}
        <Button style={styles.cancelButton} text='Close' variant='tertiary' onPress={cancelHandler} />
      </View>
    </Modal>
  );
};
