import { Pressable } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';
import { shadeColor } from 'style/utils';
import { isNativeDevice } from 'utils/helpers';

export const StyledButton = styled(Pressable)<{
  variant?: string;
  disabled?: boolean;
  hover?: boolean;
}>`
  padding: 14px 24px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  max-height: 48px;
  ${(props) =>
    props.hover &&
    css`
      background-color: ${shadeColor(props.theme.colors.primary, 1.2)};
    `}
  ${(props) => {
    switch (props.variant) {
      case 'primary':
        return '';
      case 'secondary': {
        return css`
          background-color: ${({ theme }) => theme.colors.secondary};
          ${props.hover &&
          css`
            background-color: ${shadeColor(props.theme.colors.secondary, 1.15)};
          `}
        `;
      }
      case 'tertiary': {
        return css`
          background-color: ${props.theme.colors.white};
          border: 2px solid ${props.theme.colors.gray2};
          ${props.hover &&
          css`
            background-color: ${shadeColor('#FFFFFF', 1.05)};
          `}
        `;
      }
      case 'ghost': {
        return css`
          background-color: ${props.theme.colors.gray4};
          border: 2px solid ${props.theme.colors.gray2};
          ${props.hover &&
          css`
            background-color: ${shadeColor(props.theme.colors.gray4, 1.05)};
          `}
        `;
      }
      case 'mango': {
        return css`
          background-color: ${({ theme }) => theme.colors.mangoPrimary};
          ${props.hover &&
          css`
            background-color: ${shadeColor(props.theme.colors.mangoPrimary, 1.1)};
          `}
        `;
      }
      default: {
        return '';
      }
    }
  }}
  ${({ disabled }) =>
    disabled &&
    css`
      border-width: 0;
      background-color: ${({ theme }) => theme.colors.gray4};
    `}
`;

export const ButtonText = styled(DefaultText)<{
  variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost';
  disabled?: boolean;
}>`
  ${getFontStyles(16)};
  height: 20px;
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  ${(props) => {
    switch (props.variant) {
      case 'primary':
      case 'secondary':
        return css`
          color: ${props.theme.colors.white};
        `;
      case 'tertiary': {
        return css`
          color: ${props.theme.colors.gray2};
        `;
      }
      case 'ghost': {
        return css`
          color: ${props.theme.colors.gray2};
        `;
      }
      default: {
        return '';
      }
    }
  }}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.gray3};
      ${!isNativeDevice && 'cursor: not-allowed;'}
    `}
    ${!isNativeDevice &&
  css`
    user-select: none;
  `}
`;
