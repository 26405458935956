import { Item } from 'react-native-picker-select';

import { fillByEmptySymbols } from 'utils/fillByEmptySymbols';

const getOption = (value: string): Item => ({ label: value, value });

export const HOUR_OPTIONS = new Array(12).fill(null).map((_, index) => {
  const value = fillByEmptySymbols(index + 1, 2);

  return getOption(value);
});

export const MINUTE_OPTIONS = new Array(60).fill(null).map((_, index) => {
  const value = fillByEmptySymbols(index, 2);

  return getOption(value);
});

/** @description 04:30 PM | 11:10 AM and another meridiem values */
export const MERIDIEM_FORMAT_TYPE = 'p';
/** @description 12:00 | 09:00 and another meridiem values without AM and PM */
export const MERIDIEM_FORMAT_WITHOUT_POSTFIX_TYPE = 'hh:mm';
/** @description 13:30 | 23:05 and another 24 time values */
export const TIME_24_FORMAT_TYPE = 'HH:mm';

export default {
  PLACEHOLDER_TEXT: 'Pick a time...',
};
