import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';

export const useStyles = () => {
  const { isMobile, colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    surveyModalContentWrapper: {
      ...(!isMobile && {
        width: 492,
      }),
    },
    mainWrapper: applyBreakpoints(theme, {
      lg: {},
      xxl: { flexDirection: 'row' },
    }),
    calendarWrapper: {
      position: 'relative',
      ...applyBreakpoints(theme, {
        lg: {},
        xxl: { width: '60%' },
      }),
    },
    popoverWrapper: {
      position: 'absolute',
      zIndex: 2,
      ...applyBreakpoints(theme, {
        md: { top: 8, right: 2 },
        xxl: { top: 24, right: '40%' },
      }),
    },
    popoverText: { color: colors.gray2 },
    medFilterWrapper: {
      position: 'absolute',
      zIndex: 1,
      ...applyBreakpoints(theme, {
        md: { top: 50, width: '100%' },
        xxl: { top: 4, right: 8, width: '35%' },
      }),
    },
    medicationsWrapper: applyBreakpoints(theme, {
      md: {
        width: '100%',
      },
      lg: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      xxl: {
        marginTop: 0,
        marginLeft: 20,
        flex: 1,
      },
    }),
    sectionContainer: applyBreakpoints(theme, {
      md: { marginTop: 28 },
      lg: { width: '50%', marginTop: 28 },
      xxl: { marginBottom: 30 },
    }),
    text: {
      fontFamily: fontFamilies.bold,
      fontSize: fontSizes[16].fontSize,
    },
  });
};
