import { InsuranceDTO, Insurance } from 'api/Insurance/types';

export const toInsuranceCard = (dto: InsuranceDTO): Insurance => ({
  id: dto.id ?? '',
  type: dto.cardType ?? 'PHARMACY',
  primaryPharmacyCarrier: dto.primaryPharmacyCarrier ?? '',
  primaryInsuranceCarrier: dto.primaryInsuranceCarrier ?? '',
  policyPhone: dto.policyPhoneNumber ?? '',
  status: dto.status ?? 'PROCESSED',
  cardholder: dto.nameOfInsured ?? '',
  policyId: dto.policyId ?? '',
  memberId: dto.rxMemberId ?? '',
  groupId: dto.rxGroupId ?? '',
  BIN: dto.rxBin ?? '',
  PCN: dto.rxPcn ?? '',
});
