import React, { PropsWithChildren } from 'react';

import { ToastProvider } from 'react-native-toast-notifications';

import { renderTypes } from 'components/CustomToast';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';

export const AppToastProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isMobile } = useDeviceInfoContext();

  return (
    <ToastProvider offsetTop={isMobile ? 80 : 40} renderType={renderTypes} placement='top'>
      {children}
    </ToastProvider>
  );
};
