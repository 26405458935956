import React, { useState } from 'react';

import { TouchableOpacity } from 'react-native';

import { Checkbox } from 'components/Checkbox';
import { DefaultText, webStyles } from 'style/common';
import { AnyFunction } from 'utils/types';

import { useCheckBoxWithLabelStyles } from './styles';

export type CheckboxWithLabelProps = {
  isMobile?: boolean;
  handleConfirmChange: AnyFunction;
  isConfirmed: boolean;
  text?: string;
  testID?: string;
  disabled?: boolean;
};
export const CheckboxWithLabel = ({
  isMobile,
  handleConfirmChange,
  isConfirmed,
  testID,
  disabled,
  text = '',
}: CheckboxWithLabelProps) => {
  const [buttonStyle, setButtonStyle] = useState({});

  const styles = useCheckBoxWithLabelStyles();

  const alignItemsCheckbox = isMobile ? 'flex-start' : 'center';

  const buttonStyleHandler = () => {
    setButtonStyle({ outline: 'none' });
  };

  return (
    <TouchableOpacity
      testID={testID}
      style={[styles.checkboxWrapper, webStyles.button, { alignItems: alignItemsCheckbox }, buttonStyle]}
      onPress={handleConfirmChange}
      onFocus={buttonStyleHandler}>
      <Checkbox isChecked={isConfirmed} />
      <DefaultText numberOfLines={2} style={styles.checkText}>
        {text}
      </DefaultText>
    </TouchableOpacity>
  );
};

export default CheckboxWithLabel;
