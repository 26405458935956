import { PartnerRegistrationInterface } from 'api';
import { AppThunkAction } from 'store';

type SaveUserPreferenceInfo = () => AppThunkAction<Promise<void>>;

export const saveUserPreferenceInfo: SaveUserPreferenceInfo = () => async (_, getState) => {
  const userHasCellPhonePreference = getState().userInfo.hasCellPhonePreference;
  const userHasEmailPreference = getState().userInfo.hasEmailPreference;
  const userHasMailPreference = getState().userInfo.hasMailPreference;
  const userHasPushNotificationPreference = getState().userInfo.hasPushNotificationPreference;

  try {
    await PartnerRegistrationInterface.patch({
      data: {
        mailPreference: userHasMailPreference,
        emailPreference: userHasEmailPreference,
        cellPhonePreference: userHasCellPhonePreference,
        pushNotificationPreference: userHasPushNotificationPreference,
      },
    });
  } catch (error) {
    console.log({ error });
  }
};
