import { PixelRatio, TextInput } from 'react-native';
import styled, { css } from 'styled-components/native';

export const StyledTextInput = styled(TextInput)<{ postfix?: boolean; disabled?: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.gray3};
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 2px 0 16px;
  height: 52px;
  max-height: 52px;
  font: ${16 / PixelRatio.getFontScale()}px 'Montserrat' !important;
  color: ${(props) => props.theme.colors.gray1};
  width: ${(props) => (props.postfix ? 'auto' : '100%')};
  flex-grow: 1;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.gray4};
      border-radius: 6px;
      color: ${props.theme.colors.gray2};
      border-color: ${props.theme.colors.gray4};
    `}
`;

const commonStylePrefixAndPostfix = css`
  position: absolute;
  top: 0;
  font: 16px 'Montserrat' !important;
  color: ${(props) => props.theme.colors.gray1};
  padding: 16px;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.gray3};
  height: 100%;
  max-width: 105px;
`;

export const StyledPrefix = styled.View`
  left: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  ${commonStylePrefixAndPostfix}
`;

export const StyledPostfix = styled.View`
  right: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  ${commonStylePrefixAndPostfix}
`;
