import React from 'react';

import { StyleSheet, TextStyle, PixelRatio } from 'react-native';
import { PickerStyle } from 'react-native-picker-select';
import webStyled from 'styled-components';
import styled, { useTheme } from 'styled-components/native';

import { scrollbarThumbWebStyle } from 'style/global';
import { isNativeDevice } from 'utils/helpers';

export const Container = (
  isNativeDevice
    ? styled.View``
    : webStyled.div`
  position: relative;
  select {
    position: relative;
    text-indent: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1; 
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  
  select::-webkit-scrollbar {
    width: 5px;
  }
  
  select::-webkit-scrollbar-thumb {
    border-right: 1px transparent solid;
    background-clip: content-box;
    ${scrollbarThumbWebStyle}
  }
`
) as React.ElementType;

type GetCommonStylesProps = {
  disabled?: boolean;
  maxInputWidth?: number | string;
};

export const defaultSelectBoxStyles = {
  web: { paddingLeft: 16, paddingRight: 30, zIndex: 1 },
};

export const useCommonStyles = ({ disabled, maxInputWidth = '100%' }: GetCommonStylesProps): TextStyle => {
  const { colors, fontFamilies, isMobile } = useTheme();

  return {
    height: 53,
    borderRadius: 8,
    borderColor: colors.gray3,
    borderWidth: 1,
    paddingLeft: 16,
    paddingRight: 42,
    fontFamily: fontFamilies.default,
    color: colors.gray1,
    backgroundColor: 'transparent',
    ...(disabled && { backgroundColor: colors.gray4, borderWidth: 0, color: colors.gray2 }),
    ...(!isMobile && { lineHeight: 24 / PixelRatio.getFontScale() }),
    fontSize: 16 / PixelRatio.getFontScale(),
    maxWidth: maxInputWidth,
  };
};

type GetPickerStylesProps = {
  disabled?: boolean;
  webStyle?: TextStyle;
  nativeStyle?: TextStyle;
  maxInputWidth?: number | string;
};

export const usePickerStylesProps = ({
  disabled,
  webStyle,
  nativeStyle,
  maxInputWidth,
}: GetPickerStylesProps): PickerStyle => {
  const { colors } = useTheme();

  const commonStyles = useCommonStyles({ disabled, maxInputWidth });

  const iconHeight = typeof commonStyles.height === 'string' ? 54 : commonStyles.height ?? 54;

  return StyleSheet.create({
    inputWeb: { ...commonStyles, ...defaultSelectBoxStyles.web, ...webStyle },
    inputIOS: { ...commonStyles, ...nativeStyle },
    inputAndroid: { ...commonStyles, ...nativeStyle },
    iconContainer: {
      position: 'absolute',
      top: iconHeight / 2 - 7, // half input height minus half icon height
      right: 16,
    },
    placeholder: { color: colors.gray3 },
  });
};
