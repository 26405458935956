import React, { useEffect, useRef, useState } from 'react';

import { View } from 'react-native';

import { CopayCardInfo } from 'store/reducers/user-info/types/copay-card';

import { RemainingIndicatorCard } from './RemainingIndicatorCard';
import { useCopayOverviewIndicatorsStyle } from './styles';
import { RemainingIndicatorCardProps, UsedIndicatorCardProps } from './types';
import UsedIndicatorCard from './UsedIndicatorCard';

type CopayOverviewIndicatorsProps = {
  selectedCopayCard?: CopayCardInfo;
  handleCopayCardClick: (copayCard?: CopayCardInfo) => void;
};

export const CopayOverviewIndicators = ({ selectedCopayCard, handleCopayCardClick }: CopayOverviewIndicatorsProps) => {
  const [remainingCardProps, setRemainingCardProps] = useState<RemainingIndicatorCardProps | null>();
  const [usedCardProps, setUsedCardProps] = useState<UsedIndicatorCardProps | null>();
  const [indicatorsToShowLength, setIndicatorsToShowLength] = useState(0);

  const handleCopayCardClickRef = useRef(handleCopayCardClick);
  handleCopayCardClickRef.current = handleCopayCardClick;

  const styles = useCopayOverviewIndicatorsStyle(indicatorsToShowLength);

  useEffect(() => {
    if (!selectedCopayCard) return;

    const {
      type,
      config: { isBenefitsRemaining, isBenefitsUsed, isFillsRemaining, isFillsUsed },
      fillsUsed,
      updatedAt,
      benefitsUsed,
      remainingFills,
      benefitsRemaining,
      totalFillsAllowed,
      maxAvailableBenefit,
    } = selectedCopayCard;

    switch (type) {
      case 'MEDICAL': {
        setIndicatorsToShowLength([isBenefitsRemaining, isBenefitsUsed].filter(Boolean).length);

        if (isBenefitsRemaining)
          setRemainingCardProps({
            prefix: '$',
            usedValue: benefitsUsed,
            title: 'Benefits Remaining',
            onPress: () => handleCopayCardClickRef.current(selectedCopayCard),
            remainingValue: benefitsRemaining,
            visible: isBenefitsRemaining,
            maximumAvailable: maxAvailableBenefit,
            showOnlyUsedMetrics: !benefitsRemaining,
          });

        if (isBenefitsUsed)
          setUsedCardProps({
            prefix: '$',
            title: 'Benefits Used',
            usedValue: benefitsUsed,
            onPress: () => handleCopayCardClickRef.current(selectedCopayCard),
            updatedDate: updatedAt,
            // TODO(change): should be value => how much increased... after GO Live
            maxAvailableValue: maxAvailableBenefit,
          });

        break;
      }
      case 'PHARMACY': {
        setIndicatorsToShowLength([isFillsRemaining, isFillsUsed].filter(Boolean).length);

        if (isFillsRemaining)
          setRemainingCardProps({
            usedValue: fillsUsed,
            title: 'Uses Remaining',
            onPress: () => handleCopayCardClickRef.current(selectedCopayCard),
            remainingValue: remainingFills,
            visible: isFillsRemaining,
            maximumAvailable: totalFillsAllowed,
            showOnlyUsedMetrics: !remainingFills,
          });

        if (isFillsUsed)
          setUsedCardProps({
            title: 'Fills Used',
            usedValue: fillsUsed,
            onPress: () => handleCopayCardClickRef.current(selectedCopayCard),
            updatedDate: updatedAt,
            // TODO(change): should be value => how much increased... after GO Live
            maxAvailableValue: totalFillsAllowed,
          });

        break;
      }
    }
  }, [selectedCopayCard]);

  return (
    <View style={styles.indicatorsWrapper}>
      {!!remainingCardProps && (
        <RemainingIndicatorCard {...remainingCardProps} cardWrapperStyles={styles.remainingMetricsCardWrapper} />
      )}
      {usedCardProps && <UsedIndicatorCard {...usedCardProps} cardWrapperStyles={styles.usedMetricsCardWrapper} />}
    </View>
  );
};
