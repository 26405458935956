import * as Contacts from 'expo-contacts';
import { Contact } from 'expo-contacts';
import { PhoneNumber, Address } from 'expo-contacts/src/Contacts';

import { isIOSDevice } from 'utils/helpers';

const checkContactPermission = async () => {
  const { granted } = await Contacts.requestPermissionsAsync();

  return granted;
};

export const addToContacts = async ({
  address,
  number,
  ...contact
}: Pick<Contact, 'id' | 'contactType' | 'name'> & { number: PhoneNumber; address?: Address }): Promise<
  | {
      type: 'success' | 'danger';
      message: string;
    }
  | undefined
> => {
  if (!(await checkContactPermission())) return { type: 'danger', message: 'We need permission to add this contact.' };

  const { data } = await Contacts.getContactsAsync({ pageSize: 10000000, name: contact.name });

  const contactIndex = data?.findIndex((c) => c?.name === contact.name);
  const hasContact = contactIndex !== -1;

  const newContact: Contact = {
    ...contact,
    [Contacts.Fields.ContactType]: contact.contactType,
    [Contacts.Fields.Name]: contact.name,
    [Contacts.Fields.ID]: hasContact ? data[contactIndex].id : contact.id,
    [Contacts.Fields.FirstName]: contact.name,
    [Contacts.Fields.PhoneNumbers]: [number],
    [Contacts.Fields.Addresses]: address ? [address] : [],
  };

  return Contacts.presentFormAsync(
    hasContact ? data[contactIndex].id : null,
    isIOSDevice && hasContact ? null : newContact
  );
};
