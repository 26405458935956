import * as ActionTypes from 'store/action-types';

export type SetShowEventNotification = (payload: boolean) => {
  type: typeof ActionTypes.SET_SHOW_EVENT_NOTIFICATION;
  payload: boolean;
};

export const setShowEventNotification: SetShowEventNotification = (payload) => ({
  type: ActionTypes.SET_SHOW_EVENT_NOTIFICATION,
  payload,
});
