import Constants from 'expo-constants';
import { StyleSheet, Platform, TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';
import { defaultTheme } from 'style/theme';
import { isAndroidDevice, isNativeDevice } from 'utils/helpers';

export const getItemStyles = (isMobile?: boolean, logoWidth?: number) =>
  StyleSheet.create({
    item: {
      marginRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      ...(Platform.OS === 'web' && { cursor: 'pointer', userSelect: 'none' }),
    },
    itemsWrapper: { justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%' },
    itemBlockTextWrapper: { marginTop: 20, marginBottom: 10 },
    imageWrapper: { height: 40, width: logoWidth, marginTop: !isNativeDevice && isMobile ? 20 : 0 },
    itemBlockText: { color: defaultTheme?.colors?.gray2 },
    tourGuide: { width: '100%' },
    drawerLogo: { height: 40 },
  });

export const drawerContentStyles = StyleSheet.create({
  contactButton: { marginVertical: 20 },
  needHelpText: { marginTop: 20 },
  timeText: { marginTop: 20 },
  safeArea: { flex: 1 },
  spacer40: { height: 40 },
  navigatorContainer: { flex: 1, flexDirection: 'row' },
  contactWrapper: { marginTop: 'auto' },
});

export const BottomText = styled(DefaultText)`
  ${getFontStyles(16)};
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
`;

export const ContentWrapper = styled.View`
  align-items: flex-start;
  margin: 0 20px 0 40px;
  height: 100%;
  flex: 1;
`;

export const CloseButton = styled(TouchableOpacity)`
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 15px;
  top: 25px;
  left: -${isAndroidDevice ? 45 : 48}px;
  ${isNativeDevice &&
  css`
    top: ${43 + Constants.statusBarHeight}px;
  `}
`;

export const TimeText = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${defaultTheme.colors.gray2};
`;

export const ListWrapper = styled.View`
  align-items: flex-start;
`;

export const LogoContainer = styled.Pressable`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: ${(props) => (props.theme.isMobile && !isNativeDevice ? '74px' : '104px')};
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.colors.gray3};
  width: 100%;
  ${isNativeDevice &&
  css`
    height: 140px;
  `}
`;
