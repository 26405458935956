import React from 'react';

import Svg, { Circle, SvgProps } from 'react-native-svg';

const Ellipse = (props: SvgProps) => {
  return (
    <Svg width={8} height={8} viewBox='0 0 8 8' fill='none' {...props}>
      <Circle cx={4} cy={4} r={4} fill={props.fill} />
    </Svg>
  );
};

export default Ellipse;
