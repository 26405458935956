import React from 'react';

import { View, TouchableOpacity } from 'react-native';

import { DefaultText, StandardText } from 'style/common';
import { defaultTheme } from 'style/theme';
import { AnyFunction } from 'utils/types';

import { useComponentStyles } from './styles';

export type InteractionItemProps = {
  interactionName: string;
  severity: number;
  onPress: AnyFunction;
};

const severityMapping = [
  { text: 'Minor', color: defaultTheme.colors.gray2 },
  { text: 'Moderate', color: defaultTheme.colors.warning },
  { text: 'Severe', color: defaultTheme.colors.negative },
  { text: 'Severe', color: defaultTheme.colors.negative },
];

export const SeverityStatus = ({ severity }: { severity: number }) => {
  const styles = useComponentStyles();
  const { text, color } = severityMapping[severity - 1];

  return (
    <View style={[styles.severityStatusWrapper, { backgroundColor: color }]}>
      <DefaultText style={styles.severityText}>{text}</DefaultText>
    </View>
  );
};

export const InteractionItem = ({ interactionName, severity, onPress }: InteractionItemProps) => {
  const styles = useComponentStyles();

  return (
    <TouchableOpacity onPress={onPress} style={styles.itemBlock}>
      <StandardText numberOfLines={3} style={styles.interactionItemText}>
        {interactionName}
      </StandardText>
      <SeverityStatus severity={severity} />
    </TouchableOpacity>
  );
};

export default InteractionItem;
