import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useThanksStyles = () => {
  const { colors, fontSizes, fontFamilies, isMobile } = useTheme();

  return StyleSheet.create({
    wrapper: {
      paddingHorizontal: isMobile ? 8 : 32,
      paddingBottom: isMobile ? 16 : 32,
      minWidth: isMobile ? 248 : 428,
    },
    firstTextBlock: {
      fontFamily: fontFamilies.bold,
      ...fontSizes[24],
    },
    thirdTextBlock: {
      marginTop: 30,
      ...fontSizes[16],
      color: colors.gray1,
    },
    submitButtonWrapper: { marginTop: 40 },
    exitButton: { width: 88 },
    mTop20: { marginTop: 20 },
  });
};
