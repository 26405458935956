import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';

export const useCopayOverviewIndicatorsStyle = (indicatorsToShow: number) => {
  const { width } = useTheme();

  return StyleSheet.create({
    indicatorsWrapper: {
      flex: 1,
      justifyContent: 'space-between',
      ...applyBreakpoints(
        { width },
        {
          md: {
            flexDirection: 'column',
          },
          lg: {
            flexDirection: 'row',
          },
          xxl: {
            flexDirection: 'column',
          },
        }
      ),
    },
    remainingMetricsCardWrapper: {
      padding: 20,
      marginBottom: 0,
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      flex: indicatorsToShow > 1 ? 0.47 : 1,
      ...applyBreakpoints(
        { width },
        {
          md: { marginBottom: indicatorsToShow > 1 ? 16 : 0 },
          lg: {
            flexDirection: 'column',
          },
          xxl: {
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        }
      ),
    },
    usedMetricsCardWrapper: {
      padding: 20,
      marginBottom: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      flex: indicatorsToShow > 1 ? 0.47 : 1,
    },
  });
};

export const useIndicatorCardStyles = () => {
  const { colors, fontSizes, fontFamilies } = useTheme();

  return StyleSheet.create({
    dateText: {
      color: colors.gray1,
      fontFamily: fontFamilies.semiBold,
    },
    remainingUsedValueText: {
      ...fontSizes[24],
      color: colors.gray1,
      textAlign: 'center',
      fontFamily: fontFamilies.bold,
    },
    usedValueText: {
      ...fontSizes[24],
      color: colors.gray1,
      fontFamily: fontFamilies.bold,
    },
    indicesWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    profitValueWrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    arrowStyle: {
      borderTopWidth: 0,
      borderRightWidth: 8,
      borderBottomWidth: 8,
      borderLeftWidth: 8,
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: colors.primary,
      borderRadius: 1,
      borderLeftColor: 'transparent',
      marginTop: 3,
    },
  });
};
