import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Rect } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Drops = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 66.02 133.03' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path fillOpacity={0.3} d='M13.43 62.51ZM4.55 64Z' />
        <Path
          d='M52.1 57.17c13.84 5.63 13.84 5.63 13.84 20.28s-.11 29.28.08 43.92c0 3.74-1.34 5.83-4.61 7.34-6.64 3.07-13.83 3.44-20.85 4a108.63 108.63 0 0 1-29.21-1.83C1.71 129.05 0 127.18 0 117.59V69.68c0-7.68.59-8.45 8.16-10.6 1.21-.34 3.21 0 3.09-1.72s-2-1.42-3.32-1.38c-2.58.09-3-1.41-2.94-3.52C5 45 5.07 37.49 5 30a4.31 4.31 0 0 1 3.09-4.52c9.36-3.68 13-11.29 13.83-20.62.26-3 1.92-4.41 4.27-4.62A64.36 64.36 0 0 1 38.1.32C41 .6 42.83 2 43.15 5.77c.72 8.44 4 15.76 12.39 19.52A3.73 3.73 0 0 1 58 29.15c-.09 7.82-.29 15.65.06 23.45.22 4.4-3.7 2.75-5.96 4.57Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.1}
          d='M.44 64v-.14.13ZM7.83 59.18l-.53.15ZM58 29.15a3.73 3.73 0 0 0-2.47-3.86C47.1 21.53 43.87 14.21 43.15 5.77A6.82 6.82 0 0 0 42 2.24 4.49 4.49 0 0 0 39.13.46c-.29-.05-.61-.09-1-.13H38A54 54 0 0 0 32.32 0a55.78 55.78 0 0 0-6.12.24h-.1a6 6 0 0 0-.84.13 4 4 0 0 0-2 1.14 1.92 1.92 0 0 0-.46.58 5.92 5.92 0 0 0-.86 2.75c-.84 9.35-4.52 16.96-13.88 20.64A4.31 4.31 0 0 0 5 30c.1 7.49.07 15 0 22.46 0 2.11.36 3.61 2.94 3.52 1.29 0 3.2-.22 3.32 1.38s-1.88 1.38-3.09 1.72h-.12c6.75 1.3 24.81 3.72 48.35-.08h.14c-1.3-.55-2.77-1.15-4.44-1.83 2.26-1.82 6.18-.17 6-4.57-.38-7.8-.18-15.6-.1-23.45ZM39.16 4.33h-.12Zm1.17-.47Zm-.55.24h-.07Zm-1.3.44h-.19Zm-12.73-.37-.21-.07Zm-.66-.27-.16-.06Zm-.62-.29h-.08ZM24 3.34ZM23.52 3Zm6.84 2.19.82.06Zm-4.15-.89.3.1Zm.73.23.41.11Zm.78.21.46.1Zm.83.18L29 5Zm.89.15.62.08Zm1.88.19h2-1Zm6.42-.57-.26.06ZM37 4.9l-.38.1Zm-.89.1c-.14 0-.29 0-.43.06.14.02.32.01.43-.06Zm-.89.12h-.51Zm-.93.09h-.62Z'
        />
        <G fillOpacity={0.2}>
          <Path d='M2 90.6c0-2.65-.06-5.31 0-8 .19-6.39.41-14.6.41-13.87 0 0 11 3.14 18.4 2.74S22.41 78 22.05 79 11.14 99.68 5.61 108.33a58.41 58.41 0 0 1-3.19 5.41L2 108V90.6ZM13.05 25.07c5.13-3.47 9.28-10.31 10-19.33.09-1.19 1.67-1.66 1.76-.17.41 6.74.84 13.5-2.47 19.86a5.27 5.27 0 0 1-1.78 2c-1.89 1.44-5.55.69-5.55.69s-1.25-.2-3.73-.67c-4.28-.88.07-1.14 1.77-2.38Z' />
        </G>
        <Path
          fillOpacity={0.2}
          d='M42 2.2c0 1.47-4.27 3.12-9.7 3.12s-9.51-2.21-9.57-3.21a1.92 1.92 0 0 1 .46-.58 4 4 0 0 1 2-1.14A42.38 42.38 0 0 1 32.32 0a45.76 45.76 0 0 1 6.81.45A4.49 4.49 0 0 1 42 2.2ZM58 29.15a2.46 2.46 0 0 0 0-.28c-27.3 6.25-47.43 1.85-53 .34A6.63 6.63 0 0 0 5 30c.11 7.49.08 15 0 22.46 0 2.11.37 3.61 2.94 3.52a13.3 13.3 0 0 1 1.55 0h.14a99.6 99.6 0 0 0 43.78.5c2.2-.74 4.84-.26 4.67-3.88-.37-7.8-.16-15.6-.08-23.45Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M8 59.11c-.17.29-7 1.13-7.56 5 11 3.5 30 7.38 64.75 1.71-1-3.11-3.57-4.83-8.76-6.9-23.65 3.9-41.75 1.45-48.43.19Z'
        />
        <Rect fill='#fff' fillOpacity={0.4} x={53.65} y={68.72} width={9.03} height={55.85} rx={4.51} />
      </G>
    </G>
  </Svg>
);

export default Drops;
