import { ApiConsentsInterface } from 'api/Consents/types';
import apiRequest, { apiPrefix } from 'utils/network';

const APP_CONSENTS_URL = '/api/v1/approve_consent/';

export const AppConsentsInterface: ApiConsentsInterface = {
  saveGivenConsent: ({ consent_id }) =>
    apiRequest({
      method: 'POST',
      endpoint: APP_CONSENTS_URL,
      baseURL: apiPrefix,
      body: { consent_id },
    }),
};
