import React, { Dispatch, SetStateAction } from 'react';

import { Button } from 'components';
import { DefaultText } from 'style/common';

import { useSurveyCompletionModalStyles } from './styles';

export type SurveyCompletionModalContentProps = {
  setModalVisible: Dispatch<SetStateAction<boolean>>;
};

export const SurveyCompletionModalContent = ({ setModalVisible }: SurveyCompletionModalContentProps) => {
  const styles = useSurveyCompletionModalStyles();

  return (
    <>
      <DefaultText style={styles.title}>Thanks for your feedback!</DefaultText>
      <DefaultText style={styles.description}>
        Thanks for taking the time to fill out this this survey. Your response has been recorded.
      </DefaultText>
      <Button onPress={() => setModalVisible(false)} variant='secondary' style={styles.buttonStyle} text='Close' />
    </>
  );
};
