import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';
import { getAppShadow } from 'style/utils';

export const useStyles = ({ isActive, isLoading }: { isLoading: boolean; isActive: boolean }) =>
  StyleSheet.create({
    wrapper: {
      marginHorizontal: 32,
      marginVertical: 4,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 20,
      borderRadius: 20,
      opacity: isLoading ? 0.8 : 1,
      backgroundColor: isActive ? defaultTheme.colors.white : defaultTheme.colors.gray4,
      ...getAppShadow({
        opacity: isActive ? 0.1 : 0,
        radius: 22,
        elevation: 3,
      }),
    },
    textContainer: { flexDirection: 'column', width: '80%' },
    medicationText: { fontFamily: defaultTheme.fontFamilies.semiBold },
    notificationStatusWrapper: {
      marginVertical: 10,
    },
    notificationStatusText: {
      fontFamily: defaultTheme.fontFamilies.semiBold,
    },
    medicationTimeText: { color: defaultTheme.colors.gray2, marginTop: 10 },
    checkmarkContainer: { marginLeft: 10 },
  });
