import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const styles = StyleSheet.create({
  wrapper: {
    position: 'relative',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: 24,
    maxHeight: 380,
  },
  title: {
    fontFamily: defaultTheme.fontFamilies.bold,
    ...defaultTheme.fontSizes[24],
  },
  description: { marginTop: 30, ...defaultTheme.fontSizes[16], color: defaultTheme.colors.gray1 },
  closeButton: { width: 120, marginTop: 40 },
});
