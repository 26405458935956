import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

function UndoneCheckMark(props: SvgProps) {
  return (
    <Svg width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
      <Path
        d='M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z'
        fill={defaultTheme.colors.gray3}
      />
      <Path
        d='M9.14258 15.9999L14.4759 21.7142L22.8569 10.2856'
        stroke={defaultTheme.colors.gray3}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default UndoneCheckMark;
