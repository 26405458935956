import { Answer } from '.';

export const reachableTimes: Answer[] = [
  {
    id: 1,
    label: 'Morning',
  },
  {
    id: 2,
    label: 'Afternoon',
  },
  {
    id: 3,
    label: 'Evening',
  },
];

export const TEXTS = {
  title: 'Injection Training',
  description: `We're happy to schedule an injection training call for you.`,
  exit: 'Exit',
  thanks: 'Thanks!',
  weWillBeInTouch: "We'll be in touch soon!",
};
