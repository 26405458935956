import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const FinancialAssistance = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M8 1v14M11.333 4h-5c-.619 0-1.212.21-1.65.586C4.246 4.96 4 5.47 4 6c0 .53.246 1.04.683 1.414A2.544 2.544 0 006.333 8h3.334c.619 0 1.212.21 1.65.586.437.375.683.884.683 1.414 0 .53-.246 1.04-.683 1.414a2.544 2.544 0 01-1.65.586H4'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default FinancialAssistance;
