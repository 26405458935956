import React from 'react';

import { reloadAsync } from 'expo-updates';
import { View, SafeAreaView } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { useTheme } from 'styled-components/native';

import { patientColorLogo, maintenanceDesktop, maintenanceMobile } from 'assets/images';
import Footer from 'components/Footer';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useImageSizes } from 'hooks';
import { HeaderText, MangoLogoWrapper, StyledImage, webStyles } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

import BoundaryConstants from './constants';
import { useBoundaryUIStyles } from './styles';

export type BoundaryUIProps = {
  error: Error;
  resetError: () => void;
};

export const BoundaryUI = ({ error, resetError }: BoundaryUIProps) => {
  const styles = useBoundaryUIStyles();
  const { isMobile } = useDeviceInfoContext();
  const { imageWidth: mangoLogoWidth } = useImageSizes({
    url: patientColorLogo,
    widthValue: 48,
    isSrc: true,
  });
  const theme = useTheme();

  const logoClickHandler = async () => {
    if (!isNativeDevice) {
      window.location.reload();
      return;
    }
    resetError();
    await reloadAsync();
  };

  const maintenanceImage = isMobile ? maintenanceMobile : maintenanceDesktop;

  return (
    <SafeAreaView style={styles.safeAreView}>
      <View style={styles.header}>
        <MangoLogoWrapper onPress={logoClickHandler} style={[webStyles.button, { width: mangoLogoWidth }]}>
          <StyledImage resizeMode='contain' source={patientColorLogo} />
        </MangoLogoWrapper>
      </View>
      <ScrollView contentContainerStyle={styles.content}>
        <View style={styles.maintenanceImageWrapper}>
          <StyledImage resizeMode='contain' source={maintenanceImage} />
        </View>
        <View style={styles.textWrapper}>
          <HeaderText style={styles.headerText} headerTextColor={theme.colors.gray1}>
            {BoundaryConstants.errorTitle}
          </HeaderText>
        </View>
        <Footer />
      </ScrollView>
    </SafeAreaView>
  );
};

export default BoundaryUI;
