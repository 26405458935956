import { idGenerator } from 'utils/testIds/utils';

export const TEST_ID = {
  // Auth Page
  AUTH_EMAIL_ADDRESS_FIELD: idGenerator(1000000000),
  AUTH_PASSWORD_FIELD: idGenerator(1000000001),
  AUTH_FORGOT_YOUR_PASSWORD_BUTTON: idGenerator(1000000002),
  AUTH_LOGIN_BUTTON: idGenerator(1000000003),
  AUTH_REACTIVATE_ACCOUNT_BUTTON: idGenerator(1000000004),
  // Medication List Page
  MEDICATION_LIST_ADD_NEW_MEDICATION_BUTTON: idGenerator(1000000005),
  MEDICATION_LIST_ADD_NEW_MEDICATION_PLUS_BUTTON: idGenerator(1000000006),
  // Add New Medication
  ADD_NEW_MEDICATION_NAME: idGenerator(1000000007),
  ADD_NEW_MEDICATION_NAME_SELECT_OPTIONS: idGenerator(1000000008),
  ADD_NEW_MEDICATION_DOSAGE: idGenerator(1000000009),
  ADD_NEW_MEDICATION_TYPE: idGenerator(1000000010),
  ADD_NEW_MEDICATION_STRENGTH: idGenerator(1000000011),
  ADD_NEW_MEDICATION_QUANTITY: idGenerator(1000000012),
  ADD_NEW_MEDICATION_START_DATE: idGenerator(1000000013),
  ADD_NEW_MEDICATION_TAKE_EVERY_VALUE: idGenerator(1000000014),
  ADD_NEW_MEDICATION_TAKE_EVERY_TYPE: idGenerator(1000000015),
  ADD_NEW_MEDICATION_AS_NEEDED_CHECKBOX: idGenerator(1000000016),
  ADD_NEW_MEDICATION_HOURS_TIME: idGenerator(1000000017),
  ADD_NEW_MEDICATION_MINUTES_TIME: idGenerator(1000000018),
  ADD_NEW_MEDICATION_MERIDIEM_TIME: idGenerator(1000000019),
  ADD_NEW_MEDICATION_LAST_STEP_CLOSE_BUTTON: idGenerator(1000000020),
  // Medications List Element
  MEDICATION_ELEMENT_DELETE_BUTTON: idGenerator(1000000021),
  // Delete Modal
  DELETE_MEDICATION_CANCEL_BUTTON: idGenerator(1000000022),
  DELETE_MEDICATION_CONFIRM_BUTTON: idGenerator(1000000023),
  // Consent
  CONSENT_CHECKBOX: idGenerator(1000000024),
  CONSENT_DESCRIPTION: idGenerator(1000000025),
  CONSENT_SUBMIT_BUTTON: idGenerator(1000000026),
  CONSENT_SKIP_BUTTON: idGenerator(1000000027),
};
