import React from 'react';

import { patientLogo } from 'assets/images';
import { copyright } from 'localization/footer';
import { StyledImage, DefaultText } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

import { FooterContainer, FooterText, LandingFooterIconWrapper, NativeFooterContainer, logoTitle } from './styles';

type FooterProps = {
  showNativeFooter?: boolean;
};

export const Footer = ({ showNativeFooter }: FooterProps) => {
  if (showNativeFooter || isNativeDevice) {
    return (
      <NativeFooterContainer>
        <DefaultText style={logoTitle}>Powered by</DefaultText>
        <LandingFooterIconWrapper>
          <StyledImage source={patientLogo} />
        </LandingFooterIconWrapper>
      </NativeFooterContainer>
    );
  }
  return (
    <FooterContainer>
      <LandingFooterIconWrapper>
        <StyledImage source={patientLogo} />
      </LandingFooterIconWrapper>
      <FooterText>{copyright}</FooterText>
    </FooterContainer>
  );
};

export default Footer;
