import React from 'react';

import Svg, { SvgProps, Defs, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Inhaler = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 106.66 110.03' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M7.26 34.85.65 24.29S-2.17 18.57 4 13.67 14.9 4 24.55 1.05c.71 0 7.57-3.42 10.75 2.09s5.26 8.44 5.26 8.44Z'
          fill='#ddd'
        />
        <Path
          fillOpacity={0.2}
          d='M8.45 33.42s4.41-7.2 8.6-9.13C15.7 22.6 9.19 11.88 9.19 11.88l-6 5.56a7 7 0 0 0 0 7.86c2.68 4.31 5.26 8.12 5.26 8.12Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='m24.55 3.06 8 11.34s1-1.28 6-2C38.46 11.84 33 3.06 33 3.06s-3.29-2.46-8.45 0Z'
        />
        <Path
          d='M51.63 110c-4.56.28-6.72-2.67-8.63-5.93Q29.14 80.46 15.33 56.82c-2.68-4.59-5.14-9.32-8-13.75-1.7-2.58-1.89-4.87-.47-7.42 7-12.6 17.85-20.21 31.65-23.81 4.49-1.18 6.4 1.88 8.38 5.16 9.55 16 19.28 31.79 28.75 47.78 1.44 2.44 3 3.29 5.59 2.9 3.11-.46 6.23-.83 9.31-1.45 6.44-1.29 9.85.47 12 6.49 1.2 3.27 2.35 6.56 3.51 9.85 2.43 6.89-3.07 15.54-12.33 18.11-8.75 2.44-17.74 4-26.63 6-5.09 1.11-10.3 2.21-15.46 3.32Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M90.17 99.09S87.16 94 84.3 87.8a12.66 12.66 0 0 1-1.25-6 133.26 133.26 0 0 0-33.52 5.6s2.54 7.28 5.93 14.9c1.76 4 3.89 5.31 5.53 5.69l6.14-1.33c8.64-1.89 17.38-3.46 25.9-5.78a3 3 0 0 1-2.86-1.79Z'
          fillOpacity={0.2}
        />
        <Path
          d='M102.58 72.72a12.18 12.18 0 0 0-3-4.93c-1.68-.87-5.36-2-9.13 1.63-5.12 5-6.18 8-6.18 8s-2.84 4.25 0 10.4 5.87 11.29 5.87 11.29a3 3 0 0 0 2.86 1.77l.73-.2c9.26-2.57 14.76-11.22 12.33-18.11-1.13-3.29-2.28-6.57-3.48-9.85Z'
          fillOpacity={0.3}
        />
        <G fillOpacity={0.1}>
          <Path d='M100.51 68.77a9.75 9.75 0 0 1 .74 1.08 9.75 9.75 0 0 0-.74-1.08ZM83.05 81.83a9.31 9.31 0 0 1 1.25-4.43s1.06-3 6.18-8c3.77-3.66 7.45-2.5 9.13-1.63-2.11-2-5-2.38-9.07-1.56-3.08.62-6.2 1-9.31 1.45a5.77 5.77 0 0 1-2.29-.06A71.8 71.8 0 0 0 67.79 72c-8.91 4.24-18.26 15.4-18.26 15.4A133.26 133.26 0 0 1 83 81.82ZM102 71.34c.19.43.38.88.56 1.38-.16-.5-.35-.95-.56-1.38ZM100.42 68.66a6.73 6.73 0 0 0-.81-.87 7.45 7.45 0 0 1 .81.87ZM101.26 69.86Z' />
        </G>
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M79 67.63v.6a9.29 9.29 0 0 0 5.27 9.27l.06-.1s1.06-3 6.18-8c3.77-3.66 7.45-2.5 9.13-1.63-2.11-2-5-2.38-9.07-1.56-3.08.62-6.2 1-9.31 1.45a5.68 5.68 0 0 1-2.26-.03Z'
        />
        <Path
          d='M53 83.67 17.05 24.29a49.37 49.37 0 0 1 21.47-11.84c.53-.13 4.34-2.36 7.37 3s29.75 49.3 29.75 49.3.44 1.47 3.36 2.85c-1.67.76-12 1.71-26 16.07Z'
          fillOpacity={0.05}
        />
        <Path fillOpacity={0.2} d='M13.08 27.32 49.53 87.4 53 83.67 17.05 24.29a14.36 14.36 0 0 0-3.97 3.03Z' />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M34.49 16.3c2.29-1.14 6.75-2.94 9.37-1.12 2.51 3.66 23.18 38 23.18 38S48.1 21 34.59 17.38a.58.58 0 0 1-.1-1.08Z'
        />
      </G>
    </G>
  </Svg>
);

export default Inhaler;
