import { StyleSheet } from 'react-native';
import styled, { useTheme } from 'styled-components/native';

import { BaseCard } from 'style/common';

export const useStyles = () => {
  const { width = 0, isTablet, isMobile } = useTheme();

  return StyleSheet.create({
    badgeStyles: {
      position: 'absolute',
      top: isTablet ? -131 : -80,
      right: isTablet ? (isMobile ? 60 : 0) + (width - 375) / 2 : 20,
    },
  });
};

export const CircularBadgeContainer = styled(BaseCard)`
  border-radius: 98px;
  width: 196px;
  height: 196px;
  justify-content: center;
  align-items: center;
`;

export const BadgeImageWrapper = styled.View`
  width: 100%;
  height: 100%;
`;
