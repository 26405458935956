import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { MODAL_PADDING } from 'style/sizes';
import { ModalSizes } from 'style/types';
import { getModalSizes } from 'style/utils';

export const useConfirmModalStyles = (modalSize: ModalSizes) => {
  const { colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    modalContainer: {
      paddingTop: MODAL_PADDING,
      ...getModalSizes(theme)[modalSize],
    },
    title: {
      ...fontSizes[16],
      fontFamily: fontFamilies.bold,
    },
    description: {
      ...fontSizes[16],
      color: colors.gray2,
      marginTop: 8,
    },
    buttonsContainer: {
      flexDirection: 'row',
      marginTop: 32,
    },
  });
};
