import {
  SET_MFA_CODE_WAS_SEND,
  SET_MFA_COUNTING_DOWN,
  SET_MFA_INFO,
  SET_MFA_SESSION,
  SET_MFA_USER_PHONE_NUMBER,
} from 'store/action-types';
import { SetMFACodeWasSend, SetMFACountingDown, SetMFAInfo, SetMFASession, SetMFAUserPhoneNumber } from 'store/actions';
import { AppActionCreator } from 'utils/types';

export const setMFAInfo: AppActionCreator<SetMFAInfo> = (payload) => ({
  type: SET_MFA_INFO,
  payload,
});

export const setMFASession: AppActionCreator<SetMFASession> = (payload) => ({
  type: SET_MFA_SESSION,
  payload,
});

export const setMFACodeWasSend: AppActionCreator<SetMFACodeWasSend> = (payload) => ({
  type: SET_MFA_CODE_WAS_SEND,
  payload,
});

export const setMFACountingDown: AppActionCreator<SetMFACountingDown> = (payload) => ({
  type: SET_MFA_COUNTING_DOWN,
  payload,
});

export const setMFAUserPhoneNumber: AppActionCreator<SetMFAUserPhoneNumber> = (payload) => ({
  type: SET_MFA_USER_PHONE_NUMBER,
  payload,
});
