import React from 'react';

import { View } from 'react-native';

import { Button } from 'components/Button';
import { DefaultText, PrimaryDefaultText } from 'style/common';
import { AnyFunction } from 'utils/types';

import { TEXTS } from './constants';
import { useThanksStyles } from './styles';

type ThanksProps = {
  exitClickHandler: AnyFunction;
};

export const Thanks = ({ exitClickHandler }: ThanksProps) => {
  const styles = useThanksStyles();

  return (
    <View style={styles.wrapper}>
      <PrimaryDefaultText style={styles.firstTextBlock}>{TEXTS.thanks}</PrimaryDefaultText>
      <DefaultText style={[styles.thirdTextBlock, styles.mTop20]}>{TEXTS.weWillBeInTouch}</DefaultText>
      <View style={styles.submitButtonWrapper}>
        <Button variant='secondary' style={styles.exitButton} text={TEXTS.exit} onPress={exitClickHandler} />
      </View>
    </View>
  );
};

export default Thanks;
