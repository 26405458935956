import { toCorrectDatePickerFormat } from 'utils/dateTimeHelpers';

import { MedicationFormValues } from './types';

export const medicationStepDefaultValues: MedicationFormValues = {
  name: '',
  type: '',
  dosageAmount: '',
  totalDose: '',
  dateStarted: toCorrectDatePickerFormat(new Date()),
  quantity: '',
};

export const reminderStepDefaultValues = {
  medicationId: '',
  recurring: '',
  time: '',
  hour: '',
  start_date: new Date(),
};
