import { toInsuranceCard } from 'api/Insurance/mappers';
import { UserInsuranceReturnData, UploadInsuranceImagesParams } from 'api/Insurance/types';
import apiRequest, { apiPrefix } from 'utils/network';

const USER_INSURANCES_URL = '/user/insurances';

export const InsuranceInterface = {
  getInsurances: () =>
    apiRequest<UserInsuranceReturnData>({
      method: 'GET',
      endpoint: USER_INSURANCES_URL,
      baseURL: apiPrefix,
      isProgram: true,
    }).then((res) => res.data?.map(toInsuranceCard) || []),
  uploadInsuranceImages: (body: UploadInsuranceImagesParams) =>
    apiRequest({
      method: 'POST',
      body: body,
      endpoint: USER_INSURANCES_URL,
      baseURL: apiPrefix,
      isProgram: true,
    }),
};
