import { schema, NormalizedSchema } from 'normalizr';

import { AppConsent, ProgramConsent, ServiceConsent } from 'store/reducers/user-info/types/consent';

export const consentSchema = new schema.Entity<AppConsent | ProgramConsent | ServiceConsent>('consents');

type entityKeys = 'consents';

export type NormalizedConsentsSchema<T> = NormalizedSchema<
  {
    [k in entityKeys]: {
      [key: string]: T;
    };
  },
  string[]
>;
