import {
  SET_DEMO_ADDITIONAL_SERVICES_STATE,
  SET_DEMO_FORWARD_BUTTON_STATE,
  SET_DEMO_MILESTONE_TRACKER_STATE,
  SET_SHOWED_MEDICATION_ITEM_DEMO_ANIMATION,
  SET_SHOWED_REMINDER_ITEM_DEMO_ANIMATION,
} from 'store/action-types';
import {
  SetDemoAdditionalServicesStateAction,
  SetDemoForwardButtonStateAction,
  SetDemoMilestoneTrackerStateAction,
  SetShowedMedicationItemDemoAnimationAction,
  SetShowedReminderItemDemoAnimationAction,
} from 'store/actions';
import { AppActionCreator } from 'utils/types';

export const setDemoForwardButtonState: AppActionCreator<SetDemoForwardButtonStateAction> = (payload) => ({
  type: SET_DEMO_FORWARD_BUTTON_STATE,
  payload,
});

export const setShowedMedicationItemDemoAnimation: AppActionCreator<SetShowedMedicationItemDemoAnimationAction> = (
  payload
) => ({
  type: SET_SHOWED_MEDICATION_ITEM_DEMO_ANIMATION,
  payload,
});

export const setShowedReminderItemDemoAnimation: AppActionCreator<SetShowedReminderItemDemoAnimationAction> = (
  payload
) => ({
  type: SET_SHOWED_REMINDER_ITEM_DEMO_ANIMATION,
  payload,
});

export const setDemoAdditionalServicesState: AppActionCreator<SetDemoAdditionalServicesStateAction> = (payload) => ({
  type: SET_DEMO_ADDITIONAL_SERVICES_STATE,
  payload,
});

export const setDemoMilestoneTrackerState: AppActionCreator<SetDemoMilestoneTrackerStateAction> = (payload) => ({
  type: SET_DEMO_MILESTONE_TRACKER_STATE,
  payload,
});
