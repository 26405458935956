import { SET_APP_DATA_LOADING, SET_APP_THEME, SET_HAS_MODIFICATIONS } from 'store/action-types';
import { AvailableActionsForGlobalState } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { InitialReducerState } from './types';

const appGlobalState = (state = INITIAL_STATE, action: AvailableActionsForGlobalState): InitialReducerState => {
  switch (action.type) {
    case SET_APP_DATA_LOADING:
      return {
        ...state,
        appDataIsLoading: action.payload,
      };

    case SET_APP_THEME:
      return {
        ...state,
        appTheme: action.payload,
      };
    case SET_HAS_MODIFICATIONS:
      return {
        ...state,
        hasModifications: action.payload,
      };
    default:
      return state;
  }
};

export default appGlobalState;
