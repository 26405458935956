import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { screenContentWidth } from 'style/utils';

const EditSectionRow = ({ verticalSpacing = 0 }: { verticalSpacing?: number }) => {
  const { isMobile } = useDeviceInfoContext();
  const inputYPosition = verticalSpacing + 24;

  if (isMobile) {
    return (
      <>
        <Rect x='0' y={verticalSpacing} rx='6' ry='6' width='80' height='16' />
        <Rect x='0' y={inputYPosition} rx='6' ry='6' width={screenContentWidth} height='53' />
      </>
    );
  }

  return (
    <>
      <Rect x='0' y={verticalSpacing} rx='6' ry='6' width='80' height='16' />
      <Rect x='0' y={inputYPosition} rx='6' ry='6' width='48%' height='53' />
      <Rect x='50%' y={verticalSpacing} rx='6' ry='6' width='80' height='16' />
      <Rect x='50%' y={inputYPosition} rx='6' ry='6' width='48%' height='53' />
    </>
  );
};

const YourDosePlaceHolder = () => {
  const theme = useTheme();

  return (
    <ContentLoader
      foregroundColor={theme.colors.gray4}
      backgroundColor={theme.colors.gray3}
      width={screenContentWidth}
      style={{ minHeight: 300 }}
      height={'100%'}>
      <EditSectionRow />
      <EditSectionRow verticalSpacing={100} />
      <EditSectionRow verticalSpacing={200} />
    </ContentLoader>
  );
};

export default YourDosePlaceHolder;
