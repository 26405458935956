import styledWeb from 'styled-components';

export const WebDatePickerContainer = styledWeb('div')`
  .react-datepicker-popper{
    z-index: 100;
  }

  .react-datepicker__tab-loop{
    z-index: 100;
  }

  input {
    width: calc(100% - 20px); // 20px = paddings & border width
    padding: 0px 2px 0px 16px;
    display: flex;
    align-items: center;
    height: 51px;
    border-radius: 8px;
    border: 1px solid #CDD6E1;
    outline-style: none;
    z-index: 100;
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 20px;
    &:hover, &:focus {
      border-color: #1D242D;
    }
  }
`;
