import { createSelector } from 'reselect';

import { GeneralAdditionalServicesState } from 'store/reducers/additional-services/types';

// Internal selectors:
const _getCurrentlyActiveService = (state: GeneralAdditionalServicesState) =>
  state.additionalServices?.currentlyActiveService;
//

export const getShippingAddressInfo = (state: GeneralAdditionalServicesState) =>
  state.additionalServices?.shippingAddressInfo;

export const getActions = (state: GeneralAdditionalServicesState) => state.additionalServices.actions;

export const getDigitalResources = (state: GeneralAdditionalServicesState) => state.additionalServices.digitalResources;

export const getRequests = (state: GeneralAdditionalServicesState) => state.additionalServices.requests;

export const getAllResources = createSelector(
  [getActions, getDigitalResources, getRequests],
  (actions, digitalResources, serviceRequests) => [...actions, ...digitalResources, ...serviceRequests]
);

export const getCompletedAction = (state: GeneralAdditionalServicesState) =>
  state.additionalServices.actions.find((action) => action.isCompleted);

export const isActionCompleted = (selectedActionId: number) => (state: GeneralAdditionalServicesState) =>
  !!state.additionalServices.actions.find((action) => action.id === selectedActionId && action.isCompleted);

export const getViewedDigitalResource = (state: GeneralAdditionalServicesState) =>
  state.additionalServices.digitalResources.find((digitalResource) => digitalResource.isCompleted);

export const isDigitalResourceCompleted =
  (selectedDigitalResourceId: number) => (state: GeneralAdditionalServicesState) =>
    !!state.additionalServices.digitalResources.find(
      (digitalResource) => digitalResource.id === selectedDigitalResourceId && digitalResource.isCompleted
    );

export const getCompletedRequest = (state: GeneralAdditionalServicesState) =>
  state.additionalServices.requests.find((request) => request.isCompleted);

export const isRequestCompleted = (selectedRequestId: number) => (state: GeneralAdditionalServicesState) =>
  !!state.additionalServices.requests.find((request) => request.id === selectedRequestId && request.isCompleted);

export const getAdditionalServices = (state: GeneralAdditionalServicesState) => state.additionalServices;

export const getSelectedAction = (state: GeneralAdditionalServicesState) =>
  state.additionalServices?.actions?.find((action) => action.isSelected);

export const getSelectedDigitalResource = (state: GeneralAdditionalServicesState) =>
  state.additionalServices?.digitalResources?.find((digitalResource) => digitalResource.isSelected);

export const getSelectedRequest = (state: GeneralAdditionalServicesState) =>
  state.additionalServices?.requests?.find((request) => request.isSelected);

export const getCurrentlyActiveService = createSelector(_getCurrentlyActiveService, (service) => service);

export const getResourcesNotificationIconVisibility = (state: GeneralAdditionalServicesState) =>
  state.additionalServices?.resourcesNotificationIconVisibility;
