import React from 'react';

import { Controller, Control, FieldError, UseControllerProps } from 'react-hook-form';
import { View, ViewStyle } from 'react-native';

import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';
import TextFieldWithLabel from 'components/TextFieldWithLabel';
import { TimePicker } from 'components/TimePicker';
import { ErrorText } from 'style/common';

import { sharedStyles } from './styles';
import { InputTypes, ReminderFields, Option } from './types';

interface FieldBlockProps {
  fieldName: keyof ReminderFields;
  labelText: string;
  showInfo?: boolean;
  isHomeScreen?: boolean;
  defaultValue?: Date | string;
  inputType: InputTypes;
  options?: Option[];
  disabled?: boolean;
  rules?: UseControllerProps['rules'];
  control: Control<ReminderFields, Record<any, any>>;
  error?: FieldError;
  isConditionalInput?: boolean;
  shouldRender?: boolean;
  wrapperStyle?: ViewStyle;
  shouldShowLabel?: boolean;
  placeHolderText?: string;
}

export const FieldBlockComponent = ({
  isHomeScreen,
  fieldName,
  labelText,
  showInfo = false,
  inputType,
  defaultValue,
  disabled,
  rules,
  control,
  error,
  options,
  isConditionalInput,
  shouldRender,
  wrapperStyle,
  shouldShowLabel = true,
  placeHolderText,
}: FieldBlockProps) => {
  // shouldRender prop is applicable if field is conditionalInput
  if (isConditionalInput && !shouldRender) return null;
  return (
    <Controller
      name={fieldName}
      defaultValue={defaultValue}
      rules={rules}
      control={control}
      render={({ field: { onChange, value } }) => {
        const onNumberChange = (text: string) => {
          const regex = new RegExp('^[1-9][0-9]*$');
          if (!text) onChange('');
          regex.test(text) && onChange(text);
        };
        switch (inputType) {
          case 'Select': {
            return (
              <View style={[wrapperStyle, isConditionalInput && sharedStyles.conditionalInput]}>
                <SelectBox
                  value={value}
                  labelText={labelText}
                  showInfo={showInfo}
                  disabled={disabled}
                  items={options || []}
                  onValueChange={onChange}
                  shouldShowLabel={shouldShowLabel}
                  placeHolderText={placeHolderText}
                />
                {!!error && <ErrorText>{error.message}</ErrorText>}
              </View>
            );
          }
          case 'TimePicker': {
            return (
              <>
                <TimePicker
                  isHomeScreen={isHomeScreen}
                  onChange={onChange}
                  value={value}
                  labelText={labelText}
                  showInfo={showInfo}
                />
                {!!error && <ErrorText>{error.message}</ErrorText>}
              </>
            );
          }
          case 'DatePicker': {
            return (
              <>
                <DatePicker
                  minDate={new Date()}
                  onChange={onChange}
                  value={value ? (value as Date) : new Date()}
                  labelText={labelText}
                />
                {!!error && <ErrorText>{error.message}</ErrorText>}
              </>
            );
          }
          default: {
            return (
              <View style={isConditionalInput && sharedStyles.conditionalInput}>
                <TextFieldWithLabel
                  maxLength={2}
                  keyboardType='numeric'
                  labelText={labelText}
                  onChangeText={onNumberChange}
                  value={String(value)}
                  showInfo={showInfo}
                />
                {!!error && <ErrorText>{error.message}</ErrorText>}
              </View>
            );
          }
        }
      }}
    />
  );
};

export default FieldBlockComponent;
