import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import {
  NoHaveDataConfig,
  NoHaveDataConfigItem,
} from 'screens/main/MedicationTracker/components/DailyMedications/components/types';
import { setReminderModalVisibility, setSelectedMedicationId } from 'store/actions';
import { getHasMedications } from 'store/selectors/medicine-cabinet';
import { isEmpty } from 'utils/conditions';
import { getIsPastDate, getTimestampForCurrentDateUTC } from 'utils/dateTimeHelpers';

const getConfig = (dispatch: Dispatch): NoHaveDataConfig => {
  return {
    addMedication: {
      actionButtonText: 'Add Medication',
      infoText: 'Looks like you haven’t added any medications',
      // We haven't onPress here, because in CabinetE mpty component by default will open Add Medication Modal
    },
    addReminder: {
      actionButtonText: 'Add Reminder',
      infoText: 'Looks like you haven’t added any reminders',
      onPress: () => {
        dispatch(setSelectedMedicationId(null));
        setTimeout(() => dispatch(setReminderModalVisibility(true)));
      },
    },
    pastDayWithoutReminders: {
      infoText: 'You did not take any medications on this day',
    },
  };
};

export const useNoHaveDataConfig = (selectedDate: number, selectedMedicationId: string) => {
  const [config, setConfig] = useState<NoHaveDataConfigItem>();

  const dispatch = useDispatch();

  const hasMedications = useSelector(getHasMedications);

  useEffect(() => {
    const { addMedication, addReminder, pastDayWithoutReminders } = getConfig(dispatch);

    const isPastDay = getIsPastDate(selectedDate, getTimestampForCurrentDateUTC());

    // strange estimate (we can't add reminder when we daily select medication id
    const isSelectedMedicationId = !isEmpty(selectedMedicationId, {}, ['0']);

    const timeout = setTimeout(() => {
      if ((hasMedications && isPastDay) || isSelectedMedicationId) setConfig(pastDayWithoutReminders);
      else setConfig(hasMedications ? addReminder : addMedication);
    }, 100);

    return () => clearTimeout(timeout);
  }, [dispatch, hasMedications, selectedDate, selectedMedicationId]);

  return config;
};
