import { SET_EXTERNAL_PARTNER_PROGRAM_UUID } from 'store/action-types';
import { RouteInfoActions } from 'store/actions/routeInfo/types';

import { INITIAL_STATE } from './intialState';
import { RouteInfoState } from './types';

const routeInfo = (state = INITIAL_STATE, action: RouteInfoActions): RouteInfoState => {
  switch (action.type) {
    case SET_EXTERNAL_PARTNER_PROGRAM_UUID:
      return {
        ...state,
        externalPartnerProgramUuid: action.payload,
      };
    default:
      return state;
  }
};

export default routeInfo;
