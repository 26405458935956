import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Strip = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 115.94 88.34' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M115.91 63c0 2.27.09 3.44 0 4.59-.65 7.07-1.74 8.37-8.58 10.08-13.68 3.38-27.33 6.73-41 10.18-3.22.82-4.84 1.15-3.8-3.47 3.52-15.65-.79-27.13-12.85-33.89-5.45-3.06-11.68-4.76-17.39-7.38C15.93 35.62 4.08 24.2.45 5.79-.57.59-.19 0 5.1 0c16.12 0 32.2 1.65 48.33 1 2.33-.09 4.45.17 4.51 3.25.17 8.85 6.1 14 12.34 18.81 5.75 4.44 12.81 6.19 19.38 9 11.82 5 22.07 11.59 25.56 25.15a26.17 26.17 0 0 1 .69 5.79Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fill='#fff'
          fillOpacity={0.2}
          d='M60.48 56.39a19.93 19.93 0 0 1 5.63 10.68 2.89 2.89 0 0 0 3.24 2.47c8.55-1.2 32.17-5.6 40.25-7.12a2.87 2.87 0 0 0 2.27-3.42 31.72 31.72 0 0 0-22.29-23.85 2.83 2.83 0 0 0-2.29.29C82.66 38.19 67.64 47.7 61 51.93a2.88 2.88 0 0 0-.52 4.46Z'
        />
        <Circle fill='#fff' fillOpacity={0.2} cx={99.23} cy={46.89} r={3.23} />
        <Path
          d='M67.11 24.4c-3.6-2.74-9.32-7.61-11.2-12.1a2.2 2.2 0 0 0-2.16-1.3c-8.63.49-33.37 2-42.44 2.59a2.23 2.23 0 0 0-1.83 3.29C13 23.32 22.34 37.94 36.42 41.15a2.18 2.18 0 0 0 1.31-.15c6-2.39 21.89-9.6 29-12.83a2.24 2.24 0 0 0 .38-3.77Z'
          fillOpacity={0.05}
        />
      </G>
    </G>
  </Svg>
);

export default Strip;
