import { Bell, WarningCircle } from 'assets/icons';

import { CabinetInfoConstantType } from './types';

export const cabinetInfoMapping: CabinetInfoConstantType = {
  notify: {
    defaultInfoText: 'Time to take Cizplam',
    DefaultIcon: Bell,
    actionButtons: {
      first: {
        buttonText: 'Skip',
      },
      second: {
        buttonText: 'Taken',
      },
    },
  },
  warning: {
    defaultInfoText: 'Two of your medications interact with each other',
    DefaultIcon: WarningCircle,
    actionButtons: {
      first: {
        buttonText: 'Close',
      },
      second: {
        buttonText: 'Learn More',
      },
    },
  },
};
