import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getSelectedProgram } from 'store/selectors';
import { isNativeDevice } from 'utils/helpers';

const getFaviconLinks = () =>
  Object.values(document.getElementsByTagName('link')).filter((el) => el.href.includes('favicon'));

export const useChangeFavicon = (favicon?: string) => {
  const program = useSelector(getSelectedProgram);

  const brandEmblem = program?.white_labeling_conf.brandEmblem || favicon;

  useEffect(() => {
    if (isNativeDevice || !brandEmblem) return;

    getFaviconLinks().forEach((el) => (el.href = brandEmblem));
  }, [brandEmblem]);
};
