import React from 'react';

import { ImageSourcePropType, Pressable, View } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Radio } from 'components/RadioButton';
import { useImageSizes } from 'hooks';
import { StyledImage } from 'style/common';
import { defaultTheme } from 'style/theme';
import { getNativeUrl } from 'utils/white-labeling';

import { getStyles } from './styles';

type PopupItemProps = {
  id: number;
  programUuid: string;
  logoUrl?: string;
  isSelected?: boolean;
  onProgramChange: (id: number, programUuid: string) => void;
  isLastItem?: boolean;
};

export const PopupItem = ({ id, logoUrl, isSelected, onProgramChange, isLastItem, programUuid }: PopupItemProps) => {
  const theme = useTheme();

  const { imageWidth } = useImageSizes({ url: logoUrl });

  const onChangeHandler = () => {
    onProgramChange(id, programUuid);
  };

  const styles = getStyles();
  const borderStyles = { borderColor: theme.colors.gray3, borderBottomWidth: isLastItem ? 0 : 1 };

  return (
    <Pressable onPress={onChangeHandler} style={[styles.popupItemWrapper, borderStyles]}>
      <Radio activeColor={defaultTheme.colors.mangoSecondary} isChecked={!!isSelected} />
      <View style={[styles.drawerLogo, { width: imageWidth }]}>
        <StyledImage resizeMode='cover' resizeMethod='resize' source={getNativeUrl(logoUrl)} />
      </View>
    </Pressable>
  );
};

export default PopupItem;
