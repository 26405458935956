import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Granule = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 112.95 114.66' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M112.05 56.29c-3.69-10.93-15.69-40.39-18.41-47A12.1 12.1 0 0 1 93 7c-.58-2.68-3.4-10.55-17.35-5.15C59 8.29 15.26 26.12 15.26 26.12s-7 2.23-5.3 7.2c0 0 7.82 23.61 15.07 42.59a4.43 4.43 0 0 1 2.14-.56 4.49 4.49 0 0 1 1.12 8.84c1.56 3.81 3 7.17 4.29 9.75a4.18 4.18 0 0 0 4.63 2.25 79.35 79.35 0 0 0 8.48-2.57c8-2.8 51.25-19.79 57.92-22.41a12.11 12.11 0 0 1 1.36-.47c2.19-.59 10.73-3.65 7.08-14.45Z'
          fill={props.fill || defaultTheme.colors.gray3}
        />
        <Circle fill='#efefef' cx={27.17} cy={79.85} r={4.5} />
        <Circle fill='#efefef' cx={24.81} cy={91.95} r={5.29} />
        <Circle fill='#efefef' cx={5.29} cy={101.71} r={5.29} />
        <Circle fill='#efefef' cx={17.65} cy={80.95} r={4.5} />
        <Circle fill='#efefef' cx={9.96} cy={87.45} r={3.18} />
        <Circle fill='#efefef' cx={15.26} cy={97.25} r={2.39} />
        <Circle fill='#efefef' cx={9.34} cy={112.27} r={2.39} />
        <Circle fill='#efefef' cx={16.59} cy={66.71} r={2.39} />
        <Path
          d='M11.8 33.1s20.14 26.5 22.72 59.69c6.2-1.28 59.14-21.87 59.14-21.87s16.27-5.92 11.11-22.13c-5.69-15.69-11.88-30.1-11.88-30.1S87.46 4 67.58 11.22 11.8 33.1 11.8 33.1Z'
          fillOpacity={0.1}
        />
        <Path
          d='M27.17 75.35a4.49 4.49 0 0 1 2 8.53 77.29 77.29 0 0 0 5.38 8.91C31.94 59.6 11.8 33.1 11.8 33.1s4.82 22.83 13.47 42.68a4.48 4.48 0 0 1 1.9-.43Z'
          fillOpacity={0.4}
        />
      </G>
    </G>
  </Svg>
);

export default Granule;
