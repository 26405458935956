import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useProfileStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    profileHeaderWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 21,
    },
    standardText: { color: colors.gray1 },
    insuranceInfoWrapper: { width: '100%' },
  });
};
