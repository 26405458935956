import { ViewStyle } from 'react-native';
import { DefaultTheme } from 'styled-components';
import { useTheme } from 'styled-components/native';

import { ALLOWED_MODAL_PADDINGS } from 'style/sizes';
import { defaultTheme } from 'style/theme';
import { GetAppShadowConfig, BreakpointTypes, Styles, ApplyBreakpointReturnType, ModalSizesRecord } from 'style/types';
import { isNativeDevice, screenWidth } from 'utils/helpers';

import { appThemeSizes, breakpoints } from './constants';

export const shadeColor = (color: string, decimal: number): string => {
  const base = color?.startsWith('#') ? 1 : 0;

  let r = parseInt(color.substring(base, 3), 16);
  let g = parseInt(color.substring(base + 2, 5), 16);
  let b = parseInt(color.substring(base + 4, 7), 16);

  r = Math.round(r / decimal);
  g = Math.round(g / decimal);
  b = Math.round(b / decimal);

  r = r < 255 ? r : 255;
  g = g < 255 ? g : 255;
  b = b < 255 ? b : 255;

  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16);
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16);
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16);

  return `#${rr}${gg}${bb}`;
};

export const isHexColor = (hex: string) => {
  return hex.length === 6 && !isNaN(Number('0x' + hex));
};

export const hex2rgba = (hex: string, alpha?: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const getAppShadow = ({ offset, opacity, radius, color, elevation }: GetAppShadowConfig = {}): ViewStyle => ({
  shadowColor: color ?? hex2rgba(defaultTheme.colors.gray2),
  shadowRadius: radius ?? 8,
  shadowOffset: offset ?? { width: 0, height: 0 },
  shadowOpacity: opacity ?? 1,
  elevation: elevation ?? 8,
});

/**
 * This function fill styles for all breakpoint if it absent
 * @example
 * const styles = {                  const styles = {
 *                          --->       sm:  { width: 100 },
 *   md:  { width: 100 },    --->      md:  { width: 100 },
 *                            --->     lg:  { width: 500 },
 *   xl:  { width: 500 },    --->      xl:  { width: 500 },
 *                          --->       xxl: {            },
 * }                                 }
 */
const getAllFilledBreakpointStyles = <T extends Styles>(
  styles: Partial<Record<BreakpointTypes, T>>,
  key: BreakpointTypes,
  index: number
): T =>
  styles[
    Object.keys(breakpoints)
      .slice(index)
      .find((breakpoint) => styles[breakpoint as BreakpointTypes]) as BreakpointTypes
  ] || ({} as T);

export const getStyleByBreakpoint = <T extends Styles>(
  { width = 0 }: Pick<DefaultTheme, 'width'>,
  styles: Record<BreakpointTypes, T>
): T => {
  if (width > breakpoints.xxl) return styles.xxl;

  const breakpointKeys = Object.keys(breakpoints);
  const currentBreakpoint = breakpointKeys.find(
    (key) => width <= breakpoints[key as BreakpointTypes]
  ) as BreakpointTypes;

  if (!currentBreakpoint) return {} as T;

  return styles[currentBreakpoint];
};

export const applyBreakpoints = <T extends Styles>(
  { width = 0 }: Pick<DefaultTheme, 'width'>,
  styles: Partial<Record<BreakpointTypes, Styles | T>>
): T => {
  const breakpointKeys = Object.keys(breakpoints);
  const allBreakpointsStyles = Object.fromEntries(
    breakpointKeys.map((key, i) => [key, getAllFilledBreakpointStyles(styles, key as BreakpointTypes, i)])
  ) as Record<BreakpointTypes, T>;

  return getStyleByBreakpoint({ width }, allBreakpointsStyles);
};

export const screenContentWidth = isNativeDevice ? screenWidth - 2 * appThemeSizes.contentHorizontalPadding : '100%';

export const useShowElementOn = (breakpoint: BreakpointTypes | BreakpointTypes[]): Pick<Styles, 'display'> => {
  const theme = useTheme();

  const breakpointStyles: Partial<Record<BreakpointTypes, Pick<Styles, 'display'>>> = {
    sm: { display: 'none' },
    md: { display: 'none' },
    lg: { display: 'none' },
    xl: { display: 'none' },
    xxl: { display: 'none' },
  };

  if (typeof breakpoint === 'string') breakpointStyles[breakpoint] = { display: 'flex' };
  else breakpoint.forEach((size) => (breakpointStyles[size] = { display: 'flex' }));

  return applyBreakpoints(theme, breakpointStyles);
};

// Modal
export const applySmallModalBreakpointsStyles = (theme: Pick<DefaultTheme, 'width'>): ApplyBreakpointReturnType =>
  applyBreakpoints(theme, {
    sm: { maxWidth: (theme.width || 0) - ALLOWED_MODAL_PADDINGS },
    md: { maxWidth: '50%' },
    lg: { maxWidth: '45%' },
    xl: { maxWidth: '35%' },
    xxl: { maxWidth: '25%' },
  });

export const getModalSizes = (theme: Pick<DefaultTheme, 'width'>): ModalSizesRecord => ({
  small: applySmallModalBreakpointsStyles(theme),
});
