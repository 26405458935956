import { schema, NormalizedSchema } from 'normalizr';

import { Survey, Question } from 'store/reducers/user-info/types/survey';

const questionsSchema = new schema.Entity<Question>('questions', {}, { idAttribute: 'uuid' });
export const surveySchema = new schema.Entity<Survey>(
  'surveys',
  {
    questions: [questionsSchema],
  },
  { idAttribute: 'user_survey_uuid' }
);

export type NormalizedSurveysSchema = NormalizedSchema<
  {
    surveys: Record<string, Survey>;
    questions: Record<string, Question>;
  },
  string[]
>;
