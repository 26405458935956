import apiRequest, { apiPrefix } from 'utils/network';

import {
  AdditionalServicesPostData,
  AdditionalServicesReturnData,
  AdditionalServicesNotificationsReturnData,
  GetUserHistoryCommunicationParams,
} from './types';

const PROGRAM_LEVEL_ADDITIONAL_SERVICES_URL = '/additional-services/data/info';
const UPDATE_ADDITIONAL_SERVICES_URL = '/additional-services/patient/';

const ADDITIONAL_SERVICES_NOTIFICATION = '/api/v1/communication/history/user';

export const AdditionalServicesInterface = {
  get: () => {
    return apiRequest<AdditionalServicesReturnData>({
      method: 'GET',
      endpoint: PROGRAM_LEVEL_ADDITIONAL_SERVICES_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    });
  },
  put: ({ userDataUuid, ...data }: AdditionalServicesPostData) => {
    return apiRequest<AdditionalServicesReturnData>({
      endpoint: UPDATE_ADDITIONAL_SERVICES_URL + userDataUuid,
      method: 'put',
      body: data,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    });
  },
  getNotifications: ({
    purpose = 'NEW_ADDITIONAL_SERVICE',
    channel = 'notification',
  }: GetUserHistoryCommunicationParams) => {
    return apiRequest<AdditionalServicesNotificationsReturnData>({
      method: 'GET',
      endpoint: ADDITIONAL_SERVICES_NOTIFICATION,
      baseURL: apiPrefix,
      params: {
        channel,
        purpose,
      },
    });
  },
  clearNotifications: (notificationIds: string[]) => {
    return apiRequest({
      method: 'PUT',
      endpoint: ADDITIONAL_SERVICES_NOTIFICATION,
      baseURL: apiPrefix,
      body: notificationIds,
    });
  },
};
