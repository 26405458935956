import { defaultSystemFonts } from 'react-native-render-html';

import { RenderHTMLStyleType } from 'screens/main/MedicineCabinet/sections/MedicationDetails/subSections/Details/types';
import { defaultTheme } from 'style/theme';

const textStyles = {
  ...defaultTheme.fontSizes[16],
  color: defaultTheme.colors.gray1,
  fontFamily: defaultTheme.fontFamilies.default,
};

export const systemFonts = [...defaultSystemFonts, 'Montserrat', 'Montserrat-SemiBold', 'Montserrat-Bold'];

export const idsStyles: RenderHTMLStyleType = {
  header: {
    display: 'none',
  },
};

export const tagStyles: RenderHTMLStyleType = {
  img: { display: 'none' },
  p: { margin: 0, padding: 0, paddingBottom: 10, ...textStyles },
  ul: { marginTop: 0 },
  li: { ...textStyles, paddingBottom: 6 },
  span: { ...textStyles },
};

export const classesStyles: RenderHTMLStyleType = {
  adultname: { display: 'none' },
  labels: {
    color: defaultTheme.colors.gray1,
    ...defaultTheme.fontSizes[16],
    fontFamily: defaultTheme.fontFamilies.bold,
  },
  underline: {
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    color: defaultTheme.colors.gray1,
    fontFamily: defaultTheme.fontFamilies.default,
  },
  box: { borderStyle: 'solid', borderWidth: 2, padding: 10, borderColor: 'red', borderRadius: 16, marginBottom: 12 },
};
