import { SelectionType } from 'api/AdditionalServicesInterface/types';
import { Answer } from 'components/Questionnaire';

export enum ServiceTypesEnum {
  FORM_REQUEST = 'FORM_REQUEST',
  RESOURCE = 'RESOURCE',
  PHYSICAL_SHIPMENT = 'PHYSICAL_SHIPMENT',
}

export type ServiceTypes =
  | ServiceTypesEnum.FORM_REQUEST
  | ServiceTypesEnum.PHYSICAL_SHIPMENT
  | ServiceTypesEnum.RESOURCE;

export enum ServiceIconTypesEnum {
  VIDEO = 'VIDEO',
  PACKAGE = 'PACKAGE',
  CLIPBOARD = 'CLIPBOARD',
  PHONE = 'PHONE',
  INJECTION = 'INJECTION',
}

export enum ServiceFileTypesEnum {
  PDF = 'PDF',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export type ServiceFileTypes = ServiceFileTypesEnum.IMAGE | ServiceFileTypesEnum.PDF | ServiceFileTypesEnum.VIDEO;

export enum CustomServiceTypeEnum {
  actions = 'actions',
  digitalResources = 'digitalResources',
  requests = 'requests',
}

export type CustomServiceType =
  | CustomServiceTypeEnum.actions
  | CustomServiceTypeEnum.requests
  | CustomServiceTypeEnum.digitalResources;

type ServiceConsent = {
  id: string;
  requiredToProceed: boolean;
  requiredToCommunicate: boolean;
  title: string;
  body: string;
  actionText: string;
  createdAt: string;
  updatedAt: string;
  resourceService: number;
  formRequestService: any; // Need to check available types
};

export interface Service {
  id: number;
  type: ServiceTypes;
  label?: string;
  modalTitle?: string;
  modalDescription?: string;
  isCompleted: boolean;
  isNew: boolean;
  userDataUuid: string;
  isSelected?: boolean;
  icon: ServiceIconTypesEnum;
  hasConsent: boolean;
  consentConfig: ServiceConsent[];
  repeatable: boolean;
}

export interface Action extends Service {
  question?: string;
  answers?: Answer[];
  selectionType?: SelectionType;
}

export interface DigitalResource extends Service {
  source?: string;
  fileType?: ServiceFileTypesEnum;
}

export interface Request extends Service {
  address?: any; //we'll be implementing this later.
}
export type ShippingAddressResponse = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  createdAt: string;
  id: number;
  patientId: number;
  state: string;
  zipcode: string;
};

export type InitialReducerState = {
  [CustomServiceTypeEnum.actions]: Action[];
  [CustomServiceTypeEnum.digitalResources]: DigitalResource[];
  [CustomServiceTypeEnum.requests]: Request[];
  currentlyActiveService?: { service: Action | DigitalResource | Request; customServiceType: CustomServiceTypeEnum };
  shippingAddressInfo?: ShippingAddressResponse;
  resourcesNotificationIconVisibility?: boolean;
};

export type GeneralAdditionalServicesState = {
  additionalServices: InitialReducerState;
};

export const customServiceTypeMap = {
  [ServiceTypesEnum.FORM_REQUEST]: CustomServiceTypeEnum.actions,
  [ServiceTypesEnum.PHYSICAL_SHIPMENT]: CustomServiceTypeEnum.requests,
  [ServiceTypesEnum.RESOURCE]: CustomServiceTypeEnum.digitalResources,
};
