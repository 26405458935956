import produce from 'immer';

import { RemoveSelectedSurvey } from 'store/actions/user/actionCreators/surveys/removeSelectedSurvey';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const removeSelectedSurvey = (state = INITIAL_STATE, payload: ReturnType<RemoveSelectedSurvey>['payload']): User => {
  const nextSurveyState = produce(state.surveys, (draftSurveys) => {
    delete draftSurveys.entities.surveys?.[payload.id];

    const index = draftSurveys.result.indexOf(payload.id);
    if (index > -1) {
      draftSurveys.result.splice(index, 1);
    }
  });
  return {
    ...state,
    surveys: nextSurveyState,
  };
};

export default removeSelectedSurvey;
