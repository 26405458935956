import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const getViewAgainActionBoxStyles = (isMobile?: boolean) =>
  StyleSheet.create({
    viewAgainContainer: {
      paddingHorizontal: 16,
      paddingVertical: 4,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 20,
      backgroundColor: defaultTheme.colors.gray2,
    },
    replayIconWrapper: {
      width: 16,
      height: 16,
      justifyContent: 'center',
      alignItems: 'center',
      ...(!isMobile && { marginRight: 8 }),
    },
    actionText: {
      ...defaultTheme.fontSizes[16],
      fontFamily: defaultTheme.fontFamilies.semiBold,
      color: '#FFF',
      marginLeft: 0,
    },
  });
