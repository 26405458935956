import * as ActionTypes from 'store/action-types';
import { SimpleTextNotificationTileType } from 'store/reducers/user-info/types/notification';

export type SetSimpleTextNotificationTiles = (payload: SimpleTextNotificationTileType[]) => {
  type: typeof ActionTypes.SET_SIMPLE_TEXT_NOTIFICATION_TILES;
  payload: typeof payload;
};

export const setSimpleTextNotificationTiles: SetSimpleTextNotificationTiles = (payload) => ({
  type: ActionTypes.SET_SIMPLE_TEXT_NOTIFICATION_TILES,
  payload,
});
