import { ComponentType } from 'react';

import { useSelector } from 'react-redux';

import { usePageAvailable } from 'hooks/usePageAvailable';
import { Routes } from 'routes/main';
import { AdditionalServices, Benefits, FinancialAssistance, Home, Profile, RebateCenter } from 'screens/main';
import { DemoBenefitsPage } from 'screens/main/Benefits/DemoBenefitsPage';
import { MedicationTracker } from 'screens/main/MedicationTracker';
import { TabTypesEnum } from 'store/reducers/medicine-cabinet/types';
import { getFinancialAssistanceData, getIsCopayUser, getSelectedProgram, getUserHasPsp } from 'store/selectors';
import { getIsDemoUser } from 'store/selectors/user';

import { MedicineCabinetStack } from './MedicineCabinetStack';

type Screen = {
  name: Routes;
  component: ComponentType<any>;
  tabName?: string;
};

export const screenTitles: Record<Routes | TabTypesEnum, string> = {
  [Routes.HOME]: 'Home | PatientLink',
  [Routes.TRACKER]: 'Medicine Tracker',
  [Routes.PROFILE]: 'Profile',
  [Routes.DETAILS]: 'Details',
  [Routes.INTERACTION_DETAILS]: 'Interaction',
  [Routes.FINANCIAL_ASSISTANCE]: 'Financial Assistance',
  [Routes.RESOURCES]: 'Additional Services',
  [TabTypesEnum.ABOUT_YOUR_DOSE]: 'About Your Dose',
  [TabTypesEnum.DRUG_INTERACTIONS]: 'Drug Interactions',
  [TabTypesEnum.DETAILS]: 'Details',
  [Routes.REBATE_CENTER]: 'Rebate Center',
  [Routes.MEDICINE_CABINET]: 'Medicine Cabinet',
  [Routes.LIST]: 'Medicine Cabinet',
  [Routes.MED_INTERACTION_DETAILS]: 'Medicine Cabinet | Interaction',
  [Routes.MED_DETAILS]: 'Medicine Cabinet | Details',
  [Routes.MED_LIST]: 'Medicine Cabinet | List',
  [Routes.LOGIN]: 'Login',
  [Routes.SET_PASSWORD]: 'Set Password',
  [Routes.BENEFITS]: 'Benefits',
  [Routes.NOT_FOUND]: 'Page Not Found',
  [Routes.APP_NAVIGATOR]: 'Mango App',
  [Routes.SCREENS]: 'Screens',
  [Routes.PRIVACY_POLICY]: 'Privacy Policy',
  [Routes.TERMS_CONDITIONS]: 'Terms and Conditions',
  [Routes.TWO_FACTOR_AUTHENTICATION]: 'Two Factor Authentication',
};

const createScreen = (route: Routes, component: ComponentType<any>, tabName?: string): Screen => ({
  tabName: tabName || screenTitles[route] || route,
  component,
  name: route,
});

export const useGetAppScreens = (): Screen[] => {
  const userHasPsp = useSelector(getUserHasPsp);
  const isDemoUser = useSelector(getIsDemoUser);
  const isCopayUser = useSelector(getIsCopayUser);
  const program = useSelector(getSelectedProgram);
  const financialAssistanceData = useSelector(getFinancialAssistanceData);

  const { benefitsPageAvailable } = usePageAvailable('benefits');
  const { rebateCenterPageAvailable } = usePageAvailable('rebateCenter');

  const showFinancialAssistanceScreen = Boolean(financialAssistanceData);
  const showBenefitsScreen = Boolean(benefitsPageAvailable && !isCopayUser);
  const showRebateCenterScreen = Boolean(rebateCenterPageAvailable && !isCopayUser);

  const hasPspScreens = [
    createScreen(Routes.HOME, Home, `${program?.display_name ? program.display_name + ' |' : ''} Home Page`),
    showFinancialAssistanceScreen && createScreen(Routes.FINANCIAL_ASSISTANCE, FinancialAssistance),
    showBenefitsScreen && createScreen(Routes.BENEFITS, isDemoUser ? DemoBenefitsPage : Benefits),
    createScreen(Routes.RESOURCES, AdditionalServices),
    showRebateCenterScreen && createScreen(Routes.REBATE_CENTER, RebateCenter),
    createScreen(Routes.PROFILE, Profile),
    createScreen(Routes.MEDICINE_CABINET, MedicineCabinetStack),
    createScreen(Routes.TRACKER, MedicationTracker),
  ].filter(Boolean) as Screen[];

  return userHasPsp ? hasPspScreens : noPspScreens;
};

const noPspScreens: Screen[] = [
  createScreen(Routes.HOME, MedicationTracker),
  createScreen(Routes.MEDICINE_CABINET, MedicineCabinetStack),
  createScreen(Routes.PROFILE, Profile),
];
