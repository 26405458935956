import React, { Fragment, useEffect, useRef } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation, useRoute } from '@react-navigation/native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { MedicineCabinetInterface } from 'api';
import { Spacer } from 'components';
import { Layout } from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import { ModalComponent as Modal } from 'components/Modal';
import { ReminderModal } from 'components/ReminderModal';
import { Survey } from 'components/Survey';
import { SurveyCard } from 'components/SurveyCard';
import { useNotificationHandler } from 'hooks';
import { useFirstEffect } from 'hooks/useFirstEffect';
import FinancialAssistanceOverview from 'screens/main/Home/components/FinancialAssistanceOverview';
import SpecialtyPharmacyCard from 'screens/main/Home/components/SpecialtyPharmacyCard';
import { homeAnalyticsActions } from 'screens/main/Home/constants';
import { landingPageAnalyticsHandler } from 'screens/main/Home/helpers';
import { HomePageQueryString, ProgramType, SourceType } from 'screens/main/Home/types';
import {
  removeSelectedSurveyId,
  setReminderModalVisibility,
  setReminders,
  setShouldForceSurveyOpen,
  setShouldShowSurvey,
} from 'store/actions';
import {
  getAllSurveysIds,
  getAppDataIsLoading,
  getCurrentlyActiveConsent,
  getFinancialAssistanceData,
  getIsCopayUser,
  getIsPSPMedicationHasBeenAdded,
  getMilestoneTrackers,
  getPSPReminderTemplate,
  getSelectedProgram,
  getShouldForceSurveyOpen,
  getShouldProposeTutorial,
  getShouldShowSurvey,
  getTourStatus,
  getUserFirstName,
  getUserHasPsp,
} from 'store/selectors';
import { getPSPMedicationWithoutReminder } from 'store/selectors/medicine-cabinet';
import { getIsDemoUser, getSimpleTextNotificationTiles } from 'store/selectors/user';

import {
  AdditionalServicesSection,
  DemoAdditionalServicesCard,
  DemoMilestoneTracker,
  EntreatyTourModal,
  MilestoneTracker,
  NotificationTiles,
  SimpleTextNotificationTile,
  WelcomeCard,
} from './components';
import { useHomePageStyles } from './style';

const useCardsToShow = (): JSX.Element[] => {
  const isCopayUser = useSelector(getIsCopayUser);
  const allSurveysIds = useSelector(getAllSurveysIds);
  const milestoneTrackers = useSelector(getMilestoneTrackers);
  const simpleTextNotificationTiles = useSelector(getSimpleTextNotificationTiles);
  const isDemoUser = useSelector(getIsDemoUser);

  const demoCards = isDemoUser && !isCopayUser ? [<DemoMilestoneTracker />, <DemoAdditionalServicesCard />] : [];

  const cardsList = [
    simpleTextNotificationTiles.map((tile) => (
      <SimpleTextNotificationTile key={tile.id} tile={tile} isVisible={!tile.isDeleted} />
    )),
    // TODO(remove): need to remove and use SimpleNotificationTile
    // <MissingInsuranceCard />,
    // !isCopayUser && <BenefitsCard />,
    // <FinancialAssistanceNotificationTile />,
    ...demoCards,
    ...(isCopayUser
      ? []
      : milestoneTrackers.map((tracker) => <MilestoneTracker key={tracker.uuid} trackerData={tracker} />)),
    <WelcomeCard />,
    <SpecialtyPharmacyCard />,
  ].filter(Boolean) as JSX.Element[];

  if (!(allSurveysIds?.length > 0)) return cardsList;

  const surveyCards = allSurveysIds.map((surveyId) => <SurveyCard surveyId={surveyId} />);

  return [cardsList[0], ...surveyCards, ...cardsList.slice(1)];
};

const {
  VIEW_LANDING_PAGE,
  USER_ENROLLED_TO_THE_FAP_FROM_SMS,
  USER_ENROLLED_TO_THE_FAP_FROM_EMAIL,
  USER_ENROLLED_TO_THE_PSP_FROM_SMS,
  USER_ENROLLED_TO_THE_PSP_FROM_EMAIL,
} = homeAnalyticsActions;

const getKeySourceAction = (type: ProgramType): Record<SourceType, string> => ({
  sms: type === 'HUB' ? USER_ENROLLED_TO_THE_PSP_FROM_SMS : USER_ENROLLED_TO_THE_FAP_FROM_SMS,
  email: type === 'HUB' ? USER_ENROLLED_TO_THE_PSP_FROM_EMAIL : USER_ENROLLED_TO_THE_FAP_FROM_EMAIL,
});

export const Home = () => {
  const dataIsLoading = useSelector(getAppDataIsLoading);
  const isTourActive = useSelector(getTourStatus);
  const shouldProposeTutorial = useSelector(getShouldProposeTutorial);
  const shouldShowSurvey = useSelector(getShouldShowSurvey);
  const shouldForceSurveyOpen = useSelector(getShouldForceSurveyOpen);
  const currentlyActiveConsent = useSelector(getCurrentlyActiveConsent);
  const financialAssistanceData = useSelector(getFinancialAssistanceData);
  const selectedProgram = useSelector(getSelectedProgram);
  const userFirstName = useSelector(getUserFirstName);
  const userHasPsp = useSelector(getUserHasPsp);
  const pspReminderTemplate = useSelector(getPSPReminderTemplate);
  const isPSPMedicationHasBeenAdded = useSelector(getIsPSPMedicationHasBeenAdded);
  const medicationWithoutReminder = useSelector(getPSPMedicationWithoutReminder);
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const toastRef = useRef<ToastContainer>(null);
  const dispatch = useDispatch();
  const route = useRoute();
  useNotificationHandler();

  const styles = useHomePageStyles();
  // show this analytic action only first time when user saw Landing Page
  useFirstEffect(() => {
    landingPageAnalyticsHandler(VIEW_LANDING_PAGE, getAnalyticEventLabel());
  }, []);

  useEffect(() => {
    const { type, source } = (route.params as unknown as HomePageQueryString) || {};
    if (!type && !source) return;
    landingPageAnalyticsHandler(getKeySourceAction(type)[source], getAnalyticEventLabel(type));
  }, [route]);

  const reminderModalSubmitHandler = () => {
    dispatch(setReminderModalVisibility(false));
    MedicineCabinetInterface.getReminders().then((resp) => {
      dispatch(setReminders(resp.data.results));
    });
  };

  const closeSurvey = () => {
    dispatch(removeSelectedSurveyId());
    dispatch(setShouldShowSurvey(false));
    dispatch(setShouldForceSurveyOpen(false));
  };

  const title = `Hello ${userFirstName ? userFirstName : 'guest'}.`;
  const description = `Welcome back to your ${selectedProgram?.display_name} support program!`;

  const cardsToShow = useCardsToShow();

  const shouldShowTutorialModal = !shouldShowSurvey && shouldProposeTutorial && !currentlyActiveConsent;
  const shouldShowSurveyModal = (shouldForceSurveyOpen || shouldShowSurvey) && !currentlyActiveConsent;

  const shouldShowPSPReminderModal = Boolean(
    !isPSPMedicationHasBeenAdded &&
      medicationWithoutReminder &&
      pspReminderTemplate?.type &&
      !shouldShowTutorialModal &&
      !shouldShowSurveyModal &&
      !shouldProposeTutorial &&
      !isTourActive
  );

  useFirstEffect(
    () => {
      shouldShowPSPReminderModal && dispatch(setReminderModalVisibility(true));
    },
    [shouldShowPSPReminderModal, dispatch],
    false
  );

  if (dataIsLoading) return <FullScreenLoader />;

  return (
    <Layout title={title} description={description} navigation={navigation}>
      <NotificationTiles />
      {cardsToShow.map((card, index) => (
        <Fragment key={index}>{card}</Fragment>
      ))}
      {!!financialAssistanceData && (
        <>
          <FinancialAssistanceOverview fapData={financialAssistanceData} />
          <Spacer size={24} orientation='vertical' />
        </>
      )}
      <AdditionalServicesSection />
      <EntreatyTourModal visible={shouldShowTutorialModal} />
      {shouldShowPSPReminderModal && <ReminderModal isHomeScreen submitHandler={reminderModalSubmitHandler} />}
      {userHasPsp && (
        <Modal
          modalContentStyle={styles.surveyModalContentWrapper}
          toastRef={toastRef}
          modalIsVisible={shouldShowSurveyModal}
          onRequestClose={closeSurvey}>
          <Survey isSurveyStarted={shouldShowSurveyModal} toastRef={toastRef} onRequestClose={closeSurvey} />
        </Modal>
      )}
    </Layout>
  );
};

export default Home;
