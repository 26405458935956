import { SetInitialModalStates } from 'store/actions/user/actionCreators/setInitialModalStates';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setInitialModalStates = (state = INITIAL_STATE, payload: ReturnType<SetInitialModalStates>['payload']): User => {
  return {
    ...state,
    shouldProposeTutorial: payload.shouldProposeTutorial,
  };
};

export default setInitialModalStates;
