import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const ArrowLeft = (props: SvgProps) => (
  <Svg width={10} height={16} viewBox='0 0 10 16' fill='none' {...props}>
    <Path
      d='M8.42871 1.14258L1.57157 7.99972L8.42871 14.8569'
      stroke={props.stroke || 'white'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default ArrowLeft;
