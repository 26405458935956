import { TouchableOpacity } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText } from 'style/common';

export const ButtonWrapper = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  width: 75px;
  margin-top: 16px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-left: 16px;
    `}
`;

export const ButtonText = styled(DefaultText)<{ color?: string }>`
  font-size: 16px;
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  margin-left: 12px;
  color: ${(props) => (props.color ? props.color : props.theme.colors.white)};
`;
