import React, { useEffect, useState } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface, NotificationsInterface } from 'api';
import { useNotificationToastLoop, useShowMore } from 'hooks';
import { CabinetInfo } from 'screens/main/MedicineCabinet/components/CabinetWidgets';
import { setCabinetNotifications } from 'store/actions';
import { removeCabinetNotification, setMedications, setReminders } from 'store/actions/medicineCabinet';
import { getCabinetNotifications } from 'store/selectors';
import { handlePromise } from 'utils/handlePromise';
import { AnyFunction } from 'utils/types';

import { INFO_TEXT, medicationNotificationsAnalyticsActions } from './constants';
import { dateFormatter, medicationNotificationsAnalyticsHandler } from './helpers';
import { MedNotificationPlaceholder } from './MedNotificationPlaceholder';
import { NotificationStatus, CabinetInfoType } from './types';

const { NOTIFICATION_TILE_ACKNOWLEDGED, NOTIFICATION_TILE_SKIPPED } = medicationNotificationsAnalyticsActions;

type MedicationNotificationsProps = {
  isLoading?: boolean;
  actionHandler?: AnyFunction;
};

export const MedicationNotifications = ({ isLoading, actionHandler }: MedicationNotificationsProps) => {
  const loopToast = useNotificationToastLoop();
  const toast = useToast();
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);

  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const notifications = useSelector(getCabinetNotifications);

  const { itemsToBeRendered: notificationsToBeRendered, showMoreJSX } = useShowMore({
    items: [...notifications].reverse(),
    containerStyle: { marginBottom: 20 },
  });

  useEffect(() => {
    let isMounted = true;

    const fetchNotifications = async () => {
      try {
        setIsNotificationsLoading(true);
        const notificationsResponse = await MedicineCabinetInterface.getReminderNotifications();
        if (isMounted) {
          dispatch(setCabinetNotifications(notificationsResponse?.data));
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) {
          setIsNotificationsLoading(false);
        }
      }
    };

    isFocused && fetchNotifications();

    return () => {
      isMounted = false;
    };
  }, [isFocused, dispatch]);

  const fetchUpdatedMedications = () => {
    handlePromise(MedicineCabinetInterface.getMedications).then(
      ([resp]) => !!resp && dispatch(setMedications(resp.data.results))
    );
  };

  const fetchUpdatedReminders = () => {
    handlePromise(MedicineCabinetInterface.getReminders).then(
      ([resp]) => !!resp && dispatch(setReminders(resp.data.results))
    );
  };

  const skipHandler = async ({ notificationId, medicationId }: { notificationId: number; medicationId: number }) => {
    try {
      await NotificationsInterface.update({ notificationId, status: NotificationStatus.SKIPPED });
      medicationNotificationsAnalyticsHandler(NOTIFICATION_TILE_SKIPPED);
      const { data } = await MedicineCabinetInterface.getNotificationsStatusDose({ medicationId });
      toast.show(`Doses missed this month: ${data.missedDoses}%`, {
        type: 'warning',
        animationType: 'zoom-in',
      });
      dispatch(removeCabinetNotification(notificationId));
      fetchUpdatedReminders();
      actionHandler?.();
    } catch (error) {
      console.error(error);
    }
  };

  const doneHandler = async ({ notificationId, medicationId }: { notificationId: number; medicationId: number }) => {
    try {
      await NotificationsInterface.update({ notificationId, status: NotificationStatus.ACKNOWLEDGED });
      medicationNotificationsAnalyticsHandler(NOTIFICATION_TILE_ACKNOWLEDGED);
      const { data } = await MedicineCabinetInterface.getNotificationsStatusDose({ medicationId });
      loopToast.showNext({ additionalMessage: ` Doses taken this month: ${data.takenDoses}%` });
      dispatch(removeCabinetNotification(notificationId));
      fetchUpdatedMedications();
      fetchUpdatedReminders();
      actionHandler?.();
    } catch (error) {
      console.error(error);
    }
  };

  if (isNotificationsLoading || isLoading) return <MedNotificationPlaceholder />;

  if (!notifications?.length) return null;

  return (
    <>
      {notificationsToBeRendered.map((item) => {
        const informationText = INFO_TEXT.replace('{0}', item?.medicationName);
        const date = new Date(item?.time);

        return (
          <CabinetInfo
            key={item.id}
            infoText={informationText}
            detailsText={dateFormatter(date)}
            skipHandler={() => skipHandler({ notificationId: item.id, medicationId: item.medicationId })}
            doneHandler={() => doneHandler({ notificationId: item.id, medicationId: item.medicationId })}
            type={CabinetInfoType.NOTIFY}
          />
        );
      })}
      {showMoreJSX}
    </>
  );
};

export default MedicationNotifications;
