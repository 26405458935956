import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const ArrowDown = (props: SvgProps) => (
  <Svg width={14} height={14} viewBox='0 0 14 14' fill='none' {...props}>
    <Path
      d='M1 4l6 6 6-6'
      stroke={props.stroke || '#000'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default ArrowDown;
