import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { Rect, Circle } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { screenContentWidth } from 'style/utils';

export const ProgramMedicinesPlaceHolder = () => {
  const { isMobile, ...theme } = useTheme();
  const loaderStyle = { backgroundColor: theme.colors.gray4, borderRadius: 16, marginBottom: 20 };
  const contentHeight = isMobile ? 190 : 100;

  const MedicationPlaceholder = () => {
    return (
      <ContentLoader
        backgroundColor={theme.colors.gray3}
        style={loaderStyle}
        width={screenContentWidth}
        height={contentHeight}>
        {isMobile ? (
          <>
            <Rect x='0' y='0' rx='3' ry='3' width='100%' height='24' />
            <Circle cx='40' cy='70' r='16' />
            <Rect x='65' y='62' rx='6' ry='6' width='70%' height='16' />
            <Rect x='65' y='90' rx='6' ry='6' width='30%' height='16' />
            <Rect x='65' y='118' rx='6' ry='6' width='40%' height='16' />
            <Rect x='65' y='146' rx='6' ry='6' width='60%' height='16' />
          </>
        ) : (
          <>
            <Rect x='0' y='0' rx='3' ry='3' width='100%' height='24' />
            <Rect x='24' y='55' rx='3' ry='3' width='15%' height='16' />
            <Rect x='23%' y='55' rx='3' ry='3' width='10%' height='16' />
            <Rect x='38%' y='55' rx='3' ry='3' width='20%' height='16' />
            <Rect x='62%' y='55' rx='3' ry='3' width='18%' height='16' />
            <Circle cx='87%' cy='60' r='16' />
            <Circle cx='95%' cy='60' r='16' />
          </>
        )}
      </ContentLoader>
    );
  };

  return (
    <>
      <ContentLoader
        backgroundColor={theme.colors.gray3}
        style={{ marginBottom: 12 }}
        width={screenContentWidth}
        height={20}>
        <Rect x='0' y='0' rx='6' ry='6' width={isMobile ? 180 : 220} height='18' />
      </ContentLoader>
      {new Array(3).fill(null).map((_, index) => (
        <MedicationPlaceholder key={index} />
      ))}
    </>
  );
};

export default ProgramMedicinesPlaceHolder;
