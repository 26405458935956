import React, { useState } from 'react';

import { TextStyle, View } from 'react-native';
import RNPickerSelect, { PickerSelectProps } from 'react-native-picker-select';

import { ArrowDown } from 'assets/icons';
import InputLabel from 'components/InputLabel';
import { defaultTheme } from 'style/theme';

import { usePickerStylesProps, Container } from './styles';

export type SelectBoxProps = Partial<{
  labelText: string;
  showInfo: boolean;
  webStyle: TextStyle;
  nativeStyle: TextStyle;
  maxInputWidth: number | string;
  shouldShowIcon: boolean;
  shouldShowLabel: boolean;
  shouldShowDefaultPlaceHolder: boolean;
  placeHolderText: string;
  testID?: string;
}>;

const SelectBox = ({
  labelText,
  showInfo = false,
  webStyle,
  nativeStyle,
  maxInputWidth,
  disabled,
  shouldShowIcon = true,
  shouldShowLabel = true,
  shouldShowDefaultPlaceHolder = true,
  placeholder = {},
  placeHolderText,
  testID,
  ...rest
}: SelectBoxProps & PickerSelectProps) => {
  const [open, setOpen] = useState(false);

  const pickerStyleProps = usePickerStylesProps({ disabled, webStyle, nativeStyle, maxInputWidth });

  const iconColor = disabled ? defaultTheme.colors.gray3 : defaultTheme.colors.black;
  const Icon = () => {
    if (!shouldShowIcon || disabled) return <></>;

    return (
      <ArrowDown
        style={{
          transform: [{ rotateZ: `${open ? 180 : 0}deg` }],
        }}
        pointerEvents='auto'
        stroke={iconColor}
      />
    );
  };

  return (
    <Container>
      {shouldShowLabel && <InputLabel labelText={labelText as string} showInfo={showInfo} />}
      <View testID={testID}>
        <RNPickerSelect
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          fixAndroidTouchableBug
          useNativeAndroidPickerStyle={false}
          placeholder={shouldShowDefaultPlaceHolder ? { label: placeHolderText ?? 'Select...' } : placeholder}
          style={pickerStyleProps}
          Icon={Icon}
          disabled={disabled}
          textInputProps={{ numberOfLines: 1 }}
          {...rest}
        />
      </View>
    </Container>
  );
};

export default SelectBox;
