import { useEffect } from 'react';

import { useNavigation } from '@react-navigation/core';
import * as Notifications from 'expo-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { Routes } from 'routes/main';
import { getUserHasPsp } from 'store/selectors';

import { setExternalPartnerProgramUuid } from '../store/actions';

type NotificationResponseType = Notifications.NotificationResponse | null | undefined;
type ParamsType = Record<string, unknown> | undefined;

const useParsedNotificationUrl = (notificationUrl: string): Routes => {
  const userHasPsp = useSelector(getUserHasPsp);

  switch (notificationUrl) {
    case Routes.TRACKER:
      // If user has no psp, we navigate user to home page as a default.
      return userHasPsp ? Routes.TRACKER : Routes.HOME;
    default:
      return notificationUrl as Routes;
  }
};

// This hook handles the received notifications and redirects the user to requested url(screen).
const useNotificationHandler = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const lastNotificationResponse: NotificationResponseType = Notifications.useLastNotificationResponse();
  Notifications.setBadgeCountAsync(0);

  const url = useParsedNotificationUrl(lastNotificationResponse?.notification.request.content.data?.url as string);

  useEffect(() => {
    if (
      lastNotificationResponse &&
      lastNotificationResponse.notification.request.content.data.url &&
      lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
    ) {
      const params: ParamsType = lastNotificationResponse.notification.request.content.data?.params as ParamsType;
      const partnerProgramUuid = lastNotificationResponse.notification.request.content.data?.programUUID;

      !!partnerProgramUuid && dispatch(setExternalPartnerProgramUuid(`${partnerProgramUuid}`));
      navigation.navigate(url, params);
    }
  }, [dispatch, lastNotificationResponse, navigation, url]);
};

export default useNotificationHandler;
