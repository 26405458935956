import React from 'react';

import { appDateFormatter } from 'utils/date/appDateFormatter';

import { InfoCard } from './InfoCard';

export type CopayRenewalCardProps = {
  hasCopayRenewal?: boolean;
  nextFillDate?: string;
};

export const CopayRenewalCard = ({ hasCopayRenewal, nextFillDate }: CopayRenewalCardProps) => {
  if (!hasCopayRenewal) return null;

  const formattedDate = appDateFormatter(nextFillDate, 'MM/dd/yyyy', 'No date');

  return (
    <InfoCard headerText='Copay Renewal' description='The benefits on this card will reset on' date={formattedDate} />
  );
};

export default CopayRenewalCard;
