import { StyleSheet } from 'react-native';

export interface StylesProps {
  color?: string;
  marginVertical?: number;
  marginTop?: number;
  marginBottom?: number;
}

export const getStyles = ({ color, marginTop, marginBottom, marginVertical }: StylesProps) =>
  StyleSheet.create({
    main: {
      width: '100%',
      height: 0,
      borderColor: color,
      borderBottomWidth: 1,
      ...(marginTop && { marginTop }),
      ...(marginBottom && { marginBottom }),
      ...(marginVertical && { marginVertical }),
    },
  });
