import React from 'react';

import { ClipBoard, Injection, Phone, Video, Package } from 'assets/icons';
import { ServiceIconTypesEnum } from 'store/reducers/additional-services/types';

export const iconMapping = {
  [ServiceIconTypesEnum.CLIPBOARD]: ClipBoard,
  [ServiceIconTypesEnum.PHONE]: (props: any) => <Phone {...props} width={24} height={24} strokeWidth={1.5} />,
  [ServiceIconTypesEnum.INJECTION]: Injection,
  [ServiceIconTypesEnum.VIDEO]: Video,
  [ServiceIconTypesEnum.PACKAGE]: Package,
} as const;
