import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const INTERACTION_ITEM_HEIGHT = 70;

export const useComponentStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    itemBlock: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: INTERACTION_ITEM_HEIGHT,
      borderBottomWidth: 1,
      borderColor: colors.gray3,
    },
    interactionItemText: {
      color: colors.gray1,
      marginRight: 10,
      overflow: 'hidden',
      maxWidth: '60%',
    },
    severityStatusWrapper: {
      height: 25,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 8,
      borderRadius: 50,
    },
    severityText: { color: colors.white },
  });
};
