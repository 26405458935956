import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Rect } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const GelFormingSolutions = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 59 117.51' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='M39.37 112.7s.75-5.3-3.79-5.3h-12.3s-4.92-.57-5.3-4.35v-4h-3.22s-5.49-1.7-4.16-6.43S15 88.1 15 88.1s-1.14-6.06 1.7-7.39a9.49 9.49 0 0 1 4.55-1.13s-1.33-9.09 8-14.39c0 1.52-1.52 4.55.18 5.68s4 .95 5.68 3.22A5.44 5.44 0 0 1 37.66 79c.19.19 4.92-1.14 6.63 2.08a8.19 8.19 0 0 1 1.32 6.44c0-.19 5.68.75 4.92 5.68s-6.05 5.3-6.05 5.3 3.02 10.79-5.11 14.2Z'
        />
        <Path
          fillOpacity={0.2}
          d='M21.2 79.58a9 9 0 0 0 6.55 1.6c0 .2-6.57-3.11-5.74-7.79a14.06 14.06 0 0 0-.81 6.19ZM15.32 82.14S14.27 84 15 88.1c0 0 1.7 2.46 6.32 2.37.04 0-6.32-1.57-6-8.33ZM11.13 91.16S8 96.45 14.76 99.07h5.49s-7.49-1.41-9.12-7.91ZM18 101.52s-1.15 4.94 5.3 5.88H25s-6.35-1.29-7-5.88Z'
        />
        <Path
          fill='#fff'
          d='M37.66 79s-.19 1.09-4.1 1c0 0 4.68.15 5.67.58s4.39.49 6.49 4.13c0-.29-.64-4.47-3.7-5.52a9.93 9.93 0 0 0-4.36-.19ZM45.61 87.53s-1.75 2.14-7 2.2c1.36.31 7.25-.94 9.56.89s2.11 2.88 2 3.79c.12-.26 1.21-3-.76-5.07a6.42 6.42 0 0 0-3.8-1.81ZM32.41 74s3.31-1.4 5.09 3.27c0 0-1.48-2.33-3-2.82a11.24 11.24 0 0 0-2.09-.45Z'
        />
        <Path
          d='M48.61 51.51v-7.07H28.5v.05H10.39v7.08A11.27 11.27 0 0 0 0 58.24v53.17c11.56 6.84 27.32 6.17 30.07 6 4.61.17 18.48.12 28.93-6.06V58.18a11.27 11.27 0 0 0-10.39-6.67Z'
          fill='#e2e2e2'
          fillOpacity={0.4}
        />
        <Path fillOpacity={0.2} d='M48.61 51.51v-7.07H28.5v.05H10.39v7.08c24.13 3.55 38.22-.06 38.22-.06Z' />
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='M54.22 23.93c-16.66-5.3-15-18.55-15.15-21.2S34.53.27 34 .08a31.48 31.48 0 0 0-4.3 0 31.5 31.5 0 0 0-4.3 0c-.57.19-4.92 0-5.11 2.65s1.51 15.9-15.14 21.2v25.74a155.41 155.41 0 0 0 24.05 1.89h1a155.47 155.47 0 0 0 24.06-1.89Z'
        />
        <Path
          fillOpacity={0.2}
          d='M21 8.64c-.48 4.34-2.4 10.91-9.33 13.89a.57.57 0 0 0 .1 1.09 29.1 29.1 0 0 0 8.45.44.54.54 0 0 0 .42-.34 30 30 0 0 0 1.5-15.13.57.57 0 0 0-1.14.05Z'
        />
        <Path
          fill='#fff'
          d='M36.68 4.84a19 19 0 0 1-3 .43.83.83 0 0 0-.77 1c.53 2.43 2.26 7.52 5.6 10a.83.83 0 0 0 1.27-1c-1-2.3-2.4-6.09-2.14-9.61a.81.81 0 0 0-.96-.82Z'
        />
        <Path
          fillOpacity={0.2}
          d='M5.11 23.93v25.74a155.41 155.41 0 0 0 24.05 1.89h1a155.47 155.47 0 0 0 24.06-1.89V23.93a97.81 97.81 0 0 1-49.11 0Z'
        />
        <Path
          fill='#fff'
          d='M59 58.18a11.27 11.27 0 0 0-10.39-6.67s-14.09 3.61-38.22.06A11.27 11.27 0 0 0 0 58.24s15.2 5.19 29.66 5.19S59 58.18 59 58.18Z'
        />
        <Path d='M2.31 61.65v41.94s2.5-22.21 19.34-37.52c.2 0-10.33-.42-19.34-4.42Z' fillOpacity={0.1} />
        <Rect fill='#fff' x={48.59} y={63.37} width={7.36} height={44.95} rx={3.68} />
        <Rect fill='#fff' x={49.62} y={64.61} width={5.31} height={12.36} rx={2.66} />
      </G>
    </G>
  </Svg>
);

export default GelFormingSolutions;
