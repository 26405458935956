import React from 'react';

import { TouchableOpacity } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch } from 'react-redux';

import { Radio } from 'components/RadioButton';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { setSelectedAnswerForQuestion } from 'store/actions';
import { Question } from 'store/reducers/user-info/types/survey';
import { DefaultText } from 'style/common';

import { getStyles } from './styles';

type SingleAnswerProps = {
  currentQuestion?: Question;
  toastRef: React.MutableRefObject<ToastContainer | null>;
};

export const SingleAnswer = ({ currentQuestion }: SingleAnswerProps) => {
  const dispatch = useDispatch();

  const { isMobile } = useDeviceInfoContext();
  const styles = getStyles(isMobile);

  const pressHandler = (answerId: string, isChecked: boolean) => () => {
    if (!currentQuestion) return;

    dispatch(
      setSelectedAnswerForQuestion({
        questionId: currentQuestion.uuid,
        answerId,
        isSelected: !isChecked,
        multi: false,
      })
    );
  };

  return (
    <>
      {currentQuestion?.answers.map(({ uuid: answerId, text: answerText, is_checked: isChecked }) => (
        <TouchableOpacity key={answerId} style={styles.checkboxWrapper} onPress={pressHandler(answerId, isChecked)}>
          <Radio isChecked={!!isChecked} />

          <DefaultText style={styles.answerText}>{answerText}</DefaultText>
        </TouchableOpacity>
      ))}
    </>
  );
};

export default SingleAnswer;
