import { CSSProperties } from 'react';

import { StyleSheet, StyleProp } from 'react-native';

import { Styles } from 'style/types';

const hasSizeValue = (value?: string | number) => !!value || typeof value === 'number';

export const convertStyleSheetToStyled = (style: StyleProp<Styles> = {}): CSSProperties => {
  const { marginHorizontal, marginVertical, paddingHorizontal, paddingVertical, ...styles } = StyleSheet.flatten(style);

  return {
    ...styles,
    ...(hasSizeValue(marginHorizontal) && { margin: `0 ${marginHorizontal}px` }),
    ...(hasSizeValue(marginVertical) && { margin: `${marginVertical}px 0` }),
    ...(hasSizeValue(paddingHorizontal) && { margin: `0 ${paddingHorizontal}px` }),
    ...(hasSizeValue(paddingVertical) && { margin: `${paddingVertical}px 0` }),
  } as CSSProperties;
};
