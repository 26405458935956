import * as ActionTypes from 'store/action-types';

export type SetInitialModalStates = (payload: { shouldProposeTutorial: boolean }) => {
  type: typeof ActionTypes.SET_INITIAL_MODAL_STATES;
  payload: typeof payload;
};

export const setInitialModalStates: SetInitialModalStates = (payload) => ({
  type: ActionTypes.SET_INITIAL_MODAL_STATES,
  payload,
});
