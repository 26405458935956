import React from 'react';

import { StyleSheet, View } from 'react-native';

import { Card, Spacer } from 'components';
import { LinearProgressBar } from 'components/LinearProgressBar';
import { DefaultText, StandardText, SubTitle } from 'style/common';

import { useIndicatorCardStyles } from './styles';
import { RemainingIndicatorCardProps } from './types';

export const RemainingIndicatorCard = ({
  title,
  visible,
  suffix = '',
  usedValue = 0,
  remainingValue = 0,
  maximumAvailable = 0,
  isDecimal = false,
  cardWrapperStyles,
  showOnlyUsedMetrics,
  onPress = () => null,
}: RemainingIndicatorCardProps) => {
  const styles = useIndicatorCardStyles();

  if (!visible) return null;

  const progressPercentage = usedValue ? (usedValue / maximumAvailable) * 100 : 0;

  return (
    <Card isTouchable pressHandler={onPress} style={StyleSheet.flatten(cardWrapperStyles)}>
      <SubTitle>{title}</SubTitle>
      <Spacer size={12} />
      <LinearProgressBar isVisible value={progressPercentage} />
      <Spacer size={12} />
      <View>
        <DefaultText style={styles.remainingUsedValueText}>{`${suffix}${remainingValue || 0}`}</DefaultText>
        <StandardText>Remaining</StandardText>
      </View>
    </Card>
  );
};
