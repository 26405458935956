import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Swab = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 110.86 110.86' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M17.57 0c8.08.38 12.54 6.8 16.14 13.89 2 3.94 3.58 8.25 3.24 12.87-.17 2.28 1 3.7 2.49 5.15q19.79 19.71 39.49 39.51A8.26 8.26 0 0 0 85.09 74c11.24.37 22.91 8.3 25.31 17 1.77 6.4-1.63 13.79-8.15 17.75a14.71 14.71 0 0 1-17.94-2A32.14 32.14 0 0 1 74 84.32a7.26 7.26 0 0 0-2.35-5.22q-20-19.89-39.86-39.85a7.25 7.25 0 0 0-5.22-2.36A32.19 32.19 0 0 1 4.84 27.3c-4.95-4.82-6.06-10.47-3.53-16.84C3.61 4.69 10.5 0 17.57 0Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fill='#fff'
          fillOpacity={0.2}
          d='M21.15 4.88C13 .19 6.44 5.33 3.59 11.77c-1.3 2.93 2.49 5.38 4.77 3.13a15 15 0 0 1 11.19-4.59 2.91 2.91 0 0 0 1.6-5.43Z'
        />
        <Path
          fillOpacity={0.05}
          d='M89.12 105.64c8 5 14.72.19 17.85-6.12 1.42-2.87-2.25-5.48-4.63-3.33a15 15 0 0 1-11.34 4.1 2.91 2.91 0 0 0-1.88 5.35Z'
        />
        <Ellipse
          fill='#fff'
          fillOpacity={0.2}
          cx={86.56}
          cy={82.84}
          rx={5.37}
          ry={6.51}
          transform='rotate(-47.42 86.568 82.835)'
        />
        <Ellipse
          fillOpacity={0.05}
          cx={24.19}
          cy={28.14}
          rx={5.37}
          ry={6.51}
          transform='matrix(.68 -.74 .74 .68 -12.9 26.91)'
        />
      </G>
    </G>
  </Svg>
);

export default Swab;
