import { PixelRatio, Pressable, StyleSheet } from 'react-native';
import styled from 'styled-components/native';

import { DefaultText } from 'style/common';
import { defaultTheme } from 'style/theme';

export const InputLabel = styled(DefaultText)`
  color: ${(props) => props.theme.colors.gray2};
  line-height: 16.41px;
`;

export const commonStyles = {
  borderRadius: 8,
  borderColor: defaultTheme.colors.gray3,
  borderWidth: 1,
  padding: 16,
  paddingRight: 14,
  fontFamily: defaultTheme.fontFamilies.default,
  color: defaultTheme.colors.gray1,
  ...defaultTheme.fontSizes[16],
  fontSize: 16 / PixelRatio.getFontScale(),
};

export const PickerStyleProps = StyleSheet.create({
  inputWeb: { ...commonStyles },
  inputIOS: { ...commonStyles },
  inputAndroid: { ...commonStyles },
  iconContainer: { right: 4, top: 22, backgroundColor: defaultTheme.colors.white, width: 28 },
});

export const LabelWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 8px;
`;

export const IconWrapper = styled(Pressable)`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
`;
