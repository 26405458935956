import styled, { css } from 'styled-components/native';

import { Button } from 'components/Button';
import { DefaultText, getFontStyles } from 'style/common';
import { defaultTheme } from 'style/theme';

export const ContentArea = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.secondary};
  padding: 107px 32px 0 32px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 227px 0 0 0;
    `}
`;

export const PageInformationWrapper = styled.View`
  position: absolute;
  left: 32px;
  top: 27px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      justify-content: center;
      align-items: center;
      right: 32px;
    `}
`;

export const PageTitle = styled(DefaultText)`
  ${getFontStyles(24)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 6px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      text-align: center;
    `}
`;

export const PageDescription = styled(DefaultText)`
  ${getFontStyles(16)}
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  color: ${(props) => props.theme.colors.white};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      text-align: center;
    `}
`;

export const ContentWrapper = styled.View`
  flex: 1;
  background-color: ${(props) => props.theme.colors.white};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  justify-content: center;
  align-items: center;
`;

export const PageHeader = styled(DefaultText)`
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 26px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.primary};
`;

export const CardWrapper = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 32px;
  border: 1px solid ${defaultTheme.colors.gray2};
`;

export const CardImage = styled.Image`
  min-width: 165px;
  height: 60px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-bottom: 16px;
    `}
`;

export const ActionButton = styled.TouchableOpacity`
  padding: 14px 24px;
  border-radius: 8px;
  border: 2px solid ${defaultTheme.colors.gray2};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-top: 16px;
    `}
`;

export const ActionButtonText = styled(DefaultText)`
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  font-size: 16px;
  color: ${defaultTheme.colors.gray1};
`;

export const ModalWrapper = styled.View`
  padding: 30px 55px 29px 29px;
  max-width: 490px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 250px;
      max-width: 250px;
      padding: 40px 0 5px 0;
    `}
`;

export const ProgressBar = styled.View<{ background: string; count: number }>`
  width: ${(props) => `${100 / props.count - 1}%`};
  height: 8px;
  border-radius: 16px;
  padding: 0 4px;
  background: ${(props) => props.background};
`;

export const ProgressHeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ModalHeader = styled(DefaultText)`
  font-size: 24px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary};
`;

export const ModalInfo = styled(DefaultText)`
  font-size: 16px;
  line-height: 19.5px;
  color: ${defaultTheme.colors.gray1};
  margin: 17px 0 30px 0;
  width: 100%;
`;

export const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
`;

export const ButtonWrapper = styled.View`
  width: 100px;
`;

export const BackButton = styled(Button)<{ disabled: boolean }>`
  border-color: ${(props) => (props.disabled ? 'transparent' : defaultTheme.colors.gray2)};
`;

// Document picker
export const DocumentWrapper = styled.View`
  background-color: ${defaultTheme.colors.gray4};
  border-radius: 8px;
  align-items: center;
  padding: 36px 40px;
  margin-bottom: 32px;
`;

export const DocumentInfo = styled(ModalInfo)`
  margin-bottom: 20px;
  text-align: center;
  margin-top: 0;
`;

// Reimbursement

export const ReimbursementContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const OptionWrapper = styled.View<{ active: boolean }>`
  border-radius: 16px;
  align-items: center;
  padding: 32px 0;
  border: 1px solid ${(props) => (props.active ? props.theme.colors.secondary : props.theme.colors.gray2)};
  width: 48%;
  height: 134px;
`;

export const OptionInfo = styled(DefaultText)`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  font-size: 16px;
`;

export const TutorialImage = styled.Image`
  width: 100%;
  height: 218px;
  margin: 34px 0;
  background-color: #c4c4c4;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      height: 150px;
    `}
`;
