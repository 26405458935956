export enum ReachableTimes {
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}

export const TEXTS = {
  title: 'Understanding My Insurance Coverage',
  description: 'We are happy to give you a call and walk through your insurance coverage with you.',
  exit: 'Exit',
  thanks: 'Thanks!',
  weWillBeInTouch: "We'll be in touch soon!",
};
