import { Medication } from 'store/reducers/medicine-cabinet/types';

import { TabTypesEnum, Tab } from './types';

export const getAvailableTabs = (medication?: Medication) => {
  if (!medication) return [];

  const displayInteractionTab = typeof medication.drugId === 'number';
  const displayDetailsTab = !!medication.details_file_url;

  return [
    {
      type: TabTypesEnum.ABOUT_YOUR_DOSE,
      text: 'About Your Dose',
    },
    displayInteractionTab && {
      type: TabTypesEnum.DRUG_INTERACTIONS,
      text: 'Drug Interactions',
    },
    displayDetailsTab && {
      type: TabTypesEnum.DETAILS,
      text: 'Details',
    },
  ].filter(Boolean) as Tab[];
};
