import { Dispatch, SetStateAction } from 'react';

import { MedicineCabinetInterface, PartnerRegistrationInterface, SurveyInterface } from 'api';
import { SurveysReturnData } from 'api/SurveyInterface/types';
import { UserReturnData } from 'api/UserInterface/types';
import { Routes } from 'routes/main';
import { persistor, reduxStore } from 'store';
import {
  addToConsentModalsQueue,
  setAppTheme,
  setBasicUserInformation,
  setConsents,
  setIsMedicationCabinetLoading,
  setPrograms,
  setPSPReminderTemplate,
  setSelectedProgramID,
  setSurveyCompleted,
  setSurveys,
  setUserAuthorized,
  setUserHasPsp,
  setUserToken,
} from 'store/actions';
import { initMedicineCabinet, setPSPMedicationHasBeenAdded } from 'store/actions/medicineCabinet';
import { appInitializeData, setInitialModalStates, setUserCognitoId } from 'store/actions/user';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import { SurveyEnvEnum } from 'store/reducers/user-info/types/survey';
import { applyActionsForCapturedParams } from 'utils/applyActionsForCapturedParams';
import { clearPersistCache } from 'utils/clearPersistCache';
import { getTimeZoneInfo } from 'utils/dateTimeHelpers';
import { handlePromise } from 'utils/handlePromise';
import { isNativeDevice, sanitizeColorString } from 'utils/helpers';
import apiRequest, { apiPrefix } from 'utils/network';

const prefix = isNativeDevice ? apiPrefix : '';

export const checkTokenRequest = () => {
  return apiRequest<UserReturnData>({
    method: 'GET',
    endpoint: '/v1/token-check/',
    baseURL: `${prefix}/authapi`,
  });
};

// TODO: further extract and unify app's data bootstraping and authorization

const authPages = [Routes.LOGIN, Routes.SET_PASSWORD];

export const bootstrapApp = async ({
  fontsAreLoaded,
  setAppLoading,
}: {
  fontsAreLoaded: boolean;
  setAppLoading: Dispatch<SetStateAction<boolean>> | ((isLoading: boolean) => void);
}) => {
  // TODO(native): need to do it for native
  const isOnAuthPage = authPages.some((page) => window?.location?.pathname?.includes?.(page));

  if (!isNativeDevice) {
    if (fontsAreLoaded) {
      applyActionsForCapturedParams();
    }
    if (isOnAuthPage) {
      if (!fontsAreLoaded) {
        clearPersistCache({ dispatch: reduxStore.dispatch });
      }
      if (fontsAreLoaded) {
        setAppLoading(false);
      }
      return;
    }
  }

  try {
    if (fontsAreLoaded) {
      const data = await checkTokenRequest();
      const {
        data: {
          token = null,
          double_opt = false,
          cognitoId = '',
          surveyCompleted = false,
          appConsents = [],
          programInformation = [],
          timezoneHours,
          tutorialShown,
          is_demo = false,
        } = {},
      } = data;

      if (!token) {
        reduxStore.dispatch(setUserAuthorized(false));
        return;
      }

      persistor.persist();

      await Promise.resolve(reduxStore.dispatch(setUserToken(token)));

      reduxStore.dispatch(
        setInitialModalStates({
          shouldProposeTutorial: !tutorialShown,
        })
      );

      if (appConsents?.length) {
        reduxStore.dispatch(setConsents({ consents: appConsents, groupType: ConsentModalTypeEnum.app }));

        for (const consent of appConsents) {
          reduxStore.dispatch(
            addToConsentModalsQueue({
              id: consent.id,
              groupType: ConsentModalTypeEnum.app,
              priority: 1,
            })
          );
        }
      }

      const initializeMedicineCabinet = async () => {
        reduxStore.dispatch(setIsMedicationCabinetLoading(true));
        try {
          await initMedicineCabinet();
        } catch (error) {
          console.error(error);
        } finally {
          reduxStore.dispatch(setIsMedicationCabinetLoading(false));
        }
      };
      initializeMedicineCabinet();

      if (programInformation?.length) {
        const isCopayUser = programInformation[0].type === 'COPAY';

        const [res] = await handlePromise(() =>
          MedicineCabinetInterface.sponsoredMedication(programInformation[0].uuid)
        );
        res?.data?.length && res.data[0].reminder && reduxStore.dispatch(setPSPReminderTemplate(res.data[0].reminder));
        reduxStore.dispatch(setPSPMedicationHasBeenAdded(Boolean(programInformation.find((e) => e.pspHasBeenAdded))));
        reduxStore.dispatch(setUserHasPsp(true));
        reduxStore.dispatch(setPrograms(programInformation));
        reduxStore.dispatch(setBasicUserInformation({ isCopayUser }));
        const defaultProgram = programInformation.find((program) => program.default);
        if (defaultProgram) {
          reduxStore.dispatch(setSelectedProgramID(defaultProgram.id));
          const { primary_color, secondary_color, primaryColor, secondaryColor } = defaultProgram?.white_labeling_conf;
          const secondaryColorWLConf = sanitizeColorString(secondaryColor) || secondary_color; // remove '\n' of the code color
          reduxStore.dispatch(
            setAppTheme({
              primary: primaryColor || primary_color,
              secondary: secondaryColorWLConf,
            })
          );

          if (defaultProgram.consents?.length) {
            reduxStore.dispatch(
              setConsents({ consents: defaultProgram.consents, groupType: ConsentModalTypeEnum.program })
            );

            for (const consent of defaultProgram.consents) {
              reduxStore.dispatch(
                addToConsentModalsQueue({
                  id: consent.id,
                  groupType: ConsentModalTypeEnum.program,
                  priority: 2,
                })
              );
            }
          }
        } else {
          reduxStore.dispatch(setSelectedProgramID(programInformation[0].id));
          const { primary_color, primaryColor, secondary_color, secondaryColor } =
            programInformation[0]?.white_labeling_conf;
          const secondaryColorWLConf = sanitizeColorString(secondaryColor) || secondary_color; // remove '\n' of the code color
          reduxStore.dispatch(
            setAppTheme({
              primary: primaryColor || primary_color,
              secondary: secondaryColorWLConf,
            })
          );

          if (programInformation[0].consents?.length) {
            reduxStore.dispatch(
              setConsents({ consents: programInformation[0].consents, groupType: ConsentModalTypeEnum.program })
            );

            for (const consent of programInformation[0].consents) {
              reduxStore.dispatch(
                addToConsentModalsQueue({
                  id: consent.id,
                  groupType: ConsentModalTypeEnum.program,
                  priority: 2,
                })
              );
            }
          }
        }
      } else {
        reduxStore.dispatch(setUserHasPsp(false));
      }

      reduxStore.dispatch(setUserCognitoId(cognitoId));

      // Do not remove await, we need to make sure that app data is initialized before we set loading to true.
      await Promise.resolve(reduxStore.dispatch(appInitializeData()));

      const { offSet } = getTimeZoneInfo();
      const isTimeZoneDifferent = offSet !== timezoneHours;

      reduxStore.dispatch(
        setBasicUserInformation({
          isDemo: is_demo,
          doubleOpt: double_opt,
          userTimeZoneInformation: isTimeZoneDifferent ? offSet : timezoneHours,
        })
      );

      if (isTimeZoneDifferent) await PartnerRegistrationInterface.patch({ data: { timezoneHours: offSet } });

      const [surveyResponse, _] = await handlePromise<SurveysReturnData>(() =>
        SurveyInterface.get({ environment: SurveyEnvEnum.TrialCard })
      );

      if (surveyResponse?.data?.results?.length) {
        reduxStore.dispatch(setSurveys(surveyResponse.data.results));
      }

      reduxStore.dispatch(setSurveyCompleted(surveyCompleted));
    }
  } catch (error) {
    console.log(error);
    reduxStore.dispatch(setUserAuthorized(false));
  } finally {
    if (fontsAreLoaded) {
      setAppLoading(false);
    }
  }
};
