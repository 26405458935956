import React, { useState } from 'react';

import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface } from 'api/MedicineCabinetInterface';
import { Pill } from 'assets/icons';
import { MedicationComponent } from 'screens/main/MedicineCabinet/components';
import { MedCabinetWidgetType } from 'screens/main/MedicineCabinet/types';
import { setReminderModalVisibility, setSelectedMedicationId } from 'store/actions';
import {
  getIsMedicationCabinetLoading,
  getMedications,
  getPrograms,
  getSelectedMedicationId,
  getSelectedProgram,
} from 'store/selectors';
import { TEST_ID } from 'utils/testIds/constants';

import { CabinetEmpty } from '../CabinetWidgets';
import { DeleteModal } from '../modals/DeleteModal';
import { ProgramMedicinesPlaceHolder } from '../Placeholders';

type ProgramMedicationsProps = {
  medicationModalSubmitHandler: (showToast: boolean, message: string) => void;
};

export const ProgramMedications = ({ medicationModalSubmitHandler }: ProgramMedicationsProps) => {
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const programs = useSelector(getPrograms);
  const selectedProgram = useSelector(getSelectedProgram);
  const medications = useSelector(getMedications);
  const selectedMedicationId = useSelector(getSelectedMedicationId);
  const isMedicineCabinetLoading = useSelector(getIsMedicationCabinetLoading);
  const dispatch = useDispatch();

  if (isMedicineCabinetLoading) return <ProgramMedicinesPlaceHolder />;

  const deleteMedicationPressHandler = (id: number) => {
    dispatch(setSelectedMedicationId(id));
    setDeleteModalVisibility(true);
  };

  const handleDelete = async () => {
    try {
      if (selectedMedicationId) {
        await MedicineCabinetInterface.deleteMedication(selectedMedicationId);
        medicationModalSubmitHandler(true, 'Medication successfully deleted!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderMedications = () => {
    if (!medications.length) {
      return (
        <CabinetEmpty
          Icon={Pill}
          infoText='Looks like you haven’t added any medications'
          actionButtonText='Add Medication'
          actionType={MedCabinetWidgetType.MEDICATION}
        />
      );
    }
    const program = !!programs?.length && programs[0];
    const primaryColor =
      !!program && (program.white_labeling_conf.primaryColor || program.white_labeling_conf.primary_color);
    return medications.map((medication, i) => {
      const isSponsored = !!medication?.sponsored_program_uuid;

      return (
        <MedicationComponent
          key={medication.id}
          medication={medication}
          isFirst={i === 0}
          programUuid={isSponsored && program ? selectedProgram?.uuid : undefined}
          primaryColor={isSponsored && primaryColor ? primaryColor : ''}
          onDeleteMedicationPress={deleteMedicationPressHandler}
        />
      );
    });
  };

  return (
    <View>
      <View>{renderMedications()}</View>
      <DeleteModal
        confirmTestID={TEST_ID.DELETE_MEDICATION_CONFIRM_BUTTON}
        cancelTestID={TEST_ID.DELETE_MEDICATION_CANCEL_BUTTON}
        itemNameToBeDeleted={MedCabinetWidgetType.MEDICATION}
        setWidgetModalVisibility={setReminderModalVisibility}
        setDeleteModalVisibility={setDeleteModalVisibility}
        deleteModalIsVisible={deleteModalVisibility}
        deleteHandler={handleDelete}
      />
    </View>
  );
};

export default ProgramMedications;
