import { ApiConsentsInterface } from 'api/Consents/types';
import apiRequest, { apiPrefix } from 'utils/network';

const SERVICE_CONSENTS_URL = '/consent/service-capture/';

export const ServiceConsentsInterface: ApiConsentsInterface = {
  saveGivenConsent: ({ consent_id }) =>
    apiRequest({
      method: 'POST',
      endpoint: SERVICE_CONSENTS_URL,
      baseURL: apiPrefix,
      body: { consentUuid: consent_id, expirable: true },
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    }),
};
