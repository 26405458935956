import { StyleProp, TextStyle } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText } from 'style/common';
import { defaultTheme } from 'style/theme';

export const logoTitle: StyleProp<TextStyle> = { color: defaultTheme.colors.gray2 };

export const FooterContainer = styled.View`
  width: 100%;
  justify-content: ${(props) => (props.theme.isMobile ? 'flex-start' : 'space-between')};
  min-height: auto;
  max-height: none;
  padding: 32px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  elevation: 3;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.gray4};
  flex-direction: row;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column;
      padding-bottom: 40px;
      padding-top: 28px;
    `}
`;

export const FooterText = styled(DefaultText)`
  line-height: 17px;
  color: ${(props) => props.theme.colors.gray2};
  text-align: right;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      max-width: 261px;
      margin-top: 16px;
      text-align: left;
    `}
`;

export const LandingFooterIconWrapper = styled.View`
  width: 172px;
  height: 48px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 164px;
      height: 46px;
    `}
`;

export const NativeFooterContainer = styled.View`
  width: 100%;
  height: 94px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
`;
