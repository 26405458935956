import React, { ReactNode } from 'react';

import { View, Text } from 'react-native';
import { ToastProps } from 'react-native-toast-notifications/lib/typescript/toast';

import { Check, Close, ThumbsUp, Warning } from 'assets/icons';

import { defaultTheme } from '../../style/theme';
import { useCustomToastStyles } from './styles';
import { CustomToastProps, ToastTypes } from './types';

export const CustomToast = ({ toast, icon }: CustomToastProps) => {
  const styles = useCustomToastStyles(toast.type);

  return (
    <View style={styles.container}>
      <View style={styles.iconWrapper}>{icon}</View>
      <Text style={styles.toastMessageText}>{toast.message}</Text>
    </View>
  );
};

const { white } = defaultTheme.colors;

export const renderTypes: Record<ToastTypes, (props: ToastProps) => JSX.Element> = {
  tracker_info: (props) => <CustomToast icon={<ThumbsUp stroke={white} />} toast={props} />,
  success: (props) => <CustomToast icon={<Check stroke={white} />} toast={props} />,
  danger: (props) => <CustomToast icon={<Close stroke={white} />} toast={props} />,
  warning: (props) => <CustomToast icon={<Warning stroke={white} />} toast={props} />,
};

export default CustomToast;
