import React from 'react';

import { View, ImageSourcePropType } from 'react-native';
import { useSelector } from 'react-redux';

import { patientIcon, patientColorLogo } from 'assets/images';
import ProgramSelect from 'components/Drawer/ProgramSelect';
import { getSelectedProgram, getUserHasPsp, getWebDrawerOpenStatus } from 'store/selectors';
import { StyledImage } from 'style/common';
import { getMediaUrl } from 'utils/helpers';

import { useComponentStyles } from './styles';

export const MenuHeader = () => {
  const userHasPsp = useSelector(getUserHasPsp);
  const isDrawerOpen = useSelector(getWebDrawerOpenStatus);
  const selectedProgram = useSelector(getSelectedProgram);

  const { brandEmblem, logo: brandLogo } = selectedProgram?.white_labeling_conf || {};

  const emblem = (getMediaUrl(brandEmblem) || patientIcon) as ImageSourcePropType;
  const logo = (getMediaUrl(brandLogo) || patientColorLogo) as ImageSourcePropType;

  const styles = useComponentStyles(!getMediaUrl(brandLogo));

  const brandEmblemJSX = (
    <View style={styles.brandEmblemWrapper}>
      <StyledImage style={styles.brandEmblem} resizeMode='contain' source={emblem} />
    </View>
  );

  if (userHasPsp)
    return isDrawerOpen ? (
      <View style={styles.programWrapper}>
        <ProgramSelect logo={logo} />
      </View>
    ) : (
      brandEmblemJSX
    );

  return isDrawerOpen ? (
    <View style={styles.imageWrapper}>
      <StyledImage resizeMode='contain' source={logo} />
    </View>
  ) : (
    brandEmblemJSX
  );
};

export default MenuHeader;
