import styled, { css } from 'styled-components/native';

import { DefaultText, PrimaryDefaultText } from 'style/common';

export const Wrapper = styled.View`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 100%;
    `}
  ${({ theme }) =>
    !theme.isMobile &&
    css`
      width: 30vw;
    `}
`;

export const Title = styled(PrimaryDefaultText)`
  font-family: ${(props) => props.theme.fontFamilies.bold};
  line-height: 24px;
  font-size: 24px;
`;

export const Description = styled(DefaultText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin: 16px 0;
  color: ${(props) => props.theme.colors.gray1};
`;

export const Address = styled(Description)`
  margin: 0;
  color: ${(props) => props.theme.colors.gray1};
`;

export const ButtonsWrapper = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-top: 26px;
`;
