import React, { useEffect, useContext } from 'react';

import { useSelector } from 'react-redux';

import medDetailsLayout from 'assets/images/med_details_layout.png';
import medDetailsProgramLayout from 'assets/images/med_details_program_layout.png';
import { DeviceInfoContext } from 'contexts/DeviceInfo';
import { useScroll } from 'hooks';
import { MedicineCabinetLayout } from 'screens/main/MedicineCabinet/layout';
import { SubSectionsTabs } from 'screens/main/MedicineCabinet/sections/MedicationDetails/components/SubsectionsTabs';
import { tabsMapping } from 'screens/main/MedicineCabinet/sections/MedicationDetails/components/SubsectionsTabs/mappings';
import { getShowedReminderItemDemoAnimation } from 'store/selectors/demoConfig';
import { getSelectedMedication, getSelectedTab } from 'store/selectors/medicine-cabinet';

import { CabinetScreenProps } from '../../types';

export const MedicationDetails = ({ navigation }: CabinetScreenProps) => {
  const selectedTab = useSelector(getSelectedTab);
  const selectedMedication = useSelector(getSelectedMedication);
  const showedReminderItemDemoAnimation = useSelector(getShowedReminderItemDemoAnimation);

  const { height = 0 } = useContext(DeviceInfoContext);

  const { scrollViewRef, setComponentViewY } = useScroll({
    shouldScroll: !showedReminderItemDemoAnimation,
  });

  useEffect(() => setComponentViewY(height * 0.4), [height, setComponentViewY]);

  const SelectedComponent = tabsMapping[selectedTab];

  return (
    <MedicineCabinetLayout
      scrollViewRef={scrollViewRef}
      subSectionJSX={<SubSectionsTabs />}
      navigation={navigation}
      imageUrl={selectedMedication?.sponsored_program_uuid ? medDetailsProgramLayout : medDetailsLayout}>
      <SelectedComponent />
    </MedicineCabinetLayout>
  );
};

export default MedicationDetails;
