import { ThunkDispatch } from 'redux-thunk';

import { BenefitsServicesInterface } from 'api/Benefits';
import { setMilestoneTrackerData } from 'store/actions/cards';
import { handlePromise } from 'utils/handlePromise';

export const initMilestonesData = async (
  dispatch: ThunkDispatch<unknown, unknown, ReturnType<typeof setMilestoneTrackerData>>
) => {
  const [res] = await handlePromise(BenefitsServicesInterface.getBenefitTrackers);

  if (!res) return;

  dispatch(setMilestoneTrackerData(res));
};
