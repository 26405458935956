import React from 'react';

import Svg, { SvgProps, Defs, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Tape = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 136.59 86.68' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M71.13 81.66c2.14-1.58 3-2.25 3.93-2.89 22.17-15.4 25.12-47.06 6.1-66.06-8-8-17.57-11.29-28.94-12.37C34-1.37 19.22 3.26 8 18c-13.53 17.77-9.36 45.59 8.47 59.19 8.61 6.57 18.19 9.35 28.7 9.41 22.65.12 45.29-.07 67.94.08 8.22 0 15.79-2.51 23.48-5Zm-21-11.38c-8.75 1.85-15.51-2.73-21-8.86-12.91-14.53-6.61-38 11.72-44.61 8.8-3.16 20.89 2.12 26.13 11.51 2.27 4.07 4.38 8.25 3.79 12.36C71.92 55 63.22 67.52 50.1 70.28Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.2}
          d='M46.6 15.89s-30.22-5.55-32 29.52c0 0 .72 25.76 31 26.2-.58-.44-22.19-4.25-23.51-28.81C24.48 17.11 46.6 15.89 46.6 15.89Z'
        />
        <Path
          fillOpacity={0.2}
          d='M23.84 52.17c-5.95-5.45-6.52 3.12-6.54 3.37 3.37 7.27 11 15.83 28.35 16.08-.52-.38-16.4-3.24-21.81-19.45ZM47.14 86.68S84.41 84 86.44 45.13c0 0 .39-39-37.92-45.13 0 0 33.88-1.08 43.73 31.93-.12-.24 9.12 32.77-21.56 49.65L56 86.68Z'
        />
        <Path fillOpacity={0.1} d='m89 82.47-8.57 4.21H56s8.45-3.11 12.26-4.27Z' />
        <Path
          fillOpacity={0.2}
          d='M92 37.85c-3-5.18-5.62 8.07-5.62 8.07C83.88 84 47.14 86.68 47.14 86.68H56l14.66-5.1c20.63-11.36 23.21-30 22.72-40.93a24.23 24.23 0 0 0-1.38-2.8Z'
        />
        <Path
          fillOpacity={0.2}
          d='M92.19 54.14q-.14.61-.3 1.23.17-.61.3-1.23ZM92.54 52.55l.21-1.1ZM92.27 53.82c.07-.31.14-.62.2-.93-.06.31-.13.62-.2.93ZM91.53 56.61ZM90.05 60.76ZM91 58.25ZM91.11 57.94ZM92.76 51.35c.07-.4.13-.8.19-1.19-.06.39-.12.84-.19 1.19ZM93.45 41.84v.79c.01-.27 0-.53 0-.79Z'
        />
        <Path
          fillOpacity={0.1}
          d='M71.76 10.66c14.89 13.6 14.68 34.47 14.68 34.47 0 .27 0 .52-.06.79S89 32.67 92 37.85a24.23 24.23 0 0 1 1.39 2.8v1a47.22 47.22 0 0 0-1.19-9.69C88.21 18.4 80.14 10.61 72 6.11c-6-2.69-.25 4.54-.25 4.54Z'
        />
        <Path
          fillOpacity={0.2}
          d='M93 49.85c.05-.3.08-.6.12-.89-.05.29-.12.59-.12.89ZM93.42 45v-2.1c.04.66.03 1.36 0 2.1ZM93.15 48.64c0-.37.08-.75.11-1.12-.03.37-.07.75-.11 1.12ZM93.26 47.51c0-.38.06-.75.09-1.13-.03.38-.05.75-.09 1.13ZM93.41 45.24v.86c-.03-.29-.01-.57 0-.86ZM80.46 74.55c-.4.38-.82.75-1.24 1.12.42-.37.84-.74 1.24-1.12ZM85.35 69.05c.23-.31.45-.63.67-.94-.22.31-.44.63-.67.94ZM89.88 61.15c-.14.33-.28.65-.43 1 .15-.35.29-.67.43-1ZM77.44 77.14c-.43.33-.86.66-1.31 1 .45-.34.87-.67 1.31-1ZM83.1 71.83c.35-.39.69-.79 1-1.19-.31.36-.65.8-1 1.19ZM84.29 70.42ZM82 73c.28-.29.56-.59.83-.89-.28.33-.56.63-.83.89ZM80.63 74.39c.35-.33.68-.67 1-1-.32.33-.63.67-1 1ZM78.85 76c-.32.28-.66.56-1 .83.34-.29.68-.57 1-.83ZM88 64.94l.54-1ZM72.87 80.31l1.2-.76ZM87.2 66.32l.59-1ZM88.69 63.67c.22-.41.42-.83.62-1.25-.2.42-.4.84-.62 1.25ZM87 66.58c-.26.41-.53.82-.81 1.22.32-.4.59-.8.81-1.22ZM74.51 79.26c.51-.35 1-.69 1.5-1-.48.31-1.01.65-1.5 1ZM70.69 81.58ZM92.19 54.14q-.14.61-.3 1.23.17-.61.3-1.23ZM92.54 52.55l.21-1.1ZM92.27 53.82c.07-.31.14-.62.2-.93-.06.31-.13.62-.2.93ZM91.53 56.61ZM90.05 60.76ZM91 58.25ZM91.11 57.94ZM92.76 51.35c.07-.4.13-.8.19-1.19-.06.39-.12.84-.19 1.19ZM93.45 41.84v.79c.01-.27 0-.53 0-.79Z'
        />
        <Path
          fillOpacity={0.2}
          d='M93 49.85c.05-.3.08-.6.12-.89-.05.29-.12.59-.12.89ZM93.42 45v-2.1c.04.66.03 1.36 0 2.1ZM93.15 48.64c0-.37.08-.75.11-1.12-.03.37-.07.75-.11 1.12ZM93.26 47.51c0-.38.06-.75.09-1.13-.03.38-.05.75-.09 1.13ZM93.41 45.24v.86c-.03-.29-.01-.57 0-.86ZM80.46 74.55c-.4.38-.82.75-1.24 1.12.42-.37.84-.74 1.24-1.12ZM85.35 69.05c.23-.31.45-.63.67-.94-.22.31-.44.63-.67.94ZM89.88 61.15c-.14.33-.28.65-.43 1 .15-.35.29-.67.43-1ZM77.44 77.14c-.43.33-.86.66-1.31 1 .45-.34.87-.67 1.31-1ZM83.1 71.83c.35-.39.69-.79 1-1.19-.31.36-.65.8-1 1.19ZM84.29 70.42ZM82 73c.28-.29.56-.59.83-.89-.28.33-.56.63-.83.89ZM80.63 74.39c.35-.33.68-.67 1-1-.32.33-.63.67-1 1ZM78.85 76c-.32.28-.66.56-1 .83.34-.29.68-.57 1-.83ZM88 64.94l.54-1ZM72.87 80.31l1.2-.76ZM87.2 66.32l.59-1ZM88.69 63.67c.22-.41.42-.83.62-1.25-.2.42-.4.84-.62 1.25ZM87 66.58c-.26.41-.53.82-.81 1.22.32-.4.59-.8.81-1.22ZM74.51 79.26c.51-.35 1-.69 1.5-1-.48.31-1.01.65-1.5 1ZM70.69 81.58Z'
        />
        <Path
          d='M77.55 17c4.8 3.41 5.69 0 5.86-1.66A41.15 41.15 0 0 0 72 6.11c-6-2.69-.2 3.87.46 4.49.01 0 4.6 4.97 5.09 6.4Z'
          fill='#fff'
          fillOpacity={0.4}
        />
      </G>
    </G>
  </Svg>
);

export default Tape;
