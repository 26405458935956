import { useEffect, useState } from 'react';

import { useDrawerStatus } from '@react-navigation/drawer';
import { useSelector } from 'react-redux';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { getWebDrawerOpenStatus } from 'store/selectors';
import { isSafari, isWebNativeIOSDevice } from 'utils/helpers';

const DELAY_TUTORIAL_MS = isSafari && isWebNativeIOSDevice ? 800 : 1200;

export const useDelayTutorial = (delay = DELAY_TUTORIAL_MS) => {
  const [runTutorial, setRunTutorial] = useState(false);
  const { isMobile } = useDeviceInfoContext();
  const webDrawerOpen = useSelector(getWebDrawerOpenStatus);
  const drawerStatus = useDrawerStatus();

  useEffect(() => {
    const timer = setTimeout(() => {
      setRunTutorial(isMobile ? drawerStatus !== 'open' : !webDrawerOpen);
    }, delay);
    return () => clearTimeout(timer);
  }, [isMobile, webDrawerOpen, drawerStatus, delay]);

  return runTutorial;
};
