import React, { useState } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { Pressable, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { Menu, Phone } from 'assets/icons';
import { patientColorLogo } from 'assets/images';
import { ModalComponent as Modal } from 'components/Modal';
import { Support } from 'components/Support';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useHandleNavigationPush, useImageSizes } from 'hooks';
import { safetyInfo, prescriptionInfo, call } from 'localization/header';
import { Routes } from 'routes/main';
import { getSelectedProgram, getUserHasPsp } from 'store/selectors';
import { LogoWrapper, StyledImage, webStyles } from 'style/common';
import { defaultTheme } from 'style/theme';
import { getMediaUrl, openUrlInNewTab } from 'utils/helpers';
import { getImageUrl } from 'utils/white-labeling';

import { Button } from '../Button';
import { ActionsWrapper, HeaderContainer, HeaderWrapper, InfoText, LegalInformation, MenuIconWrapper } from './styles';

const styles = StyleSheet.create({
  menuIconWrapper: { marginLeft: 16 },
  prescriptionInfoStyle: { marginRight: 0 },
});

export const Header = ({ navigation }: { navigation: DrawerNavigationHelpers }) => {
  const [modalIsVisible, setModalVisible] = useState(false);
  const { isMobile } = useDeviceInfoContext();
  const selectedProgram = useSelector(getSelectedProgram);
  const userHasPsp = useSelector(getUserHasPsp);
  const navigationHandler = useHandleNavigationPush();

  const termsAndConditionsURL = 'https://corp.trialcard.com/privacy-policy/';
  const { white_labeling_conf: { logo = null } = {} } = selectedProgram || {};

  const programLogo = getMediaUrl(logo);

  const { imageWidth: logoWidth } = useImageSizes({
    url: programLogo || patientColorLogo,
    widthValue: 48,
    isSrc: !programLogo,
  });

  const openModalClickHandler = () => setModalVisible(true);
  const closeModalClickHandler = () => setModalVisible(false);

  const navigateToTermsAndConditions = (link?: string) => () => openUrlInNewTab(link || termsAndConditionsURL);

  const menuIconSize = isMobile ? 24 : 28;

  const menuIconClickHandler = () => {
    navigation.toggleDrawer();
  };

  const logoClickHandler = () => navigationHandler(Routes.HOME);

  const selectProgramLogo = selectedProgram?.white_labeling_conf?.logo ? programLogo : patientColorLogo;

  const renderCallButton = () => {
    return isMobile ? (
      <MenuIconWrapper onPress={openModalClickHandler}>
        <Phone stroke={defaultTheme.colors.gray1} width={24} height={24} />
      </MenuIconWrapper>
    ) : (
      <Button text={call} variant='secondary' style={webStyles.button} onPress={openModalClickHandler} />
    );
  };

  return (
    <HeaderContainer>
      {userHasPsp && (
        <LegalInformation>
          <Pressable
            style={webStyles.button}
            onPress={navigateToTermsAndConditions(selectedProgram?.safetyInformationLink)}>
            <InfoText>{safetyInfo}</InfoText>
          </Pressable>
          <Pressable
            style={webStyles.button}
            onPress={navigateToTermsAndConditions(selectedProgram?.prescriberInformationLink)}>
            <InfoText style={styles.prescriptionInfoStyle}>{prescriptionInfo}</InfoText>
          </Pressable>
        </LegalInformation>
      )}
      <HeaderWrapper>
        <LogoWrapper onPress={logoClickHandler} style={[webStyles.button, { width: logoWidth }]}>
          <StyledImage resizeMode='contain' source={getImageUrl(selectProgramLogo)} />
        </LogoWrapper>
        <ActionsWrapper>
          {!!selectedProgram && renderCallButton()}
          {isMobile && (
            <MenuIconWrapper style={[styles.menuIconWrapper, webStyles.button]} onPress={menuIconClickHandler}>
              <Menu stroke={defaultTheme.colors.gray1} width={menuIconSize} height={menuIconSize} />
            </MenuIconWrapper>
          )}
        </ActionsWrapper>
      </HeaderWrapper>
      <Modal modalIsVisible={modalIsVisible} setModalVisible={setModalVisible} onRequestClose={closeModalClickHandler}>
        <Support />
      </Modal>
    </HeaderContainer>
  );
};

export default Header;
