import { defaultTheme } from 'style/theme';

export default {
  NO_SEARCH_RESULT: 'Looks like we don’t have any interaction data on that medication',
};

export const severityColors = [
  defaultTheme.colors.gray3,
  defaultTheme.colors.warning,
  defaultTheme.colors.negative,
  defaultTheme.colors.negative,
];
