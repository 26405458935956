// Analytics Constants

const VIEW_ABOUT_YOUR_DOSE_SECTION = 'VIEW_ABOUT_YOUR_DOSE_SECTION';
const VIEW_MEDICATION_DETAILS_SECTION = 'VIEW_MEDICATION_DETAILS_SECTION';
const VIEW_MEDICATION_INTERACTIONS_SECTION = 'VIEW_MEDICATION_INTERACTIONS_SECTION';
const VIEW_SEARCHED_INTERACTIONS = 'VIEW_SEARCHED_INTERACTIONS';
const VIEW_DETAILS_OF_THE_INTERACTION = 'VIEW_DETAILS_OF_THE_INTERACTION';
const VIEW_PROGRAM_MEDICATION_CABINET_DETAIL = 'VIEW_PROGRAM_MEDICATION_CABINET_DETAIL';

const EDIT_MEDICATION_YOUR_DOSE_STARTED = 'EDIT_MEDICATION_YOUR_DOSE_STARTED';
const EDIT_MEDICATION_YOUR_DOSE_FINISHED = 'EDIT_MEDICATION_YOUR_DOSE_FINISHED';
const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';

export const medicationDetailAnalyticsActions = {
  VIEW_ABOUT_YOUR_DOSE_SECTION,
  VIEW_MEDICATION_DETAILS_SECTION,
  VIEW_MEDICATION_INTERACTIONS_SECTION,
  VIEW_SEARCHED_INTERACTIONS,
  VIEW_DETAILS_OF_THE_INTERACTION,
  EDIT_MEDICATION_YOUR_DOSE_STARTED,
  EDIT_MEDICATION_YOUR_DOSE_FINISHED,
  DELETE_REMINDER_SUCCESS,
  VIEW_PROGRAM_MEDICATION_CABINET_DETAIL,
};
