import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { MODAL_PADDING, ALLOWED_MODAL_PADDINGS } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';

export const useSurveyCompletionModalStyles = () => {
  const { fontSizes, colors, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: applyBreakpoints(theme, {
      sm: { maxWidth: theme.width || 0 - ALLOWED_MODAL_PADDINGS },
      md: { padding: MODAL_PADDING, maxWidth: '70%' },
      xxl: { padding: MODAL_PADDING, maxWidth: '40%' },
    }),
    title: {
      ...fontSizes[24],
      color: colors.primary,
      fontFamily: fontFamilies.bold,
    },
    description: {
      marginTop: 16,
      color: colors.gray2,
    },
    buttonStyle: {
      alignSelf: 'flex-start',
      marginTop: 32,
    },
  });
};
