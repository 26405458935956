import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AdditionalServicesInterface } from 'api';
import { setResourcesNotificationIconVisibility } from 'store/actions';
import { getResourcesNotificationIconVisibility } from 'store/selectors';

export const useAdditionalServiceNotification = () => {
  const shouldShowNotificationIcon = useSelector(getResourcesNotificationIconVisibility);
  const [notificationIds, setNotificationIds] = useState<string[]>([]);
  const dispatch = useDispatch();

  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    AdditionalServicesInterface.getNotifications({ purpose: 'NEW_ADDITIONAL_SERVICE' }).then((res) => {
      const newElements = res.data.filter((notification) => !notification.is_read);

      dispatch(setResourcesNotificationIconVisibility(!!newElements.length));
      setNotificationIds(newElements.map((notification) => notification?.id).filter(Boolean) as string[]);
    });
  }, [refetch, dispatch]);

  return {
    shouldShowNotificationIcon,
    hideNotifications: () => {
      if (!shouldShowNotificationIcon) return;
      AdditionalServicesInterface.clearNotifications(notificationIds).then(() => setRefetch((prev) => !prev));
    },
  };
};
