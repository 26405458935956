import React from 'react';

import { useIsFocused } from '@react-navigation/native';
import { animated, useTransition } from '@react-spring/native';
import { View } from 'react-native';

import { fadeInAnimationConfig } from 'utils/animation';
import { isNativeDevice } from 'utils/helpers';

const AnimatedView = animated(View);

export const TransitionWrapper: React.FC = ({ children }) => {
  const isFocused = useIsFocused();

  const transition = useTransition(isFocused, fadeInAnimationConfig(isNativeDevice ? 0 : 150));

  return transition((styles, item) => <AnimatedView style={[{ flex: 1 }, styles]}>{children}</AnimatedView>);
};

export default TransitionWrapper;
