import { SetShouldForceSurveyOpen } from 'store/actions/user/actionCreators/surveys/setShouldForceSurveyOpen';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setShouldForceSurveyOpen = (
  state = INITIAL_STATE,
  payload: ReturnType<SetShouldForceSurveyOpen>['payload']
): User => {
  return { ...state, shouldForceSurveyOpen: payload };
};

export default setShouldForceSurveyOpen;
