import React, { useRef, useCallback, useState, MutableRefObject } from 'react';

import { useRoute } from '@react-navigation/native';
import { View, Text, NativeMethods } from 'react-native';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { ModalComponent as Modal } from 'components/Modal';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useOnClickOutside } from 'hooks';
import { setShowEventNotification } from 'store/actions';
import { PrimaryDefaultText, webStyles } from 'style/common';

import { styles } from './styles';

export type NotificationPopupProps = {
  title: string;
  description: string;
};

type ContentRefType = MutableRefObject<(HTMLElement & NativeMethods) | null | undefined>;

export const NotificationPopup = ({ title, description }: NotificationPopupProps) => {
  const contentRef: MutableRefObject<View | null> = useRef(null);
  const dispatch = useDispatch();
  const route = useRoute();
  // TODO: next todo
  // const showEventNotification = useSelector(getShowEventNotification);
  const { isMobile } = useDeviceInfoContext();

  const [isModalVisible, setIsModalVisible] = useState(false);

  // TODO: we temporarily close this popup for demo
  // useEffect(() => {
  //   setIsModalVisible(showEventNotification);
  // }, [showEventNotification]);

  const isBenefitsPage = route.name === 'benefits';

  const wrapperStyle = isMobile ? styles.popupMobileContainer : styles.popupContainer;

  const closeHandler = useCallback(() => dispatch(setShowEventNotification(false)), [dispatch]);

  useOnClickOutside(contentRef as ContentRefType, closeHandler);

  // For now we only want to show this notification in Benefits Page.
  if (!isBenefitsPage) return null;

  return (
    <Modal modalIsVisible={isModalVisible} setModalVisible={setIsModalVisible}>
      <View ref={contentRef} style={wrapperStyle}>
        <PrimaryDefaultText style={styles.popupTitle}>{title}</PrimaryDefaultText>
        <Text style={styles.popupDescription}>{description}</Text>
        <Button
          variant='secondary'
          text='Close'
          style={[webStyles.button, styles.closeButton]}
          onPress={closeHandler}
        />
      </View>
    </Modal>
  );
};

export default NotificationPopup;
