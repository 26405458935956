import * as ActionTypes from 'store/action-types';
import { AvailableActionsForUser } from 'store/actions';
import setSessionExpiredModalVisible from 'store/reducers/user-info/subreducers/consents/setSessionExpiredModalVisible';
import { setIsActivateAccountModalVisible } from 'store/reducers/user-info/subreducers/setIsActivateAccountModalVisible';
import setUserCognitoId from 'store/reducers/user-info/subreducers/setUserCognitoId';

import { INITIAL_STATE } from './initialState';
import addToConsentModalsQueue from './subreducers/consents/addToConsentModalsQueue';
import clearConsentModalsQueue from './subreducers/consents/clearConsentModalsQueue';
import clearConsents from './subreducers/consents/clearConsents';
import removeCurrentlyActiveConsent from './subreducers/consents/removeCurrentlyActiveConsent';
import removeFromConsentModalsQueue from './subreducers/consents/removeFromConsentModalsQueue';
import setConsentHasBeenGiven from './subreducers/consents/setConsentHasBeenGiven';
import setConsentHasBeenViewed from './subreducers/consents/setConsentHasBeenViewed';
import setConsents from './subreducers/consents/setConsents';
import setCurrentlyActiveConsent from './subreducers/consents/setCurrentlyActiveConsent';
import addUserInsurance from './subreducers/insurances/addUserInsurance';
import removeUserInsurance from './subreducers/insurances/removeUserInsurance';
import setUserInsurances from './subreducers/insurances/setUserInsurances';
import updateUserInsurance from './subreducers/insurances/updateUserInsurance';
import removeNotificationTileByType from './subreducers/notification-tiles/removeNotificationTileByType';
import setNotificationTiles from './subreducers/notification-tiles/setNotificationTiles';
import setSimpleTextNotificationTiles from './subreducers/notification-tiles/setSimpleTextNotificationTiles';
import setPrograms from './subreducers/programs/setPrograms';
import setSelectedProgramAsDefault from './subreducers/programs/setSelectedProgramAsDefault';
import setSelectedProgramId from './subreducers/programs/setSelectedProgramId';
import resetUserInfo from './subreducers/resetUserInfo';
import setBasicUserInfo from './subreducers/setBasicUserInfo';
import setInitialModalStates from './subreducers/setInitialModalStates';
import setIsForgotPasswordModalVisible from './subreducers/setIsForgotPasswordModalVisible';
import setSelectedCopayCard from './subreducers/setSelectedCopayCard';
import setShouldProposeTutorial from './subreducers/setShouldProposeTutorial';
import setShowEventNotification from './subreducers/setShowEventNotification';
import setUserAuthorized from './subreducers/setUserAuthorized';
import setUserHasPsp from './subreducers/setUserHasPsp';
import setUserToken from './subreducers/setUserToken';
import setWebDrawerOpen from './subreducers/setWebDrawerOpen';
import removeSelectedSurvey from './subreducers/surveys/removeSelectedSurvey';
import removeSelectedSurveyId from './subreducers/surveys/removeSelectedSurveyId';
import setSelectedAnswerForQuestion from './subreducers/surveys/setSelectedAnswerForQuestion';
import setSelectedSurveyId from './subreducers/surveys/setSelectedSurveyId';
import setShouldForceSurveyOpen from './subreducers/surveys/setShouldForceSurveyOpen';
import setShouldShowSurvey from './subreducers/surveys/setShouldShowSurvey';
import setSurveyCompleted from './subreducers/surveys/setSurveyCompleted';
import setSurveyProgress from './subreducers/surveys/setSurveyProgress';
import setSurveys from './subreducers/surveys/setSurveys';
import { User } from './types';

const userInfo = (state = INITIAL_STATE, action: ReturnType<AvailableActionsForUser>): User => {
  switch (action.type) {
    case ActionTypes.SET_USER_AUTHORIZED: {
      return setUserAuthorized(state, action.payload);
    }

    case ActionTypes.SET_USER_TOKEN: {
      return setUserToken(state, action.payload);
    }

    case ActionTypes.SET_USER_COGNITO_ID: {
      return setUserCognitoId(state, action.payload);
    }

    case ActionTypes.SET_USER_HAS_PSP: {
      return setUserHasPsp(state, action.payload);
    }

    case ActionTypes.SET_CONSENTS: {
      return setConsents(state, action.payload);
    }

    case ActionTypes.CLEAR_CONSENTS: {
      return clearConsents(state);
    }

    case ActionTypes.SET_BASIC_USER_INFORMATION: {
      return setBasicUserInfo(state, action.payload);
    }

    case ActionTypes.SET_INITIAL_MODAL_STATES: {
      return setInitialModalStates(state, action.payload);
    }

    case ActionTypes.SET_SHOULD_PROPOSE_TUTORIAL: {
      return setShouldProposeTutorial(state, action.payload);
    }

    case ActionTypes.SET_IS_FORGOT_PASSWORD_MODAL_VISIBLE: {
      return setIsForgotPasswordModalVisible(state, action.payload, action.withForgotPasswordModalMessage);
    }

    case ActionTypes.SET_IS_ACTIVATE_ACCOUNT_MODAL_VISIBLE: {
      return setIsActivateAccountModalVisible(state, action.payload);
    }

    case ActionTypes.SET_WEB_DRAWER_OPEN: {
      return setWebDrawerOpen(state, action.payload);
    }

    case ActionTypes.SET_SHOULD_SHOW_SURVEY: {
      return setShouldShowSurvey(state, action.payload);
    }

    case ActionTypes.SET_SHOULD_FORCE_SURVEY_OPEN: {
      return setShouldForceSurveyOpen(state, action.payload);
    }

    case ActionTypes.SET_SURVEYS: {
      return setSurveys(state, action.payload);
    }

    case ActionTypes.SET_SELECTED_SURVEY_ID: {
      return setSelectedSurveyId(state, action.payload);
    }

    case ActionTypes.REMOVE_SELECTED_SURVEY_ID: {
      return removeSelectedSurveyId(state);
    }

    case ActionTypes.REMOVE_SELECTED_SURVEY: {
      return removeSelectedSurvey(state, action.payload);
    }

    case ActionTypes.SET_SURVEY_COMPLETED: {
      return setSurveyCompleted(state, action.payload);
    }

    case ActionTypes.SET_SURVEY_PROGRESS: {
      return setSurveyProgress(state, action.payload);
    }

    case ActionTypes.SET_SELECTED_ANSWER_FOR_QUESTION: {
      return setSelectedAnswerForQuestion(state, action.payload);
    }

    case ActionTypes.SET_USER_INSURANCES: {
      return setUserInsurances(state, action.payload);
    }

    case ActionTypes.ADD_USER_INSURANCE: {
      return addUserInsurance(state, action.payload);
    }

    case ActionTypes.UPDATE_USER_INSURANCE: {
      return updateUserInsurance(state, action.payload);
    }

    case ActionTypes.REMOVE_USER_INSURANCE: {
      return removeUserInsurance(state, action.payload);
    }

    case ActionTypes.SET_PROGRAMS: {
      return setPrograms(state, action.payload);
    }

    case ActionTypes.SET_SELECTED_PROGRAM_ID: {
      return setSelectedProgramId(state, action.payload);
    }

    case ActionTypes.SET_SELECTED_PROGRAM_AS_DEFAULT: {
      return setSelectedProgramAsDefault(state, action.payload);
    }

    case ActionTypes.SET_SELECTED_COPAY_CARD: {
      return setSelectedCopayCard(state, action.payload);
    }

    case ActionTypes.SET_SHOW_EVENT_NOTIFICATION: {
      return setShowEventNotification(state, action.payload);
    }

    case ActionTypes.RESET_USER_INFO: {
      return resetUserInfo(state);
    }

    case ActionTypes.SET_CURRENTLY_ACTIVE_CONSENT: {
      return setCurrentlyActiveConsent(state, action.payload);
    }

    case ActionTypes.REMOVE_CURRENTLY_ACTIVE_CONSENT: {
      return removeCurrentlyActiveConsent(state);
    }

    case ActionTypes.SET_CONSENT_HAS_BEEN_VIEWED: {
      return setConsentHasBeenViewed(state, action.payload);
    }

    case ActionTypes.SET_CONSENT_HAS_BEEN_GIVEN: {
      return setConsentHasBeenGiven(state, action.payload);
    }

    case ActionTypes.ADD_TO_CONSENT_MODALS_QUEUE: {
      return addToConsentModalsQueue(state, action.payload);
    }

    case ActionTypes.REMOVE_FROM_CONSENT_MODALS_QUEUE: {
      return removeFromConsentModalsQueue(state, action.payload);
    }

    case ActionTypes.REMOVE_NOTIFICATION_TILES_BY_TYPE: {
      return removeNotificationTileByType(state, action.payload);
    }

    case ActionTypes.SET_NOTIFICATION_TILES: {
      return setNotificationTiles(state, action.payload);
    }

    case ActionTypes.SET_SIMPLE_TEXT_NOTIFICATION_TILES: {
      return setSimpleTextNotificationTiles(state, action.payload);
    }

    case ActionTypes.CLEAR_CONSENT_MODALS_QUEUE: {
      return clearConsentModalsQueue(state);
    }

    case ActionTypes.SET_SESSION_EXPIRED_MODAL_VISIBLE: {
      return setSessionExpiredModalVisible(state, action.payload);
    }

    default: {
      return state;
    }
  }
};

export default userInfo;
