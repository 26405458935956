import { StyleSheet } from 'react-native';
import styled, { useTheme, css } from 'styled-components/native';

import { getFontStyles, StandardText } from 'style/common';
import { applyBreakpoints } from 'style/utils';

export const useBenefitsInvestigationCardStyles = (emptyInvestigation: boolean) => {
  const { colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: {
      marginBottom: 24,
      backgroundColor: colors.white,
      borderWidth: 1,
      borderColor: colors.gray2,
      borderRadius: 16,
      paddingHorizontal: 32,
      paddingVertical: 28,
      marginTop: 18,
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      ...applyBreakpoints(theme, {
        md: { flexDirection: 'column' },
        xxl: { flexDirection: 'row' },
      }),
    },
    meaningButton: applyBreakpoints(theme, {
      sm: {},
      md: { marginTop: 24 },
      lg: { maxWidth: '30%' },
    }),
    meaningButtonText: {
      ...fontSizes[14],
      color: colors.gray1,
    },
    supTitle: {
      ...fontSizes[14],
      marginBottom: 8,
    },
    investigationDescriptionWrapper: applyBreakpoints(theme, {
      md: { marginBottom: 24 },
      xxl: { maxWidth: emptyInvestigation ? '60%' : '30%' },
    }),
    investigationDescription: {
      fontFamily: fontFamilies.bold,
      color: colors.gray1,
    },
    investigationPrimaryDescription: {
      fontFamily: fontFamilies.bold,
      color: colors.primary,
    },
    infoText: {
      ...fontSizes[24],
      fontFamily: fontFamilies.bold,
      color: colors.gray1,
    },
  });
};

export const InsuranceInfoBox = styled.View`
  border: 1px solid ${(props) => props.theme.colors.gray2};
  border-radius: 16px;
  padding: 32px;
  margin-top: 32px;
  margin-bottom: 28px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-bottom: 12px;
    `}
`;

export const InsuranceInfoWrapper = styled.View`
  margin-top: 24px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InsuranceText = styled(StandardText)<{ primary?: boolean }>`
  ${getFontStyles(16)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${({ theme, primary }) => (primary ? theme.colors.primary : theme.colors.gray2)};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-bottom: 12px;
    `}
`;
