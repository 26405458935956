import * as ActionTypes from 'store/action-types';
import { CustomServiceType } from 'store/reducers/additional-services/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

export type AddToConsentModalsQueue = (payload: {
  id: string;
  groupType: ConsentModalTypeEnum;
  priority: number;
  customServiceType?: CustomServiceType;
}) => {
  type: typeof ActionTypes.ADD_TO_CONSENT_MODALS_QUEUE;
  payload: typeof payload;
};

export const addToConsentModalsQueue: AddToConsentModalsQueue = (payload) => ({
  type: ActionTypes.ADD_TO_CONSENT_MODALS_QUEUE,
  payload,
});
