import * as ActionTypes from 'store/action-types';

export type SetWebDrawerOpen = (payload: boolean) => {
  type: typeof ActionTypes.SET_WEB_DRAWER_OPEN;
  payload: typeof payload;
};

export const setWebDrawerOpen: SetWebDrawerOpen = (payload) => ({
  type: ActionTypes.SET_WEB_DRAWER_OPEN,
  payload,
});
