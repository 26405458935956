import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Shampoo = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 49.03 113.25' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M25.55 32.6c4.71-.69 9.28.31 13.86.93 6.84.93 8.55 3.59 8.88 12.74.49 13.81-1.94 27.3-4.42 40.78a145.83 145.83 0 0 1-5.14 19.64c-1.24 3.69-3.5 5.55-7.21 5.64-4.81.12-9.63 0-14.45 0-4.3 0-6.27-2.81-7.31-6.29A202 202 0 0 1 2.19 68.3a112.84 112.84 0 0 1 0-26.17c.73-5.48 3.73-7.83 8.59-8.53s9.8-1.73 14.77-1Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.2}
          d='M25 41.06c12.28 0 18.84-1.34 22.15-2.44-1.17-3.2-3.44-4.5-7.79-5.09-4.58-.62-9.14-1.62-13.85-.93-5-.73-9.88.29-14.78 1-3.38.48-5.86 1.77-7.3 4.35C5.06 39.06 10 41.06 25 41.06Z'
        />
        <Path
          d='M49 49.77c0-28.41-15-33-15-33V2.87C34 .07 26.53 0 24.52 0S15 .07 15 2.87v13.92s-15 4.57-15 33A148.08 148.08 0 0 0 2.92 82c3.34 15.25 5.82 23.25 5.82 23.25s1.24 8 8.86 8h13.83c7.63 0 8.86-8 8.86-8s2.48-8 5.82-23.25A148.08 148.08 0 0 0 49 49.77Z'
          fill='#e2e2e2'
          fillOpacity={0.4}
        />
        <Path
          fillOpacity={0.2}
          d='M6.38 27.69a.41.41 0 0 0 .56.58 32.14 32.14 0 0 1 17.27-5.35c9.61 0 18.51 7.19 21.35 15.85a.41.41 0 0 0 .8-.17 29.64 29.64 0 0 0-12.94-21.44.48.48 0 0 0-.23-.07c-13.37-.27-16.18.57-16.18.57s-4.55.67-10.63 10.03Z'
        />
        <Path
          d='m46.43 48.08-.76 24.52s-7.09 4.54-21.15 4.54S4 73 4 73l-.76-24.3s6.59 1.4 21.61 1.4 21.58-2.02 21.58-2.02Z'
          fill='#fff'
        />
        <Circle fill='#fff' fillOpacity={0.2} cx={11.37} cy={87.8} r={2.92} />
        <Circle fill='#fff' fillOpacity={0.2} cx={17.58} cy={87.8} r={1.45} />
        <Circle fill='#fff' fillOpacity={0.2} cx={17.58} cy={93.7} r={1.99} />
        <Path
          d='M5.7 35s-3-.33-3.24 6.69-.54 43.55 7.24 60.19c.32.75 2.8 8.31 8 8.31s6.83-.64 6.83-.64 3.65-2.81-1.21-4.65S8.62 71.3 9.26 44.84c.11-.22.43-9.92-3.56-9.84Z'
          fillOpacity={0.05}
        />
        <Path
          d='M24.82 19.17c8.34 0 9.18-2.38 9.18-2.38V2.87C34 .07 26.53 0 24.52 0S15 .07 15 2.87v13.92s1.47 2.38 9.82 2.38Z'
          fill='#ddd'
        />
        <Path
          fillOpacity={0.1}
          d='M24.82 18.37C16.47 18.37 15 16 15 16v.81s1.44 2.38 9.79 2.38 9.21-2.4 9.21-2.4V16s-.84 2.37-9.18 2.37Z'
        />
        <Path fillOpacity={0.1} d='M15.43 3.35v12.34a2.27 2.27 0 0 0 2.13 1.06V4.07a6 6 0 0 1-2.13-.72Z' />
        <Path
          fillOpacity={0.2}
          d='M32.12 4.54v11.64a1.77 1.77 0 0 0 1.54-1.08c.29-.85.1-8.54 0-11.16a4.63 4.63 0 0 1-1.54.6ZM24.82 4.58c8.09 0 9.09-1.15 9.18-1.58v-.13C34 .07 26.53 0 24.52 0S15 .07 15 2.87c0 0 0 1.71 9.82 1.71Z'
        />
      </G>
    </G>
  </Svg>
);

export default Shampoo;
