import * as ActionTypes from 'store/action-types';
import type { AppConsent, ProgramConsent, ServiceConsent } from 'store/reducers/user-info/types/consent';

export type SetCurrentlyActiveConsent = (payload: { consent: AppConsent | ProgramConsent | ServiceConsent }) => {
  type: typeof ActionTypes.SET_CURRENTLY_ACTIVE_CONSENT;
  payload: typeof payload;
};

export const setCurrentlyActiveConsent: SetCurrentlyActiveConsent = (payload) => ({
  type: ActionTypes.SET_CURRENTLY_ACTIVE_CONSENT,
  payload,
});
