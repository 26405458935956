import React from 'react';

import { ActivityIndicator, View } from 'react-native';

import { useLoaderStyles } from './styles';

export type FullScreenLoadingProps = {
  color?: string;
};

const FullScreenLoading = ({ color }: FullScreenLoadingProps) => {
  const styles = useLoaderStyles();

  return (
    <View style={styles.loadingWrapper}>
      <ActivityIndicator color={color} size='large' />
    </View>
  );
};

export default FullScreenLoading;
