import React, { ReactNode } from 'react';

import { View } from 'react-native';

import { InformationText } from 'style/common';

export const renderInfoField = (children: ReactNode, isNested?: boolean, color?: string) =>
  isNested ? (
    <View>{children}</View>
  ) : (
    <InformationText numberOfLines={1} style={color ? { color } : undefined}>
      {children}
    </InformationText>
  );
