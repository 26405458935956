import React, { useCallback } from 'react';

import { isEqual } from 'date-fns';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { AppFlatList } from 'components';
import { AppFlatListProps } from 'components/AppFlatList/types';
import { useShowMore } from 'hooks';
import { NoHaveMedicationDataSection } from 'screens/main/MedicationTracker/components/DailyMedications/components/NoHaveMedicationDataSection';
import { TrackerContentProps } from 'screens/main/MedicationTracker/TrackerContent';
import { MedicationHistory } from 'store/reducers/medicine-cabinet/types';
import { getAsNeededReminders, getMedTrackerInfo } from 'store/selectors';
import { useIsBreakpoint } from 'style/hooks';
import { isISOToday } from 'utils/dateTimeHelpers';
import { compareItems } from 'utils/sorting';

import AddAsNeededMedicationTile from '../AddAsNeededMedicationTile';
import { DailyMedication } from '../DailyMedication';
import { useStyles, DAILY_MEDICATION_HEIGHT } from './styles';

type ExtendedMedicationHistory = MedicationHistory & { name: string; selectedDate: string };

type DailyMedicationsProps = {
  selectedDate: number;
  selectedMedicationId: string;
  getMedTrackerData: TrackerContentProps['getMedTrackerData'];
};

export const DailyMedications = ({ selectedDate, getMedTrackerData, selectedMedicationId }: DailyMedicationsProps) => {
  const medTrackerInfo = useSelector(getMedTrackerInfo);
  const asNeededReminders = useSelector(getAsNeededReminders);
  const styles = useStyles();

  const isMd = useIsBreakpoint('md');

  // Don't change it to isToday(date-fns) it doesn't work with all time zones
  const showAsNeededMedication = isISOToday(selectedDate) && !!asNeededReminders.length;

  const currentDailyMedications = medTrackerInfo?.compliance.find(({ date }) => isEqual(new Date(date), selectedDate));

  const mappedDailyMedications =
    currentDailyMedications?.dailyMedications?.flatMap(({ medication: { history, name } }) =>
      history.map((record) => ({
        ...record,
        name,
        selectedDate: currentDailyMedications.date,
      }))
    ) || [];

  const sortCallback = (prev: ExtendedMedicationHistory, next: ExtendedMedicationHistory) =>
    compareItems(prev, next, 'timestamp', true);

  const sortedDailyMedications = [...mappedDailyMedications].sort(sortCallback);

  const { itemsToBeRendered: dailyMedications, showMoreJSX } = useShowMore({
    items: sortedDailyMedications,
    containerStyle: styles.showMoreButton,
  });

  const renderItem: AppFlatListProps<ExtendedMedicationHistory>['renderItem'] = useCallback(
    ({ item: { name, selectedDate: date, id, timestamp, status } }) => (
      <DailyMedication
        name={name}
        firstDoseTime={timestamp}
        date={date}
        historyId={id}
        notificationStatus={status}
        getMedTrackerData={getMedTrackerData}
      />
    ),
    [getMedTrackerData]
  );

  const separatorJSX = <View style={styles.verticalSpace8} />;
  const footerJSX = isMd ? showMoreJSX : null;
  const medicationsToRender = isMd ? dailyMedications : sortedDailyMedications;

  return (
    <>
      <AppFlatList
        style={medicationsToRender.length ? styles.medicationsListWrapper : {}}
        data={medicationsToRender}
        getItemLayout={(_, index) => ({
          index,
          length: DAILY_MEDICATION_HEIGHT,
          offset: DAILY_MEDICATION_HEIGHT * index,
        })}
        drawAll={isMd}
        withRightMargin
        extraData={medicationsToRender}
        keyExtractor={(_, index) => `${index}`}
        ItemSeparatorComponent={() => separatorJSX}
        renderItem={renderItem}
        ListEmptyComponent={
          <NoHaveMedicationDataSection selectedMedicationId={selectedMedicationId} selectedDate={selectedDate} />
        }
        ListFooterComponent={footerJSX}
      />
      {showAsNeededMedication && <AddAsNeededMedicationTile />}
    </>
  );
};

export default DailyMedications;
