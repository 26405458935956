import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { Circle, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { screenContentWidth } from 'style/utils';

export const MedNotificationPlaceholder = () => {
  const { isMobile, ...theme } = useTheme();

  const loaderStyle = { backgroundColor: theme.colors.gray4, borderRadius: 16, marginBottom: 16 };

  if (isMobile)
    return (
      <ContentLoader backgroundColor={theme.colors.gray3} style={loaderStyle} width={screenContentWidth} height={223}>
        <Circle cx='50%' cy='40' r='16' />
        <Rect x='67' y='70' rx='6' ry='6' width='180' height='16' />
        <Rect x='67' y='105' rx='6' ry='6' width='180' height='14' />
        <Rect x='67' y='145' rx='8' ry='8' width='25%' height='48' />
        <Rect x='172' y='145' rx='8' ry='8' width='25%' height='48' />
      </ContentLoader>
    );

  return (
    <ContentLoader backgroundColor={theme.colors.gray3} style={loaderStyle} width={screenContentWidth} height={112}>
      <Circle cx='40' cy='50' r='16' />
      <Rect x='12%' y='42' rx='3' ry='3' width='18%' height='16' />
      <Rect x='35%' y='42' rx='3' ry='3' width='23%' height='16' />
      <Rect x='73%' y='25' rx='8' ry='8' width='10%' height='48' />
      <Rect x='85%' y='25' rx='8' ry='8' width='10%' height='48' />
    </ContentLoader>
  );
};

export default MedNotificationPlaceholder;
