import React from 'react';

import { useIsFocused } from '@react-navigation/core';
import { useDispatch, useSelector } from 'react-redux';

import { ModalComponent as Modal } from 'components/Modal';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useServiceConsentsQueue } from 'hooks/useServiceConsentsQueue';
import {
  saveAdditionalServices,
  setCompletedServiceAction,
  setCompletedServiceRequest,
  setSelectedServiceRequest,
  setSelectedServiceAction,
  setSelectedServiceDigitalResource,
  setCurrentlyActiveService,
  removeCurrentlyActiveService,
  removeSelectedServiceRequest,
  removeSelectedServiceAction,
  setCompletedServiceDigitalResource,
  removeSelectedServiceDigitalResource,
} from 'store/actions';
import type { Action, DigitalResource, Request } from 'store/reducers/additional-services/types';
import { ServiceTypesEnum, CustomServiceTypeEnum } from 'store/reducers/additional-services/types';
import { getAllResources } from 'store/selectors';
import { InformationText } from 'style/common';
import { AnyFunction } from 'utils/types';

import { RequestMaterialActions, ScheduleActions } from '..';
import { DigitalResourceActions } from '../DigitalResourceActions';
import { ConfirmShippingAddress } from '../ModalContent/ConfirmShipingAddress';
import DigitalResourceComponent from '../ModalContent/DigitalResource';
import QuestionnaireModal from '../ModalContent/QuestionnaireModal';

export type ServicesProps = {
  initialActionModalState: boolean;
  initialDigitalResourceModalState: boolean;
  hideResourcesNotificationIcon?: AnyFunction;
};

export const Services = ({
  initialActionModalState,
  initialDigitalResourceModalState,
  hideResourcesNotificationIcon,
}: ServicesProps) => {
  const { isMobile } = useDeviceInfoContext();
  const allResources = useSelector(getAllResources);

  const {
    actionModalIsVisible,
    digitalResourceModalIsVisible,
    requestModalIsVisible,
    selectedServiceAction,
    selectedServiceDigitalResource,
    selectedServiceRequest,
    setActionModalVisible,
    setDigitalResourceModalVisible,
    setRequestModalVisible,
  } = useServiceConsentsQueue({ initialActionModalState, initialDigitalResourceModalState });

  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const scheduleActionHandler = (service: Action) => {
    hideResourcesNotificationIcon?.();
    dispatch(setSelectedServiceAction({ id: service.id, isSelected: true })); // "setSelectedServiceAction" should be deprecated
    dispatch(setCurrentlyActiveService({ service, customServiceType: CustomServiceTypeEnum.actions }));
  };

  const digitalResourceActionHandler = (service: DigitalResource) => {
    hideResourcesNotificationIcon?.();
    dispatch(setSelectedServiceDigitalResource({ id: service.id, isSelected: true })); // "setSelectedServiceDigitalResource" should be deprecated
    dispatch(setCurrentlyActiveService({ service, customServiceType: CustomServiceTypeEnum.digitalResources }));
  };

  const requestActionHandler = (service: Request) => {
    hideResourcesNotificationIcon?.();
    dispatch(setSelectedServiceRequest({ id: service.id, isSelected: true })); // "setSelectedServiceRequest" should be deprecated
    dispatch(setCurrentlyActiveService({ service, customServiceType: CustomServiceTypeEnum.requests }));
  };

  const handleScheduleActionCompleted = (id: number, selectedAnswers: number[], userDataUuid: string) => {
    dispatch(setCompletedServiceAction({ id, isCompleted: true }));
    if (id) {
      dispatch(
        saveAdditionalServices({
          type: ServiceTypesEnum.FORM_REQUEST,
          configUuid: id,
          submittedAnswers: selectedAnswers,
          userDataUuid,
        })
      );
      dispatch(removeCurrentlyActiveService());
    }
  };

  const handleRequestActionCompleted = (id: number) => {
    dispatch(setCompletedServiceRequest({ id, isCompleted: true }));
    if (id && !selectedServiceRequest?.isCompleted) {
      dispatch(removeCurrentlyActiveService());
    }
  };

  const handleDigitalResourceActionCompleted = (id: number) => {
    setDigitalResourceModalVisible(false);
    dispatch(setCompletedServiceDigitalResource({ id, isCompleted: true }));
    dispatch(removeSelectedServiceDigitalResource({ id }));
    dispatch(removeCurrentlyActiveService());

    if (id && selectedServiceDigitalResource && !selectedServiceDigitalResource?.isCompleted) {
      dispatch(
        saveAdditionalServices({
          type: ServiceTypesEnum.RESOURCE,
          configUuid: id,
          userDataUuid: selectedServiceDigitalResource.userDataUuid,
        })
      );
    }
  };

  const setRequestModalCloseHandler = () => {
    setRequestModalVisible(false);
    selectedServiceRequest?.id && dispatch(removeSelectedServiceRequest({ id: selectedServiceRequest.id })); // "removeSelectedServiceRequest" should be deprecated
    dispatch(removeCurrentlyActiveService());
  };

  const setQuestionnaireModalCloseHandler = () => {
    setActionModalVisible(false);
    selectedServiceAction?.id && dispatch(removeSelectedServiceAction({ id: selectedServiceAction.id })); // "removeSelectedServiceAction" should be deprecated
    dispatch(removeCurrentlyActiveService());
  };

  const setDigitalResourceModalCloseHandler = () => {
    setDigitalResourceModalVisible(false);
    selectedServiceDigitalResource?.id &&
      dispatch(removeSelectedServiceDigitalResource({ id: selectedServiceDigitalResource.id })); // "removeSelectedServiceDigitalResource" should be deprecated
    dispatch(removeCurrentlyActiveService());
  };

  if (!isFocused) return null;

  if (!allResources.length) return <InformationText fontFamily='semiBold'>Coming Soon!</InformationText>;

  return (
    <>
      <ScheduleActions actionHandler={scheduleActionHandler} />
      <DigitalResourceActions actionHandler={digitalResourceActionHandler} />
      <RequestMaterialActions actionHandler={requestActionHandler} />
      {selectedServiceAction?.id && (
        <QuestionnaireModal
          selectedActionId={selectedServiceAction.id}
          handleAction={handleScheduleActionCompleted}
          setModalVisible={setQuestionnaireModalCloseHandler}
          modalVisible={actionModalIsVisible}
          selectedAction={selectedServiceAction}
        />
      )}
      {selectedServiceRequest?.id && (
        <Modal
          modalIsVisible={requestModalIsVisible}
          modalContentStyle={{ padding: 8, paddingTop: 24, maxHeight: '90%' }}
          setModalVisible={setRequestModalCloseHandler}
          useContentWrapper>
          <ConfirmShippingAddress
            id={selectedServiceRequest?.id}
            onActionCompleted={handleRequestActionCompleted}
            setModalVisible={setRequestModalVisible}
            userDataUuid={selectedServiceRequest.userDataUuid}
            modalTitle={selectedServiceRequest.modalTitle}
            modalDescription={selectedServiceRequest.modalDescription}
          />
        </Modal>
      )}
      {selectedServiceDigitalResource?.id && (
        <Modal
          modalContentStyle={{ padding: isMobile ? 0 : 20 }}
          fillScreen={isMobile}
          modalIsVisible={digitalResourceModalIsVisible}
          setModalVisible={setDigitalResourceModalCloseHandler}
          disableScrollView={isMobile}
          removeCloseIcon>
          <DigitalResourceComponent
            modalTitle={selectedServiceDigitalResource?.modalTitle}
            source={(selectedServiceDigitalResource as DigitalResource)?.source}
            resourceType={(selectedServiceDigitalResource as DigitalResource)?.fileType}
            closeHandler={() => handleDigitalResourceActionCompleted(selectedServiceDigitalResource.id)}
          />
        </Modal>
      )}
    </>
  );
};

export default Services;
