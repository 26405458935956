export const specialtyPharmacyCardText = {
  TITLE: 'Your specialty pharmacy',
  DESCRIPTION: 'Here is their information if you need to contact them.',
  CONTACT_NUMBER_LABEL: 'Phone Number',
  ADD_TO_CONTACT_BUTTON_TEXT: 'Add to Contacts',
  SEND_NUMBER_CONFIRM_MODAL_TITLE: 'Add your Specialty Pharmacy to your contacts on your phone',
  SEND_NUMBER_CONFIRM_MODAL_DESCRIPTION:
    'Would you like to receive a text message containing the contact card for your specialty pharmacy?',
  SEND_CONTACT_AFTER_USER_ADD_NUMBER_TITLE: 'Specialty Pharmacy Contact',
  SEND_CONTACT_AFTER_USER_ADD_NUMBER_DESCRIPTION: 'Contact Card has been sent. Please check your mobile device',
  ADD_PHONE_NUMBER_FOR_SPECIALTY_PHARMACY_DESCRIPTION:
    'Enter your phone number so we can send contact card to your phone',
};

// google analytics constants.
const ADD_TO_CONTACT_SPECIALTY_PHARMACY = 'ADD_TO_CONTACT_SPECIALTY_PHARMACY';
const CONTACT_CARD_VIEWED = 'CONTACT_CARD_VIEWED';

export const specialtyPharmacyAnalyticsActions = {
  ADD_TO_CONTACT_SPECIALTY_PHARMACY,
  CONTACT_CARD_VIEWED,
};
