import React from 'react';

import { View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useSelector } from 'react-redux';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { getForwardedSurveyIsExpired } from 'store/selectors';
import { AnyFunction } from 'utils/types';

import { Expired } from './Expired';
import { getStyles } from './styles';
import { SurveyContent } from './SurveyContent';

type SurveyProps = {
  onRequestClose: AnyFunction;
  toastRef: React.MutableRefObject<ToastContainer | null>;
  isSurveyStarted: boolean;
};

export const Survey = ({ onRequestClose, toastRef, isSurveyStarted }: SurveyProps) => {
  const { isMobile } = useDeviceInfoContext();
  const styles = getStyles(isMobile);

  const forwardedSurveyIsExpired = useSelector(getForwardedSurveyIsExpired);

  return (
    <View style={styles.wrapper}>
      {forwardedSurveyIsExpired ? (
        <Expired onRequestClose={onRequestClose} />
      ) : (
        <SurveyContent isSurveyStarted={isSurveyStarted} toastRef={toastRef} onRequestClose={onRequestClose} />
      )}
    </View>
  );
};

export default Survey;
