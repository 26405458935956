import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Dressing = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 95.03 129.4' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M94.94 64.51c0 12-.28 23.95.08 35.91.4 13.48-6.09 21-17.51 24.51a96.6 96.6 0 0 1-57.71.12 29.73 29.73 0 0 1-16.53-12.11A18.68 18.68 0 0 1 0 102.2c.05-23.77 0-47.55.05-71.32C.11 11.2 4.35 7.06 25.6 2.29 40.48-1 55.54-.46 70.5 2.25A53.69 53.69 0 0 1 85.09 7c7.2 3.64 9.78 10 9.83 17.64.08 13.27.02 26.57.02 39.87Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.2}
          d='M48.29 13.17c3 .16 6-.41 8.82.91.78.36 1.61.61 1.67 1.57a2.13 2.13 0 0 1-1.59 2.26 31.27 31.27 0 0 1-18.52-.05A2.07 2.07 0 0 1 37.21 16c-.13-1.29.82-1.56 1.77-2 3.02-1.32 6.2-.6 9.31-.83Z'
        />
        <Path
          fillOpacity={0.1}
          d='M44 13.18a11.73 11.73 0 0 0-5 .87c-.95.42-1.9.69-1.77 2a2.07 2.07 0 0 0 1.46 1.83A34 34 0 0 0 44 19ZM.74 57v45.8s.8 17.67 23.7 22.09c-.2-.66-17.88-14.31-23.7-67.89Z'
        />
        <Path
          d='M69.81 31.67c4.93-.67 14.75-2.53 20.39-6.17a1.26 1.26 0 0 1 1.94 1c.33 6.83.58 27.36.7 39.35a1.26 1.26 0 0 1-2.46.42c-3.16-8.92-9.61-22.91-21.17-32.39a1.25 1.25 0 0 1 .6-2.21Z'
          fillOpacity={0.3}
          fill='#fff'
        />
        <Path
          fillOpacity={0.2}
          d='M38 36.18c1.06.88 2.09 2.61 2.09 13v71.77c0 1.09-.24 4.23-1.82 5.64a4.5 4.5 0 0 1-3 1.74v.18l3.11.36a5 5 0 0 0 1.23-.78c2.6-2.31 2.5-7 2.49-7.17V49.15c0-10.28-.94-12.95-2.81-14.5a8.64 8.64 0 0 0-3.72-1.76C14.61 28.17 7.48 22.55 5.08 19c-2.54-2.11-2.35-4.36-1.8-5.81A21.77 21.77 0 0 0 1.85 16a8 8 0 0 0 .68 2.51c2 4.55 9.31 11.08 32.59 16.32A6.75 6.75 0 0 1 38 36.18Z'
        />
        <Path
          fill='#6d6d6d'
          d='M59.47 50.75c.49-1.34.27-2.94.38-4.42C57.39 41.94 55.2 41 50 42.18c-4.24 1.12-9.48 2.3-11 3-8.32 3.33-15.83 2.06-22.73-3.72-2.67-2.24-5.17-5.48-9.43-3.74-2.41 2.22-2 4.93-1.44 7.51a39.12 39.12 0 0 1 .74 8.9C6.06 62.31 8 64 16.27 62.9c1-.12 1.95-.42 2.94-.49 6.79-.48 13.61-1.08 19.88 2.53 1.23.54 9.59 4.3 12.48 4.9 5.75 1.16 9.52-2.84 7.85-8.41a16.16 16.16 0 0 1 .05-10.68ZM59.47 91.27c.49-1.34.27-2.94.38-4.42-2.46-4.39-4.65-5.31-9.85-4.15-4.24 1.12-9.48 2.3-11 3-8.34 3.3-15.85 2.02-22.75-3.76-2.67-2.24-5.17-5.48-9.43-3.74-2.41 2.22-2 4.93-1.44 7.51a39.12 39.12 0 0 1 .74 8.9c-.06 8.22 1.86 9.88 10.15 8.81 1-.12 1.95-.42 2.94-.49 6.79-.48 13.61-1.08 19.88 2.53 1.23.53 9.59 4.3 12.48 4.9 5.75 1.18 9.52-2.82 7.85-8.41a16.16 16.16 0 0 1 .05-10.68Z'
        />
        <Ellipse cx={47.99} cy={15.56} rx={44.33} ry={15.56} fillOpacity={0.4} fill='#fff' />
      </G>
    </G>
  </Svg>
);

export default Dressing;
