import React from 'react';

import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Suppository = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 67.98 122.18' {...props}>
    <G data-name='Layer 2'>
      <G data-name='Layer 1'>
        <Path
          d='M0 67.6c0-12.15.11-24.3 0-36.44 0-4.41 1.13-8 4.49-11.1 4.65-4.27 9-8.92 13.49-13.34 9.06-8.85 21.74-9 30.76-.26C54 11.55 59.08 16.85 64.35 21.93A11.71 11.71 0 0 1 68 30.86v75.4c0 5.35-3.16 8.45-7.38 10.93-5.62 3.31-12 4-18.19 4.56-10.09.87-20.25.58-30.07-2.53C3.09 116.3 0 112.11 0 102.56Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M46.56 11.8A1.5 1.5 0 0 0 48 9.3l-.66-.66C39.19.41 27.68.52 19.46 8.88h-.05a1.5 1.5 0 0 0 1.47 2.5 49.15 49.15 0 0 1 25.68.42Z'
          fillOpacity={0.4}
          fill='#fff'
        />
        <Path
          d='M64.84 92.83V32.55q0-.67-.06-1.32a2.07 2.07 0 0 0-2.39-1.87 175.76 175.76 0 0 1-20.14 1.8 2.56 2.56 0 0 0-1.67 4.41c9.63 9.3 20.7 26.43 24.26 57.26Z'
          fillOpacity={0.2}
          fill='#fff'
        />
        <Path
          d='M20.48 119.6c-4.57-.67-12.15-2.41-16.35-6.83a9 9 0 0 1-2.42-6.12c-.09-12.78 0-64.94 0-64.94s.78 31.22 21 75a2.09 2.09 0 0 1-2.23 2.89Z'
          fillOpacity={0.1}
        />
      </G>
    </G>
  </Svg>
);

export default Suppository;
