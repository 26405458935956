import apiRequest, { apiPrefix } from 'utils/network';

import { SwitchingProgramsReturnData, AuthWhiteLabelingReturnData } from './types';

const SWITCHING_PROGRAMS_URL = '/patient/switch_program/';
const PARTNER_PROGRAM_WHITE_LABELING_PROGRAMS_URL = '/api/v1/partner-registration/white_labeling/';

export const SwitchingProgramsInterface = {
  switch: ({ manufacturer_program_uuid }: { manufacturer_program_uuid: string }) =>
    apiRequest<SwitchingProgramsReturnData>({
      method: 'POST',
      body: { manufacturer_program_uuid },
      endpoint: SWITCHING_PROGRAMS_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    }),
  getPartnerRegistrationWhiteLabeling: (programId: string) =>
    apiRequest<AuthWhiteLabelingReturnData>({
      method: 'GET',
      params: { partner_program_uuid: programId },
      endpoint: PARTNER_PROGRAM_WHITE_LABELING_PROGRAMS_URL,
      baseURL: apiPrefix,
    }),
};
