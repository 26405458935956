import apiRequest, { apiPrefix } from 'utils/network';

import type { PartnerRegistrationReturnData } from './types';

const PARTNER_REGISTRATION_URL = '/api/v1/partner-registration';

export const PartnerRegistrationInterface = {
  get: (): Promise<PartnerRegistrationReturnData> => {
    return apiRequest({
      endpoint: PARTNER_REGISTRATION_URL + '/',
      baseURL: apiPrefix,
      method: 'GET',
    });
  },
  patch: ({ data }: PartnerRegistrationReturnData): Promise<PartnerRegistrationReturnData> => {
    return apiRequest({
      endpoint: PARTNER_REGISTRATION_URL,
      method: 'PATCH',
      body: data,
      baseURL: apiPrefix,
    });
  },
};
