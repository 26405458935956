import * as ActionTypes from 'store/action-types';
import { CustomServiceType } from 'store/reducers/additional-services/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

export type SetConsentHasBeenViewed = (payload: {
  id: string;
  groupType: ConsentModalTypeEnum;
  isViewed: boolean;
  customServiceType?: CustomServiceType;
}) => {
  type: typeof ActionTypes.SET_CONSENT_HAS_BEEN_VIEWED;
  payload: typeof payload;
};

export const setConsentHasBeenViewed: SetConsentHasBeenViewed = (payload) => ({
  type: ActionTypes.SET_CONSENT_HAS_BEEN_VIEWED,
  payload,
});
