import { useEffect, useState, useRef } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { TrackerContentProps } from 'screens/main/MedicationTracker/TrackerContent';
import { getReminderModalVisibility, getMedicationWizardVisibility } from 'store/selectors';

/**
 * @description Will be refetched when user closes add reminder/medication modal
 */
export const useRefetchMedTracker = (getMedTrackerData: TrackerContentProps['getMedTrackerData']) => {
  const isReminderModalVisibility = useSelector(getReminderModalVisibility);
  const isMedicationWizardVisibility = useSelector(getMedicationWizardVisibility);

  const isFocused = useIsFocused();

  const wasOpenedReminderModal = useRef(false);
  const wasOpenedMedicationModal = useRef(false);
  const [needRefresh, setNeedRefresh] = useState(false);

  useEffect(() => {
    if (isFocused && needRefresh) {
      getMedTrackerData();
      setNeedRefresh(false);
    }
  }, [getMedTrackerData, isFocused, needRefresh]);

  useEffect(() => {
    if (isReminderModalVisibility) wasOpenedReminderModal.current = true;
    else if (wasOpenedReminderModal.current) {
      wasOpenedReminderModal.current = false;
      setNeedRefresh(true);
    }
    if (isMedicationWizardVisibility) wasOpenedMedicationModal.current = true;
    else if (wasOpenedMedicationModal.current) {
      wasOpenedMedicationModal.current = false;
      setNeedRefresh(true);
    }
  }, [isMedicationWizardVisibility, isReminderModalVisibility]);
};
