import React from 'react';

import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/Button';
import {
  clearConsentModalsQueue,
  removeCurrentlyActiveConsent,
  removeCurrentlyActiveService,
  resetUserInfo,
  setUserHasPsp,
} from 'store/actions';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import { getCurrentlyActiveConsent } from 'store/selectors';
import { DefaultText, useConsentStyles } from 'style/common';
import { AnyFunction } from 'utils/types';

export type DeclineConsentProps = {
  modalOnClose: AnyFunction;
};

export const DeclineConsent = ({ modalOnClose }: DeclineConsentProps) => {
  const dispatch = useDispatch();

  const currentlyActiveConsent = useSelector(getCurrentlyActiveConsent);

  const answerYesHandler = () => modalOnClose();
  const answerNoHandler = () => {
    if (currentlyActiveConsent?.groupType === ConsentModalTypeEnum.app) {
      dispatch(resetUserInfo());
      return;
    }
    if (currentlyActiveConsent?.groupType === ConsentModalTypeEnum.program) {
      dispatch(setUserHasPsp(false));
      dispatch(clearConsentModalsQueue());
      dispatch(removeCurrentlyActiveConsent());
      modalOnClose();
      return;
    }
    if (currentlyActiveConsent?.groupType === ConsentModalTypeEnum.service) {
      dispatch(clearConsentModalsQueue());
      dispatch(removeCurrentlyActiveConsent());
      dispatch(removeCurrentlyActiveService());
      modalOnClose();
      return;
    }
  };

  const consentStyles = useConsentStyles();

  return (
    <View>
      <DefaultText style={consentStyles.closeModalText}>
        Consent is required to proceed. Do you wish to continue?
      </DefaultText>
      <View style={consentStyles.closeModalButtonsWrapper}>
        <Button variant='secondary' style={consentStyles.yesNoButtonMain} onPress={answerYesHandler} text='Yes' />
        <Button
          variant='tertiary'
          onPress={answerNoHandler}
          style={[consentStyles.closeNoAnswerButton, consentStyles.yesNoButtonMain]}
          text='No'
        />
      </View>
    </View>
  );
};

export default DeclineConsent;
