import React from 'react';

import { Pressable, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { SurveyInterface } from 'api';
import { Close } from 'assets/icons';
import { Card } from 'components/Card';
import { removeSelectedSurvey, removeSelectedSurveyId, setSelectedSurveyId, setShouldShowSurvey } from 'store/actions';
import { SurveyStatusEnum } from 'store/reducers/user-info/types/survey';
import { selectSurveyById } from 'store/selectors';
import { DefaultText, webStyles } from 'style/common';

import { useSurveyCardStyles } from './styles';
import TemplateBox from './TemplateBox';

const headerText = 'Take a quick survey.';
const description = '';
const clickButtonText = 'Click to start your survey';

const inactiveSurveyStatuses = [SurveyStatusEnum.COMPLETED, SurveyStatusEnum.REJECTED];

export const SurveyCard = ({ surveyId }: { surveyId?: string }) => {
  const { isMobile, ...theme } = useTheme();
  const unnormalizedSurveyData = useSelector(selectSurveyById(surveyId));

  const styles = useSurveyCardStyles();

  const dispatch = useDispatch();

  const closeCardHandler = () => {
    if (unnormalizedSurveyData) {
      SurveyInterface.post({
        ...unnormalizedSurveyData,
        status: SurveyStatusEnum.REJECTED,
        percent_of_complete: unnormalizedSurveyData.percent_of_complete || 0,
      });
      dispatch(removeSelectedSurvey({ id: unnormalizedSurveyData.user_survey_uuid }));
      dispatch(removeSelectedSurveyId());
    }
  };

  const startSurveyHandler = async () => {
    if (surveyId) {
      dispatch(setSelectedSurveyId(surveyId));
      dispatch(setShouldShowSurvey(true));
    }
  };

  const dontShowSurveyCard = inactiveSurveyStatuses.some((status) => status === unnormalizedSurveyData?.status);

  if (dontShowSurveyCard) return null;

  return (
    <Card isNotificationCard isTouchable pressHandler={startSurveyHandler}>
      <View style={styles.surveyCardWrapper}>
        <Pressable
          onPress={closeCardHandler}
          style={(state) => [
            styles.closeButtonWrapper,
            webStyles.button,
            styles.closeButton,
            // @ts-ignore
            state.hovered && styles.hoverButton,
          ]}>
          <Close stroke='#fff' />
        </Pressable>
        <View style={styles.overBox}>
          <View>
            <DefaultText style={styles.header}>{unnormalizedSurveyData?.title || headerText}</DefaultText>
            <DefaultText numberOfLines={5} style={styles.description}>
              {unnormalizedSurveyData?.subtext || description}
            </DefaultText>
            <TouchableOpacity onPress={startSurveyHandler} style={webStyles.button}>
              <DefaultText style={styles.clickButtonText}>{clickButtonText}</DefaultText>
            </TouchableOpacity>
          </View>
        </View>
        <TemplateBox />
      </View>
    </Card>
  );
};

export default SurveyCard;
