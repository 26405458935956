import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

type DeleteIconProps = {
  d?: string;
  size?: 'small' | 'medium';
};

const dSizes: Record<Required<DeleteIconProps>['size'], string> = {
  small:
    'M1.792 3.546h12.416M5.162 3.8V2.4c0-.371.15-.727.416-.99.266-.262.627-.41 1.003-.41h2.838c.377 0 .737.147 1.004.41.266.263.415.619.415.99v1.4m2.129 0v9.8c0 .371-.15.727-.416.99-.266.262-.627.41-1.003.41H4.452c-.376 0-.737-.148-1.003-.41a1.39 1.39 0 0 1-.416-.99V3.8h9.934Z',
  medium:
    'M2 5h20M7.429 5.4V3.2c0-.583.24-1.143.669-1.556A2.332 2.332 0 0 1 9.714 1h4.572c.606 0 1.187.232 1.616.644.429.413.67.973.67 1.556v2.2M20 5.4v15.4c0 .584-.24 1.143-.67 1.556a2.331 2.331 0 0 1-1.616.644H6.286a2.332 2.332 0 0 1-1.617-.644A2.159 2.159 0 0 1 4 20.8V5.4h16Z',
};
const sizes: Record<Required<DeleteIconProps>['size'], number> = {
  small: 16,
  medium: 24,
};

const Delete = ({ size = 'medium', ...props }: DeleteIconProps & SvgProps) => (
  <Svg width={sizes[size]} height={sizes[size]} fill='none' {...props}>
    <Path
      d={props.d || dSizes[size]}
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Delete;
