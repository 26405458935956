import { reduxStore } from 'store';
import type { UnnormalizedSurvey } from 'store/reducers/user-info/types/survey';
import { SurveyEnvEnum } from 'store/reducers/user-info/types/survey';
import apiRequest, { apiPrefix } from 'utils/network';

import { SurveysReturnData } from './types';

const SURVEY_URL = '/surveys_captured/';

export const SurveyInterface = {
  get: ({ environment }: { environment: SurveyEnvEnum }): Promise<SurveysReturnData> => {
    const token = reduxStore.getState().userInfo.token;
    return apiRequest({
      method: 'GET',
      headers: { 'Tc-User': token },
      endpoint: SURVEY_URL,
      params: { environment },
      baseURL: apiPrefix,
      isProgram: true,
    });
  },
  post: (body: UnnormalizedSurvey): Promise<SurveysReturnData> => {
    const token = reduxStore.getState().userInfo.token;
    return apiRequest({
      method: 'POST',
      headers: { 'Tc-User': token },
      endpoint: SURVEY_URL,
      baseURL: `${apiPrefix}/api/v1`,
      body,
    });
  },
};
