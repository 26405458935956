import { ThunkDispatch } from 'redux-thunk';

import { PartnerRegistrationInterface } from 'api';
import { PartnerRegistrationReturnData } from 'api/PartnerRegistrationInterface/types';
import { UserTypeEnum } from 'enums';
import { setBasicUserInformation } from 'store/actions/user/actionCreators/setBasicUserInformation';
import { Insurance } from 'store/reducers/user-info/types/insurance';
import { addDashesForPhoneNumber } from 'utils/convertString';
import { handlePromise } from 'utils/handlePromise';
import { transformNullToEmptyValue } from 'utils/helpers';

export const initPartnerRegistration = async (
  dispatch: ThunkDispatch<unknown, unknown, ReturnType<typeof setBasicUserInformation>>
) => {
  const [res] = await handlePromise<PartnerRegistrationReturnData>(PartnerRegistrationInterface.get);

  if (!res) return;

  const {
    data: {
      firstName,
      lastName,
      email,
      preferredName,
      pointOfContact,
      type,
      insurances,
      emailPreference,
      mailPreference,
      cellPhonePreference,
      pushNotificationPreference,
      next_login = true,
      is_demo = false,
      phoneNumber,
      ...rest
    },
  } = res;

  const parsedInsurances = (insurances || []).map((item) => transformNullToEmptyValue(item)) as unknown as Insurance[];

  dispatch(
    setBasicUserInformation({
      userFirstName: firstName || '',
      userLastName: lastName || '',
      userPreferredName: preferredName || '',
      email: email || '',
      contactPersonName: pointOfContact || '',
      insurances: parsedInsurances,
      userType: type || UserTypeEnum.PATIENT,
      hasEmailPreference: emailPreference,
      hasMailPreference: mailPreference,
      hasCellPhonePreference: cellPhonePreference,
      hasPushNotificationPreference: pushNotificationPreference,
      showWelcomeCard: !next_login,
      phoneNumber: addDashesForPhoneNumber(phoneNumber ?? ''),
      isDemo: is_demo,
      ...rest,
    })
  );
};
