import { Platform, StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { defaultTheme } from 'style/theme';

const borderStyles = {
  borderColor: defaultTheme.colors.gray3,
  borderRadius: 8,
  borderWidth: 1,
};

export const disabledStyles = {
  backgroundColor: defaultTheme.colors.gray4,
  borderRadius: 6,
  color: defaultTheme.colors.gray3,
  borderColor: defaultTheme.colors.gray3,
};

const androidStyles = {
  input: {
    height: 50,
    paddingLeft: 16,
    fontSize: 16,
    lineHeight: 19.5,
    fontFamily: defaultTheme.fontFamilies.default,
  },
};

const iosStyles: Record<keyof typeof androidStyles, ViewStyle | TextStyle> = {
  input: {
    height: 50,
    paddingLeft: 16,
    fontSize: 16,
    fontFamily: defaultTheme.fontFamilies.default,
  },
};

export const styles = StyleSheet.create({
  container: {
    zIndex: 1,
  },
  inputContainer: {
    ...borderStyles,
  },
  list: {
    ...borderStyles,
    paddingTop: 12,
    paddingLeft: 16,
    backgroundColor: defaultTheme.colors.white,
    borderTopWidth: 0,
    left: 0,
    right: 0,
    flexGrow: 1,
    maxHeight: 100,
  },
  ...Platform.select({
    android: androidStyles,
    ios: iosStyles,
    default: iosStyles,
  }),
  infoText: {
    paddingBottom: 13,
  },
});
