import { SetBasicUserInformation } from 'store/actions/user/actionCreators/setBasicUserInformation';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setBasicUserInfo = (state = INITIAL_STATE, payload: ReturnType<SetBasicUserInformation>['payload']): User => {
  const payloadKeys = Object.keys(payload) as (keyof typeof payload)[];
  return {
    ...state,
    ...(payloadKeys.length &&
      payloadKeys.reduce<Record<string, unknown>>((accumulator, value) => {
        accumulator[value] = payload[value];
        return accumulator;
      }, {})),
  };
};

export default setBasicUserInfo;
