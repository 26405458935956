import React from 'react';

import { HorizontalButtons } from 'components/HorizontalButtons';
import { HorizontalButtonProps } from 'components/HorizontalButtons/types';
import { MeridiemTypes } from 'components/TimePicker/types';

const AM_PM_BUTTONS: HorizontalButtonProps<MeridiemTypes>[] = [
  { id: MeridiemTypes.AM, title: 'AM' },
  { id: MeridiemTypes.PM, title: 'PM' },
];

const MERIDIEM_BUTTON_WIDTH = 52;

type Props = {
  initialValue?: MeridiemTypes;
  value?: MeridiemTypes;
  onMeridiemChange?: (id: MeridiemTypes) => void;
  testID?: string;
};

export const MeridiemPicker = ({ initialValue, value, testID, onMeridiemChange }: Props) => (
  <HorizontalButtons
    buttons={AM_PM_BUTTONS}
    buttonWidth={MERIDIEM_BUTTON_WIDTH}
    onChange={onMeridiemChange}
    initialId={initialValue}
    value={value}
    testID={testID}
  />
);
