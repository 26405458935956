import { Dispatch } from 'react';

import qs from 'query-string';

import { AnyObject } from 'utils/types';

export const getQueryParams = <T extends AnyObject>(params: T) =>
  `?${qs.stringify(params, { encode: false, skipNull: true })}`;

export const makeRequestWithLoader = async <T>(request: () => Promise<T>, setLoading: Dispatch<boolean>) => {
  setLoading(true);

  const data = await request();

  setLoading(false);

  return data;
};
