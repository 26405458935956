import { SET_WEB_TOUR, SET_CURRENT_TOUR_STEP, SET_TOUR_ACTIVE } from 'store/action-types';
import { AvailableActionsForTour } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { TourInfo } from './types';

const tour = (state = INITIAL_STATE, action: AvailableActionsForTour): TourInfo => {
  switch (action.type) {
    case SET_WEB_TOUR:
      return {
        ...state,
        startWebTour: action.payload,
      };
    case SET_TOUR_ACTIVE:
      return {
        ...state,
        isTourActive: action.payload,
      };
    default:
      return state;
  }
};

export default tour;
