import { MilestoneTrackerData, Milestone } from 'api/Benefits/types';
import { MilestonesTrackerStatus } from 'components/HistoryItem/types';
import { DemoMilestoneTrackerState } from 'store/reducers/demoConfig/types';

const mockDateString = new Date().toDateString();

const milestoneSteps: Milestone[] = [
  {
    createdAt: mockDateString,
    uuid: '0',
    title: 'Enrollment Received',
    userData: {
      uuid: '0',
      createdAt: mockDateString,
      updatedAt: mockDateString,
      name: 'Enrollment has been received',
      description: 'See where you are in your coverage determination process.',
    },
    milestoneStatus: 'Not started',
  },
  {
    createdAt: mockDateString,
    uuid: '1',
    title: 'Benefits Determined',
    userData: {
      uuid: '1',
      createdAt: mockDateString,
      updatedAt: mockDateString,
      name: 'Your benefits have been determined.',
      description: 'See where you are in your coverage determination process.',
    },
    milestoneStatus: 'Not started',
  },
  {
    createdAt: mockDateString,
    uuid: '2',
    title: 'Prescription Sent',
    userData: {
      uuid: '2',
      createdAt: mockDateString,
      updatedAt: mockDateString,
      name: 'Prescription has been sent.',
      description: 'See where you are in your coverage determination process.',
    },
    milestoneStatus: 'Not started',
  },
  {
    createdAt: mockDateString,
    uuid: '3',
    title: 'Medication Shipped',
    userData: {
      uuid: '3',
      createdAt: mockDateString,
      updatedAt: mockDateString,
      name: 'Medications have been shipped.',
      description: 'See where you are in your coverage determination process.',
    },
    milestoneStatus: 'Not started',
  },
  {
    createdAt: mockDateString,
    uuid: '4',
    title: 'Medication Delivered',
    userData: {
      uuid: '4',
      createdAt: mockDateString,
      updatedAt: mockDateString,
      name: 'Medications have been delivered.',
      description: 'See where you are in your coverage determination process.',
    },
    milestoneStatus: 'Not started',
  },
];

const baseTrackerData: MilestoneTrackerData = {
  createdAt: mockDateString,
  isHidden: false,
  trackerStatus: 'Not started',
  uuid: '0',
  name: 'We’ve received your enrollment into this program',
  description: 'Welcome! You’ve successfully enrolled in this support program.',
  milestones: [],
  meta: [],
};

const completedMilestoneTrackerProps: MilestoneTrackerData = {
  ...baseTrackerData,
  trackerStatus: 'Complete',
  milestones: milestoneSteps.map((milestone) => ({
    ...milestone,
    milestoneStatus: 'Complete' as MilestonesTrackerStatus,
  })),
};

export const demoTrackerPropsMock: Record<DemoMilestoneTrackerState, MilestoneTrackerData> = {
  INITIAL: {
    ...baseTrackerData,
    milestones: [
      ...milestoneSteps.slice(0, 2).map((milestone) => ({
        ...milestone,
        milestoneStatus: 'Complete' as MilestonesTrackerStatus,
      })),
      ...milestoneSteps.slice(2),
    ],
  },
  AFTER_FORWARD_BUTTON_PRESS: completedMilestoneTrackerProps,
  CLOSED: completedMilestoneTrackerProps,
};
