import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useCheckBoxWithLabelStyles = () => {
  const { colors, fontSizes } = useTheme();

  return StyleSheet.create({
    checkboxWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
    },
    checkText: {
      ...fontSizes[16],
      width: '88%',
      marginLeft: 10,
      color: colors.gray1,
    },
  });
};
