import { StyleSheet } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';

export const useIndicatorStyles = () => {
  const { colors, fontSizes, fontFamilies } = useTheme();

  return StyleSheet.create({
    subTitle: { marginBottom: 16 },
    standardText: {
      color: colors.gray2,
      marginBottom: 8,
    },
    usedValueText: {
      color: colors.gray1,
      ...fontSizes[24],
      fontFamily: fontFamilies.bold,
    },
    indicesWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    profitValueWrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    arrowStyle: {
      borderTopWidth: 0,
      borderRightWidth: 8,
      borderBottomWidth: 8,
      borderLeftWidth: 8,
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: colors.primary,
      borderRadius: 1,
      borderLeftColor: 'transparent',
      marginTop: 3,
    },
  });
};

const remainingValueTextWidth = 110;

export const calcRemainingTextPosition = (indicatorValue: number) => {
  const defaultPosition = remainingValueTextWidth / 2 - 10;

  if (indicatorValue > 15 && indicatorValue < 85) return defaultPosition;

  if (indicatorValue <= 15) return defaultPosition <= 65 ? 65 : defaultPosition;

  if (indicatorValue >= 85) return defaultPosition >= 25 ? 25 : defaultPosition;
};

export const IndicatorWrapper = styled.View`
  margin-right: 23px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
      margin-bottom: 13px;
    `}
`;

export const IndicatorDisplayWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
`;

export const IndicatorDisplayText = styled(DefaultText)`
  color: ${(props) => props.theme.colors.gray2};
`;

export const RemainingValueTextWrapper = styled.View<{ indicatorValue: number }>`
  position: absolute;
  right: -${({ indicatorValue }) => calcRemainingTextPosition(indicatorValue)}px;
  top: 30px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      top: 24px;
    `}
`;

export const RemainingValueText = styled(DefaultText)<{ bold?: boolean }>`
  ${getFontStyles(16)};
  font-family: ${(props) => (props.bold ? props.theme.fontFamilies.semiBold : props.theme.fontFamilies.default)};
  color: ${(props) => props.theme.colors.gray1};
  text-align: center;
  min-width: 96px;
`;

export const BarWrapper = styled.View<{ shouldShowRemainingText?: boolean }>`
  position: relative;
  margin-bottom: ${(props) => (props.shouldShowRemainingText ? 52 : 0)}px;
`;
export const GrayIndicatorBar = styled.View`
  width: 100%;
  height: 16px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.gray3};
  position: absolute;
`;

export const IndicatorBorderWrapper = styled.View<{ width?: string }>`
  height: 24px;
  width: ${(props) => props.width || 0};
  border-right-width: ${(props) => (props.width === '0%' ? 0 : 2)}px;
  border-right-color: black;
  position: relative;
`;

export const ProgressIndicator = styled.View`
  width: 100%;
  height: 16px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${(props) => props.theme.colors.primary};
`;
