import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation } from '@react-navigation/native';
import { Image, ImageSourcePropType, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { FinancialAssistanceProgramDTO } from 'api/FinancialAssistanceInterface/types';
import { Card, Spacer } from 'components';
import { useLayout } from 'hooks';
import { Routes } from 'routes/main';
import { useFAOverviewStyles } from 'screens/main/Home/components/FinancialAssistanceOverview/styles';
import { CopayOverviewIndicators, ImageWrapper } from 'screens/main/Home/components/index';
import { DetailsText, OverViewHeaderWrapper, OverViewText } from 'screens/main/Home/style';
import { setSelectedCopayCard } from 'store/actions';
import { CopayCardInfo } from 'store/reducers/user-info/types/copay-card';
import { getProgramCopayCards, getSelectedCopayCard } from 'store/selectors';
import { commonStyles, DefaultText, StyledImage, webStyles } from 'style/common';
import { useIsBreakpoint, useStylesWithAdditional } from 'style/hooks';
import { getMediaUrl } from 'utils/helpers';

const defaultHeaderText = 'Patient Assistance Program';
const defaultDescription =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

export const FinancialAssistanceOverview = ({ fapData }: { fapData: FinancialAssistanceProgramDTO }) => {
  const dispatch = useDispatch();
  const selectedCopayCard = useSelector(getSelectedCopayCard);
  const currentCopayCards = useSelector(getProgramCopayCards);

  const { onLayout: onLayoutCopayCard, width: copayCardWidth } = useLayout();

  const logo: ImageSourcePropType = { uri: getMediaUrl(fapData?.image) };

  const isMd = useIsBreakpoint('md');

  const { styles, isMobile } = useStylesWithAdditional(useFAOverviewStyles, {
    copayCardWidth,
    overviewWithoutImage: !logo.uri,
    currentCopayCardsLength: currentCopayCards.filter((c) => c.type).length,
  });

  const detailText = isMobile ? 'View your Financial Assistance' : 'Click for details';
  const headerText = fapData.name || defaultHeaderText;
  const description = fapData.description || defaultDescription;

  const isTwoCopayCard = currentCopayCards.filter((c) => c.type).length === 2;

  const navigation = useNavigation<DrawerNavigationHelpers>();

  const handleCopayCardClick = (copayCard?: CopayCardInfo) => {
    !!copayCard && dispatch(setSelectedCopayCard(copayCard));
    navigation.navigate(Routes.FINANCIAL_ASSISTANCE);
  };

  if (!fapData.type) return null;

  const copayMetricsJSX = isTwoCopayCard ? (
    <>
      {currentCopayCards.map((copayCard, i) => (
        <>
          <View key={copayCard.id} onLayout={onLayoutCopayCard} style={[styles.copayCard, commonStyles.boxShadowStyle]}>
            <ImageWrapper styles={styles.copayImageWrapper} clickHandler={() => handleCopayCardClick(copayCard)}>
              <StyledImage style={styles.styledImage} source={{ uri: copayCard?.fileUrl }} resizeMode='stretch' />
            </ImageWrapper>
          </View>
          {!i && <Spacer size={24} orientation={isMd ? 'vertical' : 'horizontal'} />}
        </>
      ))}
    </>
  ) : (
    <>
      <View onLayout={onLayoutCopayCard} style={[styles.copayCard, commonStyles.boxShadowStyle]}>
        <ImageWrapper styles={styles.copayImageWrapper} clickHandler={() => handleCopayCardClick(selectedCopayCard)}>
          <StyledImage style={styles.styledImage} source={{ uri: selectedCopayCard?.fileUrl }} resizeMode='stretch' />
        </ImageWrapper>
      </View>
      <Spacer size={24} orientation={isMd ? 'vertical' : 'horizontal'} />
      <CopayOverviewIndicators selectedCopayCard={selectedCopayCard} handleCopayCardClick={handleCopayCardClick} />
    </>
  );

  return (
    <>
      <OverViewHeaderWrapper>
        <OverViewText>Overview</OverViewText>
        <TouchableOpacity onPress={() => handleCopayCardClick()} style={webStyles.button}>
          <DetailsText>{detailText}</DetailsText>
        </TouchableOpacity>
      </OverViewHeaderWrapper>
      {fapData?.type === 'COPAY' ? (
        <View style={styles.copayOverviewContentWrapper}>{copayMetricsJSX}</View>
      ) : (
        <View style={styles.contentWrapper}>
          {!!logo.uri && (
            <Card style={StyleSheet.flatten(styles.cardContainer)}>
              <View style={styles.logoWrapper}>
                <Image source={logo} style={styles.logo} />
              </View>
            </Card>
          )}
          <Card style={StyleSheet.flatten(styles.cardContainer)}>
            <View style={styles.textBoxWrapper}>
              <DefaultText style={styles.header} numberOfLines={2}>
                {headerText}
              </DefaultText>
              <DefaultText style={styles.aboutProgramText}>About this program</DefaultText>
              <DefaultText style={styles.description} numberOfLines={8}>
                {description}
              </DefaultText>
            </View>
          </Card>
        </View>
      )}
    </>
  );
};

export default FinancialAssistanceOverview;
