import produce from 'immer';

import { SetConsents } from 'store/actions/user/actionCreators/consents/setConsents';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';
import { AppConsent, ProgramConsent, ServiceConsent } from 'store/reducers/user-info/types/consent';
import { consentSchema } from 'store/schemas/consents';
import { normalizeConsentsData } from 'store/schemas/normalizeData';
import { convertCamelCaseToSnake } from 'utils/convertString';

const setConsents = (state = INITIAL_STATE, payload: ReturnType<SetConsents>['payload']): User => {
  // Need to convert consents' keys due to inconsistency of the API's response
  const consentsWithConvertedKeys = payload.consents.map((consent) =>
    Object.fromEntries(Object.entries(consent).map(([key, value]) => [convertCamelCaseToSnake(key), value]))
  ) as (ProgramConsent | AppConsent | ServiceConsent)[];

  // Add groupType (app | program | service)
  const consentsWithGroupType = consentsWithConvertedKeys.map((consent) => ({
    ...consent,
    groupType: payload.groupType,
  })) as (ProgramConsent | AppConsent | ServiceConsent)[];

  // Add customServiceType (actions | digitalResources | requests), if present
  const consentsWithCustomServiceType = payload.customServiceType
    ? consentsWithGroupType.map((consent) => ({
        ...consent,
        customServiceType: payload.customServiceType,
      }))
    : consentsWithGroupType;

  // Normalize consents to reduce algorithms complexity in data handling
  const normConsents = normalizeConsentsData<AppConsent | ProgramConsent | ServiceConsent>(
    consentsWithCustomServiceType,
    [consentSchema]
  );

  const nextConsentModalsState = produce(state.consentModalsState, (draftConsentModalsState) => {
    const currentConsents = draftConsentModalsState[payload.groupType];
    if (currentConsents?.result?.length) {
      for (const incomingConsent of consentsWithCustomServiceType) {
        currentConsents.entities.consents[incomingConsent.id] = incomingConsent;
        if (!(incomingConsent.id in currentConsents.result)) {
          currentConsents.result.push(incomingConsent.id);
        }
      }
      return;
    }
    draftConsentModalsState[payload.groupType] = normConsents as typeof currentConsents;
  });

  return {
    ...state,
    consentModalsState: nextConsentModalsState,
  };
};

export default setConsents;
