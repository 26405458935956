import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, ImageSourcePropType, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface } from 'api/MedicineCabinetInterface';
import { Plus } from 'assets/icons';
import { AppScrollView, Button, DownloadCard, Footer, ReminderModal, TransitionWrapper } from 'components';
import { AppScrollViewRef } from 'components/AppScrollView/types';
import { WEB_STEPS_ORDER } from 'components/Tourguide/constants';
import { WebTutorialWrapper } from 'components/Tourguide/WebTutorialWrapper';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useImageSizes } from 'hooks';
import { SessionExpiredModal } from 'screens/auth/Login/components';
import {
  setMedicationWizardStep,
  setMedicationWizardVisibility,
  setReminderModalVisibility,
  setReminders,
  setSelectedMedicationId,
} from 'store/actions';
import { getSelectedReminder, getUserFirstName } from 'store/selectors';
import { DefaultText, webStyles } from 'style/common';
import { appThemeSizes } from 'style/constants';
import { isNativeDevice } from 'utils/helpers';
import { TEST_ID } from 'utils/testIds/constants';

import { MedicationWizard } from '../components/MedicationWizard';
import { MedicineCenterHeader } from './header';
import {
  LeftSideContentWrapper,
  MCContentArea,
  MCContentContainer,
  RightSideContentWrapper,
  SafeViewWrapper,
  useGetStyles,
} from './styles';

type LayoutProps = {
  title?: string;
  imageUrl?: string;
  isMedSectors?: boolean;
  showFastForward?: boolean;
  shouldShowGreeting?: boolean;
  withDownloadCard?: boolean;
  isMedCabinetEmpty?: boolean;
  children: React.ReactNode;
  subSectionJSX?: JSX.Element;
  navigation?: DrawerNavigationHelpers;
  contentAreaStyle?: StyleProp<ViewStyle>;
  mobileContentAreaStyle?: StyleProp<ViewStyle>;
  scrollViewRef?: React.RefObject<AppScrollViewRef>;
};

export const MedicineCabinetLayout = ({
  title,
  children,
  imageUrl,
  navigation,
  isMedSectors,
  subSectionJSX,
  scrollViewRef,
  contentAreaStyle,
  shouldShowGreeting,
  withDownloadCard = true,
  isMedCabinetEmpty = true,
}: LayoutProps) => {
  const { isMobile, width } = useDeviceInfoContext();
  const heightValue = isMobile ? width : width - 2 * appThemeSizes.contentHorizontalPadding;
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const { imageHeight } = useImageSizes({
    url: imageUrl,
    widthValue: 200,
    isSrc: true,
    heightValue,
  });

  const selectedReminder = useSelector(getSelectedReminder);
  const userFirstName = useSelector(getUserFirstName);

  const styles = useGetStyles(imageHeight);

  const greeting = `Hello ${userFirstName ? userFirstName : 'guest'}!`;

  const reminderModalSubmitHandler = () => {
    dispatch(setReminderModalVisibility(false));
    MedicineCabinetInterface.getReminders().then((resp) => {
      dispatch(setReminders(resp.data.results));
    });
  };

  const addMedicationHandler = async () => {
    dispatch(setSelectedMedicationId(null));
    dispatch(setMedicationWizardStep(0));
    dispatch(setMedicationWizardVisibility(true));
  };

  const backgroundImageJSX = (
    <ImageBackground style={styles.imageBackground} source={imageUrl as ImageSourcePropType} />
  );

  const topContentJSX = subSectionJSX ? (
    <>
      {!isMobile && backgroundImageJSX}
      {subSectionJSX}
    </>
  ) : (
    backgroundImageJSX
  );

  const titleContentJSX = (
    <View style={styles.titleWrapper}>
      <DefaultText style={styles.titleText}>{shouldShowGreeting ? greeting : title}</DefaultText>
      {!shouldShowGreeting && (
        <>
          {isMobile ? (
            <TouchableOpacity
              testID={TEST_ID.MEDICATION_LIST_ADD_NEW_MEDICATION_PLUS_BUTTON}
              style={styles.plusIconWrapper}
              onPress={addMedicationHandler}>
              <Plus />
            </TouchableOpacity>
          ) : (
            <Button
              variant={isMedCabinetEmpty ? 'ghost' : 'mango'}
              testID={TEST_ID.MEDICATION_LIST_ADD_NEW_MEDICATION_BUTTON}
              style={webStyles.button}
              text='Add Medication'
              onPress={addMedicationHandler}
              disabled={undefined}
            />
          )}
        </>
      )}
    </View>
  );

  return (
    <SafeViewWrapper>
      <MedicineCenterHeader navigation={navigation} />
      <AppScrollView ref={scrollViewRef} withRightMargin>
        {isFocused ? (
          <TransitionWrapper>
            <MCContentArea>
              {topContentJSX}
              <MCContentContainer>
                {isMedSectors && titleContentJSX}
                <View style={[styles.contentWrapper, contentAreaStyle]}>
                  <LeftSideContentWrapper style={styles.leftSide} size={3}>
                    {children}
                  </LeftSideContentWrapper>
                  {withDownloadCard && !isNativeDevice && (
                    <RightSideContentWrapper style={styles.rightSide}>
                      <WebTutorialWrapper order={WEB_STEPS_ORDER.DOWNLOAD_CARD}>
                        <DownloadCard />
                      </WebTutorialWrapper>
                    </RightSideContentWrapper>
                  )}
                </View>
              </MCContentContainer>
            </MCContentArea>
            <Footer />
            <MedicationWizard />
            <ReminderModal
              id={selectedReminder?.id}
              initialValues={selectedReminder}
              submitHandler={reminderModalSubmitHandler}
            />
          </TransitionWrapper>
        ) : null}
      </AppScrollView>
      <SessionExpiredModal />
    </SafeViewWrapper>
  );
};
