import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function Check(props: SvgProps) {
  return (
    <Svg width={20} height={20} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M1.714 12l8 8.571L22.286 3.428'
        stroke={props.stroke || '#000'}
        strokeWidth={props.strokeWidth || 3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default Check;
