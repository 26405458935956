import * as ActionTypes from 'store/action-types';

export type SetUserCognitoId = (payload: string) => {
  type: typeof ActionTypes.SET_USER_COGNITO_ID;
  payload: typeof payload;
};

export const setUserCognitoId: SetUserCognitoId = (payload) => ({
  type: ActionTypes.SET_USER_COGNITO_ID,
  payload,
});
