import React, { useState, useCallback } from 'react';

import { View, TouchableOpacity, ActivityIndicator, StyleSheet } from 'react-native';
import { pdfjs, Page, Document, DocumentProps } from 'react-pdf';
import webStyled from 'styled-components';
import styled from 'styled-components/native';

import { AppScrollView } from 'components';
import { Button } from 'components/Button';
import { useWebPdfViewerStyles } from 'components/PdfViewer/styles';
import { DefaultText } from 'style/common';
import { downloadAndOpenPDF } from 'utils/downloadAndOpenPDF';
import { isNativeDevice } from 'utils/helpers';

import { PdfViewerProps } from './types';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PDFOverlay = isNativeDevice
  ? styled.View``
  : (webStyled.div`
   position: absolute; top: 0; bottom: 0; left: 0; right: 0;
   background-color: rgba(0, 0, 0, 0);
   transition: 0.5s;
   
   &:hover {
      background-color: rgba(0, 0, 0, 0.1);
   }

   &:active {
      background-color: rgba(0, 0, 0, 0.3);
   }
` as React.ElementType);

const PdfViewer = ({ source, title }: PdfViewerProps) => {
  const styles = useWebPdfViewerStyles();

  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess: DocumentProps['onLoadSuccess'] = useCallback(({ numPages }) => {
    setPageCount(numPages);
    setPageNumber(1);
  }, []);

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  return (
    <View style={styles.wrapper}>
      <AppScrollView style={styles.pdfWrapper}>
        <TouchableOpacity activeOpacity={1} onPress={() => downloadAndOpenPDF(source, title)}>
          <Document loading={<ActivityIndicator size='large' />} file={source} onLoadSuccess={onDocumentLoadSuccess}>
            <Page width={StyleSheet.flatten(styles.page).width} pageNumber={pageNumber} />
          </Document>
          <PDFOverlay />
        </TouchableOpacity>
      </AppScrollView>
      <DefaultText style={{ marginTop: 8 }}>
        Page {pageNumber || (pageCount ? 1 : '--')} of {pageCount || '--'}
      </DefaultText>
      <View style={styles.actionButtonsWrapper}>
        <Button variant='secondary' text='Previous' disabled={pageNumber <= 1} onPress={previousPage} />
        <Button
          variant='secondary'
          text='Next'
          style={styles.nextButton}
          disabled={pageNumber >= pageCount}
          onPress={nextPage}
        />
      </View>
    </View>
  );
};

export default PdfViewer;
