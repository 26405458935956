import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useInformationFieldStyles = () => {
  const { fontSizes, colors, isTablet } = useTheme();

  return StyleSheet.create({
    informationBlock: {
      justifyContent: 'center',
      alignItems: 'flex-start',
      ...(isTablet && {
        width: '50%',
        paddingBottom: 24,
      }),
    },
    informationLabel: {
      color: colors.gray2,
      marginBottom: 8,
    },
    informationText: {
      ...fontSizes[16],
      color: colors.gray1,
    },
  });
};
