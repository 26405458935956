import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const GrayDayMark = (props: SvgProps) => (
  <Svg width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
    <Path
      d='M0 8.20801C0 3.78973 3.58172 0.208008 8 0.208008C12.4183 0.208008 16 3.78973 16 8.20801C16 12.6263 12.4183 16.208 8 16.208C3.58172 16.208 0 12.6263 0 8.20801Z'
      fill={defaultTheme.colors.gray3}
    />
  </Svg>
);

export default GrayDayMark;
