import { AppPlatformsEnum } from 'enums';
import { SetPasswordQueryString } from 'screens/auth/SetPassword/types';
import apiRequest, { apiPrefix } from 'utils/network';

import {
  ActivationData,
  ConfirmPasswordData,
  MFASendInfoResponse,
  ReactivateAccountData,
  SendMFAInfoDTO,
  UserReturnData,
  UserUpdateData,
  VerifyMFAInfo,
} from './types';

export const LOGIN_URL = '/v1/user/login/';
export const UPDATE_PROFILE = '/v1/user/update-user-profile/';
export const TOKEN_CHECK_URL = '/v1/token-check/';
export const CODE_CHECK_URL = '/v1/partner-registration/check-code/';
export const SEND_MFA_INFO_URL = '/v1/user/send_mfa/';
export const VERIFY_MFA_INFO_URL = '/v1/user/verify_mfa/';
export const FORGET_PASSWORD_URL = '/v1/user/resetpassword/';
export const CONFIRM_PASSWORD_URL = '/v1/user/confirmpassword/';
export const ACTIVATION_URL = '/v1/partner-registration/activate/';
export const PUSH_TOKEN_URL = '/v1/user/notification_token/';
export const DOWNLOAD_APP_URL = '/v1/send-download-link/';
export const RESEND_ACTIVATE_EMAIL = '/v1/resend-activate-email/';

export interface Credentials {
  email: string;
  password: string;
  code?: string;
}

export const UserInterface = {
  activation: (data: ActivationData) => {
    return apiRequest<UserReturnData>({
      endpoint: ACTIVATION_URL,
      baseURL: `${apiPrefix}/api`,
      method: 'POST',
      body: data,
    });
  },
  login: ({ email, password, code }: Credentials) => {
    return apiRequest<UserReturnData>({
      method: 'POST',
      body: {
        email,
        password,
        ...(code && { code }),
      },
      endpoint: LOGIN_URL,
      baseURL: `${apiPrefix}/authapi`,
    });
  },
  update: (data: UserUpdateData) => {
    return apiRequest({
      method: 'POST',
      endpoint: UPDATE_PROFILE,
      baseURL: `${apiPrefix}/authapi`,
      body: data,
    });
  },
  tokenCheck: (token: string) => {
    return apiRequest<UserReturnData>({
      endpoint: TOKEN_CHECK_URL,
      baseURL: `${apiPrefix}/authapi`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  forgotPassword: (email: string) => {
    return apiRequest({
      method: 'POST',
      endpoint: FORGET_PASSWORD_URL,
      baseURL: `${apiPrefix}/authapi`,
      body: {
        email,
      },
    });
  },
  resendActivateEmail: (email: string) => {
    return apiRequest<ReactivateAccountData>({
      method: 'POST',
      endpoint: RESEND_ACTIVATE_EMAIL,
      baseURL: `${apiPrefix}/api`,
      body: { email },
    }).then((res) => res.data);
  },
  confirmPassword: (data: ConfirmPasswordData) => {
    return apiRequest<UserReturnData>({
      method: 'POST',
      endpoint: CONFIRM_PASSWORD_URL,
      baseURL: `${apiPrefix}/authapi`,
      body: data,
    });
  },
  savePushToken: (token: string) => {
    return apiRequest({
      method: 'POST',
      endpoint: PUSH_TOKEN_URL,
      baseURL: `${apiPrefix}/authapi`,
      body: { token },
    });
  },
  deletePushToken: (token: string) => {
    return apiRequest({
      method: 'DELETE',
      endpoint: PUSH_TOKEN_URL,
      baseURL: `${apiPrefix}/authapi`,
      body: { token },
    });
  },
  downloadApp: (platform: AppPlatformsEnum) =>
    apiRequest({
      method: 'POST',
      endpoint: DOWNLOAD_APP_URL,
      baseURL: `${apiPrefix}/api`,
      body: {
        platform,
      },
    }),
  codeCheck: (code: SetPasswordQueryString['code']) =>
    apiRequest({
      method: 'GET',
      endpoint: CODE_CHECK_URL,
      baseURL: `${apiPrefix}/api`,
      params: { code },
    }),
  mfaSendInfo: (mfaInfoDTO: SendMFAInfoDTO) =>
    apiRequest<MFASendInfoResponse>({
      method: 'POST',
      endpoint: SEND_MFA_INFO_URL,
      baseURL: `${apiPrefix}/authapi`,
      body: mfaInfoDTO,
    }),
  mfaVerifyInfo: (verifyMFAInfo: VerifyMFAInfo) =>
    apiRequest<UserReturnData>({
      method: 'POST',
      endpoint: VERIFY_MFA_INFO_URL,
      baseURL: `${apiPrefix}/authapi`,
      body: verifyMFAInfo,
    }),
};
