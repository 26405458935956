import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Liquid = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 68.2 131.81' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M65.84 92.36v21c0 7.32-1.82 10.5-8.91 12.7a76.38 76.38 0 0 1-46.93-.2c-6.76-2.18-8.47-5.26-8.47-12.45 0-14.3-.08-46.76-.08-46.76s.18-1.88 6-3.2c16.23-3.86 42.86-2.24 53.19.09 6.18 1.33 5.86 3.11 5.86 3.11s-.66 18.23-.66 25.71Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M9.25 39.65V45S-.18 47.83 0 58.32s0 59.73 0 60.28c0 3 5.51 12.44 21.52 13s18 0 18 0 24.32 1.4 28.4-13.36c.18-11.2 0-61.88 0-61.88S69 46.23 59.21 45.52a51.49 51.49 0 0 1-.35-5.87S63 38.41 64 36.45c0-2.67-.35-31.29-.35-31.29S66.28 0 34 0 4.8 7.11 4.8 7.11v30.05a5.86 5.86 0 0 0 4.45 2.49Z'
          fillOpacity={0.3}
          fill='#ddd'
        />
        <Path
          d='M4.8 37.16a5.86 5.86 0 0 0 4.45 2.49s25.34 5.24 49.61 0c0 0 4.09-1.24 5.15-3.2 0-2.67-.35-31.29-.35-31.29S66.28 0 34 0 4.8 7.11 4.8 7.11Z'
          fill='#ddd'
        />
        <Path
          fillOpacity={0.4}
          d='M33.46 10.74c24.84-.4 29.59-4.65 30-5.1v-.31s2.61-5-29.37-5C10 .34 5.75 4.26 5.14 6.18c2.52 1.82 9.6 4.87 28.32 4.56Z'
        />
        <Ellipse fillOpacity={0.2} cx={33.99} cy={66.65} rx={32.52} ry={5.44} />
        <Path
          fillOpacity={0.4}
          d='M0 70.6s8.18 6.32 35.82 5.83c26-.66 32.38-4.58 32.38-4.58v29.44s-1.64 5.39-33.77 5.39S0 99.65 0 99.65Z'
        />
        <Path
          fillOpacity={0.2}
          d='M0 57.94v61.19s3.53 6.05 8.57 6.05a5.89 5.89 0 0 0 4.21-2.52V61.21S8.07 53.4 0 57.94Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M67.26 55.74s-7.91-6.74-24.54-5.6c6.59-1.32 11.86.33 17-3.62 1.12.16 6.72 1.48 7.54 9.22Z'
        />
        <Path fillOpacity={0.2} d='M14.25 11.51v27.31s-6.79-1-8.11-3.37V8.54s2.45 1.98 8.11 2.97Z' />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M59.1 9.59a8.14 8.14 0 0 0 4-2.25c.17 2.86.3 28.74.3 28.74s-2.51 2.35-4.3 2.68c.17-5.71 0-29.17 0-29.17Z'
        />
      </G>
    </G>
  </Svg>
);

export default Liquid;
