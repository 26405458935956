import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { SpecialtyPharmacyServicesInterface } from 'api/SpecialtyPharmacy';
import { setSpecialtyPharmacy } from 'store/actions/cards';
import { getSpecialtyPharmacy } from 'store/selectors/home-info-cards';
import { handlePromise } from 'utils/handlePromise';
import { makeRequestWithLoader } from 'utils/requests';

const request = async () => {
  const [res] = await handlePromise(SpecialtyPharmacyServicesInterface.getSpecialtyPharmacy);

  return res;
};

export const useSpecialtyPharmacy = () => {
  const [isLoading, setLoading] = useState(false);
  const [refetchDependency, setRefetchDependency] = useState(false);

  const specialtyPharmacy = useSelector(getSpecialtyPharmacy);

  const dispatch = useDispatch();

  useEffect(() => {
    makeRequestWithLoader(request, setLoading).then((res) => !!res && dispatch(setSpecialtyPharmacy(res)));
  }, [dispatch, refetchDependency]);

  const hideSpecialtyPharmacyModal = () => {
    specialtyPharmacy &&
      SpecialtyPharmacyServicesInterface.closeSpecialtyPharmacyModal(specialtyPharmacy.uuid).then(() =>
        setRefetchDependency((prev) => !prev)
      );
  };

  return {
    isLoading,
    specialtyPharmacy,
    hideSpecialtyPharmacyModal,
  };
};
