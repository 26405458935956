export const emailRegex = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

export const urlRegex = /^https?:\/\//i;

// Regex for -> At least one uppercase/lowercase letter and a number
export const passwordRegex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}');

export const upperCaseRegex = /(?=.*?[A-Z])/;
export const numberRegex = /(?=.*?[0-9])/;
export const specialCharacterRegex = /(?=.*?[#?!@$%^&*-])/;
export const eightLengthRegex = /.{8,}/;

export const addressPattern = /^([a-zA-Z0-9\u0080-\u024F]+(?:(\. )|-| |'))*[a-zA-Z0-9\u0080-\u024F]*$/g; // Accepts only letters, numbers, spaces, dashes(-) and diacritical marks
export const zipCodePattern = /^\d{5}[-\s]?(?:\d{4})?$/g; // Allows numbers, one space or dash
