import React, { Dispatch, MutableRefObject, SetStateAction, useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, TextFieldWithLabel } from 'components';
import { saveUserContactInfo, setBasicUserInformation } from 'store/actions';
import { User } from 'store/reducers/user-info/types';
import { getUserPhoneNumber } from 'store/selectors';
import { DefaultText } from 'style/common';
import { TRY_AGAIN_ERROR_MESSAGE } from 'utils/constants';
import { addDashesForPhoneNumber, removeDashes } from 'utils/convertString';
import { throttleToastHide } from 'utils/helpers';

import { defaultText } from './constants';
import { useAddPhoneNumberModalStyles } from './styles';

export type ChangeCommunicationPreferenceModalProps = {
  visible: boolean;
  toastRef: MutableRefObject<ToastContainer | null>;
  setVisible: Dispatch<SetStateAction<boolean>>;
  descriptionText?: string;
  storeButtonAction?: (value: string) => void;
};

const { titleAddPhoneNumberText, buttonAddPhoneNumberText, descriptionAddPhoneNumberText, errorText } = defaultText;

export const AddPhoneNumberModal = ({
  visible,
  toastRef,
  setVisible,
  descriptionText,
  storeButtonAction,
}: ChangeCommunicationPreferenceModalProps) => {
  const userPhoneNumber = useSelector(getUserPhoneNumber);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isValid, isValidating },
  } = useForm<Pick<User, 'phoneNumber'>>({ defaultValues: { phoneNumber: userPhoneNumber }, mode: 'onChange' });

  useEffect(() => {
    reset({ phoneNumber: userPhoneNumber });
  }, [reset, userPhoneNumber]);

  const dispatch = useDispatch();

  const styles = useAddPhoneNumberModalStyles();

  const onSave = async (value: Pick<User, 'phoneNumber'>) => {
    dispatch(setBasicUserInformation({ phoneNumber: value.phoneNumber }));

    try {
      storeButtonAction ? storeButtonAction(removeDashes(value.phoneNumber)) : await dispatch(saveUserContactInfo());
    } catch (error: any) {
      const message = error?.response?.data?.message || TRY_AGAIN_ERROR_MESSAGE;
      toastRef?.current?.show(message, {
        type: 'danger',
      });
      throttleToastHide(toastRef.current);
    }
    setVisible(false);
  };

  const shouldShowErrorText = !isValidating && !isValid && !!errors.phoneNumber;

  return (
    <Modal toastRef={toastRef} modalIsVisible={visible} setModalVisible={setVisible}>
      <View style={styles.wrapper}>
        <DefaultText style={styles.title}>{titleAddPhoneNumberText}</DefaultText>
        <DefaultText style={styles.description}>{descriptionText || descriptionAddPhoneNumberText}</DefaultText>
        <View style={styles.buttonContainer}>
          <View style={styles.switchWrapper}>
            <Controller
              name='phoneNumber'
              control={control}
              rules={{ required: true, minLength: 12 }}
              render={({ field: { value, onChange } }) => (
                <TextFieldWithLabel
                  value={value}
                  labelText={'Phone Number'}
                  placeholder={'000-000-0000'}
                  autoComplete={'tel-national'}
                  onChangeText={(text) => {
                    onChange(addDashesForPhoneNumber(text));
                  }}
                />
              )}
            />
            {<DefaultText style={styles.negative}>{shouldShowErrorText && errorText}</DefaultText>}
          </View>
          <Button
            onPress={handleSubmit(onSave)}
            disabled={!isValid}
            variant='secondary'
            style={styles.buttonStyle}
            text={buttonAddPhoneNumberText}
          />
        </View>
      </View>
    </Modal>
  );
};
