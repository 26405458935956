import React, { useState } from 'react';

import { DrawerContentScrollView, DrawerContentComponentProps, useDrawerStatus } from '@react-navigation/drawer';
import { SafeAreaView, View } from 'react-native';
import { useSelector } from 'react-redux';

import Close from 'assets/icons/Close';
import { patientColorLogo } from 'assets/images';
import { Button } from 'components/Button';
import { Line } from 'components/Line';
import { ModalComponent as Modal } from 'components/Modal';
import { Support } from 'components/Support';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useImageSizes } from 'hooks';
import { needHelp, days, time, contact } from 'localization/drawer';
import { getSelectedProgram, getUserHasPsp } from 'store/selectors';
import { DefaultText, StyledImage, webStyles } from 'style/common';
import { getMediaUrl } from 'utils/helpers';
import { getImageUrl } from 'utils/white-labeling';

import { MenuItems } from './MenuItems';
import { ProfileAndMedCabinetItems } from './ProfileAndMedCabinetItems';
import { ProgramSelect } from './ProgramSelect';
import { ContentWrapper, BottomText, TimeText, drawerContentStyles, getItemStyles, CloseButton } from './styles';

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const drawerStatus = useDrawerStatus();
  const { isMobile } = useDeviceInfoContext();
  const userHasPsp = useSelector(getUserHasPsp);
  const selectedProgram = useSelector(getSelectedProgram);
  const { logo: brandLogo } = selectedProgram?.white_labeling_conf || {};

  const logo = getMediaUrl(brandLogo) || patientColorLogo;

  const { imageWidth: logoWidth } = useImageSizes({ url: logo, isSrc: true });
  const styles = getItemStyles(isMobile, logoWidth);

  const [modalIsVisible, setModalVisible] = useState(false);

  const openModalClickHandler = () => {
    setModalVisible(true);
  };

  const closeModalClickHandler = () => {
    setModalVisible(false);
  };

  return (
    <>
      {drawerStatus === 'open' ? (
        <CloseButton onPress={() => props.navigation.closeDrawer()}>
          <Close stroke='#FFF' />
        </CloseButton>
      ) : null}
      <SafeAreaView style={drawerContentStyles.safeArea}>
        <DrawerContentScrollView persistentScrollbar {...props} contentContainerStyle={{ flexGrow: 1, paddingTop: 0 }}>
          <ContentWrapper>
            {userHasPsp ? (
              <ProgramSelect logo={logo} />
            ) : (
              <View style={styles.imageWrapper}>
                <StyledImage resizeMode='contain' source={getImageUrl(logo)} />
              </View>
            )}
            <View style={styles.itemBlockTextWrapper}>
              <DefaultText style={styles.itemBlockText}>Menu</DefaultText>
            </View>

            {isMobile && <MenuItems />}

            {userHasPsp && (
              <>
                <Line marginTop={15} />

                <View style={styles.itemBlockTextWrapper}>
                  <DefaultText style={styles.itemBlockText}>PatientLink Tool Kit</DefaultText>
                </View>

                <ProfileAndMedCabinetItems />
                <Line marginTop={15} />
                <View style={drawerContentStyles.contactWrapper}>
                  <BottomText style={drawerContentStyles.needHelpText}>{needHelp}</BottomText>
                  <TimeText style={drawerContentStyles.timeText}>{days}</TimeText>
                  <TimeText>{time}</TimeText>
                  <Button
                    variant='secondary'
                    text={contact}
                    style={[webStyles.button, drawerContentStyles.contactButton]}
                    onPress={openModalClickHandler}
                  />
                </View>
              </>
            )}
            <Modal
              modalIsVisible={modalIsVisible}
              setModalVisible={setModalVisible}
              onRequestClose={closeModalClickHandler}>
              <Support closeModal={closeModalClickHandler} />
            </Modal>
          </ContentWrapper>
        </DrawerContentScrollView>
      </SafeAreaView>
    </>
  );
};

export const DrawerContent = (props: DrawerContentComponentProps) => <CustomDrawerContent {...props} />;
