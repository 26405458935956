import { ImageSourcePropType } from 'react-native';

import { isNativeDevice } from './helpers';

export const getNativeUrl = (url?: string) => ({ uri: url });

export const getImageUrl = (url: string | ImageSourcePropType) => {
  if (!url) return undefined;

  if (typeof url === 'string') {
    return isNativeDevice ? getNativeUrl(url) : (url as ImageSourcePropType);
  }

  return url;
};
