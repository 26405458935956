import React, { useState } from 'react';

import { useSpring, animated } from '@react-spring/native';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import { ArrowDown } from 'assets/icons';
import { HistoryItemProps } from 'components/HistoryItem/types';
import { useGetHistoryItemInfo } from 'components/HistoryItem/utils';
import { StandardText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';

import { useHistoryItemStyle } from './styles';

const AnimatedView = animated(View);

export const HistoryItem = ({ status, isLastItem, title, description, date }: HistoryItemProps) => {
  const [isExpanded, setExpanded] = useState(false);

  const { styles, colors, isMobile } = useStylesWithAdditional(useHistoryItemStyle, { isLastItem, isExpanded });

  const { IconComponent, bgColor } = useGetHistoryItemInfo(status);

  const descriptionLimitLines = isMobile ? 7 : 2;

  const textContainerHeight = StyleSheet.flatten(styles.description).lineHeight * descriptionLimitLines + 24;

  const dateText = status === 'Not started' ? '-' : status === 'Complete' ? `Completed on ${date}` : 'In process';

  const descriptionAnimationStyles = useSpring({ maxHeight: isExpanded ? textContainerHeight : 0 });

  return (
    <View style={styles.container}>
      <View style={styles.leftSide}>
        <View style={[styles.circle, { backgroundColor: bgColor }]}>
          <IconComponent width={16} height={16} stroke={colors.white} />
        </View>
        <View style={styles.verticalHr} />
      </View>
      <View style={styles.rightSide}>
        <StandardText style={styles.title}>{title}</StandardText>
        <StandardText style={styles.date}>{dateText}</StandardText>
        <AnimatedView style={descriptionAnimationStyles}>
          <StandardText style={styles.description} numberOfLines={descriptionLimitLines}>
            {description}
          </StandardText>
        </AnimatedView>
      </View>
      {!!description && (
        <TouchableOpacity style={styles.icon} onPress={() => setExpanded((prev) => !prev)}>
          <ArrowDown />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default HistoryItem;
