import React, { useContext } from 'react';

import { View, Image, ImageSourcePropType, ViewStyle } from 'react-native';

import { DeviceInfoContext, useDeviceInfoContext } from 'contexts/DeviceInfo';

import { getTemplateImageStyles } from './styles';

export type TemplateImageProps = {
  src: ImageSourcePropType;
  imageWrapperStyle?: ViewStyle;
};

export const TemplateImage = ({ src, imageWrapperStyle }: TemplateImageProps) => {
  const { isMobile } = useDeviceInfoContext();
  const styles = getTemplateImageStyles(isMobile);
  return (
    <View style={[styles.templateBoxWrapper, imageWrapperStyle]}>
      <Image resizeMode='contain' source={src} style={styles.templateImageBox} />
    </View>
  );
};

export default TemplateImage;
