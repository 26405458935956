import { emailRegex, passwordRegex } from './regulars';

export const isEmailValid = (email: string) => emailRegex.test(String(email).toLowerCase());

export const isPasswordValid = (password: string) => password.length < 8 && !passwordRegex.test(password);

export const validatePassword = (password: string): string => {
  if (password.length < 8) return 'Password should be minimum 8 characters';
  if (!passwordRegex.test(password))
    return 'Password must contain at least one uppercase letter, one lowercase letter and one number';
  return '';
};
