import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

type Config = {
  isLastItem?: boolean;
  isExpanded: boolean;
};

export const useHistoryItemStyle = ({ isLastItem, isExpanded }: Config) => {
  const { colors, fontFamilies, fontSizes, isMobile } = useTheme();

  return StyleSheet.create({
    container: {
      position: 'relative',
      justifyContent: 'flex-start',
      flexDirection: 'row',
    },
    circle: {
      width: 32,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 16,
    },
    leftSide: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    rightSide: {
      maxWidth: isMobile ? '80%' : '95%',
      marginLeft: 16,
      marginTop: 6,

      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 8,
      transform: [{ rotate: !isExpanded ? '0deg' : '180deg' }],
    },
    date: {
      marginTop: 8,
      color: colors.gray1,
    },
    description: {
      ...fontSizes[14],
      color: colors.gray2,
      marginTop: 10,
    },
    verticalHr: {
      display: isLastItem ? 'none' : 'flex',
      marginVertical: 8,
      flex: 1,
      width: 1,
      minHeight: 24,
      backgroundColor: colors.gray3,
    },
  });
};
