import { useRef, useEffect } from 'react';

import { SwiperRef } from '@types';
import { isNumber } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

export type UseDemonstrateSwiperAnimationConfig = Partial<{
  enabled: boolean;
  closeDelay: number;
  showDelay: number;
  swiperRef: SwiperRef;
  onAnimationDone: AnyFunction;
}>;
export const useDemonstrateSwiperAnimation = ({
  swiperRef,
  showDelay = 500,
  closeDelay = 800,
  enabled,
  onAnimationDone,
}: UseDemonstrateSwiperAnimationConfig = {}) => {
  const demonstrateAnimationDone = useRef(false);

  useEffect(() => {
    if (!enabled || !swiperRef?.current || demonstrateAnimationDone.current) return;

    // Timeout to first render
    const timeout1 = setTimeout(swiperRef?.current?.openRight, showDelay);
    // Timeout to close animation
    const timeout2 = setTimeout(() => {
      swiperRef.current?.close();
      demonstrateAnimationDone.current = true;
      onAnimationDone?.();
    }, closeDelay);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [closeDelay, enabled, onAnimationDone, showDelay, swiperRef]);

  if (isNumber(closeDelay) && isNumber(showDelay) && closeDelay < showDelay) {
    throw new Error('Your close demonstrate animation delay should be more then show.');
  }
};
