import { SetShouldProposeTutorial } from 'store/actions/user/actionCreators/setShouldProposeTutorial';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setShouldProposeTutorial = (
  state = INITIAL_STATE,
  payload: ReturnType<SetShouldProposeTutorial>['payload']
): User => {
  return { ...state, shouldProposeTutorial: payload };
};

export default setShouldProposeTutorial;
