import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { breakpoints } from 'style/constants';
import { BreakpointTypes } from 'style/types';

export const useStylesWithAdditional = <T extends ReturnType<typeof StyleSheet.create>, P>(
  useStyle: ((params?: P) => T) | ((params: P) => T) | (() => T),
  params?: P
) => {
  const theme = useTheme();
  const styles = useStyle(params as P);

  return { ...theme, styles };
};

export const useIsBreakpoint = (breakpoint: BreakpointTypes) => {
  const { width } = useDeviceInfoContext();

  return width <= breakpoints[breakpoint];
};
