import { PartnerRegistrationInterface } from 'api';
import { AppThunkAction } from 'store';
import type { Insurance } from 'store/reducers/user-info/types/insurance';

import { setUserInsurances } from './setUserInsurances';

type SaveUserInsuranceInfo = () => AppThunkAction<Promise<void>>;

export const saveUserInsuranceInfo: SaveUserInsuranceInfo = () => async (_, getState) => {
  const currentInsuranceInfo = getState().userInfo.insurances;
  const composedInsurances = currentInsuranceInfo?.map((insurance) => {
    const { bin, pcn, memberId, id, type } = insurance;

    return {
      ...(bin && { bin }),
      ...(pcn && { pcn }),
      ...(memberId && { memberId }),
      ...(id && { id }),
      type,
    };
  });

  const savedData = await PartnerRegistrationInterface.patch({
    data: {
      insurances: composedInsurances,
    },
  });

  if (savedData?.data?.insurances?.length) {
    setUserInsurances(savedData?.data?.insurances as unknown as Insurance[]);
  }
};
