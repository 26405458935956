import { Medication } from 'store/reducers/medicine-cabinet/types';

export enum ReminderTypesEnum {
  ONE_TIME = 'ONE_TIME',
  EVERY_X_HOURS = 'EVERY_X_HOURS',
  EVERY_X_DAYS = 'EVERY_X_DAYS',
  EVERY_X_WEEKS = 'EVERY_X_WEEKS',
  DAILY = 'DAILY',
  AS_NEEDED = 'AS_NEEDED',
}
export type ReminderCardProps = {
  id: number;
  medication: Medication;
  isFirst?: boolean;
  time_of_day?: string;
  type: ReminderTypesEnum;
  every_x_hours: number;
  every_x_days: number;
  every_x_weeks: number;
  medicineCount: number;
  onPress: (id: number) => void;
  onDeleteReminderPress: (id: number) => void;
};
