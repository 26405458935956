import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { persistor } from 'store';
import { setMFACountingDown } from 'store/actions/user';
import { getMFACountingDown } from 'store/selectors/mfaInfoConfig';

export const useTimerCountdown = (timeLeftInSec: number, onChange: (countdown: number) => void) => {
  useEffect(() => {
    if (timeLeftInSec <= 0) return;

    const intervalId = setInterval(() => onChange(timeLeftInSec - 1), 1000);

    return () => clearInterval(intervalId);
  }, [timeLeftInSec, onChange]);
};

export const useSecondsCountdown = () => {
  const dispatch = useDispatch();
  const mfaTimeLeft = useSelector(getMFACountingDown);

  const countCallback = (newTime: number) => {
    persistor.persist();
    dispatch(setMFACountingDown(newTime));
  };

  useTimerCountdown(mfaTimeLeft, countCallback);

  return { mfaTimeLeft, isEnd: mfaTimeLeft <= 0 };
};
