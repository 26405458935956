import { useState, useCallback, useEffect, useMemo } from 'react';

import { LayoutChangeEvent } from 'react-native';

type Layout = { width: number; height: number; left: number; top: number };
type OnLayout = (event: LayoutChangeEvent) => void;

let isMounted = true;

export default function useLayout(): Layout & { onLayout: OnLayout } {
  const [layout, setLayout] = useState<Layout>({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });

  const onLayout = useCallback((e) => {
    if (isMounted) {
      setLayout(e.nativeEvent.layout);
    }
  }, []);

  useEffect(() => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  });

  return {
    onLayout,
    ...layout,
  };
}
