import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse, Rect } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const GelWithApplicator = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 98.4 99.59' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='M97.54 11.43c-2.21-2.76-8-2.21-8-2.21s-7.31-6.65-7.42-6.65c-7.51-5.76-12.6 0-13.12.43-5 5.76.11 11.85.11 11.85l5 4.36L49.43 43.9c-1.11-.64-2-.86-2.4-.48-.8.77.74 3.69 3.45 6.53s5.52 4.52 6.36 3.75c.41-.38.22-1.31-.4-2.49C59.56 48.06 79 28.53 79.82 28.94a31.61 31.61 0 0 1 3.55 3.21 8.51 8.51 0 0 0 7.71 2.37 8.91 8.91 0 0 0 5.58-3.59c3.43-4.32-.67-11.19-.67-11.19 4.1-3.21 1.78-7.53 1.55-8.31Z'
        />
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='m54.73 53-7.44-7.18-38.56 37.4c-2.8-.92-5.57-.55-7.25 1.24-2.66 2.82-1.63 8.11 2.3 11.81s9.27 4.42 11.93 1.59c1.63-1.72 1.87-4.37.91-7C26.68 81 54.73 53 54.73 53Z'
        />
        <Path
          fillOpacity={0.2}
          d='M56 51.65s-5.7-1.77-7.11-7.2l.54-.55c-1.11-.64-2-.86-2.4-.48-.8.77.74 3.69 3.45 6.53s5.52 4.52 6.36 3.75c.41-.38.22-1.31-.4-2.49-.28.27-.44.44-.44.44ZM4.53 95.68c-3.93-3.7-5-9-2.31-11.82.06-.06.12-.1.18-.15a5.11 5.11 0 0 0-.92.75c-2.66 2.82-1.63 8.11 2.3 11.81s9 4.37 11.75 1.76c-2.84 1.87-7.53.97-11-2.35Z'
        />
        <Path
          fillOpacity={0.2}
          d='M13.12 94.23A6.15 6.15 0 0 1 7 92.47c-3-3.53-1.52-6.06-1.52-6.06l3.3-3.19c-2.8-.92-5.57-.55-7.25 1.24-2.66 2.82-1.63 8.11 2.3 11.81s9.27 4.42 11.93 1.59c1.63-1.72 1.87-4.37.91-7-2.03 1.93-3.32 3.14-3.55 3.37Z'
        />
        <Path
          fillOpacity={0.1}
          d='M74.1 19.23s7.61-5.72 16.05 3.21a15 15 0 0 1 2 1.59s3.42 1.22 1.34-2.33c-2.57-1.95-7.83-6.24-11.25-6.24s-6-.24-8.1 2c-.26.45-1.28 1.26-.04 1.77ZM96 19.74s-3.15-7-9.51-8.07c-2.34-1.22-6.62-3.67-7.23-5.14-.49 0-2.57-.24-.61 1.72 2.2 1.59 13.52 6.6 17.35 11.49Z'
        />
        <Path
          fill='#fff'
          d='m74.48 7.14-.2.09a1.14 1.14 0 0 1-1.35-.39c-1-1.43-2.79-4.68 1-6.32.51-.09 2.55-2 8.09 2a1.22 1.22 0 0 1 .17.15c.73.75 3.52 3.43 5 4.88a1.13 1.13 0 0 1-.14 1.75 1.14 1.14 0 0 1-1.5-.17c-1.57-1.74-5.46-6-6.62-6.54s-3.08-1-3.77-.6S73.14 3.12 75 5.4a1.13 1.13 0 0 1-.52 1.74Z'
        />
        <Path
          d='M12.66 74.59 66.08 23s3-2.12 3 1.21c0 .62 1.83 4.77 7 7.53.67.25 5.49-.19 3.11 2.19S25.63 87 25.63 87s-4.83 1.2-9.29-3.26-3.68-9.15-3.68-9.15Z'
          fill='#e2e2e2'
          fillOpacity={0.4}
        />
        <Path
          fill='#fff'
          d='M77.46 25.51s-2.26 1.95-3.62.52a2.58 2.58 0 0 1 .45-3.91c1.21-1 5.2-5.5 10.63-1.51 1.73 1.36 4.82 4.22 7.38 4.22s2.64-.68 2.64-1.66 1-.68 1-.68a1.5 1.5 0 0 1 .83 1.74c-.15 1.43-.45 3.28-4.9 2.73-1.43-.63-6.13-3.41-8.11-3.49s-3.97-.3-6.3 2.04Z'
        />
        <Ellipse fill='#fff' cx={95.89} cy={11.86} rx={0.7} ry={1.15} transform='rotate(-45 95.893 11.86)' />
        <Path
          fillOpacity={0.05}
          d='M79.16 28.77s3.51-5.44 6.53.75c.16.17 1.33 4.69 8.78 3.43.17.25-2 1.28-3.21 1.53a8.48 8.48 0 0 1-7-1.53c-1.26-1.1-4.17-4.32-5.1-4.18ZM82.2 15.46s-7.63-8-11.68-6.57c-2.82 1.56-1.45 6-1.45 6l4.45 3.86s-.89-4.17 8.68-3.29Z'
        />
        <Path
          fill='#fff'
          d='M70.89 29.74a45 45 0 0 0 8 4.43l.23-.22c2.38-2.38-2.44-1.94-3.11-2.19-5.12-2.76-7-6.91-7-7.53 0-1.9-1-2-1.85-1.78.34 2.25 1.16 5.41 3.73 7.29Z'
        />
        <Path
          fillOpacity={0.1}
          d='M66.3 31.14 22.11 86.92s3.13.44 3.52 0l52.79-52.24s-3.42-.37-8.71-4.06a2.52 2.52 0 0 0-3.41.52Z'
        />
        <Rect
          fillOpacity={0.1}
          x={19.25}
          y={61.61}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 19.86 70.276)'
        />
        <Rect
          fillOpacity={0.1}
          x={19.99}
          y={64.21}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 20.608 72.866)'
        />
        <Rect
          fillOpacity={0.1}
          x={20.89}
          y={66.8}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 21.513 75.461)'
        />
        <Rect
          fillOpacity={0.1}
          x={22.29}
          y={68.41}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 22.916 77.072)'
        />
        <Rect
          fillOpacity={0.1}
          x={24.47}
          y={69.48}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 25.083 78.14)'
        />
        <Rect
          fillOpacity={0.1}
          x={26.66}
          y={70.55}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 27.268 79.215)'
        />
        <Rect
          fillOpacity={0.1}
          x={29.17}
          y={71.45}
          width={1.23}
          height={17.33}
          rx={0.62}
          transform='rotate(45 29.78 80.126)'
        />
        <Rect
          fill='#fff'
          x={48.67}
          y={19.08}
          width={1.97}
          height={47.48}
          rx={0.99}
          transform='rotate(45 49.654 42.818)'
        />
      </G>
    </G>
  </Svg>
);

export default GelWithApplicator;
