import React, { PropsWithChildren } from 'react';

import { TouchableOpacity, Pressable, View } from 'react-native';

import { Close } from 'assets/icons';
import { Card } from 'components';
import { useTransition, AnimatedWrapper } from 'components/Animated';
import { useStyles } from 'screens/main/Home/components/HomeClosableCard/styles';
import { DefaultText, webStyles } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';
import { getInfoCardsConfig } from 'utils/animation';
import { AnyFunction } from 'utils/types';

type Props = PropsWithChildren<{
  onPress?: AnyFunction;
  onClose?: AnyFunction;
  visible: boolean;
  title?: string;
  description?: string;
  showCloseIcon?: boolean;
  isNotificationCard?: boolean;
}>;

export const HomeClosableCard = ({
  children,
  onClose,
  onPress,
  visible,
  title,
  description,
  showCloseIcon = true,
  isNotificationCard = false,
}: Props) => {
  const { isMobile, styles } = useStylesWithAdditional(useStyles);

  const onPressText = isMobile ? 'Tap for details' : 'Click for details';

  const transition = useTransition(visible, getInfoCardsConfig(300));

  const closeButton = !!onPress && (
    <TouchableOpacity style={[styles.clickButton, webStyles.button]} onPress={onPress}>
      <DefaultText style={[styles.clickButtonText, webStyles.button]}>{onPressText}</DefaultText>
    </TouchableOpacity>
  );

  return transition(
    (style, isShow) =>
      isShow && (
        <AnimatedWrapper style={style}>
          <Card isTouchable={!!onPress} padding={28} pressHandler={onPress} isNotificationCard={isNotificationCard}>
            {showCloseIcon && (
              <Pressable
                onPress={onClose}
                style={(state) => [
                  styles.closeButton,
                  webStyles.button,
                  // @ts-ignore
                  state.hovered && styles.hoverButton,
                ]}>
                <Close />
              </Pressable>
            )}
            <View style={styles.headerContainer}>
              {!!title && <DefaultText style={styles.header}>{title}</DefaultText>}
              {!isMobile && closeButton}
            </View>
            {!!description && <DefaultText style={styles.description}>{description}</DefaultText>}
            {isMobile && closeButton}
            {children}
          </Card>
        </AnimatedWrapper>
      )
  );
};
