import { useRoute } from '@react-navigation/native';

import { medicationCabinetAnalyticsHandler, medicationTrackerAnalyticsHandler } from './helpers';

export const useWizardAnalytics = () => {
  const route = useRoute();
  const isTrackerScreen = route.name === 'tracker';

  return isTrackerScreen ? medicationTrackerAnalyticsHandler : medicationCabinetAnalyticsHandler;
};

export default useWizardAnalytics;
