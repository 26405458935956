import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Rect } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Wafer = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 101.87 101.92' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='M101.87 51.37c0 28.17-22.93 50.57-51.71 50.55C22.42 101.9-.18 78.81 0 50.35.17 22.73 22.73-.24 51.93 0c27.39.23 49.95 23.26 49.94 51.37Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={52.24} y={20.6} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.85 20.6H53.18a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.82a.93.93 0 0 0 .92-.94V21.54a.94.94 0 0 0-.94-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={68.4} y={20.6} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M81 20.6H69.34a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.82a.93.93 0 0 0 .92-.94V21.54a.94.94 0 0 0-.95-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={19.94} y={20.6} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M32.56 20.6H20.88a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.82a.93.93 0 0 0 .92-.94V21.54a.94.94 0 0 0-.93-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={36.22} y={20.6} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 20.6H37.16a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .93.94v10.82a.94.94 0 0 0 .93-.94V21.54a.94.94 0 0 0-.94-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={52.24} y={35.97} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.85 36H53.18a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.79a.93.93 0 0 0 .92-.94V36.91a.94.94 0 0 0-.94-.91Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={68.4} y={35.97} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M81 36H69.34a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.79a.93.93 0 0 0 .92-.94V36.91A.94.94 0 0 0 81 36Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={84.67} y={35.97} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M97.29 36H85.61a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.79a.93.93 0 0 0 .92-.94V36.91a.94.94 0 0 0-.93-.91Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={3.78} y={35.97} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M16.4 36H4.72a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.79a.93.93 0 0 0 .92-.94V36.91a.94.94 0 0 0-.93-.91Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={19.94} y={35.97} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M32.56 36H20.88a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.79a.93.93 0 0 0 .92-.94V36.91a.94.94 0 0 0-.93-.91Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={36.22} y={35.97} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 36H37.16a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .93.94v10.79a.94.94 0 0 0 .93-.94V36.91a.94.94 0 0 0-.94-.91Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={52.24} y={4.54} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.85 4.54H53.18a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v10.82a.93.93 0 0 0 .92-.93V5.48a.94.94 0 0 0-.94-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={36.22} y={4.54} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 4.54H37.16a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .93.94v10.82a.93.93 0 0 0 .93-.93V5.48a.94.94 0 0 0-.94-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={52.24} y={52.03} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.85 52H53.18a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94v10.86a.93.93 0 0 0 .92-.94V53a.94.94 0 0 0-.94-1Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={68.4} y={52.03} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M81 52H69.34a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94v10.86a.93.93 0 0 0 .92-.94V53a.94.94 0 0 0-.95-1Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={84.67} y={52.03} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M97.29 52H85.61a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94v10.86a.93.93 0 0 0 .92-.94V53a.94.94 0 0 0-.93-1Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={3.78} y={52.03} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M16.4 52H4.72a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94v10.86a.93.93 0 0 0 .92-.94V53a.94.94 0 0 0-.93-1Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={19.94} y={52.03} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M32.56 52H20.88a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94v10.86a.93.93 0 0 0 .92-.94V53a.94.94 0 0 0-.93-1Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={36.22} y={52.03} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 52H37.16a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .93.94v10.86a.94.94 0 0 0 .93-.94V53a.94.94 0 0 0-.94-1Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={52.24} y={67.4} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.85 67.4H53.18a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94V81a.93.93 0 0 0 .92-.94V68.34a.94.94 0 0 0-.94-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={68.4} y={67.4} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M81 67.4H69.34a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94V81a.93.93 0 0 0 .97-1V68.34a.94.94 0 0 0-1-.94ZM16.4 67.4H4.72a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94V81a.93.93 0 0 0 .92-.94V68.34a.94.94 0 0 0-.93-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={19.94} y={67.4} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M32.56 67.4H20.88a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94V81a.93.93 0 0 0 .92-.94V68.34a.94.94 0 0 0-.93-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={36.22} y={67.4} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 67.4H37.16a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .93.94V81a.94.94 0 0 0 .93-.94V68.34a.94.94 0 0 0-.94-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={52.24} y={84.15} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.85 84.15H53.18a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94V97.7a.94.94 0 0 0 .92-.94V85.09a.94.94 0 0 0-.94-.94ZM32.56 84.15H20.88a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94V97.7a.94.94 0 0 0 .92-.94V85.09a.94.94 0 0 0-.93-.94Z'
        />
        <Rect fillOpacity={0.6} fill='#f3f3f3' x={36.22} y={84.15} width={13.55} height={13.55} rx={0.94} />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 84.15H37.16a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .93.94V97.7a.94.94 0 0 0 .93-.94V85.09a.94.94 0 0 0-.94-.94ZM101 52a.94.94 0 0 0-.93.94v11.78A50.42 50.42 0 0 0 101.86 52Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M101 52a.94.94 0 0 0-.93.94v.84h1.75V52ZM99.94 37.37v11.21a.94.94 0 0 0 .94.94h1a51.87 51.87 0 0 0-1.94-12.15ZM98.22 33.21v-.92a51.83 51.83 0 0 0-6.48-11.69h-6.13a.94.94 0 0 0-.94.94v11.67a.94.94 0 0 0 .94.94h11.68a.94.94 0 0 0 .93-.94Z'
        />
        <G fillOpacity={0.6} fill='#f3f3f3'>
          <Path
            fill='#f3f3f3'
            d='M91.74 20.6h-6.13a.94.94 0 0 0-.94.94v.85H93c-.42-.61-.83-1.2-1.26-1.79ZM98.22 33.21v-.92c-.29-.74-.6-1.46-.92-2.18v4a.93.93 0 0 0 .92-.9Z'
          />
        </G>
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M84.67 12.85v4.31a.94.94 0 0 0 .94.93h4.14a51.33 51.33 0 0 0-5.08-5.24Z'
        />
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='M81 9.86v8.23a.93.93 0 0 0 .92-.93v-6.6c-.27-.24-.58-.47-.92-.7ZM75.59 6.32c-1.14-.64-2.31-1.23-3.51-1.78h-2.74a.94.94 0 0 0-.94.94v.85h7.19Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M72.08 4.54h-2.74a.94.94 0 0 0-.94.94v11.68a.94.94 0 0 0 .94.93H81a.94.94 0 0 0 .94-.93v-6.6a50.7 50.7 0 0 0-9.86-6.02ZM52.24 0v1.78a.94.94 0 0 0 .94.94h11.67a.93.93 0 0 0 .87-.6A48.94 48.94 0 0 0 52.24 0Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M64.87 1.87v.85a.92.92 0 0 0 .85-.6ZM36.32 2.19a.94.94 0 0 0 .84.53h11.67a.94.94 0 0 0 .94-.94V0a52.39 52.39 0 0 0-13.45 2.19Z'
        />
        <Path fillOpacity={0.6} fill='#f3f3f3' d='M48.84.07v2.65a.94.94 0 0 0 .93-.94V0Z' />
        <G fillOpacity={0.6} fill='#f3f3f3'>
          <Path
            fill='#f3f3f3'
            d='M75.59 6.32c-1.14-.64-2.31-1.23-3.51-1.78h-2.74a.94.94 0 0 0-.94.94v.85h7.19ZM81 9.86v8.23a.93.93 0 0 0 .92-.93v-6.6c-.27-.24-.58-.47-.92-.7Z'
          />
        </G>
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M16.4 20.6H10a50.57 50.57 0 0 0-6.22 10.94v1.67a.94.94 0 0 0 .94.94H16.4a.94.94 0 0 0 .93-.94V21.54a.94.94 0 0 0-.93-.94Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M16.4 20.6H10c-.43.59-.86 1.18-1.27 1.78h6.77a.94.94 0 0 1 .94.94v10.83a.93.93 0 0 0 .92-.94V21.54a.94.94 0 0 0-.96-.94ZM16.41 13.48v4.61a.93.93 0 0 0 .92-.93v-4.51Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M12 18.09h4.4a.93.93 0 0 0 .93-.93v-4.51A51.92 51.92 0 0 0 12 18.09ZM0 49.52h.78a.94.94 0 0 0 .94-.94v-11A50 50 0 0 0 0 49.52ZM1 52H0c0 .6 0 1.19.08 1.79a.93.93 0 0 1 .92.93V61c.25 1.26.56 2.5.9 3.73a.3.3 0 0 0 0-.1V53a.94.94 0 0 0-.9-1Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M.8 41.81v7.71a.93.93 0 0 0 .92-.94v-11C1.35 39 1.05 40.39.8 41.81ZM1 52H0a52.1 52.1 0 0 0 1.9 12.71.3.3 0 0 0 0-.1V53a.94.94 0 0 0-.9-1ZM19.94 17.16a.94.94 0 0 0 .94.93h11.68a.93.93 0 0 0 .93-.93V5.48a.94.94 0 0 0-.93-.94H30a51.18 51.18 0 0 0-10.08 6Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M31.63 6.32a.94.94 0 0 1 .94.94v10.83a.93.93 0 0 0 .92-.93V5.48a.94.94 0 0 0-.93-.94H30c-1.23.55-2.43 1.15-3.6 1.78ZM16.4 67.4H4.72a.94.94 0 0 0-.94.94v1.86A51.55 51.55 0 0 0 9.72 81h6.68a.94.94 0 0 0 .93-.94V68.34a.94.94 0 0 0-.93-.94ZM16.4 84.15h-4.17a50.28 50.28 0 0 0 5.1 5.22v-4.28a.94.94 0 0 0-.93-.94Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M16.4 84.15h-4.17c.51.61 1 1.2 1.58 1.78h1.66a.94.94 0 0 1 .94.94v1.68c.3.28.62.55.92.82v-4.28a.94.94 0 0 0-.93-.94ZM32.56 84.15H20.88a.94.94 0 0 0-.94.94v6.46a50.64 50.64 0 0 0 10.32 6.15h2.3a.94.94 0 0 0 .93-.94V85.09a.94.94 0 0 0-.93-.94ZM48.83 99.52H37.16a.92.92 0 0 0-.76.42 49.22 49.22 0 0 0 13.37 2v-1.45a.94.94 0 0 0-.94-.97Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M48.83 99.52H37.16a.92.92 0 0 0-.76.42 50.16 50.16 0 0 0 6 1.36h5.51a.93.93 0 0 1 .86.58h1v-1.45a.94.94 0 0 0-.94-.91ZM52.24 100.46v1.41a53.25 53.25 0 0 0 13.18-2.14 1 1 0 0 0-.57-.21H53.18a.94.94 0 0 0-.94.94Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M53.18 99.52a.94.94 0 0 0-.94.94v.84h6.13a52.62 52.62 0 0 0 7.05-1.57 1 1 0 0 0-.57-.21ZM81 84.15H69.34a.94.94 0 0 0-.94.94v11.67a.94.94 0 0 0 .94.94h1.71A51.5 51.5 0 0 0 82 91.38v-6.29a.94.94 0 0 0-1-.94Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M81 84.15H69.34a.94.94 0 0 0-.94.94v.84h11.69a.94.94 0 0 1 .94.94v5.21l.92-.7v-6.29a.94.94 0 0 0-.95-.94ZM89.68 84.15h-4.07a.94.94 0 0 0-.94.94v4.08a50.51 50.51 0 0 0 5.01-5.02Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M89.68 84.15h-4.07a.94.94 0 0 0-.94.94v.84h3.39c.55-.58 1.09-1.17 1.62-1.78ZM98.22 70.18v-1.84a.94.94 0 0 0-.93-.94H85.61a.94.94 0 0 0-.94.94V80a.94.94 0 0 0 .94.94h6.61a49.31 49.31 0 0 0 6-10.76Z'
        />
        <Path
          fillOpacity={0.6}
          fill='#f3f3f3'
          d='M98.22 70.18v-1.84a.94.94 0 0 0-.93-.94H85.61a.94.94 0 0 0-.94.94v.85h11.69a.94.94 0 0 1 .94.94v2.2c.32-.71.63-1.43.92-2.15Z'
        />
      </G>
    </G>
  </Svg>
);

export default Wafer;
