import { Camera } from 'expo-camera';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import { Alert } from 'react-native';

import { getBase64SizeInMB } from 'screens/main/Profile/sections/InsuranceInformation/useUploadInsuranceImages';
import { getBase64FromImage } from 'utils/getImageFromGallery';
import { handlePromise } from 'utils/handlePromise';
import { isNativeDevice } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

import { ImageTypes } from './types';

export const MAX_IMAGE_SIZE = 2.4; // MB

export const checkCameraPermission = async (onError?: AnyFunction) => {
  const { granted } = await Camera.getCameraPermissionsAsync();
  if (granted) return;

  const { status } = await Camera.requestCameraPermissionsAsync();

  if (status !== 'granted') {
    Alert.alert(
      'Camera permission',
      'We need it to allow you take a photo from the camera.\nPlease try to turn on it manually.'
    );
    onError?.();
  }
};

const CORRECT_RATIOS = ['2:1', '4:2', '5:3', '16:8', '16:9', '20:9', '20:10'];
export const getCorrectRatio = (ratios: string[]) => CORRECT_RATIOS.find((ratio) => ratios.includes(ratio)) ?? '16:9';

export const allowedImageTypesFromGallery: ImageTypes[] = ['jpeg', 'jpg', 'png'];
export const notAllowedImageTypeMessage = `The file format that you uploaded isn’t supported. Please upload your image in JPG, JPEG or PNG format`;

export const notAllowedImageTypeAlert = () =>
  Alert.alert('Image type not allowed', notAllowedImageTypeMessage, [
    { text: 'Try Again', style: 'cancel', onPress: () => null },
  ]);

const checkAllowedBase64ImageType = (base64Image: string, type: ImageTypes) =>
  base64Image.substring('data:image/'.length, base64Image.indexOf(';base64')).includes(type);

export const isAllowedWebGalleryImageType = (imageBase64: string) =>
  allowedImageTypesFromGallery?.some((type) => checkAllowedBase64ImageType(imageBase64, type));

const checkAllowedImageTypeFromMobileGallery = (fileUrl: string, type: ImageTypes) =>
  fileUrl.split('.')?.some((imageType) => imageType === type);

export const isAllowedMobileGalleryImageType = (imageUri: string) =>
  allowedImageTypesFromGallery?.some((type) => checkAllowedImageTypeFromMobileGallery(imageUri, type));

export const compressImageSize = async (image: string) => {
  const [res, err] = await handlePromise(() =>
    manipulateAsync(image, [], {
      format: SaveFormat.JPEG,
      compress: 0.3,
      base64: true,
    })
  );

  !!err && Alert.alert('something went wrong', 'image is too large');

  if (!res) return;

  return isNativeDevice
    ? getBase64FromImage({
        base64: res.base64,
        type: 'image',
        uri: res.uri,
      })
    : res.uri;
};

export const getValidImageSize = async (image: string, maxSize: number) =>
  getBase64SizeInMB(image) > maxSize ? await compressImageSize(image) : image;
