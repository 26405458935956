import React from 'react';

import Svg, { Defs, Path, G, Mask, Use, SvgProps } from 'react-native-svg';

const Info = (props: SvgProps) => (
  <Svg width={64} height={64} viewBox='0 0 64 64' preserveAspectRatio='xMinYMin slice' {...props}>
    <Defs>
      <Path
        d='M32 0c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32C14.327 64 0 49.673 0 32 0 14.327 14.327 0 32 0zm0 7.68C18.568 7.68 7.68 18.568 7.68 32S18.568 56.32 32 56.32 56.32 45.432 56.32 32 45.432 7.68 32 7.68zm.34 19.2a3.5 3.5 0 013.5 3.5v16.04a3.5 3.5 0 01-3.5 3.5h-.68a3.5 3.5 0 01-3.5-3.5V30.38a3.5 3.5 0 013.5-3.5h.68zm0-12.8a3.5 3.5 0 013.5 3.5v.68a3.5 3.5 0 01-3.5 3.5h-.68a3.5 3.5 0 01-3.5-3.5v-.68a3.5 3.5 0 013.5-3.5h.68z'
        id='info'
      />
    </Defs>
    <G fill='none' fillRule='evenodd'>
      <Mask id='infob' fill='#fff'>
        <Use xlinkHref='#info' />
      </Mask>
      <Use fill={props.fill || '#FFF'} xlinkHref='#info' />
    </G>
  </Svg>
);

export default Info;
