import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const Replay = (props: SvgProps) => {
  return (
    <Svg width={20} height={20} viewBox='0 0 24 24' fill='none' {...props}>
      <Path d='M1 3v7h7' stroke={props.stroke || '#000'} strokeWidth={3} strokeLinecap='round' strokeLinejoin='round' />
      <Path
        d='M3.761 15.323a9.986 9.986 0 003.852 5.002 9.828 9.828 0 006.055 1.659 9.846 9.846 0 005.827-2.35c1.638-1.4 2.782-3.301 3.258-5.415.477-2.114.26-4.327-.618-6.306a9.963 9.963 0 00-4.246-4.665 9.817 9.817 0 00-6.17-1.15 9.864 9.864 0 00-5.615 2.825L1 9.767'
        stroke={props.stroke || '#000'}
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default Replay;
