import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { View, Image, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { Layout } from 'components';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { TEXTS } from 'screens/main/FinancialAssistance/CopayFinancialAssistance/constants';
import { financialAssistanceAnalyticsHandler, getFAPActionsByType } from 'screens/main/FinancialAssistance/helpers';
import { useFinancialAssistanceStyles } from 'screens/main/FinancialAssistance/styles';
import { getFinancialAssistanceData } from 'store/selectors/user';
import { HeaderText, StandardText } from 'style/common';
import { openUrlInNewTab } from 'utils/helpers';

import CopayFinancialAssistance from './CopayFinancialAssistance';

type FinancialAssistanceProps = {
  navigation: DrawerNavigationHelpers;
};

const FinancialAssistance = ({ navigation }: FinancialAssistanceProps) => {
  const financialAssistanceData = useSelector(getFinancialAssistanceData);
  const typeFAP = financialAssistanceData?.type;

  const styles = useFinancialAssistanceStyles({
    hasLink: !!financialAssistanceData?.link,
    hasImage: !!financialAssistanceData?.image,
  });

  useFirstEffect(
    () => {
      financialAssistanceAnalyticsHandler(getFAPActionsByType(typeFAP), getAnalyticEventLabel(typeFAP));
    },
    [typeFAP],
    false
  );

  if (!financialAssistanceData) return null;

  if (financialAssistanceData.type === 'COPAY') return <CopayFinancialAssistance navigation={navigation} />;

  const programImageJSX = <Image style={styles.image} source={{ uri: financialAssistanceData?.image?.url || '' }} />;

  const goToFapLink = () => financialAssistanceData?.link && openUrlInNewTab(financialAssistanceData.link);

  return (
    <Layout
      title={TEXTS.title}
      description={TEXTS.description}
      contentAreaStyle={styles.topContentMobileWrapper}
      navigation={navigation}>
      <View style={styles.mobileImageWrapper}>{programImageJSX}</View>
      <HeaderText style={styles.headerTitle}>About</HeaderText>
      <View style={styles.wrapper}>
        <View style={styles.textWrapper}>
          <StandardText style={styles.name}>{financialAssistanceData?.name || ''}</StandardText>
          <StandardText style={styles.aboutTitle}>About This Program</StandardText>
          <StandardText style={styles.aboutDescription}>{financialAssistanceData?.description || ''}</StandardText>
          <View style={styles.linkButton}>
            <TouchableOpacity onPress={goToFapLink}>
              <StandardText style={styles.linkText}>{financialAssistanceData?.link || ''}</StandardText>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.webImageWrapper}>{programImageJSX}</View>
      </View>
    </Layout>
  );
};

export default FinancialAssistance;
