import React, { createContext, ReactNode, useContext, useEffect, useState, useRef } from 'react';

import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as TaskManager from 'expo-task-manager';
import { Platform } from 'react-native';

import { isNativeDevice } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

import { BACKGROUND_NOTIFICATION_TASK } from './constants';

type NotificationsContextData = {
  badgeCount: number;
  resetNotifications: AnyFunction;
  registerForPushNotificationsAsync: () => Promise<string | undefined>;
  handleNewNotification: (navigationAction?: AnyFunction) => void;
};

type NotificationsProviderProps = {
  children: ReactNode;
};

const NotificationsContext = createContext({} as NotificationsContextData);

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const NotificationsContextProvider = ({ children }: NotificationsProviderProps) => {
  const [badgeCount, setBadgeCount] = useState(0);
  const handleNewNotificationRef = useRef<NotificationsContextData['handleNewNotification']>();

  const registerForPushNotificationsAsync = async () => {
    try {
      let token = '';
      if (Constants.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          alert('Failed to get push token for push notification!');
          return '';
        }
        token = (await Notifications.getExpoPushTokenAsync()).data;
      } else {
        alert('Must use physical device for Push Notifications');
      }

      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }
      return token;
    } catch (error) {
      console.error(error);
    }
  };

  const resetNotifications = async () => {
    setBadgeCount(0);
    await Notifications.setBadgeCountAsync(0);
  };

  const handleNewNotification = async (navigationAction?: AnyFunction) => {
    try {
      setBadgeCount((prevCount: number) => prevCount + 1);
      // set badge number on app icon
      await Notifications.setBadgeCountAsync(badgeCount + 1);
      navigationAction?.();
    } catch (error) {
      console.log(error);
    }
  };

  handleNewNotificationRef.current = handleNewNotification;

  if (isNativeDevice) {
    TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data }: { data: any }) => {
      handleNewNotificationRef?.current?.();
    });
  }

  useEffect(() => {
    if (!isNativeDevice) return;

    Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);

    const foregroundReceivedNotificationSubscription =
      // listener fired whenever a notification is received while the app is foregrounded
      Notifications.addNotificationReceivedListener((notification) => {
        handleNewNotificationRef.current?.();
      });
    return () => {
      foregroundReceivedNotificationSubscription.remove();
      Notifications.unregisterTaskAsync(BACKGROUND_NOTIFICATION_TASK);
    };
  }, []);

  if (!isNativeDevice) return <>{children}</>;

  return (
    <NotificationsContext.Provider
      value={{
        badgeCount,
        resetNotifications,
        registerForPushNotificationsAsync,
        handleNewNotification,
      }}>
      {children}
    </NotificationsContext.Provider>
  );
};

const useNotifications = () => {
  const notificationsContext = useContext(NotificationsContext);
  return notificationsContext;
};

export { NotificationsContextProvider };

export default useNotifications;
