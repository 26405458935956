export const SET_CONSENTS = 'SET_CONSENTS';
export const CLEAR_CONSENTS = 'CLEAR_CONSENTS';
export const SET_CONSENT_HAS_BEEN_VIEWED = 'SET_CONSENT_HAS_BEEN_VIEWED';
export const SET_CONSENT_HAS_BEEN_GIVEN = 'SET_CONSENT_HAS_BEEN_GIVEN';
export const SET_CURRENTLY_ACTIVE_CONSENT = 'SET_CURRENTLY_ACTIVE_CONSENT';
export const REMOVE_CURRENTLY_ACTIVE_CONSENT = 'REMOVE_CURRENTLY_ACTIVE_CONSENT';

export const ADD_TO_CONSENT_MODALS_QUEUE = 'ADD_TO_CONSENT_MODALS_QUEUE';
export const REMOVE_FROM_CONSENT_MODALS_QUEUE = 'REMOVE_FROM_CONSENT_MODALS_QUEUE';
export const CLEAR_CONSENT_MODALS_QUEUE = 'CLEAR_CONSENT_MODALS_QUEUE';
