import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';
import { ALLOWED_MODAL_PADDINGS, MODAL_PADDING } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';

export const useAuthStyles = () => {
  const { colors, isMobile, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    safeArea: { flex: 0, backgroundColor: colors.secondary },
    loginPageModalWrapper: { padding: 29, paddingTop: 20 },
    mobileLoginPageModalWrapper: { paddingVertical: 20, paddingHorizontal: 10 },
    loginPageModalTitle: {
      ...fontSizes[24],
      maxWidth: '90%',
      fontFamily: fontFamilies.bold,
    },
    loginPageModalDescription: {
      ...fontSizes[16],
      color: colors.gray1,
      marginTop: 16,
      marginBottom: 30,
    },
    buttonWrapper: {
      marginTop: 32,
      width: 105,
    },
    loginScreenWrapper: {
      flex: 1,
      backgroundColor: '#fff',
      borderTopRightRadius: 16,
      borderTopLeftRadius: 16,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: isMobile ? 24 : 97,
    },
    reactivateAccountTextWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 40,
    },
    loginButton: {
      marginVertical: 28,
      ...(Platform.OS === 'web' && { cursor: 'pointer', userSelect: 'none' }),
    },
    blueContainer: {
      flex: 1,
      padding: 32,
      paddingBottom: 0,
      backgroundColor: colors.secondary,
      minHeight: '100%',
      ...(isMobile && {
        padding: 0,
        paddingTop: 47,
      }),
    },
    modalWrapper: applyBreakpoints(theme, {
      sm: { maxWidth: theme.width || 0 - ALLOWED_MODAL_PADDINGS },
      lg: { padding: MODAL_PADDING, maxWidth: '50%' },
      xxl: { padding: MODAL_PADDING, maxWidth: '30%' },
    }),
  });
};

export const LoginScreenWrapper = styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 500,
  },
}))`
  background-color: #fff;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding-top: ${({ theme }) => (theme.isMobile ? '24px' : '97px')};
`;

export const WhiteLabelTitle = styled(DefaultText)`
  ${getFontStyles(24)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  text-align: center;
  color: ${(props) => props.theme.colors.secondary};
  margin-top: 45px;
  margin-bottom: 42px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin: 26px 0;
    `}
`;

export const PasswordInputContainer = styled.View`
  position: relative;
  margin-top: 24px;
  margin-bottom: 19px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin: 28px 0;
    `}
`;

export const LinkTextWrapper = styled(TouchableOpacity).attrs({ activeOpacity: 0.75 })``;

export const LinkText = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${(props) => props.theme.colors.secondary};
`;

export const InputContainer = styled.View`
  max-width: 349px;
  width: 349px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      max-width: 270px;
      width: 270px;
    `}
`;

export const SetPasswordButtonWrapper = styled.View`
  margin-bottom: 40px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-top: 8px;
    `}
`;
