export const validationTexts = {
  validationMessage: 'Required field',
  medicationNameValidation: 'Medication selection is required',
};

export const medicationStepConstants = {
  add: {
    title: 'Add a new medication',
    description: 'Fill out the fields below',
    buttonText: 'Add',
  },
  edit: {
    title: 'Edit your medication',
    description: 'Fill out the fields below',
    buttonText: 'Update',
  },
};

export const reminderStepConstants = {
  add: {
    title: 'Set a reminder',
    description: 'Fill out the fields below',
    buttonText: 'Set',
  },
  edit: {
    title: 'Update a reminder',
    description: 'Fill out the fields below',
    buttonText: 'Update',
  },
};

export const interactionStepConstants = {
  conflict: {
    title: 'There are drug interactions across your medications.',
    description: 'There are interactions between two of the medications in your medicine cabinet.',
    buttonText: 'Close',
    buttonStyle: { width: 210 },
  },
  complete: {
    title: "You're all good!",
    description: 'We checked for any drug interactions in your medication cabinet and didn’t find any.',
    buttonText: 'Close',
    buttonStyle: { width: 110 },
  },
};
