import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useBenefitsPageStyle = () => {
  const { fontSizes, isMobile } = useTheme();

  return StyleSheet.create({
    historyTitle: {
      ...fontSizes[16],
      marginBottom: 28,
      marginTop: 10,
    },
    lastRunText: {
      marginTop: isMobile ? 8 : 0,
    },
  });
};
