import { useCallback } from 'react';

import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { SwitchingProgramsInterface, SwitchingProgramsReturnData } from 'api';
import { clearConsentModalsQueue, clearConsents, setAppDataLoading } from 'store/actions';
import { getSelectedProgram } from 'store/selectors';
import { handlePromise } from 'utils/handlePromise';
import { sleep } from 'utils/helpers';

import { bootstrapApp } from './bootstrapApp';

export const useSwitchProgramHandler = () => {
  const dispatch = useDispatch();
  const selectedProgram = useSelector(getSelectedProgram);
  const toast = useToast();

  const switchHandler = useCallback(
    async (id: string) => {
      if (selectedProgram?.uuid === id) {
        return;
      }
      dispatch(setAppDataLoading(true));
      const [switchProgramsResponse, error] = await handlePromise<SwitchingProgramsReturnData>(() =>
        SwitchingProgramsInterface.switch({ manufacturer_program_uuid: id })
      );

      if (switchProgramsResponse?.data) {
        dispatch(clearConsentModalsQueue());
        dispatch(clearConsents());

        await sleep(1000);
        bootstrapApp({
          fontsAreLoaded: true,
          setAppLoading: (isLoading: boolean) => {
            dispatch(setAppDataLoading(isLoading));
          },
        });
      }
      if (error) {
        toast.show('Unable to switch the program.', {
          type: 'danger',
        });
      }
      dispatch(setAppDataLoading(false));
    },
    [dispatch, toast, selectedProgram?.uuid]
  );

  return switchHandler;
};

export default useSwitchProgramHandler;
