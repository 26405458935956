import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const DashDayMark = (props: SvgProps) => (
  <Svg width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
    <Path d='M4.5 8.20801H11.5' stroke={defaultTheme.colors.gray2} strokeLinecap='round' strokeLinejoin='round' />
  </Svg>
);

export default DashDayMark;
