import * as ActionTypes from 'store/action-types';
import type { User } from 'store/reducers/user-info/types';

export type SetBasicUserInformation = (payload: Partial<User>) => {
  type: typeof ActionTypes.SET_BASIC_USER_INFORMATION;
  payload: typeof payload;
};

export const setBasicUserInformation: SetBasicUserInformation = (payload) => ({
  type: ActionTypes.SET_BASIC_USER_INFORMATION,
  payload,
});
