import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const getStyles = (isMobile?: boolean) =>
  StyleSheet.create({
    deleteContentWrapper: {
      paddingHorizontal: isMobile ? 8 : 29,
      paddingVertical: 8,
      maxWidth: isMobile ? 264 : 580,
      height: isMobile ? 279 : 'auto',
    },
    deleteTitle: {
      ...defaultTheme.fontSizes[24],
      color: defaultTheme.colors.gray1,
      fontFamily: defaultTheme.fontFamilies.bold,
      marginBottom: 16,
      marginTop: 19,
    },
    closeModalButton: {
      padding: 33,
      top: 0,
      right: 0,
    },
    deleteDescription: { ...defaultTheme.fontSizes[16], color: defaultTheme.colors.gray1, marginBottom: 30 },
    actionButtonsWrapper: { flexDirection: 'row' },
    cancelButton: { width: 110, marginRight: 16, bottom: -5 },
    deleteButton: { width: 110, bottom: -5 },
  });
