import { SetSelectedProgramAsDefault } from 'store/actions/user/actionCreators/programs/setSelectedProgramAsDefault';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setSelectedProgramAsDefault = (
  state = INITIAL_STATE,
  payload: ReturnType<SetSelectedProgramAsDefault>['payload']
): User => {
  return {
    ...state,
    selectedProgramIsDefault: payload,
  };
};

export default setSelectedProgramAsDefault;
