import { FAPType } from 'screens/main/FinancialAssistance/helpers';

// google analytics constants
const VIEW_FINANCIAL_ASSISTANCE = 'VIEW_FINANCIAL_ASSISTANCE';

export const financialAnalyticsActions = {
  VIEW_FINANCIAL_ASSISTANCE,
};

export const getFAPAnalyticsConstant = (type: FAPType) => `VIEW_${type}_FINANCIAL_ASSISTANCE`;
