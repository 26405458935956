import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { getAppShadow } from 'style/utils';
import { isWebBrowser } from 'utils/helpers';

export const HORIZONTAL_BUTTON_PADDING = 2;

export const useHorizontalButtonsStyles = (buttonWidth: number) => {
  const { colors, fontFamilies } = useTheme();

  return StyleSheet.create({
    container: {
      position: 'relative',
      backgroundColor: 'rgba(118, 118, 128, 0.12)',
      borderRadius: 9,
      padding: HORIZONTAL_BUTTON_PADDING,
    },
    buttonsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      bottom: isWebBrowser ? 2 : 0,
      elevation: 3, //  need for zIndex
    },
    button: {
      alignItems: 'center',
      justifyContent: 'center',
      width: buttonWidth,
      padding: 8,
      borderRadius: 7,
    },
    text: {
      fontSize: 15,
      lineHeight: 20,
      bottom: 2, // To align text on native devices
      fontFamily: fontFamilies.semiBold,
      color: colors.black,
    },
    selectedItemOverlay: {
      position: 'absolute',
      top: HORIZONTAL_BUTTON_PADDING,
      bottom: HORIZONTAL_BUTTON_PADDING,
      left: HORIZONTAL_BUTTON_PADDING,
      ...getAppShadow({
        color: colors.black,
        offset: { width: 0, height: 3 },
        elevation: 2,
        opacity: 0.1,
        radius: 4,
      }),
      borderRadius: 9,
      backgroundColor: colors.white,
      width: buttonWidth,
    },
  });
};
