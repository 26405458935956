import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const getRadioStyles = (secondaryColor: string, activeColor?: string) =>
  StyleSheet.create({
    radioWrapper: { width: 16, height: 16, borderRadius: 8 },
    radioInactive: { borderColor: defaultTheme.colors.gray3, borderWidth: 1 },
    radioActive: { borderColor: activeColor || secondaryColor, borderWidth: 4 },
  });
