import * as ActionTypes from 'store/action-types';
import type { Tile } from 'store/reducers/user-info/types/notification';

export type SetNotificationTiles = (payload: Tile[]) => {
  type: typeof ActionTypes.SET_NOTIFICATION_TILES;
  payload: typeof payload;
};

export const setNotificationTiles: SetNotificationTiles = (payload) => ({
  type: ActionTypes.SET_NOTIFICATION_TILES,
  payload,
});
