import { useState } from 'react';

import ToastContainer, { useToast } from 'react-native-toast-notifications';

import { UserInterface } from 'api';
import { makeRequestWithLoader } from 'utils/requests';
import { AnyFunction } from 'utils/types';

export const useResendActivateEmail = (toastRef: ToastContainer | null) => {
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const toast = useToast();

  const makeRequest = async (onSuccess: AnyFunction) => {
    const res = await makeRequestWithLoader(() => UserInterface.resendActivateEmail(email), setLoading);

    if (!res) return;

    const { type, message } = res;

    const correctToast = type === 'danger' ? toastRef : toast;
    res && correctToast?.show(message, { type });

    if (type === 'success') onSuccess();
  };

  return {
    email,
    isLoading,
    setEmail,
    makeRequest,
  };
};
