import React from 'react';

import { StyleSheet, View } from 'react-native';

import { defaultTheme } from 'style/theme';

export const styles = StyleSheet.create({
  barStyle: {
    height: 8,
    borderRadius: 16,
    width: 77.5,
    backgroundColor: defaultTheme.colors.gray3,
  },
});

export const StepProgressItem = ({ active }: { active: boolean }) => (
  <View style={[styles.barStyle, active && { backgroundColor: defaultTheme.colors.positive }]} />
);

export default StepProgressItem;
