import {
  SET_MEDICATIONS,
  SET_MEDICATION_TRACKER_DATA,
  SET_MEDICATION_MODAL_VISIBILITY,
  SET_MEDICATION_SELECT_OPTIONS,
  SET_METADATA,
  SET_REMINDERS,
  SET_REMINDER_MODAL_VISIBILITY,
  SET_SELECTED_MEDICATION_ID,
  SET_SELECTED_REMINDER_ID,
  SET_IS_MEDICATION_CABINET_LOADING,
  SET_CABINET_NOTIFICATIONS,
  SET_PROGRAM_MEDICATIONS,
  SET_PSP_MEDICATION_HAS_BEEN_ADDED,
  SET_SELECTED_TAB,
  REMOVE_CABINET_NOTIFICATION,
  SET_DRUG_INTERACTIONS,
  SET_DRUG_INTERACTIONS_MODAL_VISIBILITY,
  SET_MEDICATION_WIZARD_VISIBILITY,
  SET_WIZARD_STEP,
  SET_SELECTED_INTERACTION_ID,
  SET_ALL_DRUG_INTERACTIONS,
  SET_AS_NEEDED_MEDICATION_MODAL_VISIBILITY,
  SET_PREVIOUS_DRUG_ID,
  SET_PSP_REMINDER_TEMPLATE,
} from 'store/action-types';
import { AvailableActionsForMedicineCabinet } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { MedicineCabinetInfo } from './types';

const medicineCabinet = (state = INITIAL_STATE, action: AvailableActionsForMedicineCabinet): MedicineCabinetInfo => {
  switch (action.type) {
    case SET_METADATA:
      return {
        ...state,
        reminderTypes: action.payload.reminderTypes,
        medicationTypes: action.payload.medicationTypes,
      };
    case SET_MEDICATIONS:
      return {
        ...state,
        medications: action.payload,
      };
    case SET_MEDICATION_TRACKER_DATA:
      return {
        ...state,
        medTrackerInfo: action.payload,
      };
    case SET_PSP_REMINDER_TEMPLATE:
      return {
        ...state,
        pspReminderTemplate: action.payload,
      };
    case SET_PSP_MEDICATION_HAS_BEEN_ADDED:
      return {
        ...state,
        pspMedicationHasBeenAdded: action.payload,
      };
    case SET_PROGRAM_MEDICATIONS:
      return {
        ...state,
        programMedications: action.payload,
      };
    case SET_REMINDERS:
      return {
        ...state,
        reminders: action.payload,
      };
    case SET_MEDICATION_SELECT_OPTIONS:
      return {
        ...state,
        medicationSelectOptions: action.payload,
      };
    case SET_SELECTED_MEDICATION_ID:
      return {
        ...state,
        selectedMedicationId: action.payload,
      };
    case SET_SELECTED_REMINDER_ID:
      return {
        ...state,
        selectedReminderId: action.payload,
      };
    case SET_MEDICATION_MODAL_VISIBILITY:
      return {
        ...state,
        medicationModalVisibility: action.payload,
      };
    case SET_REMINDER_MODAL_VISIBILITY:
      return {
        ...state,
        reminderModalVisibility: action.payload,
      };
    case SET_IS_MEDICATION_CABINET_LOADING:
      return {
        ...state,
        isMedicationCabinetLoading: action.payload,
      };
    case SET_CABINET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case REMOVE_CABINET_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== action.payload),
      };
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case SET_DRUG_INTERACTIONS:
      return {
        ...state,
        drugInteractions: action.payload,
      };
    case SET_ALL_DRUG_INTERACTIONS:
      return {
        ...state,
        allDrugInteractions: action.payload,
      };
    case SET_DRUG_INTERACTIONS_MODAL_VISIBILITY:
      return {
        ...state,
        drugInteractionsModalVisibility: action.payload,
      };
    case SET_MEDICATION_WIZARD_VISIBILITY:
      return {
        ...state,
        medicationWizardVisibility: action.payload,
      };
    case SET_WIZARD_STEP:
      return {
        ...state,
        medicationWizardStep: action.payload,
      };
    case SET_SELECTED_INTERACTION_ID:
      return { ...state, selectedInteractionId: action.payload };
    case SET_AS_NEEDED_MEDICATION_MODAL_VISIBILITY:
      return { ...state, asNeededMedicationModalVisibility: action.payload };
    case SET_PREVIOUS_DRUG_ID:
      return { ...state, previousDrugId: action.payload };
    default: {
      return state;
    }
  }
};

export default medicineCabinet;
