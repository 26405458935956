import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { Circle, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { screenContentWidth } from 'style/utils';

type PlaceholderParams = {
  count: number;
  spacingPercentage: number;
  yDelta?: number;
};

const CalendarTextHolder = ({ count, spacingPercentage }: PlaceholderParams) => {
  return (
    <>
      {new Array(count).fill(null).map((_, index) => (
        <Rect key={index} x={`${1 + index * spacingPercentage}%`} y='80' rx='3' ry='3' width='6%' height='14' />
      ))}
    </>
  );
};

const CalendarDaysCircularRepresentation = ({ count, spacingPercentage, yDelta = 0 }: PlaceholderParams) => {
  return (
    <>
      {new Array(count).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <Circle cx={`${4 + index * spacingPercentage}%`} cy={`${140 + yDelta}`} r='10' />
          <Rect x={`${3.15 + index * spacingPercentage}%`} y={`${160 + yDelta}`} rx='4' ry='4' width='15' height='12' />
        </React.Fragment>
      ))}
    </>
  );
};

const TrackerContentPlaceholder = () => {
  const { isMobile, ...theme } = useTheme();

  return (
    <ContentLoader
      foregroundColor={theme.colors.gray4}
      backgroundColor={theme.colors.gray3}
      width={screenContentWidth}
      height={'100%'}
      style={{ minHeight: 650 }}>
      {isMobile ? (
        <>
          <Circle cx='15' cy='20' r='12' />
          <Rect x='45' y='12' rx='3' ry='3' width='60' height='16' />
          <Circle cx='135' cy='20' r='12' />
          <Rect x='200' y='12' rx='10' ry='10' width='110' height='16' />
          <Rect x='0' y='55' rx='10' ry='10' width='100%' height='50' />
          <Circle cx='20' cy='140' r='10' />
          <Rect x='12.5' y='160' rx='4' ry='4' width='15' height='12' />
          <Circle cx='90' cy='140' r='10' />
          <Rect x='82.5' y='160' rx='4' ry='4' width='15' height='12' />
          <Circle cx='160' cy='140' r='10' />
          <Rect x='152.5' y='160' rx='4' ry='4' width='15' height='12' />
          <Circle cx='230' cy='140' r='10' />
          <Rect x='222.5' y='160' rx='4' ry='4' width='15' height='12' />
          <Circle cx='300' cy='140' r='10' />
          <Rect x='292.5' y='160' rx='4' ry='4' width='15' height='12' />
          <Rect x='0' y='200' rx='4' ry='4' width='110' height='16' />
          <Rect x='0' y='230' rx='55' ry='55' width='110' height='110' />
          <Circle cx='150' cy='260' r='10' />
          <Rect x='170' y='252' rx='4' ry='4' width='80' height='15' />
          <Circle cx='150' cy='290' r='10' />
          <Rect x='170' y='282' rx='4' ry='4' width='80' height='15' />
          <Rect x='0' y='360' rx='4' ry='4' width='140' height='12' />
          <Rect x='0' y='390' rx='12' ry='12' width={screenContentWidth} height='70' />
          <Rect x='0' y='470' rx='12' ry='12' width={screenContentWidth} height='70' />
          <Rect x='0' y='550' rx='12' ry='12' width={screenContentWidth} height='70' />
        </>
      ) : (
        <>
          <Circle cx='2%' cy='20' r='12' />
          <Rect x='5%' y='12' rx='3' ry='3' width='10%' height='16' />
          <Circle cx='18%' cy='20' r='12' />
          <Rect x='25%' y='12' rx='3' ry='3' width='13%' height='16' />
          <Rect x='43%' y='0' rx='3' ry='3' width='13%' height='40' />
          <Rect x='62%' y='0' rx='4' ry='4' width='16%' height='16' />
          {/* Circular Progress Bar */}
          <Rect x='63%' y='60' rx='55' ry='55' width='110' height='110' />
          <Rect x='80%' y='80' rx='7' ry='7' width='14' height='14' />
          <Rect x='83%' y='80' rx='4' ry='4' width='7%' height='14' />
          <Rect x='80%' y='105' rx='7' ry='7' width='14' height='14' />
          <Rect x='83%' y='105' rx='4' ry='4' width='10%' height='14' />
          <Rect x='62%' y='190' rx='6' ry='6' width='20%' height='18' />
          {/* Daily Medications Placeholder */}
          <Rect x='62%' y='240' rx='12' ry='12' width='38%' height='70' />
          <Rect x='62%' y='330' rx='12' ry='12' width='38%' height='70' />
          <Rect x='90%' y='420' rx='6' ry='6' width='10%' height='20' />
          {/* Calendar Days Text Row */}
          <CalendarTextHolder count={7} spacingPercentage={8} />
          <Rect x='.5%' y='105' rx='3' ry='3' width='55%' height='2' />
          {/* Calendar Days Circular Representation */}
          {new Array(5).fill(null).map((_, index) => (
            <CalendarDaysCircularRepresentation key={index} spacingPercentage={8} count={7} yDelta={index * 75} />
          ))}
        </>
      )}
    </ContentLoader>
  );
};

export default TrackerContentPlaceholder;
