import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const Plus = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M8 1v14M1 8h14'
      stroke={props.stroke || '#000'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Plus;
