import { ImageSourcePropType } from 'react-native';

export const maintenanceDesktop: ImageSourcePropType = require('assets/images/maintenance_desktop.png');
export const maintenanceMobile: ImageSourcePropType = require('assets/images/maintenance_mobile.png');
export const mobilePatientLink: ImageSourcePropType = require('assets/images/mobile_patient_link.png');
export const patientColorLogo: ImageSourcePropType = require('assets/images/patient_color_logo.png');
export const patientIcon: ImageSourcePropType = require('assets/images/patient_icon.png');
export const patientLogo: ImageSourcePropType = require('assets/images/patient_logo.png');
export const patientProfileImg: ImageSourcePropType = require('assets/images/patient_profile_image.png');
export const supportLogo: ImageSourcePropType = require('assets/images/support_program_logo.png');
export const tcPayLogo: ImageSourcePropType = require('assets/images/tc_pay_logo.png');
export const tcSimplifyAccessLogo: ImageSourcePropType = require('assets/images/tc_simplify_access_logo.png');
