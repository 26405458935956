import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Letter = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M2.4 2H13.6C14.37 2 15 2.675 15 3.5V12.5C15 13.325 14.37 14 13.6 14H2.4C1.63 14 1 13.325 1 12.5V3.5C1 2.675 1.63 2 2.4 2Z'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M15 4L8 9L1 4'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Letter;
