import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function Package(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M16.5 10.5L8.25 5.25M21 16.65v-7.8c0-.343-.093-.679-.268-.975A1.976 1.976 0 0020 7.162l-7-3.9a2.04 2.04 0 00-2 0l-7 3.9a1.976 1.976 0 00-.732.713A1.913 1.913 0 003 8.85v7.802c0 .342.093.678.268.974.176.296.428.542.732.713l7 3.9a2.039 2.039 0 002 0l7-3.9c.304-.171.556-.417.732-.713.175-.296.268-.632.268-.974z'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M4.5 9l7.5 4.5 9-5.25M12 22.5v-9'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default Package;
