import React, { ReactNode } from 'react';

import { SimpleTextField } from 'components/SimpleTextField';

import { renderInfoField } from './renderInfoField';

export const renderTextField = ({
  isEditMode,
  isNested,
  color,
  children,
  onChangeText,
}: {
  isEditMode: boolean;
  children: ReactNode | string;
  color?: string;
  isNested?: boolean;
  onChangeText: ((text: string) => void) | undefined;
}) => {
  const EditableComponent = isNested ? (
    <>{children}</>
  ) : (
    <SimpleTextField onChangeText={onChangeText} text={children as string} color={color} />
  );
  return isEditMode ? EditableComponent : renderInfoField(children, isNested, color);
};
