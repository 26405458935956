import { InfoCardTypeEnum } from 'enums';
import { CLOSE_SELECTED_HOME_INFO_CARD } from 'store/action-types';
import {
  SET_BENEFIT_INVESTIGATIONS,
  SET_INSURANCE_TILE_INFO,
  SET_MILESTONE_TRACKER_DATA,
  SET_SPECIALTY_PHARMACY,
  SHOW_HOME_INFO_CARD,
} from 'store/action-types/cards';
import {
  SetBenefitInvestigationsAction,
  SetInsuranceTileInfoAction,
  SetMilestoneTrackerDataAction,
  SetSpecialtyPharmacyAction,
  ShowHomeInfoCardAction,
} from 'store/actions/cards/types';
import { AppActionCreator } from 'utils/types';

export const closeSelectedInfoCard = (payload: { selectedCardTitle: InfoCardTypeEnum }) => ({
  type: CLOSE_SELECTED_HOME_INFO_CARD,
  payload,
});

export const showHomeInfoCard: AppActionCreator<ShowHomeInfoCardAction> = (payload) => ({
  type: SHOW_HOME_INFO_CARD,
  payload,
});

export const setMilestoneTrackerData: AppActionCreator<SetMilestoneTrackerDataAction> = (payload) => ({
  type: SET_MILESTONE_TRACKER_DATA,
  payload,
});

export const setBenefitInvestigations: AppActionCreator<SetBenefitInvestigationsAction> = (payload) => ({
  type: SET_BENEFIT_INVESTIGATIONS,
  payload,
});

export const setSpecialtyPharmacy: AppActionCreator<SetSpecialtyPharmacyAction> = (payload) => ({
  type: SET_SPECIALTY_PHARMACY,
  payload,
});

export const setInsuranceTileInfo: AppActionCreator<SetInsuranceTileInfoAction> = (payload) => ({
  type: SET_INSURANCE_TILE_INFO,
  payload,
});
