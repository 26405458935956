import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { hex2rgba, applyBreakpoints } from 'style/utils';

const welcomeCardMinHeight = 264;

export const useStyles = () => {
  const { isMobile, colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    contentWrapper: {
      flex: 1,
      ...applyBreakpoints(theme, {
        md: { flexDirection: 'column', minHeight: welcomeCardMinHeight },
        xxl: { flexDirection: 'row', minHeight: welcomeCardMinHeight },
      }),
    },
    logoWrapper: applyBreakpoints(theme, {
      md: { height: welcomeCardMinHeight / 1.25 },
      xxl: { width: '50%' },
    }),
    logoMask: {
      ...StyleSheet.absoluteFillObject,
      borderTopLeftRadius: 16,
      borderTopRightRadius: isMobile ? 16 : 0,
      zIndex: 2,
      backgroundColor: hex2rgba(colors.primary, 0.7),
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    textBoxWrapper: {
      justifyContent: 'center',
      ...applyBreakpoints(theme, {
        md: {
          paddingHorizontal: 32,
          paddingVertical: 26,
        },
        xxl: {
          width: '50%',
          paddingHorizontal: 42,
          paddingVertical: 36,
        },
      }),
    },
    header: {
      ...fontSizes[24],
      fontFamily: fontFamilies.bold,
      color: colors.secondary,
    },
    description: {
      ...fontSizes[16],
      marginTop: 8,
      color: colors.gray1,
    },
  });
};
