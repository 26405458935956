import React, { useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import { Pressable } from 'react-native';
import { useDispatch } from 'react-redux';

import { NotificationsInterface } from 'api';
import { RouteType } from 'components/MenuItem';
import { Routes } from 'routes/main';
import { HomeClosableCard } from 'screens/main/Home/components/HomeClosableCard/HomeClosableCard';
import { initSimpleTextNotificationTiles } from 'store/actions/user/actionCreators/initData/initSimpleTextNotificationTilesData';
import { DestinationPageType, SimpleTextNotificationTileType } from 'store/reducers/user-info/types/notification';
import { DefaultText } from 'style/common';
import { openUrlInNewTab } from 'utils/helpers';

import { useSimpleNotificationTileStyles } from './styles';

type SimpleTextNotificationTileProps = {
  tile: SimpleTextNotificationTileType;
  isVisible: boolean;
};

const defaultTitle = "There's no configured title";
const defaultDescription = "There's no configured description";
const defaultExternalLinkName = 'external link';

const routeToDestinationPage: Record<DestinationPageType, RouteType> = {
  FINANCIAL_ASSISTANCE: Routes.FINANCIAL_ASSISTANCE,
  BENEFITS: Routes.BENEFITS,
  RESOURCES: Routes.RESOURCES,
  REBATE_CENTER: Routes.REBATE_CENTER,
  PROFILE: Routes.PROFILE,
  MEDICINE_CABINET: Routes.MEDICINE_CABINET,
  TRACKER: Routes.TRACKER,
};

export const SimpleTextNotificationTile = ({ tile, isVisible }: SimpleTextNotificationTileProps) => {
  const navigation = useNavigation<any>();
  const dispatch = useDispatch();
  const { id, title, description, externalLink, externalLinkName, destinationPage, isDeleted, isClosable } = tile;

  const styles = useSimpleNotificationTileStyles();

  useEffect(() => {
    NotificationsInterface.updateSimpleTextNotificationTile({ id, is_read: true });
  }, [id]);

  const openExternalLink = () => externalLink && openUrlInNewTab(externalLink);

  const onClose = async () => {
    await NotificationsInterface.updateSimpleTextNotificationTile({ id, is_deleted: true });
    await dispatch(initSimpleTextNotificationTiles);
  };

  const onPress = () => !!destinationPage && navigation.navigate(routeToDestinationPage[destinationPage]);

  if (isDeleted) return null;

  return (
    <HomeClosableCard
      onPress={destinationPage ? onPress : undefined}
      onClose={isClosable ? onClose : undefined}
      visible={isVisible}
      isNotificationCard
      title={title || defaultTitle}
      description={description || defaultDescription}>
      {!!externalLink && (
        <Pressable
          onPress={openExternalLink}
          style={(state) => [
            styles.buttonPadding,
            // @ts-ignore
            state.hovered && styles.hoverButton,
          ]}>
          <DefaultText numberOfLines={1} style={styles.externalLinkText}>
            {externalLinkName || defaultExternalLinkName}
          </DefaultText>
        </Pressable>
      )}
    </HomeClosableCard>
  );
};
