import { defaultTheme } from 'style/theme';

import { InitialReducerState } from './types';

export const INITIAL_STATE: InitialReducerState = {
  appDataIsLoading: false,
  appTheme: {
    primary: defaultTheme.colors.primary,
    secondary: defaultTheme.colors.secondary,
  },
  hasModifications: false,
};
