import * as ActionTypes from 'store/action-types';
import { CustomServiceTypeEnum } from 'store/reducers/additional-services/types';
import type { AppConsent, ProgramConsent, ServiceConsent } from 'store/reducers/user-info/types/consent';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

export type SetConsents = (payload: {
  consents: ProgramConsent[] | AppConsent[] | ServiceConsent[];
  groupType: ConsentModalTypeEnum;
  customServiceType?: CustomServiceTypeEnum;
}) => {
  type: typeof ActionTypes.SET_CONSENTS;
  payload: typeof payload;
};

export const setConsents: SetConsents = (payload) => ({ type: ActionTypes.SET_CONSENTS, payload });
