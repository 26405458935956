import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Tinture = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 64.23 142.03' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M44.05 25.8a12.4 12.4 0 0 0-1.3-3.39V10.27C42.75.18 34.27-.06 32.37 0S22 .18 22 10.27v12.14a12.4 12.4 0 0 0-1.3 3.39c-.92.65-6.92 1-6.92 2.35v15.66s.91 1.7 2.09 1.57c.13 1.43-.22 2.57 1.23 2.93a64.84 64.84 0 0 0 14.81 1.61h.93a64.84 64.84 0 0 0 14.81-1.61c1.45-.36 1.1-1.5 1.23-2.93 1.18.13 2.09-1.57 2.09-1.57V28.15c.03-1.31-5.97-1.7-6.92-2.35Z'
          fill='#ddd'
        />
        <Path
          d='M62.44 108.83c0 4.65.41 9.34-.09 13.94-1.08 10.08-3.51 13-13.62 14.19a157.51 157.51 0 0 1-34.66 0c-6.57-.71-10.95-5.29-11.41-14-.52-9.77-.12-19.59-.15-29.39 0-2.2 1-3.37 3.2-3.62 5.24-.6 10.46-1.71 15.7-1.84A250 250 0 0 1 54.7 89c7.16.78 7.74 1.18 7.75 8.4q0 5.7-.01 11.43Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.1}
          d='M32.63 49.93h-.52l-6.44-.24v30.42a10.42 10.42 0 0 0 1.32 5c1.15 2.11 2.73 6.72 3.27 16.44a6.72 6.72 0 0 0 1.85.44h.52a6.72 6.72 0 0 0 1.86-.44c.54-9.72 2.12-14.33 3.27-16.44a10.42 10.42 0 0 0 1.32-5V49.69Z'
        />
        <Path
          d='M64.23 131.26V79.78c0-12.1-7.11-16-7.11-16s-2.58-2.09-6.13-5-3.23-5.32-3.23-5.32v-5.15c-6.29 1.78-15.65 1.62-15.65 1.62s-9.35.16-15.65-1.62v5.17s.33 2.42-3.22 5.32-6.14 5-6.14 5S0 67.68 0 79.78v51.48s-.48 7.31 11.46 9A159.65 159.65 0 0 0 32.11 142a159.74 159.74 0 0 0 20.66-1.73c11.94-1.7 11.46-9.01 11.46-9.01Z'
          fillOpacity={0.4}
          fill='#e2e2e2'
        />
        <Path
          fillOpacity={0.1}
          d='M9.2 77.19s-7.71-3.69-7.71 4.23v48.25s.63 6.12 6 8.34 9 0 9 0V80.36s-4.23-2.85-7.29-3.17Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M42 64.83c3.11-.22 6.95-.82 9-2.37a.49.49 0 0 1 .53-.05c1.34.72 6.9 4 8.35 7.58a42.85 42.85 0 0 1 2 9.6.51.51 0 0 1-.92.35c-2.45-3.53-8.83-11.29-19.16-14.11a.51.51 0 0 1 .2-1ZM39.41 4.6S41 5.8 41 12.84c0 2.64-.06 4.81-.15 6.48a.56.56 0 0 1-1.11.09c-.9-4.24-2.38-10.41-3.88-11.74a2.8 2.8 0 0 1-.53-4l.1-.1c.35-.29 2.29-1.57 3.98 1.03Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={37.06} cy={5.5} r={1.65} />
        <Path
          fillOpacity={0.1}
          d='M32.37 95.35c10.81 0 22.84-2.27 29.46-3.72-.83-1.84-2.74-2.18-7.13-2.63a250 250 0 0 0-33.29-.85c-5.24.1-10.41 1.21-15.7 1.85a3.25 3.25 0 0 0-2.8 1.63 135.09 135.09 0 0 0 29.46 3.72Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M50.71 28.15c0-1.31-6-1.7-6.92-2.35 0 0-3.8 2.37-11.68 2.37S20.44 25.8 20.44 25.8c-.92.65-6.92 1-6.92 2.35 0 0 3 3 18.59 3s18.6-3 18.6-3Z'
        />
        <Path
          fillOpacity={0.1}
          d='M32.37 47.65c10.86 0 16.13-1.54 16.48-1.65v-.62c-19.75 3.62-33 0-33 0V46c.4.11 5.67 1.65 16.52 1.65ZM22.74 20.52c-.1 1.93-.4 4.27-1.22 5.39a.11.11 0 0 0 .05.18 14.86 14.86 0 0 0 5.63.9.36.36 0 0 0 .26-.62 15.5 15.5 0 0 1-4-5.95.36.36 0 0 0-.72.1Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={57.76} cy={100.83} r={3.5} />
        <Circle fill='#fff' fillOpacity={0.4} cx={50.03} cy={110} r={2.35} />
        <Circle fill='#fff' fillOpacity={0.4} cx={56.55} cy={116.4} r={2.41} />
        <Circle fill='#fff' fillOpacity={0.4} cx={47.02} cy={121.47} r={4.35} />
        <Circle fill='#fff' fillOpacity={0.4} cx={54.26} cy={127.99} r={3.02} />
        <Circle fill='#fff' fillOpacity={0.4} cx={61.94} cy={83.67} r={0.94} />
      </G>
    </G>
  </Svg>
);

export default Tinture;
