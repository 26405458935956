import { TouchableOpacity, StyleSheet, Platform } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { DefaultText, getFontStyles, getBoxShadowStyle } from 'style/common';
import { applyBreakpoints } from 'style/utils';

export const useStyles = () => {
  const { colors, fontFamilies, fontSizes, ...theme } = useTheme();

  return StyleSheet.create({
    imageWrapper: applyBreakpoints(theme, {
      md: { display: 'none' },
      xl: {
        width: '30%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    }),
    image: applyBreakpoints(theme, {
      xl: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      xxl: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      },
    }),
    container: {
      borderRadius: 16,
      width: '100%',
      backgroundColor: colors.white,
      ...getBoxShadowStyle(),
      ...(Platform.OS !== 'ios' && { overflow: 'hidden' }),
      ...applyBreakpoints(theme, {
        md: {},
        xl: { flexDirection: 'row', paddingRight: 26 },
        xxl: { flexDirection: 'column' },
      }),
    },
    infoWrapper: applyBreakpoints(theme, {
      md: { width: '100%' },
      xl: { flexDirection: 'row', justifyContent: 'space-between', width: '70%' },
    }),
    textsWrapper: applyBreakpoints(theme, {
      md: { width: '100%', marginBottom: 28 },
      xl: { width: '73%', marginBottom: 0 },
      xxl: { width: '100%', marginBottom: 28 },
    }),
    goMobileTextStyle: {
      ...fontSizes[16],
      fontFamily: fontFamilies.bold,
      marginBottom: 20,
      color: colors.gray1,
    },
    stayConnectedStyle: { ...fontSizes[16], color: colors.gray1 },
    badgesWrapperStyle: applyBreakpoints(theme, {
      md: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      xl: {},
      xxl: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
    }),
  });
};

export const HelpText = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${(props) => props.theme.colors.gray1};
  margin-bottom: 7px;
  max-width: 224px;
`;

export const CardContentWrapper = styled.View`
  padding: 32px;
  border-radius: 16px;
  background: white;
  elevation: 3;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 28px 32px 30px 23px;
    `}
`;

export const StyledImageWrapper = styled.View`
  height: 192px;
  width: 100%;
`;

export const GooglePlayBadgeWrapper = styled(TouchableOpacity)`
  width: 135px;
  height: 38px;
  margin-bottom: 8px;
`;

export const AppStoreBadgeWrapper = styled(TouchableOpacity)`
  width: 118px;
  height: 38px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const ModalWrapper = styled.View`
  padding: 0 55px 29px 29px;
  max-width: 396px;
`;

export const ModalHeader = styled(DefaultText)`
  ${getFontStyles(24)};
  color: ${(props) => props.theme.colors.primary};
  margin-top: 30px;
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const ModalDescription = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${(props) => props.theme.colors.gray2};
  margin: 30px 0 40px;
`;

export const ButtonContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ButtonWrapper = styled.View`
  width: fit-content;
`;
