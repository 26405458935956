import React from 'react';

import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { useComponentStyles } from 'components/LeftSideDrawer/components/styles';
import { getResourcesNotificationIconVisibility } from 'store/selectors';

export const MenuItemNotificationIcon = () => {
  const shouldShowNotificationIcon = useSelector(getResourcesNotificationIconVisibility);

  const styles = useComponentStyles();

  if (!shouldShowNotificationIcon) return null;

  return <View style={styles.redDotStyle} />;
};
