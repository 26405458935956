import { DependencyList, useEffect, useRef } from 'react';

import { AnyFunction } from 'utils/types';

export const useFirstEffect = (callback: AnyFunction, deps?: DependencyList, allowInitialEmptyDependencies = true) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (!allowInitialEmptyDependencies && deps?.length && deps.some((value) => !value)) return;

    if (mounted.current) return;

    mounted.current = true;
    callback();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
