import React from 'react';

import { TextStyle } from 'react-native';
import styledWeb, { css } from 'styled-components';
import styled, { useTheme } from 'styled-components/native';

import { isNativeDevice, isSafari } from 'utils/helpers';

export const usePickerStyle = (userType?: string): TextStyle => {
  const { colors, fontSizes, fontFamilies } = useTheme();

  return {
    height: 25,
    borderWidth: 0,
    backgroundColor: colors.gray2,
    color: colors.white,
    fontSize: fontSizes[14].fontSize,
    fontFamily: fontFamilies.default,
    textAlign: 'center',
    width: userType === 'CAREGIVER' ? 85 : 68,
    borderRadius: 100,
    paddingRight: 0,
    paddingLeft: 0,
    paddingVertical: 1,
    alignItems: 'center',
  };
};

export const SelectBoxWrapper = (
  isNativeDevice
    ? styled.View``
    : styledWeb.div`
  select { 
    ${
      isSafari &&
      css`
        padding: 0 6px;
      `
    }
  }
`
) as React.ElementType;
