import React, { useEffect, useState } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { FullScreenLoader } from 'components/Loaders';
import { useMountedEffect } from 'hooks/useMountedEffect';
import { CommunicationPreferencesField, ProfileInfoLayout } from 'screens/main/Profile/components';
import { profileAnalyticsActions } from 'screens/main/Profile/constants';
import { profileAnalyticsHandler } from 'screens/main/Profile/helpers';
import { BasicInfoBlock, InformationContainer } from 'screens/main/Profile/sections/styles';
import { saveUserPreferenceInfo, setBasicUserInformation } from 'store/actions';
import { getUserCommunicationPreferences } from 'store/selectors/user';
import { useStylesWithAdditional } from 'style/hooks';
import { TRY_AGAIN_ERROR_MESSAGE } from 'utils/constants';

import { usePreferencesStyles } from './styles';
import { AvailableInputFields, CommunicationInputFieldType } from './types';

export const CommunicationPreferencesSection = () => {
  const dispatch = useDispatch();

  const toast = useToast();
  const isFocused = useIsFocused();

  const { styles, colors } = useStylesWithAdditional(usePreferencesStyles);

  const communicationFields: CommunicationInputFieldType[] = [
    { name: 'hasEmailPreference', label: 'E-mail' },
    { name: 'hasCellPhonePreference', label: 'Text Message' },
    { name: 'hasPushNotificationPreference', label: 'Push Notification (Mobile Only)', style: styles.increaseWidth },
  ];

  const userCommunicationPreferences = useSelector(getUserCommunicationPreferences);

  const [savingIsInProgress, setSavingInProgress] = useState(false);

  const [userInfo, setUserInfo] = useState(userCommunicationPreferences);

  useEffect(() => {
    if (isFocused) return;

    setUserInfo(userCommunicationPreferences);
  }, [isFocused, userCommunicationPreferences]);

  const setValue = (fieldName: AvailableInputFields) => (fieldValue: boolean) => {
    setUserInfo({ ...userInfo, [fieldName]: fieldValue });
  };

  const autoSave = async () => {
    dispatch(setBasicUserInformation(userInfo));

    setSavingInProgress(true);

    try {
      await dispatch(saveUserPreferenceInfo());
      profileAnalyticsHandler(profileAnalyticsActions.COMM_PREF_CHANGED_INFO);
    } catch (error) {
      const message = error?.response?.data?.message || TRY_AGAIN_ERROR_MESSAGE;
      toast.show(message, {
        type: 'danger',
      });
    }

    setSavingInProgress(false);
  };

  useMountedEffect(() => {
    const timeout = setTimeout(autoSave, 1000);

    return () => clearTimeout(timeout);
  }, [userInfo]);

  return (
    <ProfileInfoLayout
      wrapperStyle={styles.wrapperStyle}
      title='Communication Preferences'
      titleColor={colors.mangoPrimary}
      description="Let us know how you would like us to communicate with you. We'll send you information based on all active channels."
      withActionButton={false}>
      {savingIsInProgress && <FullScreenLoader />}
      <InformationContainer style={styles.infoContainer}>
        {communicationFields.map(({ name, label, style }) => (
          <BasicInfoBlock style={[styles.infoBlock, style]} key={label}>
            <CommunicationPreferencesField isEditMode onChange={setValue(name)} value={userInfo[name]} label={label}>
              {userInfo[name] ? 'Active' : 'Inactive'}
            </CommunicationPreferencesField>
          </BasicInfoBlock>
        ))}
      </InformationContainer>
    </ProfileInfoLayout>
  );
};
