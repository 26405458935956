import { ProgramConsentsInterface, ServiceConsentsInterface } from 'api';
import { AppConsentsInterface } from 'api/Consents/appConsentsApi';
import { ApiConsentsInterface } from 'api/Consents/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

export const consentsApiMap: Record<ConsentModalTypeEnum, ApiConsentsInterface> = {
  [ConsentModalTypeEnum.app]: AppConsentsInterface,
  [ConsentModalTypeEnum.program]: ProgramConsentsInterface,
  [ConsentModalTypeEnum.service]: ServiceConsentsInterface,
};
