import { ScrollView, StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';

export const getStyles = (isMobile?: boolean, height: number) =>
  StyleSheet.create({
    modalHeaderStyle: { marginBottom: 19, marginTop: isMobile ? 11 : 0, ...(isMobile && { maxWidth: 240 }) },
    conditionsTextWrapperScrollView: {
      height: '100%',
      maxHeight: height * 0.65,
      paddingRight: isMobile ? 16 : 29,
    },
  });

export const TermsAndConditionContentContainer = styled.View`
  padding: 23px 0px 29px 29px;
  min-width: 400px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 16px 0 0 0;
      min-width: 280px;
    `}
`;

export const ConditionsText = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${(props) => props.theme.colors.gray2};
  max-width: 738px;
`;
