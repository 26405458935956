import React, { useState } from 'react';

import { View, Text } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Button, useTransition, AnimatedWrapper } from 'components';
import { useCabinetInfoStyles } from 'screens/main/MedicineCabinet/components/CabinetWidgets/styles';
import { DefaultText } from 'style/common';
import { notificationCardsAnimation } from 'utils/animation';
import { AnyFunction } from 'utils/types';

import { CabinetInfoType } from '../../types';
import { cabinetInfoMapping } from './constants';

export type CabinetInfoProps = {
  type: CabinetInfoType;
  infoText?: string;
  detailsText?: string;
  Icon?: React.ElementType;
  skipHandler: AnyFunction;
  doneHandler: AnyFunction;
};

export const CabinetInfo = ({ type, infoText, detailsText, Icon, skipHandler, doneHandler }: CabinetInfoProps) => {
  const [cabinetInfoVisible, setCabinetInfoVisible] = useState(true);
  const { colors, isMobile = true } = useTheme();
  const transition = useTransition(cabinetInfoVisible, notificationCardsAnimation(isMobile, 600));

  const styles = useCabinetInfoStyles(type);

  const {
    defaultInfoText,
    DefaultIcon,
    actionButtons: { first, second },
  } = cabinetInfoMapping[type];
  const InfoIcon = Icon || DefaultIcon;

  const handleSkip = () => {
    skipHandler();
    setCabinetInfoVisible(false);
  };

  const handleDone = () => {
    doneHandler();
    setCabinetInfoVisible(false);
  };

  return transition((style, isInfoVisible) => {
    if (!isInfoVisible) return null;

    return (
      <AnimatedWrapper style={style}>
        <View style={styles.container}>
          <View style={styles.infoTextAndIconWrapper}>
            <View style={styles.infoIconWrapper}>
              <InfoIcon width={24} height={24} stroke={colors.white} />
            </View>
            <View style={styles.textsWrapper}>
              <Text numberOfLines={1} style={styles.infoTitleText}>
                {infoText || defaultInfoText}
              </Text>
              {!!detailsText && (
                <DefaultText numberOfLines={1} style={styles.detailsText}>
                  {detailsText}
                </DefaultText>
              )}
            </View>
          </View>
          <View style={styles.actionButtonsWrapper}>
            <Button style={styles.firstInfoAction} variant='mango' text={first.buttonText} onPress={handleSkip} />
            <Button
              style={styles.secondInfoAction}
              textStyle={{ color: styles.container.backgroundColor }}
              variant='mango'
              text={second.buttonText}
              onPress={handleDone}
            />
          </View>
        </View>
      </AnimatedWrapper>
    );
  });
};

export default CabinetInfo;
