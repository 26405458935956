import { normalize, schema } from 'normalizr';

import { Survey } from 'store/reducers/user-info/types/survey';

import { NormalizedConsentsSchema } from './consents';
import { NormalizedSurveysSchema } from './surveys';

export const normalizeConsentsData = <T>(
  data: T | T[],
  schema: schema.Array<T> | Array<schema.Array<T>>
): NormalizedConsentsSchema<T> => normalize(data, schema);

export const normalizeSurveysData = (
  data: Survey[],
  schema: schema.Array<Survey> | Array<schema.Array<Survey>>
): NormalizedSurveysSchema => normalize(data, schema);
