import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';
import { isNativeDevice } from 'utils/helpers';

export const useAddPhoneNumberModalStyles = () => {
  const { fontSizes, fontFamilies, colors, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: applyBreakpoints(theme, {
      sm: {
        padding: 12,
        width: 248,
      },
      xxl: {
        padding: 32,
        width: 416,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }),
    title: {
      ...fontSizes[24],
      color: colors.primary,
      fontFamily: fontFamilies.bold,
    },
    description: {
      marginTop: 16,
      color: colors.gray2,
    },
    negative: {
      margin: 4,
      color: colors.negative,
      ...(!isNativeDevice && { height: 0 }),
    },
    buttonContainer: applyBreakpoints(theme, {
      sm: { flexDirection: 'column' },
      xxl: { flexDirection: 'column' },
    }),
    switchWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: 16,
      borderRadius: 8,
    },
    communicationSwitchText: {
      ...fontSizes[16],
      marginLeft: 16,
      color: colors.gray1,
    },
    buttonStyle: {
      alignSelf: 'flex-start',
      marginTop: 24,
    },
  });
};
