import * as ActionTypes from 'store/action-types';
import { AppAction, AppActionCreator } from 'utils/types';

export type ResetUserInfoActionCreator = AppActionCreator<AppAction<typeof ActionTypes.RESET_USER_INFO>>;
export type ResetUserDemoInfoActionCreator = AppActionCreator<AppAction<typeof ActionTypes.RESET_USER_DEMO_INFO>>;
export type ResetUserMFAInfoActionCreator = AppActionCreator<AppAction<typeof ActionTypes.RESET_USER_MFA_INFO>>;

export const resetUserInfo: ResetUserInfoActionCreator = () => ({
  type: ActionTypes.RESET_USER_INFO,
  payload: undefined,
});

export const resetUserDemoInfo: ResetUserDemoInfoActionCreator = () => ({
  type: ActionTypes.RESET_USER_DEMO_INFO,
  payload: undefined,
});

export const resetUserMFAInfo: ResetUserMFAInfoActionCreator = () => ({
  type: ActionTypes.RESET_USER_MFA_INFO,
  payload: undefined,
});
