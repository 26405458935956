import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Ring = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 133.07 133.01' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M66.62 0C29.26-.19.05 29 0 66.14s28.94 66.18 65.9 66.37c37.35.19 66-29 66.76-66.14C132 29.2 103.58.19 66.62 0Zm-.56 116.73c-26.47 1.69-50.83-23.67-50.67-50.8.16-26.31 24-50.76 51.32-50.54 26 .21 51 23.91 50.4 51.94-.51 25.61-23.91 51.21-51.05 49.4Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.1}
          d='M66.66 2.5H67a63.6 63.6 0 0 1 45.24 18.73 66.07 66.07 0 0 1 18.82 45.64 66 66 0 0 1-19 45.55A63.57 63.57 0 0 1 66.66 131h-.35c-35.88-.18-63.95-28.45-63.9-64.36S30.68 2.5 66.66 2.5m0-2C29.48.5.46 29.62.41 66.65S29.34 132.83 66.3 133h.36c37.18 0 65.67-29.11 66.41-66.14C132.43 29.71 104 .69 67 .5Z'
        />
        <Path
          d='M66.35 15.39h.36c26 .21 51 23.91 50.4 51.94-.5 24.75-22.37 49.49-48.34 49.49-.9 0-1.8 0-2.71-.09s-1.68.08-2.51.08c-25.48 0-48.31-24.61-48.16-50.88s23.81-50.54 51-50.54m0-4c-13.89 0-27.9 6.06-38.43 16.63S11.47 52.29 11.39 65.91c-.08 14.18 6 28.48 16.69 39.23 10.05 10.1 22.65 15.67 35.47 15.67.83 0 1.67 0 2.5-.07s1.82.08 2.72.08c13.42 0 26.43-5.93 36.63-16.71 9.72-10.28 15.45-23.66 15.71-36.7.28-14.19-5.37-28-15.9-38.8S80.66 11.5 66.74 11.39Z'
          fillOpacity={0.2}
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M127.62 70.93a2.51 2.51 0 0 1-2.5-2.42c-1.65-51.05-47.41-59.89-47.87-60a2.5 2.5 0 0 1 .88-4.93 68.26 68.26 0 0 1 25.34 11.52c11.64 8.44 25.72 24.52 26.65 53.25a2.51 2.51 0 0 1-2.42 2.58Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={71.72} cy={5.01} r={2.08} />
        <Path
          fillOpacity={0.1}
          d='M2.79 58.85a2.51 2.51 0 0 1 2.3 2.61c-2.34 51 42.58 63.42 43 63.53a2.5 2.5 0 0 1-1.27 4.84 68 68 0 0 1-24.32-13.44C11.55 107.08-1.22 90 .09 61.23a2.5 2.5 0 0 1 2.62-2.38Z'
        />
      </G>
    </G>
  </Svg>
);

export default Ring;
