import React from 'react';

import { TouchableOpacity, View } from 'react-native';

import { AppScrollView, CheckboxWithLabel } from 'components';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { DefaultText, PrimaryDefaultText, useConsentStyles } from 'style/common';
import { openUrlInNewTab } from 'utils/helpers';

import Constants from './constants';

export type ServiceConsentProps = {
  actionId: number;
  onConfirmed: (confirmed: boolean) => void;
  confirmed: boolean;
  title?: string;
  description?: string;
  checkboxLabel?: string;
  termsAndConditionsUrl?: string;
  serviceName?: string;
};

export const ServiceConsent = ({
  title,
  description,
  checkboxLabel,
  termsAndConditionsUrl,
  onConfirmed,
  confirmed,
  serviceName,
}: ServiceConsentProps) => {
  const { isMobile } = useDeviceInfoContext();
  const changeHandler = () => {
    onConfirmed(!confirmed);
  };

  const consentStyles = useConsentStyles();

  const termsAndConditionsNewTabHandler = () =>
    openUrlInNewTab(termsAndConditionsUrl || Constants.termsAndConditionsURL);

  return (
    <>
      <PrimaryDefaultText style={consentStyles.title}>
        {title || `Please confirm your consent to use ${serviceName || '[Name]'}`}
      </PrimaryDefaultText>
      <View style={consentStyles.consentScrollWrapper}>
        <AppScrollView leftScrollOffset={16} style={consentStyles.consentContentScroll}>
          <DefaultText style={consentStyles.consentContentText}>
            {description ||
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'}
          </DefaultText>
        </AppScrollView>
      </View>
      <CheckboxWithLabel
        isMobile={isMobile}
        handleConfirmChange={changeHandler}
        isConfirmed={confirmed}
        text={checkboxLabel || 'I consent to being contacted'}
      />
      <TouchableOpacity style={consentStyles.termsAndConditionTextWrapper} onPress={termsAndConditionsNewTabHandler}>
        <DefaultText style={consentStyles.termsAndConditionText}>Terms and conditions</DefaultText>
      </TouchableOpacity>
    </>
  );
};

export default ServiceConsent;
