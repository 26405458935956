import Constants from 'expo-constants';
import { Pressable } from 'react-native';
import styled, { css } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

export const HeaderContainer = styled.View`
  padding: 0 32px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  background: white;
  flex-direction: row;
  justify-content: space-between;
  elevation: 3;
  z-index: 1;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column-reverse;
      padding: 0;
    `}
  ${isNativeDevice &&
  css`
    box-shadow: 0 13px 10px rgba(0, 0, 0, 0.07);
    height: ${130 - Constants.statusBarHeight}px;
  `}
`;

export const HeaderWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  height: 120px;
  padding: 0px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0px 11px 0px 11px;
      height: 100px;
    `}
`;

export const ActionsWrapper = styled.View`
  margin-left: auto;
  flex-direction: row;
  align-items: center;
`;

export const MenuIconWrapper = styled(Pressable)`
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
`;
