import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';

export const useSpecialtyPharmacyCardStyles = () => {
  const { fontSizes, fontFamilies, colors, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: applyBreakpoints(theme, {
      lg: { flexDirection: 'column' },
      xxl: {
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    }),
    baseInfoWrapper: applyBreakpoints(theme, {
      lg: {},
      xxl: { width: '33%' },
    }),
    contactDataWrapper: applyBreakpoints(theme, {
      lg: {},
      xxl: { width: '27%' },
    }),
    title: {
      ...fontSizes[16],
      fontFamily: fontFamilies.bold,
      color: colors.secondary,
    },
    description: {
      ...fontSizes[16],
      marginTop: 14,
      color: colors.gray1,
    },
    contactName: {
      ...fontSizes[16],
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
      ...applyBreakpoints(theme, {
        lg: { marginTop: 18 },
      }),
    },
    contactNumberLabel: {
      marginTop: 8,
      color: colors.gray2,
    },
    contactNumber: {
      ...fontSizes[16],
      marginTop: 8,
      color: colors.gray1,
    },
    addToContactButton: applyBreakpoints(theme, {
      lg: { marginTop: 24, alignSelf: 'flex-start' },
    }),
  });
};
