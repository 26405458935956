import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { isNativeDevice } from 'utils/helpers';

export const useTourContentStyle = () => {
  const { colors, fontSizes, fontFamilies } = useTheme();
  const { width } = useDeviceInfoContext();

  return StyleSheet.create({
    container: {
      borderRadius: 16,
      padding: 32,
      paddingBottom: 16,
      width: isNativeDevice ? 'auto' : width,
      maxWidth: isNativeDevice ? width - 32 : '100%',
      backgroundColor: '#fff',
      position: 'relative',
    },
    bottomBar: {
      marginTop: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    gotItText: {
      color: colors.secondary,
      fontFamily: fontFamilies.semiBold,
      ...fontSizes[16],
    },
    skipTutorialText: {
      color: colors.gray1,
      ...fontSizes[16],
    },
    descriptionText: {
      color: colors.gray1,
      ...fontSizes[16],
    },
    titleText: {
      fontFamily: fontFamilies.bold,
      color: colors.gray1,
      ...fontSizes[16],
      marginBottom: 12,
    },
    tourActionButton: {
      paddingVertical: 10,
      paddingRight: 10,
      userSelect: 'none',
    },
  });
};

export const useTourProgressBarStyle = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    progressBar: {
      height: 8,
      borderRadius: 16,
      flex: 1,
      backgroundColor: colors.positive,
    },
  });
};

export const getTourProgressHeaderStyle = StyleSheet.create({
  headerProgressContainer: {
    width: '100%',
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
