import { GetCommunicationConsents, CommunicationConsent } from 'store/reducers/user-info/types/consent';
import apiRequest, { apiPrefix } from 'utils/network';

const PATIENT_CONSENTS_URL = '/api/v1/patients/consents/';
const ALL_PATIENT_CONSENT_URL = `${PATIENT_CONSENTS_URL}all-consents/`;

export const CommunicationConsentInterface = {
  saveGivenConsent: ({ consents }: { consents: Pick<CommunicationConsent, 'consentCategoryUuid' | 'status'>[] }) =>
    apiRequest({
      method: 'POST',
      endpoint: PATIENT_CONSENTS_URL,
      baseURL: apiPrefix,
      body: { consents },
    }),
  getConfig: (searchParam?: string) =>
    apiRequest<GetCommunicationConsents>({
      method: 'GET',
      endpoint: ALL_PATIENT_CONSENT_URL,
      baseURL: apiPrefix,
      params: !!searchParam && { consent_category_name: searchParam },
    }).then((res) => res.data),
};
