import React, { useState } from 'react';

import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface } from 'api';
import { Bell, Plus } from 'assets/icons';
import { ReminderCard } from 'components';
import {
  MedicationRemindersContainer,
  MedicationSubSection,
  MedicationSubSectionSpacer,
  PlusWrapper,
  RemindersWrapper,
} from 'screens/main/MedicineCabinet/styles';
import { MedCabinetWidgetType } from 'screens/main/MedicineCabinet/types';
import { setReminderModalVisibility, setSelectedReminderId } from 'store/actions';
import { Reminder } from 'store/reducers/medicine-cabinet/types';
import { getIsMedicationCabinetLoading, getSelectedReminderId } from 'store/selectors';
import { MedicationSubTitle } from 'style/common';

import { CabinetEmpty } from '../CabinetWidgets';
import { DeleteModal } from '../modals/DeleteModal';
import { ReminderListPlaceHolder } from '../Placeholders';

type RemindersProps = {
  reminderSubmitHandler(): void;
  reminders: Reminder[];
};

export const Reminders = ({ reminderSubmitHandler, reminders }: RemindersProps) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [deleteModalIsVisible, setDeleteModalVisibility] = useState(false);
  const selectedReminderId = useSelector(getSelectedReminderId);
  const isMedicineCabinetLoading = useSelector(getIsMedicationCabinetLoading);

  const addReminderHandler = () => {
    dispatch(setSelectedReminderId(null));
    dispatch(setReminderModalVisibility(true));
  };

  const reminderPressHandler = (id: number) => {
    dispatch(setSelectedReminderId(id));
    dispatch(setReminderModalVisibility(true));
  };

  const deleteReminderPressHandler = (id: number) => {
    dispatch(setSelectedReminderId(id));
    setDeleteModalVisibility(true);
  };

  const handleDelete = async () => {
    try {
      if (selectedReminderId) {
        await MedicineCabinetInterface.deleteReminder(selectedReminderId);
        reminderSubmitHandler();
        toast.show('Reminder successfully deleted!', { type: 'success' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderReminders = () => {
    if (isMedicineCabinetLoading) return <ReminderListPlaceHolder />;
    if (!reminders.length)
      return (
        <CabinetEmpty
          Icon={Bell}
          infoText='Looks like you haven’t added any reminders'
          actionButtonText='Add Reminder'
          actionType={MedCabinetWidgetType.REMINDER}
          onPress={addReminderHandler}
        />
      );
    return (
      <RemindersWrapper>
        {[...reminders].reverse().map((reminder, i) => (
          <ReminderCard
            {...reminder}
            key={reminder.id}
            isFirst={i === 0}
            medicineCount={1}
            onPress={reminderPressHandler}
            onDeleteReminderPress={() => deleteReminderPressHandler(reminder.id)}
          />
        ))}
      </RemindersWrapper>
    );
  };

  return (
    <MedicationRemindersContainer>
      <MedicationSubSection>
        <MedicationSubTitle>Your Reminders</MedicationSubTitle>
        <PlusWrapper onPress={addReminderHandler}>
          <Plus />
        </PlusWrapper>
      </MedicationSubSection>
      <MedicationSubSectionSpacer />
      {renderReminders()}
      <DeleteModal
        itemNameToBeDeleted={MedCabinetWidgetType.REMINDER}
        setWidgetModalVisibility={setReminderModalVisibility}
        setDeleteModalVisibility={setDeleteModalVisibility}
        deleteModalIsVisible={deleteModalIsVisible}
        deleteHandler={handleDelete}
      />
    </MedicationRemindersContainer>
  );
};

export default Reminders;
