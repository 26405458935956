import React, { createContext, PropsWithChildren, useContext } from 'react';

import { useWindowDimensions } from 'react-native';

import { breakpoints } from 'style/constants';

export type DeviceInfoContext = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isPortrait: boolean;
  width: number;
  height: number;
};

const initialDeviceInfo: DeviceInfoContext = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isPortrait: false,
  width: 0,
  height: 0,
};

export const DeviceInfoContext = createContext<DeviceInfoContext>(initialDeviceInfo);

export const DeviceInfoContextProvider = (props: PropsWithChildren<unknown>) => {
  const { width = 0, height = 0 } = useWindowDimensions();
  const deviceInfo = {
    isMobile: width <= breakpoints.sm,
    isTablet: width <= breakpoints.lg,
    isDesktop: width <= breakpoints.xxl,
    isPortrait: width < height,
    width,
    height,
  };

  return <DeviceInfoContext.Provider value={deviceInfo}>{props.children}</DeviceInfoContext.Provider>;
};

export const useDeviceInfoContext = () => useContext(DeviceInfoContext);
