import {
  USER_ENROLLED_TO_THE_PSP_FROM_EMAIL,
  USER_ENROLLED_TO_THE_PSP_FROM_SMS,
} from 'screens/auth/SetPassword/constants';

const VIEW_LANDING_PAGE = 'VIEW_LANDING_PAGE';
const USER_ENROLLED_TO_THE_FAP_FROM_SMS = 'USER_ENROLLED_TO_THE_FAP_FROM_SMS';
const USER_ENROLLED_TO_THE_FAP_FROM_EMAIL = 'USER_ENROLLED_TO_THE_FAP_FROM_EMAIL';
const VIEW_LANDING_PAGE_FIRST_TIME_DASHBOARD = 'VIEW_LANDING_PAGE_FIRST_TIME_DASHBOARD';

export const homeAnalyticsActions = {
  VIEW_LANDING_PAGE,
  USER_ENROLLED_TO_THE_FAP_FROM_SMS,
  USER_ENROLLED_TO_THE_FAP_FROM_EMAIL,
  USER_ENROLLED_TO_THE_PSP_FROM_EMAIL,
  USER_ENROLLED_TO_THE_PSP_FROM_SMS,
  VIEW_LANDING_PAGE_FIRST_TIME_DASHBOARD,
};
