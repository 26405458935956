import React, { useRef } from 'react';

import { Animated, TouchableOpacity, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SwiperRef } from '@types';
import { Delete, Edit } from 'assets/icons';
import { ActionText } from 'components/ServiceActionItem/styles';
import SwipeableRightAction from 'components/SwipeableRightAction';
import SwipeableWrapper from 'components/SwipeableWrapper';
import { useDemonstrateSwiperAnimation } from 'components/SwipeableWrapper/useDemonstrateSwiperAnimation';
import { extractMeridiemValuesFromTime24 } from 'components/TimePicker/helpers';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { setShowedReminderItemDemoAnimation } from 'store/actions/demoConfig';
import { getShowedReminderItemDemoAnimation } from 'store/selectors/demoConfig';
import { defaultTheme } from 'style/theme';
import { sleep } from 'utils/helpers';

import {
  MedicineInformationWrapper,
  ReminderCardContainer,
  ReminderInformationWrapper,
  ReminderInfoText,
  getStyles,
} from './style';
import { ReminderCardProps, ReminderTypesEnum } from './types';

export const ReminderCard = ({
  id,
  medication,
  time_of_day,
  type,
  every_x_hours,
  every_x_days,
  every_x_weeks,
  medicineCount,
  onPress,
  onDeleteReminderPress,
  isFirst,
}: ReminderCardProps) => {
  const dispatch = useDispatch();

  const swiperRef: SwiperRef = useRef(null);
  const showedReminderItemDemoAnimation = useSelector(getShowedReminderItemDemoAnimation);

  useDemonstrateSwiperAnimation({
    enabled: isFirst && !showedReminderItemDemoAnimation,
    showDelay: 700,
    closeDelay: 1000,
    swiperRef,
    onAnimationDone: () => dispatch(setShowedReminderItemDemoAnimation(true)),
  });

  const reminderText = `Take ${medicineCount} ${medication?.type}`;

  const reminderStart = time_of_day ? extractMeridiemValuesFromTime24(time_of_day).convertedTime : '00:00 AM';

  const getReminderFrequencyInfoText = () => {
    switch (type) {
      case ReminderTypesEnum.EVERY_X_DAYS: {
        return `Every ${every_x_days} Days starting at ${reminderStart}`;
      }
      case ReminderTypesEnum.EVERY_X_HOURS: {
        return `Every ${every_x_hours} ${every_x_hours > 1 ? 'hours' : 'hour'} starting at ${reminderStart}`;
      }
      case ReminderTypesEnum.EVERY_X_WEEKS: {
        return `Every ${every_x_weeks} ${every_x_weeks > 1 ? 'Weeks' : 'Week'} starting at ${reminderStart}`;
      }
      case ReminderTypesEnum.ONE_TIME: {
        return `One time reminder starting at ${reminderStart}`;
      }
      case ReminderTypesEnum.AS_NEEDED:
        return 'As-Needed';
      case ReminderTypesEnum.DAILY: {
        return `Every Day starting at ${reminderStart}`;
      }
      default: {
        return `Every Day starting at ${reminderStart}`;
      }
    }
  };

  const editReminderHandler = async () => {
    onPress(id);
    await sleep(150);
    swiperRef.current?.close();
  };

  const deleteReminderHandler = async () => {
    onDeleteReminderPress(id);
    await sleep(150);
    swiperRef.current?.close();
  };

  const { isMobile } = useDeviceInfoContext();
  const styles = getStyles(isMobile);

  const renderRightActions = (progress: Animated.AnimatedInterpolation, dragX: Animated.AnimatedInterpolation) => {
    return (
      <View style={styles.swipeableRightActionsWrapper}>
        <SwipeableRightAction
          height={106}
          x={72}
          progress={progress}
          Icon={Edit}
          onPress={editReminderHandler}
          bgColor={defaultTheme.colors.gray4}
        />
        <SwipeableRightAction
          height={106}
          x={144}
          progress={progress}
          Icon={Delete}
          onPress={deleteReminderHandler}
          bgColor={defaultTheme.colors.negative}
        />
      </View>
    );
  };

  return (
    <SwipeableWrapper swiperRef={swiperRef} renderRightActions={renderRightActions}>
      <ReminderCardContainer onPress={editReminderHandler}>
        <MedicineInformationWrapper>
          <ActionText numberOfLines={1} style={styles.actionText}>
            {reminderText}
          </ActionText>
          {!isMobile && (
            <View style={styles.actionsWrapper}>
              <TouchableOpacity onPress={editReminderHandler} style={styles.touchableArea}>
                <Edit size='small' />
              </TouchableOpacity>
              <TouchableOpacity onPress={deleteReminderHandler} style={styles.touchableArea}>
                <Delete size='small' />
              </TouchableOpacity>
            </View>
          )}
        </MedicineInformationWrapper>
        <ReminderInformationWrapper>
          <ReminderInfoText numberOfLines={1} style={styles.takeInfo}>
            {getReminderFrequencyInfoText()}
          </ReminderInfoText>
        </ReminderInformationWrapper>
      </ReminderCardContainer>
    </SwipeableWrapper>
  );
};

export default ReminderCard;
