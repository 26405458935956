import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';

export const styles = {
  addButton: { transform: [{ rotate: '45deg' }], marginRight: 10 },
};

export const AddButton = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
`;
