import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle, Ellipse, Rect } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Elixer = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 84.28 104.91' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M42 100.44c-9.68.48-19.12-1.39-28.57-3C6.07 96.1 3.12 91 5.51 83.8c2.33-7 6.63-13 10.21-19.32 1.75-3.1 5.25-2.66 7.58-2.67 13.77-.05 27.58-1.36 41.32.65 1.52.22 3 .24 3.86 1.78 3.74 6.45 8.1 12.59 10.41 19.77 2.17 6.76-.85 12.11-7.82 13.35-9.61 1.71-19.22 3.57-29.07 3.08Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Circle fill='#f9f9f9' cx={45.5} cy={80.93} r={3.5} />
        <Circle fill='#f9f9f9' cx={19.88} cy={73.83} r={3.5} />
        <Circle fill='#f9f9f9' cx={34.3} cy={86.35} r={3.5} />
        <Circle fill='#f9f9f9' cx={46.68} cy={88.6} r={1.26} />
        <Circle fill='#f9f9f9' cx={54.02} cy={78.29} r={1.26} />
        <Circle fill='#f9f9f9' cx={65.57} cy={69.66} r={1.26} />
        <Circle fill='#f9f9f9' cx={37.81} cy={77.43} r={1.26} />
        <Circle fill='#f9f9f9' cx={20.35} cy={89.86} r={1.26} />
        <Circle fill='#f9f9f9' cx={65.04} cy={89.35} r={2.33} />
        <Path
          fillOpacity={0.1}
          d='M67.34 63.1a8 8 0 0 0-2.72-.64c-13.74-2-27.55-.7-41.32-.65-1.79 0-4.28-.24-6.12 1 5.1.77 23.4 2.87 50.16.29Z'
        />
        <Path
          d='M82.11 81.46C75 66.84 59.2 35 59.2 35l-.45-30.9s1.42-.7 1.55-1.41S60.24.53 54.94.33s-16.08-.64-27.57 0c-1.29.13-5 .26-3.55 2.78a2.19 2.19 0 0 1 .71 1v30.77L2.36 80.09A22.46 22.46 0 0 0 0 88.44c-.33 5.08 1.07 11.67 10 13.37 15.08 2.86 44 5 62.56.6 4.44-1.36 16.64-6.33 9.55-20.95Z'
          fill='#e5e5e5'
          fillOpacity={0.3}
        />
        <Ellipse fillOpacity={0.1} cx={41.33} cy={1.84} rx={16.23} ry={0.66} />
        <Rect fill='#fff' fillOpacity={0.4} x={50.77} y={6.13} width={4.95} height={24.44} rx={2.48} />
        <Circle fill='#fff' fillOpacity={0.4} cx={53.25} cy={8.85} r={2.17} />
        <Path
          fillOpacity={0.1}
          d='M4.29 83.11s-7.7 16.47 14.17 18c2.1.18 12.85.89 19.52 1.33a.89.89 0 0 0 .48-1.67c-7.8-4.22-21.69-16.33-16.46-48.1a.89.89 0 0 0-1.66-.55Z'
        />
      </G>
    </G>
  </Svg>
);

export default Elixer;
