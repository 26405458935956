import { useState, useMemo } from 'react';

import { MedicineCabinetInterface } from 'api';
import { AutoCompleteData } from 'components/AutoCompleteInput/types';
import { useDebounce } from 'hooks';
import { useMountedEffect } from 'hooks/useMountedEffect';
import { handlePromise } from 'utils/handlePromise';
import { makeRequestWithLoader } from 'utils/requests';

const request = async (query: string) => {
  const [res] = await handlePromise(() => MedicineCabinetInterface.searchDrugs(query));

  return res;
};

export const useSearchDrugs = (search: string) => {
  const [isLoadingCollection, setLoadingCollection] = useState<Record<string, boolean>>({});
  const [drugsCollection, setDrugsCollection] = useState<Record<string, AutoCompleteData[]>>({});

  const debouncedSearch = useDebounce(search.toLocaleLowerCase(), 300);

  useMountedEffect(() => {
    if (drugsCollection[debouncedSearch]?.length || !debouncedSearch) return;

    makeRequestWithLoader(
      () => request(debouncedSearch),
      (isLoading) => {
        setLoadingCollection((prev) => ({ ...prev, [debouncedSearch]: isLoading }));
      }
    ).then(
      (res) =>
        res?.data.length &&
        setDrugsCollection((prev) => ({
          ...prev,
          [debouncedSearch]: res.data.map(({ id, name }) => ({
            id,
            text: name,
          })),
        }))
    );
  }, [debouncedSearch]);

  const drugs = useMemo(() => drugsCollection[debouncedSearch] ?? [], [drugsCollection, debouncedSearch]);
  const isLoading = useMemo(
    () => isLoadingCollection[debouncedSearch] ?? false,
    [isLoadingCollection, debouncedSearch]
  );

  return {
    drugs,
    isLoading,
  };
};
