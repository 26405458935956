import * as ActionTypes from 'store/action-types';

export type SetSurveyCompleted = (payload: boolean) => {
  type: typeof ActionTypes.SET_SURVEY_COMPLETED;
  payload: boolean;
};

export const setSurveyCompleted: SetSurveyCompleted = (payload) => ({
  type: ActionTypes.SET_SURVEY_COMPLETED,
  payload,
});
