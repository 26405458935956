import React from 'react';

import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import { useSelector } from 'react-redux';

import { Routes } from 'routes/main';
import { MedicationDetails, MedicineCabinet, InteractionDetails } from 'screens/main/MedicineCabinet';
import { getSelectedTab } from 'store/selectors';
import { isNativeDevice } from 'utils/helpers';

import { screenTitles } from './useGetAppScreens';

export type CabinetStackParamList = {
  [Routes.LIST]: undefined;
  [Routes.DETAILS]?: { medicationId: number; medicationProgramId: number };
  [Routes.INTERACTION_DETAILS]: { medicationId: number; interactionId: number; drugId: number };
};

const screenOptions: StackNavigationOptions = {
  headerShown: false,
  animationEnabled: isNativeDevice,
};

const CabinetStack = createStackNavigator<CabinetStackParamList>();

export const MedicineCabinetStack = () => {
  const selectedTab = useSelector(getSelectedTab);

  return (
    <CabinetStack.Navigator screenOptions={screenOptions} initialRouteName={Routes.LIST}>
      <CabinetStack.Screen
        name={Routes.LIST}
        component={MedicineCabinet}
        options={{ title: screenTitles[Routes.LIST] }}
      />
      <CabinetStack.Screen
        name={Routes.DETAILS}
        component={MedicationDetails}
        options={{ title: screenTitles[selectedTab] }}
      />
      <CabinetStack.Screen
        name={Routes.INTERACTION_DETAILS}
        component={InteractionDetails}
        options={{ title: screenTitles[Routes.INTERACTION_DETAILS] }}
      />
    </CabinetStack.Navigator>
  );
};
