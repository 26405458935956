import { StyleSheet } from 'react-native';

import { getBoxShadowStyle } from 'style/common';

export const styles = StyleSheet.create({
  wrapper: { position: 'relative' },
  content: {
    position: 'absolute',
    top: 10,
    right: -20,
    borderRadius: 10,
    padding: 20,
    backgroundColor: 'white',
    minWidth: 170,
    ...getBoxShadowStyle(),
  },
});
