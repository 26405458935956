import { StyleSheet } from 'react-native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { getBoxShadowStyle } from 'style/common';
import { isNativeDevice, isWebNativeDevice } from 'utils/helpers';

export const useBoundaryUIStyles = () => {
  const { isMobile, width } = useDeviceInfoContext();

  return StyleSheet.create({
    safeAreView: { flex: 1 },
    header: {
      flexDirection: 'row',
      height: isMobile ? 100 : 120,
      width: '100%',
      paddingLeft: 32,
      alignItems: 'center',
      ...getBoxShadowStyle(),
    },
    content: { alignItems: 'center' },
    maintenanceImageWrapper: {
      height: isMobile ? width : '80%',
      width: isMobile ? width : '80%',
      zIndex: 1,
    },
    textWrapper: {
      width: isMobile ? '75%' : '20%',
      height: isNativeDevice || isWebNativeDevice ? 'auto' : '100%',
      marginBottom: 14,
    },
    headerText: { textAlign: 'center', marginVertical: 16 },
    subText: { textAlign: 'center' },
  });
};
