import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Powder = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 78.41 130.33' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M.24 87.22V64.76c-.05-5.48 2.13-9.55 7-12.23 4.59-2.52 4.95-3.9 1.39-7.91C5.88 41.53 5 38.24 5.16 34.21c.25-8 .25-16 0-24-.13-3.89 1.67-5.8 5-7C16.15 1.08 22.52.69 28.71.34 41.45-.37 54.22-.17 66.78 3c4.68 1.18 6.68 3.36 6.47 8.29-.32 7.64-.27 15.31 0 23a13.9 13.9 0 0 1-3.47 10.41c-3.53 4-3.17 5.38 1.43 7.91 4.87 2.68 7 6.77 7 12.25-.05 14-.69 28 .18 41.9.85 13.66-8.12 18.81-17.49 21a92 92 0 0 1-43.35 0c-9.41-2.28-18.78-7.43-17.42-21.09.65-6.42.11-12.96.11-19.45Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Ellipse cx={39.04} cy={6.95} rx={32.42} ry={6.3} fillOpacity={0.3} fill='#fff' />
        <Path
          fillOpacity={0.1}
          d='M3.81 81.84a.8.8 0 0 0-1.58.16l.07 29.48s-.66 11.88 18.47 15.35a.8.8 0 0 0 .71-1.34C17.63 121.52 10 111.33 3.81 81.84ZM7.18 23.66a.52.52 0 0 0-1 .11v12.54s-.62 7.49 11.12 8.06a.52.52 0 0 0 .42-.87A44.26 44.26 0 0 1 7.18 23.66Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M70 12.23a35.64 35.64 0 0 1-11.19 2.37.68.68 0 0 0-.38 1.2C61.28 18 67.44 23.38 69.9 30a.67.67 0 0 0 1.3-.24c0-5.73-.17-14-.24-16.95a.67.67 0 0 0-.96-.58ZM58.06 54.25c4-.24 8.48-.73 10.42-1.73a.68.68 0 0 1 .54-.05 9.93 9.93 0 0 1 7.06 9.91v11.38a.71.71 0 0 1-1.38.24c-2-6-6.58-15.14-16.8-18.36a.71.71 0 0 1 .16-1.39Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={75.47} cy={77.74} r={1.26} />
        <Path
          fillOpacity={0.1}
          d='M10.46 47.1a2.42 2.42 0 0 1-.74 3.74s28.38 6.67 59.74.64c0 .09-3.4-1.91-1.54-4.38-2.04.36-22.61 5.09-57.46 0Z'
        />
        <Path
          fillOpacity={0.1}
          d='m7.38 43.05 1.24 1.57s30.65 7.55 61.15 0c0 0-.2.51.8-1.07 0 .01-29.06 7.45-63.19-.5Z'
        />
        <G fillOpacity={0.2}>
          <Ellipse cx={39.21} cy={11.51} rx={1.68} ry={0.33} />
          <Ellipse cx={34.1} cy={7.47} rx={1.4} ry={0.33} />
          <Ellipse cx={28.3} cy={4.53} rx={1.4} ry={0.33} />
          <Ellipse cx={20.35} cy={3.6} rx={1.4} ry={0.33} />
          <Ellipse cx={39.02} cy={1.71} rx={1.4} ry={0.33} />
          <Ellipse cx={57.27} cy={2.97} rx={1.4} ry={0.33} />
          <Ellipse cx={39.27} cy={4.5} rx={1.4} ry={0.33} />
          <Ellipse cx={50.25} cy={4.47} rx={1.4} ry={0.33} />
          <Ellipse cx={45.25} cy={7.39} rx={1.4} ry={0.33} />
          <Ellipse cx={25.11} cy={9.5} rx={1.68} ry={0.33} />
          <Ellipse cx={10.91} cy={6.61} rx={1.68} ry={0.33} />
          <Ellipse cx={54.17} cy={9.5} rx={1.68} ry={0.33} />
          <Ellipse cx={66.04} cy={6.56} rx={1.68} ry={0.33} />
        </G>
      </G>
    </G>
  </Svg>
);

export default Powder;
