import React from 'react';

import { useTheme } from 'styled-components/native';

import { Check, Minus } from 'assets/icons';
import { MilestonesTrackerStatus } from 'components/HistoryItem/types';

type Config = {
  IconComponent: React.ElementType;
  bgColor: string;
};

export const useGetHistoryItemInfo = (type: MilestonesTrackerStatus): Config => {
  const { colors } = useTheme();

  const statusConfigMapping: Record<MilestonesTrackerStatus, Config> = {
    Complete: {
      IconComponent: Check,
      bgColor: colors.positive,
    },
    'In process': {
      IconComponent: Minus,
      bgColor: colors.gray2,
    },
    'Not started': {
      IconComponent: Minus,
      bgColor: colors.gray2,
    },
  };

  return statusConfigMapping[type];
};
