import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function Video(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M20.211 1.5H3.789A2.289 2.289 0 001.5 3.789v16.422A2.289 2.289 0 003.789 22.5h16.422a2.289 2.289 0 002.289-2.289V3.789A2.289 2.289 0 0020.211 1.5zM7.5 1.5v21M16.5 1.5v21M1.5 12h21M1.5 6h6M1.5 18h6M18 18h4.5M18 6h4.5'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default Video;
