import Constants from 'expo-constants';
import { StyleSheet } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { Col, Grid } from 'components/Grid';
import { DefaultText, getFontStyles } from 'style/common';
import { MAX_CONTENT_WIDTH } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';
import { isNativeDevice } from 'utils/helpers';

export const useStyles = (isProfilePage: boolean) => {
  const { colors, ...theme } = useTheme();

  return StyleSheet.create({
    contentWrapper: {
      flexGrow: 1,
      backgroundColor: colors.white,
      padding: 32,
      paddingBottom: isNativeDevice ? 0 : 32,
      borderTopStartRadius: 16,
      borderTopEndRadius: 16,
      ...applyBreakpoints(theme, {
        xl: { flexDirection: 'column' },
        xxl: { flexDirection: 'row' },
      }),
    },
    rightSide: applyBreakpoints(theme, {
      sm: { width: '100%', marginTop: 0 },
      xl: { width: '100%', marginTop: 32 },
    }),
    downloadCard: { marginTop: isProfilePage ? 0 : 20 },
    leftSide: applyBreakpoints(theme, {
      xl: { width: '100%' },
      xxl: { marginRight: 62 },
    }),
  });
};

export const ContentArea = styled.View<{ contentAreaColor?: string }>`
  background-color: ${(props) => props.contentAreaColor || props.theme.colors.primary};
  padding: 0 32px;
  align-items: center;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `}
`;

export const PageInformationWrapper = styled.View`
  padding: ${({ theme }) => (theme.isMobile ? '27px 40px' : '27px 0')};
`;

export const PageTitle = styled(DefaultText)<{ isProfilePage?: boolean }>`
  ${getFontStyles(24)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${({ theme: { colors }, isProfilePage }) => (isProfilePage ? colors.gray1 : colors.white)};
  margin-bottom: 6px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      text-align: center;
    `}
`;

export const PageDescription = styled(DefaultText)<{ isProfilePage?: boolean }>`
  ${getFontStyles(16)}
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  color: ${({ theme: { colors }, isProfilePage }) => (isProfilePage ? colors.gray2 : colors.white)};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin: 0 10px;
      text-align: center;
    `}
`;

export const ContentContainer = styled.View`
  max-width: ${MAX_CONTENT_WIDTH}px;
  width: 100%;
  min-height: ${({ theme: { height = 0 } }) => height - (isNativeDevice ? 220 + Constants.statusBarHeight : 240)}px;
`;

export const ContentWrapper = styled(Grid)`
  background-color: ${(props) => props.theme.colors.white};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 32px;
  flex-direction: row;
  ${({ theme }) =>
    theme.isTablet &&
    css`
      flex-direction: column;
    `}
`;

export const LeftSideContentWrapper = styled(Col)`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 100%;
      margin-right: 0;
    `}
  ${({ theme }) =>
    theme.isTablet &&
    !theme.isMobile &&
    css`
      min-width: 100%;
    `}
`;

export const RightSideContentWrapper = styled.View`
  width: 25%;
  ${({ theme }) =>
    theme.isTablet &&
    css`
      width: 100%;
    `}
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 100%;
      padding: 0;
      justify-content: flex-end;
      margin-top: ${isNativeDevice ? 12 : 22}px;
    `}
`;
