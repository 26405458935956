import * as ActionTypes from 'store/action-types';

export type SetSessionExpiredModalVisible = (payload: boolean) => {
  type: typeof ActionTypes.SET_SESSION_EXPIRED_MODAL_VISIBLE;
  payload: boolean;
};

export const setSessionExpiredModalVisible: SetSessionExpiredModalVisible = (payload) => ({
  type: ActionTypes.SET_SESSION_EXPIRED_MODAL_VISIBLE,
  payload,
});
