import { SET_APP_DATA_LOADING, SET_APP_THEME, SET_HAS_MODIFICATIONS } from 'store/action-types';

import { AppTheme } from './types';

export const setAppDataLoading = (payload: boolean) => ({
  type: SET_APP_DATA_LOADING,
  payload,
});

export const setAppTheme = (payload: AppTheme) => ({
  type: SET_APP_THEME,
  payload,
});

export const setHasModifications = (payload: boolean) => ({
  type: SET_HAS_MODIFICATIONS,
  payload,
});
