import React from 'react';

import { AppHTML } from 'components/AppHTML/AppHTML';
import { MedicineCabinetLayout } from 'screens/main/MedicineCabinet/layout';
import { policyInfo } from 'screens/serviceInfo/PrivacyPolicy/policyInfo';

export const PrivacyPolicy = () => {
  return (
    <MedicineCabinetLayout withDownloadCard={false}>
      <AppHTML source={{ html: policyInfo }} />
    </MedicineCabinetLayout>
  );
};
