import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle, Rect } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Injection = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 103.03 103.7' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill='#ddd'
          d='M10.31 23.28 20.07 33s1.42 1.68 2.67.53 4.61-4.16 5.94-5.63 5.06-6 5.06-6 .62-.89-.62-2-10-9.5-10-9.5l4.77-4.77s1.18-.73-.6-2.42A30.91 30.91 0 0 0 23.8.39s-.89-1.16-2.8.53-10 8.69-10 8.69L.91 20.26a2.87 2.87 0 0 0 0 4.17c2 2.31 3 3.51 3 3.51s.63.84 2.31-.58a53.42 53.42 0 0 0 4.09-4.08Z'
        />
        <Path
          d='M71.28 80.8 82 70.09l-5.35-16.3-17.49-17.35a2.9 2.9 0 0 0-3.89-.17c-3.7 3.11-11.18 9.89-11.73 14.36-.56.37-6.34 2.23-8 5.4-.37 1.49-.37 2.23 1.86 4.65S54 76.89 54 76.89Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.1}
          d='M43.54 50.63s20.88-6.71 15.62-14.19c0 0-2.06-1.74-3.89-.17s-11.54 10.1-11.73 14.36Z'
        />
        <Circle fill='#fff' cx={61.56} cy={48.58} r={2.3} />
        <Circle fill='#fff' cx={61.76} cy={57.03} r={2.3} />
        <Circle fill='#fff' cx={68.58} cy={54.79} r={2.3} />
        <Circle fill='#fff' cx={69.39} cy={61.71} r={2.3} />
        <Circle fill='#fff' cx={73.97} cy={67.81} r={2.3} />
        <Path
          fill='#ddd'
          d='M10.31 23.28 20.07 33s1.42 1.68 2.67.53 4.61-4.16 5.94-5.63 5.06-6 5.06-6 .62-.89-.62-2-10-9.5-10-9.5l4.77-4.77s1.18-.73-.6-2.42A30.91 30.91 0 0 0 23.8.39s-.89-1.16-2.8.53-10 8.69-10 8.69L.91 20.26a2.87 2.87 0 0 0 0 4.17c2 2.31 3 3.51 3 3.51s.63.84 2.31-.58a53.42 53.42 0 0 0 4.09-4.08Z'
        />
        <Path
          fill='#fff'
          d='M12 12.24C18.23 6.3 21.69 2.07 23 0a2.85 2.85 0 0 0-2 .92C19 2.6 11 9.61 11 9.61L.91 20.26a3.2 3.2 0 0 0-.85 1.53A74.75 74.75 0 0 0 12 12.24Z'
        />
        <Path
          fill='#ddd'
          d='M39.82 14.67a50.06 50.06 0 0 0-6.37 5.6 1.43 1.43 0 0 1 .29 1.73s-3.73 4.52-5.06 6-4.7 4.48-5.94 5.63a1.56 1.56 0 0 1-2.1 0l-4.43 4.7a8.44 8.44 0 0 0-1.51 2.07c-.55 1.16-.68 2.27 2.5-.07a133.2 133.2 0 0 0 12.29-11.14l11.58-13.62s1.42-2.41-1.25-.9Z'
        />
        <Path
          fill='#fff'
          d='M39.82 14.67a50.06 50.06 0 0 0-6.37 5.6 1.43 1.43 0 0 1 .29 1.73s-3.73 4.52-5.06 6-4.7 4.48-5.94 5.63a1.56 1.56 0 0 1-2.1 0l-4.43 4.7a8.44 8.44 0 0 0-1.51 2.07c-.55 1.16-.68 2.27 2.5-.07a133.2 133.2 0 0 0 12.29-11.14l11.58-13.62s1.42-2.41-1.25-.9Z'
        />
        <Path
          fillOpacity={0.1}
          d='M11.29 22.43s1.24-.89 1.24-1.09S22.8 31.61 22.8 31.61s-.61 3.85-11.51-9.18ZM13.4 20.8l.43-.51 10.02 10.02-.43.48L13.4 20.8z'
        />
        <Path
          d='M80.62 52.06 41.76 13.94l-.36.37c.21.35-.33 1.26-.33 1.26L29.49 29.19A133.2 133.2 0 0 1 17.2 40.33a8.18 8.18 0 0 1-2.27 1.34l-.26.26L53.6 80.42l21.21 5.25h2.51L85 78Z'
          fill='#e2e2e2'
          fillOpacity={0.4}
        />
        <Rect
          fill='#fff'
          x={37.14}
          y={39.42}
          width={4.05}
          height={43.06}
          rx={2.02}
          transform='rotate(-45 39.157 60.95)'
        />
        <Rect
          fill='#fff'
          x={43.22}
          y={41.13}
          width={2.37}
          height={37.78}
          rx={1.19}
          transform='rotate(-45 44.404 60.027)'
        />
        <Path fillOpacity={0.2} d='M51.74 78.56 79.4 50.9l1.22 1.16-27.7 27.7-1.18-1.2z' />
        <Path
          fill='#ddd'
          d='M84.93 77.68a.31.31 0 0 0-.37-.08 19.45 19.45 0 0 0-4.53 3 21.57 21.57 0 0 0-3 4.1.31.31 0 0 0 .06.39L85.35 93a.33.33 0 0 0 .35.07c.4-.18 2.11-1.42 2.11-1.42s10.51 8.61 14.7 12a.32.32 0 0 0 .45-.45L91.31 88.56a11 11 0 0 0 1.52-2.23.28.28 0 0 0-.09-.19Z'
        />
        <Path
          fillOpacity={0.1}
          d='M91.31 88.56a11 11 0 0 0 1.52-2.23 11.62 11.62 0 0 0-4.16 2.62 15.66 15.66 0 0 0-3.45 4l.15.14a.33.33 0 0 0 .35.07c.4-.18 2.11-1.42 2.11-1.42a6.29 6.29 0 0 1 3.48-3.18ZM78.3 83.45a5.81 5.81 0 0 0-.68 1.16c.12.32 7.42 7 7.42 7a1.69 1.69 0 0 1 .44-.76c-.68-.5-7.18-7.4-7.18-7.4Z'
        />
      </G>
    </G>
  </Svg>
);

export default Injection;
