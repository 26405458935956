import React from 'react';

import { View, Image } from 'react-native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useImageSizes } from 'hooks';

export type ImageViewerProps = {
  source: string;
};

export const ImageViewer = ({ source }: ImageViewerProps) => {
  const { isMobile, width } = useDeviceInfoContext();
  const widthValue = isMobile ? width : 600;

  const { imageHeight } = useImageSizes({
    url: source,
    isSrc: true,
    heightValue: widthValue,
  });

  return (
    <View style={{ height: imageHeight, width: widthValue }}>
      <Image style={{ width: '100%', height: '100%' }} source={{ uri: source }} />
    </View>
  );
};
