import React from 'react';

import Svg, { SvgProps, Defs, LinearGradient, Stop, G, Path, Ellipse } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Spray = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 77.21 138.54' {...props}>
    <Defs>
      <LinearGradient id='linear-gradient' x1={40.65} y1={37.28} x2={77.21} y2={37.28} gradientUnits='userSpaceOnUse'>
        <Stop offset={0} stopColor='#6d6d6d' stopOpacity={0.6} />
        <Stop offset={1} stopOpacity={0} />
      </LinearGradient>
    </Defs>
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M68.51 132.62s.12-65.76 0-66.61c.6-.24.12-.72.6-1.57-.24-2.53-4.83-2.41-4.83-2.65-2.05-15.45-22.92-18.46-22.92-18.46v-4.44c-.4 0-.72-.71-.72-1.58s.32-1.58.72-1.58v-4.11c-1.09-1.33-6.52-1.09-6.52-1.09s-5.43-.24-6.52 1.09v11.71S7.45 46.34 5.4 61.79c0 .24-4.59.12-4.83 2.65.48.85 0 1.33.6 1.57-.12.85 0 66.61 0 66.61s-3.17 1.93.83 3.14c1.08.36 7.55 2.78 32.82 2.78s31.74-2.42 32.82-2.78c4.01-1.21.87-3.14.87-3.14Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.2}
          d='M5.92 62.29s-2.3-.19-1.88 1c.1.31 8.25 4.82 30.8 4.82s31.78-4.51 31.78-4.51.38-1.95-3.62-.83-44.9-3.09-44.9-3.09Z'
        />
        <Path
          d='M41.36 43.33a16.71 16.71 0 0 1-13 0S7.45 46.34 5.4 61.79c0 0 2.9 4.8 29.44 4.8s29.44-4.8 29.44-4.8c-2.05-15.45-22.92-18.46-22.92-18.46Z'
          fill='#ddd'
        />
        <Path
          fillOpacity={0.1}
          d='M4.47 68.07s8.59 2.42 14.53 2.21v63.07s-8.94.21-14.57-2.11ZM20.13 70.35h2.12v63.01h-2.12z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M53.8 70.66A67.5 67.5 0 0 0 64.1 69a2 2 0 0 1 2.46 1.91v55.65S64.83 87.29 52.55 74a2 2 0 0 1 1.25-3.34Z'
        />
        <Path
          fillOpacity={0.1}
          d='M27.35 62.05s-7-4.9.7-12.75c.42-.42 1.83-5.18-5.18-2.66s-14.29 7-14.57 13c-.28.41-.56 3.92 5.74 4.2s12.75 0 12.75 0 2.13-.18.56-1.79Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M46.21 46.6a1.25 1.25 0 0 0-1.24.68c-.45.92-.7 2.52 1.92 3.73 1.8.48 9.89 2.47 11.89 9.14.1.19 3.24 3 2.38-3-.28-.89-3.3-9.48-14.95-10.55Z'
        />
        <Ellipse
          fill='#fff'
          fillOpacity={0.4}
          cx={53.31}
          cy={53.77}
          rx={3.33}
          ry={3.65}
          transform='rotate(-37.93 53.314 53.77)'
        />
        <Path
          fillOpacity={0.2}
          d='M29.44 44.85s-1.3.53-.65 1.1 2.48 1 5.86 1a36.16 36.16 0 0 0 5.66-.4s2.37-1.1-.81-1.31.23.37.23.37 1.49.29.26.58a47.16 47.16 0 0 1-5.31.41s-4.23.11-5.28-.84c-.05-.09-.4-.1.04-.91Z'
        />
        <Path
          fillOpacity={0.1}
          d='M1.17 132.62s.85 4.88 33.67 4 33.67-4 33.67-4v-.56s-4.57 3.6-33.67 3.85c0 0-29 .82-33.67-3.85Z'
        />
        <Path
          d='M34.84 44.67a20.11 20.11 0 0 1-3.76-.45v2a21.74 21.74 0 0 0 3.6.23H35a21.69 21.69 0 0 0 3.6-.23v-2a20.11 20.11 0 0 1-3.76.45Z'
          fill='#bcbcbc'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M41.36 31.62c-1.09-1.33-6.52-1.09-6.52-1.09s-5.43-.24-6.52 1.09a21.49 21.49 0 0 0 6.52.69 21.41 21.41 0 0 0 6.52-.69Z'
        />
        <Path fillOpacity={0.1} d='M28.32 31.62v11.71a8.59 8.59 0 0 0 1.57.57V32a13.8 13.8 0 0 1-1.57-.38Z' />
        <Path fillOpacity={0.3} fill='url(#linear-gradient)' d='M40.75 36.46a3.55 3.55 0 0 0 0 1.68l36.46 36.43V0Z' />
      </G>
    </G>
  </Svg>
);

export default Spray;
