import React from 'react';

import { View } from 'react-native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';

import { styles } from './styles';

export type VideoViewerProps = { source: string };

export const VideoViewer = ({ source }: VideoViewerProps) => {
  const { isMobile, width } = useDeviceInfoContext();

  return (
    <View style={styles.videoViewerWrapper}>
      <video autoPlay controls style={{ width: isMobile ? width : 800 }} preload='metadata'>
        <source src={source} type='video/mp4' />
        Sorry, your browser does not support embedded videos.
      </video>
    </View>
  );
};

export default VideoViewer;
