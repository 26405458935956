import React from 'react';

import { View } from 'react-native';

import { Button } from 'components/Button';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { DefaultText, PrimaryDefaultText, webStyles } from 'style/common';
import { AnyFunction } from 'utils/types';

import { styles } from './styles';

type ExpiredProps = {
  onRequestClose: AnyFunction;
};

const title = 'This survey has expired';
const description = 'This survey is no longer available to participate in.';
const actionButtonText = 'Close';

export const Expired = ({ onRequestClose }: ExpiredProps) => {
  const { isMobile } = useDeviceInfoContext();

  const maxWidth = isMobile ? 235 : 330;

  return (
    <View style={styles.wrapper}>
      <View>
        <PrimaryDefaultText style={[styles.title, { maxWidth }]}>{title}</PrimaryDefaultText>
        <DefaultText style={[styles.description, { maxWidth }]}>{description}</DefaultText>
      </View>
      <Button
        variant='secondary'
        text={actionButtonText}
        style={[styles.closeButton, webStyles.button]}
        onPress={onRequestClose}
      />
    </View>
  );
};

export default Expired;
