import React from 'react';

import { View, StyleSheet } from 'react-native';

import { DirectionProps, Style } from './types';

export const Col = ({ size, style, children }: DirectionProps) => {
  const flattenedStyle = StyleSheet.flatten(style);
  const viewStyle: Style = [
    {
      flexDirection: 'column',
      flex: size ? size : flattenedStyle?.width ? 0 : 1,
      ...(flattenedStyle?.width && { flexBasis: flattenedStyle.width }),
    },
    style,
  ];

  return <View style={viewStyle}>{children}</View>;
};

export default Col;
