import React, { PropsWithChildren, useState } from 'react';

import { format } from 'date-fns';
import { TouchableOpacity, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { MedicineCabinetInterface } from 'api';
import { DoneCheckMark, UndoneCheckMark } from 'assets/icons';
import { AnimatedWrapper, useSpring } from 'components/Animated/Animated.native';
import { TrackerContentProps } from 'screens/main/MedicationTracker/TrackerContent';
import { NotificationStatusEnum } from 'store/reducers/medicine-cabinet/types';
import { DefaultText } from 'style/common';
import { getTimestampForCurrentDateUTC } from 'utils/dateTimeHelpers';
import { handlePromise } from 'utils/handlePromise';
import { throttleToastHide } from 'utils/helpers';

import { medicationTrackerAnalyticsActions } from '../../constants';
import { medicationTrackerAnalyticsHandler } from '../../helpers';
import { useStyles } from './styles';

const { EDIT_DAILY_MEDICATION_STATUS } = medicationTrackerAnalyticsActions;

type DailyMedicationProps = {
  name: string;
  firstDoseTime: string;
  date: string;
  notificationStatus: NotificationStatusEnum;
  historyId: number;
  getMedTrackerData: TrackerContentProps['getMedTrackerData'];
};

export const DailyMedication = ({
  name,
  firstDoseTime,
  date,
  notificationStatus,
  historyId,
  getMedTrackerData,
}: DailyMedicationProps) => {
  const toast = useToast();

  const isNextDate = new Date(date).getTime() > getTimestampForCurrentDateUTC();
  const formattedTime = firstDoseTime ? format(parseInt(firstDoseTime), 'h:mm a') : '00:00 AM';
  const currentStatusIsSetToSuccessful = notificationStatus === NotificationStatusEnum.ACKNOWLEDGED && !isNextDate;

  const [isLoading, setLoading] = useState(false);

  const showSuccessToast = () => {
    throttleToastHide(toast);
    toast.show('Great job taking your medications!', {
      type: 'tracker_info',
      animationType: 'zoom-in',
    });
  };

  const updateDailyMedicationsInfo = async () => {
    setLoading(true);

    const [data, error] = await handlePromise(() =>
      MedicineCabinetInterface.updateReminderHistory({
        historyIds: [historyId],
        mark: currentStatusIsSetToSuccessful ? NotificationStatusEnum.DISMISSED : NotificationStatusEnum.ACKNOWLEDGED,
      })
    );

    if (error) console.error(error.message);
    if (data) {
      medicationTrackerAnalyticsHandler(EDIT_DAILY_MEDICATION_STATUS);
      if (!currentStatusIsSetToSuccessful) showSuccessToast();

      await getMedTrackerData();
    }

    setLoading(false);
  };

  const styles = useStyles({ isActive: !!historyId, isLoading });

  const animatedOpacity = useSpring({ opacity: isLoading ? 0.5 : 1 });

  const PressWrapper = ({ children }: PropsWithChildren<unknown>) =>
    historyId ? (
      <TouchableOpacity style={styles.checkmarkContainer} disabled={isLoading} onPress={updateDailyMedicationsInfo}>
        {children}
      </TouchableOpacity>
    ) : (
      <View style={styles.checkmarkContainer}>{children}</View>
    );

  const checkmarkJSX = currentStatusIsSetToSuccessful ? <DoneCheckMark /> : <UndoneCheckMark />;

  return (
    <AnimatedWrapper style={animatedOpacity}>
      <View style={styles.wrapper}>
        <View style={styles.textContainer}>
          <DefaultText numberOfLines={1} style={styles.medicationText}>
            {name}
          </DefaultText>
          <DefaultText numberOfLines={1} style={styles.medicationTimeText}>
            {formattedTime}
          </DefaultText>
        </View>
        <PressWrapper>{checkmarkJSX}</PressWrapper>
      </View>
    </AnimatedWrapper>
  );
};

export default DailyMedication;
