import React from 'react';

import { Animated } from 'react-native';
import { Swipeable } from 'react-native-gesture-handler';

import { SwiperRef } from '@types';
import {
  UseDemonstrateSwiperAnimationConfig,
  useDemonstrateSwiperAnimation,
} from 'components/SwipeableWrapper/useDemonstrateSwiperAnimation';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';

export type SwipeableWrapperProps = {
  children: React.ReactNode;
  renderRightActions: (
    progress: Animated.AnimatedInterpolation,
    dragX: Animated.AnimatedInterpolation
  ) => React.ReactNode;

  swiperRef?: SwiperRef;
  demonstrateAnimation?: UseDemonstrateSwiperAnimationConfig;
};
export const SwipeableWrapper = ({
  renderRightActions,
  demonstrateAnimation,
  swiperRef,
  children,
}: SwipeableWrapperProps) => {
  const { isMobile } = useDeviceInfoContext();

  useDemonstrateSwiperAnimation({
    ...demonstrateAnimation,
    swiperRef,
  });

  if (!isMobile) return <>{children}</>;

  return (
    <Swipeable
      ref={swiperRef}
      useNativeAnimations
      containerStyle={{ overflow: 'visible' }}
      rightThreshold={40}
      friction={2}
      renderRightActions={renderRightActions}>
      {children}
    </Swipeable>
  );
};

export default SwipeableWrapper;
