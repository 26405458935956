const USER_SKIPPED_TUTORIAL = 'USER_SKIPPED_TUTORIAL';
const USER_COMPLETED_TUTORIAL = 'USER_COMPLETED_TUTORIAL';

export const tutorialAnalyticsActions = {
  USER_SKIPPED_TUTORIAL,
  USER_COMPLETED_TUTORIAL,
};

export const WEB_STEP_SELECTOR = 'tut';

export const WEB_STEPS_ORDER = {
  CONTACT_CARD: 6,
  DOWNLOAD_CARD: 7,
} as const;

export const IGNORED_STEP_CONTENT = 'IGNORE';
