import React from 'react';

import MangoTextInput from 'components/TextFieldWithLabel/TextInput';

type TextFieldProps = {
  text: string;
  color?: string;
  onChangeText?: (text: string) => void;
};

export const SimpleTextField = ({ onChangeText, text, color }: TextFieldProps) => {
  return <MangoTextInput numberOfLines={1} onChangeText={onChangeText} value={text} style={{ maxWidth: '90%' }} />;
};

export default SimpleTextField;
