import { StyleSheet, ViewStyle } from 'react-native';
import styledWeb from 'styled-components';
import { useTheme } from 'styled-components/native';

import { defaultTheme } from 'style/theme';

export const ampmActiveStyles: ViewStyle = {
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 3,
  },
  shadowOpacity: 0.25,
  shadowRadius: 2.65,
  elevation: 7,
  backgroundColor: '#FFF',
};

export const useTimePickerStyles = () => {
  const { colors, fontFamilies } = useTheme();

  return StyleSheet.create({
    timePickerWrapper: { height: 36, flexDirection: 'row', width: '100%' },
    timeWrapper: {
      alignItems: 'center',
      paddingHorizontal: 12,
      paddingVertical: 4,
      marginRight: 8,
    },
    formItemWrapper: {
      flexDirection: 'row',
      backgroundColor: 'rgba(118, 118, 128, 0.12)',
      borderRadius: 9,
      padding: 3,
    },
    timeSelectStyles: {
      alignItems: 'center',
      paddingHorizontal: 12,
      paddingVertical: 4,
    },
    colonMark: {
      paddingHorizontal: 12,
      color: colors.black,
      fontFamily: fontFamilies.semiBold,
    },
    spacer8: { width: 8 },
    timeText: {
      minWidth: 28,
      textAlign: 'center',
      fontSize: 15,
      fontFamily: fontFamilies.semiBold,
      color: colors.black,
      paddingHorizontal: 3,
    },
  });
};

export const WebPickerContainer = styledWeb('div')`
      select {
        font-size: 16px;
        line-height: 20px;
        font-family: ${defaultTheme.fontFamilies.semiBold};
        color: black;
        background-color: transparent;
        border-width: 0;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-width: 0;
        text-align: center;
        text-indent: 0px;
        option {
          background-color: ${defaultTheme.colors.white};
          color: black;
          font-family: ${defaultTheme.fontFamilies.default};
        }

        ::-webkit-select-placeholder {
          text-align:center;
       }
       
       :-moz-placeholder { /* Firefox 18- */
          text-align:center;
       }
       
       ::-moz-placeholder {  /* Firefox 19+ */
          text-align:center;  
       }
       
       :-ms-select-placeholder {  
          text-align:center; 
       }
      
       :-ms-input-placeholder {  
        text-align:center; 
      }
      
      ::-webkit-input-placeholder {
        text-align:center;
      }
      }
  `;
