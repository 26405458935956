import Constants from 'expo-constants';
import { StyleSheet, ViewStyle } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { Col, Grid } from 'components/Grid';
import { DefaultText, getFontStyles } from 'style/common';
import { MAX_CONTENT_WIDTH } from 'style/sizes';
import { BreakpointTypes } from 'style/types';
import { applyBreakpoints, getStyleByBreakpoint } from 'style/utils';
import { isNativeDevice } from 'utils/helpers';

export const useGetStyles = (imageHeight: number) => {
  const { isMobile, colors, fontFamilies, fontSizes, ...theme } = useTheme();

  const width = theme.width || 0;

  const imageSizes: Record<BreakpointTypes, Pick<ViewStyle, 'width' | 'height'>> = {
    sm: { height: imageHeight },
    md: { height: imageHeight * 1.65, width: width * 1.5 },
    lg: { height: imageHeight * 1.35, width: width * 1.2 },
    xl: { height: imageHeight * 1.2, width: width * 1.1 },
    xxl: { height: imageHeight * 1.05, width },
  };

  return StyleSheet.create({
    rightSide: applyBreakpoints(theme, {
      xl: { width: '100%', marginTop: 32 },
    }),
    leftSide: applyBreakpoints(theme, {
      xl: { width: '100%' },
      xxl: { marginRight: 62 },
    }),
    contentWrapper: {
      flex: 1,
      backgroundColor: colors.white,
      padding: isMobile ? 28 : 30,
      paddingBottom: isNativeDevice ? 0 : 32,
      marginTop: 16,
      borderTopStartRadius: 24,
      borderTopEndRadius: 24,
      ...applyBreakpoints(theme, {
        xl: { flexDirection: 'column' },
        xxl: { flexDirection: 'row' },
      }),
    },
    imageBackground: {
      ...StyleSheet.absoluteFillObject,
      ...applyBreakpoints(theme, imageSizes),
    },
    titleWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: (getStyleByBreakpoint(theme, imageSizes).height as number) + 24,
      paddingLeft: isMobile ? 24 : 0,
    },
    titleText: {
      fontFamily: fontFamilies.bold,
      fontSize: fontSizes[24].fontSize,
      lineHeight: 29,
    },
    plusIconWrapper: {
      padding: 2,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: isMobile ? 30 : 0,
    },
  });
};

export const PageTitle = styled(DefaultText)`
  ${getFontStyles(22)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.gray1};
  align-items: flex-start;
  margin-top: 180px;
  margin-bottom: 6px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-top: 235px;
      margin-bottom: 0;
      text-align: center;
    `}
`;

export const MCContentArea = styled.View`
  background-color: ${(props) => props.theme.colors.gray4};
  padding: 0 32px;
  align-items: center;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding: 0;
    `}
`;

export const MCContentContainer = styled.View`
  max-width: ${MAX_CONTENT_WIDTH}px;
  width: 100%;
  min-height: ${({ theme: { height = 0 } }) => height - (isNativeDevice ? 220 + Constants.statusBarHeight : 240)}px;
`;

export const MCContentWrapper = styled(Grid)`
  background-color: ${(props) => props.theme.colors.white};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 32px;
  flex-direction: row;
  ${({ theme }) =>
    theme.isTablet &&
    css`
      flex-direction: column;
    `}
`;

export const LeftSideContentWrapper = styled(Col)`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 100%;
      margin-right: 0;
    `}
  ${({ theme }) =>
    theme.isTablet &&
    !theme.isMobile &&
    css`
      min-width: 100%;
    `}
`;

export const RightSideContentWrapper = styled.View`
  width: 25%;
  ${({ theme }) =>
    theme.isTablet &&
    css`
      width: 100%;
    `}
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 100%;
      padding: 0;
      justify-content: flex-end;
    `}
`;

export const SafeViewWrapper = styled.SafeAreaView`
  flex: 1;
`;
