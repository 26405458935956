import React from 'react';

import { StyleProp, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import {
  AdditionalServices,
  Benefits,
  FinancialAssistance,
  Home,
  MedTracker,
  Pill as MedicineCabinet,
  Profile,
  RebateCenter,
} from 'assets/icons';
import { MenuItemNotificationIcon } from 'components/LeftSideDrawer/components/MenuItemNotificationIcon';
import { Routes } from 'routes/main';
import { getSelectedProgram, getUserHasPsp } from 'store/selectors';
import { AnyFunction } from 'utils/types';

import { MenuIconWrapper, MenuItemWrapper, MenuText } from './styles';

export type RouteType =
  | Routes.HOME
  | Routes.FINANCIAL_ASSISTANCE
  | Routes.BENEFITS
  | Routes.RESOURCES
  | Routes.REBATE_CENTER
  | Routes.PROFILE
  | Routes.MEDICINE_CABINET
  | Routes.TRACKER;

export type MenuItemProps = {
  menuItemRoute: RouteType;
  active: boolean;
  activeColor?: string;
  iconSize?: number;
  title: string;
  itemWrapperStyle?: StyleProp<ViewStyle>;
  testID: string;
  onPress: AnyFunction;
  hideNotifications?: AnyFunction;
  shouldShowNotificationIcon?: boolean;
};

const iconMapping = {
  [Routes.HOME]: Home,
  [Routes.FINANCIAL_ASSISTANCE]: FinancialAssistance,
  [Routes.BENEFITS]: Benefits,
  [Routes.RESOURCES]: AdditionalServices,
  [Routes.REBATE_CENTER]: RebateCenter,
  [Routes.PROFILE]: Profile,
  [Routes.MEDICINE_CABINET]: MedicineCabinet,
  [Routes.TRACKER]: MedTracker,
};

export const MenuItem = ({
  menuItemRoute,
  active,
  activeColor,
  iconSize,
  title,
  itemWrapperStyle,
  testID,
  onPress,
  hideNotifications,
}: MenuItemProps) => {
  const selectedProgram = useSelector(getSelectedProgram);
  const userHasPsp = useSelector(getUserHasPsp);
  const { colors } = useTheme();

  const IconComponent = iconMapping[menuItemRoute];

  const additionalServicesMenuItem = menuItemRoute === Routes.RESOURCES;

  const currentActiveColor = userHasPsp
    ? selectedProgram?.white_labeling_conf?.primaryColor ||
      selectedProgram?.white_labeling_conf?.primary_color ||
      colors.secondary
    : colors.mangoSecondary;
  const iconAndTextColor = active ? activeColor || currentActiveColor : colors.gray2;

  return (
    <MenuItemWrapper
      onPress={() => {
        additionalServicesMenuItem && hideNotifications?.();
        onPress();
      }}
      style={itemWrapperStyle}
      focusable={true}
      accessibilityRole='menuitem'
      testID={testID}>
      <MenuIconWrapper>
        {additionalServicesMenuItem && <MenuItemNotificationIcon />}
        <IconComponent width={iconSize || 16} height={iconSize || 16} stroke={iconAndTextColor} />
      </MenuIconWrapper>
      <MenuText numberOfLines={1} activeColor={iconAndTextColor}>
        {title}
      </MenuText>
    </MenuItemWrapper>
  );
};

export default MenuItem;
