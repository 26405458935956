import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { MOBILE_HORIZONTAL_PADDING } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';

export const useSupportStyles = () => {
  const { colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: {
      position: 'relative',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: 24,
      ...applyBreakpoints(theme, {
        sm: { width: (theme.width || 0) - MOBILE_HORIZONTAL_PADDING * 2 - 64 }, // device width - horizontal paddings - modal paddings
        xxl: { minWidth: 312, maxWidth: 364 },
      }),
    },
    title: {
      ...fontSizes[16],
      color: colors.primary,
      fontFamily: fontFamilies.bold,
    },
    description: {
      ...fontSizes[16],
      marginTop: 8,
      color: colors.gray1,
    },
    number: {
      ...fontSizes[16],
      fontFamily: fontFamilies.semiBold,
      marginTop: 16,
      color: colors.gray1,
    },
    submitButton: {
      width: 'auto',
      alignSelf: 'flex-start',
      marginTop: 28,
    },
  });
};
