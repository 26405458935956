import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useQuestionnaireStyles = () => {
  const { colors, fontSizes, fontFamilies, isMobile } = useTheme();

  return StyleSheet.create({
    wrapper: {
      paddingHorizontal: isMobile ? 8 : 32,
      paddingBottom: isMobile ? 12 : 32,
      maxWidth: 428,
    },
    firstTextBlock: {
      fontFamily: fontFamilies.bold,
      ...fontSizes[24],
    },
    secondTextBlock: {
      marginTop: 30,
      ...fontSizes[16],
      color: colors.gray1,
    },
    thirdTextBlock: {
      marginTop: 30,
      marginBottom: 10,
      ...fontSizes[16],
      color: colors.gray1,
    },
    checkboxWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      marginTop: 20,
    },
    checkboxText: {
      paddingLeft: 8,
      fontSize: 14,
    },
    submitButtonWrapper: {
      marginTop: 40,
      flexDirection: 'row',
    },
    nextButton: { marginLeft: 16 },
    mTop20: { marginTop: 20 },
  });
};
