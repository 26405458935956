import * as ActionTypes from 'store/action-types';
import type { AvailableInsuranceTypeEnum } from 'store/reducers/user-info/types/insurance';

export type AddUserInsurance = (payload: AvailableInsuranceTypeEnum) => {
  type: typeof ActionTypes.ADD_USER_INSURANCE;
  payload: typeof payload;
};

export const addUserInsurance: AddUserInsurance = (payload) => ({
  type: ActionTypes.ADD_USER_INSURANCE,
  payload,
});
