import { SetPrograms } from 'store/actions/user/actionCreators/programs/setPrograms';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';
import { PSPProgram, ProgramConfigItem } from 'store/reducers/user-info/types/program';

const toProgramConfigItemMapper = (item?: ProgramConfigItem): Required<ProgramConfigItem> => ({
  page: item?.page ?? true,
});

const toProgramConfigMapper = (config: PSPProgram['programConfig']): Required<PSPProgram['programConfig']> => ({
  benefits: toProgramConfigItemMapper(config?.benefits),
  rebateCenter: toProgramConfigItemMapper(config?.rebateCenter),
});

const setPrograms = (state = INITIAL_STATE, payload: ReturnType<SetPrograms>['payload']): User => {
  return {
    ...state,
    programs: payload.map((program) => ({
      ...program,
      programConfig: toProgramConfigMapper(program.programConfig),
    })),
  };
};

export default setPrograms;
