import axios, { AxiosRequestConfig } from 'axios';

import {
  CODE_CHECK_URL,
  CONFIRM_PASSWORD_URL,
  FORGET_PASSWORD_URL,
  LOGIN_URL,
  RESEND_ACTIVATE_EMAIL,
  TOKEN_CHECK_URL,
} from 'api/UserInterface';
import { reduxStore } from 'store';
import { setSessionExpiredModalVisible } from 'store/actions';

import { StatusCode } from '../enums';
import { fiveHundredsErrors } from '../enums/statusCode';
import { BAD_INTERNET_CONNECTION_ERROR_MESSAGE, TRY_AGAIN_ERROR_MESSAGE } from './constants';

const axiosConfig: AxiosRequestConfig = {
  baseURL: '',
  timeout: 30000,
};

const noExpirationUrls = [
  RESEND_ACTIVATE_EMAIL,
  LOGIN_URL,
  FORGET_PASSWORD_URL,
  TOKEN_CHECK_URL,
  CONFIRM_PASSWORD_URL,
  CODE_CHECK_URL,
];

const axiosClient = axios.create(axiosConfig);

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === StatusCode.ClientErrorUnauthorized && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!noExpirationUrls.includes(originalRequest?.url)) reduxStore.dispatch(setSessionExpiredModalVisible(true));

      return axiosClient(originalRequest);
    }

    if (fiveHundredsErrors.some((err) => err === error?.response?.status))
      return Promise.reject(new Error(TRY_AGAIN_ERROR_MESSAGE));

    if (error?.response?.status === 408) return Promise.reject(new Error(BAD_INTERNET_CONNECTION_ERROR_MESSAGE));

    return Promise.reject(error);
  }
);

export default axiosClient;
