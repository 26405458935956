import React from 'react';

import { DashDayMark, RedDayMark, GrayDayMark, GreenDayMark } from 'assets/icons';
import { AdherenceStatusEnum } from 'store/reducers/medicine-cabinet/types';

export const dayMarks: Record<AdherenceStatusEnum, JSX.Element> = {
  [AdherenceStatusEnum.UNKNOWN]: <DashDayMark />,
  [AdherenceStatusEnum.FAIL]: <RedDayMark />,
  [AdherenceStatusEnum.INCOMING]: <GrayDayMark />,
  [AdherenceStatusEnum.SUCCESSFUL]: <GreenDayMark />,
};
