import { addMinutes, differenceInDays, format, isToday, subMinutes } from 'date-fns';
import * as Localization from 'expo-localization';

import { MERIDIEM_FORMAT_TYPE } from 'components/TimePicker/constants';

import { isIOSDevice } from './helpers';

export const meridiemFormat = (date: Date) => {
  if (!date) return null;
  return format(date, MERIDIEM_FORMAT_TYPE);
};

// Timestamp of the current date (without hours and minutes)
export const getTimestampForCurrentDateUTC = () => {
  const d = new Date();

  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
};

export const getTimestampWithoutHours = (date: number) => {
  const d = new Date(date);

  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
};

/**
 * @param {date} date Selected date
 * @returns {number} Timestamp (year + month + date + hours + minutes)
 */
export const getTimestampForSelectedDateUTC = (date: string) => {
  if (!date) return null;
  if (typeof date === 'string') {
    const parsedTimestamp = parseInt(date, 10);
    return Date.UTC(
      new Date(parsedTimestamp).getFullYear(),
      new Date(parsedTimestamp).getMonth(),
      new Date(parsedTimestamp).getDate(),
      new Date(parsedTimestamp).getHours(),
      new Date(parsedTimestamp).getMinutes()
    );
  }
  throw new Error('Invalid type of the param');
};

export const extractTimeFromDate = (date: Date) => format(date, 'HH:mm');

// time format :   --> '12:15:00'
export const replaceTimeInDate = (time: string, date: Date = new Date()) => {
  const [hours, minutes, seconds] = time?.split(':') || [];
  hours && date.setHours(Number(hours));
  minutes && date.setMinutes(Number(minutes));
  seconds && date.setSeconds(Number(seconds));
  return date;
};

export const getTimeZoneInfo = () => ({
  timeZoneFromIntl: Localization.timezone,
  offSet: `${-(new Date().getTimezoneOffset() / 60)}`,
});

export const getIsPastDate = (a: number, b: number) => differenceInDays(a, b) < 0;

export const formatTimezone = (date: Date): Date => {
  const offset = new Date().getTimezoneOffset();

  return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset));
};

export const isISOToday = (date: number | Date) => isToday(formatTimezone(new Date(date)));

export const currentDateRoundingDownward = (seconds: number) =>
  new Date(Math.floor(new Date().getTime() / (seconds * 1000)) * (seconds * 1000));

export const convertUTCToLocalDate = (date?: Date | null | string) => {
  if (!date) return date;

  const d = new Date(date);

  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
};

export const convertLocalToUTCDate = (date?: Date | null | string) => {
  if (!date) return date;

  const d = new Date(date);

  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
};

export const toCorrectDatePickerFormat = (dateValue: Date) =>
  isIOSDevice ? dateValue : convertUTCToLocalDate(dateValue);
