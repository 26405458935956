import { RemoveFromConsentModalsQueue } from 'store/actions/user/actionCreators/consents/removeFromConsentModalsQueue';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const removeFromConsentModalsQueue = (
  state = INITIAL_STATE,
  payload: ReturnType<RemoveFromConsentModalsQueue>['payload']
): User => {
  return {
    ...state,
    consentModalsQueue: state.consentModalsQueue.filter((consentModal) => {
      const consentModalToBeRemoved = (() => {
        if (payload.customServiceType) {
          return (
            consentModal.id === payload.id &&
            consentModal.groupType === payload.groupType &&
            consentModal.customServiceType === payload.customServiceType
          );
        }
        return consentModal.id === payload.id && consentModal.groupType === payload.groupType;
      })();

      if (!consentModalToBeRemoved) {
        return true;
      }
      return false;
    }),
  };
};

export default removeFromConsentModalsQueue;
