export const policyInfo = `
  <p>
    <strong>PRIVACY POLICY</strong>
  </p>
  <p>
  PatientLink by TrialCard™ (“PatientLink”) provides patient support, websites, mobile applications, and other online or offline offerings that connect patients with the services provided by manufacturer supported patient support programs and that promote wellness and well-being (collectively, the “Services”).  By using the Services that PatientLink and its affiliates (collectively, “we,” “us,” or “our”) provide, you as a user of the services are acknowledging and agreeing to the terms of this Privacy Policy, which explains how we collect, use and share your personal information and what privacy rights are available to you. If you do not consent to the terms of the Privacy Policy, please do not access or use the Services.
  </p>
  <p>
  We operate in accordance with applicable data protection legislation and regulations. We recognize the importance of protecting the privacy of Personal Information collected about our users. This Privacy Policy discloses what Personal Information we gather, how we use it, and how you can correct or change it. It is our intention to give you as much control over your Personal Information as possible to preserve your privacy, while still allowing us and the third parties with whom we contract to utilize that Personal Information in the course of our business to provide the Services. We reserve the right, at our discretion, to change this Privacy Policy at any time, which change shall become effective upon posting on the Website, the Mobile App, via the Service or sending you an email or other notification. You will be deemed to have agreed to such changes by your decision to continue using any of the Services following the date in which such changes become effective.
  </p>
  <br/>
  <p><strong>I. REGISTRATION</strong></p>
  <p>
  If you access PatientLink in connection with a program offered by a pharmaceutical manufacturer or Healthcare Provider you may be required to register and provide certain Personal Information to such provider. We are not responsible for the use of any Personal Information that you may decide to provide to any, pharmaceutical manufacturer, or Healthcare Provider in connection with the use of the Services.
  </p>
  <br/>
  <p>
    <strong>II. PERSONAL INFORMATION WE COLLECT</strong>
  </p>
  <p>
  &ldquo;Personal Information&rdquo; means any information that may be used, either alone or in combination with other information, to personally identify an individual, including, but not limited to, a first and last name, a personal profile, an email address or other contact information. The categories of Personal Information we collect depend on the Services and whether you are a customer, user, applicant or visitor, all subject to applicable law.
  </p>
  <p>
  Information You Provide to Us.<br>
  We may collect and process certain Personal Information such as your name, telephone number, e-mail address, mailing address, telephone number, user name, and password. We use this Personal Information to fulfill your request for use of certain features of the Services, or to conduct surveys. We will collect only as much Personal Information as we need for the purposes specified in this Privacy Policy, for purposes specified at the time of collection of the Personal Information, or for other purposes that you consent to in the future.
  </p>
  <p>
    <strong>Health Information</strong>. We also collect certain health information (including medications or health status).
  </p>
  <p>
    <strong>Demographic &amp; Non-Identifying Information</strong>. We may collect and process your demographic information from time to time, such as information about your gender, age, country, and zip code (&ldquo;<strong>Demographic Information</strong>&ldquo;). To the extent that we combine any non-personally identifiable Demographic Information with your Personal Information that we collect directly from you, we will treat the combined data as Personal Information under this Privacy Policy.
  </p>
  <p>
    We also collect data about you, including health indicators, and related information that does not identify you and that enables you to fully participate in the Service.
  </p>
  <p>
    <strong>Social Media Content</strong>. We may offer forums, blogs, or social media pages. Any content you provide on these channels will be considered &ldquo;public&rdquo; and is not subject to privacy protections.
  </p>
  <p>
  <strong>Information Collected Automatically or From Others</strong><br>
  In addition to any Personal Information or other information that you choose to provide, we or our third-party service providers may use a variety of technologies that automatically (or passively) store or collect certain information whenever you visit or interact with the Website (&ldquo;<strong>Usage Information</strong>&ldquo;) This Usage Information may be stored or accessed using a variety of technologies that may be downloaded to User’s personal computer, browser, laptop, tablet, mobile phone or other device (a &ldquo;<strong>Device</strong>&ldquo;)whenever you visit or interact with the PatientLink Website and/or the Mobile App. To the extent we associate Usage Information with your Personal Information, we will treat it as Personal Information.
  </p>
  <p>This Usage Information may include:</p>
  <ul>
    <li>
      Your IP address, UDID or other unique identifier (&ldquo;<strong>Device Identifier</strong>&ldquo;). A Device Identifier is a number that is automatically assigned to your Device used to access the Website, and our computers identify your Device by its Device Identifier;
    </li>
    <li>
      Your Device functionality (including browser, operating system, hardware, mobile network information);
    </li>
    <li>the URL that referred you to our Website;</li>
    <li>
      the areas within our Website and/or Mobile App that you visits and your activities there, including remembering you, your preferences and pages you requested and/or viewed;
    </li>
    <li>Your Device location;</li>
    <li>Your Device characteristics; and</li>
    <li>certain other Device data, including the time of day, among other information.</li>
  </ul>
  <p>
    We may use various methods and technologies to store or collect Usage Information (&ldquo;<strong>Tracking Technologies</strong>&ldquo;). Tracking Technologies may set, change, alter or modify settings or configurations on your Device. The Tracking Technologies that may be used are the following (and subsequent technology and methods later developed which perform a similar function):
  </p>
  <ul>
    <li>
      <strong>Cookies</strong>. Cookies are small text files placed in visitors&rsquo; computer browsers to store their preferences. Most browsers allow you to block and delete cookies. However, if you do that, the Services may not work properly.
    </li>
    <li>
      <strong>Pixel Tags/Web Beacons</strong>. A pixel tag (also known as a web beacon) is a piece of code embedded in the Services that collects information about users&rsquo; engagement on that web page. The use of a pixel allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement.
    </li>
    <li>
      <strong>Analytics</strong>. We may also use Google Analytics and other service providers to collect information regarding visitor behavior and visitor demographics on our Services. For more information about Google Analytics, please visit&nbsp;<a href="https://www.google.com/policies/privacy/partners">https://www.google.com/policies/privacy/partners</a>. You can opt out of Google&rsquo;s collection and processing of data generated by your use of the Services by going to <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
    </li>
    <li>
      <strong>Information from Other Sources</strong>. We may obtain information about you from other sources, including through third-party services and organizations to supplement information provided by you.
    </li>
  </ul>
  <p>
  If you access or register for PatientLink in connection with a sponsored program, your benefit or healthcare provider may provide us with Personal Information about you, such as your medication list and refill reminders for us to pass on to you.
  </p>
  <p>
    In addition, if you access our Services through a third-party application, such as an app store, a third-party login service, or a social networking site, we may collect information about you from that third-party application that you have made public via your privacy settings. Information we collect through these services may include your name, your user identification number, your user name, location, gender, birth date, email, profile picture, and your contacts stored in that service. This supplemental information allows us to verify information that you have provided to us and to enhance our ability to provide you with information about our business, products, and Services.
  </p>
  <br/>
  <p><strong>III. HOW WE USE YOUR INFORMATION</strong></p>
  <p>
    Except as set forth in this Privacy Policy or as specifically agreed to by you, we will not disclose any information we gather from you as part of the Services. Except as set forth in this Privacy Policy, we do not share your Personal Information with third parties for those third parties&rsquo; direct marketing purposes. We may provide to third parties information about you that does not allow you to be identified or contacted, including where such information is combined with similar information of other users of our Services. For example, we might inform third parties regarding the number of unique users who visit and/or use our Services, the demographic breakdown of the registered users of the Services, or the activities that users engage in while on our Website and/or Mobile App.
  </p>
  <p>We use your information for a variety of business purposes, including to:</p>
  <p><strong>Provide you with our Services, such as:</strong></p>
  <ul>
    <li>Managing your information and accounts;</li>
    <li>
      Providing access to certain areas, functionalities, and features of our Services, and, in our discretion, changes to the Services and/or the service policies;
    </li>
    <li>
      To tailor the content and information that we may send or display to you, to offer location customization, and personalized help and instructions, and to otherwise personalize your experiences while using the Services;
    </li>
    <li>Communicating with you about your account activities ;</li>
    <li>Informing you of new Services features, or information we think will be of interest to you;</li>
    <li>
      Providing you with marketing materials or relevant advertising, promotions and recommendations from PatientLink or our business partners;
    </li>
    <li>Undertaking activities to verify or maintain the quality or safety of a service;</li>
    <li>Providing advertising, analytics and marketing services;</li>
    <li>
      Providing Services on behalf of our pharmaceutical customers, such as maintaining or servicing accounts, providing customer service, and verifying information;
    </li>
    <li>Processing applications and transactions; and</li>
<!--    -->
    <li>Allowing you to register for events.</li>
  </ul>
  <br/>
  <p><strong>Analyze and improve our Services pursuant to our legitimate interest, such as:</strong></p>
  <ul>
    <li>For internal business purposes;</li>
    <li>
      Detecting security incidents, protecting against malicious, deceptive, fraudulent or illegal activity, and prosecuting those responsible for that activity;
    </li>
    <li>Measuring interest and engagement in our Services;</li>
    <li>Undertaking research for technological development and demonstration;</li>
    <li>
      Researching and developing products, services, marketing or security procedures to improve performance, resilience, reliability or efficiency;
    </li>
    <li>Improving, upgrading or enhancing our Services;</li>
    <li>Developing new products and Services;</li>
    <li>Ensuring internal quality control;</li>
    <li>Verifying your identity and preventing fraud;</li>
    <li>Debugging to identify and repair errors that impair existing intended functionality;</li>
    <li>Enforcing our terms and policies; and</li>
    <li>
      Complying with our legal obligations, protecting your vital interest, or as may be required for the public good
    </li>
  </ul>
  <br/>
  <p><strong>Provide you with additional content and Services</strong>, such as:</p>
  <ul>
    <li>
      Furnishing you with customized materials about offers, products, and Services that may be of interest, including new content or Services;
    </li>
    <li>Auditing relating to interactions, transactions and other compliance activities; and</li>
    <li>
      Other purposes you consent to, are notified of, or are disclosed when you provide personal information.
    </li>
  </ul>
  <p>
    <strong>Use De-identified and Aggregated Information</strong>. We may use Personal Information and other data about you to create de-identified and aggregated information, such as de-identified demographic information, de-identified location information, information about the computer or device from which you access our Services, or other analyses we create.
  </p>
  <p>
    <strong>Share Content with Friends or Colleagues</strong>. Our Services may offer various tools and functionalities. For example, we may allow you to provide information about your friends through our referral services. Our referral services may allow you to forward or share certain content with a friend or colleague, such as an email inviting your friend to use our Services.
  </p>
  <p>
    <strong>How We Use Automatic Collection Technologies</strong>. We, as well as third parties that provide content, advertising, or other functionality on the Services, may use cookies, pixel tags, local storage, and other technologies to automatically collect information through the Services. Our uses of these Technologies fall into the following general categories:
  </p>
  <ul>
    <li>
      <strong>Operationally Necessary</strong>. This includes Technologies that allow you access to our Services, applications, and tools that are required to identify irregular site behavior, prevent fraudulent activity and improve security or that allow you to make use of our functionality;
    </li>
    <li>
      <strong>Performance Related</strong>. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how our visitors use the Services;
    </li>
    <li>
      <strong>Functionality Related</strong>. We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed;
    </li>
    <li>
      <strong>Advertising or Targeting Related</strong>. We may use first party or third-party Technologies to deliver content, including ads relevant to your interests, on our Services or on third party sites.
    </li>
  </ul>
  <p>
    <strong>Cross-Device Tracking</strong>. Your browsing activity may be tracked across different websites and different devices or apps. For example, we may attempt to match your browsing activity on your mobile device with your browsing activity on your laptop. To do this our technology partners may share data, such as your browsing patterns, geo-location and device identifiers, and will match the information of the browser and devices that appear to be used by the same person.
  </p>
  <p>
    <strong>Notice Regarding Third-Party Websites, Social Media Platforms and Software Development Kits</strong>. The Services may contain links to other websites, and other websites may reference or link to our website or other Services. These other websites are not controlled by us. We encourage our users to read the privacy policies of each website and application with which they interact. We do not endorse, screen or approve and are not responsible for the privacy practices or content of such other websites or applications. Visiting these other websites or applications is at your own risk.
  </p>
  <p>
    Our Services may include publicly accessible blogs, forums, social media pages, and private messaging features. By using such Services, you assume the risk that the personal information provided by you may be viewed and used by third parties for any number of purposes. In addition, social media buttons such as Facebook, LinkedIn and Instagram (that might include widgets such as the &ldquo;share this&rdquo; button or other interactive mini-programs) may be on our site. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. These social media features are either hosted by a third party or hosted directly on our site. Your interactions with these features apart from your visit to our site are governed by the privacy policy of the company providing it.
  </p>
  <p>
    We may use third party APIs and software development kits (&ldquo;SDKs&rdquo;) as part of the functionality of our Services. APIs and SDKs may allow third parties including analytics and advertising partners to collect your personal information for various purposes including to provide analytics services and content that is more relevant to you. For more information about our use of APIs and SDKs, please contact us as set forth below.
  </p>
  <br />
  <p><strong>IV. DISCLOSING YOUR INFORMATION TO THIRD PARTIES</strong></p>
  <p>Except as provided below, we do not share or sell personal information.</p>
  <p>For a list of the categories of personal information we have disclosed about consumers for a business purpose in the past 12 months, please <a target="_blank" href="https://www.mangohealth.com/privacy-addendum.html">click here</a>. For a list of categories of personal information we have sold about consumers for a business purpose in the past 12 months for valuable consideration, please <a target="_blank" href="https://www.mangohealth.com/privacy-addendum.html">click here.<a/></p>
  <p>Benefit/Healthcare Provider Activation Codes. If you access or register for PatientLink in connection with a sponsored program, we may provide Personal Information about you to the third party, including data related to your use of, and interaction with, the Services. The third party may be able to associate the Personal Information we provide with you</p>
  <p>
    Service Providers. We may share any Personal Information we collect about you with our third- party service providers. The categories of service providers to whom we entrust Personal Information include: IT and related services; information and services; payment processors; customer service providers; and vendors to support the provision of the Services.
  </p>
  <p>
    Business Partners. We may provide Personal Information to business partners with whom we jointly offer products or services. In such cases, our business partner&rsquo;s name will appear along with ours.
  </p>
  <p>
  Affiliates. We may share Personal Information with our affiliated companies for their use in developing associated products and services or for analytics associated with the services they provide
  </p>
  <p>
    Advertising Partners. Through our Services, we may allow third party advertising partners to set Technologies and other tracking tools to collect information regarding your activities and your device (e.g., your IP address, mobile identifiers, page(s) visited, location, time of day). We may also combine and share such information and other information (such as demographic information and past purchase history) with third party advertising partners. These advertising partners may use this information (and similar information collected from other websites) for purposes of delivering targeted advertisements to you when you visit third party websites within their networks. This practice is commonly referred to as &ldquo;interest-based advertising&rdquo; or &ldquo;online behavioral advertising. We may allow access to other data collected by the Services to share information that may be useful, relevant, valuable or otherwise of interest to you. If you prefer not to share your personal information with third party advertising partners, you may follow the instructions below.
  </p>
  <p>
    Disclosures to Protect Us or Others. We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our or others&rsquo; rights, property, or safety; enforce our policies or contracts; collect amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity. Additionally, we may share your information, including Personal Information, with pharmaceutical companies for the purpose of meeting applicable adverse event reporting obligations.
  </p>
  <p>
    Disclosure in the Event of Merger, Sale, or Other Asset Transfers. If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership, purchase or sale of assets, or transition of service to another provider, then your Personal Information may be sold or transferred as part of such a transaction, as permitted by law and/or contract.
  </p>
  <p>
    You agree that all Personal Information processed by us may be transferred, processed, and stored anywhere in the world, including but not limited to, the United States or other countries, which may have data protection laws that are different from the laws where you live. We have taken appropriate safeguards to require that your personal information will remain protected and require our third-party service providers and partners to have appropriate safeguards as well. Further details can be provided upon request.
  </p>
  <br/>
  <p>
   <strong>V. YOUR CHOICES</strong>
  </p>
  <p>General</p>
  <p>
  You have certain choices about your personal information. Where you have consented to the processing of your Personal Information, you may withdraw that consent at any time and prevent further processing by contacting us as described below. Even if you opt out, we may still collect and use non- Personal Information regarding your earlier activities on our Services and for other legal purposes as described above.
  </p>
  <p>Email and Telephone Communications</p>
  <p>
    If you receive an unwanted email from us, you can use the unsubscribe link found at the bottom of the email to opt out of receiving future emails. Note that you will continue to receive transaction-related emails regarding products or Services you have requested. We may also send you certain non-promotional communications regarding us and our Services, and you will not be able to opt out of those communications (e.g., communications regarding the Services or updates to our Terms or this Privacy Policy).
  </p>
  <p>
    We process requests to be placed on do-not-mail, do-not-phone and do-not-contact lists as required by applicable law.
  </p>
  <p>Mobile Devices</p>
  <p>
    We may send you push notifications through our mobile application. You may at any time opt- out from receiving these types of communications by changing the settings on your mobile device. We may also collect location-based information if you use our mobile applications. You may opt-out of this collection by changing the settings on your mobile device.
  </p>
  <p>&ldquo;Do Not Track&rdquo;</p>
  <p>
    Do Not Track (&ldquo;DNT&rdquo;) is a privacy preference that users can set in certain web browsers. Please note that we do not respond to or honor DNT signals or similar mechanisms transmitted by web browsers.
  </p>
  <p>Cookies and Interest-Based Advertising</p>
  <p>
    You may stop or restrict the placement of Technologies on your device or remove them by adjusting your preferences as your browser or device permits. The online advertising industry also provides websites from which you may opt out of receiving targeted ads from data partners and other advertising partners that participate in self-regulatory programs. You can access these and learn more about targeted advertising and consumer choice and privacy, at <a href="https://www.networkadvertising.org/managing/opt_out.asp">https://www.networkadvertising.org/managing/opt_out.asp</a>, <a href="https://www.youronlinechoices.eu/">https://www.youronlinechoices.eu/</a>, <a href="https://youradchoices.ca/choices/">https://youradchoices.ca/choices/</a>, and <a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a>. To separately make choices for mobile apps on a mobile device, you can download DAA&rsquo;s AppChoices application from your device&rsquo;s app store. Alternatively, for some devices you may use your device&rsquo;s platform controls in your settings to exercise choice.
  </p>
  <p>
    Please note you must separately opt out in each browser and on each device. Advertisements on third party websites that contain the AdChoices link may have been directed to you based on information collected by advertising partners over time and across websites. These advertisements provide a mechanism to opt out of the advertising partners&rsquo; use of this information for interest-based advertising purposes.
  </p>
  <p>Your Privacy Rights</p>
  <p>In accordance with applicable law, you may have the right to:</p>
  <ul>
    <li>
      <strong>Access to/Portability of Personal Information</strong> about you consistent with legal requirements. In addition, you may have the right in some cases to receive or have your electronic Personal Information transferred to another party.
    </li>
    <li>
      <strong>Request Correction of your Personal Information</strong> where it is inaccurate or incomplete. In some cases, we may provide self-service tools that enable you to update your Personal Information or we may refer you to the controller of your Personal Information who is able to make the correction.
    </li>
    <li>
      <strong>Request Deletion of your Personal Information</strong>, subject to certain exceptions prescribed by law.
    </li>
    <li>
      <strong>Request restriction of or object to processing of your Personal Information</strong>, including the right to
      opt in or opt out of the sale of your Personal Information to third parties, if applicable, where such requests are
      permitted by law.
    </li>
  </ul>
  <p>
  If you would like to exercise any of these rights, please send an email to: <a href="mailto:support@patientlinkbytc.com">support@patientlinkbytc.com</a>  We will process such requests in accordance with applicable laws. To protect your privacy, we will take steps to verify your identity before fulfilling your request.
  </p>
  <br/>
  <p><strong>VI. DATA RETENTION</strong></p>
  <p>
    We store the Personal Information we receive as described in this Privacy Policy for as long as you use our Services or as necessary to fulfill the purpose(s) for which it was collected, provide our Services, resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable laws.
  </p>
  <br/>
  <p><strong>VII. SECURITY OF YOUR INFORMATION</strong></p>
  <p>
    We take steps to ensure that your Personal Information is treated securely and in accordance with this Privacy Policy. Unfortunately, no system is 100% secure, and we cannot ensure or warrant the security of any information you provide to us. To the fullest extent permitted by applicable law, we do not accept liability for unintentional disclosure.
  </p>
  <p>
    By using the Services or providing Personal Information to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the Services. If we learn of a security system&rsquo;s breach, we may attempt to notify you electronically by posting a notice on the Services, by mail or by sending an e-mail to you.
  </p>
  <br/>
  <p><strong>VIII. CHILDREN&rsquo;S INFORMATION</strong></p>
  <p>
    The Services are not directed to children under 13 (or other age as required by local law), and we do not knowingly collect Personal Information from children. If you learn that your child has provided us with Personal Information without your consent, you may contact us as set forth below. If we learn that we have collected any Personal Information in violation of applicable law, we will promptly take steps to delete such information and terminate the child&rsquo;s account.
  </p>
  <br/>
  <p><strong>IX. OTHER PROVISIONS</strong></p>
  <p>
    Supervisory Authority<br>
    This Service is designed for residents of the United States and Canada only.  If you are located in the European Economic Area or the UK, you have the right to lodge a complaint with a supervisory authority if you believe our processing of your personal information violates applicable law.
  </p>
  <br/>
  <p><strong>X. DO NOT SELL</strong></p>
  <p>
  If you would like to request that TrialCard does not sell your personal information to any third parties, please submit a request to our support team by following the instructions below. Please note that making the request may cause you to lose access to functionality in the app that is specific to one or more third parties with whom TrialCard and PatientLink by TrialCard has an ongoing business relationship.
  </p>
  <br/>
  <p><strong>XI. CONTACT US</strong></p>
  <p>
    If you have any questions about our privacy practices or this Privacy Policy, or if you wish to submit a request to exercise your rights as detailed in this Privacy Policy, please contact us at: <br/><a href="mailto:support@patientlinkbytc.com">support@patientlinkbytc.com</a>
  </p>
`;
