import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';

const DAILY_MEDICATION_SEPARATOR_HEIGHT = 8;
export const DAILY_MEDICATION_HEIGHT = 92 + DAILY_MEDICATION_SEPARATOR_HEIGHT;

export const useStyles = () => {
  const theme = useTheme();

  return StyleSheet.create({
    medicationsListWrapper: {
      marginHorizontal: -32,
      paddingTop: 24,
      ...applyBreakpoints(theme, {
        md: {},
        xxl: {
          maxHeight: DAILY_MEDICATION_HEIGHT * 4,
        },
      }),
    },
    showMoreButton: {
      marginTop: 20,
      paddingHorizontal: 32,
      ...applyBreakpoints(theme, {
        md: { display: 'flex' },
        xxl: { display: 'none' },
      }),
    },
    verticalSpace8: { height: DAILY_MEDICATION_SEPARATOR_HEIGHT },
  });
};
