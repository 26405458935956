import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { View } from 'react-native';
import { Rect, Circle } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { screenContentWidth } from 'style/utils';

const ReminderPlaceHolder = () => {
  const { isMobile, ...theme } = useTheme();
  const loaderStyle = { backgroundColor: theme.colors.gray4, borderRadius: 16, marginBottom: 20 };

  return (
    <ContentLoader
      backgroundColor={theme.colors.gray3}
      style={loaderStyle}
      width={screenContentWidth}
      height={isMobile ? 73.5 : 112}>
      {isMobile ? (
        <>
          <Rect x='32' y='27' rx='3' ry='3' width='35%' height='16' />
          <Rect x='60%' y='27' rx='3' ry='3' width='35%' height='16' />
        </>
      ) : (
        <>
          <Rect x='32' y='32' rx='3' ry='3' width='35%' height='16' />
          <Circle cx='88%' cy='40' r='16' />
          <Circle cx='95%' cy='40' r='16' />
          <Rect x='32' y='63' rx='3' ry='3' width='35%' height='16' />{' '}
        </>
      )}
    </ContentLoader>
  );
};

export const ReminderListPlaceHolder = () => {
  return (
    <View style={{ maxWidth: '100%' }}>
      {new Array(3).fill(null).map((_, index: number) => (
        <View key={index}>
          <ReminderPlaceHolder />
        </View>
      ))}
    </View>
  );
};

export default ReminderListPlaceHolder;
