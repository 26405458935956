import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const Phone = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M15 11.482v2.107a1.403 1.403 0 01-.957 1.337 1.41 1.41 0 01-.578.068 13.95 13.95 0 01-6.075-2.156 13.714 13.714 0 01-4.223-4.216 13.885 13.885 0 01-2.161-6.09 1.403 1.403 0 01.833-1.412A1.41 1.41 0 012.407 1h2.111a1.41 1.41 0 011.408 1.208c.09.675.255 1.337.493 1.974a1.402 1.402 0 01-.317 1.483l-.894.892a11.251 11.251 0 004.224 4.215l.894-.892a1.409 1.409 0 011.485-.316 9.056 9.056 0 001.978.492 1.408 1.408 0 011.21 1.426z'
      stroke={props.stroke || '#000'}
      strokeWidth={props.strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Phone;
