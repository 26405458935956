import React from 'react';

import { format } from 'date-fns';
import { StyleSheet, View } from 'react-native';

import { Card, Spacer } from 'components';
import { useIndicatorCardStyles } from 'screens/main/Home/components/FinancialAssistanceOverview/CopayOverviewIndicators/styles';
import { DefaultText, StandardText, SubTitle } from 'style/common';

import { UsedIndicatorCardProps } from './types';

export const UsedIndicatorCard = ({
  title,
  prefix = '',
  updatedDate,
  usedValue = 0,
  maxAvailableValue,
  cardWrapperStyles,
  onPress = () => null,
}: UsedIndicatorCardProps) => {
  const styles = useIndicatorCardStyles();

  const formattedDate = updatedDate && format(new Date(updatedDate), 'MMMM d, Y');

  return (
    <Card isTouchable pressHandler={onPress} style={StyleSheet.flatten(cardWrapperStyles)}>
      <SubTitle>{title}</SubTitle>
      <Spacer size={12} />
      <StandardText>
        Updated: <StandardText style={styles.dateText}>{formattedDate}</StandardText>
      </StandardText>
      <Spacer size={12} />
      <View>
        <DefaultText style={styles.usedValueText}>{`${prefix}${usedValue || 0}`}</DefaultText>
        {/* TODO(add): will add this functionality after GO Live PL App
          <View style={styles.profitValueWrapper}>
            <View style={styles.arrowStyle} />
            <DefaultText style={[styles.standardText, { marginLeft: 6 }]}>
              {`+${prefix}${maxAvailableValue || 0 - usedValue}`}
            </DefaultText>
          </View>*/}
      </View>
    </Card>
  );
};

export default UsedIndicatorCard;
