import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const WarningCircle = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} fill='none' {...props}>
      <Path
        d='M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM12 7v5.467M12 17h.01'
        stroke={props.stroke || defaultTheme.colors.gray1}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default WarningCircle;
