import React from 'react';

import { useSpring, animated } from '@react-spring/native';
import { View } from 'react-native';

import { useLinerProgressBarStyles } from './styles';
import { LinearProgressBarProps } from './types';

const AnimatedView = animated(View);

export const LinearProgressBar = ({ value, isVisible }: LinearProgressBarProps) => {
  const styles = useLinerProgressBarStyles();

  const animatedWidth = useSpring({ width: isVisible ? `${value}%` : `0%` });

  return (
    <View style={styles.wrapper}>
      <AnimatedView style={[styles.bar, animatedWidth]} children={null} />
    </View>
  );
};

export default LinearProgressBar;
