import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
  NativeSyntheticEvent,
  ReturnKeyType,
  StyleProp,
  TextInput,
  TextInputFocusEventData,
  TextInputProps,
  TextStyle,
} from 'react-native';
import { useTheme } from 'styled-components/native';

import { useHover } from 'hooks';
import { isNativeDevice } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

import { StyledTextInput } from './styles';

export type MangoTextInputProps = {
  postfix?: boolean;
  disabled?: boolean;
  actionCallbacks?: Partial<Record<ReturnKeyType, AnyFunction>>;
  returnKeyType?: ReturnKeyType;
} & TextInputProps;

export type MangoTextInputRef = {
  focus: AnyFunction;
  blur: AnyFunction;
};

const MangoTextInputComponent: ForwardRefRenderFunction<MangoTextInputRef, MangoTextInputProps> = (
  { actionCallbacks, returnKeyType, ...props },
  ref
) => {
  const { colors } = useTheme();
  const textInputRef = useRef<TextInput | any>(null);
  const [styles, setStyles] = useState<StyleProp<TextStyle>>({});
  const [isActive, setIsActive] = useState(false);
  const isHovered = useHover(textInputRef);
  const webOutlineStyle = { outline: 'none' };

  useImperativeHandle(ref, () => textInputRef.current);

  useEffect(() => {
    setStyles({ borderColor: isActive || isHovered ? colors.gray1 : colors.gray3 });
  }, [colors, isHovered, isActive]);

  const handleStyles = (active: boolean) => {
    const borderColor = active ? colors.gray1 : colors.gray3;
    const placeholderColor = colors.gray3;
    if (!isNativeDevice) {
      setIsActive(active);
      return;
    }
    // setting props through setNativeProps is much more performant for native.
    textInputRef.current?.setNativeProps({
      borderColor: borderColor,
      placeholderTextColor: placeholderColor,
    });
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    handleStyles(false);
    props.onBlur?.(e);
  };

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    handleStyles(true);
    props.onFocus?.(e);
  };

  return (
    <StyledTextInput
      {...props}
      numberOfLines={1}
      onSubmitEditing={() => {
        if (!returnKeyType || !actionCallbacks) return;
        const callback = actionCallbacks[returnKeyType];
        if (callback) callback();
      }}
      ref={textInputRef}
      style={[!isNativeDevice && (webOutlineStyle as TextStyle), styles, props.style]}
      placeholderTextColor={colors.gray3}
      onBlur={handleBlur}
      onFocus={handleFocus}
    />
  );
};

export const MangoTextInput = forwardRef(MangoTextInputComponent);
export default forwardRef(MangoTextInputComponent);
