import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const GelCap = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 139.4 99.09' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M70.78 0c20.68.77 41 5.76 56.43 22 19.15 20.09 14.43 44.49-5.08 60-18.93 15-41 19.27-64.68 16.24-17.27-2.2-33-7.92-45.17-20.71C-6.95 57.21-2.22 33.2 17 17.41 32.19 4.88 50.37.44 70.78 0Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M122.13 70.27c-18.93 13.19-41 17-64.68 14.29-17.27-1.94-33-7-45.17-18.22-7.21-6.67-11-13.81-12-20.86-1.27 10.59 2.33 21.77 12 32C24.41 90.26 40.18 96 57.45 98.18c23.72 3 45.75-1.25 64.68-16.24 12.23-9.69 18.65-22.9 17-36.36-1.25 9.22-7.3 17.93-17 24.69Z'
          fillOpacity={0.2}
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M1.36 40s31.79-12.14 74.26 10.4 57.36-.54 60-4.89 1.85-10.88-3.29-17.19S111.43-.17 69.19.05 9.47 24.82 8.56 26c-4.76 5.6-7.2 14-7.2 14Z'
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={115.76} cy={32.53} r={10.26} />
      </G>
    </G>
  </Svg>
);

export default GelCap;
