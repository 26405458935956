import React, { useState, useContext, useCallback } from 'react';

import { useSpring } from '@react-spring/native';
import { ViewStyle } from 'react-native';

import Button from 'components/Button';
import { ShowMoreArrow } from 'components/ShowMoreArrow';
import { DeviceInfoContext, useDeviceInfoContext } from 'contexts/DeviceInfo';

type Config<T> = {
  items?: T[];
  itemsLimit?: number;
  containerStyle?: ViewStyle;
};

export const useShowMore = <T extends unknown>({ items, itemsLimit = 2, containerStyle }: Config<T>) => {
  const { isMobile } = useDeviceInfoContext();
  const [showAll, setShowAll] = useState(false);
  const rotateProps = useSpring({ rotate: showAll ? '180deg' : '0deg' });

  const showMoreHandler = useCallback(() => setShowAll((prevValue) => !prevValue), []);

  const itemsToBeRendered = items?.slice(0, showAll ? items?.length : itemsLimit) || [];

  const showMoreText = showAll ? 'Show Less' : 'Show More';
  const shouldShowArrow = items?.length && items.length > itemsLimit;

  const showMoreJSX = isMobile ? (
    <Button variant='tertiary' containerStyle={containerStyle} text={showMoreText} onPress={() => showMoreHandler()} />
  ) : (
    <ShowMoreArrow
      style={containerStyle}
      showMoreHandler={showMoreHandler}
      showMoreText={showMoreText}
      rotateProps={rotateProps}
    />
  );

  return {
    itemsToBeRendered,
    showMoreJSX: shouldShowArrow ? showMoreJSX : null,
    showMoreHandler,
  };
};
