import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const RedDayMark = (props: SvgProps) => (
  <Svg width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M0 8C0 3.58172 3.58172 0 8 0V0C12.4183 0 16 3.58172 16 8V8C16 12.4183 12.4183 16 8 16V16C3.58172 16 0 12.4183 0 8V8Z'
      fill={defaultTheme.colors.negative}
    />
    <Path d='M11.5 4.5L4.5 11.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    <Path d='M4.5 4.5L11.5 11.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
  </Svg>
);

export default RedDayMark;
