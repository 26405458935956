import * as ImagePicker from 'expo-image-picker';
import { ImageInfo, MediaTypeOptions } from 'expo-image-picker';

import { isNativeDevice } from './helpers';

export const getImageFromGallery = async () => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: MediaTypeOptions.Images,
    allowsEditing: false,
    // for validation type of Image
    base64: !isNativeDevice,
    quality: 1,
  });

  if (!result.cancelled) {
    return {
      base64: getBase64FromImage(result),
      uri: result.uri,
    };
  }
};

export const getBase64FromImage = (data: Pick<ImageInfo, 'base64' | 'type' | 'uri'>) => {
  if (!data.base64) return;

  const { base64, uri, type = 'image' } = data;
  const fileExtension = uri.substring(uri.lastIndexOf('.') + 1) || 'png';
  return `data:${type}/${fileExtension};base64,${base64}`;
};
