const MEDICATION_CREATION_STARTED = 'MEDICATION_CREATION_STARTED';
const MEDICATION_CREATION_INTERRUPTED = 'MEDICATION_CREATION_INTERRUPTED';
const MEDICATION_CREATION_FINISHED = 'MEDICATION_CREATION_FINISHED';
const MEDICATION_CREATION_FAILED = 'MEDICATION_CREATION_FAILED';

const REMINDER_CREATION_STARTED = 'REMINDER_CREATION_STARTED';
const REMINDER_CREATION_FINISHED = 'REMINDER_CREATION_FINISHED';
const REMINDER_CREATION_SKIPPED = 'REMINDER_CREATION_SKIPPED';
const REMINDER_CREATION_FAILED = 'REMINDER_CREATION_FAILED';

const MEDICATION_INTERACTIONS_STEP_VIEWED = 'MEDICATION_INTERACTIONS_STEP_VIEWED';
const VIEW_INTERACTION_DETAILS_FROM_SEQUENCE = 'VIEW_INTERACTION_DETAILS_FROM_SEQUENCE';

export const medicationWizardAnalyticsActions = {
  MEDICATION_CREATION_STARTED,
  MEDICATION_CREATION_INTERRUPTED,
  MEDICATION_CREATION_FINISHED,
  MEDICATION_CREATION_FAILED,
  REMINDER_CREATION_STARTED,
  REMINDER_CREATION_FINISHED,
  REMINDER_CREATION_SKIPPED,
  REMINDER_CREATION_FAILED,
  MEDICATION_INTERACTIONS_STEP_VIEWED,
  VIEW_INTERACTION_DETAILS_FROM_SEQUENCE,
};
