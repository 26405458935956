import { StyleSheet, Platform, View } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';
import { MAX_CONTENT_WIDTH } from 'style/sizes';

export const useSubsectionsTabsStyle = (pspMedicationPrimaryColor?: string) => {
  const { colors, isMobile, fontFamilies, width = 0 } = useTheme();

  return StyleSheet.create({
    headerWrapper: { marginBottom: 26 },
    defaultText: {
      color: colors.gray2,
      ...(Platform.OS === 'web' && { cursor: 'pointer', userSelect: 'none' }),
      paddingBottom: 5,
    },
    tabsContainer: {
      ...(isMobile && { paddingHorizontal: 32 }),
    },
    tabsSeparator: {
      width: 32,
    },
    activeBorderText: {
      borderBottomWidth: 2,
      borderRadius: 1,
      borderStyle: 'solid',
      borderColor: pspMedicationPrimaryColor || colors.mangoPrimary,
    },
    activeColorText: {
      color: pspMedicationPrimaryColor || colors.mangoPrimary,
      fontFamily: fontFamilies.bold,
    },
    backButtonActionsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      paddingTop: 15,
      ...(isMobile &&
        pspMedicationPrimaryColor && {
          backgroundColor: pspMedicationPrimaryColor,
          height: 48,
          width: width + 64,
          right: 32,
          paddingLeft: 64,
          paddingTop: 0,
        }),
    },
    backButtonStyles: {
      marginLeft: 0,
      marginTop: 0,
      padding: 10,
      paddingLeft: 0,
      ...(isMobile && { marginLeft: 32 }),
    },
    goToPspText: {
      color: colors.gray1,
      fontFamily: fontFamilies.semiBold,
      ...(isMobile && { color: colors.white }),
      padding: 10,
      paddingLeft: 0,
    },
    medicationContent: { width: '100%', maxWidth: MAX_CONTENT_WIDTH },
  });
};

export const TitleContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 15px;
  ${(props) =>
    props.theme.isMobile &&
    css`
      margin-top: 10px;
      margin-left: 32px;
    `}
`;

export const MedicationDetailsTitle = styled(DefaultText)`
  ${getFontStyles(24)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.gray1};
  margin-top: 20px;
  margin-bottom: 16px;
  margin-left: 19px;
  ${(props) =>
    props.theme.isMobile &&
    css`
      max-width: 260px;
      margin-bottom: 21px;
      margin-top: 26px;
    `}
`;

export const MedicationDetailsTabs = styled.View`
  flex-direction: row;
  align-items: flex-start;
`;
