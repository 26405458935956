import { CopayCardInfo } from 'api/CopayCardInterface/types';
import * as ActionTypes from 'store/action-types';

export type SetSelectedCopayCard = (payload: CopayCardInfo) => {
  type: typeof ActionTypes.SET_SELECTED_COPAY_CARD;
  payload: typeof payload;
};

export const setSelectedCopayCard: SetSelectedCopayCard = (payload) => ({
  type: ActionTypes.SET_SELECTED_COPAY_CARD,
  payload,
});
