import { ThunkDispatch } from 'redux-thunk';

import { AdditionalServicesInterface } from 'api';
import { AdditionalServicesReturnData } from 'api/AdditionalServicesInterface/types';
import { initializeProgramAdditionalServices } from 'store/actions/additionalServices';
import { customServiceTypeMap } from 'store/reducers/additional-services/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';
import { handlePromise } from 'utils/handlePromise';

import { setConsents } from '../consents/setConsents';

export const initAdditionalServicesData = async (
  dispatch: ThunkDispatch<unknown, unknown, ReturnType<typeof setConsents | typeof initializeProgramAdditionalServices>>
) => {
  const [res] = await handlePromise<AdditionalServicesReturnData>(() => AdditionalServicesInterface.get());

  if (!res?.data?.configs?.length) return;

  dispatch(initializeProgramAdditionalServices(res.data));

  res.data.configs.forEach((serviceConfig) => {
    if (serviceConfig.consentConfig?.length) {
      dispatch(
        setConsents({
          consents: serviceConfig.consentConfig as any,
          groupType: ConsentModalTypeEnum.service,
          customServiceType: customServiceTypeMap[serviceConfig.type],
        })
      );
    }
  });
};
