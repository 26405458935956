import React, { PropsWithChildren } from 'react';

import { View, ViewStyle, StyleProp } from 'react-native';

import { WEB_STEP_SELECTOR } from 'components/Tourguide/constants';

type Props = PropsWithChildren<{
  order: number;
  containerStyle?: StyleProp<ViewStyle>;
}>;

export const WebTutorialWrapper = ({ order, children, containerStyle }: Props) => (
  // @ts-ignore need {dataSet} prop for web tutorial
  <View style={containerStyle} dataSet={{ [WEB_STEP_SELECTOR]: order }}>
    {children}
  </View>
);
