import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints, getAppShadow, hex2rgba } from 'style/utils';

export const useSpecialtyPharmacyModalStyles = () => {
  const { fontSizes, fontFamilies, colors, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: applyBreakpoints(theme, {
      sm: {
        padding: 12,
        width: 248,
      },
      xxl: {
        padding: 32,
        width: 384,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }),
    title: {
      ...fontSizes[24],
      color: colors.primary,
      fontFamily: fontFamilies.bold,
    },
    description: {
      marginTop: 16,
      color: colors.gray2,
    },
    buttonStyle: {
      alignSelf: 'flex-start',
      marginTop: 32,
    },
  });
};
