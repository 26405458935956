const ids: number[] = [];

/**
 * @description The function can generate id by key.
 * @param generateIdKey number which should be bigger than 268435455 and less than 4294967296 to generate 8 characters
 * @return 8 characters(letters and symbols) which is depends on passed key
 * @example
 * idGenerator(1000000000) => "3b9aca00"
 * idGenerator(1000000001) => "3b9aca01"
 * idGenerator(4294967295) => "ffffffff"
 */
export const idGenerator = (generateIdKey = 268435456) => {
  if (generateIdKey < 268435456 || generateIdKey > 4294967295) {
    throw new Error('You should set key in this diapason [268435456, 4294967295] to generate 8 characters id');
  }

  if (ids.includes(generateIdKey))
    console.error(`You've already have this test id, please set uniq id instead of ${generateIdKey}`);
  else ids.push(generateIdKey);

  return generateIdKey.toString(16);
};
