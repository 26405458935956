import apiRequest, { apiPrefix } from 'utils/network';

import { FinancialAssistanceResponse, CopayMetricsResponse } from './types';

const FINANCIAL_ASSISTANCE_URL = '/fa-user-program/';

const FINANCIAL_ASSISTANCE_METRICS_URL = '/copay-cards/user/list-data';

export const FinancialAssistanceInterface = {
  updateIntroductionStatus: (fapUuid: string) => {
    return apiRequest({
      method: 'POST',
      endpoint: FINANCIAL_ASSISTANCE_URL + fapUuid,
      body: { isShown: true },
      baseURL: apiPrefix,
      isProgram: true,
    });
  },
  get: (): Promise<FinancialAssistanceResponse> => {
    return apiRequest({
      method: 'GET',
      endpoint: FINANCIAL_ASSISTANCE_URL,
      baseURL: apiPrefix,
      isProgram: true,
    });
  },
};

export const FinancialAssistanceMetrics = {
  get: (): Promise<CopayMetricsResponse> =>
    apiRequest({
      method: 'GET',
      endpoint: FINANCIAL_ASSISTANCE_METRICS_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    }),
};
