import axios from 'axios';

export const downloadAndOpenPDF = (source: string, title?: string) => {
  axios.get(source, { responseType: 'blob' }).then((response) => {
    const blob = new Blob([response.data], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob);

    const pdfWindow = window.open(url);

    if (pdfWindow)
      setTimeout(() => {
        if (pdfWindow.document) pdfWindow.document.title = title || 'File';
      }, 1000);
  });
};
