import { StyleSheet, Platform } from 'react-native';
import { useTheme } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

export const useProfileLayoutStyles = (titleColor?: string, savingIsDisabled?: boolean, showIcon?: boolean) => {
  const { colors, fontSizes } = useTheme();

  return StyleSheet.create({
    headerWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
    standardText: {
      color: colors.gray1,
      ...(Platform.OS === 'web' && {
        cursor: 'pointer',
        userSelect: 'none',
      }),
    },
    subTitle: {
      color: titleColor ? titleColor : colors.gray1,
    },
    editModeButton: {
      opacity: savingIsDisabled ? 0.5 : 1,
    },
    descriptionWrapper: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: 24,
      marginRight: showIcon && isNativeDevice ? 44 : 0, // to align text when icon going render in the native
    },
    description: {
      ...fontSizes[14],
      color: colors.gray2,
    },
  });
};
