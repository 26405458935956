import { useCallback, useEffect, useState } from 'react';

import { InsuranceInterface } from 'api/Insurance';
import { Insurance } from 'api/Insurance/types';
import { makeRequestWithLoader } from 'utils/requests';

const editInsurances: Insurance[] = [
  { id: '1', type: 'MEDICAL', status: 'PROCESSED' },
  { id: '2', type: 'PHARMACY', status: 'PROCESSED' },
];

export const useInsurances = () => {
  const [isLoading, setLoading] = useState(false);
  const [insurances, setInsurances] = useState<Insurance[]>([]);

  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    makeRequestWithLoader(InsuranceInterface.getInsurances, setLoading).then(setInsurances);
  }, [refetch]);

  const onUpdate = useCallback(() => setRefetch((prev) => !prev), []);

  const isProcessing = insurances.length ? insurances.every((item) => item?.status === 'PROCESSING') : false;

  return {
    noHaveData: !isLoading && !insurances.length,
    isProcessing,
    isLoading,
    insurances,
    editInsurances,
    onUpdate,
  };
};
