import React, { useRef } from 'react';

import { View } from 'react-native';
import ToastContainer, { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/Button';
import { ModalComponent as Modal } from 'components/Modal';
import { setSessionExpiredModalVisible } from 'store/actions';
import { logoutHandler } from 'store/actions/user/actionCreators/logoutHandler';
import { getSessionExpiredModalVisible } from 'store/selectors';
import { PrimaryDefaultText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';

import { TEXTS } from '../constants';
import { useAuthStyles } from '../styles';

export const SessionExpiredModal = () => {
  const isSessionExpiredModalVisible = useSelector(getSessionExpiredModalVisible);
  const toastRef = useRef<ToastContainer>(null);
  const toast = useToast();
  const dispatch = useDispatch();

  const { styles, isMobile } = useStylesWithAdditional(useAuthStyles);

  const closeHandler = () => {
    dispatch(logoutHandler(true));
    toast.show(TEXTS.RE_LOGIN, {
      type: 'warning',
    });
    dispatch(setSessionExpiredModalVisible(false));
  };

  const wrapperStyle = isMobile ? styles.mobileLoginPageModalWrapper : styles.loginPageModalWrapper;

  return (
    <Modal
      onRequestClose={closeHandler}
      modalIsVisible={isSessionExpiredModalVisible}
      setModalVisible={setSessionExpiredModalVisible}
      disableScrollView
      toastRef={toastRef}>
      <View style={wrapperStyle}>
        <PrimaryDefaultText style={styles.loginPageModalTitle}>{TEXTS.SESSION_EXPIRED}</PrimaryDefaultText>
        <View style={[styles.buttonWrapper]}>
          <Button onPress={closeHandler} variant='secondary' text={TEXTS.CLOSE} />
        </View>
      </View>
    </Modal>
  );
};

export default SessionExpiredModal;
