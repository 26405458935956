import React, { useMemo, useState, useCallback, useRef } from 'react';

import { ScrollView, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useOnClickOutside } from 'hooks';
import { useMountedEffect } from 'hooks/useMountedEffect';
import { saveAdditionalServices } from 'store/actions';
import { ServiceTypesEnum } from 'store/reducers/additional-services/types';
import { getShippingAddressInfo } from 'store/selectors/additional-services';

import { ChangeShippingAddress } from '../ChangeShippingAddress';
import { TEXTS } from './constants';
import { useGetUserAddress } from './hooks';
import { Title, Description, Address, ButtonsWrapper, Wrapper } from './styles';
import { ConfirmShippingAddressProps, UserAddress } from './types';
import { getAddressItems, hasRequiredAddressFields } from './utils';

export const ConfirmShippingAddress = ({
  onActionCompleted,
  setModalVisible,
  id,
  modalTitle,
  userDataUuid,
  modalDescription,
}: ConfirmShippingAddressProps) => {
  const toast = useToast();
  const { isMobile } = useDeviceInfoContext();

  const viewRef = useRef<View>(null);
  const setModalVisibleRef = useRef<ConfirmShippingAddressProps['setModalVisible']>();
  setModalVisibleRef.current = setModalVisible;

  const onClickOutsideHandler = useCallback(() => {
    setModalVisibleRef.current?.(false);
  }, []);

  // @ts-ignore TODO: typing
  useOnClickOutside(viewRef, onClickOutsideHandler);

  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(false);

  const shippingAddressInfo = useSelector(getShippingAddressInfo);
  const userAddress = useGetUserAddress();

  const initAddress = useMemo(() => {
    if (
      shippingAddressInfo &&
      hasRequiredAddressFields({ ...shippingAddressInfo, zipCode: shippingAddressInfo.zipcode })
    )
      return { ...shippingAddressInfo, zipCode: shippingAddressInfo.zipcode };
    if (userAddress && hasRequiredAddressFields(userAddress)) return userAddress;
    return null;
  }, [userAddress, shippingAddressInfo]);

  const [formAddress, setFormAddress] = useState<UserAddress>({
    addressLine1: shippingAddressInfo?.addressLine1 || userAddress.addressLine1,
    addressLine2: shippingAddressInfo?.addressLine2 || userAddress.addressLine2,
    city: shippingAddressInfo?.city || userAddress.city,
    zipCode: shippingAddressInfo?.zipcode || userAddress.zipCode,
    state: shippingAddressInfo?.state || userAddress.state,
  });

  useMountedEffect(() => {
    !!initAddress && setFormAddress(initAddress);
    setEditMode(!initAddress);
  }, [initAddress]);

  const formJSX = (
    <ScrollView showsVerticalScrollIndicator={false} style={{ paddingBottom: isMobile ? 52 : 0 }}>
      <ChangeShippingAddress
        withBackButton={hasRequiredAddressFields(formAddress)}
        onSave={(data) => {
          setFormAddress(data);
          setEditMode(false);
        }}
        onBack={() => setEditMode(false)}
        address={formAddress}
      />
    </ScrollView>
  );

  const addressItems = getAddressItems(formAddress);

  const onSave = ({ zipCode, ...rest }: UserAddress) => {
    dispatch(
      saveAdditionalServices({
        type: ServiceTypesEnum.PHYSICAL_SHIPMENT,
        configUuid: id,
        zipcode: zipCode,
        userDataUuid: userDataUuid || '',
        ...rest,
      })
    );
    toast.show(TEXTS.toast, { type: 'success' });
    id && onActionCompleted?.(id);
    setModalVisibleRef.current?.(false);
  };

  return (
    <View ref={viewRef}>
      {editMode ? (
        formJSX
      ) : (
        <Wrapper>
          {!!modalTitle && <Title>{modalTitle}</Title>}
          {!!modalDescription && <Description>{modalDescription}</Description>}
          {addressItems.map((item) => (
            <Address key={item}>{item}</Address>
          ))}
          <ButtonsWrapper>
            <Button
              withoutPadding
              containerStyle={{ width: '35%', paddingRight: 12 }}
              variant='tertiary'
              text={TEXTS.edit}
              onPress={() => setEditMode(true)}
            />
            <Button
              withoutPadding
              containerStyle={{ width: '35%' }}
              text={TEXTS.set}
              variant='secondary'
              onPress={() => onSave(formAddress)}
            />
          </ButtonsWrapper>
        </Wrapper>
      )}
    </View>
  );
};
