import { SetIsForgotPasswordModalVisible } from 'store/actions/user/actionCreators/setIsForgotPasswordModalVisible';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setIsForgotPasswordModalVisible = (
  state = INITIAL_STATE,
  payload: ReturnType<SetIsForgotPasswordModalVisible>['payload'],
  withForgotPasswordModalMessage?: string
): User => ({
  ...state,
  isForgotPasswordModalVisible: payload,
  withForgotPasswordModalMessage,
});

export default setIsForgotPasswordModalVisible;
