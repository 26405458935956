import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { getBoxShadowStyle } from 'style/common';
import { applyBreakpoints } from 'style/utils';

export const useProgramMedicationStyles = (primaryColor?: string) => {
  const { colors, fontSizes, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    medicationWrapper: {
      ...getBoxShadowStyle(),
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 24,
      borderRadius: 16,
      marginBottom: 16,
      backgroundColor: colors.white,
      ...(primaryColor && {
        borderTopWidth: 16,
        borderColor: primaryColor,
      }),
    },
    iconsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',

      ...applyBreakpoints(theme, {
        sm: { display: 'none' },
      }),
    },
    titleWrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      ...applyBreakpoints(theme, {
        sm: { width: '80%' },
        xxl: { width: '70%' },
      }),
    },
    displayNameText: {
      ...fontSizes[16],
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
      paddingRight: 16,
      ...applyBreakpoints(theme, {
        sm: { paddingRight: 0 },
        xxl: { width: '35%' },
      }),
    },
    quantityInfoWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      ...applyBreakpoints(theme, {
        sm: { marginTop: 8 },
      }),
    },
    displayQuantityText: {
      fontFamily: fontFamilies.default,
      ...fontSizes[14],
    },
    standardText: {
      color: colors.gray2,
      paddingRight: 12,
    },
    gotoPSPText: {
      color: colors.gray1,
      fontFamily: fontFamilies.semiBold,
    },
    swipeableRightActionsWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingLeft: 24,
      overflow: 'hidden',
    },
    touchableArea: {
      position: 'relative',
      ...applyBreakpoints(theme, {
        sm: { padding: 8 },
        xxl: { marginLeft: 4, padding: 10 },
      }),
    },
    medicationNameAndRemindingTimeWrapper: { marginLeft: 16, width: '100%' },
    remindingTimeWrapper: { flexDirection: 'row', alignItems: 'center', marginTop: 10 },
    reminderTimeText: { color: colors.gray2 },
    mgInfoAndTitleWrapper: {
      width: '100%',
      justifyContent: 'flex-start',
      ...applyBreakpoints(theme, {
        sm: {},
        xxl: { flexDirection: 'row' },
      }),
    },
    badgeWrapper: {
      position: 'absolute',
      top: 3,
      right: 4,
      width: 15,
      height: 15,
      borderRadius: 100,
      backgroundColor: colors.mangoSecondary,
      justifyContent: 'center',
      alignItems: 'center',
    },
    swipeBadgeStyles: {
      width: 18,
      height: 18,
      top: -8,
      right: -8,
    },
    badgeText: {
      ...fontSizes[10],
      color: colors.white,
    },
  });
};
