import React from 'react';

import { ViewProps } from 'react-native';

import { defaultTheme } from 'style/theme';

import { ProgressHeaderContainer, ProgressBar } from '../styles';

type TutorialProgressBar = {
  active: boolean;
  count: number;
};

const TutorialProgressBar = ({ active, count }: TutorialProgressBar) => {
  const progressColor = active ? defaultTheme.colors.positive : defaultTheme.colors.gray3;
  return <ProgressBar background={progressColor} count={count} />;
};

export type ProgressHeaderProps = {
  currentStep: number;
  stepCount: number;
};

export const ProgressHeader = ({ currentStep, stepCount, ...rest }: ProgressHeaderProps & ViewProps) => (
  <ProgressHeaderContainer style={rest.style}>
    {new Array(stepCount).fill(null).map((_, index) => (
      <TutorialProgressBar key={index} active={currentStep >= index + 1} count={stepCount} />
    ))}
  </ProgressHeaderContainer>
);
