import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function ViewIcon(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M1.5 12S5.318 4.5 12 4.5 22.5 12 22.5 12s-3.818 7.5-10.5 7.5S1.5 12 1.5 12z'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <Path
        d='M12 15a3 3 0 100-6 3 3 0 000 6z'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default ViewIcon;
