import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useAdditionalServicesSectionsStyles = () => {
  const { fontFamilies, fontSizes } = useTheme();

  return StyleSheet.create({
    titleText: {
      ...fontSizes[24],
      fontFamily: fontFamilies.bold,
      marginBottom: 24,
    },
  });
};
