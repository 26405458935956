import React from 'react';

import { Pressable, Animated } from 'react-native';

import { AnyFunction } from 'utils/types';

import { styles } from './styles';

export type SwipeableRightActionProps = {
  Icon: React.ReactNode;
  bgColor: string;
  onPress: AnyFunction;
  x: number;
  height?: number;
  progress: Animated.AnimatedInterpolation;
};

export const SwipeableRightAction = ({ Icon, bgColor, onPress, x, progress, height }: SwipeableRightActionProps) => {
  const trans = progress.interpolate({
    inputRange: [0, 1],
    outputRange: [x, 0],
  });

  return (
    <Animated.View style={{ transform: [{ translateX: trans }] }}>
      <Pressable onPress={onPress} style={[styles.actionWrapper, { backgroundColor: bgColor, height: height || 140 }]}>
        {Icon}
      </Pressable>
    </Animated.View>
  );
};

export default SwipeableRightAction;
