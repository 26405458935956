import React from 'react';

import { View } from 'react-native';

import { Button, Modal } from 'components';
import { DefaultText } from 'style/common';
import { AnyFunction } from 'utils/types';

import { text } from './constants';
import { useSpecialtyPharmacyModalStyles } from './styles';

export type SpecialtyPharmacyModalProps = {
  visible: boolean;
  onClose: AnyFunction;
};

const { title, description, buttonText } = text;

export const SpecialtyPharmacyModal = ({ visible, onClose }: SpecialtyPharmacyModalProps) => {
  const styles = useSpecialtyPharmacyModalStyles();

  return null; // TODO: we don't need this modal right now, will be needed in future sprints

  return (
    <Modal modalIsVisible={visible} onRequestClose={onClose}>
      <View style={styles.wrapper}>
        <DefaultText style={styles.title}>{title}</DefaultText>
        <DefaultText style={styles.description}>{description}</DefaultText>
        <Button onPress={onClose} variant='secondary' style={styles.buttonStyle} text={buttonText} />
      </View>
    </Modal>
  );
};
