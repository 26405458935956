import * as ActionTypes from 'store/action-types';

export type RemoveSelectedSurvey = (payload: { id: string }) => {
  type: typeof ActionTypes.REMOVE_SELECTED_SURVEY;
  payload: typeof payload;
};

export const removeSelectedSurvey: RemoveSelectedSurvey = (payload) => ({
  type: ActionTypes.REMOVE_SELECTED_SURVEY,
  payload,
});
