import React from 'react';

import { View } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useSelector } from 'react-redux';

import { Button } from 'components/Button';
import { SurveyCompletionModalContent } from 'screens/main/Home/components/SurveyCompletionModalContent';
import { QuestionTypeEnum } from 'store/reducers/user-info/types/survey';
import { getQuestionsOfSelectedSurvey, getSelectedSurvey, getUnnormalizedSelectedSurvey } from 'store/selectors';
import { DefaultText, PrimaryDefaultText, webStyles } from 'style/common';
import { AnyFunction } from 'utils/types';

import { ProgressSteps } from '../../index';
import { useHandleSurvey } from '../useHandleSurvey';
import { MultipleAnswers } from './Multiple';
import { RangeOfAnswers } from './Range';
import { SingleAnswer } from './Single';
import { useSurveyContentStyle } from './styles';

type SurveyProps = {
  onRequestClose: AnyFunction;
  toastRef: React.MutableRefObject<ToastContainer | null>;
  isSurveyStarted: boolean;
};

export const SurveyContent = ({ onRequestClose, toastRef, isSurveyStarted }: SurveyProps) => {
  const selectedSurvey = useSelector(getSelectedSurvey);
  const unnormalizedSelectedSurvey = useSelector(getUnnormalizedSelectedSurvey);
  const questionsOfSelectedSurvey = useSelector(getQuestionsOfSelectedSurvey);

  const styles = useSurveyContentStyle();

  const {
    currentStep,
    currentQuestion,
    nextButtonIsAvailable,
    isLastModalVisible,
    setLastModalVisible,
    handleAnswerSubmit,
    handleBackButton,
  } = useHandleSurvey({
    questionsOfSelectedSurvey,
    onRequestClose,
    isSurveyStarted,
  });

  if ((!selectedSurvey || !currentQuestion) && !isLastModalVisible) return null;

  const questionComponentJSX = (() => {
    switch (currentQuestion?.question_type) {
      case QuestionTypeEnum.SINGLE_SELECT:
        return <SingleAnswer currentQuestion={currentQuestion} toastRef={toastRef} />;
      case QuestionTypeEnum.MULTI_SELECT:
        return (
          <MultipleAnswers
            surveyId={selectedSurvey?.user_survey_uuid}
            currentQuestion={currentQuestion}
            toastRef={toastRef}
          />
        );
      case QuestionTypeEnum.RATING_SCALE:
        return (
          <RangeOfAnswers
            surveyId={selectedSurvey?.user_survey_uuid}
            currentQuestion={currentQuestion}
            toastRef={toastRef}
          />
        );
      default:
        return null;
    }
  })();

  return (
    <>
      {isLastModalVisible ? (
        <SurveyCompletionModalContent setModalVisible={setLastModalVisible} />
      ) : (
        <>
          {!!selectedSurvey?.questions?.length && (
            <ProgressSteps
              steps={selectedSurvey.questions}
              currentStep={currentStep}
              currentlyActiveItemId={currentQuestion?.uuid}
            />
          )}
          <PrimaryDefaultText numberOfLines={4} style={styles.title}>
            {currentQuestion?.title}
          </PrimaryDefaultText>
          {!!currentQuestion?.description && (
            <DefaultText numberOfLines={8} style={styles.description}>
              {currentQuestion.description}
            </DefaultText>
          )}
          <View style={styles.questionsWrapper}>{questionComponentJSX}</View>
          <View style={styles.buttonWrapper}>
            <Button
              variant='tertiary'
              text='Back'
              disabled={currentStep <= 1}
              style={[styles.backButton, webStyles.button, currentStep <= 1 && styles.backButtonDisabled]}
              onPress={handleBackButton(currentStep)}
            />
            <Button
              variant='secondary'
              style={[styles.submitButton, webStyles.button]}
              text={selectedSurvey?.questions.length === currentStep ? 'Submit' : 'Next'}
              onPress={handleAnswerSubmit(unnormalizedSelectedSurvey, questionsOfSelectedSurvey)}
              disabled={!nextButtonIsAvailable}
            />
          </View>
        </>
      )}
    </>
  );
};

export default SurveyContent;
