import React, { useState } from 'react';

import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface } from 'api';
import { Button, ErrorBox } from 'components';
import { ModalComponent as Modal } from 'components/Modal';
import SelectBox from 'components/SelectBox';
import { Option } from 'screens/main/MedicineCabinet/components/FieldBlock/types';
import { setAsNeededMedicationModalVisibility } from 'store/actions/medicineCabinet';
import { getAsNeededMedicationModalVisibility, getAsNeededReminders } from 'store/selectors';
import { DefaultText, StandardText } from 'style/common';
import { SOMETHING_WRONG_ERROR_MESSAGE } from 'utils/constants';
import { AnyFunction } from 'utils/types';

import { useAsNeededMedicationStyles } from './styles';

type AsNeededMedicationModalProps = {
  onSubmit: AnyFunction;
};

export const AsNeededMedicationModal = ({ onSubmit }: AsNeededMedicationModalProps) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const modalVisibility = useSelector(getAsNeededMedicationModalVisibility);
  const asNeededReminders = useSelector(getAsNeededReminders);

  const selectOptions: Option[] = asNeededReminders.map((reminder) => ({
    id: reminder.id,
    label: reminder.medication.name,
    value: reminder.id,
  }));

  const setModalVisibility = (visibility: boolean) => dispatch(setAsNeededMedicationModalVisibility(visibility));

  const styles = useAsNeededMedicationStyles();

  const handleChange = (value: string) => {
    if (errorMessage) setErrorMessage('');
    setValue(value);
  };

  const handleSubmit = async () => {
    if (!value) return setErrorMessage('Medication selection is required');
    setIsLoading(true);
    try {
      await MedicineCabinetInterface.setAsNeededMedication(Number(value));
      onSubmit();
      handleCloseModal();
    } catch (error) {
      setErrorMessage(`${SOMETHING_WRONG_ERROR_MESSAGE}. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setValue('');
    setModalVisibility(false);
  };

  return (
    <Modal onRequestClose={handleCloseModal} modalIsVisible={modalVisibility} setModalVisible={setModalVisibility}>
      <View style={styles.asNeededModalContainer}>
        <DefaultText style={styles.modalTitle}>Add an As Needed Medication</DefaultText>
        <StandardText style={styles.modalDescription}>Fill out the fields below</StandardText>
        <SelectBox items={selectOptions} onValueChange={handleChange} labelText='Medication?' />
        {!!errorMessage && <ErrorBox message={errorMessage} />}
        <View style={styles.buttonsWrapper}>
          <Button onPress={handleCloseModal} style={styles.cancelButton} variant='tertiary' text='Cancel' />
          <Button disabled={isLoading} onPress={handleSubmit} style={styles.addButton} variant='mango' text='Add' />
        </View>
      </View>
    </Modal>
  );
};

export default AsNeededMedicationModal;
