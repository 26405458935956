import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { screenContentWidth } from 'style/utils';

const DetailsContentPlaceholder = () => {
  const theme = useTheme();

  return (
    <ContentLoader
      foregroundColor={theme.colors.gray4}
      backgroundColor={theme.colors.gray3}
      width={screenContentWidth}
      height={'100%'}
      style={{ minHeight: 600 }}>
      <Rect x='0' y='32' rx='6' ry='6' width='80' height='16' />
      <Rect x='0' y='60' rx='6' ry='6' width='120' height='16' />
      <Rect x='140' y='62' rx='3' ry='3' width='80%' height='12' />
      <Rect x='0' y='94' rx='3' ry='3' width='35%' height='12' />
      <Rect x='40%' y='94' rx='3' ry='3' width='45%' height='12' />
      <Rect x='0' y='122' rx='3' ry='3' width='65%' height='12' />
      <Rect x='70%' y='122' rx='3' ry='3' width='25%' height='12' />
      <Rect x='0' y='154' rx='6' ry='6' width='160' height='16' />
      <Rect x='60' y='186' rx='3' ry='3' width='45%' height='12' />
      <Rect x='0' y='218' rx='6' ry='6' width='160' height='16' />
      <Rect x='60' y='250' rx='3' ry='3' width='55%' height='12' />
      <Rect x='60' y='278' rx='3' ry='3' width='75%' height='12' />
      <Rect x='60' y='306' rx='3' ry='3' width='60%' height='12' />
      <Rect x='60' y='334' rx='3' ry='3' width='70%' height='12' />
      <Rect x='0' y='362' rx='6' ry='6' width='160' height='16' />
      <Rect x='60' y='390' rx='3' ry='3' width='45%' height='12' />
      <Rect x='0' y='418' rx='6' ry='6' width='120' height='16' />
      <Rect x='140' y='446' rx='3' ry='3' width='80%' height='12' />
      <Rect x='0' y='474' rx='3' ry='3' width='35%' height='12' />
    </ContentLoader>
  );
};

export default DetailsContentPlaceholder;
