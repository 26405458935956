import {
  SET_COMPLETED_SERVICE_ACTION,
  SET_COMPLETED_SERVICE_REQUEST,
  SET_SELECTED_SERVICE_ACTION,
  SET_SELECTED_SERVICE_REQUEST,
  SET_SELECTED_SERVICE_DIGITAL_RESOURCE,
  SET_COMPLETED_SERVICE_DIGITAL_RESOURCE,
  SET_CURRENTLY_ACTIVE_SERVICE,
  REMOVE_CURRENTLY_ACTIVE_SERVICE,
  REMOVE_SELECTED_SERVICE_ACTION,
  REMOVE_SELECTED_SERVICE_DIGITAL_RESOURCE,
  REMOVE_SELECTED_SERVICE_REQUEST,
  INITIALIZE_PROGRAM_ADDITIONAL_SERVICES,
  SET_SHIPPING_ADDRESS_INFO,
  SET_RESOURCES_NOTIFICATION_ICON_VISIBILITY,
} from 'store/action-types';
import { SET_VIEWED_FINANCIAL_ASSISTANCE } from 'store/action-types/additional-services';
import { AvailableActionsForAdditionalServices } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { InitialReducerState, Service } from './types';

const additionalServices = (
  state = INITIAL_STATE,
  action: AvailableActionsForAdditionalServices
): InitialReducerState => {
  switch (action.type) {
    case SET_COMPLETED_SERVICE_ACTION:
      return {
        ...state,
        actions: state.actions.map((service) => {
          const { id, isCompleted } = action.payload;
          if (id === service.id) {
            return { ...service, isCompleted };
          }
          return service;
        }),
      };
    case SET_COMPLETED_SERVICE_REQUEST:
      return {
        ...state,
        requests: state.requests.map((request) => {
          const { id, isCompleted } = action.payload;
          if (id === request.id) {
            return { ...request, isCompleted };
          }
          return request;
        }),
      };
    case SET_COMPLETED_SERVICE_DIGITAL_RESOURCE:
      return {
        ...state,
        digitalResources: state.digitalResources.map((digitalResource) => {
          const { id, isCompleted } = action.payload;
          if (id === digitalResource.id) {
            return { ...digitalResource, isCompleted };
          }
          return digitalResource;
        }),
      };
    case SET_SELECTED_SERVICE_ACTION:
      return {
        ...state,
        actions: state.actions.map((service) => {
          if (action.payload.id === service.id) return { ...service, isSelected: action.payload.isSelected };

          return { ...service, isSelected: false };
        }),
      };
    case SET_VIEWED_FINANCIAL_ASSISTANCE:
      return {
        ...state,
        [action.payload.type]: (state[action.payload.type] as Service[]).map((service) => {
          if (action.payload.id === service.id) return { ...service, isNew: false };

          return service;
        }),
      };

    case REMOVE_SELECTED_SERVICE_ACTION:
      return {
        ...state,
        actions: state.actions.map((service) => {
          if (action.payload.id === service.id) return { ...service, isSelected: false };

          return service;
        }),
      };

    case SET_SELECTED_SERVICE_REQUEST:
      return {
        ...state,
        requests: state.requests.map((request) => {
          if (action.payload.id === request.id) {
            return { ...request, isSelected: action.payload.isSelected };
          }
          return { ...request, isSelected: false };
        }),
      };

    case REMOVE_SELECTED_SERVICE_REQUEST:
      return {
        ...state,
        requests: state.requests.map((request) => {
          if (action.payload.id === request.id) {
            return { ...request, isSelected: false };
          }
          return request;
        }),
      };

    case SET_SELECTED_SERVICE_DIGITAL_RESOURCE:
      return {
        ...state,
        digitalResources: state.digitalResources.map((digitalResource) => {
          if (action.payload.id === digitalResource.id) {
            return { ...digitalResource, isSelected: action.payload.isSelected };
          }
          return { ...digitalResource, isSelected: false };
        }),
      };

    case REMOVE_SELECTED_SERVICE_DIGITAL_RESOURCE:
      return {
        ...state,
        digitalResources: state.digitalResources.map((digitalResource) => {
          if (action.payload.id === digitalResource.id) {
            return { ...digitalResource, isSelected: false };
          }
          return digitalResource;
        }),
      };

    case SET_CURRENTLY_ACTIVE_SERVICE:
      return {
        ...state,
        currentlyActiveService: action.payload,
      };

    case REMOVE_CURRENTLY_ACTIVE_SERVICE:
      return {
        ...state,
        currentlyActiveService: undefined,
      };

    case SET_SHIPPING_ADDRESS_INFO:
      return {
        ...state,
        shippingAddressInfo: action.payload,
      };

    case SET_RESOURCES_NOTIFICATION_ICON_VISIBILITY:
      return {
        ...state,
        resourcesNotificationIconVisibility: action.payload,
      };

    case INITIALIZE_PROGRAM_ADDITIONAL_SERVICES:
      return action.payload;

    default:
      return state;
  }
};

export default additionalServices;
