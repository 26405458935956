import { RemoveNotificationTileByType } from 'store/actions/user/actionCreators/notification-tiles/removeNotificationTileByType';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const removeNotificationTileByType = (
  state = INITIAL_STATE,
  payload: ReturnType<RemoveNotificationTileByType>['payload']
): User => {
  const filteredTiles = state.notificationTiles?.filter((tile) => tile.type !== payload);
  return { ...state, notificationTiles: filteredTiles };
};

export default removeNotificationTileByType;
