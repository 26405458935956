// analyticsConstants

const ADD_REMINDER_STARTED = 'ADD_REMINDER_STARTED';
const ADD_PSP_REMINDER_STARTED = 'ADD_PSP_REMINDER_STARTED';
const ADD_REMINDER_FINISHED = 'ADD_REMINDER_FINISHED';
const ADD_PSP_REMINDER_FINISHED = 'ADD_PSP_REMINDER_FINISHED';
const ADD_REMINDER_INTERRUPTED = 'ADD_REMINDER_INTERRUPTED';
const EDIT_REMINDER_STARTED = 'EDIT_REMINDER_STARTED';
const EDIT_PSP_REMINDER_STARTED = 'EDIT_PSP_REMINDER_STARTED';
const EDIT_REMINDER_FINISHED = 'EDIT_REMINDER_FINISHED';
const EDIT_PSP_REMINDER_FINISHED = 'EDIT_PSP_REMINDER_FINISHED';
const EDIT_REMINDER_INTERRUPTED = 'EDIT_REMINDER_INTERRUPTED';
const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';

export const reminderModalAnalyticsActions = {
  ADD_REMINDER_STARTED,
  ADD_PSP_REMINDER_STARTED,
  ADD_REMINDER_FINISHED,
  ADD_PSP_REMINDER_FINISHED,
  ADD_REMINDER_INTERRUPTED,
  EDIT_REMINDER_STARTED,
  EDIT_PSP_REMINDER_STARTED,
  EDIT_REMINDER_FINISHED,
  EDIT_PSP_REMINDER_FINISHED,
  EDIT_REMINDER_INTERRUPTED,
  DELETE_REMINDER_SUCCESS,
};
