import { InfoCardTypeEnum } from 'enums';

import { InitialReducerState } from './types';

export const INITIAL_STATE: InitialReducerState = {
  shownCards: [
    { title: InfoCardTypeEnum.SURVEY, shown: true },
    { title: InfoCardTypeEnum.BENEFITS, shown: true },
    { title: InfoCardTypeEnum.FINANCIAL_ASSISTANCE, shown: true },
    { title: InfoCardTypeEnum.INSURANCE_TILE, shown: true },
  ],
  benefitInvestigations: null,
  milestoneTrackers: [],
  specialtyPharmacy: null,
  insuranceTileInfo: null,
};
