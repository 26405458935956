import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function RightArrow(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path
        d='M6.857 22.286L17.143 12 6.857 1.714'
        stroke={props.stroke || '#000'}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default RightArrow;
