import React from 'react';

import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'components/Modal';
import { setMedicationWizardStep, setMedicationWizardVisibility } from 'store/actions/medicineCabinet';
import { getMedicationWizardStep, getMedicationWizardVisibility } from 'store/selectors';

import { medicationWizardAnalyticsActions } from './constants';
import { wizardSteps } from './mappings';
import StepProgressItem from './StepProgressItem';
import { NoStep } from './Steps';
import { getMedicationWizardStyles } from './styles';
import useWizardAnalytics from './useWizardAnalytics';

const { MEDICATION_CREATION_INTERRUPTED, REMINDER_CREATION_SKIPPED } = medicationWizardAnalyticsActions;

export const MedicationWizard = () => {
  const dispatch = useDispatch();
  const wizardVisibility = useSelector(getMedicationWizardVisibility);
  const wizardStep = useSelector(getMedicationWizardStep);
  const isReminderStep = wizardStep === 1;
  const isMedicationStep = wizardStep === 0;

  const analyticsHandler = useWizardAnalytics();

  const setWizardVisibility = (visible: boolean) => dispatch(setMedicationWizardVisibility(visible));

  const styles = getMedicationWizardStyles();

  const StepComponent = wizardSteps[wizardStep] || NoStep;

  const setNextStep = () => dispatch(setMedicationWizardStep(wizardStep + 1));

  const handleOnRequestClose = () => {
    if (isMedicationStep) {
      analyticsHandler(MEDICATION_CREATION_INTERRUPTED);
    }
    if (isReminderStep) {
      analyticsHandler(REMINDER_CREATION_SKIPPED);
      setNextStep();
      return;
    }

    setWizardVisibility(false);
  };

  return (
    <Modal
      onRequestClose={handleOnRequestClose}
      closeButtonStyle={styles.closeButtonStyle}
      modalContentStyle={styles.contentStyle}
      modalIsVisible={wizardVisibility}
      setModalVisible={setWizardVisibility}
      disableScrollView
      useKeyboardAvoid>
      <View style={styles.wizardWrapper}>
        <View style={styles.stepProgressBarWrapper}>
          {new Array(wizardSteps.length).fill(null).map((_, index) => (
            <StepProgressItem key={index} active={wizardStep + 1 > index} />
          ))}
        </View>
        <StepComponent />
      </View>
    </Modal>
  );
};

export default MedicationWizard;
