import React from 'react';

import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Capsule = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 141.92 76.49' {...props}>
    <G data-name='Layer 2'>
      <G data-name='Layer 1'>
        <Path
          d='M140.46 29.85c-.86-3.5-3.39-13.26-11.63-20.64-5.67-5.09-11.71-6.7-15.7-7.78C105.27-.71 100.16.07 88.66.5 82.47.74 73.34.92 62 .5v75.87h18c.51.12 18.74.16 27.23.08a29.75 29.75 0 0 0 6.82-.84 34.85 34.85 0 0 0 15.73-9.08c2.82-2.73 9.5-9.16 11.52-20a38 38 0 0 0-.84-16.68Z'
          fill='#f3f3f3'
        />
        <Path
          d='M.62 46.37C2.8 57.24 10 63.67 13 66.39a38 38 0 0 0 17 9.09 34.38 34.38 0 0 0 7.34.84c9.15.08 18.3-.09 27.44.08 3 .06 4.44-1 5.31-3.9a109.78 109.78 0 0 0 3.78-44.19A173.16 173.16 0 0 0 69.66 3.9c-.44-1.73-.75-3.45-2.88-3.4C54.84.73 42.76-1 31 1.3a37.3 37.3 0 0 0-16.92 7.78C5.21 16.46 2.48 26.22 1.56 29.72a35.22 35.22 0 0 0-.94 16.65Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M132.62 63.82C119.83 78 106.89 76.41 106.89 76.41s-47.95.31-71.88-.26C24 75.9 7.75 66.23 2 50.82a31.76 31.76 0 0 1-2-11.45c10 8.73 22.17 11.69 34.94 11.87q36.11.51 72.21 0c9.21-.14 18.4-1.53 26.91-5.81 3.07-1.55 5-4.31 7.83-6a34 34 0 0 1-9.27 24.39Z'
          fillOpacity={0.2}
        />
      </G>
    </G>
  </Svg>
);

export default Capsule;
