import { Platform, Pressable, StyleSheet, ViewStyle } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

import { FontFamilies } from './theme';

export const getBoxShadowStyle = () => {
  if (Platform.OS === 'android') {
    return {
      elevation: 5,
      shadowColor: '#000',
    };
  }
  return {
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: Platform.OS === 'ios' ? 10 : 16,
  };
};

export const webStyles = StyleSheet.create({
  // @ts-ignore
  button: { ...(Platform.OS === 'web' && { cursor: 'pointer', userSelect: 'none' }) },
});

export const commonStyles = StyleSheet.create({
  boxShadowStyle: getBoxShadowStyle(),
});

export const closeButtonStyles = {
  button: {
    padding: 10,
  },
  onHover: {
    backgroundColor: '#e8e8e8',
    borderRadius: 20,
  },
};

export const SafeViewWrapper = styled.SafeAreaView`
  flex: 1;
`;

export const fontSizeLineHeightRatio = 1.2187;

export const getFontStyles = (size: number) => css`
  font-size: ${size}px;
  line-height: ${size * fontSizeLineHeightRatio}px;
`;

export const DefaultText = styled.Text`
  font: 14px 'Montserrat' !important;
  line-height: 17px;
  ${({ theme }) =>
    !theme.isMobile &&
    css`
      user-select: text;
    `}
`;

export const PrimaryDefaultText = styled(DefaultText)`
  color: ${(props) => props.theme.colors.primary};
`;

export const ErrorText = styled(DefaultText)`
  color: ${(props) => props.theme.colors.negative};
`;

export const SecondaryDefaultText = styled(DefaultText)`
  color: ${(props) => props.theme.colors.secondary};
`;

export const LogoWrapper = styled(Pressable)`
  width: 222px;
  height: 48px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 184px;
      height: 40px;
    `}
  ${({ theme }) =>
    theme.isMobile &&
    !isNativeDevice &&
    css`
      margin-top: 24px;
    `}
`;

export const MangoLogoWrapper = styled(Pressable)`
  width: 222px;
  height: 48px;
  margin-top: 12px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 184px;
      height: 40px;
      margin: 0;
    `}
`;

export const StyledImage = styled.Image`
  width: 100%;
  height: 100%;
`;
export const FooterLogoWrapper = styled.View`
  margin-bottom: 60px;
  width: 172px;
  height: 48px;
  margin-top: auto;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-bottom: 20px;
      width: 164px;
      height: 46px;
    `}
`;

export const BaseCard = styled.Pressable({
  shadowColor: '#000',
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 16,
  elevation: 3,
});

export const HeaderText = styled(DefaultText)<{ headerTextColor?: string }>`
  ${getFontStyles(24)}
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.headerTextColor || props.theme.colors.primary};
  position: relative;
`;

export const StandardText = styled(DefaultText)`
  ${getFontStyles(16)}
  color: ${(props) => props.theme.colors.gray2};
`;

export const InfoBlockLabel = styled(DefaultText)`
  color: ${(props) => props.theme.colors.gray2};
  margin-bottom: 8px;
`;

export const InformationText = styled(DefaultText)<{ fontFamily?: FontFamilies }>`
  ${getFontStyles(16)}
  color: ${(props) => props.theme.colors.gray1};
  ${({ fontFamily }) =>
    fontFamily &&
    css`
      font-family: ${(props) => props.theme.fontFamilies[fontFamily]};
    `}
`;

export const IconWrapper = styled.View`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

export const HeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column;
    `}
`;

export const SubTitle = styled(DefaultText)`
  ${getFontStyles(16)}
  color: ${(props) => props.theme.colors.primary};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const MedicationSubTitle = styled(DefaultText)`
  ${getFontStyles(16)}
  color: ${(props) => props.theme.colors.gray1};
  font-family: ${(props) => props.theme.fontFamilies.bold};
`;

export const modalContentStyle: ViewStyle = { padding: 0, paddingTop: 20, overflow: 'hidden' };

export const useConsentStyles = () => {
  const { isMobile, fontSizes, fontFamilies, colors } = useTheme();

  return StyleSheet.create({
    wrapper: isMobile
      ? { paddingHorizontal: 5, paddingTop: 23, paddingBottom: 5, maxWidth: 300 }
      : { paddingHorizontal: 29, paddingTop: 17, paddingBottom: 29, maxWidth: 500, minWidth: 400 },
    title: {
      ...fontSizes[24],
      fontFamily: fontFamilies.bold,
      marginTop: 20,
    },
    descriptionWrapper: { marginTop: 20 },
    description: {
      ...fontSizes[16],
      color: colors.gray1,
    },
    consentContentText: {
      ...fontSizes[16],
      color: colors.gray1,
    },
    consentContentScroll: {
      maxHeight: 180,
    },
    consentScrollWrapper: {
      marginVertical: 20,
      paddingLeft: 24,
      paddingRight: 8,
      borderWidth: 1,
      borderColor: colors.gray3,
      borderRadius: 8,
    },
    confirmButton: { width: 90, marginTop: 20 },
    submitButtonWrapper: { flexDirection: 'row' },
    submitButton: { width: 120, marginTop: 30 },
    skipButton: { width: 120, marginTop: 30, marginLeft: 30 },
    confirmAddressMaxWidth: { maxWidth: isMobile ? 240 : 560 },
    consentMaxWidth: { maxWidth: isMobile ? 300 : 348 },
    termsAndConditionText: { color: colors.primary },
    termsAndConditionTextWrapper: { marginTop: 18 },
    closeModalText: {
      ...fontSizes[16],
      color: colors.gray1,
      marginBottom: 30,
      fontFamily: fontFamilies.semiBold,
    },
    closeModalButtonsWrapper: { flexDirection: 'row' },
    closeNoAnswerButton: { marginLeft: 24 },
    yesNoButtonMain: {
      ...fontSizes[16],
      width: 90,
      fontFamily: fontFamilies.semiBold,
    },
  });
};
