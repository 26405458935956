const SURVEY_STARTED = 'SURVEY_STARTED';
const SURVEY_NEXT_STEP_TRIGGERED = 'SURVEY_NEXT_STEP_TRIGGERED';
const SURVEY_PREVIOUS_STEP_TRIGGERED = 'SURVEY_PREVIOUS_STEP_TRIGGERED';
const SURVEY_COMPLETED = 'SURVEY_COMPLETED';
// which question/step the user gave up
const SURVEY_INTERRUPTED = 'SURVEY_INTERRUPTED';

export const surveyAnalyticsActions = {
  SURVEY_STARTED,
  SURVEY_COMPLETED,
  SURVEY_INTERRUPTED,
  SURVEY_NEXT_STEP_TRIGGERED,
  SURVEY_PREVIOUS_STEP_TRIGGERED,
};
