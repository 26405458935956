import React, { useState, useRef, useCallback } from 'react';

import { TextInput, View, TouchableOpacity, ViewProps, StyleProp, ViewStyle } from 'react-native';

import { AppFlatList } from 'components';
import InputLabel from 'components/InputLabel';
import { useOnClickOutside } from 'hooks';
import { DefaultText } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

import { styles, disabledStyles } from './styles';
import { AutoCompleteProps } from './types';

const webOutlineStyle = { outline: 'none' };
const DROPDOWN_TEXT_HEIGHT = 12 + 17; // margin + line height

export const AutocompleteInput = ({
  data,
  containerStyle,
  inputContainerStyle,
  listContainerStyle,
  onStartShouldSetResponderCapture,
  onChangeText,
  onItemSelect,
  labelText,
  value,
  disabled,
  loading,
  selectTestID,
  ...rest
}: AutoCompleteProps & ViewProps) => {
  const viewRef = useRef<View>(null);
  const [showResults, setShowResults] = useState(false);
  const outSideClickHandler = useCallback(() => setShowResults(false), []);
  //@ts-ignore
  useOnClickOutside(viewRef, outSideClickHandler);

  const handleSelectItem = (text: string, id: number) => {
    onChangeText?.(text);
    onItemSelect?.(id);
    setShowResults(false);
  };

  const handleTextChange = (text: string) => {
    setShowResults(!!text);
    onChangeText?.(text);
  };

  const handleShowSuggestionList = () => {
    if (disabled || !value) return;
    setShowResults(true);
  };

  return (
    <View ref={viewRef} style={[styles.container, containerStyle]}>
      <InputLabel labelText={labelText} />
      <View style={[styles.inputContainer, inputContainerStyle]}>
        <TextInput
          onFocus={handleShowSuggestionList}
          value={value || ''}
          onChangeText={handleTextChange}
          style={[
            styles.input,
            rest.style,
            !isNativeDevice && (webOutlineStyle as StyleProp<ViewStyle>),
            disabled && disabledStyles,
          ]}
          editable={!disabled}
          {...rest}
        />
      </View>
      {showResults && (
        <View
          style={[listContainerStyle, styles.list]}
          onStartShouldSetResponderCapture={onStartShouldSetResponderCapture}>
          {loading ? (
            <DefaultText style={styles.infoText}>Fetching...</DefaultText>
          ) : (
            <AppFlatList
              getItemLayout={(_, index) => ({
                index,
                length: DROPDOWN_TEXT_HEIGHT,
                offset: DROPDOWN_TEXT_HEIGHT * index,
              })}
              nestedScrollEnabled
              data={data}
              testID={selectTestID}
              keyExtractor={({ id }) => id.toString()}
              renderItem={({ item: { id, text } }) => (
                <TouchableOpacity onPress={() => handleSelectItem(text, id)} style={{ marginBottom: 12 }}>
                  <DefaultText numberOfLines={1}>{text}</DefaultText>
                </TouchableOpacity>
              )}
              ListEmptyComponent={
                <DefaultText style={styles.infoText}>
                  Looks like we don’t have that medication in our system.
                </DefaultText>
              }
            />
          )}
        </View>
      )}
    </View>
  );
};

export default AutocompleteInput;
