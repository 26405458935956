import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Home = (props: SvgProps) => (
  <Svg width={14} height={14} viewBox='0 0 14 14' fill='none' {...props}>
    <Path
      d='M1.75 5.25L7 1.167l5.25 4.083v6.417a1.167 1.167 0 01-1.167 1.166H2.917a1.167 1.167 0 01-1.167-1.166V5.25z'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M5.25 12.833V7h3.5v5.833'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Home;
