import React, { useEffect, useRef, useState } from 'react';

import { ImageSourcePropType, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { NotificationsInterface } from 'api';
import { ButtonLoader } from 'components/Loaders';
import { removeNotificationTileByType, setNotificationTiles } from 'store/actions';
import { TileTypesEnum } from 'store/reducers/user-info/types/notification';
import { getNotificationTiles } from 'store/selectors';
import { handlePromise } from 'utils/handlePromise';

import NotificationTile from '../NotificationTile';
import { getNotificationTileMapping } from './mappings';
import { styles } from './styles';
import TemplateImage from './TemplateImage';
import { Notification } from './types';

export const NotificationTiles = () => {
  const { isMobile, ...theme } = useTheme();
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationTiles);
  const fetchNotificationsRef = useRef<() => Promise<void>>();
  const [loading, setLoading] = useState(true);

  fetchNotificationsRef.current = async () => {
    const [response, error] = await handlePromise(NotificationsInterface.getNotificationTiles);
    error && console.error(`fetchNotifications error: ${error.message}`);
    response && dispatch(setNotificationTiles(response.data));
    setLoading(false);
  };

  useEffect(() => {
    fetchNotificationsRef.current?.();
  }, []);

  const notificationTileMapping = getNotificationTileMapping(theme, isMobile);

  const notificationCloseHandler = (id: number, type: TileTypesEnum) => {
    dispatch(removeNotificationTileByType(type));
    NotificationsInterface.setNotificationTileShown(id);
  };

  const notificationsJSX = notifications.map((notification: Notification) => {
    const { templateSrc, templateWrapperStyle, ...rest } = notificationTileMapping[notification.type];
    return (
      <NotificationTile
        key={notification.id}
        date={notification?.date}
        {...rest}
        onCloseHandler={() => notificationCloseHandler(notification.id, notification.type)}
        templateImageComponent={
          <TemplateImage imageWrapperStyle={templateWrapperStyle} src={templateSrc as ImageSourcePropType} />
        }
      />
    );
  });

  if (loading)
    return (
      <View style={styles.loaderWrapperStyle}>
        <ButtonLoader text='Fetching Notification Tiles...' />
      </View>
    );

  if (!notifications?.length) return null;

  return <View style={styles.tilesWrapper}>{notificationsJSX}</View>;
};

export default NotificationTiles;
