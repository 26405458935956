import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { FINANCIAL_ASSISTANCE_IMAGE } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';

export const useFinancialAssistanceStyles = ({ hasLink, hasImage }: { hasImage: boolean; hasLink: boolean }) => {
  const { fontSizes, fontFamilies, colors, ...theme } = useTheme();

  return StyleSheet.create({
    topContentMobileWrapper: applyBreakpoints(theme, {
      md: { marginBottom: hasImage ? FINANCIAL_ASSISTANCE_IMAGE.HEIGHT / 2 - 28 : 0 }, // Half of image + padding bottom
    }),
    headerTitle: applyBreakpoints(theme, {
      md: { marginTop: hasImage ? FINANCIAL_ASSISTANCE_IMAGE.HEIGHT / 2 + 26 : 0 }, // Half of image + margin top
    }),
    wrapper: {
      marginTop: 24,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
    },
    textWrapper: {
      flex: 1,
      marginRight: 42,
    },
    name: {
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
    },
    aboutTitle: {
      ...fontSizes['14'],
      marginTop: 24,
      color: colors.gray1,
    },
    aboutDescription: {
      ...fontSizes['14'],
      marginTop: 8,
    },
    mobileImageWrapper: {
      width: '100%',
      height: FINANCIAL_ASSISTANCE_IMAGE.HEIGHT,
      borderRadius: 16,
      overflow: 'hidden',
      position: 'absolute',
      top: -FINANCIAL_ASSISTANCE_IMAGE.HEIGHT / 2,
      ...applyBreakpoints(theme, {
        md: { display: hasImage ? 'flex' : 'none' },
        xxl: { display: 'none' },
      }),
    },
    webImageWrapper: {
      borderRadius: 16,
      overflow: 'hidden',
      display: hasImage ? 'flex' : 'none',
      ...applyBreakpoints(theme, {
        md: { display: 'none' },
        lg: { width: FINANCIAL_ASSISTANCE_IMAGE.WIDTH * 0.7, height: FINANCIAL_ASSISTANCE_IMAGE.HEIGHT * 0.7 },
        xl: { width: FINANCIAL_ASSISTANCE_IMAGE.WIDTH * 0.85, height: FINANCIAL_ASSISTANCE_IMAGE.HEIGHT * 0.85 },
        xxl: { width: FINANCIAL_ASSISTANCE_IMAGE.WIDTH, height: FINANCIAL_ASSISTANCE_IMAGE.HEIGHT },
      }),
    },
    image: {
      width: '100%',
      height: '100%',
    },
    linkButton: {
      display: hasLink ? 'flex' : 'none',
      flexDirection: 'row',
    },
    linkText: {
      ...fontSizes['14'],
      marginTop: 16,
      color: colors.primary,
    },
  });
};
