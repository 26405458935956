import React from 'react';

import Svg, { SvgProps, Circle } from 'react-native-svg';

type DotProps = {
  color?: string;
} & SvgProps;

const Dot = ({ color }: DotProps) => (
  <Svg width='8' height='8' viewBox='0 0 8 8' fill='none'>
    <Circle cx='4' cy='4' r='4' fill={color} />
  </Svg>
);

export default Dot;
