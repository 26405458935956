import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';

import { DefaultText } from 'style/common';

export const InstructionsWrapper = styled.View`
  padding: 0px 0px 24px;
`;

export const ParagraphTitle = styled(DefaultText)`
  line-height: 17px;
  color: ${(props) => props.theme.colors.gray1};
  margin-bottom: 9px;
`;

export const ParagraphDescription = styled(DefaultText)`
  line-height: 17px;
  color: ${(props) => props.theme.colors.gray2};
`;
