import { Dimensions, Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import styled, { css, DefaultTheme } from 'styled-components/native';

import { Grid } from 'components/Grid';
import { BaseCard, DefaultText, getFontStyles, StyledImage, webStyles } from 'style/common';
import { isAndroidDevice, isNativeDevice } from 'utils/helpers';

export const getStyles = ({
  isMobile,
  width,
  copayCardWidth,
}: {
  isMobile?: boolean;
  width: number;
  copayCardWidth: number;
}) =>
  StyleSheet.create({
    copayCard: {
      flex: 1,
      ...(!isAndroidDevice && { flexDirection: 'row', flexWrap: 'wrap-reverse' }),
      height: copayCardWidth * 0.6345,
      borderRadius: 32,
    },
    copayImageWrapper: {
      flex: 1,
      borderRadius: 32,
      // 0,6345 value the aspect ratio of the image.
      ...(isMobile && { height: (width - 64) * 0.6345 }),
      ...webStyles.button,
    },
    styledImage: {
      ...(!isAndroidDevice && { borderRadius: 32 }),
      backgroundColor: 'white',
    },
  });

export const getCopayStyles = ({ theme }: { theme: DefaultTheme }) =>
  StyleSheet.create({
    copayCardsWrapper: { marginVertical: 20 },
    headerWrapper: { marginBottom: 26 },
    standardText: {
      color: theme.colors.gray2,
      ...(Platform.OS === 'web' && { cursor: 'pointer', userSelect: 'none' }),
      paddingBottom: 5,
    },
    activeBorderText: {
      borderBottomWidth: 2,
      borderRadius: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.primary,
    },
    activeColorText: {
      color: theme.colors.primary,
      fontFamily: theme.fontFamilies.bold,
    },
    subTitle: { marginRight: 32 },
  });

export const mobileContentAreaStyle: StyleProp<ViewStyle> = {
  alignItems: 'center',
  height: 227,
  justifyContent: 'flex-start',
};

export const contentAreaStyle: StyleProp<ViewStyle> = {
  paddingBottom: 48,
};

export const FinancialAssistanceContainer = styled.View`
  ${({ theme }) =>
    theme.isMobile &&
    css`
      padding-top: 109px;
    `}
`;

export const HeaderText = styled(DefaultText)`
  ${getFontStyles(16)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.secondary};
  position: relative;
`;

export const InfoBlocksWrapper = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  padding-right: 50px;
  width: ${isNativeDevice ? Dimensions.get('window').width - 321 : 'calc(100% - 321px)'};
  max-width: ${isNativeDevice ? '100%' : '530px'};
  justify-content: space-between;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
      flex-wrap: wrap;
      margin-top: 18px;
      padding-right: 0px;
    `}
`;

export const RenewalDetailsWrapper = styled(Grid)`
  flex-direction: row;
  margin-bottom: 6px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column-reverse;
      margin-top: 0;
    `}
`;

export const RenewalCard = styled.View`
  width: 311px;
  align-self: flex-end;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.colors.gray2};
  padding: 22px 24px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      min-width: 100%;
    `}
`;

export const RenewalText = styled(DefaultText)`
  ${getFontStyles(16)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.gray1};
  margin-bottom: 7px;
`;

export const RenewalDateStyle = styled(DefaultText)`
  ${getFontStyles(16)};
  font-weight: 600;
  color: ${(props) => props.theme.colors.secondary};
`;

export const RenewalDescription = styled(DefaultText)`
  ${getFontStyles(16)};
  color: ${(props) => props.theme.colors.gray2};
`;

export const DeckWrapper = styled(BaseCard)`
  position: absolute;
  border-radius: 20px;
  top: -80px;
  right: 0;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      top: -116px;
      // 311px width of the image + 32px right/left padding --> 311px + 64px = 375px;
      // By doing this calculation copay card image will be accurately centered in mobile.
      right: ${(theme!.width! - 375) / 2}px;
    `}
`;

export const CopayImageWrapper = styled.View`
  width: 311px;
  height: 197px;
  border-radius: 20px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

export const LinkText = styled(DefaultText)`
  line-height: 17px;
  color: ${(props) => props.theme.colors.primary};
`;

export const CopayStyledImage = styled(StyledImage)`
  background-color: white;
`;

export const RemainingBlock = styled.View`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  ${(props) =>
    props.theme.isMobile &&
    css`
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;
    `}
`;

export const RemainingBlocksWrapper = styled.View<{ isCopayRenewal?: boolean; isPrescriptionRefill?: boolean }>`
  flex-direction: row;
  flex-wrap: wrap;
  width: ${({ isCopayRenewal, isPrescriptionRefill }) =>
    isCopayRenewal || isPrescriptionRefill ? 'calc(100% - 311px)' : '100%'};
  ${(props) =>
    props.theme.isMobile &&
    css`
      width: 100%;
    `}
`;

export const CopayRenewalCardWrapper = styled.View`
  width: 311px;
  flex: 1 1 100%;
  flex-direction: row;
  justify-content: ${isAndroidDevice ? 'space-around' : 'space-between'};
  height: ${!isNativeDevice ? 'fit-content' : 'auto'};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      width: 100%;
      margin-bottom: 27px;
    `}
`;
