import React from 'react';

import { ActivityIndicator, Text, View } from 'react-native';

import { useLoaderStyles } from './styles';

export type ButtonLoaderProps = {
  text: string;
};

export const ButtonLoader = ({ text }: ButtonLoaderProps) => {
  const styles = useLoaderStyles();

  return (
    <View style={styles.buttonLoaderWrapper}>
      <ActivityIndicator />
      <Text style={styles.loaderText}>{text}</Text>
    </View>
  );
};

export default ButtonLoader;
