export enum SurveyEnvEnum {
  TrialCard = 'TC',
  Cizplam = 'CZ',
}

export enum SurveyStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export enum QuestionTypeEnum {
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  RATING_SCALE = 'RATING_SCALE',
}

export type Answer = {
  uuid: string;
  text: string | number;
  is_checked: boolean;
};

export type Question = {
  uuid: string;
  question_type: QuestionTypeEnum;
  title: string;
  description?: string;
  order: number;
  is_answered: boolean;
  answers: Answer[];
};

export type Survey = {
  uuid: string;
  user_survey_uuid: string;
  title: string;
  subtext: string;
  logo_url: string;
  expired_at: string;
  environment: SurveyEnvEnum;
  status: SurveyStatusEnum | null;
  percent_of_complete: number;
  questions: string[];
};

export type UnnormalizedSurvey = Omit<Survey, 'questions'> & { questions: Question[] };

export type NormalizedSurveys = {
  entities: {
    surveys: Record<string, Survey>;
    questions: Record<string, Question>;
  };
  result: string[];
};
