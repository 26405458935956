export const title = 'Resources';
export const description = 'There are more ways we can help! Request and receive additional services.';

// google analytics constants
const VIEW_ADDITIONAL_SERVICE_PAGE = 'VIEW_ADDITIONAL_SERVICE_PAGE';
const VIEW_ADDITIONAL_SERVICE_RESOURCE = 'VIEW_ADDITIONAL_SERVICE_RESOURCE';

export const additionalServicesAnalyticsActions = {
  VIEW_ADDITIONAL_SERVICE_PAGE,
  VIEW_ADDITIONAL_SERVICE_RESOURCE,
};
