export const SET_MEDICATIONS = 'SET_MEDICATIONS';
export const SET_MEDICATION_TRACKER_DATA = 'SET_MEDICATION_TRACKER_DATA';
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_METADATA = 'SET_METADATA';
export const SET_MEDICATION_SELECT_OPTIONS = 'SET_MEDICATION_SELECT_OPTIONS';
export const SET_SELECTED_REMINDER_ID = 'SET_SELECTED_REMINDER_ID';
export const SET_SELECTED_MEDICATION_ID = 'SET_SELECTED_MEDICATION_ID';
export const SET_REMINDER_MODAL_VISIBILITY = 'SET_REMINDER_MODAL_VISIBILITY';
export const SET_MEDICATION_MODAL_VISIBILITY = 'SET_MEDICATION_MODAL_VISIBILITY';
export const SET_IS_MEDICATION_CABINET_LOADING = 'SET_IS_MEDICATION_CABINET_LOADING';
export const SET_PSP_REMINDER_TEMPLATE = 'SET_PSP_REMINDER_TEMPLATE';
export const SET_PSP_MEDICATION_HAS_BEEN_ADDED = 'SET_PSP_MEDICATION_HAS_BEEN_ADDED';
export const SET_PROGRAM_MEDICATIONS = 'SET_PROGRAM_MEDICATIONS';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_DRUG_INTERACTIONS = 'SET_DRUG_INTERACTIONS';
export const SET_ALL_DRUG_INTERACTIONS = 'SET_ALL_DRUG_INTERACTIONS';
export const SET_DRUG_INTERACTIONS_MODAL_VISIBILITY = 'SET_DRUG_INTERACTIONS_MODAL_VISIBILITY';
export const SET_MEDICATION_WIZARD_VISIBILITY = 'SET_MEDICATION_WIZARD_VISIBILITY';
export const SET_AS_NEEDED_MEDICATION_MODAL_VISIBILITY = 'SET_AS_NEEDED_MEDICATION_MODAL_VISIBILITY';
export const SET_WIZARD_STEP = 'SET_WIZARD_STEP';

export const SET_CABINET_NOTIFICATIONS = 'SET_CABINET_NOTIFICATIONS';
export const REMOVE_CABINET_NOTIFICATION = 'REMOVE_CABINET_NOTIFICATION';

export const SET_SELECTED_INTERACTION_ID = 'SET_SELECTED_INTERACTION_ID';
export const SET_PREVIOUS_DRUG_ID = 'SET_PREVIOUS_DRUG_ID';
