import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';

import { ImageEditor } from 'expo-image-editor';
import { useToast } from 'react-native-toast-notifications';

import { PhotoPickerProps } from 'components/PhotoPicker/PhotoPicker';
import { getImageFromGallery } from 'utils/getImageFromGallery';
import { AnyFunction } from 'utils/types';

import { getValidImageSize, isAllowedWebGalleryImageType, MAX_IMAGE_SIZE, notAllowedImageTypeMessage } from './utils';

export type WebGalleryProps = Pick<PhotoPickerProps, 'onImagePick'>;
export type WebGalleryRef = {
  openGallery: AnyFunction;
};

const WebGalleryComponent: ForwardRefRenderFunction<WebGalleryRef, WebGalleryProps> = ({ onImagePick }, ref) => {
  const [isCropperVisible, setCropperVisible] = useState(false);
  const [pickedImage, setPickedImage] = useState<string>();

  const toast = useToast();
  const onNotAllowedImageType = () => toast?.show(notAllowedImageTypeMessage, { type: 'danger' });

  const onPickImage = (image: string) => {
    setPickedImage(image);
    setCropperVisible(true);
  };

  const openGallery = async () => {
    const image = await getImageFromGallery();

    if (!image?.uri) return;

    if (isAllowedWebGalleryImageType(image.uri)) onPickImage(image.uri);
    else onNotAllowedImageType();
  };

  const onClose = () => {
    setCropperVisible(false);
    setPickedImage(undefined);
  };

  useImperativeHandle(ref, () => ({ openGallery }));

  return (
    <ImageEditor
      visible={isCropperVisible}
      onCloseEditor={onClose}
      imageUri={pickedImage}
      fixedCropAspectRatio={4 / 3}
      lockAspectRatio
      minimumCropDimensions={{
        width: 100,
        height: 50,
      }}
      allowedTransformOperations={['crop', 'rotate']}
      onEditingComplete={async (result) => onImagePick((await getValidImageSize(result?.uri, MAX_IMAGE_SIZE)) ?? '')}
    />
  );
};

export const WebGallery = forwardRef(WebGalleryComponent);
