import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { PROGRAM_LOGO_IMAGE } from 'style/sizes';
import { isNativeDevice } from 'utils/helpers';

export const useComponentStyles = (isUnbrandedImage?: boolean) => {
  const { colors } = useTheme();

  const imageSizeCoefficient = isUnbrandedImage ? 0.45 : 0.4;

  return StyleSheet.create({
    itemPadding: {
      paddingHorizontal: 24,
    },
    menuItem: {
      marginRight: 0,
      paddingVertical: 18,
      ...(!isNativeDevice && { cursor: 'pointer', userSelect: 'none' }),
    },
    programWrapper: { paddingLeft: 24 },
    brandEmblemWrapper: { marginVertical: 12, alignSelf: 'center' },
    brandEmblem: { width: 32, height: 32 },
    imageWrapper: {
      width: PROGRAM_LOGO_IMAGE.WIDTH * imageSizeCoefficient,
      height: PROGRAM_LOGO_IMAGE.HEIGHT * imageSizeCoefficient,
      paddingLeft: 24,
    },
    verticalHr: {
      borderColor: colors.gray3,
      borderBottomWidth: 1,
      marginVertical: 16,
    },
    redDotStyle: {
      position: 'absolute',
      top: -6,
      right: -6,
      height: 8,
      width: 8,
      backgroundColor: colors.negative,
      borderRadius: 4,
    },
  });
};
