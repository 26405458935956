import { RemoveUserInsurance } from 'store/actions/user/actionCreators/insurances/removeUserInsurance';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const removeUserInsurance = (state = INITIAL_STATE, payload: ReturnType<RemoveUserInsurance>['payload']): User => {
  const insuranceToBeUpdated = state.insurances.find((insurance) =>
    insurance.id ? insurance.id === payload : insurance._id === payload
  );

  const filteredInsurances = state.insurances.filter((insurance) =>
    insurance.id ? insurance.id !== insuranceToBeUpdated?.id : insurance._id !== insuranceToBeUpdated?._id
  );

  return { ...state, insurances: filteredInsurances };
};

export default removeUserInsurance;
