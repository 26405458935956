import React from 'react';

import { View, ViewProps } from 'react-native';

import { DashDayMark, GrayDayMark, GreenDayMark, RedDayMark } from 'assets/icons';
import { DefaultText } from 'style/common';

export const indicatorStyle = { width: 16, height: 16, alignItems: 'center' } as ViewProps;

const legendList = [
  { Icon: GreenDayMark, text: 'Adherent' },
  { Icon: RedDayMark, text: 'Not Adherent' },
  { Icon: GrayDayMark, text: 'Not Started' },
  { Icon: DashDayMark, text: 'No Reminders' },
];

export const MedAdherenceLegend = () => (
  <View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
    {legendList.map((item, index) => (
      <View
        key={index}
        style={{ flexDirection: 'row', marginBottom: index !== legendList.length - 1 ? 10 : undefined }}>
        <item.Icon style={indicatorStyle} />
        <View style={{ marginLeft: 10 }}>
          <DefaultText>{item.text}</DefaultText>
        </View>
      </View>
    ))}
  </View>
);

export default MedAdherenceLegend;
