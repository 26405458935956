import { useState, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AdditionalServicesInterface } from 'api';
import { setInsuranceTileInfo } from 'store/actions/cards';
import { getInsuranceTileInfo } from 'store/selectors/home-info-cards';
import { makeRequestWithLoader } from 'utils/requests';

export const useInsuranceNotification = () => {
  const [isLoading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const notification = useSelector(getInsuranceTileInfo);

  useEffect(() => {
    makeRequestWithLoader(
      () => AdditionalServicesInterface.getNotifications({ purpose: 'MISSING_INSURANCE_NOTIFICATION_TILE' }),
      setLoading
    ).then((res) => {
      const data = res.data?.[0]; // Will be only one notification
      if (!data || data.is_read || !data.id) return;

      dispatch(
        setInsuranceTileInfo({
          id: data.id,
          title: data.communication?.communication_template?.subject || '',
          description: data.communication?.communication_template?.template || '',
        })
      );
    });
  }, [dispatch]);

  const clearNotification = useCallback(() => {
    notification?.id && AdditionalServicesInterface.clearNotifications([notification.id]);
  }, [notification?.id]);

  return {
    isLoading,
    notification,
    clearNotification,
  };
};
