export const SET_USER_AUTHORIZED = 'SET_USER_AUTHORIZED';
export const SET_BASIC_USER_INFORMATION = 'SET_BASIC_USER_INFORMATION';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const RESET_USER_INFO = 'RESET_USER_INFO';
export const RESET_USER_MFA_INFO = 'RESET_USER_MFA_INFO';
export const RESET_USER_DEMO_INFO = 'RESET_USER_DEMO_INFO';
export const SET_USER_COGNITO_ID = 'SET_USER_COGNITO_ID';

export const SET_INITIAL_MODAL_STATES = 'SET_INITIAL_MODAL_STATES';
export const SET_SHOULD_PROPOSE_TUTORIAL = 'SET_SHOULD_PROPOSE_TUTORIAL';
export const SET_IS_FORGOT_PASSWORD_MODAL_VISIBLE = 'SET_IS_FORGOT_PASSWORD_MODAL_VISIBLE';
export const SET_SESSION_EXPIRED_MODAL_VISIBLE = 'SET_SESSION_EXPIRED_MODAL_VISIBLE';
export const SET_IS_ACTIVATE_ACCOUNT_MODAL_VISIBLE = 'SET_IS_ACTIVATE_ACCOUNT_MODAL_VISIBLE';

export const SET_WEB_DRAWER_OPEN = 'SET_WEB_DRAWER_OPEN';

export const SET_SELECTED_COPAY_CARD = 'SET_SELECTED_COPAY_CARD';
export const SET_SHOW_EVENT_NOTIFICATION = 'SET_SHOW_EVENT_NOTIFICATION';
