import { useState, useMemo, useCallback, useRef, useEffect } from 'react';

import { useIsFocused } from '@react-navigation/native';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

import { MedicineCabinetInterface } from 'api/MedicineCabinetInterface';
import { ComplianceData as ComplianceDataDTO } from 'api/MedicineCabinetInterface/types';
import { useIsMountedRef } from 'hooks';
import { setIsMedicationCabinetLoading, setMedTrackerData } from 'store/actions';
import { ComplianceData } from 'store/reducers/medicine-cabinet/types';
import { handlePromise } from 'utils/handlePromise';
import { changeFieldNameInObject } from 'utils/helpers';
import { getQueryParams } from 'utils/requests';

type GetMedTrackerDataProps = {
  withLoading?: boolean;
};

export const useMedTrackerData = () => {
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const isMountedRef = useIsMountedRef();
  const isFirstLoader = useRef(true);

  const [requestedDate, setRequestedDate] = useState(Date.now());
  const [medicationId, setMedicationId] = useState(0);

  const getMedTrackerData = useCallback(
    async ({ withLoading }: GetMedTrackerDataProps | undefined = {}) => {
      if (!isMountedRef.current || !requestedDate) return;

      withLoading && dispatch(setIsMedicationCabinetLoading(true));

      const [medTrackerData] = await handlePromise(() =>
        MedicineCabinetInterface.getMedicationTracker(
          getQueryParams({
            requestedDate: format(requestedDate, 'MM/dd/yyyy'),
            medicationId: medicationId, // 0 - id of all type
          })
        )
      );

      if (medTrackerData?.data) {
        const mappedCompliance = medTrackerData.data.compliance.map((item) =>
          changeFieldNameInObject<ComplianceDataDTO, ComplianceData>(item, 'trackerStatus', 'status')
        );

        dispatch(
          setMedTrackerData({
            ...medTrackerData.data,
            compliance: mappedCompliance,
          })
        );
      }

      withLoading && dispatch(setIsMedicationCabinetLoading(false));
    },
    [isMountedRef, requestedDate, dispatch, medicationId]
  );

  useEffect(() => {
    if (!isFocused) return;

    getMedTrackerData({ withLoading: isFirstLoader.current });
    isFirstLoader.current = false;
  }, [getMedTrackerData, isFocused]);

  return useMemo(
    () => ({
      setRequestedDate,
      setMedicationId,
      getMedTrackerData,
    }),
    [getMedTrackerData]
  );
};

export default useMedTrackerData;
