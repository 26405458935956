import React from 'react';

import { View, StyleProp, ViewStyle } from 'react-native';

import { progressBarItemStyle } from 'components/ProgressSteps/styles';

type Props = {
  backgroundColor: string;
  containerStyle?: StyleProp<ViewStyle>;
};

export const ProgressBarItem = ({ backgroundColor, containerStyle }: Props) => (
  <View style={[progressBarItemStyle, containerStyle, { backgroundColor }]} />
);
