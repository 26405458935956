import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const GreenDayMark = (props: SvgProps) => (
  <Svg width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M0 8C0 3.58172 3.58172 0 8 0V0C12.4183 0 16 3.58172 16 8V8C16 12.4183 12.4183 16 8 16V16C3.58172 16 0 12.4183 0 8V8Z'
      fill={defaultTheme.colors.positive}
    />
    <Path
      d='M4.57227 8.00021L7.23893 10.8574L11.4294 5.14307'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default GreenDayMark;
