import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

const ThumbsUp = (props: SvgProps) => (
  <Svg width={16} height={16} fill='none' {...props}>
    <Path
      d='m4.392 7.267 2.98-6.6c.593 0 1.162.232 1.581.644.42.413.655.972.655 1.556V5.8h4.217a1.51 1.51 0 0 1 1.142.504 1.46 1.46 0 0 1 .349 1.183l-1.029 6.6a1.46 1.46 0 0 1-.508.897c-.274.23-.622.353-.982.35H4.392m0-8.067v8.066m0-8.066H2.156c-.395 0-.774.154-1.053.43-.28.274-.437.647-.437 1.036v5.134c0 .389.157.762.437 1.037.279.275.658.43 1.053.43h2.236'
      stroke='#fff'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default ThumbsUp;
