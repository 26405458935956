import React from 'react';

import { View } from 'react-native';
import { DateData, DayState } from 'react-native-calendars';

import { dayMarks } from 'components/Calendar/constants';
import { MedicationTrackerData } from 'store/reducers/medicine-cabinet/types';
import { DefaultText } from 'style/common';

import { indicatorStyle } from './styles';

type AppliedIndicatorProps = Partial<{
  date: DateData;
  state: DayState;
  medTrackerInfo: MedicationTrackerData;
  isSelectable: boolean;
}>;

export const AppliedIndicator = ({ date, state, isSelectable, medTrackerInfo }: AppliedIndicatorProps) => {
  const shouldShowMedTracker = !!isSelectable && !!medTrackerInfo;

  const currentDateMedInfo = shouldShowMedTracker
    ? medTrackerInfo?.compliance?.find((info) => info.date && new Date(info.date).getTime() === date?.timestamp)
    : undefined;

  const mark = currentDateMedInfo?.status && dayMarks[currentDateMedInfo?.status];

  const markJSX = mark || (state !== 'disabled' && <DefaultText>_</DefaultText>);

  return <View style={indicatorStyle}>{markJSX}</View>;
};
