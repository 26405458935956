import React from 'react';

import ContentLoader, { Rect } from 'react-content-loader/native';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

const SkeletonForInsuranceCard = (props: SvgProps) => {
  const theme = useTheme();

  return (
    <ContentLoader
      speed={2}
      width={353}
      height={280}
      viewBox='0 0 353 328'
      foregroundColor={theme.colors.gray4}
      backgroundColor={theme.colors.gray3}
      {...props}>
      <Rect x='0' y='11' rx='3' ry='3' width='148' height='11' />
      <Rect x='0' y='38' rx='3' ry='3' width='70' height='10' />
      <Rect x='553' y='103' rx='0' ry='0' width='108' height='84' />
      <Rect x='122' y='111' rx='0' ry='0' width='0' height='1' />
      <Rect x='86' y='109' rx='0' ry='0' width='0' height='1' />
      <Rect x='84' y='259' rx='0' ry='0' width='0' height='2' />
      <Rect x='0' y='65' rx='10' ry='10' width='151' height='84' />
      <Rect x='176' y='66' rx='10' ry='10' width='121' height='48' />
      <Rect x='0' y='210' rx='10' ry='10' width='151' height='84' />
      <Rect x='0' y='185' rx='3' ry='3' width='56' height='10' />
      <Rect x='176' y='210' rx='10' ry='10' width='121' height='48' />
    </ContentLoader>
  );
};

export default SkeletonForInsuranceCard;
