import React, { Dispatch, MutableRefObject, SetStateAction, useRef } from 'react';

import throttle from 'lodash.throttle';
import { ViewStyle } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';

import { ModalComponent as Modal } from 'components/Modal';
import Questionnaire from 'components/Questionnaire';
import { Action } from 'store/reducers/additional-services/types';
import { modalContentStyle } from 'style/common';

type QuestionnaireModalProps = {
  modalVisible: boolean;
  selectedActionId: number;
  selectedAction: Action | undefined;
  consentRequired?: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  handleAction: (id: number, selectedAnswers: number[], userDataUuid: string) => void;
};

const QuestionnaireModal = ({
  modalVisible,
  selectedActionId,
  selectedAction,
  setModalVisible,
  handleAction,
}: QuestionnaireModalProps) => {
  const toastRef: MutableRefObject<ToastContainer | null> = useRef(null);

  const throttledToastHide = throttle(() => {
    toastRef.current?.hideAll();
  }, 400);

  const actionHandler = (id: number, isValid: boolean, selectedAnswers: number[]) => {
    if (isValid) {
      handleAction(id, selectedAnswers, selectedAction?.userDataUuid || '');
      return;
    }
    throttledToastHide();
    toastRef.current?.show('Please select at least one suitable time.', { type: 'warning' });
  };

  return (
    <Modal
      toastRef={toastRef}
      setModalVisible={setModalVisible}
      modalIsVisible={modalVisible}
      modalContentStyle={modalContentStyle as ViewStyle}
      useContentWrapper>
      <Questionnaire
        id={selectedActionId}
        onScheduleCompleted={actionHandler}
        setModalVisible={setModalVisible}
        contentTitle={selectedAction?.modalTitle}
        contentDescription={selectedAction?.modalDescription}
        questionText={selectedAction?.question}
        answers={selectedAction?.answers}
        hasConsent={selectedAction?.hasConsent}
        serviceName={selectedAction?.label}
        selectionType={selectedAction?.selectionType}
      />
    </Modal>
  );
};

export default QuestionnaireModal;
