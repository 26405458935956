import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

function Warning(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
      <Path d='M12 1v14' stroke={props.stroke || '#000'} strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' />
      <Path
        d='M12 21h.01'
        stroke={props.stroke || '#000'}
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
}

export default Warning;
