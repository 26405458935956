import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';

export const useInsuranceCardStyles = () => {
  const { colors, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    wrapper: {
      backgroundColor: colors.white,
      borderColor: colors.gray2,
      borderWidth: 1,
      borderRadius: 16,
      padding: 24,
      ...applyBreakpoints(theme, {
        lg: {},
        xxl: { flex: 0.5 },
      }),
    },
    title: {
      fontFamily: fontFamilies.semiBold,
      color: colors.gray1,
      marginBottom: 16,
    },
    informationField: { width: 'auto' },
  });
};
