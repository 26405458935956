import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { ServiceFileTypesEnum } from 'store/reducers/additional-services/types';
import { MOBILE_HORIZONTAL_PADDING, MODAL_PADDING } from 'style/sizes';

export const useDigitalResourceStyles = (resourceType: ServiceFileTypesEnum) => {
  const { colors } = useTheme();

  const isPDF = resourceType === ServiceFileTypesEnum.PDF;

  return StyleSheet.create({
    headerWrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    title: { marginBottom: 12 },
    mobileWrapper: {
      width: '100%',
      height: '100%',
      backgroundColor: colors.gray1,
    },
    mobileContentWrapper: {
      flex: 1,
      paddingBottom: MODAL_PADDING,
    },
    mobileContent: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      ...(isPDF && {
        backgroundColor: colors.gray3,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
      }),
    },
    mobileHeaderWrapper: {
      padding: MOBILE_HORIZONTAL_PADDING / 2,
      alignItems: 'flex-end',
    },
    mobileCloseButton: {
      padding: MOBILE_HORIZONTAL_PADDING / 2,
    },
  });
};
