import componentsRefs from 'utils/componentsRefs';

export const scrollToCurrentDay = (deviceWidth: number) => {
  const scroll = componentsRefs.getRef('scroll');
  const currentDay = componentsRefs.getRef('day');

  if (scroll && currentDay) {
    currentDay.measureLayout(scroll, (x: number, y: number, width: number, height: number) => {
      scroll.scrollTo({ x: x - (deviceWidth - width) / 2, animated: true });
    });
  }
};
