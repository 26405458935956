import React from 'react';

import { View, ViewProps } from 'react-native';

import { Spacer } from 'components/Spacer';
import { defaultTheme } from 'style/theme';

import { useTourProgressBarStyle } from './style';

type TourProgressBarProps = {
  active?: boolean;
  index: number;
};

export const TourProgressBar = ({ active, index, ...rest }: TourProgressBarProps & ViewProps) => {
  const styles = useTourProgressBarStyle();

  return (
    <>
      {!!index && <Spacer size={8} orientation='horizontal' />}
      <View style={[styles.progressBar, !active && { backgroundColor: defaultTheme.colors.gray3 }]} {...rest} />
    </>
  );
};
