import React, { useRef } from 'react';

import { View, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { MenuRightArrow } from 'assets/icons';
import { animated, AnimatedWrapper, useSpring } from 'components/Animated/Animated.native';
import { setWebDrawerOpen } from 'store/actions';
import { getWebDrawerOpenStatus } from 'store/selectors';
import { CLOSE_LEFT_SIDE_MENU_WIDTH, OPEN_RIGHT_SIDE_MENU_WIDTH } from 'style/sizes';

import { MenuHeader, MenuItems } from './components';
import { getLeftSideDrawerStyles } from './styles';

const AnimatedPressable = animated(Pressable);

export const LeftSideDrawer = () => {
  const drawerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const isDrawerOpen = useSelector(getWebDrawerOpenStatus);

  const theme = useTheme();
  const dispatch = useDispatch();

  const styles = getLeftSideDrawerStyles(theme);
  const onRightArrowPress = () => dispatch(setWebDrawerOpen(!isDrawerOpen));

  const animatedProps = useSpring({ width: isDrawerOpen ? OPEN_RIGHT_SIDE_MENU_WIDTH : CLOSE_LEFT_SIDE_MENU_WIDTH });
  const rotateProps = useSpring({ rotate: isDrawerOpen ? '180deg' : '0deg' });

  return (
    <AnimatedWrapper ref={drawerRef} style={[styles.drawerContainer, animatedProps]}>
      <View style={styles.menuSectionWrapper}>
        <MenuHeader />
        <MenuItems />
      </View>
      <AnimatedPressable
        style={[styles.expandToolBarWrapper, { transform: [{ rotate: rotateProps.rotate }] }]}
        onPress={onRightArrowPress}>
        <MenuRightArrow />
      </AnimatedPressable>
    </AnimatedWrapper>
  );
};

export default LeftSideDrawer;
