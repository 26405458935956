import React from 'react';

import { View, ActivityIndicator, StyleSheet, ViewProps } from 'react-native';

export const CabinetLoading = (props: ViewProps) => {
  return (
    <View {...props} style={[styles.loadingWrapper, props.style]}>
      <ActivityIndicator size='large' />
    </View>
  );
};

export default CabinetLoading;

const styles = StyleSheet.create({
  loadingWrapper: { flex: 1, justifyContent: 'center', alignItems: 'center' },
});
