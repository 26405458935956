import React, { ReactNode } from 'react';

import { Platform, TouchableHighlight, TouchableOpacity, ViewStyle, ImageStyle } from 'react-native';

import { commonStyles } from 'style/common';
import { AnyFunction } from 'utils/types';

export const ImageWrapper = ({
  styles,
  clickHandler,
  children,
}: {
  styles: ViewStyle | ImageStyle;
  clickHandler: AnyFunction;
  children: ReactNode;
}) => {
  const imageWrapperStyle = [styles, commonStyles.boxShadowStyle];
  return Platform.OS === 'android' ? (
    <TouchableHighlight
      style={{
        ...commonStyles.boxShadowStyle,
        ...{
          position: 'relative',
          backgroundColor: '#fff',
          borderRadius: 32,
          overflow: 'hidden',
        },
      }}
      underlayColor='#fff'
      onPress={clickHandler}>
      {children}
    </TouchableHighlight>
  ) : (
    <TouchableOpacity style={imageWrapperStyle} onPress={clickHandler}>
      {children}
    </TouchableOpacity>
  );
};

export default ImageWrapper;
