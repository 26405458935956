import React from 'react';

import { View } from 'react-native';

import { Button } from 'components';
import { ModalComponent as Modal } from 'components/Modal';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import {
  medicationCabinetAnalyticsHandler,
  medicationDetailsAnalyticsHandler,
} from 'screens/main/MedicineCabinet/helpers';
import { MedCabinetWidgetType } from 'screens/main/MedicineCabinet/types';
import { DefaultText } from 'style/common';

import { TEXTS, deleteModalAnalyticsConstants } from './constants';
import { getStyles } from './styles';

type DeleteModalProps = {
  deleteModalIsVisible: boolean;
  setDeleteModalVisibility: (visible: boolean) => void;
  setWidgetModalVisibility: (visible: boolean) => void;
  deleteHandler: () => Promise<void>;
  itemNameToBeDeleted: MedCabinetWidgetType;
  overlayColor?: string;
  cancelTestID?: string;
  confirmTestID?: string;
};

const { DELETE_MEDICATION_SUCCESS, DELETE_REMINDER_SUCCESS } = deleteModalAnalyticsConstants;

export const DeleteModal = ({
  deleteModalIsVisible,
  setDeleteModalVisibility,
  setWidgetModalVisibility,
  deleteHandler,
  itemNameToBeDeleted,
  overlayColor,
  cancelTestID,
  confirmTestID,
}: DeleteModalProps) => {
  const { isMobile } = useDeviceInfoContext();
  const styles = getStyles(isMobile);

  const isMedication = itemNameToBeDeleted === MedCabinetWidgetType.MEDICATION;
  const analyticsHandler = isMedication ? medicationCabinetAnalyticsHandler : medicationDetailsAnalyticsHandler;

  const cancelHandler = () => {
    setDeleteModalVisibility(false);
    setWidgetModalVisibility(true);
  };

  const handleDelete = async () => {
    await deleteHandler();
    const eventName = isMedication ? DELETE_MEDICATION_SUCCESS : DELETE_REMINDER_SUCCESS;
    analyticsHandler(eventName);
    setDeleteModalVisibility(false);
  };

  const title = TEXTS.TITLE.replace('{0}', itemNameToBeDeleted);
  const description = TEXTS.DESCRIPTION.replace('{0}', itemNameToBeDeleted);

  return (
    <Modal
      overlayColor={overlayColor}
      modalIsVisible={deleteModalIsVisible}
      closeButtonStyle={styles.closeModalButton}
      setModalVisible={setDeleteModalVisibility}
      onRequestClose={cancelHandler}>
      <View style={styles.deleteContentWrapper}>
        <DefaultText style={styles.deleteTitle}>{title}</DefaultText>
        <DefaultText style={styles.deleteDescription}>{description}</DefaultText>
        <View style={styles.actionButtonsWrapper}>
          <Button
            testID={cancelTestID}
            variant='tertiary'
            text={TEXTS.CANCEL}
            style={styles.cancelButton}
            onPress={cancelHandler}
          />
          <Button
            testID={confirmTestID}
            variant='mango'
            text={TEXTS.DELETE}
            style={styles.deleteButton}
            onPress={handleDelete}
          />
        </View>
      </View>
    </Modal>
  );
};
