import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { getTourStatus, getWebDrawerOpenStatus } from 'store/selectors';
import { CLOSE_LEFT_SIDE_MENU_WIDTH, OPEN_RIGHT_SIDE_MENU_WIDTH, TUTORIAL_MODAL_MAX_WIDTH } from 'style/sizes';

export const useTourModalStyles = (isWebTour = false) => {
  const isDrawerOpen = useSelector(getWebDrawerOpenStatus);
  const isTourActive = useSelector(getTourStatus);
  const { isMobile, width } = useDeviceInfoContext();

  const isMobileTourActive = isTourActive && isMobile && !isWebTour;

  const getMenuWidth = () => {
    if (!isTourActive || isMobile) return 0;
    return isDrawerOpen ? OPEN_RIGHT_SIDE_MENU_WIDTH : CLOSE_LEFT_SIDE_MENU_WIDTH;
  };

  return {
    styles: StyleSheet.create({
      centeredViewStyle: {
        justifyContent: isMobileTourActive ? 'flex-end' : 'center',
        alignItems: 'center',
        backgroundColor: isMobileTourActive ? 'transparent' : 'rgba(0, 0, 0, 0.7)',
        left: getMenuWidth(),
        width: width - getMenuWidth(),
      },
      contentStyle: {
        padding: 0,
        shadowRadius: 16,
        width: TUTORIAL_MODAL_MAX_WIDTH,
        ...(isMobileTourActive && {
          width: '100%',
          maxWidth: '100%',
          marginBottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
          borderBottomEndRadius: 0,
          borderBottomStartRadius: 0,
        }),
        ...(isTourActive && {
          right: getMenuWidth() / 2,
        }),
      },
    }),
    isMobileTourActive,
  };
};
