import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const getStyles = (isMobile?: boolean) =>
  StyleSheet.create({
    sliderWrapper: { minHeight: isMobile ? 100 : 120, justifyContent: 'center' },
    divisionsWrapper: { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 5 },
    division: { marginBottom: 10 },
    slider: { flexGrow: 0 },
  });
