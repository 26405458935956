import { Pressable } from 'react-native';
import styled from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';

export const ForwardText = styled(DefaultText)`
  ${getFontStyles(16)};
  color: #000;
  text-align: left;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 7px;
  max-width: 224px;
`;

export const FastForwardCardContainer = styled(Pressable)`
  background-color: ${(props) => props.theme.colors.secondary};
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 24px;
  & :hover {
    box-shadow: 0 2px 16px rgba(255, 255, 255, 1);
  }
`;

export const FastForwardHeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;
