import { UserAddress } from './types';

export const TEXTS = {
  title: 'Confirm your shipping address',
  description: 'Would you like to use this address as your shipping address?',
  toast: 'Your shipment request has been completed.',
  edit: 'Edit',
  set: 'Set',
};

export const REQUIRED_ADDRESS_FIELDS: (keyof UserAddress)[] = ['addressLine1', 'zipCode', 'state', 'city'];
