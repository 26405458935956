import React from 'react';

import ContentLoader from 'react-content-loader/native';
import { Circle, Rect } from 'react-native-svg';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { isNativeDevice } from 'utils/helpers';

const InteractionListItemPlaceHolder = () => {
  const { width } = useDeviceInfoContext();

  const contentWidth = isNativeDevice ? width - 64 : '100%';

  return (
    <ContentLoader width={contentWidth} height={60}>
      <Circle cx='20' cy='30' r='16' />
      <Rect x='60' y='20' rx='8' ry='8' width='15%' height='20' />
      <Circle cx='92%' cy='30' r='16' />
      <Rect x='0' y='58' rx='8' ry='8' width='100%' height='2' />
    </ContentLoader>
  );
};

export const InteractionListItemsPlaceHolder = () => {
  return (
    <>
      {new Array(9).fill(null).map((_, index: number) => (
        <InteractionListItemPlaceHolder key={index} />
      ))}
    </>
  );
};

export default InteractionListItemsPlaceHolder;
