import React from 'react';

import { View, Text, ViewStyle, StyleProp } from 'react-native';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { Button } from 'components';
import { MedCabinetWidgetType } from 'screens/main/MedicineCabinet/types';
import { setSelectedMedicationId, setMedicationWizardStep, setMedicationWizardVisibility } from 'store/actions';
import { TEST_ID } from 'utils/testIds/constants';
import { AnyFunction } from 'utils/types';

import { useCabinetEmptyStyles } from './styles';

export type CabinetEmptyProps = {
  infoText: string;
  Icon: React.ElementType;
  actionType?: MedCabinetWidgetType;
  actionButtonText?: string;
  containerStyle?: StyleProp<ViewStyle>;
  onPress?: AnyFunction;
  isMedicationTracker?: boolean;
};

export const CabinetEmpty = ({
  infoText,
  Icon,
  actionType = MedCabinetWidgetType.MEDICATION,
  actionButtonText,
  containerStyle,
  onPress,
  isMedicationTracker,
}: CabinetEmptyProps) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();

  const styles = useCabinetEmptyStyles(actionType, isMedicationTracker);

  const handleAddMedication = async () => {
    await Promise.resolve(dispatch(setSelectedMedicationId(null)));
    dispatch(setMedicationWizardStep(0));
    dispatch(setMedicationWizardVisibility(true));
  };

  const handlePress = () => (onPress ? onPress() : handleAddMedication());

  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.infoTextAndIconWrapper}>
        <View style={styles.infoIconWrapper}>
          <Icon width={24} height={24} stroke={colors.gray1} />
        </View>
        <Text style={styles.infoText}>{infoText}</Text>
      </View>
      {!!actionButtonText && (
        <Button
          style={styles.actionButton}
          testID={TEST_ID.MEDICATION_LIST_ADD_NEW_MEDICATION_BUTTON}
          variant='mango'
          text={actionButtonText}
          onPress={handlePress}
        />
      )}
    </View>
  );
};

export default CabinetEmpty;
