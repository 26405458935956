import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const MedTracker = (props: SvgProps) => (
  <Svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      d='M9 10.7L12.2308 14L23 3'
      stroke={props.stroke || defaultTheme.colors.gray2}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M23 12V20.5556C23 21.2039 22.7425 21.8256 22.284 22.284C21.8256 22.7425 21.2039 23 20.5556 23H3.44444C2.79614 23 2.17438 22.7425 1.71596 22.284C1.25754 21.8256 1 21.2039 1 20.5556V3.44444C1 2.79614 1.25754 2.17438 1.71596 1.71596C2.17438 1.25754 2.79614 1 3.44444 1H16.8889'
      stroke={props.stroke || defaultTheme.colors.gray2}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default MedTracker;
