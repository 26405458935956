import styled, { css } from 'styled-components/native';

export const DetailInformationContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DetailsBlock = styled.View<{ isEditMode?: boolean }>`
  margin-bottom: 24px;
  width: 48%;
  ${({ theme, isEditMode }) =>
    theme.isMobile &&
    css`
      width: ${isEditMode ? '100%' : '48%'};
    `}
`;

export const SectionTitleBlock = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;
