import styled, { css } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';

export const ActionText = styled(DefaultText)<{ done?: boolean }>`
  ${getFontStyles(16)};
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  color: ${(props) => (props.done ? props.theme.colors.gray2 : props.theme.colors.gray1)};
  margin-left: 18px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      max-width: 70%;
    `}
`;

export const DoneBoxContainer = styled.View<{ color?: string }>`
  padding: 4px 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: ${(props) => props.color || props.theme.colors.secondary};
`;

export const DoneText = styled(ActionText)`
  color: #fff;
  margin-left: 0;
`;

export const DoneCheckIconWrapper = styled.View`
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    !theme.isMobile &&
    css`
      margin-right: 8px;
    `}
`;
