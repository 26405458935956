import { ThunkDispatch } from 'redux-thunk';

import { FinancialAssistanceInterface } from 'api';
import { copayCardMetricsMapper } from 'api/CopayCardInterface/mappers';
import { CopayCardInfo, CopayCardType } from 'api/CopayCardInterface/types';
import { FinancialAssistanceMetrics } from 'api/FinancialAssistanceInterface';
import { setBasicUserInformation } from 'store/actions/user/actionCreators/setBasicUserInformation';
import { handlePromise } from 'utils/handlePromise';

import { setSelectedCopayCard } from '../setSelectedCopayCard';

export const initFinancialAssistanceData = async (
  dispatch: ThunkDispatch<unknown, unknown, ReturnType<typeof setBasicUserInformation | typeof setSelectedCopayCard>>
) => {
  const [fapResponse] = await handlePromise(FinancialAssistanceInterface.get);
  const fapData = fapResponse?.data;

  if (!fapData) return;

  const [copayMetricsResponse] = fapData.type === 'COPAY' ? await handlePromise(FinancialAssistanceMetrics.get) : [];

  const fapMedicalMetrics = copayCardMetricsMapper({
    fapData,
    fapMetrics: copayMetricsResponse?.data?.results.find((res) => res.cardType === ('MEDICAL' as CopayCardType)),
  });
  const fapPharmacyMetrics = copayCardMetricsMapper({
    fapData,
    fapMetrics: copayMetricsResponse?.data?.results.find((res) => res.cardType === ('PHARMACY' as CopayCardType)),
  });

  const selectedMedicalCard = fapMedicalMetrics.type === 'MEDICAL' ? fapMedicalMetrics : undefined;
  const selectedPharmacyCard = fapPharmacyMetrics.type === 'PHARMACY' ? fapPharmacyMetrics : undefined;

  dispatch(
    setBasicUserInformation({
      financialAssistanceData: fapData,
      ...(fapData.type === 'COPAY' && {
        copayCards: [fapMedicalMetrics, fapPharmacyMetrics] as CopayCardInfo[],
        selectedCopayCard: selectedMedicalCard || selectedPharmacyCard,
      }),
    })
  );
};
