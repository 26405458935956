import React, { Component } from 'react';
import { View, ScrollView } from 'react-native';

 export class MonthWithScrollWrapper extends Component {
    constructor(props) {
        super(props);
      }
      componentWillUnmount() {
        this.props.componentsRefs.remove('scroll')
    }
    render() {
        return (
            <View style={{flex: 1}}>
                <ScrollView ref={this.props.componentsRefs.setRef('scroll')} showsHorizontalScrollIndicator={false} horizontal={true} style={{paddingHorizontal: 20, paddingVertical: 15, marginHorizontal: -30, marginTop: 50}}>
                    <View style={this.props.monthViewStyle}>{this.props.weeks}</View>
                </ScrollView>
            </View>
            )
        }
}