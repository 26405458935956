import React, { useState, useEffect } from 'react';

import { View, TouchableOpacity, Animated } from 'react-native';

import { StandardText } from 'style/common';

import { useHorizontalButtonsStyles, HORIZONTAL_BUTTON_PADDING } from './styles';
import { HorizontalButtonsProps, HorizontalButtonProps } from './types';

const overlayLeftPosition = new Animated.Value(HORIZONTAL_BUTTON_PADDING);

export const HorizontalButtons = <T extends string>({
  buttons,
  buttonWidth, // need for animation
  containerStyle,
  onChange,
  value,
  initialId,
  testID,
}: HorizontalButtonsProps<T>) => {
  const [selectedId, setSelectedId] = useState<T | undefined>(initialId || value || buttons[0]?.id);

  useEffect(() => {
    value !== undefined && setSelectedId(value);
  }, [value]);

  const styles = useHorizontalButtonsStyles(buttonWidth);

  useEffect(() => {
    let index = buttons.findIndex((button) => button.id === selectedId);
    if (index === -1) index = 0;

    Animated.spring(overlayLeftPosition, {
      toValue: HORIZONTAL_BUTTON_PADDING + buttonWidth * index,
      useNativeDriver: false,
      stiffness: 900,
      damping: 38,
    }).start();
  }, [buttonWidth, buttons, selectedId]);

  const onChangeHandle: typeof onChange = (id) => {
    setSelectedId(id);
    onChange?.(id);
  };

  const renderHorizontalButton = ({ id, title }: HorizontalButtonProps<T>) => (
    <TouchableOpacity key={id} style={styles.button} onPress={() => onChangeHandle(id)}>
      <StandardText style={styles.text}>{title}</StandardText>
    </TouchableOpacity>
  );

  return (
    <View style={[styles.container, containerStyle]}>
      <Animated.View style={[styles.selectedItemOverlay, { left: overlayLeftPosition }]} />
      <View style={styles.buttonsWrapper} testID={testID}>
        {buttons.map(renderHorizontalButton)}
      </View>
    </View>
  );
};
