import { useState, useEffect } from 'react';

import { NativeScrollEvent, LayoutChangeEvent } from 'react-native';

import { Layout, LayoutEvent } from 'components/AppScrollView/types';

const OFFSET = 2;

export const isScrolledDivToEnd = (scrollElement: HTMLDivElement, horizontal?: boolean) => {
  const { scrollHeight, clientHeight, scrollWidth, clientWidth, scrollTop, scrollLeft } = scrollElement;

  const scrollSize = horizontal ? scrollWidth : scrollHeight;
  const clientSize = horizontal ? clientWidth : clientHeight;

  if (scrollSize <= clientSize + OFFSET) return true;

  const allSize = scrollSize - clientSize;

  const position = horizontal ? scrollLeft : scrollTop;

  return position >= allSize - OFFSET;
};

export const isScrolledScrollViewToEnd = (scrollElement: NativeScrollEvent, horizontal?: boolean) => {
  const { layoutMeasurement, contentOffset, contentSize } = scrollElement;

  const allSize = horizontal ? contentSize.width : contentSize.height;

  const position = horizontal ? layoutMeasurement.width + contentOffset.x : layoutMeasurement.height + contentOffset.y;

  return position >= allSize - OFFSET;
};

export const isScrolledScrollViewWithoutScrollbar = (
  scrollViewLayout: Layout,
  contentLayout: Layout,
  horizontal?: boolean
) => {
  const scrollSize = horizontal ? scrollViewLayout.width : scrollViewLayout.height;
  const contentSize = horizontal ? contentLayout.width : contentLayout.height;

  return contentSize <= scrollSize;
};

export const useScrollViewAreaChanging = (onScrollHandler: (event: LayoutEvent) => void) => {
  const [scrollLayout, setScrollLayout] = useState<Layout>();
  const [contentLayout, setContentLayout] = useState<Layout>();

  const onContentSizeChange = (width: number, height: number) => setContentLayout({ width, height });
  const onLayout = ({ nativeEvent: { layout } }: LayoutChangeEvent) => setScrollLayout(layout);

  useEffect(() => {
    if (!contentLayout || !scrollLayout) return;

    onScrollHandler({ type: 'layout', contentLayout, scrollLayout });
  }, [scrollLayout, contentLayout, onScrollHandler]);

  return {
    onContentSizeChange,
    onLayout,
  };
};
