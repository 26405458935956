export const TEXTS = {
  FG_TITLE: 'Reset your password',
  FG_DESCRIPTION: 'Enter the email connected with your account to receive an email to reset your password',
  FG_INPUT_LABEL: 'Enter Email',
  FG_INPUT_PLACEHOLDER: 'name@domain.com',
  FG_BUTTON_TEXT: 'Send',
  FG_SUCCESS_TEXT: 'Email sent successfully',
  FG_STATUS_422_ERROR_MESSAGE: 'Sorry! We can’t seem to find that email address in our system',
  LOG_IN: 'Log In',
  LOG_IN_SENDING: 'Logging In...',
  SESSION_EXPIRED: 'Your session has been expired',
  RE_LOGIN: 'Try to re-login one more time',
  CLOSE: 'Okay',
};

// google analytics constants
const USER_ENROLLED_TO_THE_PROGRAM = 'USER_ENROLLED_TO_THE_PROGRAM';

export const loginAnalyticsAction = {
  USER_ENROLLED_TO_THE_PROGRAM,
};
