import React from 'react';

import { View, Modal } from 'react-native';
import { useSelector } from 'react-redux';

import { Triangle } from 'assets/icons';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useSwitchProgramHandler } from 'hooks';
import { getPrograms, getSelectedProgramID } from 'store/selectors';

import PopupItem from './PopupItem';
import { getStyles } from './styles';

type SelectPopupProps = {
  visible: boolean;
  setVisibility: (visibility: boolean) => void;
};

export const SelectPopup = React.forwardRef<View, SelectPopupProps>(({ visible, setVisibility }, ref) => {
  const programs = useSelector(getPrograms);
  const switchHandler = useSwitchProgramHandler();

  const selectedProgramID = useSelector(getSelectedProgramID);

  const handleChange = (id: number, uuid: string) => {
    switchHandler(uuid);
    setVisibility(false);
  };

  const { isMobile } = useDeviceInfoContext();
  const styles = getStyles(isMobile);

  return (
    <Modal transparent animationType='fade' visible={visible}>
      <View style={styles.popupContainer}>
        <View ref={ref} style={styles.popupWrapper}>
          {programs.map(({ id, white_labeling_conf: { logo }, uuid }, index) => {
            const isLastItem = index === programs.length - 1;
            return (
              <PopupItem
                key={id}
                id={id}
                programUuid={uuid}
                logoUrl={logo}
                isLastItem={isLastItem}
                isSelected={id === selectedProgramID}
                onProgramChange={handleChange}
              />
            );
          })}
        </View>
        <View style={styles.triangleWrapper}>
          <Triangle />
        </View>
      </View>
    </Modal>
  );
});

export default SelectPopup;
