import React from 'react';

import Svg, { SvgProps, Defs, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Cream = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 103.35 105.22' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M49.89 8.05c15.54.27 27.87 1.33 37.75 9.24 11.28 9 17.24 21.06 15.37 36.06-.84 6.72-1.2 13.54-2.54 20.16q-5.19 25.58-32.71 30.35c-14.65 2.53-29.16 1.84-42.82-4.69-10.51-5-18.1-12.57-20.19-24.75C3.27 65.81-.85 57.35.16 48.64 2.8 25.91 7.4 16.58 31.46 10.4c7.01-1.8 14.35-2.58 18.43-2.35Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M103.16 46.79c-.13.68-2.14 20.75-50.28 20.54S.16 48.64.16 48.64.9 39.3 1.75 37.81 5.49 60.41 53.1 59s48.14-23.11 48.14-23.11a28 28 0 0 1 1.92 10.9Z'
        />
        <Path
          d='M98.56 57a1.18 1.18 0 0 1 2.15.61c.42 9.75-1.27 37.1-32.48 42.77-3.09.62-18.83 4.83-35.41-.92a1.19 1.19 0 0 1 .1-2.28C45.44 94.27 78.18 86.84 98.56 57Z'
          fillOpacity={0.1}
        />
        <Path fillOpacity={0.2} d='M13.28 27S18.47 9 51.39 9 89 24.56 89 24.56Z' />
        <Path
          d='M12.8 28.13a.66.66 0 0 0 0 .14c0 1.27.88 18.57 40.08 18 40.69-.61 37.63-18 37.63-18S91.73 19 70.05 13.83h-.09C64.79 12 62.35 10.75 59.3 8 56.56 5.54 51.38-2.54 45.94.8a1 1 0 0 0 .23 1.8c.44.12.55.37.14.89-.83 1.07-.12 5.33-12.31 10.06-2.83.94-20 3.26-21.2 14.58Z'
          fill='#fafafa'
        />
        <Path
          d='M24.66 21.42a10.56 10.56 0 0 0-1.84 5.78 5.47 5.47 0 0 0 2.63 4.35A19.14 19.14 0 0 0 30.74 34a59.9 59.9 0 0 0 6 1.49c4.17.9 8.33 1.82 12.5 2.64 2.09.42 4.18.84 6.29 1.15a41 41 0 0 0 6.4.68 21.2 21.2 0 0 1-6.49 1.28 50 50 0 0 1-6.59 0 83.94 83.94 0 0 1-13-1.85 41.67 41.67 0 0 1-6.45-1.83 18.47 18.47 0 0 1-6.06-3.61 8.91 8.91 0 0 1-2.18-3.12 6.78 6.78 0 0 1-.29-3.83 9.38 9.38 0 0 1 3.79-5.58Z'
          fill='#dfe1e1'
        />
        <Path
          fillOpacity={0.2}
          d='M101.11 35.52c-.79 4.25-6.52 20.88-48 22.12-43.07 1.29-49.54-18.31-51-21l-.24 1.05C2.87 38 7.19 60.36 53.1 59c44.81-1.35 47.9-20.64 48.12-22.9Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M40.54 65.08h-.11C12.28 63.1 3 49.57 2.63 49a1.5 1.5 0 0 1 2.5-1.66s2.22 3.25 7.66 6.66c5 3.15 14 7.12 27.85 8.09a1.5 1.5 0 0 1-.1 3Z'
        />
      </G>
    </G>
  </Svg>
);

export default Cream;
