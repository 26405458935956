import { AddToConsentModalsQueue } from 'store/actions/user/actionCreators/consents/addToConsentModalsQueue';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import type { User } from 'store/reducers/user-info/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

const addToConsentModalsQueue = (
  state = INITIAL_STATE,
  payload: ReturnType<AddToConsentModalsQueue>['payload']
): User => {
  const consentIsPresentInQueue = state.consentModalsQueue.find((consent) => {
    if (payload.customServiceType) {
      return (
        consent.id === payload.id &&
        consent.groupType === payload.groupType &&
        consent.customServiceType === payload.customServiceType
      );
    }
    return consent.id === payload.id && consent.groupType === payload.groupType;
  });

  const currentConsent = state.consentModalsState[payload.groupType].entities.consents[payload.id];
  const consentHasBeenGiven = currentConsent.isGiven;

  if (!consentIsPresentInQueue) {
    if (payload.groupType === ConsentModalTypeEnum.service && !currentConsent.required_to_proceed) {
      return state;
    }
    if (!consentHasBeenGiven) {
      return {
        ...state,
        consentModalsQueue: [...state.consentModalsQueue, payload],
      };
    }
  }

  return state;
};

export default addToConsentModalsQueue;
