import React from 'react';

import Svg, { SvgProps, Defs, G, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Foam = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 111.18 131.32' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M80.1 131.32s2.1-14.65-10.46-14.65h-34S22 115.1 21 104.63v-11h-8.9S-3.08 88.94.58 75.86s12-12.56 12-12.56-3.14-16.74 4.7-20.4 12.56-3.14 12.56-3.14S26.22 14.65 51.85 0c0 4.19-4.18 12.56.53 15.7s11 2.61 15.69 8.89c1.57 1.05 7.85 5.23 7.33 13.6.52.52 13.6-3.14 18.31 5.76 1 1 5.75 8.89 3.66 17.78 0-.52 15.69 2.1 13.6 15.7S94.23 92.08 94.23 92.08s8.37 29.82-14.13 39.24Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          fillOpacity={0.2}
          d='M29.88 39.76s6.46 5.58 18.12 4.42c0 .56-18.16-8.6-15.89-21.53.01 1.11-2.85 5.19-2.23 17.11ZM13.63 46.85s-2.9 5-1 16.45c0 0 4.68 6.83 17.45 6.57.25 0-17.26-4.34-16.45-23.02ZM2.05 71.78s-8.8 14.6 10 21.87h15.22S6.57 89.74 2.05 71.78ZM21 100.4s-3.19 13.67 14.64 16.27h4.68S22.86 113.09 21 100.4Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M75.4 38.19s-.55 3-11.34 2.83c0 0 12.93.41 15.68 1.58S91.86 44 97.68 54c-.13-.81-1.78-12.36-10.23-15.26.23.11-4.27-1.61-12.05-.55ZM97.37 61.73s-4.84 5.91-19.32 6.1c3.76.85 20-2.61 26.42 2.45s5.83 8 5.58 10.47c.33-.7 3.34-8.4-2.1-14 .05-.04-5.11-5.01-10.58-5.02ZM60.87 24.46s9.17-3.89 14.07 9c0 0-4.08-6.46-8.24-7.81a29.92 29.92 0 0 0-5.83-1.19Z'
        />
      </G>
    </G>
  </Svg>
);

export default Foam;
