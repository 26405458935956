import React from 'react';

import { Info } from 'assets/icons';

import { InputLabelText, LabelWrapper, IconWrapper } from './styles';

type InputLabelProps = {
  labelText: string;
  showInfo?: boolean;
};

const InputLabel = ({ labelText, showInfo }: InputLabelProps) => (
  <LabelWrapper>
    <InputLabelText>{labelText}</InputLabelText>
    {!!showInfo && (
      <IconWrapper>
        <Info width={16} height={16} fill='#898181' />
      </IconWrapper>
    )}
  </LabelWrapper>
);

export default InputLabel;
