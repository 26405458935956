import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useAsNeededMedicationStyles = () => {
  const theme = useTheme();

  return StyleSheet.create({
    asNeededMedicationContainer: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingHorizontal: 24,
      paddingVertical: 30,
      borderWidth: 1,
      borderColor: theme.colors.gray3,
      borderRadius: 16,
      marginTop: 16,
    },
    asNeededText: {
      color: theme.colors.gray1,
      fontFamily: theme.fontFamilies.semiBold,
    },
    asNeededModalContainer: {
      flex: 1,
      padding: 8,
      marginTop: 18,
      maxWidth: 270,
    },
    modalTitle: {
      ...theme.fontSizes[24],
      fontFamily: theme.fontFamilies.bold,
      color: theme.colors.gray1,
      paddingRight: 20,
    },
    modalDescription: {
      marginTop: 20,
      marginBottom: 28,
      color: theme.colors.gray2,
    },
    buttonsWrapper: {
      marginTop: 32,
      flexDirection: 'row',
      width: '100%',
    },
    cancelButton: {
      width: 110,
      marginRight: 16,
    },
    addButton: {
      width: 90,
    },
  });
};
