import { MilestoneTrackerData } from 'api/Benefits/types';

export const demoTrackerData: MilestoneTrackerData = {
  createdAt: 'January 10, 2022',
  isHidden: false,
  meta: null,
  description: '',
  trackerStatus: 'Complete',
  uuid: '',
  name: 'Demo Tracker',
  milestones: [
    {
      uuid: '1',
      milestoneStatus: 'Complete',
      title: 'Enrolment Received',
      createdAt: 'January 30, 2022',
      userData: {
        createdAt: 'January 30, 2022',
        description:
          'Your enrolment information has been received and is processed. We’ll keep you updated on our progress.',
        name: 'Enrolment Received',
        uuid: '1',
        updatedAt: 'January 30, 2022',
      },
    },
    {
      uuid: '2',
      milestoneStatus: 'Complete',
      title: 'Missing Information',
      createdAt: 'January 30, 2022',
      userData: {
        createdAt: 'January 30, 2022',
        description:
          'We can use your help! We need some information to process your enrolment. Please provide the missing information highlighted in your profile.',
        name: 'Missing Information',
        uuid: '2',
        updatedAt: 'January 30, 2022',
      },
    },
    {
      uuid: '3',
      milestoneStatus: 'Complete',
      title: 'Prior Authorization Required',
      createdAt: 'January 31, 2022',
      userData: {
        createdAt: 'January 31, 2022',
        description:
          'Your insurance requires prior authorization from your healthcare provide. We’re working on it for you. We’ll keep you updated on our progress.',
        name: 'Prior Authorization Required',
        uuid: '3',
        updatedAt: 'January 31, 2022',
      },
    },
    {
      uuid: '4',
      milestoneStatus: 'In process',
      title: 'Benefits Determined',
      createdAt: 'February 02, 2022',
      userData: {
        createdAt: 'February 02, 2022',
        description:
          'Your coverage been determined and a summary of benefits is available for your review. View and track your benefits here.',
        name: 'Benefits Determined',
        uuid: '4',
        updatedAt: 'February 02, 2022',
      },
    },
    {
      uuid: '5',
      milestoneStatus: 'Not started',
      title: 'Medication Shipped',
      createdAt: 'February 04, 2022',
      userData: {
        createdAt: 'February 04, 2022',
        description:
          'Your medication is on the way. It should take 3-5 days for your shipment to arrive. You’ll receive an update when we’re notified of delivery.',
        name: 'Medication Shipped',
        uuid: '5',
        updatedAt: 'February 04, 2022',
      },
    },
    {
      uuid: '6',
      milestoneStatus: 'Not started',
      title: 'Medication Delivered',
      createdAt: 'February 06, 2022',
      userData: {
        createdAt: 'February 06, 2022',
        description:
          'Our records show that you medication was delivered. Please call us if you have questions or have not received your shipment.',
        name: 'Medication Delivered',
        uuid: '6',
        updatedAt: 'February 06, 2022',
      },
    },
  ],
};
