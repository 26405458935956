import { SetSelectedProgramID } from 'store/actions/user/actionCreators/programs/setSelectedProgramId';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setSelectedProgramId = (state = INITIAL_STATE, payload: ReturnType<SetSelectedProgramID>['payload']): User => {
  return {
    ...state,
    selectedProgramId: payload,
  };
};

export default setSelectedProgramId;
