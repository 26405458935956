import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';

export type CabinetScreenProps = {
  navigation: DrawerNavigationHelpers;
};

export enum MedCabinetWidgetType {
  MEDICATION = 'medication',
  REMINDER = 'reminder',
}

export enum CabinetInfoType {
  NOTIFY = 'notify',
  WARNING = 'warning',
}
