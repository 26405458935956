import React from 'react';

import styledWeb from 'styled-components';
import styled from 'styled-components/native';

import { AppFlatListProps } from 'components/AppFlatList/types';
import { AppScrollViewProps } from 'components/AppScrollView/types';
import { isNativeDevice } from 'utils/helpers';

export const scrollbarThumbWebStyle = `
  background-color: #787D83;
  border-radius: 4px;
`;

export const AppContainer = isNativeDevice
  ? styled.View`
      flex: 1;
    `
  : (styledWeb.div`
    display: flex;
    flex: 1 1 100%;
    
    html, body, #root {
      -webkit-overflow-scrolling: auto;
      overflow: -moz-scrollbars-vertical; 
      overflow-y: scroll; 
    }
` as React.ElementType);

export const WebScrollElement = isNativeDevice
  ? styled.View``
  : (styledWeb.div<AppFlatListProps | AppScrollViewProps>`  
  flex: 1;
  padding-right: ${({ leftScrollOffset }) => leftScrollOffset ?? 0}px;
  ${({ VHHeight }) => (VHHeight ? `max-height: ${VHHeight}vh;` : ``)}
  overflow-x: ${({ horizontal }) => (horizontal ? `overlay` : `clip`)};
  overflow-y: ${({ horizontal }) => (horizontal ? `clip` : `overlay`)};

  &::-webkit-scrollbar {
    width: ${({ withRightMargin }) => (withRightMargin ? 6 : 4)}px;
  }
 
  &::-webkit-scrollbar-thumb {
    ${({ withRightMargin }) => {
      if (!withRightMargin) return '';

      return `
        border-right: 2px transparent solid;
        background-clip: content-box;
      `;
    }}
    ${scrollbarThumbWebStyle}
  }
` as React.ElementType);
