import React from 'react';

import HTML from 'react-native-render-html';
import { RenderHTMLSourceProps } from 'react-native-render-html/lib/typescript/shared-types';

import { tagStyles, systemFonts, idsStyles, classesStyles } from 'components/AppHTML/styles';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';

type Props = Pick<RenderHTMLSourceProps, 'source'>;

export const AppHTML = ({ source }: Props) => {
  const { width } = useDeviceInfoContext();

  return (
    <HTML
      defaultTextProps={{ selectable: true }}
      systemFonts={systemFonts}
      tagsStyles={tagStyles}
      idsStyles={idsStyles}
      classesStyles={classesStyles}
      contentWidth={width}
      source={source}
    />
  );
};
