import { Pressable } from 'react-native';
import styled, { css } from 'styled-components/native';

import { DefaultText, getFontStyles } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

const isWeb = !isNativeDevice;

export const MenuItemWrapper = styled(Pressable)`
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  ${isWeb &&
  css`
    align-items: center;
  `}
`;

export const MenuText = styled(DefaultText)<{ activeColor?: string }>`
  ${getFontStyles(16)}
  font-family: ${(props) => props.theme.fontFamilies.semiBold};
  color: ${(props) => props.activeColor};
`;

export const MenuIconWrapper = styled.View`
  position: relative;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  ${isWeb &&
  css`
    margin-right: 24px;
    width: 24px;
    height: 24px;
  `}
`;
