import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { BenefitsServicesInterface } from 'api/Benefits';
import { setBenefitInvestigations } from 'store/actions/cards';
import { getBenefitInvestigations, getMilestoneTrackers } from 'store/selectors/home-info-cards';
import { handlePromise } from 'utils/handlePromise';
import { makeRequestWithLoader } from 'utils/requests';

const request = async () => {
  const [res] = await handlePromise(BenefitsServicesInterface.getBenefitInvestigations);

  return res;
};

export const useGetBenefitInvestigations = (trackerId?: string) => {
  const [isLoading, setLoading] = useState(false);

  const data = useSelector(getBenefitInvestigations);
  const trackers = useSelector(getMilestoneTrackers);

  const dispatch = useDispatch();

  useEffect(() => {
    makeRequestWithLoader(request, setLoading).then((res) => !!res && dispatch(setBenefitInvestigations(res)));
  }, [dispatch]);

  const selectedTracker = trackers.find(({ uuid, type }) => (trackerId ? uuid === trackerId : type === 'BI_TRACKER'));

  return {
    isLoading,
    benefitsInvestigation: selectedTracker?.type === 'BI_TRACKER' ? data : null,
    benefitTracker: selectedTracker,
  };
};
