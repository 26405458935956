import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const resetUserInfo = (state = INITIAL_STATE): User => {
  return {
    ...INITIAL_STATE,
    showEventNotification: state.showEventNotification,
  };
};

export default resetUserInfo;
