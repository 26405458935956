import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Clock = (props: SvgProps) => (
  <Svg width={18} height={18} fill='none' {...props}>
    <Path
      d='M9 16.333A7.333 7.333 0 1 0 9 1.666a7.333 7.333 0 0 0 0 14.667Z'
      stroke={defaultTheme.colors.gray2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M9 5v4l2.667 1.333'
      stroke={defaultTheme.colors.gray2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Clock;
