import React, { useEffect, useState } from 'react';

import { useIsFocused, useRoute } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { MedicineCabinetInterface } from 'api';
import { Edit } from 'assets/icons';
import { useTimer } from 'hooks';
import { Reminders } from 'screens/main/MedicineCabinet/components';
import { FieldBlockComponent } from 'screens/main/MedicineCabinet/components/FieldBlock/FieldBlock';
import { MedicationFields } from 'screens/main/MedicineCabinet/components/FieldBlock/types';
import { totalDoseFormatter } from 'screens/main/MedicineCabinet/components/MedicationWizard/Steps/shared/helpers';
import { MedicationDetailsRouteProps } from 'screens/main/MedicineCabinet/sections/MedicationDetails/components/SubsectionsTabs/types';
import { SectionContainer, SectionTitle } from 'screens/main/MedicineCabinet/sections/MedicationDetails/styles';
import {
  setIsMedicationCabinetLoading,
  setMedications,
  setReminderModalVisibility,
  setReminders,
  setSelectedMedicationId,
} from 'store/actions/medicineCabinet';
import {
  getIsMedicationCabinetLoading,
  getMedicationTypes,
  getSelectedMedication,
  getSelectedMedicationReminders,
} from 'store/selectors/medicine-cabinet';
import { DefaultText } from 'style/common';
import { capitalize } from 'utils/convertString';
import { convertLocalToUTCDate, toCorrectDatePickerFormat } from 'utils/dateTimeHelpers';

import { medicationDetailAnalyticsActions } from '../../constants';
import { medicationDetailsAnalyticsHandler } from '../../helpers';
import { TEXTS } from './constants';
import { getFields } from './fieldList';
import { DetailInformationContainer, DetailsBlock, SectionTitleBlock } from './styles';
import YourDosePlaceHolder from './YourDosePlaceHolder';

const {
  VIEW_ABOUT_YOUR_DOSE_SECTION,
  EDIT_MEDICATION_YOUR_DOSE_STARTED,
  EDIT_MEDICATION_YOUR_DOSE_FINISHED,
  VIEW_PROGRAM_MEDICATION_CABINET_DETAIL,
} = medicationDetailAnalyticsActions;

export const AboutYourDose = () => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const medicationTypes = useSelector(getMedicationTypes);
  const selectedMedication = useSelector(getSelectedMedication);
  const isMedicineCabinetLoading = useSelector(getIsMedicationCabinetLoading);
  const selectedMedicationReminders = useSelector(getSelectedMedicationReminders);
  const isFocused = useIsFocused();
  const { start, pause } = useTimer();

  useEffect(() => {
    if (isFocused) {
      medicationDetailsAnalyticsHandler(VIEW_ABOUT_YOUR_DOSE_SECTION);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!selectedMedication?.sponsored_program_uuid) return;
    medicationDetailsAnalyticsHandler(VIEW_PROGRAM_MEDICATION_CABINET_DETAIL);
  }, [selectedMedication]);

  const { params } = useRoute<MedicationDetailsRouteProps>();
  const medicationId = params?.medicationId;

  useEffect(() => {
    if (!selectedMedication?.id) {
      medicationId && dispatch(setSelectedMedicationId(medicationId));
    }
  }, [dispatch, medicationId, selectedMedication?.id]);

  const name = selectedMedication?.name;
  const method = selectedMedication?.type;
  const totalDose = selectedMedication?.totalDose;
  const dosageAmount = selectedMedication?.dosageAmount;
  const quantity = selectedMedication?.quantity;
  const dateStarted = selectedMedication?.dateStarted
    ? toCorrectDatePickerFormat(new Date(selectedMedication?.dateStarted))
    : null;

  useEffect(() => {
    const typeWithPostfix =
      !!method && typeof totalDose === 'number' ? `${capitalize(method)}${totalDose > 1 ? 's' : ''}` : '';
    setType(typeWithPostfix);
  }, [method, totalDose]);

  const [type, setType] = useState(method);
  const defaultValues = { type, totalDose, dosageAmount, quantity, dateStarted };
  const textField = Object.values(defaultValues);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<MedicationFields>({ mode: 'onBlur', defaultValues });

  useEffect(() => {
    if (!selectedMedication) return;

    reset({
      type: selectedMedication.type,
      totalDose: selectedMedication.totalDose,
      dosageAmount: selectedMedication.dosageAmount,
      quantity: selectedMedication.quantity,
      dateStarted: selectedMedication?.dateStarted
        ? toCorrectDatePickerFormat(new Date(selectedMedication?.dateStarted))
        : null,
    });
  }, [selectedMedication, reset]);

  const formValues = watch();

  const medicationSubmitHandler = async (values: MedicationFields) => {
    const data = {
      ...values,
      name: name,
      dosageAmount: values.dosageAmount || '',
      totalDose: totalDoseFormatter(String(values.totalDose)),
      dateStarted: convertLocalToUTCDate(values.dateStarted),
      id: selectedMedication?.id,
      drugId: selectedMedication?.drugId,
    };
    dispatch(setIsMedicationCabinetLoading(true));
    try {
      if (selectedMedication?.id) {
        await MedicineCabinetInterface.updateMedication(data);
        const medicationsResponse = await MedicineCabinetInterface.getMedications();
        dispatch(setMedications(medicationsResponse?.data?.results));
        const passedTime = pause();
        medicationDetailsAnalyticsHandler(EDIT_MEDICATION_YOUR_DOSE_FINISHED, { event_label: passedTime });
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(setIsMedicationCabinetLoading(false));
  };

  const toggleEditMode = async () => {
    if (isEditMode) {
      await handleSubmit(medicationSubmitHandler)();

      const isErrors = Object.values(errors).some(Boolean);
      return setIsEditMode(isErrors);
    }

    start();
    medicationDetailsAnalyticsHandler(EDIT_MEDICATION_YOUR_DOSE_STARTED);

    setIsEditMode(true);
  };

  const fields = getFields();

  const { SAVE, TITLE_TEXT } = TEXTS;

  const reminderModalSubmitHandler = () => {
    dispatch(setReminderModalVisibility(false));
    MedicineCabinetInterface.getReminders().then((resp) => {
      dispatch(setReminders(resp.data.results));
    });
  };

  return (
    <SectionContainer>
      <SectionTitleBlock>
        <SectionTitle>{TITLE_TEXT}</SectionTitle>
        <TouchableOpacity onPress={toggleEditMode} disabled={isMedicineCabinetLoading}>
          {isEditMode ? <DefaultText>{SAVE}</DefaultText> : <Edit size='small' />}
        </TouchableOpacity>
      </SectionTitleBlock>
      {isMedicineCabinetLoading ? (
        <YourDosePlaceHolder />
      ) : (
        <DetailInformationContainer>
          {fields.map((field, index) => (
            <DetailsBlock isEditMode={isEditMode} key={field.fieldName}>
              <FieldBlockComponent
                key={field.fieldName}
                options={medicationTypes}
                editField={isEditMode}
                fieldText={textField[index]}
                {...field}
                inputType={isEditMode ? field.inputType : undefined}
                control={control}
                error={errors[field.fieldName]}
                numeric={field.numeric}
                formValues={formValues}
              />
            </DetailsBlock>
          ))}
        </DetailInformationContainer>
      )}
      <Reminders reminders={selectedMedicationReminders} reminderSubmitHandler={reminderModalSubmitHandler} />
    </SectionContainer>
  );
};
