import { MedicineCabinetInfo, TabTypesEnum } from './types';

export const INITIAL_STATE: MedicineCabinetInfo = {
  medications: [],
  programMedications: [],
  reminders: [],
  reminderTypes: [],
  medicationTypes: [],
  medicationSelectOptions: [],
  reminderModalVisibility: false,
  pspReminderTemplate: {},
  pspMedicationHasBeenAdded: false,
  medicationModalVisibility: false,
  isMedicationCabinetLoading: true,
  notifications: [],
  selectedTab: TabTypesEnum.ABOUT_YOUR_DOSE,
  drugInteractions: [],
  drugInteractionsModalVisibility: false,
  medicationWizardVisibility: false,
  medicationWizardStep: 0,
  selectedInteractionId: 0,
  allDrugInteractions: [],
  asNeededMedicationModalVisibility: false,
  previousDrugId: 0,
};
