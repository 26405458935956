import React from 'react';

import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { View } from 'react-native';

import { useTitles, useScreens } from 'components/LeftSideDrawer/helpers';
import { MenuItem, RouteType } from 'components/MenuItem';
import { useAdditionalServiceNotification } from 'components/MenuItem/useAdditionalServiceNotification';
import { useHandleNavigationPush } from 'hooks';

import { getItemStyles } from './styles';

export const MenuItems = () => {
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const navigationHandler = useHandleNavigationPush();
  const screens = useScreens();
  const titles = useTitles();
  const navigationState = navigation.getState().routes[0].state;
  const activeRouteName = navigationState?.index
    ? navigationState?.routes?.[navigationState.index]?.name
    : navigationState?.routes?.[0]?.name;

  const { hideNotifications } = useAdditionalServiceNotification();

  const styles = getItemStyles();

  return (
    <View style={styles.itemsWrapper}>
      {screens.map((screen, index) => (
        <MenuItem
          key={index}
          menuItemRoute={screen as RouteType}
          active={screen === activeRouteName}
          title={titles[index]}
          testID={`${screen}-test`}
          itemWrapperStyle={styles.item}
          onPress={() => navigationHandler(screen)}
          hideNotifications={hideNotifications}
        />
      ))}
    </View>
  );
};

export default MenuItems;
