export const termsInfo = `
  <p>
    <strong>TERMS</strong> <strong>AND CONDITIONS</strong>
  </p>
  <p>
  Unless otherwise indicated, these Terms and Conditions of Service (“Terms”) apply to (i) your use of and/or access to PatientLink by TrialCard™ and PatientLink’s services available via PatientLink’s site and mobile apps which are owned or operated by TrialCard Incorporated (“TrialCard”, “we”, “us”) or its affiliates (collectively, “TrialCard Incorporated ”, “we,” “us,” or “our”), and (ii) your use of and/or access to the mobile phone applications which we may own or operate currently or in the future (collectively, the “Mobile App”) and (iii) your use of and or access to any content or information and other online or mobile-enabled technology, digital tools and other services and products provided by us (together with the Website and the Mobile App, collectively, the “Service”). For purposes of these Terms & Conditions, “affiliates” shall mean any entity or person, directly or indirectly, owning a controlling interest in, owned by, or under common ownership control with, TrialCard Incorporated IF YOU ARE NOT WILLING TO BE OR CANNOT BE BOUND BY THESE TERMS, INCLUDING WITHOUT LIMITATION THE FOLLOWING AGREEMENT AND ACKNOWLEDGEMENT, THEN DO NOT ACCESS, BROWSE OR USE THE SERVICE. MOREOVER, <strong>TRIALCARD</strong> DOES NOT AND WILL NOT GRANT YOU ANY RIGHT OR LICENSE TO ACCESS, BROWSE OR THE SERVICE WITHOUT YOUR WILLINGNESS AND ABILITY TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
  </p>
  <p>
    We are committed to protecting your privacy and security. For more information, you should review our Privacy Policy, which is incorporated into these Terms by this reference.
  </p>
  <p>
    IF YOU DO NOT ABIDE BY THE PROVISIONS OF THESE TERMS, EXCEPT AS WE MAY OTHERWISE PROVIDE FROM TIME TO TIME, YOU AGREE THAT WE MAY IMMEDIATELY DEACTIVATE OR DELETE YOUR USER ACCOUNT AND ALL RELATED INFORMATION AND FILES IN YOUR USER ACCOUNT AND/OR RESTRICT ANY FURTHER ACCESS TO SUCH INFORMATION AND/OR FILES, OR OUR SERVICE, WITH OR WITHOUT NOTICE.
  </p>
  <p>
    IMPORTANT!!! THE SERVICE IS INTENDED SOLELY AS A TOOL TO ASSIST YOU IN ORGANIZING, UNDERSTANDING AND MANAGING HEALTHCARE-RELATED INFORMATION. THE SERVICE IS NOT INTENDED TO PROVIDE HEALTH OR MEDICAL ADVICE. THE SERVICE IS NOT INTENDED TO (AND DOES NOT) CREATE ANY PATIENT RELATIONSHIP BETWEEN PATIENTLINK AND YOU, NOR SHOULD IT BE CONSIDERED A REPLACEMENT FOR CONSULTATION WITH A HEALTH CARE PROFESSIONAL. YOU SHOULD NEVER DISREGARD MEDICAL ADVICE OR DELAY SEEKING MEDICAL ADVICE BECAUSE OF SOMETHING YOU HAVE READ ON THE SERVICE OR THE RESULTS YOU RECEIVE THROUGH THE SERVICE.
  </p>
  <p>
  IN ADDITION, YOU UNDERSTAND AND AGREE THAT IN PROVIDING THE SERVICE WE RELY ON A NUMBER OF THIRD-PARTY PROVIDERS, INCLUDING FOR PURPOSES OF SENDING PUSH NOTIFICATIONS, AND HEREBY DISCLAIM ANY LIABILITY WITH RESPECT TO THE SERVICES PROVIDED BY SUCH PROVIDERS. YOU SHOULD NOT RELY ON THE SERVICE FOR ANY LIFE-THREATENING CONDITION OR ANY OTHER SITUATION WHERE TIMELY ADMINISTRATION OF MEDICATIONS OR OTHER TREATMENT IS CRITICAL.
  </p>
  <p>LIMITED LICENSE</p>
  <p>
    Subject to your continued compliance with the Terms, we grant you a non-exclusive, non-transferable, non-sublicensable, fully revocable, limited license to access and use the Service (whether through the Website or by downloading and installing the Mobile App, including any updates and bug fixes). Your use is limited for your personal, noncommercial use only.
  </p>
  <p>
    The Service, or any portion thereof, may not be reproduced, duplicated, copied, modified, sold, resold, distributed, visited, or otherwise exploited for any commercial purpose without the express written consent of TrialCard. Furthermore, except for the limited rights granted in this Section, you will not (and will not encourage or assist any third party to): (i) modify, alter, tamper with, repair or otherwise create derivative works of the Services or any software or technology included in or used or distributed by PatientLink to provide the Services; (ii) reverse engineer, disassemble or decompile the Services, or attempt to discover or recreate the source code for the Services; ; (iii) make the functionality of the App or the Service available to multiple users through any means; or (iv) use the Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms.
  </p>
  <p>RESTRICTIONS ON USE OF CONTENT</p>
  <p>
    You acknowledge that our Service contains content, information, software, photos, video, text, graphics, music, sounds, questions, creative suggestions, messages, comments, feedback, ideas, notes, drawings, articles and other materials (collectively, &ldquo;Content&rdquo;) that are protected by copyrights, patents, trademarks, trade secrets and/or other proprietary rights, and that these rights are valid and protected in all forms, media and technologies existing now or hereafter developed. All Content is copyrighted under the United States copyright laws (and, if applicable, similar foreign laws), and we own a copyright in the selection, coordination, arrangement and enhancement of such Content. All trademarks appearing on the Services are trademarks of their respective owners.
  </p>
  <p>
    Our commercial partners, suppliers, advertisers, sponsors, licensors, contractors and other third parties may also have additional proprietary rights in the Content which they make available on our Services. You may not modify, publish, transmit, distribute, perform, participate in the transfer or sale, create derivative works of, or in any way exploit, any of the Content, in whole or in part. When Content is downloaded to your computer, phone, tablet or any other mobile device, you do not obtain any ownership interest in such Content. Modification of the Content or use of the Content for any other purpose, including, but not limited to, use of any Content in printed form or on any other applications or networked computer environment is strictly prohibited unless you receive our prior written consent.
  </p>
  <p>
    We may at any time suspend your use of the Services and/or remove or disable any Content as to which we reasonably and in good faith believe is in violation of any of these Terms. We will provide you with notice of any such suspension or disablement before its implementation unless such suspension or disablement is necessary to comply with legal process, regulation, order or prevent imminent harm to the Services or any third party, in which case we will notify you to the extent allowed by applicable law of such suspension or disablement as soon as reasonably practicable thereafter.
  </p>
  <p>APP PROVIDER TERMS</p>
  <p>
    The following terms apply to any App accessed through or downloaded from any app store or distribution platform (like the Apple App Store or Google Play) where the App may now or in the future be made available (each an &ldquo;App Provider&rdquo;). You acknowledge and agree that:
  </p>
  <ol>
    <li>
      <span>
      These Terms are between you and TrialCard, and not with the App Provider, and that TrialCard is solely responsible for the App.
      </span>
    </li>
    <li>
      <span>The App Provider has no obligation to furnish any maintenance and support services with respect to the App.</span>
    </li>
    <li>
      <span>
      In the event of any failure of the App to conform to any applicable warranty, you may notify the App Provider, and the App Provider will refund the purchase price for the App to you (if applicable) and to the maximum extent permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be the sole responsibility of TrialCard.
      </span>
    </li>
    <li>
      <span>
      The App Provider is not responsible for addressing any claims you have or any claims of any third party relating to the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.
      </span>
    </li>
    <li>
      <span>
      In the event of any third party claim that the App or your possession and use of that App infringes that third party’s intellectual property rights, TrialCard will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.
      </span>
    </li>
    <li>
      <span>
      The App Provider, and its subsidiaries, are third party beneficiaries of these Terms as related to your license of the App, and that, upon your acceptance of the terms and conditions of these Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third party beneficiary thereof.
      </span>
    </li>
    <li>
      <span>You must also comply with all applicable third party terms of service when using the App.</span>
    </li>
  </ol>
  <p>
    Our Service may change from time to time and/or we may stop (permanently or temporarily) providing the Service (or features within the Service), possibly without prior notice to you. Our Service may include advertisements, which may be targeted to the content or information on the Service, queries made through the Service, or from other information. The types and extent of advertising on the Service are also subject to change over time. In consideration for providing you the Service, you agree that we and our third party providers and partners may place advertising on our Service or in connection with the display of content or information on our Service, and that we may receive remuneration for placing such advertising.
  </p>
  <p>CREATING A PATIENTLINK ACCOUNT</p>
  <p>
    <span><span>&nbsp;&nbsp; &nbsp; </span></span>
    You represent and warrant that all user information you provide on the registration form or otherwise in connection with your use of the Services will be current, complete and accurate. As a registered user of the Services, you are entirely responsible for maintaining the confidentiality of your user account information. You may not use the account or user name of any other member at any time. You must notify us immediately in the event of any known or suspected unauthorized use of your user account, or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your or anyone else&rsquo;s user account information.
  </p>
  <p>
    You are also entirely responsible for any and all activities which occur under your user account. We will not be liable for any loss that you incur as a result of someone else using your account, either with or without your knowledge. You may also be held liable for any losses incurred by TrialCard, its affiliates, officers, directors, employees, consultants, agents and representatives due to someone else&rsquo;s use of your account.
  </p>
  <p>
    <span>One Account Per User</span>.
    You agree not to register or subscribe for more than one account, create an account on behalf of someone else, or create a false or misleading identity on this Website. If your registration or subscription is revoked for any reason, you agree not to register or subscribe again with our Services using another user name or through any other means. If we have reason to suspect, in our sole discretion, that your account has previously been terminated, we reserve the right to terminate any new accounts you have registered without any notice to you, or to exercise any other remedies available to us under these Terms or by law.
  </p>
  <p>
    We reserve the right to suspend or terminate your Account if any information provided during the registration process or thereafter proves to be inaccurate, false or that violates our Terms or if you have created more than one Account.
  </p>
  <p>CONSENT TO RECEIVE ELECTRONIC COMMUNICATIONS</p>
  <p>
    a.&nbsp;<span>Legal Communications</span>. We may send the following to you by email or posting them on the Website and/or the Mobile App: these Terms, including legal disclosures; future changes to these Terms, Privacy Policy, and other notices, legal communications or disclosures and information related to the Service. Such communications are part of the Services which you cannot opt out of receiving.
  </p>
  <p>
    b.&nbsp;<span>Service Announcements</span>. In using the Service, you may receive periodic electronic communications from us regarding the Service, such as new product offers and other information regarding the Website and/or the Mobile App, which are part of the Service and which you cannot opt out of receiving.
  </p>
  <p>
    c.&nbsp;<span>Promotional Communications</span>. You may also receive periodic promotions and other offers or materials we believe might be of interest to you. You can opt-out of receiving these promotional messages at any time by (a) following the unsubscribe instructions contained in each message; or (b) changing the messaging preferences in your account.
  </p>
  <p>
    d.&nbsp;<span>Business Customer Communications</span>. If you have an account linked to a pharmaceutical manufacturer program or benefits or healthcare provider (&ldquo;third party), the third party may also send periodic electronic communications or post materials on our Mobile App, subject to their applicable terms and conditions and/or privacy policy.
  </p>
  <p>
    e.&nbsp;<span>Withdrawing Your Consent</span>. If you later decide that you do not want to receive future Communications electronically, please send an email to&nbsp;<a href="mailto:support@patientlinkbytc.com">support@patientlinkbytc.com</a>
  </p>
  <br/>
  <p>
    Your withdrawal of consent shall be effective within a reasonable time after we receive your withdrawal notice described above. Your withdrawal of consent will not affect the legal validity or enforceability of the Terms provided to, and electronically signed by, you prior to the effective date of your withdrawal. If you withdraw your consent to receive Communications electronically, we may terminate your account and/or access to the Service (or any part thereof).
  </p>
  <p>PATIENTLINK CONTENT SUBMISSIONS</p>
  <p>
    Our Service allows you and other users to post, link, store, share and otherwise make available certain information, images, videos, text and/or other content (&ldquo;Content&rdquo;). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness. By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, sell and distribute such Content on and through the Service. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights.
  </p>
  <p>
    You can remove Content that you posted by specifically deleting it. In certain instances, however, some Content (such as posts or comments you make) may not be completely removed and copies of your Content may continue to exist on the Service and/or elsewhere. We are not responsible or liable for the removal or deletion of (or the failure to remove or delete) any Content on the Service.
  </p>
  <p>
    You agree that you will not (and/or will not use the Website, the Mobile App and/or any part of our Services to):
  </p>
  <ol>
    <li>
      <span>Upload, post, email or otherwise transmit any User Content or other materials that:</span>
    <ul>
      <li>
       <span>are unlawful, harmful, threatening, abusive, harassing, inflammatory, tortious, defamatory, vulgar, libelous, slanderous, discriminatory, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable (in our sole discretion);</span>
      </li>
      <li>
        <span>contain adult content, including obscene, pornographic, and/or sexual terms, descriptions and/or images, nudity, profanity or graphic violence;</span>
      </li>
      <li>
        <span>incite or encourage criminal or terrorist activities or physical harm against another;</span>
      </li>
      <li>
        <span>exploit political agendas or “hot button” issues for commercial use; or that contain hate speech based upon the race, sex, national original, religious affiliation, marital status, sexual orientation, gender identify, or language of an individual or group;</span>
      </li>
      <li>
        <span>are not your own, or that you do not have a right to upload, post, email or otherwise transmit under any law or under contractual or fiduciary relationships (such as insider information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);</span>
      </li>
      <li>
        <span>infringe upon any patent, trademark, trade secret, copyright, right of privacy or publicity or other proprietary rights of any party or that are deceptive, misleading or fraudulent or that may otherwise be unlawful or give rise to civil or criminal liability;</span>
      </li>
      <li>
        <span>are unauthorized advertising, promotional materials, “junk mail,” “spam,” “phishing,” “chain letters,” “pyramid schemes,” or any other form of solicitation, opinions or notices, commercial or otherwise;</span>
      </li>
      <li>
        <span>contain software viruses, spyware, adware, worms, or any other computer malware or malicious code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</span>
      </li>
    </ul>
    </li>
    <li>
      <span>Harm minors in any way, or solicit or otherwise attempt to gain any information from a minor;</span>
    </li>
    <li>
      <span>Advertise or otherwise solicit funds or constitute a solicitation for goods or services;</span>
    </li>
    <li>
      <span>Impersonate any person or entity, including, but not limited to any user of the Services, a director, officer, employee, shareholder, agent or representative of TrialCard, our Business Customers or any other person or entity, or falsely state or otherwise misrepresent your affiliation with TrialCard. our Business Customers or any other person or entity; </span>
    </li>
    <li>
      <span>Provide false, misleading or inaccurate information to TrialCard or any other member;</span>
    </li>
    <li>
      <span>Create more than one unique public profile or register for more than one account except as specifically permitted in these Terms & Services, create an account on behalf of someone else, or create a false or misleading identity on the Services;</span>
    </li>
    <li>
      <span>Forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content or other materials transmitted to or through the Services;</span>
    </li>
    <li>
      <span>Attempt to probe, scan or test the vulnerability of the Services or any associated system or network, or breach security or authentication measures without proper authorization;</span>
    </li>
    <li>
      <span>Use or affect the Services in any manner that could damage, disable, overburden or impair the Services or its functionality, or disrupt the normal flow of dialogue (including, without limitation, &ldquo;flooding,&rdquo; &ldquo;mail bombing,&rdquo; or &ldquo;crashing&rdquo;), or otherwise act in a manner that negatively affects or otherwise diminishes the quality of another user&rsquo;s experience of the Services;</span>
    </li>
    <li>
      <span>Interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Services;</span>
    </li>
    <li>
      <span>Intentionally or unintentionally violate any applicable local, state, national or international law, including, but not limited to, any regulations having the force of law;</span>
    </li>
    <li>
      <span>&ldquo;Stalk&rdquo; or otherwise harass another user or employee of the Services;</span>
    </li>
    <li>
      <span>Solicit, collect or post personal data or attempt to solicit, collect or post personal data about other users of the Services, including usernames or passwords;</span>
    </li>
    <li>
      <span>Access or attempt to access another user&rsquo;s account without his or her consent;</span>
    </li>
    <li>
      <span>Reverse engineer, disassemble or decompile the Mobile App, or attempt to discover or recreate the source code for the Mobile App or any portion of the Services; or</span>
    </li>
    <li>
      <span>Assign, transfer or sublicense your rights as a registered user of the Services.</span>
    </li>
  </ol>
  <p>
    This list is an example and is not intended to be complete or exclusive. We don&rsquo;t have an obligation to monitor your access to or use of the Service or to review or edit any Content, but we have the right to do so for the purpose of operating the Service, to ensure your compliance with these Terms, or to comply with applicable law or the order or requirement of a court, administrative agency or other governmental body. We reserve the right, at any time and without prior notice, to remove or disable access to any Content, that we consider, in our sole discretion, to be in violation of these Terms or otherwise harmful to the Service.
  </p>
  <p>
    We also reserve the right to suspend or terminate your Account and your use of the Service at any time in case of violation by you of these Terms or if PatientLink discontinues providing the Service for any reason.
  </p>
  <p>
    YOU AGREE AND UNDERSTAND THAT YOU MAY BE HELD LEGALLY RESPONSIBLE FOR DAMAGES SUFFERED BY OTHER MEMBERS OR THIRD PARTIES AS THE RESULT OF YOUR REMARKS, INFORMATION, FEEDBACK OR OTHER CONTENT POSTED OR MADE AVAILABLE ON THE SERVICES (INCLUDING ANY PUBLIC FORUM) THAT IS DEEMED DEFAMATORY OR OTHERWISE LEGALLY ACTIONABLE. UNDER THE FEDERAL COMMUNICATIONS DECENCY ACT OF 1996, TRIALCARD IS NOT LEGALLY RESPONSIBLE, NOR CAN IT BE HELD LIABLE FOR DAMAGES OF ANY KIND, ARISING OUT OF OR IN CONNECTION TO ANY DEFAMATORY OR OTHERWISE LEGALLY ACTIONABLE REMARKS, INFORMATION, FEEDBACK OR OTHER CONTENT POSTED OR MADE AVAILABLE ON THE SERVICES.
  </p>
  <br />
  <p>LINKS TO THIRD PARTY SITES &amp; THIRD-PARTY CONTENT</p>
  <p>
    Certain Contents provided in the Website and/or Mobile App include links to websites of third parties (&ldquo;Third-Party Websites&rdquo;), some of whom may have established relationships with TrialCard and some of whom may not. We do not have control over the content and performance of Third-Party Websites. We are providing these links to you only as a convenience and are not responsible for the content or links displayed on such sites. You are responsible for and assume all risk arising from your use or reliance of any Third-Party Website.
  </p>
  <p>
    TRIALCARD HAS NOT REVIEWED, AND CANNOT REVIEW OR CONTROL, ALL OF THE MATERIAL, INCLUDING COMPUTER SOFTWARE OR OTHER GOODS OR SERVICES, MADE AVAILABLE ON OR THROUGH THIRD-PARTY WEBSITES AND DOES NOT ENDORSE (AND IS NOT RESPONSIBLE OR LIABLE FOR) ANY CONTENT, ADVERTISING, PRODUCTS, OR OTHER MATERIALS ON OR AVAILABLE FROM SUCH THIRD PARTY WEBSITES. ACCORDINGLY, TRIALCARD DOES NOT REPRESENT, WARRANT OR ENDORSE ANY THIRD-PARTY WEBSITE, OR THE ACCURACY, CURRENCY, CONTENT, FITNESS, LAWFULNESS OR QUALITY OF THE INFORMATION MATERIAL, GOODS OR SERVICES AVAILABLE THROUGH THIRD-PARTY WEBSITES. TRIALCARD DISCLAIMS, AND YOU AGREE TO ASSUME, ALL RESPONSIBILITY AND LIABILITY FOR ANY DAMAGES OR OTHER HARM, WHETHER TO YOU OR TO THIRD PARTIES, RESULTING FROM YOUR USE OF OR RELIANCE ON, ANY CONTENT, ADVERTISEMENTS, PRODUCTS OR OTHER RESOURCES AVAILABLE ON ANY THIRD-PARTY WEBSITES (REGARDLESS OF WHETHER WE DIRECTLY OR INDIRECTLY LINK TO SUCH CONTENT, ADVERTISEMENTS, PRODUCTS OR OTHER RESOURCES).
  </p>
  <p>
    YOU AGREE THAT, WHEN LINKING TO OR OTHERWISE ACCESSING OR USING A THIRD-PARTY WEBSITE, YOU ARE RESPONSIBLE FOR: (I) TAKING PRECAUTIONS AS NECESSARY TO PROTECT YOU AND YOUR COMPUTER SYSTEMS FROM VIRUSES, WORMS, TROJAN HORSES, MALICIOUS CODE AND OTHER HARMFUL OR DESTRUCTIVE CONTENT; (II) ANY DOWNLOADING, USE OR PURCHASE OF MATERIAL THAT IS OBSCENE, INDECENT, OFFENSIVE, OR OTHERWISE OBJECTIONABLE OR UNLAWFUL, OR THAT CONTAINS TECHNICAL INACCURACIES, TYPOGRAPHICAL MISTAKES AND OTHER ERRORS; (III) ANY DOWNLOADING, USE OR PURCHASE OF MATERIAL THAT VIOLATES THE PRIVACY OR PUBLICITY RIGHTS, OR INFRINGES THE INTELLECTUAL PROPERTY AND OTHER PROPRIETARY RIGHTS OF THIRD PARTIES, OR THAT IS SUBJECT TO ADDITIONAL TERMS AND CONDITIONS, STATED OR UNSTATED; (IV) ALL FINANCIAL CHARGES OR OTHER LIABILITIES TO THIRD PARTIES RESULTING FROM TRANSACTIONS OR OTHER ACTIVITIES; AND (V) READING AND UNDERSTANDING ANY TERMS OF USE OR PRIVACY POLICIES THAT APPLY TO THOSE THIRD-PARTY WEBSITES.
  </p>
  <p>DISCLAIMER</p>
  <p>
    TRIALCARD, ON BEHALF OF ITSELF AND ITS AFFILIATES AND ANY OF ITS OR THEIR RESPECTIVE THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS, HEREBY DISCLAIMS ALL WARRANTIES. THE PRODUCTS, OFFERINGS, CONTENT AND MATERIALS PROVIDED IN THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, TRIALCARD, ON BEHALF OF ITSELF, AND ITS AFFILIATES AND ANY OF ITS OR THEIR RESPECTIVE THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS, DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE, COMPATIBILITY, SECURITY, ACCURACY OR NON-INFRINGEMENT. NEITHER TRIALCARD, ANY OF ITS AFFILIATES, NOR ANY OF ITS OR THEIR RESPECTIVE LICENSORS, LICENSEES, SERVICE PROVIDERS OR SUPPLIERS WARRANT THAT THE APPLICATIONS OR ANY FUNCTION CONTAINED IN THE APPLICATIONS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE APPLICATIONS OR THE SERVERS THAT MAKE THE APPLICATIONS AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
  </p>
  <p>
    ANY PRODUCT, OFFERING, CONTENT AND MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR SOLE RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR DEVICES OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH PRODUCT, OFFERING, CONTENT OR MATERIAL.
  </p>
  <p>
    NEITHER TRIALCARD, ANY OF OUR AFFILIATES, NOR ANY OF OUR OR THEIR RESPECTIVE LICENSORS, LICENSEES, BUSINESS CUSTOMERS, SERVICE PROVIDERS OR SUPPLIERS WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE PRODUCTS, OFFERINGS, CONTENT AND MATERIALS IN THE SERVICES IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE.
  </p>
  <p>
    NO ADVICE OR INFORMATION, OBTAINED BY YOU FROM OUR PERSONNEL OR THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY PROVIDED FOR IN THESE TERMS &amp; CONDITIONS.
  </p>
  <p>LIMITATION OF LIABILITY</p>
  <p>
    YOU EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT SHALL TRIALCARD, ITS AFFILIATES OR ANY OF ITS OR THEIR RESPECTIVE THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR ANY OTHER DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, OR RESULTING FROM, (A) THE USE OR THE INABILITY TO USE THE SERVICE; (B) THE USE OF ANY CONTENT OR OTHER MATERIAL ON THE WEBSITE, OUR APPLICATIONS OR APPLICATIONS LINKED TO OUR APPLICATIONS, (C) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICES; (D) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (E) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR SERVICES; OR (F) ANY OTHER MATTER RELATING TO THE SERVICE.
  </p>
  <p>
    YOU, ON BEHALF OF YOURSELF, YOUR PERSONAL REPRESENTATIVES AND YOUR HEIRS, HEREBY VOLUNTARILY AGREE TO RELEASE, WAIVE, DISCHARGE, HOLD HARMLESS, DEFEND AND INDEMNIFY TRIALCARD, ITS AFFILIATES AND ANY OF TRIALCARD&rsquo;S AND ITS AFFILIATES&rsquo; OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, AFFILIATES, REPRESENTATIVES, SUBLICENSEES, SUCCESSORS AND ASSIGNS FROM ANY AND ALL CLAIMS, ACTIONS OR LOSSES FOR BODILY INJURY, PROPERTY DAMAGE, WRONGFUL DEATH, EMOTIONAL DISTRESS, LOSS OF SERVICES OR OTHER DAMAGES OR HARM, WHETHER TO YOU OR TO THIRD PARTIES, WHICH MAY RESULT FROM YOUR USE OF THE SERVICE.
  </p>
  <p>
    YOU UNDERSTAND AND AGREE THAT THE CANCELLATION OR TERMINATION OF YOUR SUBSCRIPTION IS YOUR SOLE RIGHT AND REMEDY WITH RESPECT TO ANY DISPUTE WITH US INCLUDING, WITHOUT LIMITATION, ANY DISPUTE RELATED TO, OR ARISING OUT OF: (I) THESE TERMS &amp; CONDITIONS OR OUR ENFORCEMENT OR APPLICATION THEREOF; (II) ANY PRACTICE OR POLICY OF TRIALCARD INCLUDING THESE TERMS AND OUR PRIVACY POLICY, OR OUR ENFORCEMENT OR APPLICATION OF THESE POLICIES; (III) THE CONTENT AVAILABLE THROUGH THE WEBSITE AND/OR APPLICATIONS OR ANY CHANGE IN CONTENT PROVIDED THROUGH THE WEBSITE AND/OR APPLICATIONS THROUGH THE SERVICES; (IV) YOUR ABILITY TO ACCESS AND/OR USE OUR WEBSITE AND/OR APPLICATIONS; OR (V) THE AMOUNT OR TYPES OF OUR FEES OR CHARGES, SURCHARGES, APPLICABLE TAXES, OR BILLING METHODS, OR ANY CHANGE TO OUR FEES OR CHARGES, APPLICABLE TAXES, OR BILLING METHODS.
  </p>
  <p>
    THE MAXIMUM LIABILITY OF TRIALCARD AND ITS AFFILIATES AND ITS OR THEIR RESPECTIVE THIRD-PARTY SERVICE PROVIDERS, LICENSORS AND SUPPLIERS TO YOU UNDER ALL CIRCUMSTANCES FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) SHALL NOT EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING OUR SERVICES. IF ANY PORTION OF THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID, LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. YOU AGREE THAT THIS LIMITATION OF LIABILITY REPRESENTS A REASONABLE ALLOCATION OF RISK AND IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN TRIALCARD AND YOU. THE SERVICES WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.
  </p>
  <p>
    ANY CAUSE OF ACTION BY YOU ARISING OUT OF OR RELATING TO THE SERVICES, OR THESE TERMS &amp; CONDITIONS MUST BE INSTITUTED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION AROSE OR BE FOREVER WAIVED AND BARRED. ALL ACTIONS SHALL BE SUBJECT TO THE LIMITATIONS SET FORTH IN THESE TERMS.
  </p>
  <p>INDEMNITY</p>
  <p>
    Without limiting any indemnification provisions of these Terms, you agree to indemnify, hold harmless and, at our option, defend TrialCard and our affiliates, and our and their respective officers, directors, members, employees, stockholders, managers, agents and representatives (collectively, &ldquo;Indemnified Parties&rdquo;) from any and all third party claims, actions, demands, causes of action, liability, damages and/or costs (including, but not limited to, reasonable attorneys&rsquo; fees and expenses) (collectively, &ldquo;Claims&rdquo;) arising from (i) your improper use of the Service or our products or offerings, (ii) your violation of these Terms, (iii) your provision to TrialCard or any of the Indemnified Parties of information or other data, (iv) your infringement or alleged infringement, or the infringement or use (or alleged infringement or alleged use) by any other user of your account, of any intellectual property or other right of any person or entity, or (v) your violation or alleged violation of any foreign or domestic, federal, state or local law or regulation.
  </p>
  <p>
    The Indemnified Parties will have the right, but not the obligation, to participate through counsel of their choice in any defense by you of any Claim as to which you are required to defend, indemnify or hold harmless the Indemnified Parties. You may not settle any Claim without the prior written consent of the concerned Indemnified Parties.
  </p>
  <p>GENERAL</p>
  <p>
  These Terms are governed by the laws of the State of North Carolina, without regard to any conflict of laws rules or principles. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service. If we make any material changes to these Terms, we will notify you of such changes by posting them on the PatientLink website or by sending you an email or other notification or message (including push notifications and in-app news notices) and we will indicate when such changes will become effective. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised Terms.
  </p>
  <p>QUESTIONS &amp; CONTACT INFORMATION</p>
  <p>Please contact us if you have any questions about our Terms.</p>
  <span>  
    <span style="background-color: yellow;">Effective: July 24, 2020</span>
  </span>
  <br/>
  <br/>
  <p>LEXICOMP END USER LICENSE AGREEMENT</p>
  <p>
  The following end-user license agreement relates to the Lexicomp IntegratedTM platform, which is integrated into ’, and which provides information on drug interactions. By accepting our Terms of Use, you are also agreeing to the Lexicomp IntegratedTM Product End-User License Agreement as the end user.
  </p>
  <p>LEXICOMP INTEGRATED TM PRODUCT END-USER LICENSE AGREEMENT</p>
  <p>
    This product utilizes the Lexicomp Integrated<span><sup>TM</sup></span> platform (the &ldquo;Lexicomp Product), which contains information provided by Lexi-Comp, Inc. (D.B.A. Lexicomp) (&ldquo;Lexicomp&rdquo;) and/or Cerner Multum, Inc. (&ldquo;Multum&rdquo;). The Lexicomp Product is a product licensed to you by Lexicomp. The Lexicomp Product and the &ldquo;Client System&rdquo; are separate products provided by separate entities. The Lexicomp Product is intended for use in the United States.
  </p>
  <p>
    Your use of this product acknowledges acceptance of these restrictions, disclaimers, and limitations. You expressly acknowledge and agree that neither Lexicomp nor Multum is responsible for the results of your decisions resulting from the use of the Lexicomp Product, including, but not limited to, your choosing to seek or not to seek professional medical care, or from choosing or not choosing specific treatment based on the Lexicomp Product.
  </p>
  <p>
    Every effort has been made to ensure that the information provided in the Lexicomp Product is accurate, up-to-date, and complete, but no guarantee is made to that effect. In addition, the drug information contained herein may be time sensitive. The information contained in the Lexicomp Product has been compiled for use by healthcare practitioners and individuals in the United States. Providers do not warrant that uses outside of the United States are appropriate.
  </p>
  <p>
    The Lexicomp Product does not endorse drugs, diagnose patients, or recommend therapy. The Lexicomp Product is an informational resource designed to assist licensed healthcare practitioners in caring for their patients and provide consumers with drug specific information. Healthcare practitioners should use their professional judgment in using the information provided. The Lexicomp Product is not a substitute for the care provided by licensed healthcare practitioners and consumers are urged to consult with their healthcare practitioner in all instances. The absence of a warning for a given drug or drug combination in no way should be construed to indicate that the drug or drug combination is safe, effective or appropriate for any given patient.
  </p>
  <p>
    Lexicomp does not assume any responsibility for any aspect of healthcare administered or not administered with the aid of information the Lexicomp Product provides.
  </p>
  <p>
    The Lexicomp Product is copyright 2012 Lexi-Comp, Inc. and/or Cerner Multum, Inc. All rights reserved. Client System as integrated with the Lexicomp Product is is covered by U.S. Patent No. 5,833,599 and U.S. Patent No. 6,317,719.
  </p>
  <p>DISCLAIMER OF WARRANTIES</p>
  <p>
    THE END-USER ACKNOWLEDGES THAT THE LEXICOMP PRODUCT IS PROVIDED ON AN &ldquo;AS IS&rdquo; BASIS. EXCEPT FOR WARRANTIES WHICH MAY NOT BE DISCLAIMED AS A MATTER OF LAW, LEXICOMP, MULTUM AND ITS AFFILIATES MAKES NO REPRESENTATIONS OR WARRANTIES WHATSOEVER, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO REPRESENTATIONS OR WARRANTIES REGARDING THE ACCURACY OR NATURE OF THE CONTENT OF THE LEXICOMP PRODUCT, WARRANTIES OF TITLE, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
  </p>
  <p>
  IN ADDITION, WITHOUT LIMITING THE FOREGOING, THE LEXICOMP PRODUCT HAS BEEN DESIGNED FOR USE IN THE UNITED STATES ONLY AND COVERS THE DRUG PRODUCTS USED IN PRACTICE IN THE UNITED STATES. NEITHER LEXICOMP NOR MULTUM PROVIDE ANY CLINICAL INFORMATION NOR DO LEXICOMP NOR MULTUM CHECK FOR DRUGS NOT AVAILABLE FOR SALE IN THE UNITED STATES AND CLINICAL PRACTICE PATTERNS OUTSIDE THE UNITED STATES MAY DIFFER SUBSTANTIALLY FROM INFORMATION SUPPLIED BY THE LEXICOMP PRODUCT. NEITHER LEXICOMP NOR MULTUM WARRANT THAT USES OUTSIDE THE UNITED STATES ARE APPROPRIATE.
  </p>
  <p>
    The End-User acknowledges that updates to the Lexicomp Product are at the sole discretion of Lexicomp. Lexicomp makes no representations or warranties whatsoever, express or implied, with respect to the compatibility of the Lexicomp Product, or future releases thereof, with any computer hardware or software, nor does Lexicomp represent or warrant the continuity of the features or the facilities provided by or through the Lexicomp Product as between various releases thereof.
  </p>
  <p>
    Any warranties expressly provided herein do not apply if: (i) the End-User alters, mishandles or improperly uses, stores or installs all, or any part, of the Lexicomp Product, (ii) the End-User uses, stores or installs the Lexicomp Product on a computer system which fails to meet the specifications provided by Lexicomp, or (iii) the breach of warranty arises out of or in connection with acts or omissions of persons other than Lexicomp.
  </p>
  <p>ASSUMPTION OF RISK, DISCLAIMER OF LIABILITY, INDEMNITY</p>
  <p>
    THE END-USER ASSUMES ALL RISK FOR SELECTION AND USE OF THE LEXICOMP PRODUCT AND CONTENT PROVIDED THEREON. NEITHER LEXICOMP NOR MULTUM SHALL NOT BE RESPONSIBLE FOR ANY ERRORS, MISSTATEMENTS, INACCURACIES OR OMISSIONS REGARDING CONTENT DELIVERED THROUGH THE LEXICOMP PRODUCT OR ANY DELAYS IN OR INTERRUPTIONS OF SUCH DELIVERY.
  </p>
  <p>
    THE END-USER ACKNOWLEDGES THAT LEXICOMP AND/OR MULTUM: (A) HAS NO CONTROL OF OR RESPONSIBILITY FOR THE END-USER&rsquo;S USE OF THE LEXICOMP PRODUCT OR CONTENT PROVIDED THEREON, (B) HAS NO KNOWLEDGE OF THE SPECIFIC OR UNIQUE CIRCUMSTANCES UNDER WHICH THE LEXICOMP PRODUCT OR CONTENT PROVIDED THEREON MAY BE USED BY THE END-USER, (C) UNDERTAKES NO OBLIGATION TO SUPPLEMENT OR UPDATE CONTENT OF THE LEXICOMP PRODUCT, AND (D) HAS NO LIABILITY TO ANY PERSON FOR ANY DATA OR INFORMATION INPUT ON THE LEXICOMP PRODUCT BY PERSONS OTHER THAN LEXICOMP OR MULTUM RESPECTIVELY.
  </p>
  <p>
    LEXICOMP, MULTUM AND THEIR RESPECTIVE AFFILIATES SHALL NOT BE LIABLE TO ANY PERSON (INCLUDING BUT NOT LIMITED TO THE END-USER AND PERSONS TREATED BY OR ON BEHALF OF THE END-USER) FOR, AND THE END-USER AGREES TO INDEMNIFY AND HOLD LEXICOMP AND MULTUM HARMLESS FROM ANY CLAIMS, LAWSUITS, PROCEEDINGS, COSTS, ATTORNEYS&rsquo; FEES, DAMAGES OR OTHER LOSSES (COLLECTIVELY, &ldquo;LOSSES&rdquo;) ARISING OUT OF OR RELATING TO (A) THE END-USER&rsquo;S USE OF THE LEXICOMP PRODUCT OR CONTENT PROVIDED THEREON OR ANY EQUIPMENT FURNISHED IN CONNECTION THEREWITH AND (B) ANY DATA OR INFORMATION INPUT ON THE LEXICOMP PRODUCT BY END-USER, IN ALL CASES INCLUDING BUT NOT LIMITED TO LOSSES FOR TORT, PERSONAL INJURY, MEDICAL MALPRACTICE OR PRODUCT LIABILITY. FURTHER, WITHOUT LIMITING THE FOREGOING, IN NO EVENT SHALL LEXICOMP, MULTUM AND THEIR RESPECTIVE AFFILIATES BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, LOSS OF BUSINESS, OR DOWN TIME, EVEN IF LEXICOMP OR MULTUM HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE INFORMATION CONTAINED WITHIN THE LEXICOMP PRODUCT IS INTENDED FOR USE ONLY AS AN INFORMATIONAL TOOL AND END-USERS ARE URGED TO CONSULT WITH A PHYSICIAN OR OTHER HEALTHCARE PROFESSIONAL REGARDING THEIR SPECIFIC SITUATION FOR DIAGNOSIS OR BY PHYSICIANS AND PROFESSIONALS WHO SHOULD RELY ON THEIR CLINICAL DISCRETION AND JUDGMENT IN DIAGNOSIS AND TREATMENT. AS BETWEEN THE END-USER AND LEXICOMP OR MULTUM, THE END-USER HEREBY ASSUMES FULL RESPONSIBILITY FOR INSURING THE APPROPRIATENESS OF USING AND RELYING UPON THE INFORMATION IN VIEW OF ALL ATTENDANT CIRCUMSTANCES, INDICATIONS, AND CONTRAINDICATIONS.
  </p>
  <p>LIABILITY OF LEXICOMP TO THE END-USER</p>
  <p>Under no circumstances shall Lexicomp, Multum, and their respective affiliates be liable to the End-User or any other person for any direct, indirect, exemplary, special or consequential damages arising out of or relating to the End-User&rsquo;s use of or inability to use the Lexicomp Product or the content of the Lexicomp Product provided thereon or any equipment furnished in connection therewith. Lexicomp&rsquo;s and Multum&rsquo;s total maximum cumulative liability hereunder in connection with this Agreement, whether arising under contract or otherwise, are limited to the fees received by Lexicomp under this Agreement specifically relating to the End-User&rsquo;s use of the Lexicomp Product or product which is the subject of the claim.</p>
`;
