import React, { useEffect, Dispatch, SetStateAction } from 'react';

import throttle from 'lodash.throttle';
import { Calendar, Direction } from 'react-native-calendars';
import { useSelector } from 'react-redux';

import { ArrowDown } from 'assets/icons';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { getMedTrackerInfo } from 'store/selectors';
import componentsRefs from 'utils/componentsRefs';

import { CustomDayComponent } from './CustomDayComponent';
import { scrollToCurrentDay } from './scrollToCurrentDay';
import { useCalendarStyles } from './styles';

type CustomCalendarProps = {
  isSelectable?: boolean;
  selectedDate: number;
  setSelectedDate: Dispatch<SetStateAction<number>>;
  setRequestedDate: Dispatch<SetStateAction<number>>;
};

export const CustomCalendar = ({
  isSelectable,
  selectedDate,
  setSelectedDate,
  setRequestedDate,
}: CustomCalendarProps) => {
  const { isMobile, width: deviceWidth } = useDeviceInfoContext();

  const medTrackerInfo = useSelector(getMedTrackerInfo);
  const calendarStyle = useCalendarStyles();

  const addMonth = () => {
    setRequestedDate((prev) => {
      const date = new Date(prev);
      date.setMonth(date.getMonth() + 1);
      return date.getTime();
    });
  };

  const throttleAddMonth = throttle(addMonth, 1000);

  const substructMonth = () => {
    setRequestedDate((prev) => {
      const date = new Date(prev);
      date.setMonth(date.getMonth() - 1);
      return date.getTime();
    });
  };

  const throttleSubstructMonth = throttle(substructMonth, 1000);

  useEffect(() => {
    const timerID = setTimeout(() => scrollToCurrentDay(deviceWidth), 1000);

    return () => clearTimeout(timerID);
  }, [deviceWidth]);

  const renderArrow = (direction: Direction) => (
    <ArrowDown
      style={{
        transform: [{ rotate: `${direction === 'right' ? '-' : ''}90deg` }],
      }}
    />
  );

  return (
    <Calendar
      shownAsScrollRow={isMobile}
      hideDayNames={isMobile}
      renderArrow={renderArrow}
      componentsRefs={componentsRefs}
      theme={calendarStyle}
      dayComponent={(props) => (
        <CustomDayComponent
          isSelectable={isSelectable}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          medTrackerInfo={medTrackerInfo}
          {...props}
        />
      )}
      onPressArrowLeft={throttleSubstructMonth}
      onPressArrowRight={throttleAddMonth}
    />
  );
};

export default CustomCalendar;
