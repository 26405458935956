import produce from 'immer';

import { SetSelectedAnswerForQuestion } from 'store/actions/user/actionCreators/surveys/setSelectedAnswerForQuestion';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setSelectedAnswerForQuestion = (
  state = INITIAL_STATE,
  payload: ReturnType<SetSelectedAnswerForQuestion>['payload']
): User => {
  const { questionId, answerId, multi, isSelected } = payload;
  const nextSurveyState = produce(state.surveys, (draftSurveys) => {
    const question = draftSurveys.entities.questions?.[questionId];

    if (question) {
      question.answers.forEach((answer) => {
        if (answer.uuid === answerId) {
          answer.is_checked = isSelected;
          return;
        }
        if (!multi) answer.is_checked = false;
      });
      if (question.answers.some((answer) => answer.is_checked)) {
        question.is_answered = true;
      }
    }
  });
  return {
    ...state,
    surveys: nextSurveyState,
  };
};

export default setSelectedAnswerForQuestion;
