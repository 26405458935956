import React, { useRef } from 'react';

import { TouchableWithoutFeedback, KeyboardAvoidingView, Keyboard } from 'react-native';

import { AppScrollView } from 'components';
import { AppScrollViewRef } from 'components/AppScrollView/types';
import { isNativeDevice } from 'utils/helpers';

import { styles, behavior } from './styles';
import type { KeyboardAvoidProps } from './types';

export const KeyboardAvoid = ({ children }: KeyboardAvoidProps) => {
  const scrollViewRef = useRef<AppScrollViewRef>(null);

  const scrollToBottom = () => {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  };

  return isNativeDevice ? (
    <KeyboardAvoidingView behavior={behavior} style={styles.avoidingView}>
      <AppScrollView
        ref={scrollViewRef}
        contentContainerStyle={styles.scrollView}
        bounces={false}
        onLayout={scrollToBottom}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
          <>{children}</>
        </TouchableWithoutFeedback>
      </AppScrollView>
    </KeyboardAvoidingView>
  ) : (
    <AppScrollView ref={scrollViewRef} contentContainerStyle={styles.scrollView}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <>{children}</>
      </TouchableWithoutFeedback>
    </AppScrollView>
  );
};

export default KeyboardAvoid;
