import throttle from 'lodash.throttle';

export const handlePromise = async <T>(
  handler: () => Promise<T>,
  throttleDelay?: number
): Promise<[T | undefined, any]> => {
  try {
    const data = await (throttleDelay ? throttle(handler, throttleDelay)() : handler());
    return [data, null];
  } catch (error) {
    return [undefined, error];
  }
};
