import * as ActionTypes from 'store/action-types';

export type SetSelectedProgramID = (payload: number) => {
  type: typeof ActionTypes.SET_SELECTED_PROGRAM_ID;
  payload: typeof payload;
};

export const setSelectedProgramID: SetSelectedProgramID = (payload) => ({
  type: ActionTypes.SET_SELECTED_PROGRAM_ID,
  payload,
});
