import { useCallback, useRef } from 'react';

import { useToast } from 'react-native-toast-notifications';
import { ToastOptions } from 'react-native-toast-notifications/lib/typescript/toast';

const defaultToastMessages = ['Nice job!', 'Keep it up!', 'Well done!', 'Thanks!'];

interface ReturnValue {
  showNext: (params?: { additionalMessage?: string; options?: ToastOptions }) => void;
}

export const useNotificationToastLoop = (messages = defaultToastMessages): ReturnValue => {
  const toast = useToast();
  const index = useRef(0);

  const showNext = useCallback<ReturnValue['showNext']>(
    ({ additionalMessage, options } = {}) => {
      if (!messages.length) throw new Error('You should set one message at least');

      toast.hideAll();
      toast.show(messages[index.current] + additionalMessage || '', {
        type: 'success',
        animationType: 'zoom-in',
        duration: 1000,
        ...options,
      });

      index.current++;
      if (index.current === messages.length) index.current = 0;
    },
    [messages, toast]
  );

  return { showNext };
};
