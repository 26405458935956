import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Benefits = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M3 14V9.333M3 6.667V2M8 14V8M8 5.333V2M13 14v-3.333M13 8V2M1 9.333h4M6 5.333h4M11 10.667h4'
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Benefits;
