export enum TabTypesEnum {
  ABOUT_YOUR_DOSE = 'ABOUT_YOUR_DOSE',
  DRUG_INTERACTIONS = 'DRUG_INTERACTIONS',
  DETAILS = 'DETAILS',
}

export type Tab = {
  text: string;
  type: TabTypesEnum;
};

export type MedicationDetailsRouteProps = {
  params: { medicationId?: number; medicationProgramUuid?: string; reminderId?: number };
  key: string;
  name: string;
};
