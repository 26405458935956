import {
  SET_MFA_CODE_WAS_SEND,
  SET_MFA_COUNTING_DOWN,
  SET_MFA_INFO,
  SET_MFA_SESSION,
  SET_MFA_USER_PHONE_NUMBER,
} from 'store/action-types/user/mfaConfig';
import { MFAConfigurationActions } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { MFAConfigInfoState } from './types';

const mfaConfig = (state = INITIAL_STATE, action: MFAConfigurationActions): MFAConfigInfoState => {
  switch (action.type) {
    case SET_MFA_INFO:
      return {
        ...state,
        mfaInfo: {
          ...state.mfaInfo,
          ...action.payload,
        },
      };
    case SET_MFA_SESSION:
      return {
        ...state,
        mfaInfo: { ...state.mfaInfo, session: action.payload },
      };
    case SET_MFA_CODE_WAS_SEND:
      return {
        ...state,
        isMFACodeWasSend: action.payload,
      };
    case SET_MFA_COUNTING_DOWN:
      return {
        ...state,
        mfaCountingDown: action.payload,
      };
    case SET_MFA_USER_PHONE_NUMBER:
      return {
        ...state,
        userMFAPhoneNumber: action.payload,
      };

    default:
      return state;
  }
};

export default mfaConfig;
