import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { TouchableOpacityProps } from 'react-native';
import { useSelector } from 'react-redux';

import ArrowLeft from 'assets/icons/ArrowLeft';
import { getHasModifications } from 'store/selectors/app-global-state';
import { showAlert } from 'utils/alert';
import { isNativeDevice } from 'utils/helpers';

import { ButtonWrapper, ButtonText } from './styles';

export type BackButtonProps = {
  color?: string;
  navigation: DrawerNavigationHelpers;
};

export const BackButton = ({ color, navigation, ...rest }: BackButtonProps & TouchableOpacityProps) => {
  const hasModifications = useSelector(getHasModifications);

  const goBackAction = () => {
    if (!isNativeDevice) {
      window.history.back();
      return;
    }
    navigation.goBack();
  };

  const backButtonPressHandler = () => {
    if (hasModifications) {
      showAlert(() => {
        goBackAction();
      });
      return;
    }
    goBackAction();
  };

  return (
    <ButtonWrapper onPress={backButtonPressHandler} {...rest}>
      <ArrowLeft stroke={color} />
      <ButtonText color={color}>Back</ButtonText>
    </ButtonWrapper>
  );
};

export default BackButton;
