import { useState, useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import { SurveyInterface } from 'api';
import { useTimer } from 'hooks';
import { reduxStore } from 'store';
import { setSurveyCompleted, setSurveyProgress, removeSelectedSurvey, removeSelectedSurveyId } from 'store/actions';
import type { Survey, UnnormalizedSurvey, Question } from 'store/reducers/user-info/types/survey';
import { QuestionTypeEnum, SurveyStatusEnum } from 'store/reducers/user-info/types/survey';
import { AnyFunction } from 'utils/types';

import { surveyAnalyticsActions } from './constants';
import { surveyAnalyticsHandler } from './helpers';

const {
  SURVEY_STARTED,
  SURVEY_COMPLETED,
  SURVEY_NEXT_STEP_TRIGGERED,
  SURVEY_PREVIOUS_STEP_TRIGGERED,
  SURVEY_INTERRUPTED,
} = surveyAnalyticsActions;

type UseHandleSurveyProps = {
  selectedSurvey?: Survey;
  unnormalizedSelectedSurvey?: UnnormalizedSurvey;
  questionsOfSelectedSurvey?: Question[];
  onRequestClose: AnyFunction;
  isSurveyStarted: boolean;
};

// TODO: Extract analytics timer

const calculateSurveyProgress = (user_survey_uuid: string, questionsOfSelectedSurvey?: Question[]) => {
  if (!user_survey_uuid || !questionsOfSelectedSurvey) return 0;
  const currentSurveyProgress =
    (questionsOfSelectedSurvey.filter((question) => question.is_answered).length * 100) /
    questionsOfSelectedSurvey.length;

  const formattedSurveyProgress = parseFloat(currentSurveyProgress.toFixed(1));

  reduxStore.dispatch(setSurveyProgress({ surveyId: user_survey_uuid, progress: formattedSurveyProgress }));

  return formattedSurveyProgress;
};

export const useHandleSurvey = ({
  questionsOfSelectedSurvey,
  onRequestClose,
  isSurveyStarted,
}: UseHandleSurveyProps) => {
  const dispatch = useDispatch();

  const [isLastModalVisible, setLastModalVisible] = useState(false);
  const isLastModalShowed = useRef(false);

  useEffect(() => {
    if (!isLastModalVisible && isLastModalShowed.current) {
      onRequestClose();
    }
  }, [dispatch, isLastModalVisible, onRequestClose]);

  const [currentStep, setCurrentStep] = useState(1);
  const { start, pause, reset, timerStatus } = useTimer();

  const currentQuestion = questionsOfSelectedSurvey?.[currentStep - 1];
  const selectedAnswers = currentQuestion?.answers?.filter((option) => option.is_checked);

  useEffect(() => {
    if (isSurveyStarted) {
      start();
      surveyAnalyticsHandler(SURVEY_STARTED);
    }
  }, [isSurveyStarted, start]);

  useEffect(() => {
    if (!isSurveyStarted && timerStatus === 'running') {
      reset();
      surveyAnalyticsHandler(SURVEY_INTERRUPTED, { event_label: currentQuestion?.title || 'No question data' });
    }
  }, [isSurveyStarted, currentQuestion?.title, timerStatus, reset]);

  const handleAnswerSubmit =
    (unnormalizedSelectedSurvey?: UnnormalizedSurvey, questionsOfSelectedSurvey?: Question[]) => () => {
      if (!unnormalizedSelectedSurvey) return;

      const progress = calculateSurveyProgress(unnormalizedSelectedSurvey.user_survey_uuid, questionsOfSelectedSurvey);

      if (unnormalizedSelectedSurvey.questions.length !== currentStep) {
        if (progress < 100) {
          SurveyInterface.post({
            ...unnormalizedSelectedSurvey,
            status: SurveyStatusEnum.IN_PROGRESS,
            percent_of_complete: progress,
          });
        }
        setCurrentStep(currentStep + 1);
        surveyAnalyticsHandler(SURVEY_NEXT_STEP_TRIGGERED);
        return;
      }
      dispatch(setSurveyCompleted(true));
      setLastModalVisible(true);
      isLastModalShowed.current = true;
      const passedTime = pause();
      surveyAnalyticsHandler(SURVEY_COMPLETED, { event_label: passedTime });
      SurveyInterface.post({
        ...unnormalizedSelectedSurvey,
        status: SurveyStatusEnum.COMPLETED,
        percent_of_complete: 100,
      });
      dispatch(removeSelectedSurvey({ id: unnormalizedSelectedSurvey.user_survey_uuid }));
      dispatch(removeSelectedSurveyId());
    };

  const handleBackButton = (currentStep: number) => () => {
    setCurrentStep(currentStep - 1);
    surveyAnalyticsHandler(SURVEY_PREVIOUS_STEP_TRIGGERED);
  };

  const nextButtonIsAvailable =
    currentQuestion?.question_type === QuestionTypeEnum.MULTI_SELECT
      ? selectedAnswers && selectedAnswers.length > 0
      : !!selectedAnswers?.length;

  return {
    currentStep,
    currentQuestion,
    nextButtonIsAvailable,
    isLastModalVisible,
    setLastModalVisible,
    handleAnswerSubmit,
    handleBackButton,
  };
};
