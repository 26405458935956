import { PartnerRegistrationInterface } from 'api';
import { UserTypeEnum } from 'enums';
import { AppThunkAction } from 'store';

type SaveUserBasicInfo = () => AppThunkAction<Promise<void>>;

export const saveUserBasicInfo: SaveUserBasicInfo = () => async (_, getState) => {
  const currentUserInfo = getState().userInfo;
  try {
    await PartnerRegistrationInterface.patch({
      data: {
        firstName: currentUserInfo.userFirstName,
        lastName: currentUserInfo.userLastName,
        preferredName: currentUserInfo.userPreferredName,
        pointOfContact: currentUserInfo.contactPersonName,
        type: currentUserInfo.userType.toUpperCase() as UserTypeEnum,
        timezoneHours: currentUserInfo.userTimeZoneInformation,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
