import { Dimensions, StyleSheet } from 'react-native';
import styled, { css, useTheme } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

import { BasicInfoBlock } from '../styles';

export const useContactInformationStyles = () => {
  const { colors, isMobile } = useTheme();

  return StyleSheet.create({
    wrapper: { marginTop: isMobile ? 0 : 7 },
    negative: {
      margin: 4,
      color: colors.negative,
      ...(!isNativeDevice && { height: 0 }),
    },
  });
};

export const ContactInfoBlock = styled(BasicInfoBlock)`
  width: ${isNativeDevice ? Dimensions.get('window').width / 2 - 80 : 'calc(50% - 80px)'};
  margin-right: 80px;
  ${({ theme }) =>
    theme.isTablet &&
    css`
      margin-right: 0;
      width: 100%;
    `}
`;
