import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const MenuRightArrow = (props: SvgProps) => {
  return (
    <Svg width={6} height={8} fill='none' {...props}>
      <Path
        d='M1.286 7.429L4.714 4 1.286.572'
        stroke={props.stroke || defaultTheme.colors.gray1}
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};

export default MenuRightArrow;
