import { DefaultTheme } from 'styled-components/native';

export type FontFamilies = 'default' | 'semiBold' | 'bold';

export const defaultTheme: DefaultTheme & { isMobile?: boolean; colors: { mangoOrange: string } } = {
  borderRadius: '4px',
  colors: {
    main: '#FFFFFF',
    primary: '#0F4445',
    mangoPrimary: '#0F4445',
    secondary: '#00AAC6',
    mangoSecondary: '#00AAC6',
    white: '#FFFFFF',
    black: '#000000',
    orangePrimary: '#F19941',
    error: '#F19941',

    // gray
    gray1: '#1D242D',
    gray2: '#6E7577',
    gray3: '#CDD6E1',
    gray4: '#F1F7FF',
    gray5: '#C4C4C4',

    // status
    positive: '#00A65D',
    warning: '#F0CF22',
    negative: '#F33938',

    // mango brand
    mangoOrange: '#E59D53',
  },
  fontFamilies: {
    default: 'Montserrat',
    semiBold: 'Montserrat-SemiBold',
    bold: 'Montserrat-Bold',
  },
  fontSizes: {
    10: { fontSize: 10, lineHeight: 12 },
    12: { fontSize: 12, lineHeight: 13 },
    14: { fontSize: 14, lineHeight: 17 },
    16: { fontSize: 16, lineHeight: 19.5 },
    18: { fontSize: 18, lineHeight: 22 },
    20: { fontSize: 20, lineHeight: 24.5 },
    22: { fontSize: 22, lineHeight: 27 },
    24: { fontSize: 24, lineHeight: 29 },
  },
};
