export const SET_COMPLETED_SERVICE_ACTION = 'SET_COMPLETED_SERVICE_ACTION';
export const SET_COMPLETED_SERVICE_DIGITAL_RESOURCE = 'SET_COMPLETED_SERVICE_DIGITAL_RESOURCE';
export const SET_COMPLETED_SERVICE_REQUEST = 'SET_COMPLETED_SERVICE_REQUEST';

export const SET_CURRENTLY_ACTIVE_SERVICE = 'SET_CURRENTLY_ACTIVE_SERVICE';
export const REMOVE_CURRENTLY_ACTIVE_SERVICE = 'REMOVE_CURRENTLY_ACTIVE_SERVICE';

export const SET_SELECTED_SERVICE_ACTION = 'SET_SELECTED_SERVICE_ACTION';
export const REMOVE_SELECTED_SERVICE_ACTION = 'REMOVE_SELECTED_SERVICE_ACTION';
export const SET_SELECTED_SERVICE_DIGITAL_RESOURCE = 'SET_SELECTED_SERVICE_DIGITAL_RESOURCE';
export const REMOVE_SELECTED_SERVICE_DIGITAL_RESOURCE = 'REMOVE_SELECTED_SERVICE_DIGITAL_RESOURCE';
export const SET_SELECTED_SERVICE_REQUEST = 'SET_SELECTED_SERVICE_REQUEST';
export const REMOVE_SELECTED_SERVICE_REQUEST = 'REMOVE_SELECTED_SERVICE_REQUEST';

export const SET_VIEWED_FINANCIAL_ASSISTANCE = 'SET_VIEWED_FINANCIAL_ASSISTANCE';

export const INITIALIZE_PROGRAM_ADDITIONAL_SERVICES = 'INITILIAZE_PROGRAM_ADDITIONAL_SERVICES';

export const SET_SHIPPING_ADDRESS_INFO = 'SET_SHIPPING_ADDRESS_INFO';

export const SET_RESOURCES_NOTIFICATION_ICON_VISIBILITY = 'SET_RESOURCES_NOTIFICATION_ICON_VISIBILITY';
