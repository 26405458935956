import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { CLOSE_LEFT_SIDE_MENU_WIDTH } from 'style/sizes';
import { applyBreakpoints } from 'style/utils';

import { ToastContainerColor, ToastTypes } from './types';

export const useCustomToastStyles = (type?: ToastTypes) => {
  const { colors, ...theme } = useTheme();

  const containerColors: ToastContainerColor = {
    success: colors.positive,
    danger: colors.negative,
    warning: colors.warning,
    normal: colors.mangoSecondary,
    tracker_info: colors.mangoSecondary,
  };

  return StyleSheet.create({
    container: {
      paddingVertical: 14,
      paddingHorizontal: 32,
      borderRadius: 50,
      backgroundColor: containerColors[type || ''] || colors.mangoSecondary,
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 12,
      ...applyBreakpoints(theme, {
        sm: {},
        md: { left: CLOSE_LEFT_SIDE_MENU_WIDTH / 2 },
      }),
    },
    iconWrapper: { width: 16, height: 16, justifyContent: 'center', alignItems: 'center', marginRight: 8 },
    toastMessageText: {
      fontSize: 16,
      lineHeight: 19.5,
      color: colors.white,
      fontFamily: 'Montserrat-SemiBold',
    },
  });
};
