import { addressPattern, zipCodePattern } from 'utils/regulars';

import { UserAddress } from '../ConfirmShipingAddress/types';
import { InputField } from './types';

export const getInputFieldsList = (address: UserAddress): InputField[] => [
  {
    fieldName: 'addressLine1',
    labelText: 'Address Line 1',
    defaultValue: address.addressLine1 || '',
    rules: {
      required: true,
      pattern: {
        value: addressPattern,
        message: 'Invalid address',
      },
    },
  },
  {
    fieldName: 'addressLine2',
    labelText: 'Address Line 2',
    defaultValue: address.addressLine2 || '',
    rules: {
      required: false,
      pattern: {
        value: addressPattern,
        message: 'Invalid address',
      },
    },
  },
  {
    fieldName: 'city',
    labelText: 'City',
    defaultValue: address.city || '',
    rules: {
      required: true,
      pattern: {
        value: addressPattern,
        message: 'Invalid city',
      },
    },
  },
  {
    fieldName: 'state',
    labelText: 'State',
    defaultValue: address.state || '',
    rules: {
      required: true,
      pattern: {
        value: addressPattern,
        message: 'Invalid state',
      },
    },
  },
  {
    fieldName: 'zipCode',
    labelText: 'Zip Code',
    defaultValue: address.zipCode || '',
    rules: {
      required: true,
      pattern: {
        value: zipCodePattern,
        message: 'Invalid zip code',
      },
    },
  },
];
