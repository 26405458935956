import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useExplanationModalStyle = () => {
  const { colors, isMobile, fontFamilies, fontSizes } = useTheme();

  return StyleSheet.create({
    contentWrapper: {
      paddingHorizontal: isMobile ? 8 : 32,
      padding: isMobile ? 8 : 32,
      maxWidth: isMobile ? '100%' : 428,
      height: isMobile ? '100%' : 'auto',
    },
    explanationTitle: {
      ...fontSizes[24],
      color: colors.primary,
      fontFamily: fontFamilies.bold,
      marginBottom: 16,
      marginTop: 19,
    },
    explanationDescription: {
      ...fontSizes[16],
      color: colors.gray1,
      marginBottom: 22,
    },
    cancelButton: {
      width: 100,
      marginTop: 10,
    },
    exampleTitle: {
      ...fontSizes[16],
      color: colors.gray1,
      fontFamily: fontFamilies.bold,
      marginBottom: 10,
    },
    secondGrayColorText: {
      color: colors.gray2,
      fontFamily: fontFamilies.semiBold,
    },
    firstGrayColorText: {
      color: colors.gray1,
      fontFamily: fontFamilies.bold,
    },
    paragraphStyle: {
      marginBottom: 16,
    },
  });
};
