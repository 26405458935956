import { validatePastTimeForReminder } from '../helpers';
import { ConditionalFieldParams, ReminderFieldListParams, ReminderFieldsMap, StaticReminderFields } from './types';

export const getFieldsList = ({
  medicationOptions,
  medicationDisabled,
  isAsNeededOptionSelected,
  startDate = new Date(),
}: ReminderFieldListParams): StaticReminderFields => ({
  startDateField: {
    fieldName: 'start_date',
    labelText: 'Start Date',
    inputType: 'DatePicker',
    rules: {
      required: 'This field is required',
    },
  },
  medicationField: {
    fieldName: 'medicationId',
    labelText: 'Medication',
    inputType: 'Select',
    options: medicationOptions,
    disabled: medicationDisabled,
    rules: {
      required: 'This field is required',
    },
  },
  setTimeField: {
    fieldName: 'time_of_day',
    labelText: 'Set a time',
    inputType: 'TimePicker',
    defaultValue: '',
    shouldRender: !isAsNeededOptionSelected,
    isConditionalInput: true,
    rules: {
      validate: (value: string | Date) =>
        !validatePastTimeForReminder(startDate, value) || 'Please select the current time or a time in the future',
    },
  },
});

export const getDynamicFields = ({
  reminderOptions,
  isDynamicRecurringValue,
  dynamicOptions,
  recurringFieldName,
}: ConditionalFieldParams): ReminderFieldsMap[] => [
  {
    fieldName: 'recurring',
    labelText: '',
    showInfo: false,
    inputType: 'Select',
    options: reminderOptions,
    rules: {
      required: 'Required field',
    },
    shouldShowLabel: false,
    wrapperStyle: { flex: 1 },
  },
  {
    fieldName: recurringFieldName ?? '',
    labelText: '',
    inputType: 'Select',
    rules: {
      required: recurringFieldName ? 'Required field' : false,
    },
    isConditionalInput: true,
    shouldRender: isDynamicRecurringValue,
    options: dynamicOptions,
    shouldShowLabel: false,
    placeHolderText: '#',
  },
];
