import { Response } from '@types';
import { ShippingAddressResponse } from 'store/reducers/additional-services/types';

export enum ServiceTypesEnum {
  FORM_REQUEST = 'FORM_REQUEST',
  RESOURCE = 'RESOURCE',
  PHYSICAL_SHIPMENT = 'PHYSICAL_SHIPMENT',
}

export type ServiceTypes =
  | ServiceTypesEnum.FORM_REQUEST
  | ServiceTypesEnum.PHYSICAL_SHIPMENT
  | ServiceTypesEnum.RESOURCE;

enum ServiceIconTypesEnum {
  VIDEO = 'VIDEO',
  PACKAGE = 'PACKAGE',
  CLIPBOARD = 'CLIPBOARD',
  PHONE = 'PHONE',
  INJECTION = 'INJECTION',
}

export type ServiceIconTypes =
  | ServiceIconTypesEnum.VIDEO
  | ServiceIconTypesEnum.PHONE
  | ServiceIconTypesEnum.PACKAGE
  | ServiceIconTypesEnum.INJECTION
  | ServiceIconTypesEnum.CLIPBOARD;

enum ServiceFileTypesEnum {
  PDF = 'PDF',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export type ServiceFileTypes = ServiceFileTypesEnum.IMAGE | ServiceFileTypesEnum.PDF | ServiceFileTypesEnum.VIDEO;

type ServiceConsent = {
  id: string;
  requiredToProceed: boolean;
  requiredToCommunicate: boolean;
  title: string;
  body: string;
  actionText: string;
  createdAt: string;
  updatedAt: string;
  resourceService: number;
  formRequestService: any; // Need to check available types
};

type ServiceUserData = {
  createdAt: string;
  id: number;
  patientId: number;
  isSubmitted?: boolean;
  isNew?: boolean;
  uuid: string;
};

export type SelectionType = 'MULTI_SELECT' | 'SINGLE_SELECT';
export type UserHistoryCommunicationPurposeTypes = 'MISSING_INSURANCE_NOTIFICATION_TILE' | 'NEW_ADDITIONAL_SERVICE';
export type UserHistoryCommunicationChannelTypes = 'notification';

export type ServiceConfig = {
  id: number;
  uuid: number;
  label: string;
  modalTitle: string;
  modalDescription: string;
  icon: ServiceIconTypes;
  partnerProgram: number;
  type: ServiceTypes;
  fileType: ServiceFileTypes;
  repeatable: boolean;
  userData: ServiceUserData[];
  consentConfig: ServiceConsent[];
  question?: any; // Need to check available types
  answers?: any; // Need to check available types
  selectionType?: SelectionType;
  source: string;
};

export type AdditionalServicesPostData = {
  data?: ShippingAddressResponse;
  configs?: ServiceConfig[];
  type?: ServiceTypes;
  configUuid?: number;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  submittedAnswers?: number[];
  isSubmitted?: boolean;
  isNew?: boolean;
  userDataUuid: string;
};

export type CommunicationTemplate = {
  id: string;
  template: string;
  created_at: string;
  updated_at: string;
  tags: string[];
  subject: string;
};

export type AdditionalServicesNotification = Partial<{
  id: string;
  communication_uuid: string;
  manufacture_program_uuid: string;
  status: string;
  is_read: boolean;
  communication: {
    purpose: UserHistoryCommunicationPurposeTypes;
    channel: UserHistoryCommunicationChannelTypes;
    name: string;
    communication_template?: CommunicationTemplate;
  };
}>;

export type GetUserHistoryCommunicationParams = {
  channel?: UserHistoryCommunicationChannelTypes;
  purpose: UserHistoryCommunicationPurposeTypes;
};

export type AdditionalServicesNotificationsReturnData = Response<AdditionalServicesNotification[]>;

export type AdditionalServicesReturnData = Response<AdditionalServicesPostData>;
