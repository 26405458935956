import React, { useCallback, useEffect, useRef, useState } from 'react';

import { MaterialIcons } from '@expo/vector-icons';
import { Camera } from 'expo-camera';
import * as FileSystem from 'expo-file-system';
import { ImageEditor } from 'expo-image-editor';
import { Modal, TouchableOpacity, View } from 'react-native';

import { usePhotoPickerStyles } from 'components/PhotoPicker/styles';
import {
  getCorrectRatio,
  getValidImageSize,
  isAllowedMobileGalleryImageType,
  MAX_IMAGE_SIZE,
  notAllowedImageTypeAlert,
} from 'components/PhotoPicker/utils';
import { StandardText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';
import { getBase64FromImage, getImageFromGallery } from 'utils/getImageFromGallery';
import { isNativeDevice } from 'utils/helpers';

export type PhotoPickerProps = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onImagePick: (base64: string) => void;
  withGalleryPicking?: boolean;
};

export const PhotoPicker = ({ visible, setVisible, onImagePick, withGalleryPicking }: PhotoPickerProps) => {
  const { styles, colors } = useStylesWithAdditional(usePhotoPickerStyles);
  const camera = useRef<Camera>(null);

  const [pickedImage, setPickedImage] = useState<string>();
  const [isCropperVisible, setCropperVisible] = useState(false);

  const [isCameraReady, setCameraReady] = useState(false);
  const [isPhotoProcess, setPhotoProcess] = useState(false);

  const [ratio, setRatio] = useState('4:2');

  // Clear state helpers when camera will be closed
  useEffect(() => {
    if (visible) return;
    clearCamera();
  }, [visible]);

  // Get ratios when camera is ready
  useEffect(() => {
    isCameraReady &&
      camera.current?.getSupportedRatiosAsync().then((ratios) => {
        setRatio(getCorrectRatio(ratios));
      });
  }, [isCameraReady]);

  const onGalleryPress = useCallback(async () => {
    clearCamera();
    const image = await getImageFromGallery();

    if (!image?.uri) return;

    if (isAllowedMobileGalleryImageType(image.uri)) onPickImage(image.uri);
    else notAllowedImageTypeAlert();
  }, []);

  if (!isNativeDevice) return null;

  const clearCamera = () => {
    setPickedImage(undefined);
    setCropperVisible(false);
  };

  const onPickImage = (image: string) => {
    setPickedImage(image);
    setCropperVisible(true);
  };

  const takePhoto = async () => {
    if (!camera.current) return;

    setPhotoProcess(true);
    const image = await camera.current.takePictureAsync({ quality: 1, base64: false });
    setPhotoProcess(false);

    if (!image?.uri) return;

    if (isAllowedMobileGalleryImageType(image.uri)) onPickImage(image.uri);
    else notAllowedImageTypeAlert();
  };

  const confirmImage = async (uri?: string) => {
    if (!uri) return;

    const image = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
    const base64 = getBase64FromImage({
      base64: image,
      type: 'image',
      uri,
    });

    if (!base64) return;

    onImagePick((await getValidImageSize(base64, MAX_IMAGE_SIZE)) ?? '');

    clearCamera();
    setVisible(false);
  };

  const imageEditorJSX = (
    <View style={styles.cropper}>
      <ImageEditor
        asView
        visible={isCropperVisible}
        onCloseEditor={clearCamera}
        imageUri={pickedImage}
        fixedCropAspectRatio={4 / 3}
        lockAspectRatio
        minimumCropDimensions={{
          width: 100,
          height: 50,
        }}
        allowedTransformOperations={['crop', 'rotate']}
        onEditingComplete={(result) => confirmImage(result?.uri)}
      />
    </View>
  );

  const cameraJSX = (
    <Camera ref={camera} ratio={ratio} type='back' onCameraReady={() => setCameraReady(true)} style={styles.camera}>
      {!isCropperVisible ? (
        <>
          <View style={styles.topButtons}>
            <TouchableOpacity style={[styles.buttonContainer, styles.backButton]} onPress={() => setVisible(false)}>
              <MaterialIcons name='arrow-back' size={26} color={colors.white} />
            </TouchableOpacity>
          </View>
          <View style={styles.bottomButtonsContainer}>
            <TouchableOpacity
              onPress={takePhoto}
              disabled={!isCameraReady}
              style={[styles.buttonContainer, styles.takePhotoButton]}>
              <MaterialIcons name='camera' size={36} color={colors.white} />
            </TouchableOpacity>
            {withGalleryPicking && (
              <TouchableOpacity onPress={onGalleryPress} style={styles.galleryButton}>
                <MaterialIcons name='image' size={36} color={colors.white} />
              </TouchableOpacity>
            )}
          </View>
          {isPhotoProcess && <StandardText style={styles.loadingText}>LOADING...</StandardText>}
        </>
      ) : (
        imageEditorJSX
      )}
    </Camera>
  );

  return (
    <Modal statusBarTranslucent animated animationType='fade' presentationStyle='overFullScreen' visible={visible}>
      {cameraJSX}
    </Modal>
  );
};
