import { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { ReactourStep } from 'reactour';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { TutorialStep, TutorialTabName } from 'store/reducers/user-info/types/program';
import { getPrograms, getIsCopayUser, getFinancialAssistanceData } from 'store/selectors';
import { useIsBreakpoint } from 'style/hooks';
import { isNativeDevice, isWebBrowser } from 'utils/helpers';

import { WEB_STEPS_ORDER, WEB_STEP_SELECTOR, IGNORED_STEP_CONTENT } from './constants';

type TourStep = {
  title: string;
  description: string;
  isWebTour?: boolean;
};

const getFindTutorialFunction = (name: TutorialTabName) => (step: TutorialStep) =>
  step?.tutorialConfigurationTab?.name?.toLowerCase() === name?.toLowerCase();

export const useTutorialSteps = () => {
  const programs = useSelector(getPrograms);
  const tutorialSteps = programs[0]?.tutorialConfiguration.steps;

  const main = tutorialSteps.find(getFindTutorialFunction('main'));
  const home = tutorialSteps.find(getFindTutorialFunction('home'));
  const financialAssistance = tutorialSteps.find(getFindTutorialFunction('financialAssistance'));
  const benefits = tutorialSteps.find(getFindTutorialFunction('benefits'));
  const additionalServices = tutorialSteps.find(getFindTutorialFunction('additionalServices'));
  const rebateCenter = tutorialSteps.find(getFindTutorialFunction('rebateCenter'));
  const profile = tutorialSteps.find(getFindTutorialFunction('profile'));
  const support = tutorialSteps.find(getFindTutorialFunction('support'));
  const appDownload = tutorialSteps.find(getFindTutorialFunction('appDownload'));

  return {
    main,
    home,
    financialAssistance,
    benefits,
    additionalServices,
    rebateCenter,
    profile,
    support,
    appDownload,
  };
};

export const useTourGuideSteps = (): TourStep[] => {
  const { isTablet } = useDeviceInfoContext();

  const { main, home, financialAssistance, benefits, additionalServices, rebateCenter, profile, support, appDownload } =
    useTutorialSteps();

  const isXl = useIsBreakpoint('xl');
  const isSmallScreens = isXl || isTablet;

  const isCopayUser = useSelector(getIsCopayUser);
  const financialAssistanceData = useSelector(getFinancialAssistanceData);

  return [
    !!main && {
      title: main.title,
      description: main.description,
    },
    !!home && {
      title: home.title,
      description: home.description,
    },
    !!financialAssistanceData &&
      !!financialAssistance && {
        title: financialAssistance.title,
        description: financialAssistance.description,
      },
    !isCopayUser &&
      !!benefits && {
        title: benefits.title,
        description: benefits.description,
      },
    !!additionalServices && {
      title: additionalServices.title,
      description: additionalServices.description,
    },
    !isCopayUser &&
      !!rebateCenter && {
        title: rebateCenter.title,
        description: rebateCenter.description,
      },
    !!profile && {
      title: profile.title,
      description: profile.description,
    },
    !isSmallScreens &&
      isWebBrowser &&
      !!support && {
        title: support.title,
        description: support.description,
        isWebTour: true,
      },
    isWebBrowser &&
      !!appDownload && {
        title: appDownload.title,
        description: appDownload.description,
        isWebTour: true,
      },
  ].filter(Boolean) as TourStep[];
};

const createWebStep = (order: number, action?: (element: HTMLElement) => void): ReactourStep => ({
  content: '',
  selector: `[data-${WEB_STEP_SELECTOR}="${order}"]`,
  action,
  stepInteraction: false,
});

const contactSectionWebStep = createWebStep(WEB_STEPS_ORDER.CONTACT_CARD, (element) =>
  element?.scrollIntoView?.({ behavior: 'smooth', block: 'end', inline: 'nearest' })
);

const downloadCardSectionWebStep = createWebStep(WEB_STEPS_ORDER.DOWNLOAD_CARD, (element) =>
  element?.scrollIntoView?.({ behavior: 'smooth', block: 'end', inline: 'nearest' })
);

const desktopWebTours: ReactourStep[] = [contactSectionWebStep, downloadCardSectionWebStep];

// adding ignored step to make sure that smalls screens will have the same number of steps as desktop
const tabletAndMobileWebTours: ReactourStep[] = [
  {
    ...downloadCardSectionWebStep,
    content: IGNORED_STEP_CONTENT,
  },
  downloadCardSectionWebStep,
];

export const useWebSteps = () => {
  const isXl = useIsBreakpoint('xl');
  const [webStep, setWebStep] = useState<ReactourStep[]>([]);

  useEffect(() => {
    if (isNativeDevice) return setWebStep([]);

    const currentWebSteps = isXl ? tabletAndMobileWebTours : desktopWebTours;

    setWebStep(currentWebSteps);
  }, [isXl]);

  return webStep;
};

export const useTourStepsLength = () => useTourGuideSteps().length;
