import React, { useState } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { StyleSheet } from 'react-native';

import { Menu } from 'assets/icons';
import { patientColorLogo } from 'assets/images';
import { ModalComponent as Modal } from 'components/Modal';
import { Support } from 'components/Support';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { useHandleNavigationPush, useImageSizes } from 'hooks';
import { Routes } from 'routes/main';
import { MangoLogoWrapper, StyledImage, webStyles } from 'style/common';
import { defaultTheme } from 'style/theme';
import { NavigationPropObj } from 'utils/types';

import { ActionsWrapper, HeaderContainer, HeaderWrapper, MenuIconWrapper } from './styles';

const styles = StyleSheet.create({
  menuIconWrapper: { marginLeft: 16 },
  prescriptionInfoStyle: { marginRight: 0 },
});

export const MedicineCenterHeader = ({ navigation }: Partial<NavigationPropObj<DrawerNavigationHelpers>>) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const { isMobile } = useDeviceInfoContext();
  const navigationHandler = useHandleNavigationPush();

  const closeModalClickHandler = () => {
    setModalVisible(false);
  };

  const { imageWidth: mangoLogoWidth } = useImageSizes({
    url: patientColorLogo,
    widthValue: 48,
    isSrc: true,
  });

  const menuIconSize = isMobile ? 24 : 28;

  const menuIconClickHandler = () => navigation?.toggleDrawer();

  const logoClickHandler = () => navigationHandler(Routes.HOME);

  return (
    <HeaderContainer>
      <HeaderWrapper>
        <MangoLogoWrapper onPress={logoClickHandler} style={[webStyles.button, { width: mangoLogoWidth }]}>
          <StyledImage resizeMode='contain' source={patientColorLogo} />
        </MangoLogoWrapper>
        {!!navigation && isMobile && (
          <ActionsWrapper>
            <MenuIconWrapper style={[styles.menuIconWrapper, webStyles.button]} onPress={menuIconClickHandler}>
              <Menu stroke={defaultTheme.colors.gray1} width={menuIconSize} height={menuIconSize} />
            </MenuIconWrapper>
          </ActionsWrapper>
        )}
      </HeaderWrapper>
      <Modal modalIsVisible={isModalVisible} setModalVisible={setModalVisible} onRequestClose={closeModalClickHandler}>
        <Support />
      </Modal>
    </HeaderContainer>
  );
};
