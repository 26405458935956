import React from 'react';

import { Controller, Control, FieldError } from 'react-hook-form';
import { View } from 'react-native';

import { TextFieldWithLabel } from 'components';
import DatePicker from 'components/DatePicker';
import SelectBox from 'components/SelectBox';
import { TimePicker } from 'components/TimePicker';
import { DefaultText } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

import { dateFormatter } from './helpers';
import { Option, MedicationFields, InputTypes } from './types';

interface FieldBlockProps {
  fieldName: keyof MedicationFields;
  labelText: string;
  placeholder?: string;
  fieldText?: any;
  showInfo?: boolean;
  editField?: boolean;
  defaultValue?: string | number;
  inputType?: InputTypes;
  options: Option[];
  rules?: Record<string, string | boolean | Date>;
  control: Control<MedicationFields, Record<string, unknown>>;
  error?: FieldError;
  formValues: MedicationFields;
  disabled?: boolean;
  numeric: boolean;
}

type PostfixField = {
  fieldName: string;
  postfix: string;
};

const postfixFields: PostfixField[] = [
  {
    fieldName: 'totalDose',
    postfix: 'type',
  },
];

export const FieldBlockComponent = ({
  fieldName,
  labelText,
  fieldText,
  showInfo = false,
  editField,
  defaultValue,
  inputType,
  options,
  rules,
  numeric,
  placeholder,
  control,
  error,
  formValues,
  disabled,
}: FieldBlockProps) => {
  const { totalDose } = formValues;
  const modifiedField = postfixFields.find((pf) => pf.fieldName === fieldName);
  const getPostfixValue = () => {
    if (modifiedField) {
      const { postfix } = modifiedField;
      const postfixFormValue = formValues[postfix as FieldBlockProps['fieldName']];
      if (postfixFormValue) {
        const typeLabel = options.find((option) => option.value === postfixFormValue)?.label;
        return !!totalDose && postfix === 'type'
          ? `${typeLabel}${totalDose > 1 ? 's' : ''}`
          : postfixFormValue.toString();
      }
      return '';
    }
    return '';
  };
  return (
    <View>
      <Controller
        name={fieldName}
        control={control as any}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
          const onNumberChange = (text: string) => {
            const regex = new RegExp('[+-]?([0-9]*[.|,])?[0-9]+');
            if (!text) onChange('');
            const isNumber = !isNaN(Number(text.replace(',', '.')));
            if (regex.test(text) && isNumber) onChange(text);
          };
          switch (inputType) {
            case 'Select':
              return (
                <SelectBox
                  value={value}
                  labelText={labelText}
                  showInfo={showInfo}
                  items={options || []}
                  disabled={disabled}
                  onValueChange={onChange}
                />
              );
            case 'TimePicker':
              return <TimePicker onChange={onChange} value={value as Date} labelText={labelText} showInfo={showInfo} />;
            case 'DatePicker':
              return (
                <DatePicker
                  minDate={rules?.minDate as Date}
                  onChange={onChange}
                  value={value as Date}
                  labelText={labelText}
                />
              );
            default:
              return (
                <TextFieldWithLabel
                  numberOfLines={1}
                  keyboardType={isNativeDevice && numeric ? 'numeric' : 'default'}
                  value={value?.toString()}
                  labelText={labelText}
                  placeholder={placeholder}
                  fieldText={dateFormatter(fieldText)}
                  showInfo={showInfo}
                  editField={editField}
                  onChangeText={numeric ? onNumberChange : onChange}
                  postfix={getPostfixValue()}
                  editable={!disabled}
                />
              );
          }
        }}
      />
      {error && <DefaultText style={{ color: '#f54275' }}>Required field</DefaultText>}
    </View>
  );
};
