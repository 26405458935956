import { StyleSheet } from 'react-native';

import { modalContentStyle } from 'style/common';
import { isNativeDevice } from 'utils/helpers';

export const getMedicationWizardStyles = () =>
  StyleSheet.create({
    wizardWrapper: { padding: 40, maxWidth: isNativeDevice ? 'auto' : 328, paddingTop: 44 },
    stepProgressBarWrapper: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 24,
      minWidth: 248,
    },
    closeButtonStyle: { top: 10 },
    contentStyle: { ...modalContentStyle, ...(isNativeDevice && { maxHeight: '90%' }) },
  });
