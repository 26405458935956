import { AppThunkAction } from 'store/index';

import { initAdditionalServicesData } from './initAdditionalServicesData';
import { initFinancialAssistanceData } from './initFinancialAssistanceData';
import { initMilestonesData } from './initMilestonesData';
import { initPartnerRegistration } from './initPartnerRegistration';
import { initSimpleTextNotificationTiles } from './initSimpleTextNotificationTilesData';

export type AppInitializeData = () => AppThunkAction<Promise<void>>;

export const appInitializeData: AppInitializeData = () => async (dispatch, getState) => {
  // Partner Registration
  await initPartnerRegistration(dispatch);

  if (getState().userInfo.hasPsp) {
    // Simple Text Notification Tile
    await initSimpleTextNotificationTiles(dispatch);
    // Financial Assistance
    await initFinancialAssistanceData(dispatch);
    // Additional Services
    await initAdditionalServicesData(dispatch);
    // Milestones Data
    await initMilestonesData(dispatch);
  }
};
