// This class is used to handle (store/remove) references to the dom elements
// This approach allows reuse of DOM elements and omitting rerenders

export class ComponentsRefs {
  private _elements = new Map();

  public setRef = (key: string) => (ref: any) => {
    this._elements.set(key, ref);
  };

  public getRef(key: string) {
    return this._elements.get(key);
  }

  public remove(key: string) {
    this._elements.delete(key);
  }

  public clear() {
    this._elements.clear();
  }
}

export default new ComponentsRefs();
