import { validationTexts } from './constants';
import { AddMedicationField, MedicationFormValues } from './types';

export const getAddMedicationFields = (
  renders: Record<keyof MedicationFormValues, AddMedicationField['render']>
): AddMedicationField[] => [
  {
    name: 'name',
    rules: { required: true },
    render: renders.name,
    errorText: validationTexts.medicationNameValidation,
  },
  {
    name: 'type',
    rules: { required: true },
    errorText: validationTexts.validationMessage,
    render: renders.type,
  },
  {
    name: 'totalDose',
    render: renders.totalDose,
    errorText: validationTexts.validationMessage,
  },
  {
    name: 'dosageAmount',
    render: renders.dosageAmount,
    errorText: validationTexts.validationMessage,
  },
  {
    name: 'quantity',
    render: renders.quantity,
    errorText: validationTexts.validationMessage,
  },
  {
    name: 'dateStarted',
    render: renders.dateStarted,
  },
];
