import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useHistoryItemStyle = ({ isExpanded, status }: { isExpanded: boolean; status: string }) => {
  const { colors, fontSizes, fontFamilies, isMobile } = useTheme();

  const statusBackgroundColor = status === 'In process' ? colors.gray2 : colors.positive;

  return StyleSheet.create({
    historyTitle: {
      ...fontSizes[16],
      marginBottom: 28,
      marginTop: 10,
    },
    historyBlockWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: isMobile ? 'stretch' : 'center',
      marginBottom: 28,
    },
    historyTitleContainer: {
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'flex-start',
    },
    historyDate: {
      ...fontSizes[16],
      fontFamily: fontFamilies.bold,
      marginRight: isMobile ? 0 : 32,
      marginBottom: isMobile ? 8 : 0,
    },
    historyItemsWrapper: {
      overflow: 'hidden',
    },
    trackerStatusWrapper: {
      backgroundColor: statusBackgroundColor,
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 20,
      marginRight: isMobile ? 0 : 50,
      ...(isMobile && { position: 'absolute', top: 0, right: 48 }),
    },
    trackerStatusText: {
      color: colors.white,
    },
    icon: {
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 8,
      transform: [{ rotate: !isExpanded ? '0deg' : '180deg' }],
    },
  });
};
