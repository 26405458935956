import { useRef, useState, useEffect, useCallback } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation, useIsFocused, CommonActions, useFocusEffect } from '@react-navigation/native';

import { AppScrollViewRef } from 'components/AppScrollView/types';

export const useScroll = ({
  shouldScroll,
  isVertical = true,
  isHorizontal = false,
}: {
  shouldScroll: boolean;
  isVertical?: boolean;
  isHorizontal?: boolean;
}) => {
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const isFocused = useIsFocused();
  const [componentViewY, setComponentViewY] = useState(0);
  const [componentViewX, setComponentViewX] = useState(0);

  const scrollViewRef = useRef<AppScrollViewRef>(null);

  const setParamsToDefault = useCallback(() => {
    // screen changed and clear params of the screen
    navigation.dispatch(CommonActions.setParams({ isInsurance: undefined, isHistory: undefined }));
  }, [navigation]);

  useFocusEffect(() => {
    return () => {
      setParamsToDefault;
    };
  });

  useEffect(() => {
    if (shouldScroll && isFocused) {
      if (isHorizontal) {
        scrollViewRef.current?.scrollTo({ y: 0, x: componentViewX, animated: true });
        return;
      }
      if (isVertical) {
        scrollViewRef.current?.scrollTo({ y: componentViewY, x: 0, animated: true });
        return;
      }
    }
  }, [shouldScroll, isVertical, isHorizontal, componentViewY, componentViewX, isFocused]);

  return {
    scrollViewRef,
    setComponentViewY,
    setComponentViewX,
  };
};

export default useScroll;
