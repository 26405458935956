import { createSelector } from 'reselect';

import { InitialMedicineCabinetState, ReminderTypesEnum } from 'store/reducers/medicine-cabinet/types';

import { movePspMedicationsToTop } from './utils';

// Internal selectors
const _getMedTrackerInfo = (state: InitialMedicineCabinetState) => state.medicineCabinet.medTrackerInfo;
const _getMedications = (state: InitialMedicineCabinetState) => state.medicineCabinet.medications;
const _getProgramMedications = (state: InitialMedicineCabinetState) => state.medicineCabinet.programMedications;
const _getReminders = (state: InitialMedicineCabinetState) => state.medicineCabinet.reminders;
const _getCabinetNotifications = (state: InitialMedicineCabinetState) => state.medicineCabinet.notifications;
const _getDrugInteractions = (state: InitialMedicineCabinetState) => state.medicineCabinet.drugInteractions;
const _getAllDrugInteractions = (state: InitialMedicineCabinetState) => [
  ...(state.medicineCabinet.allDrugInteractions || []),
  ...(state.medicineCabinet.drugInteractions || []),
];
const _getAllMedications = (state: InitialMedicineCabinetState) => [...state.medicineCabinet.medications];
//

export const getSelectedTab = (state: InitialMedicineCabinetState) => state.medicineCabinet.selectedTab;
export const getReminderTypes = (state: InitialMedicineCabinetState) => state.medicineCabinet.reminderTypes;
export const getMedicationTypes = (state: InitialMedicineCabinetState) => state.medicineCabinet.medicationTypes;
export const getMedicationSelectOptions = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.medicationSelectOptions;
export const getSelectedDrugsInteractions = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.allDrugInteractions;

export const getSelectedMedicationId = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.selectedMedicationId;
export const getSelectedReminderId = (state: InitialMedicineCabinetState) => state.medicineCabinet.selectedReminderId;
export const getSelectedInteractionId = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.selectedInteractionId;

export const getReminderModalVisibility = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.reminderModalVisibility;
export const getMedicationModalVisibility = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.medicationModalVisibility;
export const getDrugInteractionsModalVisibility = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.drugInteractionsModalVisibility;

export const getPSPReminderTemplate = (state: InitialMedicineCabinetState) => state.medicineCabinet.pspReminderTemplate;
export const getIsPSPMedicationHasBeenAdded = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.pspMedicationHasBeenAdded;

export const getMedicationWizardVisibility = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.medicationWizardVisibility;
export const getMedicationWizardStep = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.medicationWizardStep;

export const getIsMedicationCabinetLoading = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.isMedicationCabinetLoading;

export const getAsNeededMedicationModalVisibility = (state: InitialMedicineCabinetState) =>
  state.medicineCabinet.asNeededMedicationModalVisibility;

export const getSelectedMedicationReminders = createSelector(
  [_getReminders, getSelectedMedicationId],
  (reminders, selectedMedicationId) => reminders.filter((reminder) => reminder?.medication?.id == selectedMedicationId)
);

export const getMedicationRemindersById = (medicationId?: number) =>
  createSelector(_getReminders, (reminders) =>
    reminders.filter((reminder) => {
      if (!medicationId) return false;
      return reminder?.medication?.id == medicationId;
    })
  );

export const getMedTrackerInfo = createSelector(_getMedTrackerInfo, (medTrackerInfo) => medTrackerInfo);
export const getMedications = createSelector(_getMedications, (medication) => movePspMedicationsToTop(medication));
export const getHasMedications = createSelector(_getMedications, (medication) => !!medication.length);
export const getProgramMedications = createSelector(_getProgramMedications, (programMedication) => programMedication);
export const getReminders = createSelector(_getReminders, (reminder) => reminder);
export const getCabinetNotifications = createSelector(_getCabinetNotifications, (notification) => notification);
export const getDrugInteractions = createSelector(_getDrugInteractions, (interactions) => interactions);

export const getSelectedMedication = createSelector(
  [_getAllMedications, getSelectedMedicationId],
  (medications, selectedId) => medications.find((medication) => medication.id == selectedId)
);

export const getPSPMedicationWithoutReminder = createSelector(
  [getProgramMedications, getReminders],
  (medications, reminders) => {
    const pspReminder = reminders.find((reminder) => reminder.medication.sponsored_program_uuid);
    return medications.find(
      (medication) => medication.sponsored_program_uuid !== pspReminder?.medication.sponsored_program_uuid
    );
  }
);

export const getSelectedReminder = createSelector([_getReminders, getSelectedReminderId], (reminders, selectedId) =>
  reminders.find((reminder) => reminder.id == selectedId)
);

export const getAsNeededReminders = createSelector(_getReminders, (reminders) =>
  reminders.filter((reminder) => reminder.type === ReminderTypesEnum.AS_NEEDED)
);

export const getSelectedInteraction = createSelector(
  [_getAllDrugInteractions, getSelectedInteractionId],
  (interactions, selectedId) => interactions.find((interaction) => interaction.interacted_with.id == selectedId)
);

export const getPreviousDrugId = (state: InitialMedicineCabinetState) => state.medicineCabinet.previousDrugId;
