import { PartnerRegistrationInterface } from 'api';
import { AppThunkAction } from 'store';
import { removeDashes } from 'utils/convertString';

type SaveUserContactInfo = () => AppThunkAction<Promise<void>>;

export const saveUserContactInfo: SaveUserContactInfo = () => async (_, getState) => {
  const currentUserInfo = getState().userInfo;

  await PartnerRegistrationInterface.patch({
    data: {
      email: currentUserInfo.email,
      addressLine1: currentUserInfo.addressLine1,
      addressLine2: currentUserInfo.addressLine2,
      city: currentUserInfo.city,
      state: currentUserInfo.state,
      zipCode: currentUserInfo.zipCode,
      phoneNumber: removeDashes(currentUserInfo.phoneNumber),
    },
  });
};
