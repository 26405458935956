import { StyleSheet } from 'react-native';
import { DefaultTheme } from 'styled-components/native';

export const getInteractionDetailStyles = (theme: DefaultTheme, isMobile?: boolean) =>
  StyleSheet.create({
    wrapper: {
      flex: 1,
      alignSelf: 'flex-start',
    },
    headerWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 60,
      ...(isMobile && { width: '100%', paddingHorizontal: 32, marginTop: 10 }),
    },
    headerText: { color: theme.colors.gray1, marginRight: 16 },
    layoutContentAreaStyle: { marginTop: 16 },
    interactionName: { marginBottom: 16, color: theme.colors.gray1, fontFamily: theme.fontFamilies.bold },
    backButtonActionsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      paddingTop: 15,
      ...(isMobile && {
        height: 48,
        width: theme.width! + 64,
        right: 64,
        paddingHorizontal: 64,
        paddingTop: 0,
      }),
    },
    backButtonStyles: {
      marginLeft: 0,
      marginTop: 0,
      padding: 10,
      paddingLeft: 0,
      ...(isMobile && { marginLeft: 32 }),
    },
  });
