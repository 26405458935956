export const TEXTS = {
  TITLE: 'Set your password',
  PASSPORT_PLACEHOLDER: 'Enter your new password',
  PASSWORD_LABEL: 'Enter Password',
  REPEAT_PASSWORD_PLACEHOLDER: 'Re-enter your new password',
  REPEAT_PASSWORD_LABEL: 'Re-enter Password',
  SET_PASSWORD: 'Set Password',
  SET_PASSWORD_SENDING: 'Setting Password...',
  TOKEN_CODE_ERROR_MESSAGE: "Url doesn't include a registration code or token",
};

// google analytics constants
const USER_START_REGISTRATION_FROM_SMS = 'USER_START_REGISTRATION_FROM_SMS';
const USER_START_REGISTRATION_FROM_EMAIL = 'USER_START_REGISTRATION_FROM_EMAIL';
const USER_FINISHED_REGISTRATION_FROM_SMS = 'USER_FINISHED_REGISTRATION_FROM_SMS';
const USER_FINISHED_REGISTRATION_FROM_EMAIL = 'USER_FINISHED_REGISTRATION_FROM_EMAIL';
export const USER_ENROLLED_TO_THE_PSP_FROM_SMS = 'USER_ENROLLED_TO_THE_PSP_FROM_SMS';
export const USER_ENROLLED_TO_THE_PSP_FROM_EMAIL = 'USER_ENROLLED_TO_THE_PSP_FROM_EMAIL';

export const setPasswordAnalyticsActions = {
  USER_START_REGISTRATION_FROM_SMS,
  USER_START_REGISTRATION_FROM_EMAIL,
  USER_FINISHED_REGISTRATION_FROM_SMS,
  USER_FINISHED_REGISTRATION_FROM_EMAIL,
  USER_ENROLLED_TO_THE_PSP_FROM_SMS,
  USER_ENROLLED_TO_THE_PSP_FROM_EMAIL,
};
