import React from 'react';

import { useSelector } from 'react-redux';

import { ServiceActionItem } from 'components/ServiceActionItem';
import type { Request } from 'store/reducers/additional-services/types';
import { CustomServiceTypeEnum } from 'store/reducers/additional-services/types';
import { getRequests } from 'store/selectors';

import { iconMapping } from '../map';

type RequestMaterialActionsProp = {
  actionHandler: (id: Request) => void;
};

export const RequestMaterialActions = ({ actionHandler }: RequestMaterialActionsProp) => {
  const requests = useSelector(getRequests);

  return (
    <>
      {requests?.map((request) => (
        <ServiceActionItem
          key={request.id}
          stateType={CustomServiceTypeEnum.requests}
          type={request.type}
          onAction={() => actionHandler(request)}
          done={request.isCompleted}
          userDataUuid={request.userDataUuid}
          id={request.id}
          isNew={request.isNew}
          actionLabel={request.label}
          IconComponent={(iconMapping as any)[request.icon]}
          triggerableAction={request.repeatable}
        />
      ))}
    </>
  );
};
