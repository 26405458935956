import produce from 'immer';

import { SetConsentHasBeenViewed } from 'store/actions/user/actionCreators/consents/setConsentHasBeenViewed';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setConsentHasBeenViewed = (
  state = INITIAL_STATE,
  payload: ReturnType<SetConsentHasBeenViewed>['payload']
): User => ({
  ...state,
  consentModalsState: produce(state.consentModalsState, (draftConsentModalsState) => {
    draftConsentModalsState[payload.groupType].entities.consents[payload.id] = {
      ...draftConsentModalsState[payload.groupType].entities.consents[payload.id],
      isViewed: payload.isViewed,
    };
  }),
});

export default setConsentHasBeenViewed;
