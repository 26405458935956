import { MedicineCabinetInterface } from 'api';
import { AppThunkAction, reduxStore } from 'store';
import {
  SET_MEDICATION_TRACKER_DATA,
  SET_METADATA,
  SET_MEDICATIONS,
  SET_REMINDERS,
  SET_MEDICATION_SELECT_OPTIONS,
  SET_SELECTED_MEDICATION_ID,
  SET_SELECTED_REMINDER_ID,
  SET_REMINDER_MODAL_VISIBILITY,
  SET_MEDICATION_MODAL_VISIBILITY,
  SET_IS_MEDICATION_CABINET_LOADING,
  SET_CABINET_NOTIFICATIONS,
  SET_PSP_REMINDER_TEMPLATE,
  SET_PSP_MEDICATION_HAS_BEEN_ADDED,
  SET_PROGRAM_MEDICATIONS,
  SET_SELECTED_TAB,
  REMOVE_CABINET_NOTIFICATION,
  SET_DRUG_INTERACTIONS,
  SET_DRUG_INTERACTIONS_MODAL_VISIBILITY,
  SET_MEDICATION_WIZARD_VISIBILITY,
  SET_WIZARD_STEP,
  SET_SELECTED_INTERACTION_ID,
  SET_ALL_DRUG_INTERACTIONS,
  SET_AS_NEEDED_MEDICATION_MODAL_VISIBILITY,
  SET_PREVIOUS_DRUG_ID,
} from 'store/action-types';
import {
  MedicationTrackerData,
  Medication,
  Reminder,
  PSPReminderTemplate,
  MetaType,
  MetaData,
  ReminderNotification,
  TabTypesEnum,
  DrugInteractions,
} from 'store/reducers/medicine-cabinet/types';

export const initMedicineCabinet = async () => {
  const [metaDataResponse, medicationResponse, reminderResponse] = await Promise.all([
    MedicineCabinetInterface.getMetaData(),
    MedicineCabinetInterface.getMedications(),
    MedicineCabinetInterface.getReminders(),
  ]);

  reduxStore.dispatch(setMetaData(metaDataResponse.data.meta));
  reduxStore.dispatch(setMedications(medicationResponse.data.results));
  reduxStore.dispatch(setReminders(reminderResponse.data.results));

  reduxStore.dispatch(
    setProgramMedications(medicationResponse?.data?.results?.filter((med) => med.sponsored_program_uuid) ?? [])
  );
};

export const setMedTrackerData = (payload: MedicationTrackerData) => ({ type: SET_MEDICATION_TRACKER_DATA, payload });

export const setMedications =
  (data: Medication[]): AppThunkAction<Promise<void>> =>
  async (dispatch) => {
    const medicationSelectOptions: MetaType[] = data.map(({ id, name }) => ({
      id: id.toString(), // check, if we need to store "id" and "value" as numbers
      value: id.toString(),
      label: name,
    }));
    dispatch({ type: SET_MEDICATIONS, payload: data });
    dispatch({ type: SET_MEDICATION_SELECT_OPTIONS, payload: medicationSelectOptions });
  };

export const setReminders = (payload: Reminder[]) => ({ type: SET_REMINDERS, payload });

export const setMetaData = (data: MetaData) => {
  const payload = {
    medicationTypes: data.medicationTypes.map((medicationType) => ({ ...medicationType, value: medicationType.id })),
    reminderTypes: data.reminderTypes.map((reminderType) => ({ ...reminderType, value: reminderType.id })),
  };
  return {
    type: SET_METADATA,
    payload,
  };
};

export const setSelectedTab = (payload: TabTypesEnum) => ({ type: SET_SELECTED_TAB, payload });
export const setSelectedReminderId = (payload: number | null) => ({ type: SET_SELECTED_REMINDER_ID, payload });
export const setSelectedMedicationId = (payload: number | null) => ({ type: SET_SELECTED_MEDICATION_ID, payload });
export const setReminderModalVisibility = (payload: boolean) => ({ type: SET_REMINDER_MODAL_VISIBILITY, payload });
export const setMedicationModalVisibility = (payload: boolean) => ({ type: SET_MEDICATION_MODAL_VISIBILITY, payload });

export const setCabinetNotifications = (payload: ReminderNotification[]) => ({
  type: SET_CABINET_NOTIFICATIONS,
  payload,
});

export const removeCabinetNotification = (payload: number) => ({ type: REMOVE_CABINET_NOTIFICATION, payload });

export const setIsMedicationCabinetLoading = (payload: boolean) => ({
  type: SET_IS_MEDICATION_CABINET_LOADING,
  payload,
});

export const setPSPReminderTemplate = (payload: PSPReminderTemplate) => ({
  type: SET_PSP_REMINDER_TEMPLATE,
  payload,
});

// if user have medication from PSProgram
export const setPSPMedicationHasBeenAdded = (payload: boolean) => ({
  type: SET_PSP_MEDICATION_HAS_BEEN_ADDED,
  payload,
});

export const setProgramMedications = (payload: Medication[]) => ({
  type: SET_PROGRAM_MEDICATIONS,
  payload,
});

// Cabinet Interactions
export const setDrugInteractions = (payload: DrugInteractions) => ({ type: SET_DRUG_INTERACTIONS, payload });
// All Interactions
export const setAllDrugInteractions = (payload: DrugInteractions) => ({ type: SET_ALL_DRUG_INTERACTIONS, payload });

export const setPreviousDrugId = (payload: number | string) => ({ type: SET_PREVIOUS_DRUG_ID, payload });

export const setDrugInteractionsModalVisibility = (payload: boolean) => ({
  type: SET_DRUG_INTERACTIONS_MODAL_VISIBILITY,
  payload,
});

export const setMedicationWizardVisibility = (payload: boolean) => ({
  type: SET_MEDICATION_WIZARD_VISIBILITY,
  payload,
});

export const setMedicationWizardStep = (payload: number) => ({ type: SET_WIZARD_STEP, payload });

export const setSelectedInteractionId = (payload: number) => ({ type: SET_SELECTED_INTERACTION_ID, payload });

export const setAsNeededMedicationModalVisibility = (payload: boolean) => ({
  type: SET_AS_NEEDED_MEDICATION_MODAL_VISIBILITY,
  payload,
});
