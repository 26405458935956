import { useNavigation } from '@react-navigation/core';
import { useDispatch, useSelector } from 'react-redux';

import { Routes } from 'routes/main';
import { setHasModifications } from 'store/actions';
import { getHasModifications } from 'store/selectors/app-global-state';
import { showAlert } from 'utils/alert';

const useHandleNavigationPush = () => {
  const navigation = useNavigation<any>();
  const dispatch = useDispatch();
  const hasModifications = useSelector(getHasModifications);

  const navigationHandler = (screen: Routes, params?: Record<string, unknown>) => {
    if (hasModifications) {
      showAlert(() => {
        navigation.navigate(screen, params || {});
        dispatch(setHasModifications(false));
      });
      return;
    }
    navigation.navigate(screen, params || {});
  };

  return navigationHandler;
};

export default useHandleNavigationPush;
