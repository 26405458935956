import * as ActionTypes from 'store/action-types';

export type SetShouldForceSurveyOpen = (payload: boolean) => {
  type: typeof ActionTypes.SET_SHOULD_FORCE_SURVEY_OPEN;
  payload: boolean;
};

export const setShouldForceSurveyOpen: SetShouldForceSurveyOpen = (payload) => ({
  type: ActionTypes.SET_SHOULD_FORCE_SURVEY_OPEN,
  payload,
});
