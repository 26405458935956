import React from 'react';

import { ImageSourcePropType } from 'react-native';

import { useCardItemStyles } from 'screens/main/RebateCenter/components/styles';
import { DefaultText } from 'style/common';
import { AnyFunction } from 'utils/types';

import { CardWrapper, CardImage, ActionButton, ActionButtonText } from '../styles';

type TCardProps = {
  image: ImageSourcePropType;
  info: string;
  buttonText: string;
  onPress: AnyFunction;
};

export const CardItem: React.FC<TCardProps> = ({ image, info, buttonText, onPress }) => {
  const styles = useCardItemStyles();

  return (
    <CardWrapper style={styles.container}>
      <CardImage source={image} />
      <DefaultText>{info}</DefaultText>
      <ActionButton onPress={onPress}>
        <ActionButtonText>{buttonText}</ActionButtonText>
      </ActionButton>
    </CardWrapper>
  );
};
