import React from 'react';

import { TouchableOpacity } from 'react-native';
import ToastContainer from 'react-native-toast-notifications';
import { useDispatch } from 'react-redux';

import { Checkbox } from 'components/Checkbox';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { setSelectedAnswerForQuestion } from 'store/actions';
import { Question } from 'store/reducers/user-info/types/survey';
import { DefaultText } from 'style/common';

import { getStyles } from './styles';

export type MultipleAnswersProps = {
  surveyId?: string;
  currentQuestion?: Question;
  toastRef: React.MutableRefObject<ToastContainer | null>;
};

export const MultipleAnswers = ({ currentQuestion }: MultipleAnswersProps) => {
  const dispatch = useDispatch();

  const { isMobile } = useDeviceInfoContext();
  const styles = getStyles(isMobile);

  if (!currentQuestion) return null;

  const pressHandler = (answerId: string, isChecked: boolean) => () => {
    dispatch(
      setSelectedAnswerForQuestion({
        questionId: currentQuestion.uuid,
        answerId,
        isSelected: !isChecked,
        multi: true,
      })
    );
  };

  return (
    <>
      {currentQuestion.answers?.map(({ uuid: answerId, text: answerText, is_checked: isChecked }) => (
        <TouchableOpacity key={answerId} style={styles.checkboxWrapper} onPress={pressHandler(answerId, isChecked)}>
          <Checkbox isChecked={isChecked} />
          <DefaultText style={styles.answerText}>{answerText}</DefaultText>
        </TouchableOpacity>
      ))}
    </>
  );
};

export default MultipleAnswers;
