import type { ValidateInputFieldsProps } from './types';

export const validateInputFields = ({ fieldName, fieldValue, fieldError, setFieldError }: ValidateInputFieldsProps) => {
  if (fieldName === 'userFirstName') {
    if (!fieldValue) {
      setFieldError((prev) => ({ ...prev, [fieldName]: { message: 'This field is required' } }));
    }
    if (fieldValue && fieldError[fieldName]) {
      setFieldError((prev) => ({ ...prev, [fieldName]: { message: '' } }));
    }
  }
};
