export const title = 'Your Profile';
export const description = 'Keep your personal information up-to-date.';

// Analytics Constants
const VIEWED_PROFILE_CONTENT = 'VIEWED_PROFILE_CONTENT';
const EDIT_BASIC_INFO_STARTED = 'EDIT_BASIC_INFO_STARTED';
const EDIT_BASIC_INFO_FINISHED = 'EDIT_BASIC_INFO_FINISHED';
const EDIT_CONTACT_INFO_STARTED = 'EDIT_CONTACT_INFO_STARTED';
const EDIT_CONTACT_INFO_FINISHED = 'EDIT_CONTACT_INFO_FINISHED';
const COMM_PREF_CHANGED_INFO = 'COMM_PREF_CHANGED_INFO';

export const profileAnalyticsActions = {
  VIEWED_PROFILE_CONTENT,
  EDIT_BASIC_INFO_STARTED,
  EDIT_BASIC_INFO_FINISHED,
  EDIT_CONTACT_INFO_STARTED,
  EDIT_CONTACT_INFO_FINISHED,
  COMM_PREF_CHANGED_INFO,
};
