import React, { PropsWithChildren } from 'react';

import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components/native';

import { getTheme } from '../components/Navigation/styles';
import { getAppTheme, getUserAuthorization, getUserHasPsp } from '../store/selectors';
import { useDeviceInfoContext } from './DeviceInfo';

/**
 * export const DefaultText = styled.Text`
 *  ${({ theme }) =>
 *   theme.isDesktop &&
 *   css`
 *     user-select: text;
 *   `}
 * `;
 * @description Do not use the theme.isDesktop variable in styled.components,
 * if you want to use styles for the web that are missing from native device support.
 * The isDesktop variable cannot be initialized in time.
 */

export const AppThemeProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { isMobile, isTablet, isDesktop, width, height } = useDeviceInfoContext();

  const userHasPsp = useSelector(getUserHasPsp);
  const isLoggedIn = useSelector(getUserAuthorization);

  const appThemeColors = useSelector(getAppTheme);

  const theme = getTheme({
    isMobile,
    isTablet,
    isDesktop,
    width,
    height,
    isLoggedIn,
    userHasPsp,
    appThemeColors,
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
