import React from 'react';

import { View } from 'react-native';

import { Row } from './Row';
import { DirectionProps, Style } from './types';

export const Grid = ({ style, children }: DirectionProps) => {
  const isRow = Array.isArray(children) ? children.some((child) => child === Row) : children === Row;
  const layoutStyle: Style = [{ flex: 1, flexDirection: isRow ? 'row' : 'column' }, style];

  return <View style={layoutStyle}>{children}</View>;
};

export default Grid;
