import React from 'react';

import { View } from 'react-native';

import { Insurance, InsuranceImages } from 'api/Insurance/types';
import { InformationField } from 'screens/main/FinancialAssistance/CopayFinancialAssistance/components';
import { InsuranceEditCardContent } from 'screens/main/Profile/sections/InsuranceInformation/InsuranceCard/InsuranceEditCardContent/InsuranceEditCardContent';
import { InsuranceCardType } from 'screens/main/Profile/sections/InsuranceInformation/types';
import { SubTitle } from 'style/common';
import { AnyFunction } from 'utils/types';

import SkeletonForInsuranceCard from './SkeletonForInsuranceCard';
import { useInsuranceCardStyles } from './styles';
import { getInsuranceCardInfo } from './utils';

export type InsuranceCardProps = {
  insurance: Insurance;
  isEditMode: boolean;
  isLoading: boolean;
  forceRerender: AnyFunction;
  onImageChanged: (images: InsuranceImages, type: InsuranceCardType) => void;
};

export const InsuranceCard = ({
  insurance,
  isEditMode,
  isLoading,
  forceRerender,
  onImageChanged,
}: InsuranceCardProps) => {
  const { title, infoFields } = getInsuranceCardInfo(insurance);

  const styles = useInsuranceCardStyles();

  if (isLoading) return <SkeletonForInsuranceCard />;

  const contentJSX = isEditMode ? (
    <InsuranceEditCardContent
      onChange={(images) => onImageChanged(images, insurance.type)}
      forceRerender={forceRerender}
    />
  ) : (
    infoFields.map((field, i) => (
      <InformationField
        {...field}
        key={i}
        style={[
          styles.informationField,
          {
            marginTop: i ? 16 : 0,
          },
        ]}
      />
    ))
  );

  return (
    <View style={styles.wrapper}>
      <SubTitle style={styles.title}>{title}</SubTitle>
      {contentJSX}
    </View>
  );
};
