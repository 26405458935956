type NotificationContent = {
  title: string;
  description: string;
};

export const notificationContent: NotificationContent = {
  title: 'Your Coverage is Changing.',
  description:
    'Your coverage has been affected by a recent change in legislation. Insurance companies are now changing to a copay accumulator plan. As a result your benefits will be updated and we will be issuing you a new copay card. Please contact us for more information.',
};
