import React from 'react';

import { StyleProp, TextStyle, ViewStyle, View } from 'react-native';

import { DefaultText } from 'style/common';

import { useInformationFieldStyles } from './styles';

export type InformationFieldProps = {
  label: string;
  info?: string | number;
  infoTextStyles?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
};

export const InformationField = ({ label, info = '', infoTextStyles, style }: InformationFieldProps) => {
  const styles = useInformationFieldStyles();

  return (
    <View style={[styles.informationBlock, style]}>
      <DefaultText numberOfLines={5} style={styles.informationLabel}>
        {label}
      </DefaultText>
      <DefaultText numberOfLines={5} style={[styles.informationText, infoTextStyles]}>
        {info}
      </DefaultText>
    </View>
  );
};
