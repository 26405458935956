import { StyleSheet, ViewStyle } from 'react-native';
import { Theme, DayState } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';

import { webStyles } from 'style/common';
import { applyBreakpoints } from 'style/utils';

type DayStylesProps = Partial<{
  state: DayState;
  isSelectable: boolean;
  isSelectedDate: boolean;
}>;

export const indicatorStyle: ViewStyle = { width: 16, height: 16, alignItems: 'center' };

export const useCalendarStyles = (): Theme => {
  const { isMobile, colors, fontFamilies, fontSizes, ...theme } = useTheme();

  return {
    'stylesheet.calendar.header': {
      header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 0,
        marginLeft: -12, // to remove left arrow padding
        ...applyBreakpoints(theme, {
          sm: { marginBottom: 24, paddingLeft: 0 },
          xxl: { marginBottom: 50 },
        }),
      },
      week: {
        marginBottom: 6,
        borderBottomWidth: 1,
        borderBottomColor: colors.gray4,
        ...applyBreakpoints(theme, {
          sm: {},
          md: { marginTop: 32 },
        }),
      },
      dayHeaderText: { color: colors.gray1, ...fontSizes[14] },
      dayHeaderWrapper: { width: `${100 / 7}%`, alignItems: 'center', justifyContent: 'center' },
      monthText: {
        margin: 0,
        fontFamily: fontFamilies.bold,
        fontSize: fontSizes[16].fontSize,
        ...applyBreakpoints(theme, {
          lg: {},
          xxl: { position: 'relative', bottom: 8 },
        }),
      },
      arrow: {
        padding: 12,
        alignItems: 'center',
        ...applyBreakpoints(theme, {
          lg: {},
          xxl: { bottom: 8 },
        }),
      },
    },
    'stylesheet.calendar.main': {
      monthView: {
        flexDirection: isMobile ? 'row' : 'column',
      },
      week: {
        marginVertical: 0,
        ...applyBreakpoints(theme, {
          sm: {},
          xxl: {
            paddingVertical: 6,
            borderBottomColor: colors.gray4,
            borderBottomWidth: 1,
          },
        }),
      },
    },
  };
};

export const useDayStyles = ({ state, isSelectable, isSelectedDate }: DayStylesProps) => {
  const { isMobile, colors, fontFamilies, ...theme } = useTheme();

  return StyleSheet.create({
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      ...applyBreakpoints(theme, {
        sm: { paddingHorizontal: 8 },
        xxl: { width: `${100 / 7}%` },
      }),
    },
    wrapper: {
      ...(isSelectable && webStyles.button),
      borderRadius: 100,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isSelectedDate ? colors.gray4 : colors.white,
      ...applyBreakpoints(theme, {
        sm: {
          paddingVertical: 10,
          width: 48,
        },
        xxl: {
          paddingVertical: 16,
          paddingHorizontal: 18,
        },
      }),
    },
    dayText: {
      color: state === 'disabled' ? colors.gray3 : colors.gray1,
      marginTop: 16,
      fontFamily: fontFamilies.semiBold,
    },
    weekDayText: {
      display: isMobile ? 'flex' : 'none',
      textAlign: 'center',
      color: colors.gray1,
    },
  });
};
