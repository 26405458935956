import React from 'react';

import { useNavigation } from '@react-navigation/core';
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';

import { MenuItem, RouteType } from 'components/MenuItem';
import { useHandleNavigationPush } from 'hooks';
import { medCabinet, medTracker, profile } from 'localization/drawer';
import { Routes } from 'routes/main';

import { getItemStyles } from './styles';

const items: { route: RouteType; title: string }[] = [
  { route: Routes.PROFILE, title: profile },
  { route: Routes.MEDICINE_CABINET, title: medCabinet },
  { route: Routes.TRACKER, title: medTracker },
];

export const ProfileAndMedCabinetItems = () => {
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const navigationHandler = useHandleNavigationPush();
  const navigationState = navigation.getState().routes[0].state;
  const activeRouteName = navigationState?.index
    ? navigationState?.routes?.[navigationState.index]?.name
    : navigationState?.routes?.[0]?.name;

  const { colors } = useTheme();
  const styles = getItemStyles();

  return (
    <View style={styles.itemsWrapper}>
      {items.map(({ route, title }) => (
        <MenuItem
          key={title}
          title={title}
          menuItemRoute={route}
          active={activeRouteName === route}
          activeColor={colors.mangoSecondary}
          testID={`${route}-test`}
          onPress={() => navigationHandler(route)}
          itemWrapperStyle={styles.item}
        />
      ))}
    </View>
  );
};

export default ProfileAndMedCabinetItems;
