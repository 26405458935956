import throttle from 'lodash.throttle';
import queryString from 'query-string';
import { Dimensions, Linking, Platform } from 'react-native';
import ToastContainer, { ToastType } from 'react-native-toast-notifications';

import { breakpoints } from 'style/constants';
import { AnyObject } from 'utils/types';

import { urlRegex } from './regulars';

export const screenWidth = Dimensions.get('screen').width;

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const isNativeDevice = Platform.OS === 'ios' || Platform.OS === 'android';
export const isWebBrowser = !isNativeDevice;
// Mobile Web
export const isWebNativeAndroidDevice = /Android/i.test(navigator.userAgent);
export const isWebNativeIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isWebNativeOthersDevice = /webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isWebNativeDevice = isWebNativeAndroidDevice || isWebNativeIOSDevice || isWebNativeOthersDevice;

export const isMobile = screenWidth <= breakpoints.sm;

export const isSafari = !isNativeDevice
  ? /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)
  : false;

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isIOSDevice = Platform.OS === 'ios';
export const isAndroidDevice = Platform.OS === 'android';

export const openUrlInNewTab = async (url: string) => {
  const link = urlRegex.test(url) ? url : 'https://' + url;

  if (!isNativeDevice) {
    window?.open(link, '_blank')!.focus();
    return;
  }
  if (await Linking.canOpenURL(link)) {
    await Linking.openURL(link);
  }
};

export const throttleToastHide = throttle((toast: ToastType | ToastContainer | null) => toast?.hideAll(), 400);

export const transformNullToEmptyValue = <T>(item: T, emptyValue: unknown = ''): T => {
  const copyItem = { ...item };

  Object.keys(copyItem as AnyObject).forEach((key) => {
    if (copyItem[key as keyof T] === null) copyItem[key as keyof T] = emptyValue as T[keyof T];
  });

  return copyItem;
};

export const changeFieldNameInObject = <T extends AnyObject, R extends AnyObject>(
  object: T,
  originalKey: keyof T,
  targetKey: keyof R
): R => {
  const copy = { ...object };
  const value = copy[originalKey];

  delete copy[originalKey];

  copy[targetKey as keyof T] = value;

  return copy as unknown as R;
};

export const isNumber = (value: unknown): value is number => typeof value === 'number';

//TODO: remove it later, It’s a temporary fix sanitizer func for corrupted hex code value.
export const sanitizeColorString = (str: string) => str.replace('\n', '');

// TODO(Remove): Temporarily added this helper until be fixes the image url issues
export const getMediaUrl = (source: any) => {
  if (!source) return null;
  return typeof source === 'string' ? source : source.url;
};

export const getUrlQueryValues = <T extends AnyObject>() =>
  !isNativeDevice && (queryString.parse(location.search) as unknown as T);

export const booleanXor = <V1, V2>(value1: V1, value2: V2) => !!value1 !== !!value2;
