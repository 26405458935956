import { UserInterface } from 'api';
import { AppThunkAction } from 'store';
import { clearPersistCache } from 'utils/clearPersistCache';

type LogoutHandler = (shouldReload?: boolean) => AppThunkAction<Promise<void>>;

export const logoutHandler: LogoutHandler =
  (shouldReload = false) =>
  async (dispatch, getState) => {
    const pushToken = getState().userInfo.pushToken;

    try {
      if (pushToken) await UserInterface.deletePushToken(pushToken);
    } catch (error) {
      console.error(error);
    } finally {
      clearPersistCache({ dispatch, shouldReload });
    }
  };
