import { format } from 'date-fns';

import { getDateFromUnknown } from 'utils/date/getDateFromUnknown';

export type AppDateFormats = 'MM/dd/yyyy' | 'MM/d/yy' | 'MMMM dd, yyyy';

export const appDateFormatter = <T>(
  value: string | Date | undefined, // Don't remove undefined - it is needed!
  formatType: AppDateFormats,
  defaultValue = new Date() as unknown as T
) => {
  const date = value instanceof Date ? value : getDateFromUnknown(value, defaultValue);

  if (date instanceof Date) return format(date, formatType);

  return date;
};
