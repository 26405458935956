import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import { View, StyleProp, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { MedicineCabinetInterface } from 'api';
import { CustomCalendar } from 'components/Calendar';
import { ProgressLegend } from 'components/CircularProgressBar/Legend';
import { MedAdherenceLegend } from 'components/MedAdherenceLegend';
import { Popover } from 'components/Popover';
import SelectBox from 'components/SelectBox';
import { useMedTrackerData } from 'hooks';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { medicationTrackerAnalyticsActions } from 'screens/main/MedicationTracker/constants';
import { useRefetchMedTracker } from 'screens/main/MedicationTracker/hooks';
import { getProgramMedications } from 'store/selectors/medicine-cabinet';
import { DefaultText } from 'style/common';
import { getTimestampForCurrentDateUTC } from 'utils/dateTimeHelpers';
import { isNativeDevice } from 'utils/helpers';

import { DailyMedications } from './components/DailyMedications';
import { medicationOptionFormatter, medicationTrackerAnalyticsHandler } from './helpers';
import { useStyles } from './styles';
import { Option } from './types';

export type TrackerContentProps = {
  getMedTrackerData: ReturnType<typeof useMedTrackerData>['getMedTrackerData'];
  setRequestedDate: Dispatch<SetStateAction<number>>;
  setMedicationId: Dispatch<SetStateAction<number>>;
  containerStyle?: StyleProp<ViewStyle>;
};

const { VIEW_ADHERENCE_CONTENT, VIEW_PROGRAM_MEDICATION_TRACKER } = medicationTrackerAnalyticsActions;

export const TrackerContent = ({
  getMedTrackerData,
  setMedicationId,
  setRequestedDate,
  containerStyle,
}: TrackerContentProps) => {
  const styles = useStyles();
  const programMedications = useSelector(getProgramMedications);

  const [selectedMedicationId, setSelectedMedicationId] = useState(0);
  useEffect(() => setMedicationId(selectedMedicationId), [selectedMedicationId, setMedicationId]);

  const [selectedDate, setSelectedDate] = useState(getTimestampForCurrentDateUTC());
  const [filterOptions, setFilterOptions] = useState<Option[]>([]);

  useFirstEffect(() => {
    medicationTrackerAnalyticsHandler(VIEW_ADHERENCE_CONTENT, getAnalyticEventLabel());
  }, []);

  useEffect(() => {
    MedicineCabinetInterface.getMedicationTrackerFilterData().then((response) => {
      setFilterOptions(medicationOptionFormatter(response.data.results));
    });
  }, []);

  useEffect(() => {
    const isSelectedMedicationIsPSPMedication = !!programMedications.find(
      (pspMed) => pspMed.id === +selectedMedicationId
    );
    if (!isSelectedMedicationIsPSPMedication) return;

    medicationTrackerAnalyticsHandler(VIEW_PROGRAM_MEDICATION_TRACKER, getAnalyticEventLabel());
  }, [programMedications, selectedMedicationId]);

  useRefetchMedTracker(getMedTrackerData);

  return (
    <View style={[styles.mainWrapper, containerStyle]}>
      <View style={styles.calendarWrapper}>
        <Popover containerStyle={styles.popoverWrapper} tooltipContent={<MedAdherenceLegend />}>
          <DefaultText style={styles.popoverText}>View Legend</DefaultText>
        </Popover>
        <View style={styles.medFilterWrapper}>
          <SelectBox
            maxInputWidth={styles.medFilterWrapper.width}
            shouldShowLabel={false}
            shouldShowDefaultPlaceHolder={false}
            disabled={false}
            value={isNativeDevice ? selectedMedicationId : undefined} // this props doesn't work correctly on web (bad library implementation)
            items={filterOptions}
            onValueChange={setSelectedMedicationId}
          />
        </View>
        <CustomCalendar
          isSelectable
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setRequestedDate={setRequestedDate}
        />
      </View>
      <View style={styles.medicationsWrapper}>
        <View style={styles.sectionContainer}>
          <DefaultText style={[styles.text, { marginBottom: 18 }]}>Adherence</DefaultText>
          <ProgressLegend />
        </View>
        <View style={styles.sectionContainer}>
          <DefaultText style={styles.text}>Daily Medications</DefaultText>
          <DailyMedications
            selectedMedicationId={selectedMedicationId}
            selectedDate={selectedDate}
            getMedTrackerData={getMedTrackerData}
          />
        </View>
      </View>
    </View>
  );
};

export default TrackerContent;
