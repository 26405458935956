import { gaHandler } from 'analytics/global_handlers';
import { EventCategories } from 'analytics/types';
import { Medication } from 'store/reducers/medicine-cabinet/types';

import { Option } from './types';

export const medicationTrackerAnalyticsHandler = gaHandler(EventCategories.TRACK_MEDICATION_TRACKER);

export const medicationOptionFormatter = (medications: Medication[]): Option[] => {
  return [
    {
      label: 'All',
      value: 0,
    },
    ...medications.map((medication) => ({
      label: medication.name,
      value: medication.id,
    })),
  ];
};
