import * as ActionTypes from 'store/action-types';
import type { UnnormalizedSurvey } from 'store/reducers/user-info/types/survey';

export type SetSurveys = (payload: UnnormalizedSurvey[]) => {
  type: typeof ActionTypes.SET_SURVEYS;
  payload: typeof payload;
};

export const setSurveys: SetSurveys = (payload) => ({
  type: ActionTypes.SET_SURVEYS,
  payload,
});
