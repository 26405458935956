import React from 'react';

import { View, ViewStyle, StyleProp } from 'react-native';

import { ProgressBarItem } from 'components/ProgressSteps/ProgressBarItem';
import { DefaultText } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';

import { useStyles } from './styles';

type ProgressStep = Partial<{
  isCompleted: boolean;
  isChecked: boolean;
  stepText: string;
}>;

interface Props {
  steps: (ProgressStep | string)[];
  currentStep: number;
  currentlyActiveItemId?: string;
  containerStyle?: StyleProp<ViewStyle>;
  stepItemWrapperStyle?: ViewStyle;
  showText?: boolean;
}

export const ProgressSteps = ({
  showText = true,
  steps,
  currentStep,
  currentlyActiveItemId,
  containerStyle,
  stepItemWrapperStyle,
}: Props) => {
  const { styles, colors } = useStylesWithAdditional(useStyles, { stepItemWrapperStyle, stepsCount: steps.length });

  if (steps.length < 2) return null;

  return (
    <View style={[styles.stepsWrapper, containerStyle]}>
      {steps.map((step, index) => {
        const wrapperStyle = [styles.stepItemWrapper, !index && { paddingLeft: 0 }];

        const isCompletedStep =
          typeof step === 'object'
            ? step.isCompleted || step.isChecked
            : currentlyActiveItemId && step === currentlyActiveItemId;

        const barColor = isCompletedStep || currentStep > index ? colors.positive : colors.gray3;
        const barJSX = <ProgressBarItem containerStyle={styles.stepItemIcon} backgroundColor={barColor} />;

        const contentJSX =
          typeof step === 'object' ? (
            <>
              {barJSX}
              {showText && !!step.stepText && (
                <DefaultText numberOfLines={1} style={styles.stepItemText}>
                  {step.stepText}
                </DefaultText>
              )}
            </>
          ) : (
            barJSX
          );

        return (
          <View key={index} style={wrapperStyle}>
            {contentJSX}
          </View>
        );
      })}
    </View>
  );
};

export default ProgressSteps;
