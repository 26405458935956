import { SetSurveys } from 'store/actions/user/actionCreators/surveys/setSurveys';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';
import { normalizeSurveysData } from 'store/schemas/normalizeData';
import { surveySchema } from 'store/schemas/surveys';

const setSurveys = (state = INITIAL_STATE, payload: ReturnType<SetSurveys>['payload']): User => {
  const normalizedSurveyData = normalizeSurveysData(payload, [surveySchema]);
  return { ...state, surveys: normalizedSurveyData };
};

export default setSurveys;
