import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const clearConsentModalsQueue = (state = INITIAL_STATE): User => {
  return {
    ...state,
    consentModalsQueue: [],
  };
};

export default clearConsentModalsQueue;
