import React from 'react';

import { StyleSheet, View } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Check } from 'assets/icons';

export type CheckboxProps = {
  isChecked: boolean | (() => boolean);
};

export const Checkbox = ({ isChecked }: CheckboxProps) => {
  const isActive = typeof isChecked === 'boolean' ? isChecked : isChecked();
  const theme = useTheme();

  const style = [styles.main, { backgroundColor: isActive ? theme.colors.secondary : theme.colors.white }];

  return <View style={style}>{isActive && <Check width={12} height={12} stroke='#FFF' />}</View>;
};

const styles = StyleSheet.create({
  main: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#cdd6e1',
  },
});
