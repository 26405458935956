import React, { useEffect, useState } from 'react';

import { useToast } from 'react-native-toast-notifications';
import { useSelector } from 'react-redux';

import { CommunicationConsentInterface } from 'api/Consents/communicationConsentsApi';
import { FullScreenLoader } from 'components/Loaders';
import { useMountedEffect } from 'hooks/useMountedEffect';
import { CommunicationPreferencesField, ProfileInfoLayout } from 'screens/main/Profile/components';
import { BasicInfoBlock, InformationContainer } from 'screens/main/Profile/sections/styles';
import { CommunicationConsents, CommunicationConsentStatusType } from 'store/reducers/user-info/types/consent';
import { getPrograms } from 'store/selectors/user';
import { useStylesWithAdditional } from 'style/hooks';
import { APP_NAME, TRY_AGAIN_ERROR_MESSAGE } from 'utils/constants';
import { removeDashes } from 'utils/convertString';

import { usePreferencesStyles } from './styles';

type PatientConsentCommunicationProps = {
  consents: CommunicationConsents;
};

const changeStatusToBoolean: Record<CommunicationConsentStatusType, boolean> = {
  ACTIVE: true,
  INACTIVE: false,
};

const changeStatusToLabel: Record<CommunicationConsentStatusType, string> = {
  ACTIVE: 'Yes',
  INACTIVE: 'No',
};

// TODO(remove) remove consent type "All", will use in the future
// const getIsAllConsent = (consent: Pick<CommunicationConsent, 'consentCategoryName'>) =>
//   ['All', 'global'].includes(consent.consentCategoryName);

const getStatusByBoolean = (value: boolean): CommunicationConsentStatusType => (value ? 'ACTIVE' : 'INACTIVE');

export const PatientConsentCommunication = ({ consents }: PatientConsentCommunicationProps) => {
  const programs = useSelector(getPrograms);
  const { styles, colors } = useStylesWithAdditional(usePreferencesStyles);

  const toast = useToast();

  const [savingIsInProgress, setSavingInProgress] = useState(false);

  const [consentList, setConsentList] = useState<CommunicationConsents>([]);
  useEffect(() => setConsentList(consents), [consents]);

  const consentProgramId = consents.length ? consents[0]?.manufactureProgramUuid : ''; // inside array all manufacturerId are similar
  const isAppConsent = consentProgramId === null;
  const programName = isAppConsent
    ? APP_NAME
    : programs.find((program) => program.uuid === removeDashes(consentProgramId || ''))?.display_name;

  const setValue = (index: number) => (value: boolean) => {
    if (!consentList[index] || changeStatusToBoolean[consentList[index]?.status] === value) return;

    setConsentList((prev) => {
      const newConsents = [...prev];

      newConsents[index].status = getStatusByBoolean(value);

      // TODO(remove) remove consent type "All", will use in the future
      // const allConsentIndex = newConsents.findIndex(getIsAllConsent);
      // const isAllConsent = allConsentIndex === index;

      // if (isAllConsent)
      //   return newConsents.map((consent) => ({
      //     ...consent,
      //     status: getStatusByBoolean(value),
      //   }));

      // if (allConsentIndex === -1) return newConsents;
      // else if (newConsents.every((c) => c.status === 'ACTIVE' || getIsAllConsent(c)))
      //   newConsents[allConsentIndex].status = 'ACTIVE';
      // else if (newConsents.some((c) => c.status === 'INACTIVE')) {
      //   newConsents[allConsentIndex].status = 'INACTIVE';
      // }

      return newConsents;
    });
  };

  const autoSave = async () => {
    setSavingInProgress(true);
    try {
      await CommunicationConsentInterface.saveGivenConsent({
        consents: consentList
          .filter((c) => c.consentCategoryName !== 'All') // We need to send global category for app consent
          .map((item) => ({
            consentCategoryUuid: item?.consentCategoryUuid,
            status: item?.status,
          })),
      });
    } catch (error: any) {
      const message = error?.response?.data?.message || TRY_AGAIN_ERROR_MESSAGE;
      toast.show(message, {
        type: 'danger',
      });
    }
    setSavingInProgress(false);
  };

  useMountedEffect(() => {
    const timeout = setTimeout(autoSave, 1000);

    return () => clearTimeout(timeout);
  }, [JSON.stringify(consentList)]);

  if (!programName) return null;

  return (
    <ProfileInfoLayout
      wrapperStyle={styles.wrapperStyle}
      title={`${programName} Communication`}
      titleColor={colors.mangoPrimary}
      description='Let us know which type of communications you would like to receive.'
      withActionButton={false}>
      {savingIsInProgress && <FullScreenLoader />}
      <InformationContainer style={styles.infoContainer}>
        {consentList.map(({ consentCategoryName, status }, i) => {
          // TODO(remove) remove consent type "All", will use in the future
          if (consentCategoryName === 'All') return null;
          return (
            <BasicInfoBlock style={styles.infoBlock} key={i}>
              <CommunicationPreferencesField
                isEditMode
                onChange={setValue(i)}
                value={changeStatusToBoolean[status]}
                label={isAppConsent && consentCategoryName === 'global' ? 'All' : consentCategoryName}>
                {changeStatusToLabel[status]}
              </CommunicationPreferencesField>
            </BasicInfoBlock>
          );
        })}
      </InformationContainer>
    </ProfileInfoLayout>
  );
};
