import React, { useState } from 'react';

import { View, TouchableOpacity } from 'react-native';

import { DefaultText } from 'style/common';
import { AnyFunction } from 'utils/types';

import { useTourContentStyle } from './style';
import { TourProgressHeader } from './TourProgressHeader';

type TourContentProps = {
  currentStep: number;
  title: string;
  description: string;
  handleNext: AnyFunction;
  handlePrevious: AnyFunction;
};

export const TourContent = ({ currentStep, title, description, handleNext, handlePrevious }: TourContentProps) => {
  const [buttonStyle, setButtonStyle] = useState({});
  const styles = useTourContentStyle();

  const buttonStyleHandler = () => setButtonStyle({ outline: 'none' });

  return (
    <View style={styles.container}>
      <TourProgressHeader currentStep={currentStep} />
      <DefaultText style={styles.titleText}>{title}</DefaultText>
      <DefaultText style={styles.descriptionText}>{description}</DefaultText>
      <View style={[styles.bottomBar]}>
        <TouchableOpacity
          style={[styles.tourActionButton, buttonStyle]}
          onPress={handlePrevious}
          onFocus={buttonStyleHandler}>
          <DefaultText style={styles.skipTutorialText}>Skip Tutorial</DefaultText>
        </TouchableOpacity>
        <TouchableOpacity style={styles.tourActionButton} onPress={handleNext}>
          <DefaultText style={styles.gotItText}>Got It!</DefaultText>
        </TouchableOpacity>
      </View>
    </View>
  );
};
