import * as React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

type GoToArrowIconProps = {
  d1?: string;
  d2?: string;
  size?: 'small' | 'medium';
};

const dSizes1: Record<Required<GoToArrowIconProps>['size'], string> = {
  small: 'm10.605 9.909 4.455-4.454L10.605 1',
  medium: 'm16 15 7-7-7-7',
};
const dSizes2: Record<Required<GoToArrowIconProps>['size'], string> = {
  small: 'M1.06 15V8.926c0-.92.369-1.804 1.025-2.455A3.515 3.515 0 0 1 4.56 5.455h10.5',
  medium: 'M1 23v-9.546c0-1.446.58-2.834 1.61-3.856A5.523 5.523 0 0 1 6.5 8H23',
};
const sizes: Record<Required<GoToArrowIconProps>['size'], number> = {
  small: 16,
  medium: 24,
};

const GoToArrow = ({ size = 'medium', ...props }: GoToArrowIconProps & SvgProps) => (
  <Svg width={sizes[size]} height={sizes[size]} fill='none' {...props}>
    <Path
      d={props.d1 || dSizes1[size]}
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d={props.d2 || dSizes2[size]}
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default GoToArrow;
