import { useCallback, useEffect, useState } from 'react';

import { AnyFunction } from 'utils/types';

export type TimerStatus = 'idle' | 'running' | 'paused';

export type ReturnValue = {
  reset: AnyFunction;
  start: AnyFunction;
  pause: () => number;
  time: number;
  timerStatus: TimerStatus;
};

export const useTimer = (): ReturnValue => {
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [time, setTime] = useState(0);
  const [status, setStatus] = useState<TimerStatus>('idle');

  const reset = useCallback(() => {
    setStartTime(0);
    setEndTime(0);
    setTime(0);
  }, []);

  const start = useCallback(() => {
    reset();
    setStartTime(Date.now());
    setStatus('running');
  }, [reset]);

  const pause = useCallback(() => {
    const finalTime = Date.now();
    setEndTime(finalTime);
    setStatus('paused');
    if (!startTime) return 0;
    return (finalTime - startTime) / 1000;
  }, [startTime]);

  useEffect(() => {
    if (!startTime || !endTime) return;
    setTime((endTime - startTime) / 1000);
  }, [startTime, endTime, time]);

  // time in seconds
  return { reset, time, start, pause, timerStatus: status };
};
