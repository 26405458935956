import { SetSimpleTextNotificationTiles } from 'store/actions/user/actionCreators/notification-tiles/setSimpleTextNotificationTiles';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const setSimpleTextNotificationTiles = (
  state = INITIAL_STATE,
  payload: ReturnType<SetSimpleTextNotificationTiles>['payload']
): User => ({ ...state, simpleTextNotificationTiles: payload });

export default setSimpleTextNotificationTiles;
