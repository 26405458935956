import React, { Dispatch, SetStateAction } from 'react';

import { View, TouchableOpacity, GestureResponderEvent } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from 'components';
import { setSelectedMedicationId } from 'store/actions';
import { ReminderTypesEnum, PSPReminderTimeMetricsType } from 'store/reducers/medicine-cabinet/types';
import { getPSPMedicationWithoutReminder, getPSPReminderTemplate } from 'store/selectors/medicine-cabinet';
import { DefaultText } from 'style/common';
import { AnyFunction } from 'utils/types';

import { useReminderOverviewStyles } from './styles';

type ReminderModalProps = {
  submitIsDisabled: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  submitHandler?: (event: GestureResponderEvent) => void;
  onClose: AnyFunction;
};

const suffixMapping: Record<PSPReminderTimeMetricsType, string> = {
  [ReminderTypesEnum.EVERY_X_WEEKS]: 'weeks',
  [ReminderTypesEnum.EVERY_X_HOURS]: 'hours',
  [ReminderTypesEnum.EVERY_X_DAYS]: 'days',
};

export const ReminderOverview = ({ submitIsDisabled, setEditMode, submitHandler, onClose }: ReminderModalProps) => {
  const medicationsWithoutReminder = useSelector(getPSPMedicationWithoutReminder);
  const pspReminderTemplate = useSelector(getPSPReminderTemplate);
  const { type = ReminderTypesEnum.EVERY_X_HOURS, frequency = 0 } = pspReminderTemplate || {};

  const dispatch = useDispatch();

  const styles = useReminderOverviewStyles();

  if (!medicationsWithoutReminder || !pspReminderTemplate?.type) return null;

  return (
    <View style={styles.wrapper}>
      <DefaultText style={styles.reminderTitle}>Please confirm your medication reminder</DefaultText>
      <DefaultText style={styles.reminderDescription}>
        {`Confirm the following medication reminder for ${medicationsWithoutReminder.name}. We’ll add it to your medication cabinet.`}
      </DefaultText>
      <View style={styles.formWrapper}>
        <DefaultText style={styles.medicationName}>{medicationsWithoutReminder.name}</DefaultText>
        <DefaultText style={styles.medicationParams}>{medicationsWithoutReminder.totalDose} Dose</DefaultText>
        <DefaultText style={styles.medicationParams}>{`Take every ${frequency} ${suffixMapping[type]}`}</DefaultText>
      </View>
      <View style={styles.buttonWrapper}>
        <Button
          variant='tertiary'
          text='Edit'
          style={styles.editButton}
          onPress={() => {
            dispatch(setSelectedMedicationId(medicationsWithoutReminder.id));
            setEditMode(true);
          }}
        />
        <Button
          onPress={submitHandler}
          variant='mango'
          disabled={submitIsDisabled}
          style={styles.mangoButton}
          text='Add'
        />
      </View>
      <TouchableOpacity style={styles.skipButtonWrapper} onPress={onClose}>
        <DefaultText>Skip for now</DefaultText>
      </TouchableOpacity>
    </View>
  );
};

export default ReminderOverview;
