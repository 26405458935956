import { APP_NAME } from 'utils/constants';
import { isWebNativeDevice, isWebBrowser, openUrlInNewTab } from 'utils/helpers';

export const text = {
  downloadCardTitle: `Go Mobile and download the ${APP_NAME} App`,
  downloadCardDescription: 'Always stay connected for a personalized experience.',
  questionModalTitle: `Download the ${APP_NAME} App on your phone.`,
  questionModalDescription: `Would you like to receive a text containing a link to the ${APP_NAME} App?`,
  sendTitle: 'App download link has been sent',
  sendDescription: `Please check your mobile device.`,
  sendOptInTitle: 'Please opt in to receive SMS communications.',
  sendOptInDescription: 'Please check your mobile device.',
};

export const getDownloadButtonsVisibilityState = (condition: boolean) => (isWebNativeDevice ? condition : isWebBrowser);

export const openAndroidUrl = async () =>
  await openUrlInNewTab('https://play.google.com/store/apps/details?id=com.tc.patientlink');
export const openIOSUrl = async () =>
  await openUrlInNewTab('https://apps.apple.com/us/app/patientlink-by-trialcard/id1635680252');

// google analytics constants
const APP_DOWNLOAD_ACTION_TRIGGERED = 'APP_DOWNLOAD_ACTION_TRIGGERED';
const APP_DOWNLOAD_ACTION_FAILED = 'APP_DOWNLOAD_ACTION_FAILED';

export const downloadCardAnalyticsActions = {
  APP_DOWNLOAD_ACTION_TRIGGERED,
  APP_DOWNLOAD_ACTION_FAILED,
};
