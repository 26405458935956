export enum NotificationStatus {
  SKIPPED = 'skipped',
  IGNORED = 'ignored',
  DISMISSED = 'dismissed',
  ACKNOWLEDGED = 'acknowledged',
}

export enum MedCabinetWidgetType {
  MEDICATION = 'medication',
  REMINDER = 'reminder',
}

export enum CabinetInfoType {
  NOTIFY = 'notify',
  WARNING = 'warning',
}
