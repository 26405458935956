import React from 'react';

import { View } from 'react-native';

import { useTourStepsLength } from 'components/Tourguide/steps';

import { getTourProgressHeaderStyle } from './style';
import { TourProgressBar } from './TourProgressBar';

type TourProgressHeaderProps = {
  currentStep: number;
};

export const TourProgressHeader = ({ currentStep }: TourProgressHeaderProps) => {
  const styles = getTourProgressHeaderStyle;

  const allStepsLength = useTourStepsLength();

  return (
    <View style={styles.headerProgressContainer}>
      {new Array(allStepsLength).fill(null).map((_, index) => (
        <TourProgressBar key={index} index={index} active={currentStep >= index} />
      ))}
    </View>
  );
};
