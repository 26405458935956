import React from 'react';

import { Pill } from 'assets/icons';
import { useNoHaveDataConfig } from 'screens/main/MedicationTracker/components/DailyMedications/components/hooks';
import { CabinetEmpty } from 'screens/main/MedicineCabinet/components/CabinetWidgets';
import { MedCabinetWidgetType } from 'screens/main/MedicineCabinet/types';

type Props = {
  selectedDate: number;
  selectedMedicationId: string;
};

export const NoHaveMedicationDataSection = ({ selectedDate, selectedMedicationId }: Props) => {
  const config = useNoHaveDataConfig(selectedDate, selectedMedicationId);

  if (!config) return null;

  return <CabinetEmpty Icon={Pill} actionType={MedCabinetWidgetType.MEDICATION} isMedicationTracker {...config} />;
};
