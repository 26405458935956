import { MedicationField } from 'screens/main/MedicineCabinet/components/FieldBlock/types';
import { PLACEHOLDERS } from 'utils/constants';

export const getFields = (): MedicationField[] => [
  {
    fieldName: 'type',
    labelText: 'Method',
    inputType: 'Select',
    numeric: false,
    rules: {
      required: true,
    },
  },
  {
    fieldName: 'totalDose',
    labelText: 'Dosage',
    placeholder: PLACEHOLDERS.DOSAGE,
    inputType: 'Input',
    numeric: true,
  },
  {
    fieldName: 'dosageAmount',
    labelText: 'Strength',
    placeholder: PLACEHOLDERS.STRENGTH,
    inputType: 'Input',
    numeric: false,
  },
  {
    fieldName: 'quantity',
    labelText: 'Quantity',
    placeholder: PLACEHOLDERS.QUANTITY,
    inputType: 'Input',
    numeric: true,
  },
  {
    fieldName: 'dateStarted',
    labelText: 'Date Started',
    inputType: 'DatePicker',
    numeric: false,
    rules: {
      minDate: new Date(),
    },
  },
];
