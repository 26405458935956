import React from 'react';

import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Pill = (props: SvgProps) => {
  return (
    <Svg width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <Rect
        x='-0.172212'
        y='11.9651'
        width='17.2122'
        height='5.94977'
        rx='2.97489'
        transform='rotate(-45 -0.172212 11.9651)'
        stroke={props.stroke || defaultTheme.colors.gray2}
        strokeWidth='1.5'
      />
      <Path d='M6 6L10 10' stroke={props.stroke || defaultTheme.colors.gray2} strokeWidth='1.5' />
    </Svg>
  );
};

export default Pill;
