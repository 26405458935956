import React, { useState, useRef, ReactNode } from 'react';

import { Pressable, View, StyleProp, ViewStyle } from 'react-native';

import { useSpring, AnimatedWrapper } from 'components/Animated/Animated.native';
import { useHover } from 'hooks';
import { isNativeDevice } from 'utils/helpers';

import { styles } from './styles';

type PopoverProps = {
  children: ReactNode;
  tooltipContent: ReactNode;
  containerStyle?: StyleProp<ViewStyle>;
};

export const Popover = ({ children, tooltipContent, containerStyle }: PopoverProps) => {
  const [isPressed, setPressed] = useState(false);
  const anchorRef = useRef(null);

  const isHovered = useHover(anchorRef);

  const onPress = () => {
    setPressed(true);
    setTimeout(() => {
      setPressed(false);
    }, 2000);
  };

  const opacityValue = (!isNativeDevice && isHovered) || (isNativeDevice && isPressed) ? 1 : 0;
  const animatedStyles = useSpring({ opacity: opacityValue });

  return (
    <View style={[styles.wrapper, containerStyle]}>
      <Pressable ref={anchorRef} onPress={onPress}>
        {children}
      </Pressable>
      {(isHovered || isPressed) && (
        <AnimatedWrapper style={{ opacity: animatedStyles.opacity }}>
          <View style={styles.content}>{tooltipContent}</View>
        </AnimatedWrapper>
      )}
    </View>
  );
};

export default Popover;
