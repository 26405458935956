import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useRoute, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { Layout } from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import { ProfileBadge } from 'components/ProfileBadge';
import { useScroll } from 'hooks';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { profileAnalyticsHandler } from 'screens/main/Profile/helpers';
import { getAppDataIsLoading } from 'store/selectors';

import { title, description, profileAnalyticsActions } from './constants';
import { ProfileSection } from './sections';
import { BadgeContainer, ProfileContainer, ProfileContentWrapper, mobileContentAreaStyle } from './styles';
import { ProfileScreenRouteProp } from './types';

const { VIEWED_PROFILE_CONTENT } = profileAnalyticsActions;

export const Profile = () => {
  const { isTablet } = useTheme();

  const dataIsLoading = useSelector(getAppDataIsLoading);

  const navigation = useNavigation<DrawerNavigationHelpers>();

  const route = useRoute<ProfileScreenRouteProp>();

  useFirstEffect(() => {
    profileAnalyticsHandler(VIEWED_PROFILE_CONTENT, getAnalyticEventLabel());
  }, []);

  const { scrollViewRef, setComponentViewY } = useScroll({
    shouldScroll: !!route.params?.isInsurance,
  });

  if (dataIsLoading) return <FullScreenLoader />;

  return (
    <Layout
      contentAreaStyle={isTablet ? mobileContentAreaStyle : {}}
      title={title}
      description={description}
      navigation={navigation}
      scrollViewRef={scrollViewRef}>
      <ProfileContainer>
        <ProfileContentWrapper>
          <ProfileSection setInsuranceSectionPosition={setComponentViewY} />
        </ProfileContentWrapper>
        <BadgeContainer>
          <ProfileBadge />
        </BadgeContainer>
      </ProfileContainer>
    </Layout>
  );
};

export default Profile;
