import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Lozenge = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 96.13 93.92' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M50.43 93.91H16.98C9.37 93.89.11 85.05.08 77.42Q-.08 44 .07 10.54.12.12 10.58.07C33.55 0 56.52.06 79.49 0A9.13 9.13 0 0 1 86 2.15c5 4.9 10.39 9.16 10.16 17.7-.57 20.61-.18 41.25-.19 61.88 0 8.54-3.6 12.16-12.06 12.18q-16.75.02-33.48 0Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <Path
          d='M96.13 19.85c.23-8.54-5.2-12.8-10.16-17.7a6.28 6.28 0 0 0-1.09-.83 16.06 16.06 0 0 1 3.17 10.25c-.56 20.62-.18 41.26-.19 61.89 0 8.53-3.59 12.16-12.06 12.17H8.9a14.43 14.43 0 0 1-7.11-2.2c3 5.62 9.56 10.46 15.19 10.47h66.9c8.46 0 12.05-3.64 12.06-12.18.06-20.62-.38-41.26.19-61.87Z'
          fillOpacity={0.2}
        />
        <Circle cx={44.23} cy={41.78} r={26.33} fillOpacity={0.1} />
        <Circle fill='#fff' fillOpacity={0.4} cx={12.42} cy={12.08} r={5.51} />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M73.59 4.57H44.35a5.76 5.76 0 0 0-5 2.77 2.13 2.13 0 0 0 2 3.25c1-.09 1.93-.14 2.91-.14a31.33 31.33 0 0 1 31.3 31.33q0 1.38-.12 2.73a2.11 2.11 0 0 0 3 2.06 5.76 5.76 0 0 0 3.12-5.18V12.58a8 8 0 0 0-7.97-8.01Z'
        />
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M58.62 42.53c2 4.35 8.45 2.78 8.36-2-.24-11.72-9.41-20.93-22.62-19.06-4.84.68-5 7.51-.26 8.6 7.64 1.77 12.06 7.01 14.52 12.46Z'
        />
      </G>
    </G>
  </Svg>
);

export default Lozenge;
