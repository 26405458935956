import React from 'react';

import { View, StyleSheet } from 'react-native';

import { DirectionProps, Style } from './types';

export const Row = ({ size, style, children }: DirectionProps) => {
  const flattenedStyle = StyleSheet.flatten(style);
  const viewStyle: Style = [
    {
      flexDirection: 'row',
      flex: size ? size : flattenedStyle?.height ? 0 : 1,
      ...(flattenedStyle?.height && { flexBasis: flattenedStyle.height }),
    },
    style,
  ];

  return <View style={viewStyle}>{children}</View>;
};

export default Row;
