import React, { useMemo } from 'react';

import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { Dot } from 'assets/icons';
import { CircularProgressBar } from 'components/CircularProgressBar';
import { getMedTrackerInfo } from 'store/selectors';
import { DefaultText } from 'style/common';
import { isNumber } from 'utils/helpers';

import { constants } from './constants';
import { useProgressLegendStyles } from './styles';

export const ProgressLegend = () => {
  const medTrackerInfo = useSelector(getMedTrackerInfo);
  const { colors, styles } = useProgressLegendStyles();

  const progressBarValue = useMemo(() => {
    if (
      !medTrackerInfo?.compliance?.length ||
      !isNumber(medTrackerInfo?.totalDosesTaken) ||
      !isNumber(medTrackerInfo?.totalMissedDoses)
    )
      return null;

    return (
      (medTrackerInfo.totalDosesTaken / (medTrackerInfo.totalDosesTaken + medTrackerInfo.totalMissedDoses)) * 100 || 0
    );
  }, [medTrackerInfo]);

  const nullDosesJSX = <DefaultText style={styles.nullDosesText}>{constants.nullDosesText}</DefaultText>;
  const dosesDescriptionJSX = (
    <>
      <View style={styles.positiveTextWrapper}>
        <Dot color={colors.positiveColor} />
        <DefaultText
          style={styles.dosesText}>{`${medTrackerInfo?.totalDosesTaken} ${constants.dosesTaken}`}</DefaultText>
      </View>
      <View style={styles.negativeTextWrapper}>
        <Dot color={colors.negativeColor} />
        <DefaultText style={styles.dosesText}>
          {`${medTrackerInfo?.totalMissedDoses} ${constants.dosesMissed}`}
        </DefaultText>
      </View>
    </>
  );

  return (
    <View style={styles.container}>
      <CircularProgressBar value={progressBarValue} initialColor={colors.negativeColor} />
      <View style={styles.wrapper}>{progressBarValue == null ? nullDosesJSX : dosesDescriptionJSX}</View>
    </View>
  );
};
