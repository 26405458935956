import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

export const useLoaderStyles = () => {
  const { fontSizes, fontFamilies } = useTheme();

  return StyleSheet.create({
    loadingWrapper: {
      ...StyleSheet.absoluteFillObject,
      justifyContent: 'center',
      alignItems: 'center',
    },
    loaderText: { ...fontSizes[16], fontFamily: fontFamilies.semiBold, marginLeft: 6 },
    buttonLoaderWrapper: { flexDirection: 'row' },
  });
};
