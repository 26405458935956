import type { UpdateUserInsurance } from 'store/actions/user/actionCreators/insurances/updateUserInsurance';
import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import type { User } from 'store/reducers/user-info/types';

const updateUserInsurance = (state = INITIAL_STATE, payload: ReturnType<UpdateUserInsurance>['payload']): User => {
  const insuranceToBeUpdated = state.insurances.find((insurance) => insurance.id === payload.id);

  const updatedInsurances = state.insurances.map((insurance) => {
    if (insurance.id === insuranceToBeUpdated?.id) {
      return { ...insurance, ...payload };
    }
    return insurance;
  });

  return {
    ...state,
    insurances: updatedInsurances,
  };
};

export default updateUserInsurance;
