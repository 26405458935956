import React, { useState } from 'react';

import { useSpring, animated } from '@react-spring/native';
import { TouchableOpacity, View } from 'react-native';

import { MilestoneTrackerData } from 'api/Benefits/types';
import { ArrowDown } from 'assets/icons';
import { HistoryItem } from 'components/HistoryItem';
import { DefaultText, StandardText } from 'style/common';
import { appDateFormatter } from 'utils/date/appDateFormatter';

import { useHistoryItemStyle } from './styles';

type HistoryItemsBlockProps = {
  trackerData: MilestoneTrackerData;
};

const AnimatedView = animated(View);

export const HistoryItemsBlock = ({ trackerData }: HistoryItemsBlockProps) => {
  const [isExpanded, setExpanded] = useState(true);
  const styles = useHistoryItemStyle({ isExpanded, status: trackerData?.trackerStatus || 'Completed' });

  const milestonesCount = trackerData?.milestones.length || 0;

  const wrapperHeightStyles = useSpring({
    maxHeight: isExpanded ? milestonesCount * 74 + milestonesCount * 64 : 0, // Milestones heights + milestones expand heights
  });

  return (
    <>
      <View style={styles.historyBlockWrapper}>
        <View style={styles.historyTitleContainer}>
          <StandardText style={styles.historyDate}>
            {appDateFormatter(trackerData.createdAt, 'MMMM dd, yyyy', '')}
          </StandardText>
          <StandardText numberOfLines={3}>{trackerData.name}</StandardText>
        </View>
        <View style={styles.historyTitleContainer}>
          <View style={styles.trackerStatusWrapper}>
            <DefaultText style={styles.trackerStatusText}>{trackerData.trackerStatus}</DefaultText>
          </View>
          <TouchableOpacity style={styles.icon} onPress={() => setExpanded((prev) => !prev)}>
            <ArrowDown />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.historyItemsWrapper}>
        <AnimatedView style={wrapperHeightStyles}>
          {trackerData.milestones.map(({ createdAt, milestoneStatus, title, userData }, i) => (
            <HistoryItem
              key={i.toString()}
              title={title}
              description={userData?.description || ''}
              status={milestoneStatus}
              date={appDateFormatter(createdAt, 'MMMM dd, yyyy', '')}
              isLastItem={i === trackerData.milestones.length - 1}
            />
          ))}
        </AnimatedView>
      </View>
    </>
  );
};
