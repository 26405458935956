import {
  isWebNativeDevice,
  isWebNativeIOSDevice,
  isWebNativeAndroidDevice,
  isWebNativeOthersDevice,
  isWebBrowser,
  isNativeDevice,
  isIOSDevice,
  isAndroidDevice,
} from 'utils/helpers';
import { AnyFunction } from 'utils/types';

export type ApplyDifferentDevicesFunctionConfig = {
  onDesktopWeb?: AnyFunction;
  onNativeDevice?:
    | AnyFunction
    | Partial<{
        onIOS: AnyFunction;
        onAndroid: AnyFunction;
        onOthers: AnyFunction;
      }>;
  onNativeDeviceWeb?:
    | AnyFunction
    | Partial<{
        onIOS: AnyFunction;
        onAndroid: AnyFunction;
        onOthers: AnyFunction;
      }>;
};

export const applyDifferentDevicesFunction = ({
  onNativeDeviceWeb,
  onNativeDevice,
  onDesktopWeb,
}: ApplyDifferentDevicesFunctionConfig) => {
  if (onNativeDeviceWeb && isWebNativeDevice) {
    if (typeof onNativeDeviceWeb === 'function') return onNativeDeviceWeb();

    if (isWebNativeIOSDevice) onNativeDeviceWeb.onIOS?.();
    else if (isWebNativeAndroidDevice) onNativeDeviceWeb.onAndroid?.();
    else if (isWebNativeOthersDevice) onNativeDeviceWeb.onOthers?.();

    return;
  }

  if (onDesktopWeb && isWebBrowser) return onDesktopWeb();

  if (onNativeDevice && isNativeDevice) {
    if (typeof onNativeDevice === 'function') return onNativeDevice();

    if (isIOSDevice) onNativeDevice.onIOS?.();
    else if (isAndroidDevice) onNativeDevice.onAndroid?.();
  }
};
