import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { Image, View } from 'react-native';

import { InsuranceImages } from 'api/Insurance/types';
import { Button } from 'components';
import { PhotoPicker, PhotoPickerProps } from 'components/PhotoPicker/PhotoPicker';
import { WebGallery, WebGalleryRef } from 'components/PhotoPicker/WebGallery';
import { getBase64SizeInMB } from 'screens/main/Profile/sections/InsuranceInformation/useUploadInsuranceImages';
import { StandardText } from 'style/common';
import { applyDifferentDevicesFunction } from 'utils/applyDifferentDevicesFunction';
import { isNativeDevice, isWebBrowser, isWebNativeDevice } from 'utils/helpers';
import { AnyFunction } from 'utils/types';

import { useInsuranceEditCardContentStyles } from './styles';

type InsuranceImageType = 'Front' | 'Back';

type UploadImageItem = {
  title: InsuranceImageType;
  onFilePick: Dispatch<SetStateAction<string | undefined>>;
  image?: string;
};

export type InsuranceEditCardContentProps = {
  forceRerender: AnyFunction;
  onChange: (images: InsuranceImages) => void;
};

export const InsuranceEditCardContent = ({ onChange, forceRerender }: InsuranceEditCardContentProps) => {
  const styles = useInsuranceEditCardContentStyles();

  const webGalleryRef = useRef<WebGalleryRef>(null);

  const [{ visible, onImagePick }, setCameraProps] = useState<Pick<PhotoPickerProps, 'visible' | 'onImagePick'>>({
    visible: false,
    onImagePick: () => null,
  });

  const setVisible = useCallback(
    (value: boolean) =>
      setCameraProps({
        visible: value,
        onImagePick,
      }),
    [onImagePick]
  );

  const [frontImage, setFrontImage] = useState<string>();
  const [backImage, setBackImage] = useState<string>();

  useEffect(() => {
    forceRerender();
  }, [forceRerender, frontImage, backImage]);

  useEffect(() => {
    onChange({ front: frontImage, back: backImage });
  }, [frontImage, backImage, onChange]);

  const uploadImageItems: UploadImageItem[] = [
    { title: 'Front', onFilePick: setFrontImage, image: frontImage },
    { title: 'Back', onFilePick: setBackImage, image: backImage },
  ];

  const shouldTakeImageFromGallery = isWebNativeDevice || isWebBrowser;

  return (
    <View>
      {shouldTakeImageFromGallery && <WebGallery ref={webGalleryRef} onImagePick={onImagePick} />}
      {isNativeDevice && (
        <PhotoPicker withGalleryPicking visible={visible} onImagePick={onImagePick} setVisible={setVisible} />
      )}
      {uploadImageItems.map(({ title, onFilePick, image }, i) => (
        <View key={title} style={{ width: '100%', marginTop: i ? 20 : 0 }}>
          <StandardText>{title}</StandardText>
          <View style={styles.imageFieldWrapper}>
            <View style={styles.imageWrapper}>
              <Image resizeMode='cover' source={{ uri: image }} style={styles.emptyImageContent} />
            </View>
            <Button
              onPress={() => {
                const openGallery = () => {
                  setCameraProps({ visible: false, onImagePick: onFilePick });
                  webGalleryRef.current?.openGallery();
                };
                applyDifferentDevicesFunction({
                  onNativeDevice: () => setCameraProps({ visible: true, onImagePick: onFilePick }),
                  onNativeDeviceWeb: openGallery,
                  onDesktopWeb: openGallery,
                });
              }}
              containerStyle={{ width: '40%' }}
              style={styles.button}
              text='Upload'
              variant='tertiary'
            />
          </View>
          {image && <StandardText>{`Size ${getBase64SizeInMB(image).toFixed(2)} MB`}</StandardText>}
        </View>
      ))}
    </View>
  );
};
