import React, { useState, useRef, useCallback } from 'react';

import { TouchableOpacity, View, SafeAreaView } from 'react-native';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { Close } from 'assets/icons';
import { ImageViewer } from 'components/ImageViewer';
import PdfViewer from 'components/PdfViewer';
import VideoViewer from 'components/VideoViewer';
import { useOnClickOutside } from 'hooks';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { additionalServicesAnalyticsActions } from 'screens/main/AdditionalServices/constants';
import { additionalServicesAnalyticsHandler } from 'screens/main/AdditionalServices/helpers';
import { ServiceFileTypesEnum } from 'store/reducers/additional-services/types';
import { DefaultText, webStyles } from 'style/common';
import { useStylesWithAdditional } from 'style/hooks';
import { AnyFunction } from 'utils/types';

import { Title } from '../ConfirmShipingAddress/styles';
import { useDigitalResourceStyles } from './styles';

export type DigitalResourceProps = {
  source?: string;
  modalTitle?: string;
  resourceType?: ServiceFileTypesEnum;
  closeHandler: AnyFunction;
};

const { VIEW_ADDITIONAL_SERVICE_RESOURCE } = additionalServicesAnalyticsActions;

export const DigitalResource = ({
  source,
  modalTitle,
  resourceType = ServiceFileTypesEnum.VIDEO,
  closeHandler,
}: DigitalResourceProps) => {
  const { styles, colors, isMobile } = useStylesWithAdditional(useDigitalResourceStyles, resourceType);

  const [buttonStyle, setButtonStyle] = useState({});

  const viewRef = useRef<View>(null);
  const closeHandlerRef = useRef<DigitalResourceProps['closeHandler']>();
  closeHandlerRef.current = closeHandler;

  const onClickOutsideHandler = useCallback(() => {
    closeHandlerRef.current?.();
  }, []);

  // @ts-ignore TODO: typing
  useOnClickOutside(viewRef, onClickOutsideHandler);

  useFirstEffect(
    () => {
      additionalServicesAnalyticsHandler(VIEW_ADDITIONAL_SERVICE_RESOURCE, getAnalyticEventLabel(resourceType));
    },
    [resourceType, modalTitle],
    false
  );

  const buttonStyleHandler = () => setButtonStyle({ outline: 'none' });

  const renderResource = () => {
    switch (resourceType) {
      case ServiceFileTypesEnum.VIDEO:
        return (
          <VideoViewer
            source={source || 'https://web-media-content.s3.amazonaws.com/video/TrialCard+-+Simplify+Access.mp4'}
          />
        );
      case ServiceFileTypesEnum.PDF:
        return (
          <PdfViewer
            title={modalTitle}
            source={source || 'https://web-media-content.s3.amazonaws.com/pdf/PDF_File.pdf'}
          />
        );
      case ServiceFileTypesEnum.IMAGE:
        return (
          <ImageViewer
            source={
              source ||
              'https://zed-content.s3-accelerate.amazonaws.com/Background/Hospital%201.png?AWSAccessKeyId=AKIAIP7HHMN3H2GI7RZA&Expires=1639038751&response-content-type=image%2Fpng&Signature=xFpoY%2FcMectdFq9v%2FLRAbsmYlJw%3D'
            }
          />
        );
      default:
        return <DefaultText>Not supported file type</DefaultText>;
    }
  };

  if (isMobile)
    return (
      <SafeAreaView style={styles.mobileWrapper}>
        <View style={styles.mobileHeaderWrapper}>
          <TouchableOpacity onPress={closeHandler} style={styles.mobileCloseButton} onFocus={buttonStyleHandler}>
            <Close width={14} height={14} stroke={colors.white} />
          </TouchableOpacity>
        </View>
        <View style={styles.mobileContentWrapper}>
          <View style={styles.mobileContent}>{renderResource()}</View>
        </View>
      </SafeAreaView>
    );

  return (
    <View ref={viewRef}>
      <View style={styles.headerWrapper}>
        {!!modalTitle && <Title style={styles.title}>{modalTitle}</Title>}
        <TouchableOpacity onPress={closeHandler} style={[webStyles.button, buttonStyle]} onFocus={buttonStyleHandler}>
          <Close width={14} height={14} stroke={colors.gray2} />
        </TouchableOpacity>
      </View>
      {renderResource()}
    </View>
  );
};

export default DigitalResource;
