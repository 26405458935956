import { StyleSheet } from 'react-native';
import { DefaultTheme } from 'styled-components/native';

import { isNativeDevice } from 'utils/helpers';

// open width : 267 , close width: 72
export const getLeftSideDrawerStyles = (theme: DefaultTheme) =>
  StyleSheet.create({
    drawerContainer: {
      shadowColor: '#000',
      shadowOpacity: 0.25,
      shadowRadius: 16,
      height: '100%',
      backgroundColor: '#fff',
      width: 267,
      paddingTop: 16,
      zIndex: 10,
    },
    logoWrapper: { marginBottom: 8, paddingLeft: 24, height: 40 },
    menuSectionWrapper: { overflow: 'hidden' },
    expandToolBarWrapper: {
      position: 'absolute',
      top: 8,
      right: -8,
      width: 16,
      height: 16,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 8,
      backgroundColor: theme.colors.gray3,
      ...(!isNativeDevice && { cursor: 'pointer' }),
      zIndex: 10,
    },
  });
