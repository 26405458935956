import { MilestoneTrackerData, BenefitInvestigationsData } from 'api/Benefits/types';
import { SpecialtyPharmacyDTO } from 'api/SpecialtyPharmacy/types';
import { InfoCardTypeEnum } from 'enums';
import { InsuranceTileInfo } from 'screens/main/Home/components/MissingInsuranceCard/types';
import { GeneralInfoCardsState } from 'store/reducers/home-info-cards/types';

export const isSelectedInfoCardShown =
  (selectedCardTitle: InfoCardTypeEnum) =>
  (state: GeneralInfoCardsState): boolean =>
    !!state.homeInfoCards.shownCards.find((card) => card.title === selectedCardTitle && card.shown);

export const getMilestoneTrackers = (state: GeneralInfoCardsState): MilestoneTrackerData[] =>
  state.homeInfoCards.milestoneTrackers;

export const isMilestoneTrackerFinished = (state: GeneralInfoCardsState): boolean =>
  !!state.homeInfoCards.milestoneTrackers[0]?.milestones.every((milestone) => !!milestone.userData);

export const getBenefitInvestigations = (state: GeneralInfoCardsState): BenefitInvestigationsData | null =>
  state.homeInfoCards.benefitInvestigations;

export const getSpecialtyPharmacy = (state: GeneralInfoCardsState): SpecialtyPharmacyDTO | null =>
  state.homeInfoCards.specialtyPharmacy;

export const getInsuranceTileInfo = (state: GeneralInfoCardsState): InsuranceTileInfo | null =>
  state.homeInfoCards.insuranceTileInfo;
