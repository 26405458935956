import React from 'react';

import { View } from 'react-native';

import { SubTitle } from 'style/common';

import {
  BarWrapper,
  GrayIndicatorBar,
  IndicatorBorderWrapper,
  IndicatorDisplayText,
  IndicatorDisplayWrapper,
  IndicatorWrapper,
  ProgressIndicator,
  RemainingValueText,
  RemainingValueTextWrapper,
  useIndicatorStyles,
} from './styles';
import { RemainingIndicatorProps } from './types';

export const RemainingIndicator = ({
  title,
  usedValue = 0,
  remainingValue = 0,
  prefix = '',
  showOnlyUsedMetrics,
  maximumAvailable = 0,
}: RemainingIndicatorProps) => {
  const progressPercentage = (usedValue / maximumAvailable) * 100 || 0;
  const shouldShowRemainingText = Boolean(!showOnlyUsedMetrics && remainingValue && progressPercentage);

  const styles = useIndicatorStyles();

  return (
    <IndicatorWrapper>
      <SubTitle style={styles.subTitle}>{title}</SubTitle>
      <View>
        <IndicatorDisplayWrapper>
          <IndicatorDisplayText>{`${prefix}${usedValue || 0} Used`}</IndicatorDisplayText>
          {!showOnlyUsedMetrics && (
            <IndicatorDisplayText>{`${prefix}${maximumAvailable || 0} Total`}</IndicatorDisplayText>
          )}
        </IndicatorDisplayWrapper>
        <BarWrapper shouldShowRemainingText={shouldShowRemainingText}>
          <GrayIndicatorBar />
          <IndicatorBorderWrapper width={`${progressPercentage}%`}>
            <ProgressIndicator />
            <RemainingValueTextWrapper indicatorValue={progressPercentage}>
              {shouldShowRemainingText && (
                <>
                  <RemainingValueText bold>
                    {prefix}
                    {remainingValue}
                  </RemainingValueText>
                  <RemainingValueText>Remaining</RemainingValueText>
                </>
              )}
            </RemainingValueTextWrapper>
          </IndicatorBorderWrapper>
        </BarWrapper>
      </View>
    </IndicatorWrapper>
  );
};

export default RemainingIndicator;
