import { StyleSheet, ViewStyle } from 'react-native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';

type Config = {
  stepItemWrapperStyle?: ViewStyle;
  stepsCount: number;
};

export const useStyles = ({ stepItemWrapperStyle, stepsCount }: Config) => {
  const { isMobile } = useDeviceInfoContext();

  return StyleSheet.create({
    stepsWrapper: {
      flexDirection: 'row',
      marginTop: 10,
      width: '100%',
    },
    stepItemWrapper: {
      width: `${100 / stepsCount}%`,
      paddingLeft: 8,
      ...stepItemWrapperStyle,
    },
    stepItemIcon: {
      width: '100%',
      borderRadius: 16,
    },
    stepItemText: {
      alignSelf: 'center',
      marginTop: 12,
      textAlign: 'center',
      display: isMobile ? 'none' : 'flex',
    },
  });
};

export const progressBarItemStyle: ViewStyle = {
  borderRadius: 16,
  height: 8,
};
