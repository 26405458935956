module.exports = {
  name: 'PRODUCTION',
  appName: 'PatientLink',
  apiURL: 'https://patientlinkbytc.com',
  androidPackageID: 'com.tc.patientlink',
  iosBundleID: 'com.patientlinkbytc.ios',
  associatedDomain: 'txtbypl.com',
  firebaseApiKey: 'AIzaSyAlzfP272HQibx8-anH_78mOly7ROwgv9c',
  firebaseAuthDomain: 'patient-link-9c2d9.firebaseapp.com',
  firebaseProjectId: 'patient-link-9c2d9',
  firebaseStorageBucket: 'patient-link-9c2d9.appspot.com',
  firebaseMessagingSenderId: '663599681354',
  firebaseAppId: '1:663599681354:web:c2ae49fb5f81de2c08bbb3',
  firebaseMeasurementId: 'G-C4FW829GFN',
  googleServicesFileANDROID: './google-services.prod.json',
  googleServicesFileIOS: './GoogleService-Info.prod.plist',
  extraAppConfig: {
    eas: {
      projectId: '3f992c19-4b18-48c0-a956-a6ded454e3f3',
    },
  },
  additionalAppProps: {
    runtimeVersion: {
      policy: 'sdkVersion',
    },
  },
  appConfigHooks: {
    postPublish: [
      {
        file: 'sentry-expo/upload-sourcemaps',
        config: {
          organization: 'zealic-solutions',
          project: 'patient-link-app',
          authToken: 'f8bd118ed53d44159e74a814821d06271e2a5106204048ca88fc146c6f4c7b01',
        },
      },
    ],
  },
};
