import { ViewStyle } from 'react-native';

export enum TileTypesEnum {
  notUsed = 'notUsed',
  used = 'used',
  missedRefill = 'missedRefill',
  upcomingRefill = 'upcomingRefill',
}

export type Tile = {
  headerText: string;
  descriptionText: string;
  templateSrc: string;
  headerTextColor?: string;
  templateBackgroundColor?: string;
  templateWrapperStyle?: ViewStyle;
};

export type TileMapping = {
  [key in TileTypesEnum]: Tile;
};

export type Notification = {
  id: number;
  type: TileTypesEnum;
  date?: string;
};
