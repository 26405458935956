export const APP_NAME = 'PatientLink';
export const TRY_AGAIN_ERROR_MESSAGE = 'Oops, try again!';
export const SOMETHING_WRONG_ERROR_MESSAGE = 'Oops, something went wrong!';
export const BAD_INTERNET_CONNECTION_ERROR_MESSAGE =
  'Oops, something went wrong. Please check your internet connection.';

export const EMAIL_OR_PASSWORD_ARE_INVALID_ERROR_MESSAGE = 'This email and/or password are invalid';

export const PLACEHOLDERS = {
  DOSAGE: '1',
  STRENGTH: '10 mg, 100 ml, etc.',
  QUANTITY: '30',
};

export const REQUIRED_FIELD = 'This field is required';
