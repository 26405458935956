export type ReminderNotificationType = 'every_hour' | 'every_day';

// Do not fix typos -> it backend value
export enum NotificationStatusEnum {
  SKIPPED = 'skipped',
  IGNORED = 'ignored',
  DISMISSED = 'dismissed',
  ACKNOWLEDGED = 'acknowledged',
}
// Do not fix typos -> it backend value
export enum AdherenceStatusEnum {
  UNKNOWN = 'UNKNOWN',
  SUCCESSFUL = 'SUCCESSFULL',
  FAIL = 'FAIL',
  INCOMING = 'INCOMING',
}

export type MedicationData = {
  drugId?: number;
  name?: string;
  type?: string;
  dosageAmount: string;
  totalDose: string;
};

export type AddReminderData = {
  medication_id?: number | string;
  type: string;
  time: string | Date;
  hour: number | null;
  user_datetime?: string;
};

export type AddReminderReturnData = {
  data: {
    dosageAmount: string;
    id: number;
    name: string;
    totalDose: number;
    type: string;
  };
};

export type UpdateReminderData = {
  type: string;
  time: string | Date;
  hour: number | null;
};

enum DrugTypeEnum {
  RX = 'RX',
  OTC = 'OTC',
}

type DrugData = {
  drug_id: string;
  id: number;
  name: string;
  type: DrugTypeEnum;
};

export type SearchDrugReturnData = {
  data: DrugData[];
};

export type MedicationHistory = {
  id: number;
  timestamp: string;
  status: NotificationStatusEnum;
};

export type DailyMedication = {
  id: number;
  type: ReminderNotificationType;
  time: string;
  hour: number;
  status: AdherenceStatusEnum;
  medication_id: number;
  medication: {
    id: number;
    name: string;
    details_file_url: string;
    drugId: number;
    dosageAmount: string;
    dateStarted: string;
    totalDose: number;
    type: string;
    quantity: number;
    history: MedicationHistory[];
    historyIds: number[];
  };
  every_x_hours: number;
  time_of_day: string;
  every_x_days: number;
  every_x_weeks: number;
  date_time: string;
  start_date: string;
  is_deleted: boolean;
};

export type Medication = {
  id: number;
  name: string;
  totalDose: number;
  dosageAmount: string;
  dateStarted: string;
  quantity: number;
  type: string;
  details_file_url?: string;
  sponsored_program_uuid: string | null;
  isProgramMedication?: boolean;
  drugId: number | string;
  is_program_sponsored?: boolean;
};

export enum ReminderTypesEnum {
  ONE_TIME = 'ONE_TIME',
  EVERY_X_HOURS = 'EVERY_X_HOURS',
  EVERY_X_DAYS = 'EVERY_X_DAYS',
  EVERY_X_WEEKS = 'EVERY_X_WEEKS',
  DAILY = 'DAILY',
  AS_NEEDED = 'AS_NEEDED',
}

export type PSPReminderTimeMetricsType =
  | ReminderTypesEnum.EVERY_X_WEEKS
  | ReminderTypesEnum.EVERY_X_HOURS
  | ReminderTypesEnum.EVERY_X_DAYS;

export type Reminder = {
  id: number;
  medication: Medication;
  type: ReminderTypesEnum;
  every_x_hours: number;
  every_x_days: number;
  every_x_weeks: number;
  time_of_day?: string;
  start_date: string;
};

export type PSPReminderTemplate = {
  createdAt?: string;
  frequency: string;
  startDate?: string | null;
  startTime?: string | null;
  timeOfDay?: string | null;
  type: PSPReminderTimeMetricsType;
  uuid?: string;
};

export type PSPReminderDTO = {
  data: [
    {
      uuid: string;
      createdAt: string;
      dosageAmount: string;
      drugId: string;
      medicationInfoSource: string | null;
      totalDose: string;
      type: string;
      dateStarted: string;
      quantity: string;
      programUuid: string | null;
      reminder: PSPReminderTemplate;
    }
  ];
};

export enum TabTypesEnum {
  ABOUT_YOUR_DOSE = 'ABOUT_YOUR_DOSE',
  DRUG_INTERACTIONS = 'DRUG_INTERACTIONS',
  DETAILS = 'DETAILS',
}

export type ComplianceData = {
  date: string;
  dailyMedications: DailyMedication[];
  status: AdherenceStatusEnum;
};

export type MedicationTrackerData = {
  totalDosesTaken: number;
  totalMissedDoses: number;
  compliance: ComplianceData[];
};

export type DrugIteractionResponseItemType = {
  information: {
    severity: number;
    body: string;
  };
  interacted_with: {
    id: number;
    trade_name: string;
  };
};

export type DrugInteractions = DrugIteractionResponseItemType[];

export type MetaType = {
  id: string;
  label: string;
  value: string;
};

export type ReminderNotification = {
  id: number;
  medicationId: number;
  medicationName: string;
  time: string;
};

export type MetaData = { medicationTypes: MetaType[]; reminderTypes: MetaType[] };

export type MedicineCabinetInfo = {
  medTrackerInfo?: MedicationTrackerData;
  medications: Medication[];
  programMedications: Medication[];
  reminders: Reminder[];
  reminderTypes: MetaType[];
  medicationTypes: MetaType[];
  medicationSelectOptions: MetaType[];
  selectedReminderId?: number;
  selectedMedicationId?: number;
  reminderModalVisibility: boolean;
  pspReminderTemplate?: PSPReminderTemplate;
  pspMedicationHasBeenAdded: boolean;
  medicationModalVisibility: boolean;
  isMedicationCabinetLoading: boolean;
  notifications: ReminderNotification[];
  selectedTab: TabTypesEnum;
  drugInteractions: DrugInteractions;
  allDrugInteractions: DrugInteractions;
  drugInteractionsModalVisibility: boolean;
  medicationWizardVisibility: boolean;
  medicationWizardStep: number;
  selectedInteractionId: number;
  asNeededMedicationModalVisibility: boolean;
  previousDrugId: number | string;
};

export type InitialMedicineCabinetState = {
  medicineCabinet: MedicineCabinetInfo;
};
