import { AdditionalServicesPostData, ServiceConfig } from 'api/AdditionalServicesInterface/types';
import { ServiceTypesEnum } from 'store/reducers/additional-services/types';

type NormalizedService = ServiceConfig & {
  userDataUuid: string;
  isNew?: boolean;
  isCompleted?: boolean;
  question?: any;
  answers?: any;
};

type InitialServiceData = {
  actions: NormalizedService[];
  digitalResources: NormalizedService[];
  requests: NormalizedService[];
  resourcesNotificationIconVisibility: boolean;
};

export const normalizeAdditionalServices = (data: AdditionalServicesPostData) => {
  const initialData: InitialServiceData = {
    actions: [],
    digitalResources: [],
    requests: [],
    resourcesNotificationIconVisibility: false,
  };

  data?.configs?.forEach((serviceItem) => {
    switch (serviceItem.type) {
      case ServiceTypesEnum.RESOURCE: {
        initialData.digitalResources.push({
          ...serviceItem,
          userDataUuid: serviceItem?.userData?.[0]?.uuid,
          isNew: !!serviceItem.userData.find(({ isNew }) => isNew),
          isCompleted: !!serviceItem.userData.find(({ isSubmitted }) => isSubmitted),
          id: serviceItem.uuid,
        });
        break;
      }
      case ServiceTypesEnum.FORM_REQUEST: {
        initialData.actions.push({
          ...serviceItem,
          userDataUuid: serviceItem?.userData?.[0]?.uuid,
          isNew: !!serviceItem.userData.find(({ isNew }) => isNew),
          isCompleted: !!serviceItem.userData.find(({ isSubmitted }) => isSubmitted),
          answers: serviceItem.answers?.map((answer: any) => ({ id: answer.uuid, label: answer.text })),
          id: serviceItem.uuid,
        });
        break;
      }
      case ServiceTypesEnum.PHYSICAL_SHIPMENT:
        {
          initialData.requests.push({
            ...serviceItem,
            userDataUuid: serviceItem?.userData?.[0]?.uuid,
            isNew: !!serviceItem.userData.find(({ isNew }) => isNew),
            isCompleted: !!serviceItem.userData.find(({ isSubmitted }) => isSubmitted),
            id: serviceItem.uuid,
          });
        }
        break;
      default: {
        break;
      }
    }
  });
  return initialData;
};
