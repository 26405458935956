import React from 'react';

import { useForm } from 'react-hook-form';

import { Button } from 'components/Button';
import { DefaultInputs } from 'screens/main/Profile/sections/ContactInformation/types';

import { ButtonsWrapper, Description, Title } from '../ConfirmShipingAddress/styles';
import InputBlockComponent from '../InputBlock';
import { TEXTS } from './constants';
import { ChangeAddressWrapper, FormWrapper } from './styles';
import { ChangeShippingAddressProps } from './types';
import { getInputFieldsList } from './utils';

export const ChangeShippingAddress = ({ address, onSave, onBack, withBackButton }: ChangeShippingAddressProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DefaultInputs>();

  const inputFieldsList = getInputFieldsList(address);

  return (
    <ChangeAddressWrapper>
      <Title>{TEXTS.title}</Title>
      <Description>{TEXTS.description}</Description>
      <FormWrapper>
        {inputFieldsList.map((props) => (
          <InputBlockComponent key={props.fieldName} control={control} error={errors[props.fieldName]} {...props} />
        ))}
      </FormWrapper>
      <ButtonsWrapper>
        {withBackButton && (
          <Button
            withoutPadding
            containerStyle={{ width: 84, paddingRight: 12 }}
            variant='tertiary'
            text={TEXTS.back}
            onPress={onBack}
          />
        )}
        <Button
          withoutPadding
          containerStyle={{ width: 84 }}
          variant='secondary'
          text={TEXTS.save}
          onPress={handleSubmit(onSave)}
        />
      </ButtonsWrapper>
    </ChangeAddressWrapper>
  );
};
