import React, { useEffect, useState } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { FlatList, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { MedicineCabinetInterface } from 'api';
import { Search, Ellipse, RightArrow } from 'assets/icons';
import TextFieldWithLabel from 'components/TextFieldWithLabel';
import { useDebounce, useShowMore } from 'hooks';
import { Routes } from 'routes/main';
import { SectionContainer, SectionTitle } from 'screens/main/MedicineCabinet/sections/MedicationDetails/styles';
import { setAllDrugInteractions, setPreviousDrugId, setSelectedInteractionId } from 'store/actions/medicineCabinet';
import { getPreviousDrugId, getSelectedDrugsInteractions, getSelectedMedication } from 'store/selectors';
import { DefaultText } from 'style/common';
import { defaultTheme } from 'style/theme';
import { handlePromise } from 'utils/handlePromise';

import { medicationDetailAnalyticsActions } from '../../constants';
import { medicationDetailsAnalyticsHandler } from '../../helpers';
import Constants, { severityColors } from './constants';
import InteractionsListPlaceHolder from './InteractionsListPlaceHolder';
import { severityFilters } from './mappings';
import { ContentContainer, ItemBlock, getStyles } from './styles';

export type DrugIteractionResponseItemType = {
  information: {
    severity: number;
    body: string;
  };
  interacted_with: {
    id: number;
    trade_name: string;
  };
};

const SeverityIndicator = ({ severity }: { severity: number }) => {
  return <Ellipse style={{ marginRight: 10 }} fill={severityColors[severity - 1]} />;
};

const { VIEW_MEDICATION_INTERACTIONS_SECTION, VIEW_SEARCHED_INTERACTIONS } = medicationDetailAnalyticsActions;

export const DrugInteractions = () => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [filteredItems, setFilteredItems] = useState<DrugIteractionResponseItemType[]>([]);
  const dispatch = useDispatch();
  const selectedMedication = useSelector(getSelectedMedication);
  const drugInteractions = useSelector(getSelectedDrugsInteractions);
  const previousDrugId = useSelector(getPreviousDrugId);
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigation = useNavigation<DrawerNavigationHelpers>();
  const debouncedSearchTerm = useDebounce(searchValue, 300);
  const isFocused = useIsFocused();
  const { itemsToBeRendered, showMoreJSX } = useShowMore({
    items: filteredItems,
    itemsLimit: 25,
    containerStyle: styles.showMoreStyle,
  });

  useEffect(() => {
    if (isFocused) {
      medicationDetailsAnalyticsHandler(VIEW_MEDICATION_INTERACTIONS_SECTION);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!selectedMedication?.drugId) return;

    const refetchInteractions = async () => {
      if (previousDrugId !== selectedMedication.drugId) {
        const [interactionsResponse, _] = await handlePromise(() =>
          MedicineCabinetInterface.getAllDrugInteractionsByDrugId(selectedMedication.drugId)
        );
        dispatch(setAllDrugInteractions(interactionsResponse?.data?.interactions || []));
        dispatch(setPreviousDrugId(selectedMedication.drugId));
      }
      setLoading(false);
    };

    refetchInteractions();
  }, [selectedMedication?.drugId, dispatch, previousDrugId]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const itemsToShow = drugInteractions.filter(({ interacted_with: { trade_name } }) => {
        return trade_name.toLocaleLowerCase().includes(debouncedSearchTerm.toLocaleLowerCase());
      });
      setFilteredItems(itemsToShow);
      medicationDetailsAnalyticsHandler(VIEW_SEARCHED_INTERACTIONS);
    } else setFilteredItems(drugInteractions);
  }, [debouncedSearchTerm, drugInteractions]);

  const navigateToDetailsOfInteraction = (interactionId: number) => {
    dispatch(setSelectedInteractionId(interactionId));
    navigation.navigate(Routes.INTERACTION_DETAILS, {
      medicationId: selectedMedication?.id,
      interactionId,
      drugId: selectedMedication?.drugId,
    });
  };

  return (
    <SectionContainer>
      <SectionTitle>Drug Interactions</SectionTitle>
      <ContentContainer>
        <View style={styles.severityFiltersWrapper}>
          {severityFilters.map(({ id, text, severity }) => (
            <View key={id} style={styles.severityFilterItem}>
              <SeverityIndicator severity={severity} />
              <DefaultText style={styles.severityText}>{text}</DefaultText>
            </View>
          ))}
        </View>
        <View style={styles.searchFormWrapper}>
          <View style={styles.searchIcon}>
            <Search stroke={defaultTheme.colors.gray3} />
          </View>
          <TextFieldWithLabel
            labelText=''
            style={styles.textField}
            onChangeText={setSearchValue}
            placeholder='Search'
            renderLabel={false}
          />
        </View>
        {loading ? (
          <InteractionsListPlaceHolder />
        ) : (
          <FlatList // used just for ListEmptyComponent & ListFooterComponent
            data={itemsToBeRendered}
            keyExtractor={(item) => item.interacted_with.id.toString()}
            renderItem={({ item }) => (
              <ItemBlock onPress={() => navigateToDetailsOfInteraction(item.interacted_with?.id)}>
                <View style={styles.severityInfoWrapper}>
                  <SeverityIndicator severity={item?.information?.severity} />
                  <DefaultText style={styles.itemStyle}>{item?.interacted_with?.trade_name}</DefaultText>
                </View>
                <RightArrow width={16} height={16} stroke={defaultTheme.colors.gray2} />
              </ItemBlock>
            )}
            ListEmptyComponent={<DefaultText style={styles.noResultText}>{Constants.NO_SEARCH_RESULT}</DefaultText>}
            ListFooterComponent={showMoreJSX}
          />
        )}
      </ContentContainer>
    </SectionContainer>
  );
};
