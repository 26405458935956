import { StyleSheet } from 'react-native';

export const getStyles = (isMobile?: boolean, logoWidth?: number) =>
  StyleSheet.create({
    drawerLogo: {
      height: 40,
      marginVertical: 16,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft: 16,
    },
    imageWrapper: { height: 40, width: logoWidth },
    listArrow: { marginLeft: 10 },
    popupContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,.3)',
    },
    popupWrapper: {
      borderRadius: 20,
      padding: 24,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
      backgroundColor: 'white',
      width: 288,
      position: 'absolute',
      top: 100,
      ...(isMobile ? { right: 12 } : { left: 12 }),
    },
    triangleWrapper: {
      position: 'absolute',
      top: 75,
      ...(isMobile ? { right: 135 } : { left: 135 }),
      transform: [{ rotate: '-90deg' }],
    },
    popupItemWrapper: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  });
