import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';
import { isIOSDevice } from 'utils/helpers';

export const getTemplateImageStyles = (isMobile?: boolean) =>
  StyleSheet.create({
    templateBoxWrapper: {
      position: 'absolute',
      backgroundColor: 'transparent',
      bottom: 0,
      right: isMobile ? (isIOSDevice ? -70 : -80) : 65,
      width: isMobile ? '60%' : '20%',
      height: isMobile ? '70%' : '90%',
      zIndex: 3,
    },
    templateImageBox: {
      width: '100%',
      height: '100%',
    },
  });

export const styles = StyleSheet.create({
  loaderWrapperStyle: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultTheme.colors.gray3,
    height: 100,
    marginBottom: 20,
    borderRadius: 16,
  },
  tilesWrapper: { flexDirection: 'column-reverse' },
});
