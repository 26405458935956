import React, { useEffect, useRef, useState } from 'react';

import { View } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';

import InputLabel from 'components/InputLabel';
import { HOUR_OPTIONS, MINUTE_OPTIONS } from 'components/TimePicker/constants';
import { MeridiemPicker } from 'components/TimePicker/MeridiemPicker/MeridiemPicker';
import { StandardText } from 'style/common';

import { extractMeridiemValuesFromTime24, fromMeridiemToTime24Format } from './helpers';
import { useTimePickerStyles, WebPickerContainer } from './styles';
import { TimePickerProps } from './types';

export const TimePicker = ({
  labelText,
  showInfo = false,
  hoursTestID,
  minutesTestID,
  meridiemTestID,
  value,
  onChange,
}: TimePickerProps) => {
  const { hourValue, minuteValue, meridiemValue } = extractMeridiemValuesFromTime24(value || new Date());
  const [selectedHourValue, setSelectedHourValue] = useState(hourValue);
  const [selectedMinuteValue, setSelectedMinuteValue] = useState(minuteValue);
  const [meridiemSelectedValue, setMeridiemSelectedValue] = useState(meridiemValue);

  const onChangeRef = useRef(onChange);

  useEffect(() => {
    setSelectedHourValue(hourValue);
    setSelectedMinuteValue(minuteValue);
    setMeridiemSelectedValue(meridiemValue);
  }, [hourValue, minuteValue, meridiemValue]);

  useEffect(
    () =>
      onChangeRef.current(
        fromMeridiemToTime24Format(`${selectedHourValue}:${selectedMinuteValue} ${meridiemSelectedValue}`)
      ),
    [selectedHourValue, selectedMinuteValue, meridiemSelectedValue]
  );

  const styles = useTimePickerStyles();

  return (
    <>
      <InputLabel labelText={labelText} showInfo={showInfo} />
      <View style={styles.timePickerWrapper}>
        <View style={[styles.formItemWrapper, styles.timeSelectStyles]}>
          <WebPickerContainer data-testid={hoursTestID}>
            <RNPickerSelect
              placeholder={{}}
              items={HOUR_OPTIONS}
              value={selectedHourValue}
              onValueChange={setSelectedHourValue}
            />
          </WebPickerContainer>
          <StandardText style={styles.colonMark}>:</StandardText>
          <WebPickerContainer data-testid={minutesTestID}>
            <RNPickerSelect
              placeholder={{}}
              items={MINUTE_OPTIONS}
              value={selectedMinuteValue}
              onValueChange={setSelectedMinuteValue}
            />
          </WebPickerContainer>
        </View>
        <View style={styles.spacer8} />
        <MeridiemPicker
          testID={meridiemTestID}
          value={meridiemSelectedValue}
          onMeridiemChange={setMeridiemSelectedValue}
        />
      </View>
    </>
  );
};

export default TimePicker;
