import React from 'react';

import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { AdditionalServicesInterface } from 'api';
import { RightSideActions } from 'components/RightSideActions';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { setViewedFinancialAssistance } from 'store/actions/additionalServices';
import { getSelectedProgram } from 'store/selectors';
import { webStyles } from 'style/common';
import { defaultTheme } from 'style/theme';

import {
  ActionLabelWrapper,
  ActionText,
  NonTouchableActionItemContainer,
  TouchableActionItemContainer,
  ActionIconWrapper,
  actionLabelStyle,
} from './styles';
import { ServiceActionItemProps, CurrentActionItemContainerProps } from './types';

export const ServiceActionItem = ({
  IconComponent,
  onAction,
  actionLabel,
  done,
  isNew,
  id = 0,
  userDataUuid,
  type,
  stateType,
  triggerableAction,
}: ServiceActionItemProps) => {
  const selectedProgram = useSelector(getSelectedProgram);
  const iconColor = done
    ? defaultTheme.colors.gray2
    : selectedProgram?.white_labeling_conf?.secondaryColor || defaultTheme.colors.secondary;

  const { isMobile } = useDeviceInfoContext();

  const dispatch = useDispatch();

  const actionHandler = () => {
    if (isNew && userDataUuid && stateType) {
      dispatch(setViewedFinancialAssistance({ id, type: stateType }));
      AdditionalServicesInterface.put({ type, isNew: false, userDataUuid });
    }
    if (!done || triggerableAction) {
      onAction?.();
    }
  };

  const CurrentActionItemContainer =
    done && !triggerableAction
      ? ({ children }: CurrentActionItemContainerProps) => (
          <NonTouchableActionItemContainer>{children}</NonTouchableActionItemContainer>
        )
      : ({ children }: CurrentActionItemContainerProps) => (
          <TouchableActionItemContainer style={webStyles.button} onPress={actionHandler}>
            {children}
          </TouchableActionItemContainer>
        );

  return (
    <CurrentActionItemContainer>
      <ActionLabelWrapper>
        <View style={actionLabelStyle}>
          <ActionIconWrapper>
            <IconComponent stroke={iconColor} />
          </ActionIconWrapper>
          <ActionText done={done}>{actionLabel}</ActionText>
        </View>
        <RightSideActions isNew={isNew} isDone={done} isMobile={isMobile} viewAgainIsShown={triggerableAction} />
      </ActionLabelWrapper>
    </CurrentActionItemContainer>
  );
};

export default ServiceActionItem;
