import { StyleSheet } from 'react-native';
import { useTheme } from 'styled-components/native';

import { applyBreakpoints } from 'style/utils';

export const useCardItemStyles = () => {
  const theme = useTheme();

  return StyleSheet.create({
    container: applyBreakpoints(theme, {
      md: {
        flexDirection: 'column',
        alignItems: 'center',
        paddingVertical: 28,
        paddingHorizontal: 0,
      },
    }),
  });
};
