import React from 'react';

import { RenewalText, RenewalDateStyle, RenewalDescription, RenewalCard } from '../../styles';

export type InfoCardProps = {
  headerText: string;
  description: string;
  date?: string;
};

export const InfoCard = ({ headerText, description, date }: InfoCardProps) => {
  return (
    <RenewalCard>
      <RenewalText>{headerText}</RenewalText>
      <RenewalDescription>{description}</RenewalDescription>
      <RenewalDateStyle>{date}</RenewalDateStyle>
    </RenewalCard>
  );
};

export default InfoCard;
