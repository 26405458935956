import { View } from 'react-native';

export type SpacerProps = {
  size: number;
  orientation?: 'vertical' | 'horizontal';
};

export const Spacer = ({ size, orientation = 'vertical' }: SpacerProps) => (
  <View
    style={{
      width: orientation === 'horizontal' ? size : 'auto',
      height: orientation === 'vertical' ? size : 'auto',
    }}
  />
);
