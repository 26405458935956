export const TEXTS = {
  TITLE: 'Are you sure you want to delete this {0}?',
  DESCRIPTION: 'This {0} will be permanently deleted from your account.',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
};

// Analytics Constants
const DELETE_REMINDER_SUCCESS = 'DELETE_REMINDER_SUCCESS';
const DELETE_MEDICATION_SUCCESS = 'DELETE_MEDICATION_SUCCESS';

export const deleteModalAnalyticsConstants = {
  DELETE_REMINDER_SUCCESS,
  DELETE_MEDICATION_SUCCESS,
};
