import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const SvgComponent = (props: SvgProps) => (
  <Svg width={18} height={17} fill='none' {...props}>
    <Path
      d='M8 13.833A6.333 6.333 0 1 0 8 1.166a6.333 6.333 0 0 0 0 12.667ZM16.333 15.834l-3.333-4'
      stroke={defaultTheme.colors.gray3}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default SvgComponent;
