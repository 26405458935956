import React from 'react';

import { View } from 'react-native';

import { ErrorBox } from 'components/ErrorBox';
import { InfoBlockLabel } from 'style/common';

import { renderTextField } from './renderTextField';
import { ProfileInformationFieldProps } from './types';

export const ProfileInformationField = ({
  label,
  children,
  isNested = false,
  isEditMode = false,
  error,
  color,
  onChangeText,
}: ProfileInformationFieldProps) => {
  return (
    <>
      <View>{label && <InfoBlockLabel>{label}</InfoBlockLabel>}</View>
      {renderTextField({
        isEditMode,
        isNested,
        color,
        onChangeText: onChangeText as (text: string) => void,
        children,
      })}
      {error && <ErrorBox message={error.message} />}
    </>
  );
};
