import React from 'react';

import Svg, { SvgProps, Path } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

type EditIconProps = {
  d?: string;
  size?: 'small' | 'medium';
};

const dSizes: Record<Required<EditIconProps>['size'], string> = {
  small:
    'M11.6708 1.58492C11.8563 1.39948 12.0764 1.25238 12.3187 1.15201C12.561 1.05165 12.8207 1 13.0829 1C13.3452 1 13.6049 1.05165 13.8472 1.15201C14.0894 1.25238 14.3096 1.39948 14.495 1.58492C14.6805 1.77036 14.8276 1.99051 14.9279 2.2328C15.0283 2.47509 15.08 2.73478 15.08 2.99703C15.08 3.25928 15.0283 3.51897 14.9279 3.76126C14.8276 4.00355 14.6805 4.2237 14.495 4.40914L4.96327 13.9409L1.07996 15L2.13904 11.1167L11.6708 1.58492Z',
  medium: 'M17.386 2.377a2.995 2.995 0 114.237 4.237L7.325 20.91 1.5 22.5l1.589-5.825L17.386 2.377z',
};
const sizes: Record<Required<EditIconProps>['size'], number> = {
  small: 16,
  medium: 24,
};

const Edit = ({ size = 'medium', ...props }: EditIconProps & SvgProps) => (
  <Svg width={sizes[size]} height={sizes[size]} fill='none' {...props}>
    <Path
      d={props.d || dSizes[size]}
      stroke={props.stroke || defaultTheme.colors.gray1}
      strokeWidth={props.strokeWidth || 2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export default Edit;
