import { useEffect } from 'react';

import componentsRefs from 'utils/componentsRefs';

export const useRefsClear = () => {
  useEffect(() => {
    return () => componentsRefs.clear(); // clear whole map from componentsRefs class
  }, []);
};

export default useRefsClear;
