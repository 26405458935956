import React from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import { View, LayoutChangeEvent } from 'react-native';
import { useSelector } from 'react-redux';

import { DrawerStackParamList } from '@types';
import { Layout } from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import NotificationPopup from 'components/NotificationPopup';
import { useScroll } from 'hooks';
import benefitsLocalization from 'localization/screens/Benefits';
import { Routes } from 'routes/main';
import { BenefitsInvestigationCard } from 'screens/main/Benefits/components/BenefitsInvestigationCard';
import { getExplanationModalType } from 'screens/main/Benefits/components/BenefitsInvestigationCard/utils';
import { getAppDataIsLoading } from 'store/selectors';
import { HeaderText, HeaderWrapper, StandardText } from 'style/common';
import { appDateFormatter } from 'utils/date/appDateFormatter';

import { MilestoneTracker } from '../Home/components';
import { HistoryItemsBlock } from './components/HistoryItemsBlock';
import { notificationContent } from './constants';
import { useGetBenefitInvestigations } from './hooks/useGetBenefitInvestigations';
import { useBenefitsPageStyle } from './styles';

type BenefitsScreenRouteProp = RouteProp<DrawerStackParamList, Routes.BENEFITS>;

const { title, description, header } = benefitsLocalization;

export const Benefits = () => {
  const styles = useBenefitsPageStyle();
  const isAppDataLoading = useSelector(getAppDataIsLoading);

  const route = useRoute<BenefitsScreenRouteProp>();

  const {
    benefitsInvestigation,
    benefitTracker,
    isLoading: isBenefitsLoading,
  } = useGetBenefitInvestigations(route.params?.trackerId);

  const navigation = useNavigation<DrawerNavigationHelpers>();

  const isLoading = isAppDataLoading || isBenefitsLoading;

  const { scrollViewRef, setComponentViewY } = useScroll({
    shouldScroll: !!route.params?.trackerId && !isLoading,
  });

  const onHistoryItemsLayout = (e: LayoutChangeEvent) => {
    const { layout } = e.nativeEvent;
    setComponentViewY(layout.y + layout.height / 2);
  };

  if (isLoading) return <FullScreenLoader />;

  const lastRunOnDate = appDateFormatter(
    benefitTracker?.milestones[benefitTracker?.milestones.length - 1]?.userData?.updatedAt,
    'MMMM dd, yyyy',
    ''
  );

  return (
    <>
      <Layout title={title} description={description} navigation={navigation} scrollViewRef={scrollViewRef}>
        <HeaderWrapper>
          <HeaderText>{header}</HeaderText>
          {!!lastRunOnDate && <StandardText style={styles.lastRunText}>Last run on {lastRunOnDate}</StandardText>}
        </HeaderWrapper>
        {!!benefitsInvestigation && benefitsInvestigation?.completedAt ? (
          <BenefitsInvestigationCard
            benefitInvestigation={benefitsInvestigation}
            investigationType={getExplanationModalType(benefitsInvestigation)}
          />
        ) : (
          <View style={{ marginTop: 32 }}>
            <MilestoneTracker trackerData={benefitTracker} isBenefitPage />
          </View>
        )}
        {!!benefitTracker?.milestones.length && (
          <>
            <HeaderText onLayout={onHistoryItemsLayout} style={styles.historyTitle}>
              History
            </HeaderText>
            <HistoryItemsBlock trackerData={benefitTracker} />
          </>
        )}
      </Layout>
      <NotificationPopup {...notificationContent} />
    </>
  );
};

export default Benefits;
