import React from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';

import { IGNORED_STEP_CONTENT } from 'components/Tourguide/constants';
import { useTourGuideSteps, useTutorialSteps, useWebSteps } from 'components/Tourguide/steps';
import { TourContent } from 'components/Tourguide/TourContent';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { setWebTourState } from 'store/actions';
import { getWebTourState } from 'store/selectors';
import { useIsBreakpoint } from 'style/hooks';
import { TUTORIAL_MODAL_MAX_WIDTH } from 'style/sizes';
import { isSafari, isWebNativeIOSDevice } from 'utils/helpers';

import { WebTourGuideProps } from './types';
import { useDelayTutorial } from './useDelayTutorial';

const UPDATE_DELAY_MS = isSafari && isWebNativeIOSDevice ? 800 : 1200;

export const WebTourGuide = ({ currentStep, goToNextStep, closeModal }: WebTourGuideProps) => {
  const dispatch = useDispatch();
  const webTourState = useSelector(getWebTourState);
  const { width, isTablet } = useDeviceInfoContext();
  const delayedTutorialFlag = useDelayTutorial();
  const isXl = useIsBreakpoint('xl');

  const steps = useWebSteps();
  const tourGuideSteps = useTourGuideSteps();
  const { support } = useTutorialSteps();

  const isLastStep = currentStep === tourGuideSteps.length - 1;
  const isSmallScreen = isXl || isTablet;

  const handleStop = () => {
    closeModal();
    dispatch(setWebTourState(false));
  };

  const handleNext = (goTo: (step: number) => void, current: number) => {
    goTo(current + 1);
    if (isLastStep) {
      closeModal(true);
      dispatch(setWebTourState(false));
      return;
    }
    goToNextStep();
  };

  const disableBody = (target: HTMLElement | Element) => disableBodyScroll(target);

  const enableBody = (target: HTMLElement | Element) => enableBodyScroll(target);

  // NOTE: currentStep goes off limits when user resizes the screen to isSmallScreen, in order to show the correct content we're forcing the currentStep to be the last one
  const step = currentStep >= tourGuideSteps.length ? currentStep - 1 : currentStep;
  const tourContent = tourGuideSteps[step] || tourGuideSteps[0];

  return (
    <Tour
      onRequestClose={handleStop}
      steps={steps}
      isOpen={webTourState}
      rounded={8}
      maskSpace={10}
      // we're forcing the update of the tour position when the width and delayedTutorialFlag changes
      update={`${tourContent.title}${width}${delayedTutorialFlag}`}
      updateDelay={UPDATE_DELAY_MS}
      disableInteraction
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      CustomHelper={({ gotoStep, close, current, content }) => {
        // NOTE: logic to updating steps manually created to cover the need of https://zealic.atlassian.net/browse/MGO-3301
        // we're skipping the first step on small screens
        if (content === IGNORED_STEP_CONTENT && isSmallScreen) {
          goToNextStep();
          gotoStep(current + 1);
        }

        // we're going back to the previous step if the user is on the web's first step and the title is the same
        if (current === 1 && tourContent.title === support?.title) gotoStep(current - 1);

        return (
          <View style={{ maxWidth: TUTORIAL_MODAL_MAX_WIDTH }}>
            <TourContent
              currentStep={currentStep}
              title={tourContent.title}
              description={tourContent.description}
              handleNext={() => handleNext(gotoStep, current)}
              handlePrevious={close}
            />
          </View>
        );
      }}
    />
  );
};

export default WebTourGuide;
