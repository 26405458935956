import React, { useEffect, useRef, useState } from 'react';

import { ContactTypes } from 'expo-contacts';
import { View } from 'react-native';
import ToastContainer, { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { getAnalyticEventLabel } from 'analytics/helpers';
import { Button, ConfirmModal } from 'components';
import { AddPhoneNumberModal } from 'components/AddPhoneNumberModal';
import { supportAnalyticsHandler } from 'components/Support/helpers';
import { HomeClosableCard } from 'screens/main/Home/components/HomeClosableCard/HomeClosableCard';
import {
  specialtyPharmacyAnalyticsActions,
  specialtyPharmacyCardText,
} from 'screens/main/Home/components/SpecialtyPharmacyCard/constants';
import { specialtyPharmacyAnalyticsHandler } from 'screens/main/Home/components/SpecialtyPharmacyCard/helpers';
import { useSendSpecialtyPharmacySMS } from 'screens/main/Home/components/SpecialtyPharmacyCard/hooks/useSendSpecialtyPharmacySMS';
import { useSpecialtyPharmacy } from 'screens/main/Home/components/SpecialtyPharmacyCard/hooks/useSpecialtyPharmacy';
import { useSpecialtyPharmacyCardStyles } from 'screens/main/Home/components/SpecialtyPharmacyCard/styles';
import { saveUserContactInfo } from 'store/actions';
import { getUserPhoneNumber } from 'store/selectors';
import { DefaultText } from 'style/common';
import { addToContacts } from 'utils/contacts';
import { addDashesForPhoneNumber } from 'utils/convertString';
import { isNativeDevice } from 'utils/helpers';

import { SpecialtyPharmacyModal } from '../SpecialtyPharmacyModal';

const {
  TITLE,
  DESCRIPTION,
  CONTACT_NUMBER_LABEL,
  ADD_TO_CONTACT_BUTTON_TEXT,
  SEND_NUMBER_CONFIRM_MODAL_TITLE,
  SEND_NUMBER_CONFIRM_MODAL_DESCRIPTION,
  SEND_CONTACT_AFTER_USER_ADD_NUMBER_TITLE,
  SEND_CONTACT_AFTER_USER_ADD_NUMBER_DESCRIPTION,
  ADD_PHONE_NUMBER_FOR_SPECIALTY_PHARMACY_DESCRIPTION,
} = specialtyPharmacyCardText;
const { ADD_TO_CONTACT_SPECIALTY_PHARMACY, CONTACT_CARD_VIEWED } = specialtyPharmacyAnalyticsActions;

const SpecialtyPharmacyCard = () => {
  const userPhoneNumber = useSelector(getUserPhoneNumber);
  const [confirmSendSMSModalVisible, setConfirmSendSMSModalVisible] = useState(false);
  const [addPhoneNumberModalVisible, setAddPhoneNumberModalVisible] = useState(false);
  const [confirmSendingContactModalVisible, setConfirmSendingContactModalVisible] = useState(false);
  const toastRef = useRef<ToastContainer>(null);
  const dispatch = useDispatch();

  const styles = useSpecialtyPharmacyCardStyles();

  const toast = useToast();
  const {
    specialtyPharmacy,
    hideSpecialtyPharmacyModal,
    isLoading: isGetSpecialtyPharmacyLoading,
  } = useSpecialtyPharmacy();

  const { sendSMS, isLoading: isSendSMSLoading } = useSendSpecialtyPharmacySMS(specialtyPharmacy?.uuid || '');

  const isVisible = !isGetSpecialtyPharmacyLoading && !specialtyPharmacy?.isAllowShipmentTracking;
  const firstVisibleMounted = useRef(false);

  useEffect(() => {
    if (firstVisibleMounted.current) return;
    if (isVisible) {
      supportAnalyticsHandler(CONTACT_CARD_VIEWED, getAnalyticEventLabel());
      firstVisibleMounted.current = true;
    }
  }, [isVisible]);

  if (!specialtyPharmacy?.uuid) return null;

  const addToContactsHandler = () => {
    specialtyPharmacyAnalyticsHandler(ADD_TO_CONTACT_SPECIALTY_PHARMACY, getAnalyticEventLabel());

    if (isNativeDevice)
      return addToContacts({
        id: specialtyPharmacy.uuid,
        name: specialtyPharmacy.name,
        contactType: ContactTypes.Company,
        address: {
          id: `${specialtyPharmacy.uuid} | ${specialtyPharmacy.addressLine1} ${specialtyPharmacy.addressLine2}`,
          label: `${specialtyPharmacy.name} Address`,
          city: specialtyPharmacy.city,
          region: specialtyPharmacy.state,
          street: `${specialtyPharmacy.addressLine1} ${specialtyPharmacy.addressLine2}`,
          postalCode: specialtyPharmacy.zipCode,
        },
        number: {
          id: `${specialtyPharmacy.uuid} | ${specialtyPharmacy.phoneNumber}`,
          label: `${specialtyPharmacy.name} Number`,
          number: specialtyPharmacy.phoneNumber,
        },
      }).then((res) => res && toast.show(res.message, { type: res.type }));

    userPhoneNumber ? setConfirmSendSMSModalVisible(true) : setAddPhoneNumberModalVisible(true);
  };

  const onSavePhoneNumber = async () => {
    await dispatch(saveUserContactInfo());
    await sendSMS();
    setConfirmSendingContactModalVisible(true);
  };

  const onConfirm = async () => {
    await sendSMS();
    setConfirmSendSMSModalVisible(false);
  };

  const shouldShowPharmacyModal = !(
    specialtyPharmacy.isShown &&
    isGetSpecialtyPharmacyLoading &&
    specialtyPharmacy.isAllowShipmentTracking
  );

  return (
    <>
      <HomeClosableCard visible={isVisible} showCloseIcon={false}>
        <View style={styles.wrapper}>
          <View style={styles.baseInfoWrapper}>
            <DefaultText style={styles.title}>{TITLE}</DefaultText>
            <DefaultText style={styles.description}>{DESCRIPTION}</DefaultText>
          </View>
          <View style={styles.contactDataWrapper}>
            <DefaultText style={styles.contactName}>{specialtyPharmacy.name}</DefaultText>
            <DefaultText style={styles.contactNumberLabel}>{CONTACT_NUMBER_LABEL}</DefaultText>
            <DefaultText style={styles.contactNumber}>
              {addDashesForPhoneNumber(specialtyPharmacy.phoneNumber)}
            </DefaultText>
          </View>
          <Button
            disabled={isSendSMSLoading}
            style={styles.addToContactButton}
            onPress={addToContactsHandler}
            variant='tertiary'
            text={ADD_TO_CONTACT_BUTTON_TEXT}
          />
        </View>
      </HomeClosableCard>
      <SpecialtyPharmacyModal visible={shouldShowPharmacyModal} onClose={hideSpecialtyPharmacyModal} />
      <ConfirmModal
        onConfirm={onConfirm}
        onCancel={() => setConfirmSendSMSModalVisible(false)}
        visible={confirmSendSMSModalVisible}
        setVisible={setConfirmSendSMSModalVisible}
        title={SEND_NUMBER_CONFIRM_MODAL_TITLE}
        description={SEND_NUMBER_CONFIRM_MODAL_DESCRIPTION}
      />
      <AddPhoneNumberModal
        toastRef={toastRef}
        setVisible={setAddPhoneNumberModalVisible}
        visible={addPhoneNumberModalVisible}
        storeButtonAction={onSavePhoneNumber}
        descriptionText={ADD_PHONE_NUMBER_FOR_SPECIALTY_PHARMACY_DESCRIPTION}
      />
      <ConfirmModal
        buttons={[{ text: 'Okay', variant: 'secondary' }, undefined]}
        visible={confirmSendingContactModalVisible}
        setVisible={setConfirmSendingContactModalVisible}
        onConfirm={() => setConfirmSendingContactModalVisible(false)}
        title={SEND_CONTACT_AFTER_USER_ADD_NUMBER_TITLE}
        description={SEND_CONTACT_AFTER_USER_ADD_NUMBER_DESCRIPTION}
      />
    </>
  );
};

export default SpecialtyPharmacyCard;
