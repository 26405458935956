import React, { useState, useEffect } from 'react';

import AutoCompleteInput from 'components/AutoCompleteInput';
import { useSearchDrugs } from 'screens/main/MedicineCabinet/components/MedicationWizard/Steps/components/useSearchDrugs';

export type DrugAutoCompleteProps = {
  onSelectedMedicationItem: (id: number | string) => void;
  initialValue?: string;
  disabled?: boolean;
  testID?: string;
  selectTestID?: string;
};

export const DrugAutoComplete = ({
  onSelectedMedicationItem,
  initialValue,
  disabled,
  testID,
  selectTestID,
}: DrugAutoCompleteProps) => {
  const [query, setQuery] = useState(initialValue || '');
  const { drugs, isLoading } = useSearchDrugs(query);

  useEffect(() => {
    const enteredDrug = drugs.find(({ text }) => text === query);

    onSelectedMedicationItem(enteredDrug?.id || '');
  }, [query, drugs, onSelectedMedicationItem]);

  return (
    <AutoCompleteInput
      testID={testID}
      selectTestID={selectTestID}
      data={drugs}
      value={query}
      onChangeText={setQuery}
      labelText='Medication Name'
      disabled={disabled}
      loading={isLoading}
    />
  );
};
