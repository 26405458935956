import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Ellipse } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Powdering = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 54.68 111.06' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          fill={props?.color || defaultTheme.colors.gray3}
          d='M1.56 88.07S7.37 82 27.82 82s25.3 6.06 25.3 6.06V100s-5.51 8.9-25.3 8.9-26.26-8.69-26.26-8.69ZM27.9.07C29.1.06 8.84-1.1 9.64 6.18c0 .61.81 2.83 2.63 3-.2 1.82 0 8.29 0 8.29s1 1.13 6.45 2v5.66s1.24 3 8.62 2.63a2.6 2.6 0 0 0 .56-.07 2.52 2.52 0 0 0 .56.07c7.38.4 8.62-2.63 8.62-2.63s.17-3.84 0-5.66c4.84-.68 6.44-2 6.44-2s.2-6.47 0-8.29c1.82-.2 2.63-2.42 2.63-3C47-1.1 26.69.08 27.9.07ZM41.37 54.93a13.55 13.55 0 0 1-13.55 13.56c-7.49 0-13.56-5.18-13.56-13.56.61-13.22 13.56-22.1 13.56-22.1s13.55 9.41 13.55 22.1Z'
        />
        <Ellipse fillOpacity={0.2} cx={27.78} cy={3.3} rx={14.86} ry={3.19} />
        <Ellipse
          cx={31.62}
          cy={45.07}
          rx={3.23}
          ry={3.96}
          transform='rotate(-26.99 31.622 45.07)'
          fillOpacity={0.5}
          fill='#fff'
        />
        <Path
          d='M40 24.1v-5.13c-1.87.67-12.31 1.41-12.31 1.41s-8.89-.32-12.95-1.77v5.49S0 27.06 0 41.74v58.41s2.18 10.91 27 10.91h.71c24.81 0 27-10.91 27-10.91V41.74C54.68 27.06 40 24.1 40 24.1Z'
          fill='#e2e2e2'
          fillOpacity={0.4}
        />
        <Path
          fillOpacity={0.2}
          d='M27.82 91.62c12.42 0 20.61-1.48 25.3-2.71v-.84S48.27 82 27.82 82 1.56 88.07 1.56 88.07v.47c2.78 1.03 10.3 3.08 26.26 3.08Z'
        />
        <Path
          d='M12.27 9.21a69.79 69.79 0 0 0 15.43 2 75.76 75.76 0 0 0 15.82-2v8.29s-2 1.45-6.44 2c-4.84.32-8.44 1.59-18.36 0a17.68 17.68 0 0 1-6.45-2c-.02-.68 0-8.29 0-8.29Z'
          fillOpacity={0.2}
        />
      </G>
    </G>
  </Svg>
);

export default Powdering;
