import * as ActionTypes from 'store/action-types';
import { CustomServiceType } from 'store/reducers/additional-services/types';
import { ConsentModalTypeEnum } from 'store/reducers/user-info/types/consent';

export type SetConsentHasBeenGiven = (payload: {
  id: string;
  groupType: ConsentModalTypeEnum;
  isGiven: boolean;
  customServiceType?: CustomServiceType;
}) => {
  type: typeof ActionTypes.SET_CONSENT_HAS_BEEN_GIVEN;
  payload: typeof payload;
};

export const setConsentHasBeenGiven: SetConsentHasBeenGiven = (payload) => ({
  type: ActionTypes.SET_CONSENT_HAS_BEEN_GIVEN,
  payload,
});
