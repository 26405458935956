import React from 'react';

import Svg, { SvgProps, Defs, G, Path, Circle } from 'react-native-svg';

import { defaultTheme } from 'style/theme';

const Pad = (props: SvgProps) => (
  <Svg width={40} height={40} viewBox='0 0 114.21 111.83' {...props}>
    <Defs />
    <G id='Layer_2' data-name='Layer 2'>
      <G id='Layer_1-2' data-name='Layer 1'>
        <Path
          d='M56.87 103.85H18.43c-7.72 0-10.27-2.47-10.27-10V18c0-7.55 2.53-10 10.24-10h77.37c7.73 0 10.28 2.47 10.28 10v75.84c0 7.56-2.53 10-10.24 10q-19.47.03-38.94.01Z'
          fill={props?.color || defaultTheme.colors.gray3}
        />
        <G fillOpacity={0.2}>
          <Path
            d='M56.83 111.83H12c-9 0-12-2.89-12-11.66V11.7C0 2.89 3 0 12 0h90.25c9 0 12 2.88 12 11.65v88.47c0 8.81-3 11.69-12 11.7q-22.71.03-45.42.01Z'
            fill='#fafafa'
          />
        </G>
        <Path
          fill='#fff'
          fillOpacity={0.4}
          d='M105.34 5.31H65.91a3.77 3.77 0 0 0-2.73 6.37s27 11.34 39.43 41.43c1.56 2 6.5.8 6.5-2.6V9.08a3.77 3.77 0 0 0-3.77-3.77Z'
        />
        <Path
          d='M16.29 97.93h28.08a2.68 2.68 0 0 0 1.94-4.53 57.89 57.89 0 0 1-28.07-29.5c-1.12-1.46-4.63-.57-4.63 1.85v29.5a2.68 2.68 0 0 0 2.68 2.68Z'
          fillOpacity={0.2}
        />
        <Circle fill='#fff' fillOpacity={0.4} cx={53.15} cy={9.27} r={3.96} />
      </G>
    </G>
  </Svg>
);

export default Pad;
