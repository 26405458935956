import React, { useEffect } from 'react';

import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types';
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { DrawerStackParamList } from '@types';
import { getAnalyticEventLabel } from 'analytics/helpers';
import Layout from 'components/Layout';
import { FullScreenLoader } from 'components/Loaders';
import { useAdditionalServiceNotification } from 'components/MenuItem/useAdditionalServiceNotification';
import { useFirstEffect } from 'hooks/useFirstEffect';
import { Routes } from 'routes/main';
import { additionalServicesAnalyticsActions, description, title } from 'screens/main/AdditionalServices/constants';
import { additionalServicesAnalyticsHandler } from 'screens/main/AdditionalServices/helpers';
import { getAppDataIsLoading } from 'store/selectors';
import { HeaderText } from 'style/common';

import { Services } from './components';
import { styles } from './styles';

type AdditionalServicesScreenRouteProp = RouteProp<DrawerStackParamList, Routes.RESOURCES>;

const { VIEW_ADDITIONAL_SERVICE_PAGE } = additionalServicesAnalyticsActions;

export const AdditionalServices = () => {
  const dataIsLoading = useSelector(getAppDataIsLoading);

  const { hideNotifications } = useAdditionalServiceNotification();

  const navigation = useNavigation<DrawerNavigationHelpers>();
  const route = useRoute<AdditionalServicesScreenRouteProp>();
  const isFocused = useIsFocused();

  const initialScheduleModalState = isFocused && Boolean(route.params?.openInjections);
  const initialVideoModalState = isFocused && Boolean(route.params?.openVideo);

  useEffect(() => hideNotifications?.(), [hideNotifications]);

  useFirstEffect(() => {
    additionalServicesAnalyticsHandler(VIEW_ADDITIONAL_SERVICE_PAGE, getAnalyticEventLabel());
  }, []);

  if (dataIsLoading) return <FullScreenLoader />;

  return (
    <Layout title={title} description={description} navigation={navigation}>
      <HeaderText style={styles.headerStyle}>Resources</HeaderText>
      <Services
        initialDigitalResourceModalState={initialVideoModalState}
        initialActionModalState={initialScheduleModalState}
      />
    </Layout>
  );
};

export default AdditionalServices;
