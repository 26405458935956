export enum StatusCode {
  SuccessOK = 200,
  SuccessCreated = 201,
  ClientErrorBadRequest = 400,
  ClientErrorForbidden = 403,
  ClientErrorNotFound = 404,

  /**
   * Although the HTTP standard specifies "unauthorized", semantically this response means "unauthenticated".
   * That is, the client must authenticate itself to get the requested response.
   */
  ClientErrorUnauthorized = 401,

  /**
   * The request was well-formed but was unable to be followed due to semantic
   * errors.
   */
  ClientErrorUnprocessableEntity = 422,

  /**
   * A generic error message, given when an unexpected condition was
   * encountered and no more specific message is suitable.
   */
  ServerErrorInternal = 500,

  /**
   * The server error response code means that the server does not support
   * the functionality required to fulfill the request.
   */
  ServerErrorNotImplement = 501,

  /**
   * The server was acting as a gateway or proxy and received an invalid
   * response from the upstream server.
   */
  ServerErrorBadGateway = 502,

  /**
   * The server is currently unavailable (because it is overloaded or down for
   * maintenance). Generally, this is a temporary state.
   */
  ServerErrorServiceUnavailable = 503,

  /**
   * The server was acting as a gateway or proxy and did not receive a timely
   * response from the upstream server.
   */
  ServerErrorGatewayTimeout = 504,

  /**
   * The HTTP version used in the request is not supported by the server.
   */
  ServerErrorHTTPVersionNotSupported = 505,

  /**
   * The server has an internal configuration error: the chosen variant resource is configured to engage in transparent
   * content negotiation itself, and is therefore not a proper end point in the negotiation process.
   */
  ServerErrorVariantAlsoNegotiates = 506,

  /**
   * Storage (WebDAV)
   * The method could not be performed on the resource because the server is unable to store the representation needed
   * to successfully complete the request.
   */
  ServerErrorInsufficientStorage = 507,

  /**
   * Loop Detected (WebDAV)
   * The server detected an infinite loop while processing the request.
   */
  ServerErrorLoopDetected = 508,

  /**
   * Further extensions to the request are required for the server to fulfill it.
   */
  ServerErrorNotExtended = 510,

  /**
   * Indicates that the client needs to authenticate to gain network access.
   */
  ServerErrorNetworkAuthenticationRequired = 511,
}

const {
  ServerErrorInternal,
  ServerErrorNotImplement,
  ServerErrorBadGateway,
  ServerErrorServiceUnavailable,
  ServerErrorGatewayTimeout,
  ServerErrorHTTPVersionNotSupported,
  ServerErrorVariantAlsoNegotiates,
  ServerErrorInsufficientStorage,
  ServerErrorLoopDetected,
  ServerErrorNotExtended,
  ServerErrorNetworkAuthenticationRequired,
} = StatusCode;

/**
 * included: 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511
 */
export const fiveHundredsErrors = [
  ServerErrorInternal,
  ServerErrorNotImplement,
  ServerErrorBadGateway,
  ServerErrorServiceUnavailable,
  ServerErrorGatewayTimeout,
  ServerErrorHTTPVersionNotSupported,
  ServerErrorVariantAlsoNegotiates,
  ServerErrorInsufficientStorage,
  ServerErrorLoopDetected,
  ServerErrorNotExtended,
  ServerErrorNetworkAuthenticationRequired,
];
