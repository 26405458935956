import React, { useState, UIEventHandler, useMemo, useCallback } from 'react';

import { View, ViewStyle } from 'react-native';

import { AppFlatListProps } from 'components/AppFlatList/types';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';

const itemContainerStyle: ViewStyle = { position: 'absolute', width: `100%` };
const emptyCallback = () => '';
const emptySeparators = {
  highlight: emptyCallback,
  unhighlight: emptyCallback,
  updateProps: emptyCallback,
};

type Config<T> = Pick<
  AppFlatListProps<T>,
  'data' | 'keyExtractor' | 'ItemSeparatorComponent' | 'renderItem' | 'drawAll' | 'getItemLayout'
>;

export const useWebLazyScroll = <T extends unknown>({
  data,
  keyExtractor,
  getItemLayout,
  drawAll,
  renderItem,
  ItemSeparatorComponent,
}: Config<T>) => {
  if (!keyExtractor || !renderItem) {
    throw new Error('Fields (keyExtractor, renderItem) should be for virtual scrolling');
  }

  const { height } = useDeviceInfoContext();

  const [scrollTop, setScrollTop] = useState(0);
  const onScrollHandler: UIEventHandler<HTMLDivElement> = (e) => setScrollTop(e.currentTarget.scrollTop);

  const { startIndex, endIndex, innerHeight } = useMemo(() => {
    const itemsCount = data?.length || 0;
    const allItemSize = itemsCount && getItemLayout ? getItemLayout(data as T[], 0).length : 0;

    return {
      innerHeight: itemsCount * allItemSize,
      startIndex: Math.floor(scrollTop / allItemSize),
      endIndex: Math.min(itemsCount - 1, Math.floor((scrollTop + height) / allItemSize)),
    };
  }, [data, getItemLayout, height, scrollTop]);

  const itemsContainerStyle: ViewStyle = { position: 'relative', height: `${innerHeight}px` };

  const render = useCallback(
    (item: T, index: number, isEnd: boolean) => (
      <View
        key={keyExtractor(item, index)}
        style={{ ...itemContainerStyle, top: `${getItemLayout?.(data as T[], index).offset || 0}px` }}>
        {renderItem({
          index,
          item,
          separators: emptySeparators,
        })}
        {!!ItemSeparatorComponent && !isEnd && <ItemSeparatorComponent />}
      </View>
    ),
    [ItemSeparatorComponent, data, getItemLayout, keyExtractor, renderItem]
  );

  const renderedItems = useMemo<JSX.Element[]>(() => {
    if (!data?.length) return [];

    const elements: JSX.Element[] = [];

    const start = drawAll ? 0 : startIndex;
    const end = drawAll ? data.length : endIndex;

    for (let index = start; index <= end; index++) {
      const item = data[index];
      if (!item) continue;

      elements.push(render(item, index, index === end));
    }

    return elements;
  }, [data, drawAll, endIndex, render, startIndex]);

  return { onScrollHandler, renderedItems, itemsContainerStyle };
};
