import React from 'react';

import { useSelector } from 'react-redux';

import { ServiceActionItem } from 'components/ServiceActionItem';
import type { DigitalResource } from 'store/reducers/additional-services/types';
import { CustomServiceTypeEnum } from 'store/reducers/additional-services/types';
import { getDigitalResources } from 'store/selectors';

import { iconMapping } from '../map';

type DigitalResourceActionsProp = {
  actionHandler: (id: DigitalResource) => void;
};

export const DigitalResourceActions = ({ actionHandler }: DigitalResourceActionsProp) => {
  const resources = useSelector(getDigitalResources);

  return (
    <>
      {resources?.map((resource) => (
        <ServiceActionItem
          key={resource.id}
          onAction={() => actionHandler(resource)}
          done={resource.isCompleted}
          id={resource.id}
          stateType={CustomServiceTypeEnum.digitalResources}
          type={resource.type}
          userDataUuid={resource.userDataUuid}
          isNew={resource.isNew}
          actionLabel={resource.label}
          IconComponent={(iconMapping as any)[resource.icon]}
          triggerableAction={resource.repeatable}
        />
      ))}
    </>
  );
};
