import React from 'react';

import { animated, SpringValue } from '@react-spring/native';
import { View, StyleSheet, Pressable, PressableProps, ViewStyle } from 'react-native';

import { ArrowDown } from 'assets/icons';
import { StandardText } from 'style/common';
import { defaultTheme } from 'style/theme';
import { AnyFunction } from 'utils/types';

const AnimatedView = animated(View);

const styles = StyleSheet.create({
  arrowDownStyle: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,
  },
  showMoreText: { marginRight: 8, color: defaultTheme.colors.gray1, fontFamily: defaultTheme.fontFamilies.semiBold },
});

type ShowMoreArrowProps = {
  showMoreText: string;
  rotateProps: {
    rotate: SpringValue<string>;
  };
  showMoreHandler: AnyFunction;
  style?: ViewStyle;
} & PressableProps;

export const ShowMoreArrow = ({ style, showMoreHandler, showMoreText, rotateProps, ...rest }: ShowMoreArrowProps) => (
  <Pressable style={[styles.arrowDownStyle, style]} onPress={showMoreHandler} {...rest}>
    <StandardText style={styles.showMoreText}>{showMoreText}</StandardText>
    <AnimatedView style={{ transform: [{ rotate: rotateProps.rotate }] }}>
      <ArrowDown />
    </AnimatedView>
  </Pressable>
);

export default ShowMoreArrow;
