import { useRef, useEffect } from 'react';

import { FieldErrors } from 'react-hook-form';

import { AppScrollViewRef } from 'components/AppScrollView/types';

type Config = {
  itemHeight: number;
  errorNames: string[];
  errors: FieldErrors;
};

export const useScrollToErrorField = ({ errorNames, itemHeight, errors }: Config) => {
  const list = useRef<AppScrollViewRef>(null);

  // Next variables for optimize in useEffect dependencies
  const errorsString = JSON.stringify(errors); // we need handle values changing

  useEffect(() => {
    errorNames.some((name, i) => {
      const isError = !!errors[name];

      if (isError) {
        list.current?.scrollTo({ y: i * itemHeight, x: 0, animated: true });
      }

      return isError;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorNames.join(''), errorsString, errors, itemHeight]);

  return list;
};
