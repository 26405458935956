import { StyleSheet } from 'react-native';
import styled, { css } from 'styled-components/native';

import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { DefaultText, getFontStyles } from 'style/common';

export const useHomePageStyles = () => {
  const { isMobile } = useDeviceInfoContext();

  return StyleSheet.create({
    surveyModalContentWrapper: {
      height: 'auto',
      width: isMobile ? 330 : 492, // (figma) should keep empty space inside modal
      maxHeight: isMobile ? '90%' : '80%',
    },
  });
};

export const OverViewHeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-vertical: 26px;
  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-direction: column;
      align-items: flex-start;
      margin-vertical: 20px;
    `}
`;

export const OverViewText = styled(DefaultText)`
  ${getFontStyles(24)};
  font-family: ${(props) => props.theme.fontFamilies.bold};
  color: ${(props) => props.theme.colors.primary};
  ${({ theme }) =>
    theme.isMobile &&
    css`
      margin-bottom: 6px;
    `}
`;

export const DetailsText = styled(DefaultText)`
  ${getFontStyles(16)};
  text-align: right;
  color: ${(props) => props.theme.colors.gray1};
`;
