import { INITIAL_STATE } from 'store/reducers/user-info/initialState';
import { initialConsentModalsState } from 'store/reducers/user-info/initialState';
import { User } from 'store/reducers/user-info/types';

const clearConsents = (state = INITIAL_STATE): User => ({
  ...state,
  consentModalsState: initialConsentModalsState,
});

export default clearConsents;
