export const needHelp = 'Need Help?';
export const days = 'Monday - Friday';
export const time = '8:30 am - 5:00pm ET';
export const contact = 'Contact';
export const home = 'Home';

export const financialAssistance = 'Financial Assistance';
export const benefits = 'Benefits';
export const additionalResources = 'Resources';
export const rebateCenter = 'Rebate Center';
export const profile = 'Profile';
export const medCabinet = 'Medicine Cabinet';
export const medTracker = 'Medication Tracker';
