const getParsedTimestamp = (timestamp: number | string) =>
  typeof timestamp === 'number' ? timestamp : parseInt(timestamp, 10);

export const compareItems = <T>(prev: T, next: T, fieldToSortBy: keyof T, ascending?: boolean) => {
  let prevValue: string | number | undefined;
  let nextValue: string | number | undefined;

  if (typeof prev[fieldToSortBy] === 'string' || typeof prev[fieldToSortBy] === 'number') {
    prevValue = prev[fieldToSortBy] as unknown as string | number;
    nextValue = next[fieldToSortBy] as unknown as string | number;

    const parsedPrevTimestamp = getParsedTimestamp(prevValue);
    const parsedNextTimestamp = getParsedTimestamp(nextValue);

    if (!parsedPrevTimestamp || !parsedNextTimestamp) {
      throw new Error('Cannot parse arguments of compareItems');
    }
  }

  if (prevValue === nextValue) return 0;

  if (!prevValue) return 1;

  if (!nextValue) return -1;

  if (ascending) return prevValue < nextValue ? -1 : 1;

  return prevValue < nextValue ? 1 : -1;
};

export default compareItems;
