import React, { useEffect, useRef, useState } from 'react';

import { View } from 'react-native';
import ToastContainer, { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import { UserInterface } from 'api';
import { Button } from 'components/Button';
import { ModalComponent as Modal } from 'components/Modal';
import { TextFieldWithLabel } from 'components/TextFieldWithLabel';
import { useDeviceInfoContext } from 'contexts/DeviceInfo';
import { StatusCode } from 'enums';
import { useDebounce } from 'hooks';
import { setIsForgotPasswordModalVisible } from 'store/actions/user/actionCreators/setIsForgotPasswordModalVisible';
import { getForgotPasswordModalMessage, getForgotPasswordModalVisible } from 'store/selectors';
import { DefaultText, PrimaryDefaultText } from 'style/common';
import { isEmailValid } from 'utils/validations';

import { TEXTS } from '../constants';
import { useAuthStyles } from '../styles';

export const ForgotPasswordModal = () => {
  const isForgotPasswordModalVisible = useSelector(getForgotPasswordModalVisible);
  const withForgotPasswordModalMessage = useSelector(getForgotPasswordModalMessage);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [emailText, setEmailText] = useState('');
  const { isMobile } = useDeviceInfoContext();
  const toastRef = useRef<ToastContainer>(null);
  const toast = useToast();
  const dispatch = useDispatch();

  const styles = useAuthStyles();

  const debouncedEmailText = useDebounce(emailText, 300);

  useEffect(() => {
    setSendButtonDisabled(!isEmailValid(debouncedEmailText));
  }, [debouncedEmailText]);

  useEffect(() => {
    withForgotPasswordModalMessage && toastRef.current?.show(withForgotPasswordModalMessage, { type: 'danger' });
  }, [withForgotPasswordModalMessage]);

  const submitHandler = async () => {
    try {
      await UserInterface.forgotPassword(emailText);
      toast.show(TEXTS.FG_SUCCESS_TEXT, {
        type: 'success',
      });
      dispatch(setIsForgotPasswordModalVisible(false));
    } catch (e: any) {
      if (e?.response?.status === StatusCode.ClientErrorUnprocessableEntity) {
        toastRef.current?.show(TEXTS.FG_STATUS_422_ERROR_MESSAGE, { type: 'danger' });
      }
    }
  };

  const cancelHandler = () => {
    dispatch(setIsForgotPasswordModalVisible(false));
  };

  const maxWidthDescription = isMobile ? 260 : 363;
  const wrapperStyle = isMobile ? styles.mobileLoginPageModalWrapper : styles.loginPageModalWrapper;

  return (
    <Modal
      onRequestClose={cancelHandler}
      modalIsVisible={isForgotPasswordModalVisible}
      setModalVisible={setIsForgotPasswordModalVisible}
      toastRef={toastRef}>
      <View style={wrapperStyle}>
        <PrimaryDefaultText style={styles.loginPageModalTitle}>{TEXTS.FG_TITLE}</PrimaryDefaultText>
        <DefaultText style={[styles.loginPageModalDescription, { maxWidth: maxWidthDescription }]}>
          {TEXTS.FG_DESCRIPTION}
        </DefaultText>
        <TextFieldWithLabel
          height={56}
          labelText={TEXTS.FG_INPUT_LABEL}
          placeholder={TEXTS.FG_INPUT_PLACEHOLDER}
          autoComplete='off'
          value={emailText}
          onChangeText={setEmailText}
        />
        <View style={[styles.buttonWrapper]}>
          <Button onPress={submitHandler} disabled={sendButtonDisabled} text={TEXTS.FG_BUTTON_TEXT} />
        </View>
      </View>
    </Modal>
  );
};

export default ForgotPasswordModal;
