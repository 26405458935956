import React from 'react';

import { useTheme } from 'styled-components/native';

import { Check } from 'assets/icons';

import { DoneBoxContainer, DoneCheckIconWrapper, DoneText } from './styles';

type DoneActionBoxProps = {
  color?: string;
};

export const DoneActionBox = ({ color }: DoneActionBoxProps) => {
  const { isMobile, colors } = useTheme();

  return (
    <DoneBoxContainer color={color}>
      <DoneCheckIconWrapper>
        <Check stroke={colors.white} />
      </DoneCheckIconWrapper>
      {!isMobile && <DoneText>Done</DoneText>}
    </DoneBoxContainer>
  );
};

export default DoneActionBox;
