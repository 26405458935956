import { CLOSE_SELECTED_HOME_INFO_CARD } from 'store/action-types';
import {
  SHOW_HOME_INFO_CARD,
  SET_MILESTONE_TRACKER_DATA,
  SET_BENEFIT_INVESTIGATIONS,
  SET_SPECIALTY_PHARMACY,
  SET_INSURANCE_TILE_INFO,
} from 'store/action-types/cards';
import { AvailableActionsForCard } from 'store/actions';

import { INITIAL_STATE } from './initialState';
import { InitialReducerState } from './types';

const infoCards = (state = INITIAL_STATE, action: AvailableActionsForCard): InitialReducerState => {
  switch (action.type) {
    case CLOSE_SELECTED_HOME_INFO_CARD:
      return {
        ...state,
        shownCards: state.shownCards.filter((card) => card.title !== action.payload.selectedCardTitle),
      };
    case SHOW_HOME_INFO_CARD:
      return {
        ...state,
        shownCards: state.shownCards.map((card) => ({
          ...card,
          shown:
            typeof action.payload === 'string' ? action.payload === card.title : action.payload.includes(card.title),
        })),
      };
    case SET_MILESTONE_TRACKER_DATA:
      return {
        ...state,
        milestoneTrackers: action.payload,
      };
    case SET_BENEFIT_INVESTIGATIONS:
      return {
        ...state,
        benefitInvestigations: action.payload,
      };
    case SET_SPECIALTY_PHARMACY:
      return {
        ...state,
        specialtyPharmacy: action.payload,
      };
    case SET_INSURANCE_TILE_INFO:
      return {
        ...state,
        insuranceTileInfo: action.payload,
      };
    default:
      return state;
  }
};

export default infoCards;
