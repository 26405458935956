import { createSelector } from 'reselect';

import { InitialMFAState, MFAConfigChannels } from 'store/reducers/user-info/subreducers/mfaConfig/types';

export const getMFAInfo = (state: InitialMFAState) => state.mfaConfig.mfaInfo;
export const getMFASession = (state: InitialMFAState) => state.mfaConfig.mfaInfo.session;
export const getMFACountingDown = (state: InitialMFAState) => state.mfaConfig.mfaCountingDown;
export const getIsMFACodeWasSend = (state: InitialMFAState) => state.mfaConfig.isMFACodeWasSend;
export const getMFAUserPhoneNumber = (state: InitialMFAState) => state.mfaConfig.userMFAPhoneNumber;
export const getIsUserHasMFAPhoneNumber = (state: InitialMFAState) => state.mfaConfig.mfaInfo.isPhoneAdded;

export const getMFAInfoDTO = createSelector([getMFAInfo, getMFAUserPhoneNumber], (mfaInfo, mfaUserPhoneNumber) => {
  if (!mfaInfo) return;

  return {
    userId: mfaInfo.userId ?? '',
    session: mfaInfo.session ?? '',
    userName: mfaInfo.userName ?? '',
    phoneNumber: mfaUserPhoneNumber ?? '',
    mfaChannel: 'phone_number' as MFAConfigChannels['name'],
  };
});
