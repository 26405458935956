import * as ActionTypes from 'store/action-types';
import type { TileTypesEnum } from 'store/reducers/user-info/types/notification';

export type RemoveNotificationTileByType = (payload: TileTypesEnum) => {
  type: typeof ActionTypes.REMOVE_NOTIFICATION_TILES_BY_TYPE;
  payload: typeof payload;
};

export const removeNotificationTileByType: RemoveNotificationTileByType = (payload) => ({
  type: ActionTypes.REMOVE_NOTIFICATION_TILES_BY_TYPE,
  payload,
});
