import { Insurance } from 'api/Insurance/types';
import { addDashesForPhoneNumber } from 'utils/convertString';

export const getInsuranceCardInfo = ({
  type,
  primaryPharmacyCarrier,
  primaryInsuranceCarrier,
  policyId,
  policyPhone,
  cardholder,
  memberId,
  groupId,
  BIN,
  PCN,
}: Insurance) => ({
  title: type === 'MEDICAL' ? 'Medical Insurance' : 'Pharmacy Insurance',
  infoFields:
    // Empty space(' ') need for each of items to create spacer
    type === 'MEDICAL'
      ? [
          { label: 'Primary Insurance Carrier', info: primaryInsuranceCarrier || ' ' },
          { label: 'Policy ID #', info: policyId || ' ' },
          { label: 'Policy Phone', info: policyPhone ? addDashesForPhoneNumber(policyPhone) : ' ' },
        ]
      : [
          { label: 'Primary Pharmacy Carrier', info: primaryPharmacyCarrier || ' ' },
          { label: 'Policy Phone', info: policyPhone ? addDashesForPhoneNumber(policyPhone) : ' ' },
          { label: 'Name of Insured (Cardholder)', info: cardholder || ' ' },
          { label: 'RX Member ID', info: memberId || ' ' },
          { label: 'RX Group ID', info: groupId || ' ' },
          { label: 'RX BIN', info: BIN || ' ' },
          { label: 'RX PCN', info: PCN || ' ' },
        ],
});
