import React from 'react';

import { View } from 'react-native';
import { DefaultTheme, FlattenSimpleInterpolation, ThemedStyledProps } from 'styled-components';

import { ThemeSizes, BreakpointTypes } from './types';

export const breakpoints: Record<BreakpointTypes, number> = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1307,
  xxl: 1440,
};

export const appThemeSizes: ThemeSizes = {
  contentHorizontalPadding: 32,
};

export const mediaQueries = (key: BreakpointTypes, styles: FlattenSimpleInterpolation | string) => {
  return (props: ThemedStyledProps<React.RefAttributes<View>, DefaultTheme>) => {
    const {
      theme: { width = 0 },
    } = props;

    if (width <= breakpoints[key]) {
      return `${styles}`;
    }
  };
};
