export const SET_SHOULD_SHOW_SURVEY = 'SET_SHOULD_SHOW_SURVEY';
export const SET_SHOULD_FORCE_SURVEY_OPEN = 'SET_SHOULD_FORCE_SURVEY_OPEN';
export const SET_SURVEYS = 'SET_SURVEYS';
export const SET_SELECTED_SURVEY_ID = 'SET_SELECTED_SURVEY_ID';
export const REMOVE_SELECTED_SURVEY_ID = 'REMOVE_SELECTED_SURVEY_ID';
export const SET_FORWARDED_SURVEY_EXPIRED = 'SET_FORWARDED_SURVEY_EXPIRED';
export const SET_SURVEY_PROGRESS = 'SET_SURVEY_PROGRESS';
export const REMOVE_SELECTED_SURVEY = 'REMOVE_SELECTED_SURVEY';
export const SET_SURVEY_COMPLETED = 'SET_SURVEY_COMPLETED';
export const SET_SELECTED_ANSWER_FOR_QUESTION = 'SET_SELECTED_ANSWER_FOR_QUESTION';
