import { ApiConsentsInterface } from 'api/Consents/types';
import apiRequest, { apiPrefix } from 'utils/network';

const PROGRAM_CONSENTS_URL = '/consent/program-capture';
const CONSENTS_CONFIG_URL = '/consent/configuration';

export const ProgramConsentsInterface: ApiConsentsInterface = {
  saveGivenConsent: ({ consent_id }) =>
    apiRequest({
      method: 'POST',
      endpoint: PROGRAM_CONSENTS_URL,
      baseURL: apiPrefix,
      body: { consentUuid: consent_id, source: 'PARTNER_REGISTRATION', expirable: true },
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    }),
  getConfig: ({ program_uuid }) =>
    apiRequest({
      method: 'GET',
      endpoint: CONSENTS_CONFIG_URL,
      baseURL: apiPrefix,
      isProgram: true,
      programConfig: {
        withProxy: true,
      },
    }),
};
