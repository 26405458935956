import * as ActionTypes from 'store/action-types';

export type SetSurveyProgress = (payload: { surveyId: string; progress: number }) => {
  type: typeof ActionTypes.SET_SURVEY_PROGRESS;
  payload: typeof payload;
};

export const setSurveyProgress: SetSurveyProgress = (payload) => ({
  type: ActionTypes.SET_SURVEY_PROGRESS,
  payload,
});
