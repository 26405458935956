import { StyleSheet } from 'react-native';

import { defaultTheme } from 'style/theme';

export const styles = StyleSheet.create({
  popupContainer: { paddingHorizontal: 29, paddingTop: 17, paddingBottom: 29, maxWidth: 500 },
  popupMobileContainer: { paddingHorizontal: 5, paddingTop: 23, paddingBottom: 5, maxWidth: 300 },
  popupTitle: {
    ...defaultTheme.fontSizes[24],
    marginBottom: 24,
    fontFamily: defaultTheme.fontFamilies.bold,
  },
  popupDescription: {
    ...defaultTheme.fontSizes[16],
    color: defaultTheme.colors.gray1,
    fontFamily: defaultTheme.fontFamilies.default,
  },
  closeButton: {
    marginTop: 30,
    width: 110,
  },
});
